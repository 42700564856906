import React, { useState, useEffect } from 'react';
import FromFieldLabel from '../../../atoms/FromFieldLabel';
import FromFieldText from '../../../atoms/FromFieldText';
import FromFieldDate from '../../../atoms/FromFieldDate';
import "react-datepicker/dist/react-datepicker.css";



function AddFormOne() {


    const [dinno, setDinno] = useState('');


    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h5>Initiate Single Disposal- Add Form One</h5>
                </div>
            </div>


            <div className="row mt-4">
                <div className="col-6">
                    <div className="list-group">
                        <a href="#" className="list-group-item list-group-item-action">Furniture-001</a>
                        <a href="#" className="list-group-item list-group-item-action">Furniture-002</a>
                        <a href="#" className="list-group-item list-group-item-action">Furniture-003</a>
                        <a href="#" className="list-group-item list-group-item-action">Furniture-004</a>
                    </div>
                </div>
                <div className="col-6">
                    <div className="list-group">
                        <a href="#" className="list-group-item list-group-item-action">Furniture-001</a>
                        <a href="#" className="list-group-item list-group-item-action">Furniture-002</a>
                        <a href="#" className="list-group-item list-group-item-action">Furniture-003</a>
                        <a href="#" className="list-group-item list-group-item-action">Furniture-004</a>
                    </div>
                </div>
            </div>

            <div className="row mt-4 bgcolor">
                <div className="col-6">
                    <FromFieldText
                        label="Financial Classification"
                        placeholder="Financial Classification"
                        inputValue=''
                        setInputValue=''
                    />
                </div>
                <div className="col-6">
                    <FromFieldDate
                        label="Invoice Date"
                        inputValue=''
                        setInputValue=''
                    />
                </div>
            </div>

            <div className="row bgcolor">
                <div className="col-6">
                    <FromFieldText
                        label="Supplier Name"
                        placeholder="Supplier Name"
                        inputValue=''
                        setInputValue=''
                    />
                </div>
                <div className="col-6">
                    <FromFieldText
                        label="Supplier Invoice No"
                        placeholder="Supplier Invoice No"
                        inputValue=''
                        setInputValue=''
                    />
                </div>
            </div>

            <div className="row bgcolor">
                <div className="col-6">
                    <FromFieldText
                        label="Capitalized Value"
                        placeholder="Capitalized Value"
                        inputValue=''
                        setInputValue=''
                    />
                </div>
                <div className="col-6">
                    <FromFieldDate
                        label="Acc Dep upto Date"
                        inputValue=''
                        setInputValue=''
                    />
                </div>
            </div>

            <div className="row bgcolor">
                <div className="col-6">
                    <FromFieldDate
                        label="WDV Value on Date"
                        inputValue=''
                        setInputValue=''
                    />
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-4"></div>
                <div className="col-2 text-center">
                    <button className="btn btn-primary" > Back </button>
                </div>
                <div className="col-2 text-center">
                    <button className="btn btn-primary" > Next </button>
                </div>
                <div className="col-4"></div>
            </div>



        </div>
    )
}

export default AddFormOne;