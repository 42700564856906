import React, { useState, useRef, useEffect, useCallback } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { DataGrid } from '@mui/x-data-grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import axios from "axios";
// import { read } from "xlsx";
import { uploadAllocation } from '../../../services/asset.service'


const templatePath = "/exceltemplates/Asset_Allocation_Template.xlsx"



function InitiateBulkTransfer() {


    return (
        <div className='container'>
            <div className='row single__assets'>
                <div className="col">
                    <h5>Initiate Bulk Transfer</h5>
                </div>
            </div>

            <div className="row form-fields">
                <div className='col-12'>
                    <div className="row form-fields">
                        <div className="label col-3">
                            <label>Initiate Internal Transfe Upload Excel </label>
                            <span className="star error"> *</span>
                        </div>
                        <div className="float-start col-3">
                            <Button variant='contained' component='label' className="btn btn-primary">
                                <i className="bi bi-upload"></i> upload
                                <input hidden type='file' />
                            </Button>
                        </div>
                        <div className="float-start col-3">
                            <a href='' className="text-decoration-none btn btn-secondary btn-sm">
                                Download Template <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
                                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"></path>
                                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"></path>
                                </svg></a>

                        </div>
                    </div>
                </div>
            </div>

            <div className="row form-fields">
                <div className='col-12'>
                    <div className="row form-fields">
                        <div className="label col-3">
                            <label>Initiate External Transfe Upload Excel </label>
                            <span className="star error"> *</span>
                        </div>
                        <div className="float-start col-3">
                            <Button variant='contained' component='label' className="btn btn-primary">
                                <i className="bi bi-upload"></i> upload
                                <input hidden type='file' />
                            </Button>
                        </div>
                        <div className="float-start col-3">
                            <a href='' className="text-decoration-none btn btn-secondary btn-sm">
                                Download Template <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
                                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"></path>
                                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"></path>
                                </svg></a>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default InitiateBulkTransfer
