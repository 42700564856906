import React, {useEffect, useState} from 'react';
import './atom.css';
import Modal from 'react-modal';
import {getUserRollList, getUserList, updateTicketAssignee} from "../services/tickets.service";
import * as CryptoJS from "crypto-js";


const TicketAssignPopUpBox = ({ticketCode, ticketAssignedTo, ticketStatus, isOpen, onClose, onConfirm}) => {
    const [loading, setLoading] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [items, setItems] = useState([]);

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    const TEXT_REGEX = /^[a-zA-Z0-9-_ ]{1,500}$/;

    const [modules, setModules] = useState('');
    const [ErrModules, setErrModules] = useState(false);

    const [subModules, setSubModules] = useState('');
    const [ErrSubModules, setErrSubModules] = useState(false);

    const [mods, setMods] = useState([]);
    const [subMods, setSubMods] = useState([]);

    useEffect(() => {
        //encrypted data
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            //'param_data':obj,
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token': EncryptData}
        getUserRollList(oData).then(res => {
            if (res?.data?.data?.length) setMods(res.data.data);
        });

    }, [])

    const handleModule = (e) => {
        let mod_id = e.target.value;
        setModules([e.target.value]); // Set as an array
        let EncryptData1 = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': {'roll_id': mod_id},
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData1 = {'token': EncryptData1};
        getUserList(oData1).then(res => {
            if (Array.isArray(res.data.data)) {
                setSubMods(res.data.data);
            } else {
                setSubMods([]); // Set to an empty array if data is not an array
            }
        });

    }

    const handleSubModule = (e) => {
        let value = e.target.value;
        setSubModules([e.target.value]); // Set as an array
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        var valid_Name = true;

        if (!TEXT_REGEX.test(modules)) {
            setErrModules('Ticket Module is required');
            valid_Name = false; //alert(valid_Name);
            //return;
        } else {
            setErrModules('');
        }
        if (!TEXT_REGEX.test(subModules)) {
            setErrSubModules('Ticket Sub Module is required');
            valid_Name = false; //alert(valid_Name);
            //return;
        } else {
            setErrSubModules('');
        }

        if (valid_Name) { //alert('test1');
            saveData(); //submit form
        } else {
            return false;
        }
    }

    const saveData = function () {
        if (ticketCode !== '' && ticketCode !== null) {

            // Convert the decrypted bytes to a string

            var obj = {
                'ticket_code': ticketCode,
                'ticket_user_roll': modules,
                'ticket_user_code': subModules
            }
        }

        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': obj,
            //'param_data': {'ticket_code': ticketCode},
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token': EncryptData}
        try {
            updateTicketAssignee(oData).then(function (res) {
                console.log(res);
                if ((typeof (res.data.errors) != "undefined") && res.data.errors.errno > 0) {
                    setErrMsg(res.data.message);
                    setSuccess(false);
                } else {
                    setSuccess(res.data.message);
                    resetAssetInfo();
                    setItems();
                }
            });

            //setValidName(false);
        } catch (err) {
            setSuccess(false);
            if (!err?.data) {
                //console.log(err);
                setErrMsg('No Server Response');
            } else if (err.data?.status === 409) {
                setErrMsg('data already exist');
            } else {
                setErrMsg('Insert/Update Failed');
            }
            //elementRef.current.focus();
        }
    }

    const resetAssetInfo = function () {
        setModules('');
        setSubModules('');
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Confirm Box"
            style={modalStyles}
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Ticket Assignee Update</h5>
                        <button type="button" class="btn-close" onClick={onClose}></button>
                    </div>
                    <div class="modal-body">
                        <h6 className="modal-title">Ticket Code - {ticketCode} | Ticket Assigned To - {ticketAssignedTo} | Current Status - {ticketStatus}</h6>
                    </div>
                    <div style={modalBody}>


                        {success ? (
                            <div className="alert alert-success" role="alert"><i
                                className="bi bi-check-circle-fill"></i>{success}</div>
                        ) : (errMsg ? (<div className="alert alert-danger" role="alert"><i
                                className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>) : ('')
                        )}

                        <form onSubmit={handleSubmit}>

                            <div className="row mt-3">

                                <div className="label col-3">
                                    <label>Select User Roll</label><span className="star error"> *</span>
                                </div>

                                <div className="item col-3">

                                    <select onChange={handleModule} className="form-select form-select-sm mb-3">
                                        <option value="">Select User Roll</option>
                                        {mods && mods.length > 0 ? (
                                            mods.map((option) => (
                                                <option value={option.id}
                                                        selected={(option.id == modules) ? "selected" : ''}>{option.role_name}</option>
                                            ))
                                        ) : (
                                            <option value="">No Data</option>
                                        )}
                                    </select><span className="invalid-feedback"> {ErrModules} </span>
                                </div>

                                <div className="label col-3">
                                    <label>Select User</label><span className="star error"> *</span>
                                </div>


                                <div className="item col-3">
                                    <select onChange={handleSubModule} className="form-select form-select-sm">
                                        <option value="">Select User</option>
                                        {subMods && subMods.length > 0 ? (
                                            subMods.map((option) => (
                                                <option value={option.id}
                                                        selected={(subModules === option.id) ? "selected" : ''}>{option.name}</option>
                                            ))
                                        ) : (
                                            <option value="">No Data</option>
                                        )}
                                    </select>
                                    <span className="invalid-feedback"> {ErrSubModules} </span>
                                </div>

                                <div className="col-3">
                                    {(ticketCode !== '' && ticketCode !== null) ?
                                        (<button className="btn btn-primary btn-md"> Update </button>) :
                                        <button className="btn btn-primary btn-md"> SAVE </button>}

                                </div>

                            </div>


                        </form>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

const modalStyles = {
    content: {
        width: '550px',
        height: '250px',
        textAlign: 'center',
    },
};
const modalBody = {
    content: {
        position: 'relative',
        flex: '1 1 auto',
        padding: '10px !important'
    },
}
export default TicketAssignPopUpBox;