import React, { useState, useEffect } from 'react';
import FromFieldText from '../../../atoms/FromFieldText';
import FromFieldDate from '../../../atoms/FromFieldDate';
import "react-datepicker/dist/react-datepicker.css";



function UploadAuthorization() {


    const [dinno, setDinno] = useState('');


    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h5>Upload Authorization</h5>
                </div>
            </div>


            <div className='row '>
                 <div className='col-12'>&nbsp;</div>
        <div className='row'><div className='col-10'></div> <div className='col-2'></div></div> : <div className='row'><div className='col'><h5 className='align-right'>DIN No </h5></div>
            <div className='col'><input  inputValue='' type="text" className="form-control" placeholder='Search DIN No' /><p></p></div>
            <div className='col'><button className='btn btn-primary'> Search </button></div></div>
        
      </div>

            <div className="row mt-4">
                <div className="col-6">
                    <FromFieldText
                        label="DIN No"
                        placeholder="DIN No"
                        inputValue={dinno}
                        setInputValue={setDinno}
                    />
                </div>
                <div className="col-6">
                    <FromFieldDate
                        label="Date of DIN"
                        inputValue=''
                        setInputValue=''
                    />
                </div>
            </div>


            <div className="row">
                <div className="col-12">
                    <label style={{ marginRight: '10px' }}> Upload Authorization </label>
                    <input type="file" name="asset_invoice" />
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-4"></div>
                <div className="col-4 text-center">
                    <button className="btn btn-primary" > Submit </button>
                </div>
                <div className="col-4"></div>
            </div>



        </div>
    )
}

export default UploadAuthorization;