import React, {useEffect, useRef, useState} from 'react'
import {useLocation} from "react-router-dom";
import FromFieldText from '../../atoms/FromFieldText';
import FromSpFieldText from '../../atoms/FromSpFieldText';
import FromFieldDate from '../../atoms/FromFieldDate';
import FromFieldNumber from '../../atoms/FromFieldNumber';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import '../ExcelUploads/AssetInfo/SingleAsset.css';
import {getAddBond,getBondingConfigure,saveAddBond} from '../../services/stpi.service';
import * as CryptoJS from "crypto-js";

function getFormatedDate(dateString) {
    if (dateString != null) {
        let dte = new Date(dateString);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }
        return year + '-' + month + '-' + day;
    } else {
        return null;
    }
}

const TEXT_REGEX = /^[A-Za-z0-9-_/.@= ]{1,155}$/;
const TEXT_SPCL_REGEX = /^[A-Za-z0-9-_.,\/\[\]\-()"'+&'%$#@*!= ]{1,500}$/;
const NUMBER_REGEX = /^[0-9.]{1,15}$/;
const TEXT_DATE = /^[a-zA-Z0-9-_+:/() ]{1,35}$/;
export default function StpiAddBond() {
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const Bond_Id = query.get('boi');
    const BackUrl = query.get('back_url');
    const elementRef = useRef();
    const [selectedYearOptions, setSelectedYearOptions] = useState(null);
    useEffect(() => {
        if (Bond_Id && Bond_Id !== null) {

            try {
            // Log the original value before decryption
            //console.log('Original Bond_Id:', Bond_Id);
            // Replace space with '+' for decryption and decrypt the data
            const encryptedString = Bond_Id.replace(/ /g, '+');
            const decryptedBytes = CryptoJS.AES.decrypt(encryptedString, process.env.REACT_APP_ENCRYPT_SECRET_KEY);
            // Convert the decrypted bytes to a UTF-8 string
            const decryptedString = decryptedBytes.toString(CryptoJS.enc.Utf8);
            // Parse the decrypted string as JSON and extract Bond_Id
            const decrypt_data = JSON.parse(decryptedString);
            const decryptedBond_Id = decrypt_data.bond_id;
            //console.log('Decrypted Bond_Id:', decryptedBond_Id);
            if (decryptedBond_Id) {
                getAddBondInfo(decryptedBond_Id);
                setBondId(decryptedBond_Id);
            } else {
                console.error('Decryption failed or Bond_Id is missing.');
            }
        } catch (error) {
            console.error('Decryption error:', error);
        }
        }
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData1 = {'token': EncryptData}
        getBondingConfigure(oData1).then(function (res) {
            if (res?.data?.data?.data) {
                var configInfo = res.data.data.data;
                setBondConfigId(configInfo.bonding_id);
            }
        });
    }, [])

    function reset() {
        //setBondPrice('');
        setBondNumber ('');
        setBondAmount ('');
        setDateOfBond('');
    }

    
    const getAddBondInfo = (decryptedBond_Id) => {
        //console.log(decryptedBond_Id);
        try {
            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': {'bond_id':decryptedBond_Id},
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData1 = {'token': EncryptData}
            
            getAddBond(oData1).then(function (res) {
                console.log(res.data.data);
                if (res?.data?.data?.data) {
                    var configInfo = res.data.data.data;
                    setBondConfigId(configInfo.config_id);
                    //setBondNumber(configInfo.running_balance_available);
                    setBondNumber(configInfo.bond_number);
                    setBondAmount(configInfo.bond_amount);
                    setDateOfBond(new Date(configInfo.bond_date));
                    //setBondAmount(configInfo.remaining_bond_price);
                }
            })
        } catch (err) {
            //setAssetInfo('');
        }

    }
    //const userRef = useRef();
    const errRef = useRef();
    const [validName, setValidName] = useState(true);
    const [disabled, setDisabled] = useState(false);
    const [configId, setConfigId] = useState([]);
    const [BondId, setBondId] = useState([]);
    //const [BondPrice, setBondPrice] = useState('');
    //const [ErrBondPrice, setErrBondPrice] = useState(false);

    //const [BondDate, setBondDate] = useState('');
    //const [ErrBondDate, setErrBondDate] = useState(false);

    //const [RunningBalance, setRunningBalance] = useState('');
    //const [ErrRunningBalance, setErrRunningBalance] = useState(false);

    const [BondNumber, setBondNumber] = useState('');
    const [ErrBondNumber, setErrBondNumber] = useState(false);

    //const [RemainingAvailable , setRemainingAvailable ] = useState('');
    //const [ErrRemainingAvailable , setErrRemainingAvailable ] = useState(false);

    const [BondAmount , setBondAmount ] = useState('');
    const [ErrBondAmount , setErrBondAmount ] = useState(false);

    const [DateOfBond , setDateOfBond ] = useState('');
    const [ErrDateOfBond , setErrDateOfBond ] = useState(false);

    const [bondConfigId, setBondConfigId] = useState('');

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
    {/*
    const [bondNumberList, setBondNumberList] = useState([{ text: '' }]);
    const [bondPriceList, setBondPriceList] = useState([{ text: '' }]);
    const [dateList, setDateList] = useState([{ text: '' }]);
    // Handle input change
    const handleBondNumberChange = (e, index) => {
        const { name, value } = e.target;
        const numberlist = [...bondNumberList];
        numberlist[index][name] = value;
        setBondNumberList(numberlist);
        calculateTotal();
    };
    const handleBondPriceChange = (e, index) => {
        const { name, value } = e.target;
        const pricelist = [...bondPriceList];
        pricelist[index][name] = value;
        setBondPriceList(pricelist);
        calculateTotal();
    };
    const handleBondDateChange = (e, index) => {
        const { name, value } = e.target;
        const list1 = [...dateList];
        list1[index][name] = value;
        setDateList(list1);
    };
    // Handle click event of the "More" button
    const handleAddClick = () => {
        setBondNumberList([...bondNumberList, { text: '' }]);
        setBondPriceList([...bondPriceList, { price: '' }]);
        setDateList([...dateList, { date: '' }]);
        calculateTotal();
    };
    const handleRemoveField = (index) => {
        const values = [...bondNumberList];
        values.splice(index, 1);
        setBondNumberList(values);
        setBondPriceList(values);
        calculateTotal();
    };
    // Function to calculate the total of all input values
    const calculateTotal = () => {
        // Ensure the inputs are converted to numbers before summing
        const total = bondPriceList.reduce((acc, curr) => {
            return acc + (parseFloat(curr.price) || 0); // Convert to float, default to 0 if not a number
        }, 0);
        setBondAmount( parseFloat(total)+ parseFloat(RemainingAvailable));
        //return total;
    };
    */}
    const handleSubmit = async (e) => {
        e.preventDefault();
        var valid_Name = true; //alert(valid_Name);
        // if button enabled with JS hack
        //const v1 = TEXT_REGEX.test(yearaddition);
        
        if (!TEXT_REGEX.test(BondNumber )) {
            setErrBondNumber ('Bond number is required');
            valid_Name = false; //alert('1');
            //return;
        } else {
            setErrBondNumber('');
        }
        if (!TEXT_REGEX.test(BondAmount )) {
            setErrBondAmount('Bond amount is required');
            valid_Name = false; //alert('1');
            //return;
        } else {
            setErrBondAmount('');
        }
        if ((DateOfBond!="")) {
            setErrDateOfBond('');
            //return;
        } else {
            setErrDateOfBond('Date of bond is required');
            valid_Name = false;
        }
        /*if (!TEXT_REGEX.test(AdditionalCgApproved)) {
            setErrAdditionalCgApproved('Additional CG APPROVED is required');
            valid_Name = false; //alert('3');
            //return;
        } else {
            setErrAdditionalCgApproved('');
        }
        if (AdditionalDateOfBond!="") {
            setErrAdditionalDateOfBond('');
            //return;
        } else { 
            setErrAdditionalDateOfBond('STPI LOP Date is required');
            valid_Name = false; //alert('3');
        }*/
        
        if (valid_Name) { //alert('validName'); 
            saveData(); //submit form
        } else {
            //alert('valid_Name false');
            return false;
        }
    }
    
    const saveData = function () {
        var data = {
            'config_id': bondConfigId,
            //'running_balance_available': RemainingAvailable,
            'bond_number': BondNumber,// bondNumberList.map(item => item['text']).join(','),
            'bond_amount': BondAmount , //bondNumberList.map(item => item['text']).join(','),
            //'bond_price': bondNumberList.map(item => item['text']).join(','),
            'bond_date': DateOfBond , // dateList.map(item => item['date']).join(','),
            //'remaining_bond_price': BondAmount ,
            'created_by': sessionStorage.getItem('LoggedInUserId'),
            'company_id': sessionStorage.getItem('LoggedInCompanyId'),
            'division_id': sessionStorage.getItem('LoggedInDivisionId'),
        };
        if(Bond_Id !== '' && Bond_Id !== null){
            data.bond_id=BondId;
        }
        try {
            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': data, 
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData2 = {'token': EncryptData}
            saveAddBond(oData2).then(function (res) {
                if ((typeof (res.data.errors) !== "undefined") && res.data.errors.errno > 0) {
                    setErrMsg(res.data.message);
                    setSuccess(false);
                } else {
                    setSuccess(res.data.message);
                    setDisabled(true);
                    setErrMsg(false);
                    reset();
                }
            });
        } catch (err) {
            setSuccess(false);
            if (!err?.data) {
                console.log(err);
                setErrMsg('No Server Response');
            } else if (err.data?.status === 409) {
                setErrMsg('Username Taken');
            } else {
                setErrMsg('Insert/Update Failed');
            }
            //elementRef.current.focus();
        }
    }

    return (
        <div className='container' id="single-asset">
            <div className='row' ref={elementRef}>
                <div className="col">
                    <h5>Add Bond</h5>
                </div>

                <div className="invalid-feedback" role="alert">
                    <i className="fa fa-info-circle"></i>
                    Please Fill all mandatory field.
                </div>

                {success ? (
                    <div className="alert alert-success" role="alert"><i
                        className="bi bi-check-circle-fill"></i>{success}</div>
                ) : (errMsg ? (<div className="alert alert-danger" role="alert"><i
                        className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>) : ('')
                )}
            </div>
            <form onSubmit={handleSubmit} encType="multipart/form-data" method="post">
                
                {/*<div className='row m-2'>
                    <div className='col-md-6'>
                        <FromFieldText label="Running Balance available" star="*"
                            inputValue={RemainingAvailable }
                            setInputValue={setRemainingAvailable }
                            setErrorMsg={ErrRemainingAvailable }
                        />
                    </div>
                    <div className='col-md-4'>
                        
                    </div>

                </div> */}

                <div className='row m-2'>
                    <div className='col-md-6'>
                        <FromFieldText label="Bond Number" star="*"
                            inputValue={BondNumber }
                            setInputValue={setBondNumber }
                            setErrorMsg={ErrBondNumber }
                        />
                    </div>
                    <div className='col-md-4'>
                        
                    </div>

                </div>
                <div className='row m-2'>
                    <div className='col-md-6'>
                        <FromSpFieldText label="Bond Amount" star="*"
                            inputValue={BondAmount}
                            setInputValue={setBondAmount}
                            setErrorMsg={ErrBondAmount}
                        />
                    </div>
                    <div className='col-md-4'>
                        
                    </div>

                </div>
                <div className='row m-2'>
                    <div className='col-md-6'>
                    <FromFieldDate label="Date of Bond" star="*" 
                            //placeholder="YYYY-MM-DD"
                            inputValue={DateOfBond}
                            setInputValue={setDateOfBond}
                            setErrorMsg={ErrDateOfBond}
                        />
                        
                    </div>
                    <div className='col-md-4'>
                        
                    </div>
                </div>
        {/*  {bondNumberList.map((x, i) => (
          <div className='row m-2' key={i}>
            <div className='col-md-1-5'>
                <label>Bond Number</label><span class="star error"> * </span>
            </div>
            <div className='col-md-2'>
                <input className="form-control"
                name="text"
                //placeholder="Enter text"
                value={x.text}
                onChange={(e) => handleBondNumberChange(e, i)}
                />
            </div>
            <div className='col-md-1-5'>
                <label>Bond Amount</label><span class="star error"> * </span>
            </div>
            <div className='col-md-2'>
                <input className="form-control"
                name="text"
                //placeholder="Enter text"
                value={x.price}
                onChange={(e) => handleBondPriceChange(e, i)}
                />
            </div>
            <div className='col-md-1-5'>
                <label>Date of Bond</label><span class="star error"> * </span>
            </div>
            <div className='col-md-2'>
                <input type='date' className="form-control"
                name="date"
                placeholder="YYYY-MM-DD"
                value={x.date}
                onChange={(e1) => handleBondDateChange(e1, i)}
                />
            </div>
            <div className='col-md-2'>
                <button className="btn btn-primary " type="button" onClick={handleAddClick}>ADD</button>
                {(i > 0)?(
                <button className="btn btn-danger " type="button" onClick={() => handleRemoveField(i)}>Remove</button>
                ):'' }
            </div>
          </div>
        ))}
                    
                        
                    <div className='row m-2'>
                    <div className='col-md-6'>
                        <FromSpFieldText label="Running Balance in Bond" star="*"
                            inputValue={RemainingBalance}
                            setInputValue={setRemainingBalance}
                            setErrorMsg={ErrRemainingBalance}
                        />
                    </div>
                    <div className='col-md-6'>
                        
                    </div>

                </div> */}
                <div className='row save__button text-center'>
                    <div className='col-md-8'>
                        {(Bond_Id !== '' && Bond_Id !== null) ? <div>
                                <button className="btn btn-primary btn-md" disabled={disabled}>Update</button>
                                &nbsp;&nbsp;
                                <a href={'/stpi-bond-list' } className="btn btn-secondary btn-md">Cancel</a>
                                </div>
                            : <button id="btn-submit" className="btn btn-primary btn-md"
                                        disabled={disabled}> SAVE </button>
                        }
                    </div>
                </div>
            </form>
        </div>
    )
}
