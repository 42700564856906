import React,{ useState, useEffect, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate } from "react-router-dom";
import './Login.css'
import { ResetPasswordUser,verifyToken }  from '../../services/user.service';
const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z0-9\d@#$!%*?&]{8,}$/;
function ResetPassword() {
    
    const navigate  = useNavigate();
    const [userPwd,setUserPwd] = useState('');
    const [ErrUserPwd, setErrUserPwd] = useState(false);
    const [userCnfPWD,setUserCnfPWD] = useState('');
    const [ErrUserCaptcha, setErrUserCaptcha] = useState('');
    const [ErrUserCnfPWD, setErrUserCnfPWD] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState('');
    //ReCaptcha 
    const captchaRef = useRef(null);
    
    useEffect(() => {
        //userRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [userPwd, userCnfPWD])

    const handleSubmit = async (e) => {
		e.preventDefault(); var valid_Name=true; //alert(valid_Name);
		// if button enabled with JS hack
		//const v1 = TEXT_REGEX.test(yearaddition);
		
    if (userPwd=='') { 
			setErrUserPwd('Password is required'); valid_Name=false;
		}
    if (!strongPasswordRegex.test(userPwd)) { //alert('password');
        setErrUserPwd('Password should be strong. Please check note'); valid_Name=false;
		}else{ setErrUserPwd(''); //alert(password); 
      }
    if (userCnfPWD=='') { 
			setErrUserCnfPWD('Confirm password is required'); valid_Name=false;
			//return;
		}
        if (!strongPasswordRegex.test(userCnfPWD)) { //alert('cnfpassword');
          setErrUserCnfPWD('Confirm password should be strong. Please check note'); valid_Name=false;
			//return;
		}else{ setErrUserCnfPWD(''); //alert('1');
        }
        if(userPwd !== userCnfPWD) { //alert('=password');
          setErrUserCnfPWD('Password and confirm password must be same'); valid_Name=false;
			//return;
		}else{ setErrUserCnfPWD(''); //alert('2');
        }

        if (valid_Name) { 
          let token = captchaRef.current.getValue();
          //alert(token);
            if(token){
              var data1 = {
                'secret': process.env.REACT_APP_CAPTCHA_SECRET_KEY,
                'token':token, 
                };
                //let valid_token = await verifyToken(token);
                verifyToken(data1).then(function(result){
                  console.log(result.data);
                  if(result.data.success){  //alert(token);
                      //console.log(result.data.data);
                      checkResetPassword(); //submit form
                      //setSuccess(result.data.message);
                    }else if(result.data){ 
                      setErrMsg(result.data.message);
                      setSuccess(false);
                    }
                });
            }else{ 
              setErrUserCaptcha('You must confirm you are not a robot');
                //alert("You must confirm you are not a robot");
            }
		    }else{
            //alert('valid_Name1');
            return false;
        }
    }
    
    const checkResetPassword = function() {
        var data = { 
            'password': userPwd,
            //'cnf_password':userCnfPWD, 
            'email':sessionStorage.getItem('ForgotEmail'),
        };
        //console.log(data);
        try { 
            ResetPasswordUser(data).then(function(res){
                //console.log(res.data.data.length);
             if((typeof(res.data.error)!= "undefined") && res.data.error.errno>0){ 
                    setErrMsg(res.data.message);setSuccess(false);
              }else if(res.data.message=="Password reset successfully"){ 
                    //console.log(res.data.message);
                    setSuccess("Password reset successfully. Please login with your current password");
                    sessionStorage.setItem('Forgot_Email', '');
                    sessionStorage.setItem('Forgot_OTP', '');
                    navigate("/");
                    
                    window.location.reload(false);
                  }else{ setErrMsg("Password not reset. Please try again");setSuccess(false); }
                });
              } catch (err) { setSuccess(false); setErrMsg(err.data.message);
                if (!err?.data) {
                          console.log(err);
                  setErrMsg('No Server Response');
                } else if (err.data?.status === 409) {
                  setErrMsg('Unauthorized');
                } else {
                  setErrMsg('ResetPassword Failed');
                }
                //elementRef.current.focus();
            }
    }
  return (
    <div className='login-page'> 
      
      <div className='form shadow-lg p-3 mb-5 rounded'>
      <div className='login-logo'>{/*<img  src='Microchip_logo.png'/>*/}</div>
          <h5>Reset Password</h5>
          <form onSubmit={handleSubmit} > 
          {success ? (
				  <div className="alert alert-success" role="alert"><i className="bi bi-check-circle-fill"></i>{success}</div>
			        ) : ( errMsg ? (<div className="alert alert-danger" role="alert"><i className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>):('')
                ) }
        <div className="mb-3 input-group">
        <label className="input-group-text">Password &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
            <input
              type="password"
              className="form-control"
              placeholder="Enter password"
              value={userPwd}
              onChange={(event) => {setUserPwd(event.target.value)}}
            />
            <span className="invalid-feedback"> {ErrUserPwd} </span>
          </div>
          <div className="mb-3 input-group">
            <label className="input-group-text">Confirm Password </label>
            <input
              type="password"
              className="form-control"
              placeholder="Enter Confirm password"
              value={userCnfPWD}
              onChange={(event) => {setUserCnfPWD(event.target.value)}}
            /><span>(NOTE: Password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, one digit, and one special character.)</span>
            <span className="invalid-feedback"> {ErrUserCnfPWD} </span>
          </div>
          <div className="mb-3">
            
          </div>
          <div className="input-group">
            <div className='col-4'>
              
            </div>
            <div className='col-8'>
              <ReCAPTCHA sitekey="6Le5KyEmAAAAAHX7fDPb6n8V_iG0sEmONEmb_qjP" ref={captchaRef}  />
              <span className="invalid-feedback"> {ErrUserCaptcha} </span>
            </div>
          </div>
          <div className="input-group">
            <div className='col-3'></div><div className='col-2'></div>
            <div className='col-2'><button className="btn btn-primary btn-md"> Reset </button></div>
          </div>
          <p className="forgot-password text-right">
          <a href="/"> Sign-In </a>
          </p>
          </form>
        </div>
    </div>
  )
}
export default ResetPassword