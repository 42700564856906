import React,{ useState, useEffect,useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate } from "react-router-dom";
import './Login.css'
import { verifyOTP, verifyToken }  from '../../services/user.service';
const NUMBER_REGEX = /^[0-9]{1,15}$/;

function OtpVerify() {
    const navigate  = useNavigate();
    const [userOTP,setUserOTP] = useState('');
    const [ErrUserOTP, setErrUserOTP] = useState(false);
    const [ErrUserCaptcha, setErrUserCaptcha] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState('');
    //ReCaptcha 
    const captchaRef = useRef(null);
    
    useEffect(() => {
        //userRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [userOTP])

    const handleSubmit = async (e) => {
		e.preventDefault(); var valid_Name=true; //alert(valid_Name);
		// if button enabled with JS hack
		if (!NUMBER_REGEX.test(userOTP)) { 
			setErrUserOTP('userOTP is required'); valid_Name=false;
            //return;
		}else{ setErrUserOTP('');  }
        
        if (valid_Name) { 
          let token = captchaRef.current.getValue();
          //alert(token);
            if(token){
              var data1 = { 
                'secret': process.env.REACT_APP_CAPTCHA_SECRET_KEY,
                'token':token, 
              };
                //let valid_token = await verifyToken(token);
                verifyToken(data1).then(function(result){
                  console.log(result.data);
                  if(result.data.success){  //alert(token);
                      //console.log(result.data.data);
                      checkForgot(); //submit form
                      //setSuccess(result.data.message);
                    }else if(result.data){ 
                      setErrMsg(result.data.message);
                      setSuccess(false);
                    }
                });
            }else{ 
              setErrUserCaptcha('You must confirm you are not a robot');
                //alert("You must confirm you are not a robot");
            }
		    }else{
            //alert('valid_Name1');
            return false;
        }
    }
    
    const checkForgot = function() {
        var data = {
            'otp': userOTP,
            'email':sessionStorage.getItem('ForgotEmail'), 
          };
        //console.log(data);
        try { 
        verifyOTP(data).then(function(res){
                //console.log(res.data.data);
              if((typeof(res.data.error)!= "undefined") && res.data.error.errno>0){ 
                    setErrMsg(res.data.message);setSuccess(false);
              }else if(res.data.data){ 
                    //console.log(res.data.data);
                    setSuccess(res.data.message);
                    if(res.data.message==="OTP Verified"){
                      sessionStorage.setItem('Forgot_OTP', 'success');
                      sessionStorage.setItem('Forgot_Email','')
                      navigate("/resetpassword");
                      window.location.reload(false);
                    }else{
                        setErrMsg("Something Wrong");setSuccess(false);
                    }
                  }else{ setErrMsg("Invalid Forgot. Please try again");setSuccess(false); }
                });
              } catch (err) { setSuccess(false); setErrMsg(err.data.message);
                if (!err?.data) {
                  //console.log(err);
                  setErrMsg('No Server Response');
                } else if (err.data?.status === 409) {
                  setErrMsg('Unauthorized');
                } else {
                  setErrMsg('Forgot Failed');
                }
                //elementRef.current.focus();
            }
    }
  
  return (
    <div className='forgot-page'> 
      
      <div className='form shadow-lg p-3 mb-5 rounded'>
      <div className='login-logo'>{/*<img  src='Microchip_logo.png'/>*/}</div>
          <h5>Verify OTP</h5>
          <form onSubmit={handleSubmit} > 
          
          <div class="mb-12">
          {success ? (
				    <div className="alert alert-success" role="alert"><i className="bi bi-check-circle-fill"></i>{success}</div>
			        ) : ( errMsg ? (<div className="alert alert-danger" role="alert"><i className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>):('')
                ) }
                </div>
            <div className="mb-3 input-group">
            <label className="input-group-text"> OTP &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </label>
            <input
              type="password"
              className="form-control"
              placeholder="Enter OTP"
              value={userOTP}
              onChange={(event) => {setUserOTP(event.target.value)}}
            />
            <span className="invalid-feedback"> {ErrUserOTP} </span>
          </div>
          
          <div className="mb-3">
            
          </div>
          <div className="input-group">
            <div className='col-4'>
              <div className="form-check">
                {/*<input
                  type="checkbox"
                  className="form-check-input"
                  id="customCheck1"
                />
                <label className="form-check-label" htmlFor="customCheck1">
                  Remember me
          </label>*/}
              </div>
            </div>
            <div className='col-8'>
              <ReCAPTCHA sitekey="6Le5KyEmAAAAAHX7fDPb6n8V_iG0sEmONEmb_qjP" ref={captchaRef}  />
              <span className="invalid-feedback"> {ErrUserCaptcha} </span>
            </div>
          </div>
          <div className="input-group">
            <div className='col-3'></div><div className='col-2'></div>
            <div className='col-2'><button className="btn btn-primary btn-md"> Verify </button></div>
          </div>
          <p className="forgot-password text-right">
             <a href="/"> Sign-In </a>
          </p>
          </form>
        </div>
    </div>
  )
}

export default OtpVerify