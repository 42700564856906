import React, {useEffect, useRef, useState} from 'react'
import {useLocation} from "react-router-dom";
import FromFieldText from '../../atoms/FromFieldText';
import '../ExcelUploads/AssetInfo/SingleAsset.css';
import {getRunningBalance,getBondingConfigure} from '../../services/stpi.service';
import * as CryptoJS from "crypto-js";

const TEXT_REGEX = /^[A-Za-z0-9-_/.@= ]{1,155}$/;

export default function RunningBalance() {
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const Bond_Id = query.get('boi');
    const BackUrl = query.get('back_url');
    const elementRef = useRef();
    useEffect(() => {
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData1 = {'token': EncryptData}
        getBondingConfigure(oData1).then(function (res) {
            if (res?.data?.data?.data) {
                var configInfo = res.data.data.data;
                setBondConfigId(configInfo.bonding_id);
            }
        });
    }, [])

    const getRunningBalanceInfo = (decryptedBond_Id) => {
        //console.log(decryptedBond_Id);
        try {
            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': {'bond_id':decryptedBond_Id},
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData1 = {'token': EncryptData}
            
            getRunningBalance(oData1).then(function (res) {
                console.log(res.data.data);
                if (res?.data?.data?.data) {
                    var configInfo = res.data.data.data;
                    setBondConfigId(configInfo.config_id);
                    //setBondNumber(configInfo.running_balance_available);
                    setBondNumber(configInfo.running_balance_available);
                    
                }
            })
        } catch (err) {
            //setAssetInfo('');
        }

    }
    //const userRef = useRef();
    const errRef = useRef();
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [configId, setConfigId] = useState([]);
    const [bondConfigId, setBondConfigId] = useState('');
    const [BondNumber, setBondNumber] = useState('');
    const [ErrBondNumber, setErrBondNumber] = useState(false);
    return (
        <div className='container' id="single-asset">
            <div className='row' ref={elementRef}>
                <div className="col">
                    <h5>Running Balance</h5>
                </div>

                <div className="invalid-feedback" role="alert">
                    <i className="fa fa-info-circle"></i>
                    Please Fill all mandatory field.
                </div>

                {success ? (
                    <div className="alert alert-success" role="alert"><i
                        className="bi bi-check-circle-fill"></i>{success}</div>
                ) : (errMsg ? (<div className="alert alert-danger" role="alert"><i
                        className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>) : ('')
                )}
            </div>
            
                
                <div className='row m-2'>
                    <div className='col-md-6'>
                        <FromFieldText label="Bond Number" star="*"
                            inputValue={BondNumber }
                            setInputValue={setBondNumber }
                            setErrorMsg={ErrBondNumber }
                        />
                    </div>
                    <div className='col-md-4'>
                        
                    </div>

                </div>
                
                
            
        </div>
    )
}
