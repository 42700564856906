import React, { useState, useEffect } from 'react';



function ITEvaluationList() {

    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h5>IT Evaluation List</h5>
                </div>
            </div>

            <div className='row mt-4'>
                <div className='table-responsive'>
                    <table className='table-bordered w-100 text-center'>
                        <thead>
                            <tr>
                                <td style={{ fontWeight: 700, color: '#fff', background: '#000531', padding: '5px' }}>DIN</td>
                                <td style={{ fontWeight: 700, color: '#fff', background: '#000531', padding: '5px' }}>TAG</td>
                                <td style={{ fontWeight: 700, color: '#fff', background: '#000531', padding: '5px' }}>Final Sale Price W/o GST</td>
                                <td style={{ fontWeight: 700, color: '#fff', background: '#000531', padding: '5px' }}>WDV Value on Sale Date</td>
                                <td style={{ fontWeight: 700, color: '#fff', background: '#000531', padding: '5px' }}>Evaluation</td>
                                <td style={{ fontWeight: 700, color: '#fff', background: '#000531', padding: '5px' }}>Comment</td>
                                
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colSpan={4}></td>
                                <td>Final Sale Price W/o GST "Greater Than" WDV Value on Sale Date</td>
                                <td>STCG</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            
            <div className='row mt-4'>
                <div className='col-12 text-center'>
                    <button type='button' className='btn btn-primary'>PRINT EVALUATION</button>
                </div>
            </div>





        </div>
    )
}

export default ITEvaluationList;