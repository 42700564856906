import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import './AssetDetails.css';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FromFieldText from '../../../atoms/FromFieldText';

import {getDepreciation, getSTPI} from '../../../services/asset.service';
import {getDepartmentList} from '../../../services/user.service';

import * as CryptoJS from "crypto-js";

Date.prototype.dbFormatedDate = function () {

    let year = this.getFullYear();
    let month = this.getMonth() + 1;
    let day = this.getDate();
    if (month < 10) {
        month = '0' + month;
    }
    if (day < 10) {
        day = '0' + day;
    }
    return year + '-' + month + '-' + day;
    //return `${this.getFullYear()}-${this.getMonth()+1}-${this.getDate()-1}`
}

function getFormatedDate(dateString) {
    if (dateString !== null) {
        let dte = new Date(dateString);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }
        return year + '-' + month + '-' + day;
        //return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
    } else {
        return null;
    }
}


function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function ConsumablesAssetDetails() {
    const [value, setValue] = useState(0);
    const [tag, setTag] = useState(0);
    const [assetInfo, setAssetInfo] = useState(0);
    const [loading, setLoading] = useState(false);
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const Tag = query.get('tag');
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
    const [back_url, setBackUrl] = useState(false);
    //const [roles,setRoles] = useState([]);
    const [departments, setDepartments] = useState([]);
    useEffect(() => {
        if (Tag !== '' && Tag !== null) {
            const decryptedBytes = CryptoJS.AES.decrypt(Tag.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
            // Convert the decrypted bytes to a string
            const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
            const decryptedTag = decrypt_data.tag;
            setBackUrl(decrypt_data?.back_url);
            getTagAutoInfo(decryptedTag);
            //alert(Tag);
        }
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            //'param_data':obj,
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token': EncryptData}

        getDepartmentList(oData).then(res => {
            if (res?.data?.data?.length) setDepartments(res.data.data);
        });
    }, []);

    const handleChange = (event, newValue) => {
        console.log(newValue)
        setValue(newValue);
    };
    const getTagAutoInfo = function (Tag) {
        //alert(Tag);
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': {'tag': Tag},
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token': EncryptData}
        getSTPI(oData).then(function (res) {
            if (res?.data?.data) {
                setErrMsg('');
                var {assetInfo} = res.data.data;
                setAssetInfo(assetInfo);

            } else {
                setAssetInfo({});
                setErrMsg('Assets Not Found');
            }

        })
    }

    const getTagInfo = function () {
        if (tag == '') {
            setErrMsg('Enter Assets');
        } else {
            setErrMsg('');
            try {
                setLoading('..');
                let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                    'param_data': {'tag': tag},
                    'authToken': sessionStorage.getItem('LoggedInToken'),
                }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
                let oData = {'token': EncryptData}
                getDepreciation(oData).then(function (res) {
                    if (res.status === 200) {
                        var data = res?.data?.data;
                        if (data?.assetInfo && data?.assetDeprisiation) {
                            setErrMsg('');
                            setAssetInfo(data.assetInfo);
                            //setAssetDepriciation(data.assetDeprisiation);
                        } else {
                            setAssetInfo({});
                            setErrMsg('Assets Not Found');
                        }
                    }
                });
                setLoading(false); // Stop loading
            } catch (err) {
                setLoading(false); // Stop loading
                //setAssetDepriciation('');
            }
        }
    }
    return (
        <div className="container ">
            <h5> Consumables Asset Details </h5>
                    <div className='row'>
                            { 
                            (Tag !== '' && Tag !== null) ? <div className='row'>
                                <div className='col-10'></div>
                                <div className='col-2'>
                                    &nbsp;</div>
                                    </div> : 
                                    (back_url !== '' && back_url !== undefined && back_url !== false) ? (
                                    <a href={'/' + back_url}>
                                        <button className='btn btn-secondary btn-md'><i className="bi bi-arrow-left"></i> Back</button>
                                    </a>
                                    ) : (<a href={'/my-asset-list'}>
                                        <button className='btn btn-secondary btn-md'><i className="bi bi-arrow-left"></i> Back</button>
                                    </a> )
                                    
                            }
                 </div>       
                
                    
                


            {(Tag !== '' && Tag !== null) ? <div className='row'>
                    <div className='col-10'></div>
                    <div className='col-2'></div>
                </div> : <div className='row'>
                    <div className='col'><h5 className='align-right'>TAG </h5></div>
                    <div className='col'>
                        <input onChange={function (event) { setTag(event.target.value) }} 
                        inputValue={assetInfo.tag} type="text" 
                        className="form-control" placeholder='Enter the asset TAG'/>
                    </div>
                    <div className='col'>
                        <button onClick={getTagInfo} className='btn btn-info btn-md'><i className="bi bi-search"></i> Search
                        </button>
                    </div>
                </div>
            }


            <div className='row h-100'>
                <div className='col h-100'>
                    <Box sx={{width: '100%'}}>
                        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="Information" {...a11yProps(0)} />

                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0}>
                            <div className='row'>
                                <div className='col-6'><FromFieldText readOnly="readonly" label="TAG"
                                                                      inputValue={assetInfo.tag}/></div>
                                <div className='col-6'><FromFieldText readOnly="readonly" label="Parent TAG"
                                                                      inputValue={assetInfo.parent_tag}/></div>

                                <div className='col-6'><FromFieldText readOnly="readonly" label="Year of Addition"
                                                                      inputValue={assetInfo.year_of_addition}/></div>
                                <div className='col-6'><FromFieldText readOnly="readonly" label="Type of Addition"
                                                                      inputValue={assetInfo.type_of_addition}/></div>

                                <div className='col'><FromFieldText readOnly="readonly" label="Project Marking"
                                                                    inputValue={assetInfo.project_marking}/></div>
                                <div className='col-6'><FromFieldText readOnly="readonly" label="PO No (If Any)"
                                                                      inputValue={assetInfo.po_no}/></div>

                                <div className='col-6'><FromFieldText readOnly="readonly" label="Supplier Code (if Any)"
                                                                      inputValue={assetInfo.supplier_code}/></div>
                                <div className='col-6'><FromFieldText readOnly="readonly" label="Name of the Supplier"
                                                                      inputValue={assetInfo.supplier_name}/></div>

                                <div className='col-6'><FromFieldText readOnly="readonly" label="Invoice No"
                                                                      inputValue={assetInfo.invoice_no}/></div>
                                <div className='col-6'><FromFieldText readOnly="readonly" label="Invoice Date"
                                                                      inputValue={assetInfo.invoice_date ? getFormatedDate(assetInfo.invoice_date) : ''}/>
                                </div>

                                <div className='col-6'><FromFieldText readOnly="readonly" label="Bill of Entry No"
                                                                      inputValue={assetInfo.boe_no}/></div>
                                <div className='col-6'><FromFieldText readOnly="readonly" label="Date of BOE"
                                                                      inputValue={assetInfo.boe_date ? getFormatedDate(assetInfo.boe_date) : ''}/>
                                </div>

                                <div className='col-6'><FromFieldText readOnly="readonly" label="Shipping / AWB No"
                                                                      inputValue={assetInfo.shipping_awb_no}/></div>
                                <div className='col-6'><FromFieldText readOnly="readonly" label="Date of Shipping/AWB"
                                                                      inputValue={assetInfo.shipping_awb_date ? getFormatedDate(assetInfo.shipping_awb_date) : ''}/>
                                </div>

                                <div className='col-6'><FromFieldText readOnly="readonly" label="Accounting Voucher No."
                                                                      inputValue={assetInfo.accounting_voucher_no}/>
                                </div>
                                <div className='col-6'><FromFieldText readOnly="readonly" label="Internal Approval No"
                                                                      inputValue={assetInfo.internal_approval_no}/>
                                </div>

                                <div className='col-6'><FromFieldText readOnly="readonly" label="Cost Center Code"
                                                                      inputValue={assetInfo.cost_center_code}/></div>
                                <div className='col-6'><FromFieldText readOnly="readonly" label="Cost Center Name"
                                                                      inputValue={assetInfo.cost_center_name}/></div>

                                <div className='col-6'><FromFieldText readOnly="readonly" label="Qty"
                                                                      inputValue={assetInfo.qty}/></div>


                                <div className='col-6'><FromFieldText readOnly="readonly" label="Unit Of Measure"
                                                                      inputValue={assetInfo.unit_of_measure}/></div>
                                <div className='col-6'><FromFieldText readOnly="readonly"
                                                                      label="Name of the Asset (as per Invoice)"
                                                                      inputValue={assetInfo.asset_name}/></div>

                                <div className='col-6'><FromFieldText readOnly="readonly"
                                                                      label="Detailed Dsicription of the Asset"
                                                                      inputValue={assetInfo.asset_description}/></div>
                                <div className='col-6'><FromFieldText readOnly="readonly" label="Nature of Presence"
                                                                      inputValue={assetInfo.nature_of_presence}/></div>

                                <div className='col-6'><FromFieldText readOnly="readonly" label="Nature of Addition"
                                                                      inputValue={assetInfo.nature_of_addition}/></div>
                                <div className='col-6'><FromFieldText readOnly="readonly"
                                                                      label="Principal Asset to which Cost is Added"
                                                                      inputValue={assetInfo.principal_asset_tag}/></div>

                                <div className='col-6'><FromFieldText readOnly="readonly"
                                                                      label="Unique Sl No of the Asset"
                                                                      inputValue={assetInfo.asset_serial_no}/></div>
                                <div className='col-6'><FromFieldText readOnly="readonly" label="Make / Manufacturer"
                                                                      inputValue={assetInfo.manufacturer}/></div>

                                <div className='col-6'><FromFieldText readOnly="readonly" label="Model No"
                                                                      inputValue={assetInfo.model_no}/></div>
                                <div className='col-6'><FromFieldText readOnly="readonly" label="Part No"
                                                                      inputValue={assetInfo.part_no}/></div>

                                <div className='col-6'><FromFieldText readOnly="readonly" label="TAG"
                                                                      inputValue={assetInfo.tag}/></div>
                                <div className='col-6'><FromFieldText readOnly="readonly" label="Comp Ref. No."
                                                                      inputValue={assetInfo.comp_ref_no}/></div>

                                <div className='col-6'><FromFieldText readOnly="readonly" label="Asset TAG Name"
                                                                      inputValue={assetInfo.tag_name}/></div>
                                <div className='col-6'><FromFieldText readOnly="readonly" label="Asset TAG Branch"
                                                                      inputValue={assetInfo.tag_division}/></div>
                                <div className='col-6'><FromFieldText readOnly="readonly" label="Security Seal Date"
                                                                      inputValue={assetInfo.security_seal_date ? getFormatedDate(assetInfo.security_seal_date) : ''}/>
                                </div>
                                <div className='col-6'><FromFieldText readOnly="readonly" label="Control Department"
                                                                      inputValue={(departments && departments.length) > 0 ? (
                                                                        departments
                                                                            .filter(option => assetInfo.ownership_id === option.id)
                                                                            .map(option => option.department_name)
                                                                            .join(', ')
                                                                    ) : ('')}/></div>
                                <div className='col'></div>
                                <div className='col-6'></div>

                            </div>
                        </TabPanel>

                    </Box>
                </div>
            </div>
        </div>
    )
}

export default ConsumablesAssetDetails