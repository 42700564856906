import React, { useState,useEffect } from 'react';
import Box from '@mui/material/Box';
import { DataGrid,GridToolbarContainer  } from '@mui/x-data-grid';
import { getCgApprovalList } from '../../services/stpi.service';

import  * as CryptoJS  from "crypto-js";

function getFormatedDate(dateString) {
  if(dateString!=null) {
      let dte = new Date(dateString);
      let year = dte.getFullYear();
      let month = dte.getMonth() + 1;
      let day = dte.getDate();
      if (month < 10) {
        month = '0' + month;
      }if (day < 10) {
        day = '0' + day;
      }
      return year + '-' + month + '-' + day;
      //return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
  } else {
      return null;
  }   
}
function CgApprovalList() {
  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState(false);
  const [cgId, setCgId] = useState('');
  const [transferId, setTransferId] = useState('');
  const [ErrCgId, setErrCgId] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const handleSelectionModelChange = (newSelection) => {
    setSelectionModel(newSelection);
  };
  
  const handleConfirmOpen = async (tin_no) => {
    await setCgId(tin_no);
    setIsConfirmOpen(true);
  };

  const handleConfirmClose = () => {
    setIsConfirmOpen(false);
  };
  const handleConfirmReceived = () => {
    // Use the S3 getObject method to get the image and initiate the download
      //console.log('flagid'+flagId);
    if (window.confirm('Are you want to proceed to confirm items?')){
      //setCgId(tin_no);
      //getCgIdInfo(tin_no);
      //handleConfirm();
    }else{
      return false;
    }
  };
  

  const columns = [
    {"field":"cg_id","hideable":true,"hide":true,"editable":false,"headerName":"Sl No","width":50},
    {"field":"cg_approval_number","editable":false,"hide":false ,"headerName":"CG Approval Number","width":200},
    
    {"field":"additional_approved_price","editable":true,"hide":false ,"headerName":"Additional CG APPROVED Rs.","width":300},
    {"field":"cg_approval_date","hideable":true,"hide":false ,"editable":false,"headerName":"Date of Approval","width":180,
      renderCell: (params) => (
        <p>{ getFormatedDate(params.row.cg_approval_date)}</p>
      ) },
    
    {"field":"created_at","editable":true,"hide":false ,"headerName":"Created Date","width":130,
      renderCell: (params) => (
        <p>{ getFormatedDate(params.row.created_at)}</p>
      ) },
      {
        "field": "updated_at", "editable": false, "hide": false, "headerName": "Action", "width": 250,
        renderCell: (params) => {
            let EncryptID = CryptoJS.AES.encrypt(JSON.stringify({
                'cg_id': params.row.cg_id,
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            return (<a href={`/cg-approval?cg_id=${EncryptID}`}>Edit</a>);
        }
    },
  ];
  
function CustomToolbar() {
  return (
    <GridToolbarContainer>
      
    </GridToolbarContainer>
  );
}
const [items, setItems] = useState([]);
  useEffect(()=>{
    let isMounted = true;
    const fetchData = async () => {
      try {
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
          'param_data':{'new_ownership_id':sessionStorage.getItem('LoggedInDepartmentId')},
          'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token':EncryptData}
        const res = await getCgApprovalList(oData);
        console.log(res.data.data.data);
        if (isMounted && res?.data?.data?.data?.length) {
          setItems(res.data.data.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  
    // Cleanup function to handle unmounting
    return () => {
      isMounted = false;
    };
  },[])
  
  // readAssets();
  return (
    <div className='container'><h5> CG Approval List </h5>
      {success ? (<div className="alert alert-success" role="alert">
        <i className="bi bi-check-circle-fill"></i>{success}</div>) : (errMsg ? (
          <div className="alert alert-danger" role="alert">
            <i className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>) : ('')
      )}
      
      <div className='filter-container1 col-12'>
      <div className='col-8'></div><div className='col-2'>
        {/* <button className='btn btn-primary btn-md text-right' onClick={handleConfirmation}>Confirm</button> &nbsp; */}
        
        </div>
      </div>
      
      <DataGrid
        rows={items}
        getRowId={(row) => row.cg_id}
        columns={columns}
        pageSize={50}
        //height={500}
        //autoHeight
        //stickyHeaders={true}
        rowsPerPageOptions={[50]}
        components={{
          Toolbar: CustomToolbar,
        }}
      />
      
    </div>
  )
}

export default CgApprovalList ;
