import React,{ useRef, useState, useEffect } from 'react';

import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate } from "react-router-dom";
import './Login.css'
import { forgotUserPassword,verifyToken }  from '../../services/user.service';
const TEXT_REGEX = /^[a-zA-Z0-9@.-_ ]{1,155}$/;

function Forgot() {
    
    const navigate  = useNavigate();
    const [userName,setUserName] = useState('');
    const [ErrUserName, setErrUserName] = useState(false);
    const [ErrUserCaptcha, setErrUserCaptcha] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState('');
    //ReCaptcha 
    const captchaRef = useRef(null);
    
    useEffect(() => {
        setErrMsg('');
    }, [userName ])

    const handleSubmit = async (e) => {
		e.preventDefault(); var valid_Name=true; //alert(valid_Name);
		// if button enabled with JS hack
		if (!TEXT_REGEX.test(userName)) { 
			setErrUserName('Email is required'); valid_Name=false;
		}else{ setErrUserName('');  }
        if (valid_Name) { 
          let token = captchaRef.current.getValue();
          //alert(token);
            if(token){
              var data1 = { 
                'secret': process.env.REACT_APP_CAPTCHA_SECRET_KEY, 
                'token':token, 
              };
                //let valid_token = await verifyToken(token);
                verifyToken(data1).then(function(result){
                  console.log(result.data);
                  if(result.data.success){  //alert(token);
                      //console.log(result.data.data);
                      checkForgot(); //submit form
                      //setSuccess(result.data.message);
                    }else if(result.data){ 
                      setErrMsg(result.data.message);
                      setSuccess(false);
                    }
                });
            }else{ 
              setErrUserCaptcha('You must confirm you are not a robot');
            }
		    }else{
            //alert('valid_Name1');
            return false;
        }
    }
    
    const checkForgot = function() {
        var data = { 
            'Email': userName,
        };
        console.log(data);
        try { 
          forgotUserPassword(data).then(function(res){
                //console.log(res.data);
             if((typeof(res.data.error)!= "undefined") && res.data.error.errno>0){ 
                    setErrMsg(res.data.message);setSuccess(false);
              }else if(res.data.data){ 
                    //console.log(res.data.data);
                    setSuccess(res.data.message);
                    sessionStorage.setItem('ForgotEmail', data.Email);
                    sessionStorage.setItem('Forgot_Email', 'verified');
                    navigate("/verifyotp");
                    window.location.reload(false);
                  }else{ setErrMsg("Invalid Forgot. Please try again");setSuccess(false); }
                });
          } catch (err) { 
            console.log(err);
            setSuccess(false); setErrMsg(err.data.message);
                if (!err?.data) {
                          console.log(err);
                  setErrMsg('No Server Response');
                } else if (err.data?.status === 409) {
                  setErrMsg('Unauthorized');
                } else {
                  setErrMsg('Forgot Failed');
                }
            }
    }
  return (
    <div className='forgot-page'> 
      
      <div className='form shadow-lg p-3 mb-5 rounded'>
      <div className='login-logo'>{/*<img  src='Microchip_logo.png'/>*/}</div>
          <h5 class="sign-in-header">Forgot Password</h5>
          <div className="row"><div className="col-2">&nbsp;</div><div className="col-8"><p className="sign-in-para">Hey Enter Your Email And We Will Send You A OTP To Reset Your Password.</p></div><div className="col-2">&nbsp;</div></div>
          <form onSubmit={handleSubmit} > 
          {success ? (
				<div className="alert alert-success" role="alert"><i className="bi bi-check-circle-fill"></i>{success}</div>
			        ) : ( errMsg ? (<div className="alert alert-danger" role="alert"><i className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>):('')
                ) }
          <div className="mb-3 input-group">
            
          
            <label className="input-group-text">Email address</label>
            <input
              type="email"
              className="form-control"
              placeholder="Enter email"
              value={userName}
              onChange={(event) => {setUserName(event.target.value)}}
            />
            <span className="invalid-feedback"> {ErrUserName} </span>
          </div>
          
          <div className="mb-3">
            
          </div>
          <div className="input-group">
            <div className='col-4'>
              <div className="form-check">
                {/*<input
                  type="checkbox"
                  className="form-check-input"
                  id="customCheck1"
                />
                <label className="form-check-label" htmlFor="customCheck1">
                  Remember me
          </label>*/}
              </div>
            </div>
            <div className='col-8'>
              <ReCAPTCHA sitekey="6Le5KyEmAAAAAHX7fDPb6n8V_iG0sEmONEmb_qjP" ref={captchaRef}  />
              <span className="invalid-feedback"> {ErrUserCaptcha} </span>
            </div>
          </div>
          <div className="input-group">
            <div className='col-3'></div><div className='col-2'></div>
            <div className='col-12'><button className="btn btn-primary btn-lg mt-2 mb-2 w-100"> Submit </button></div>
          </div>
          <p className="forgot-password text-right">
             <a href="/"> <i class="bi bi-arrow-left-square"></i> Back to Login </a>
          </p>
          </form>
        </div>
    </div>
  )
}

export default Forgot