import React,{useState,useEffect,useRef} from 'react'
import { useLocation } from "react-router-dom";
import FromFieldText from '../../../atoms/FromFieldText';
import FromFieldTextArea from '../../../atoms/FromFieldTextArea';
import { getAllocationLog } from '../../../services/asset.service';
import FromSpFieldTextArea from '../../../atoms/FromSpFieldTextArea';
import  * as CryptoJS  from "crypto-js";
//import { FromFieldTextArea } from '../../../atoms/FromFieldTextArea';
/*Date.prototype.dbFormatedDate = function () {
    return `${this.getFullYear()}-${this.getMonth()+1}-${this.getDate()}`
  }
  */
  Date.prototype.dbFormatedDate = function () {
    
    let year = this.getFullYear();
    let month = this.getMonth() + 1;
    let day = this.getDate();
    if (month < 10) {
      month = '0' + month;
    }if (day < 10) {
      day = '0' + day;
    }
    return year + '-' + month + '-' + day;
    //return `${this.getFullYear()}-${this.getMonth()+1}-${this.getDate()-1}`
  }
  function getFormatedDate(dateString) {
    if(dateString!==null) {
        let dte = new Date(dateString);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        if (month < 10) {
          month = '0' + month;
        }if (day < 10) {
          day = '0' + day;
        }
        return year + '-' + month + '-' + day;
        //return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
    } else {
        return null;
    }   
  }
  const TEXT_REGEX = /^[A-Za-z0-9-_/.@= ]{1,155}$/;
const TEXT_SPCL_REGEX = /^[A-Za-z0-9-_.,\/\[\]\-()"'+&'%$#@*!= ]{1,500}$/;
//const NUMBER_REGEX = /^[0-9]{1,15}$/;

function AllocationLogDetails() {
    
    const [assetInfo, setAssetInfo] = useState({});
    const [tag, setTag] = useState(0);
    const [division,setDivision] = useState('');
    const [premises,setPremises] = useState('');
    const [floor,setFloor] = useState('');
    const [siteId,setSiteId] = useState('');
    const [userType,setUserType] = useState('');
    const [userDepartment,setUserDepartment] = useState('');
    const [employeeId,setEmployeeId] = useState('');
    const [employeeName,setemployeeName] = useState('');
    const [userMailId, setUserMailId] = useState('');
    const [reason,setReason] = useState('');
    const [effectivedate,setEffectiveDate] = useState('');
    const [financialsClassification,setFinancialsClassification] = useState('');
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const Tag = query.get('tag'); 
    const LogId = query.get('LogId');
    const elementRef = useRef();
    useEffect(() => {
        if(Tag!=='' && Tag!==null){
            const decryptedBytes = CryptoJS.AES.decrypt(Tag.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
            // Convert the decrypted bytes to a string
            const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
            const decryptedTag = decrypt_data.tag;
            getTagAssetInfo(decryptedTag);
            //alert(Tag);
        }
        
    }, []);
    const getTagAssetInfo = function() {
        const decryptedBytes = CryptoJS.AES.decrypt(Tag.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
            // Convert the decrypted bytes to a string
            const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
            const decryptedTag = decrypt_data.tag;
         const decryptedLogBytes = CryptoJS.AES.decrypt(LogId.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
            // Convert the decrypted bytes to a string
            const decrypt_log = JSON.parse(decryptedLogBytes.toString(CryptoJS.enc.Utf8));
            const decryptedLogId = decrypt_log.log_id;
        let obj= {'tag':decryptedTag,'logid':decryptedLogId,}
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data':obj,
            'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData = {'token':EncryptData}
        getAllocationLog(oData).then(function(res){
            if(res?.data?.data) {
                var Info = res.data.data[0]; 
                console.log(Info);
                setDivision(Info.division);
                setPremises(Info.premises);
                setFloor(Info.floor);
                setSiteId(Info.site_id);
                setUserType(Info.user_type);
                setUserDepartment(Info.user_department);
                setEmployeeId(Info.employee_id);
                setemployeeName(Info.employee_name);
                setUserMailId(Info.user_mail_id);
                //setInvoiceDate((new Date(Info.invoice_date)));
                setFinancialsClassification(Info.financials_classification);
                setTag(Info.tag);
                setReason(Info.reason);
                setEffectiveDate(getFormatedDate(new Date((Info.effective_date!=null)?Info.effective_date:Info.unallocate_date)));
            }
        })
    }
    const getTagInfo = function() {
          let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data':{'tag':tag},
            'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData = {'token':EncryptData}
          getAllocationLog(oData).then(function(res){
            if(res?.data?.data) {
                var Info = res.data.data; 
                //console.log(Info);
                setDivision(Info.division);
                setPremises(Info.premises);
                setFloor(Info.floor);
                setSiteId(Info.site_id);
                setUserType(Info.user_type);
                setUserDepartment(Info.user_department);
                setEmployeeId(Info.employee_id);
                setemployeeName(Info.employee_name);
                //setInvoiceDate((new Date(Info.invoice_date)));
                setFinancialsClassification(Info.financials_classification);
                setTag(Info.tag);
                setReason(Info.reason);
                setEffectiveDate((new Date(Info.effective_date)));
            }
        })
    }

    return (
        <div className="container">
            
            <div className="row">
                <div className="col">
                    <h5>Allocation Log Details</h5>
                </div>
                
            </div>
            {
            (Tag!='' && Tag!=null) ? <div className='row'><div className='col-10'></div> <div className='col-2'><a href={"/allocation-log?tag="+Tag} ><button className='btn btn-secondary btn-sm'><i className="bi bi-arrow-left"></i> Back </button></a>&nbsp;</div></div>
             : <div className='row'><div className='col'><h5 className='align-right'>TAG </h5></div>
            <div className='col'><input onChange={function(event){setTag(event.target.value)}} inputValue={assetInfo.tag} type="text" className="form-control"  placeholder='Enter the asset TAG'/></div>
            <div className='col'><button onClick={getTagInfo} className='btn btn-info btn-sm'><i className="bi bi-search"></i> Search </button></div></div>
            }  
            
            <div className='row'></div>
            
            <div className="row">
                <div className="col-6">
                    <FromFieldText  
                        label="TAG" 
                        inputValue={tag}
                        readOnly="readonly"
                        /> 
                </div><div className='col-6'></div>
                <div className="col-6">
                    {/*<FromFieldText
                        label="Invoice Date" 
                        inputValue={getFormatedDate(invoiceDate)}
                        //setInputValue={setInvoiceDate}
        /> */}
                </div>
            </div>
            
            <div className="row">
                <div className="col-6">
                    <FromFieldText 
                        label="Division" 
                        inputValue={division}
                        readOnly="readonly"
                        /> 
                </div>
                <div className="col-6"></div>
                <div className="col-6">
                    <FromFieldText 
                        label="Premises" 
                        inputValue={premises} 
                        readOnly="readonly"
                        /> 
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <FromFieldText 
                        label="Floor" 
                        inputValue={floor}
                        readOnly="readonly"
                        /> 
                </div><div className="col-6"></div>
                <div className="col-6">
                    <FromFieldText  
                        label="Site ID" 
                        inputValue={siteId} 
                        readOnly="readonly"
                        /> 
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <FromFieldText  
                        label="User Type" 
                        inputValue={userType}
                        readOnly="readonly"
                        /> 
                </div><div className="col-6"></div>
                <div className="col-6">
                    <FromFieldText   
                        label="User Department" 
                        inputValue={userDepartment} 
                        readOnly="readonly"
                        /> 
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <FromFieldText  
                        label="Employee ID" 
                        inputValue={employeeId}
                        readOnly="readonly"
                        /> 
                        
                </div><div className="col-6"></div>
                <div className="col-6">
                    <FromFieldText
                        label="Employee Name" 
                        inputValue={employeeName} 
                        readOnly="readonly"
                        /> 
                </div><div className="col-6"></div>
                <div className="col-6">
                    <FromFieldText  
                        label="User Mail ID" 
                        inputValue={userMailId}
                        readOnly="readonly"
                        /> 
                        
                </div><div className="col-6"></div>
            </div>
            <div className="row">
                <div className="col-6">
                <FromSpFieldTextArea maxLength = "500" numRows = "3"  //star="*"
                    label="Reason" 
                    inputValue={reason}
                    readOnly="readonly"
                />
                </div>
                <div className="col-6"></div>
                
            </div>
            <div className="row">
                <div className="col-6">
                <FromFieldText
                    label="Effective Date" 
                    inputValue={effectivedate} 
                    readOnly="readonly"
                />
                </div>
                <div className="col-6"></div>
                
            </div>
            
            <div className="row">
            <div className="col-3"></div>
                <div className="col-3">
                
                    </div>
                <div className="col-6"></div>
            </div>
            
        </div>
    )
}

export default AllocationLogDetails


