import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import {DataGrid, GridToolbarContainer} from '@mui/x-data-grid';
import {getAssetsCertificates} from '../../../services/asset.service';
//import download from 'downloadjs';
import * as CryptoJS from "crypto-js";
import './AssetInfo.css';
import CustomModal from '../../../atoms/ViewImagePdfModal';
import AWS from 'aws-sdk';

AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    region: process.env.REACT_APP_AWS_BUCKET_REGION,
});
const s3 = new AWS.S3();

Date.prototype.dbFormatedDate = function () {
    let year = this.getFullYear();
    let month = this.getMonth() + 1;
    let day = this.getDate();
    if (month < 10) {
        month = '0' + month;
    }
    if (day < 10) {
        day = '0' + day;
    }
    return year + '-' + month + '-' + day;
    //return `${this.getFullYear()}-${this.getMonth()+1}-${this.getDate()-1}`
}

function getFormatedDate(dateString) {
    if (dateString != null) {
        let dte = new Date(dateString);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }
        return year + '-' + month + '-' + day;
        //return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
    } else {
        return null;
    }
}

function AssetCertificateList() {
    const [loading, setLoading] = useState(true);
    /*const handleDownloadFile = async (id, path) => {
      var data1= {
        'token': sessionStorage.getItem('LoggedInToken'),
        'id':id,
        'path':path,
        //'mimetype':"image/jpg",
      }
      let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
        'param_data':data1,
        'authToken': sessionStorage.getItem('LoggedInToken'),
      }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
      let oData1 = {'token':EncryptData}
      downloadFile(oData1).then(res=>{
        //if(res?.data?.data?.length) {
        //  alert('sdf'); //setItems(res.data.data);
        //}
        const split = path.split('/');
        const filename = split[split.length - 1];
        return download(res.data, filename, data1.mimetype);

      });
    };*/
    const handleViewFile = async (id, imageId) => {
        try {
            const data = await s3.getObject({
                Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
                Key: imageId, // 'path/to/your/image.jpg',
            }).promise();

            const url = URL.createObjectURL(new Blob([data.Body]));
            //setImageUrl(url);
            if (url.endsWith('.jpg') || url.endsWith('.png')) {
                setModalContentType('image');
            } else if (url.endsWith('.pdf')) {
                setModalContentType('pdf');
            }
            setModalContent(url);
            setModalIsOpen(true);
        } catch (error) {
            console.error('Error fetching image:', error);
        }
    };
    const handlePreview = async (id, imageId) => {
        imageId = 'certificate/' + imageId;
        try {
            const data = await s3.getObject({
                Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
                Key: imageId, // 'path/to/your/image.jpg',
            }).promise();
            console.log(data.ContentType);
            const signedUrl = s3.getSignedUrl('getObject', {
                Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
                Key: imageId,
            });
            //setImageUrl(url);
            console.log(signedUrl);
            if (data.ContentType === 'image/jpeg' || data.ContentType === 'image/jpg' || data.ContentType === 'image/png' || data.ContentType === 'application/octet-stream') {
                //if (url.endsWith('.jpg') || url.endsWith('.png')) {
                setModalContentType('image');
            } else if (data.ContentType === 'application/pdf') { // if (url.endsWith('.pdf')) {
                setModalContentType('pdf');
            }
            setModalContent(signedUrl);
            setModalIsOpen(true);
        } catch (error) {
            console.error('Error fetching image:', error);
        }
    };
    const handleDownloadFile = async (id, imageId) => {
        // Use the S3 getObject method to get the image and initiate the download
        console.log('imageId' + imageId);
        imageId = 'certificate/' + imageId;
        s3.getObject({
            Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
            Key: imageId, //imageId,
        }, (err, data) => {
            if (err) {
                console.error('Error downloading image:', err);
            } else {
                console.log(data.Body);
                const url = URL.createObjectURL(new Blob([data.Body]));
                const a = document.createElement('a');
                a.href = url;
                a.download = imageId;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }
        });
    };
    const columns = [
        {"field": "sl_no", "hideable": true, "hide": true, "editable": false, "headerName": "Sl No", "width": 50},
        {"field": "tag", "editable": false, "hide": false, "headerName": "TAG", "width": 250,},
        {
            "field": "parent_tag",
            "hideable": true,
            "hide": true,
            "editable": true,
            "headerName": "Parent TAG",
            "width": 250
        },
        {"field": "supplier_code", "editable": true, "hide": false, "headerName": "Supplier Code", "width": 130},
        {"field": "supplier_name", "editable": true, "hide": false, "headerName": "Supplier Name", "width": 130},
        {"field": "invoice_no", "editable": true, "hide": false, "headerName": "Invoice No", "width": 130},
        {
            "field": "invoice_date", "editable": true, "hide": false, "headerName": "Invoice Date", "width": 130,
            renderCell: (params) => (
                <p>{getFormatedDate(params.row.certificate_date)}</p>
            )
        },

        {
            "field": "created_at", "editable": true, "hide": false, "headerName": "Date of Addition", "width": 130,
            renderCell: (params) => (
                <p>{getFormatedDate(params.row.created_at)}</p>
            )
        },
        {"field": "doc_type", "editable": false, "hide": false, "headerName": "Doc Type", "width": 100},
        {
            field: 'asset_certificate', headerName: 'Actions', width: 200, renderCell: (params) => {
                return (<span>{(
                        <div><a href="#"
                                onClick={() => handleDownloadFile(params.row.id, params.row.asset_certificate)}>Download</a> &nbsp;
                        </div>
                    )}</span>
                );
            }
        },

    ];

    function readTemplate() {
        // axios.get(templatePath).then((res)=>{
        //     console.log("res",res)
        // })
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer>

            </GridToolbarContainer>
        );
    }

    const [items, setItems] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [modalContentType, setModalContentType] = useState('');
    var onCellEditStopFn = (event, newCell) => {
        var obj = {};
        obj[newCell.field] = event.target.value;
    }

    useEffect(() => {
        setLoading(true); // Set loading to true when fetching starts

        let EncryptData = CryptoJS.AES.encrypt(
            JSON.stringify({
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }),
            process.env.REACT_APP_ENCRYPT_SECRET_KEY
        ).toString();
        let oData = { 'token': EncryptData };

        getAssetsCertificates(oData)
            .then(res => {
                if (res?.data?.data?.length) {
                    setItems(res.data.data);
                }
            })
            .finally(() => {
                setLoading(false); // Set loading back to false when fetching is complete
            });
    }, []);

    // readAssets();
    return (
        <div className='container'>
            <h5> Asset Certificates </h5>
            <Box >

                {loading ? (
                    <div className="text-center">
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ) : (
                    <DataGrid
                        rows={items}
                        getRowId={(row) => row.id}
                        columns={columns}
                        pageSize={50}
                        autoHeight
                        rowsPerPageOptions={[50]}
                        components={{
                            Toolbar: CustomToolbar,
                        }}
                    />
                )}

            </Box>
            <CustomModal
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                content={modalContent}
                contentType={modalContentType}
            />
        </div>
    )
}

export default AssetCertificateList
