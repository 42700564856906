import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import FromFieldText from '../../atoms/FromFieldText';
import {getERAssignmentDataView} from '../../services/user.service';
import * as CryptoJS from "crypto-js";
import FromFieldDate from "../../atoms/FromFieldDate";
import FromFieldDateHari from "../../atoms/FromFieldDateHari";
import FromSpFieldTextAreaHari from "../../atoms/FromSpFieldTextAreaHari";

import FromFieldTime from "../../atoms/FromFieldTime";
import FromFieldTextER from "../../atoms/FromFieldTextER";

function ERAssessmentViewReport() {
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
    const [aInfo, setAInfo] = useState([]);
    const [mInfo, setMInfo] = useState([]);

    const [assignedDate, setAssignedDate] = useState('');

    const [acceptedDate, setAcceptedDate] = useState('');

    const [sericeProviderName, setSericeProviderName] = useState('');
    const [contactName, setContactName] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [mailId, setEmailId] = useState('');
    const [mobileNo, setMobileNo] = useState('');
    const [invoiceAmount, setInvoiceAmount] = useState('');
    const [invoiceDate, setInvoiceDate] = useState('');
    const [invoiceNo, setInvoiceNo] = useState('');

    const [assignmentNumber, setAssignmentNumber] = useState('');
    const [ErrAssignmentNumber, setErrAssignmentNumber] = useState('');

    const [detailProblem, setDetailProblem] = useState('');

    const [detailProblemIdentified, setDetailProblemIdentified] = useState('');

    const [materialUsed, setMaterialUsed] = useState('');

    const [formCategory, setFormCategory] = useState('');

    const [assignedTo, setAssignedTo] = useState('');

    const [nextServiceDue, setNextServiceDue] = useState('');

    const [nextServiceDate, setNextServiceDate] = useState('');

    const [closingNote, setClosingNote] = useState('');

    const [repairCategory, setRepairCategory] = useState('');

    const [materials, setMaterials] = useState([{
        date: '',
        name: '',
        partNumber: '',
        make: '',
        model: '',
        serialNo: '',
        qty: '',
        cost: ''
    }]);

    const [showTextField, setShowTextField] = useState(false);

    const resetUserInfo = () => {
        setAssignmentNumber('');
    };

    const handleFormCategory = (e) => {
        setFormCategory(e.target.value);
    }

    const handleClosingNote = (e) => {
        setClosingNote(e.target.value);
    }

    // Inside your function that processes the data
    const getTagInfo = function () {
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': {'assignment_no': assignmentNumber},
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

        let oData = {'token': EncryptData};

        getERAssignmentDataView(oData).then(function (res) {
            // If there is data in the response
            if (res.data.data.assessment_data) {
                setErrMsg('');  // Clear any error messages
                setAInfo(res.data.data.list_equipment);  // Set the full array of equipment info
                setMInfo(res.data.data.list_material_used);  // Set the full array of material data info
                const firstItem = res.data.data.assessment_data;
                setAssignmentNumber(firstItem.assignment_no);
                setAssignedTo(firstItem.endUser_name);
                setAssignedDate(new Date(firstItem.assignedDate));
                setAcceptedDate(firstItem.assignment_accepted_date ? new Date(firstItem.assignment_accepted_date) : null);
                setFormCategory(firstItem.cat_ir);
                setRepairCategory(firstItem.service_name);
                setMobileNo(firstItem.mobile_no);
                setEmailId(firstItem.contact_email);
                setContactNumber(firstItem.contact_no);
                setContactName(firstItem.contact_person);
                setSericeProviderName(firstItem.provider_name);
                setDetailProblem(firstItem.problem_desc);
                setDetailProblemIdentified(firstItem.detail_problem_identified);
                setMaterialUsed(firstItem.materialUsed);
                setInvoiceNo(firstItem.invoice_no);
                setInvoiceAmount(firstItem.invoice_amount);
                setInvoiceDate(new Date(firstItem.invoice_date));


                setNextServiceDue(firstItem.nextServiceDue);
                setNextServiceDate(new Date(firstItem.nextServiceDate));
                setClosingNote(firstItem.handleClosingNote);

            } else {
                setErrMsg('Assignment no not found');
                setAInfo([]);  // Reset aInfo to an empty array if no data is found
            }
        }).catch(() => {
            setErrMsg('Failed to fetch data');
            setAInfo([]);  // Handle errors by clearing aInfo
        });
    };

    useEffect(() => {
        if (mInfo.length > 0) {
            const mappedMaterials = mInfo.map(material => ({
                date: material.material_date,
                name: material.material_name,
                partNumber: material.material_partNumber,
                make: material.material_make,
                model: material.material_model,
                serialNo: material.material_serialNo,
                qty: material.material_qty,
                cost: material.material_cost
            }));

            setMaterials(mappedMaterials);
        }
    }, [mInfo]);


    const handleMaterial = (e) => {
        setMaterialUsed(e.target.value);
    }
    const handleServices = (event) => {
        const value = event.target.value;
        setNextServiceDue(event.target.value);
        setShowTextField(value === "Yes");
    }

    const handleInputChange = (index, event) => {
        const {name, value} = event.target;
        const updatedMaterials = materials.map((material, i) => (
            i === index ? {...material, [name]: value} : material
        ));
        setMaterials(updatedMaterials);
    };

    // Encrypt the assignment number
    let encryptedAssignmentNO = CryptoJS.AES.encrypt(
        JSON.stringify({ 'assignment_no': assignmentNumber }),
        process.env.REACT_APP_ENCRYPT_SECRET_KEY
    ).toString();


    return (
        <div className="container">
            <div className="row">
                <div className="col-6">
                    <h5>View ER Assessment Report</h5>
                </div>


                <div className="invalid-feedback" role="alert">
                    <i className="fa fa-info-circle"></i>
                    Please Fill all mandatory fields.
                </div>

                {success ? (
                    <div className="alert alert-success" role="alert">
                        <i className="bi bi-check-circle-fill"></i>{success}
                    </div>
                ) : (
                    errMsg && (
                        <div className="alert alert-danger" role="alert">
                            <i className="bi bi-exclamation-triangle-fill"></i>{errMsg}
                        </div>
                    )
                )}
            </div>

            <div className="row">
                <div className="col-6">
                    <h5>&nbsp;</h5>
                </div>
                <div className="col-5 text-right">
                    <a href={`/er-assessment-report?assignmentNos=${encodeURIComponent(encryptedAssignmentNO)}`}>
                        <button type="button" className="btn btn-danger btn-sm ms-3">
                            Edit Report
                        </button>
                    </a>
                </div>
            </div>


            <div className='row '>
                <div className='col-12'>&nbsp;</div>
                {
                    <div className='row'>
                        <div className='col'><h5 className='align-right'>Assignment No </h5></div>
                        <div className='col'><input onChange={function (event) {
                            setAssignmentNumber(event.target.value)
                        }} inputValue='' type="text" className="form-control"
                                                    placeholder='Enter the assignment no'/><p
                            className='invalid-feedback'>{ErrAssignmentNumber}</p></div>
                        <div className='col'>
                            <button onClick={getTagInfo} className='btn btn-info btn-sm'><i
                                className="bi bi-search"></i> Search
                            </button>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href={'/ir-assessment-report'}>
                            <button className='btn btn-info btn-sm'>Reset</button>
                        </a>&nbsp;</div>
                    </div>
                }
            </div>
            <div className='row'></div>

            <div className="row">

                <div className="row mt-2">

                    <div className="col-6">
                        <div className="row">
                            <div className="col-md-6">
                                <label> FORM Category </label>
                                <span className="star error">  </span>
                            </div>
                            <div className="col-md-6">
                                <select disabled onChange={handleFormCategory} name="formCategory"
                                        className="form-select form-select-sm">
                                    <option value="">Select Form Category</option>
                                    <option value="ERAI"
                                            selected={formCategory === "ERAI" ? "selected" : ''}>IT Assets
                                    </option>
                                    <option value="ERAO"
                                            selected={formCategory === "ERAO" ? "selected" : ''}>Other Asset
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-2">

                    <div className='col-md-6'>
                        <FromFieldDate
                            readOnly={true}
                            label="Assigned Date" star="*" placeholder="YYYY-MM-DD"
                            inputValue={assignedDate}
                            setInputValue={setAssignedDate}
                        />
                    </div>
                    <div className="col-6">
                        <FromFieldText
                            readOnly={true}
                            star="*"
                            label="Assignment Number"
                            inputValue={assignmentNumber}
                            setInputValue={setAssignmentNumber}
                            setErrorMsg={setErrAssignmentNumber}
                        />
                    </div>
                </div>

                <div className="row mt-2">

                    <div className='col-md-6'>
                        <FromFieldDate
                            readOnly={true}
                            label="Assignment Accepted Date" star="*" placeholder="YYYY-MM-DD"
                            inputValue={acceptedDate}
                            setInputValue={setAcceptedDate}
                        />
                    </div>
                    <div className='col-md-6'>
                        <FromFieldText
                            readOnly={true}
                            star="*"
                            label="Assigned To"
                            placeholder="Assigned To User Name"
                            inputValue={assignedTo}
                            setInputValue={setAssignedTo}
                        />
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-6">
                        <div className="row">
                            <div className="col-md-6">
                                <label> Repair Category </label>
                                <span className="star error">  </span>
                            </div>
                            <div className="col-md-6">
                                <select disabled onChange={handleFormCategory} name="alternative_verification"
                                        className="form-select form-select-sm">
                                    <option value="">Select Form repair Category</option>
                                    <option value="AMC"
                                            selected={repairCategory === "AMC" ? "selected" : ''}>AMC
                                    </option>
                                    <option value="warranty"
                                            selected={repairCategory === "Warranty" ? "selected" : ''}>warranty
                                    </option>
                                    <option value="Insurance"
                                            selected={repairCategory === "Insurance" ? "selected" : ''}>Insurance
                                    </option>
                                    <option value="Paid service"
                                            selected={repairCategory === "Paid service" ? "selected" : ''}>Paid
                                        service
                                    </option>
                                    <option value="Others"
                                            selected={repairCategory === "Others" ? "selected" : ''}>Others
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <FromFieldText
                            readOnly={true}
                            star="*"
                            label="Service Provider Name"
                            placeholder="Service Provider Name"
                            inputValue={sericeProviderName}
                            setInputValue={setSericeProviderName}
                        />
                    </div>
                </div>

                <div className="row mt-2">

                    <div className='col-md-6'>
                        <FromFieldText
                            readOnly={true}
                            label="Contact Person Name" star="*" placeholder="Contact Person Name"
                            inputValue={contactName}
                            setInputValue={setContactName}
                        />
                    </div>
                    <div className="col-6">
                        <FromFieldText
                            readOnly={true}
                            star="*"
                            label="Contact Number" placeholder="Contact Number"
                            inputValue={contactNumber}
                            setInputValue={setContactNumber}
                        />
                    </div>
                </div>

                <div className="row mt-2">

                    <div className='col-md-6'>
                        <FromFieldText
                            readOnly={true}
                            label="Mail ID" star="*" placeholder="Mail Id"
                            inputValue={mailId}
                            setInputValue={setEmailId}
                        />
                    </div>
                    <div className="col-6">
                        <FromFieldText
                            readOnly={true}
                            star="*"
                            label="Mobile No" placeholder="Mobile no"
                            inputValue={mobileNo}
                            setInputValue={setMobileNo}
                        />
                    </div>
                </div>

                <div className="row mt-3">
                    <h6 className="text-center">List of Equipment</h6>
                    <table className="table table-bordered">
                        <thead className="text-center">
                        <tr>
                            <th>Sl #</th>
                            <th>TAG No</th>
                            <th>TAG Name</th>
                            <th>Item Description</th>
                        </tr>
                        </thead>
                        <tbody className="text-center">
                        {aInfo && aInfo.length > 0 ? (
                            aInfo.map((item, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.tag}</td>
                                    <td>{item.tag_name}</td>
                                    <td>{item.equipment_desc}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="4">No equipment data available</td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>

                <div className="row mt-3">
                    <div className="col-md-6">
                        <label>Brief description of the problem reported <span className="error">*</span></label>
                    </div>
                    <div className='col-md-6'>
                        <FromSpFieldTextAreaHari maxLength="2000" numRows="5"
                                                 readOnly={true}
                                                 inputValue={detailProblem}
                                                 setInputValue={setDetailProblem}
                        />
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-md-6">
                        <label>Brief description of the actual problem identified<span
                            className="error">*</span></label>
                    </div>
                    <div className='col-md-6'>
                        <FromSpFieldTextAreaHari maxLength="2000" numRows="5" readOnly
                                                 inputValue={detailProblemIdentified}
                                                 setInputValue={setDetailProblemIdentified}
                        />
                    </div>
                </div>

                <div>
                    <div className="row mt-3">
                        <div className="col-md-12">
                            <label>Upload Files of the Problem (if applicable)</label>
                            <br/>
                            <small className="form-text text-muted">Only JPEG, PNG, and PDF files are allowed.
                                Maximum
                                size: 5 MB. Maximum 5 files allowed at
                                once. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</small>
                            <input type="file" name="uploadFile" multiple/>
                        </div>
                    </div>
                    <div>
                        <div className="col-md-12 mt-3">
                            <label>Upload Screenshots of the Problem (if
                                applicable) &nbsp;&nbsp;&nbsp;&nbsp;</label>
                            <br/>
                            <small className="form-text text-muted">Only JPEG, PNG, and PDF files are allowed.
                                Maximum
                                size: 5 MB. Maximum 5 files allowed
                                once.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</small>
                            <input type="file" name='uploadScreenshot' multiple
                            />
                        </div>

                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-md-6">
                        <label> Any Materials Used <span className="error">*</span></label>
                        <span className="star error">  </span>
                    </div>
                    <div className="col-md-3">
                        <select disabled onChange={handleMaterial} name="materialUsed"
                                className="form-select form-select-sm" value={materialUsed}>
                            <option value="">Select Material Used or not</option>
                            <option value="Yes"
                                    selected={materialUsed === "Yes" ? "selected" : ''}>Yes
                            </option>
                            <option value="No"
                                    selected={materialUsed === "No" ? "selected" : ''}>No
                            </option>
                        </select>
                    </div>
                </div>

                {/* Conditionally render the table if materialUsed is "Yes" */}
                {materialUsed === "Yes" && (
                    <div className="row mt-3" style={{maxHeight: '350px', overflowY: 'auto'}}>
                        <h6 className="text-center">List of Material Used</h6>
                        <table className="table table-bordered">
                            <thead className="text-center">
                            <tr>
                                <th>Sl #</th>
                                <th>Date</th>
                                <th>Material Name</th>
                                <th>Part Number</th>
                                <th>Make</th>
                                <th>Model</th>
                                <th>Serial No</th>
                                <th>Qty</th>
                                <th>Cost</th>
                            </tr>
                            </thead>
                            <tbody className="text-center">
                            {materials.map((material, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td><input readOnly type="date" name="date" value={material.date}
                                               onChange={(e) => handleInputChange(index, e)}/></td>
                                    <td><input readOnly type="text" name="name" value={material.name}
                                               onChange={(e) => handleInputChange(index, e)}/></td>
                                    <td><input readOnly type="text" name="partNumber" value={material.partNumber}
                                               onChange={(e) => handleInputChange(index, e)}/></td>
                                    <td><input readOnly type="text" name="make" value={material.make}
                                               onChange={(e) => handleInputChange(index, e)}/></td>
                                    <td><input readOnly type="text" name="model" value={material.model}
                                               onChange={(e) => handleInputChange(index, e)}/></td>
                                    <td><input type="text" name="serialNo" value={material.serialNo}
                                               onChange={(e) => handleInputChange(index, e)}/></td>
                                    <td><input readOnly type="number" name="qty" value={material.qty}
                                               onChange={(e) => handleInputChange(index, e)}/></td>
                                    <td><input readOnly type="number" name="cost" value={material.cost}
                                               onChange={(e) => handleInputChange(index, e)}/></td>
                                </tr>
                            ))}
                            {/* Calculate and display totals */}
                            <tr>
                                <td colSpan="7" className="text-right"><strong>Total</strong></td>
                                <td>
                                    <strong>{materials.reduce((acc, material) => acc + Number(material.qty || 0), 0)}</strong>
                                </td>
                                <td>
                                    <strong>{materials.reduce((acc, material) => acc + Number(material.cost || 0), 0).toFixed(2)}</strong>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                )}


                <div className="row mt-3">
                    <div className="col-md-6">
                        <label>Invoice No<span className="error">*</span></label>
                    </div>
                    <div className='col-md-6'>
                        <FromFieldTextER
                            readOnly
                            placeholder="Invoice No"
                            inputValue={invoiceNo}
                            setInputValue={setInvoiceNo}
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-6">
                        <label>Invoice Date<span className="error">*</span></label>
                    </div>
                    <div className='col-md-6'>
                        <FromFieldDateHari
                            readOnly
                            label="Invoice Date" star="*" placeholder="YYYY-MM-DD"
                            inputValue={invoiceDate}
                            setInputValue={setInvoiceDate}
                        />
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-md-6">
                        <label>Invoice Amount with Tax<span className="error">*</span></label>
                    </div>
                    <div className='col-md-6'>
                        <FromFieldTextER
                            readOnly
                            placeholder="Invoice Amount with Tax"
                            inputValue={invoiceAmount}
                            setInputValue={setInvoiceAmount}
                        />
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-md-6">
                        <label>Next Service Due (if Any)<span
                            className="error">*</span></label>
                        <span className="star error"></span>
                    </div>
                    <div className="col-md-3">
                        <select disabled onChange={handleServices} name="nextServiceDue"
                                className="form-select form-select-sm">
                            <option value="">Select Service Due</option>
                            <option value="Yes" selected={nextServiceDue === "Yes"}>Yes</option>
                            <option value="No" selected={nextServiceDue === "No"}>No</option>
                        </select>
                    </div>
                </div>

                {showTextField && (
                    <div className="row mt-3">
                        <div className="col-md-6">
                            <label>If Yes, select the date<span className="error">*</span></label>
                        </div>
                        <div className="col-md-6">
                            <FromFieldDateHari
                                readOnly
                                maxLength={2000}
                                numRows={5}
                                className="form-control"
                                inputValue={nextServiceDate}
                                setInputValue={setNextServiceDate}
                                onChange={(e) => setNextServiceDate(e.target.value)}
                            />
                        </div>
                    </div>
                )}

                <div className="row mt-3">
                    <div className="col-md-6">
                        <label> Closing Note <span
                            className="error">*</span></label>
                        <span className="star error">  </span>
                    </div>
                    <div className="col-md-3">
                        <select disabled onChange={handleClosingNote} name="alternative_verification"
                                className="form-select form-select-sm">
                            <option value="">Select Closing Note</option>
                            <option value="Repair Completed - Asset Working" 
                            selected={closingNote === "Repair Completed - Asset Working" ? "selected" : ''}>Repair Completed - Asset Working
                            </option>
                            <option value="Recommended for Disposal"
                                    selected={closingNote === "Recommended for Disposal" ? "selected" : ''}>Recommended for Disposal
                            </option>
                        </select>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-md-12">

                    </div>

                </div>

                <div className="row mt-4 mb-2">

                    <div className="col-md-12 d-flex justify-content-center">

                        <a href={`/er-assessment-report?assignmentNos=${encodeURIComponent(encryptedAssignmentNO)}`}>
                            <button type="button" className="btn btn-danger btn-sm ms-3">
                                Edit Report
                            </button>
                        </a>
                    </div>


                </div>

            </div>
        </div>
    );
}

export default ERAssessmentViewReport;