import React,{useState,useEffect} from 'react';
import { useLocation } from "react-router-dom";
import { getTemproryTransferData, saveTemproryTransferData } from '../../../services/asset.service';
import FromFieldLabel from '../../../atoms/FromFieldLabel';
import FromFieldText from '../../../atoms/FromFieldText';
import FromFieldDate from '../../../atoms/FromFieldDate';
import FromFieldSelect from '../../../atoms/FromFieldSelect';

const TEXT_REGEX = /^[a-zA-Z0-9-_ ]{1,23}$/;
const NUMBER_REGEX = /^[0-9]{1,15}$/;
const getFormatedDate = function(dateString) {
    if(dateString!=null) {
        let dte = new Date(dateString);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        if (month < 10) {
          month = '0' + month;
        }if (day < 10) {
          day = '0' + day;
        }
        return day + '-' + month + '-' + year;
        //return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
    } else {
        return null;
    }   
  }

function TemproryTransfer() {
    const [tag, setTag] = useState(0);

    const [ErrTAG, setErrTag] = useState(false);
    const [errMsg, setErrMsg] = useState('');
	const [success, setSuccess] = useState(false);
    const [validName, setValidName] = useState(false);
    const [assetInfo, setAssetInfo] = useState({
        "financials_classification": "",
        "invoice_date": "",
        "supplier_name": "",
        "invoice_no": ""
    });

    var transferId='';
    const [transferInitiatedBy,setTransferInitiatedBy] = useState('');
    const [errTransferInitiatedBy,setErrTransferInitiatedBy] = useState('');

    const [initiationDate,setInitiationDate] = useState('');
    const [errInitiationDate,setErrInitiationDate] = useState('');

    const [transferApprovedBy,setTransferApprovedBy] = useState('');
    const [errTransferApprovedBy,setErrTransferApprovedBy] = useState('');

    const [approvalDate,setApprovalDate] = useState('');
    const [errApprovalDate,setErrApprovalDate] = useState('');

    const [transferType,setTransferType] = useState('');
    const [errTtransferType,setErrTransferType] = useState('');

    const [serviceProviderName,setServiceProviderName] = useState('');
    const [errServiceProviderName,setErrServiceProviderName] = useState('');

    const [address,setAddress] = useState('');
    const [errAddress,setErrAddress] = useState('');

    const [contactPersonName,setContactPersonName]= useState('');
    const [errContactPersonName,setErrContactPersonName]= useState('');

    const [contactNumber,setContactNumber]= useState('');
    const [errContactNumber,setErrContactNumber]= useState('');

    const [mailId,setMailId]= useState('');
    const [errMailId,setErrMailId]= useState('');

    const [mobileNumber,setMobileNumber]= useState('');
    const [errMobileNumber,setErrMobileNumber]= useState('');

    const [serviceUnder,setServiceUnder] = useState('');
    const [errServiceUnder,setErrServiceUnder] = useState('');

    const [expectedReturnDate,setExpectedReturnDate] = useState('');
    const [errExpectedReturnDate,setErrExpectedReturnDate] = useState('');

    const [actualReturnDate,setActualReturnDate] = useState('');
    const [errActualReturnDate,setErrActualReturnDate] = useState('');

    const [invoiceNumber,setInvoiceNumber] = useState('');
    const [errInvoiceNumber,setErrInvoiceNumber] = useState('');

    const [invoiceAmount,setInvoiceAmount] = useState('');
    const [errInvoiceAmount,setErrInvoiceAmount] = useState('');

    const [invoiceDate,setInvoiceDate] = useState('');
    const [errInvoiceDate,setErrInvoiceDate] = useState('');

    const [receiptRemark,setReceiptRemark] = useState('');
    const [errReceiptRemark,setErrReceiptRemark] = useState('');

    const [receiptDate,setReceiptDate] = useState('');
    const [errReceiptDate,setErrReceiptDate] = useState('');

    const [recivedBy,setRecivedBy] = useState('');
    const [errRecivedBy,setErrRecivedBy] = useState('');

    const [employeeId,setEmployeeId] = useState('');
    const [errEmployeeId,setErrEmployeeId] = useState('');

    const [employeeName,setEmployeeName] = useState('');
    const [errEmployeeName,setErrEmployeeName] = useState('');
    // const [expectedReturnDate,setExpectedReturnDate] = useState('');
    const [extensionDate,setExtensionDate] = useState('');
    const [errExtensionDate,setErrExtensionDate] = useState('');
    const [extendedReturnDate,setExtendedReturnDate] = useState('');
    const [errExtendedReturnDate,setErrExtendedReturnDate] = useState('');
    const [extensionReason,setExtensionReason] = useState('');
    const [errExtensionReason,setErrExtensionReason] = useState('');
    const [extensionApprovedBy,setExtensionApprovedBy] = useState('');
    const [errExtensionApprovedBy,setErrExtensionApprovedBy] = useState('');
    const [remarks,setRemarks] = useState('');
    const [errRemarks,setErrRemarks] = useState('');
    const [movementReason,setMovementReason] = useState('');

    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const Tag = query.get('tag'); 
    
    useEffect(() => {
        if(Tag!=='' && Tag!==null){
            getTagAssetInfo(Tag);
            //alert(Tag);
        }
        
    }, [])

    const getTagAssetInfo = function() {
        getTemproryTransferData(Tag).then(function(res){
            if(res?.data?.data) {
                let data = res.data.data;
                setAssetInfo({
                    "financials_classification": data?.financials_classification,
                    "invoice_date": data?.invoice_date,
                    "supplier_name": data?.supplier_name,
                    "invoice_no": data?.invoice_no
                });
                transferId = data?.id;
                setTag(tag);
                setServiceProviderName(data?.service_provider_name);
                setAddress(data?.address);
                setContactPersonName(data?.contact_person_name);
                setContactNumber(data?.contact_number);
                setMailId(data?.mail_id);
                setMobileNumber(data?.mobile_number);
                setServiceUnder(data?.service_under);
                setExpectedReturnDate(data?.expected_return_date);
                setActualReturnDate(data?.actual_return_date);
                setInvoiceNumber(data?.invoice_number);
                setInvoiceAmount(data?.invoice_amount);
                setInvoiceDate(data?.invoice_date);
                setReceiptRemark(data?.recipt_remark);
                setReceiptDate(data?.recipt_date);
                setRecivedBy(data?.recived_by);
                setTransferInitiatedBy(data?.initiated_by);
                setInitiationDate(data?.initiated_date);
                setTransferApprovedBy(data?.approved_by);
                setApprovalDate(data?.approved_date);
                setTransferType(data?.transfer_type);
                setEmployeeId(data?.employee_id);
                setEmployeeName(data?.employee_name);
                setExtensionDate(data?.date_of_extension);
                setExtendedReturnDate(data?.extended_date);
                setExtensionReason(data?.extension_reason);
                setExtensionApprovedBy(data?.extension_approved_by);
                setRemarks(data?.remarks);
            }
        })
    }

    const getTagInfo = function() {
        getTemproryTransferData(tag).then(function(res){
            if(res?.data?.data) {
                let data = res.data.data;
                setAssetInfo({
                    "financials_classification": data?.financials_classification,
                    "invoice_date": data?.invoice_date,
                    "supplier_name": data?.supplier_name,
                    "invoice_no": data?.invoice_no
                });
                transferId = data?.id;
                setTag(tag);
                setServiceProviderName(data?.service_provider_name);
                setAddress(data?.address);
                setContactPersonName(data?.contact_person_name);
                setContactNumber(data?.contact_number);
                setMailId(data?.mail_id);
                setMobileNumber(data?.mobile_number);
                setServiceUnder(data?.service_under);
                setExpectedReturnDate(data?.expected_return_date);
                setActualReturnDate(data?.actual_return_date);
                setInvoiceNumber(data?.invoice_number);
                setInvoiceAmount(data?.invoice_amount);
                setInvoiceDate(data?.invoice_date);
                setReceiptRemark(data?.recipt_remark);
                setReceiptDate(data?.recipt_date);
                setRecivedBy(data?.recived_by);
                setTransferInitiatedBy(data?.initiated_by);
                setInitiationDate(data?.initiated_date);
                setTransferApprovedBy(data?.approved_by);
                setApprovalDate(data?.approved_date);
                setTransferType(data?.transfer_type);
                setEmployeeId(data?.employee_id);
                setEmployeeName(data?.employee_name);
                setExtensionDate(data?.date_of_extension);
                setExtendedReturnDate(data?.extended_date);
                setExtensionReason(data?.extension_reason);
                setExtensionApprovedBy(data?.extension_approved_by);
                setRemarks(data?.remarks);
            }
        })
    }

    const handleSubmit = async (e) => {
		e.preventDefault();
		// if button enabled with JS hack 
		//const v1 = TEXT_REGEX.test(yearaddition);
    if (transferInitiatedBy ==='') {
        setErrTransferInitiatedBy('Transfer Initiated By is required'); setValidName(false);
            //return;
        }else{ setErrTransferInitiatedBy(''); setValidName(true); }

    if (serviceProviderName ==='') {
        setErrServiceProviderName('Asset in use Date is required'); setValidName(false);
			//return;
		}else{ setErrServiceProviderName(''); setValidName(true); }

    if (!NUMBER_REGEX.test(initiationDate)) {
			setErrInitiationDate('Depreciation Rate is required'); setValidName(false);
			//return;
		}else{ setErrInitiationDate(''); setValidName(true); }
        if (!validName) { alert(validName);
			return false;
		}
		try {  //alert('abc');
			//const response = saveData();
      saveData();
            /* saveData().then(res => {
            setSuccess(res.data.message); alert('a');
                elementRef.current.focus();
                }); */
			// TODO: remove console.logs before deployment
			//console.log(JSON.stringify(response?.data));
            //setSuccess("Successfully saved Single Asset");
			//clear state and controlled inputs 
			 setErrTag(''); setErrServiceProviderName(''); setErrAddress(''); setErrContactPersonName('');
             setErrContactNumber(''); setErrMailId(''); setErrMobileNumber(''); setErrServiceUnder('');
             setErrExpectedReturnDate(''); setErrActualReturnDate(''); setErrInvoiceNumber(''); setErrInvoiceAmount('');
             setErrInvoiceDate(''); setErrReceiptRemark(''); setErrReceiptDate(''); setErrRecivedBy(''); setErrTransferInitiatedBy('');
             setErrInitiationDate(''); setErrTransferApprovedBy(''); setErrApprovalDate(''); setErrTransferType('');
             setErrEmployeeId(''); setErrEmployeeName(''); setErrExtensionDate(''); setErrExtendedReturnDate(''); setErrExtensionReason('');
             setErrExtensionApprovedBy(''); setErrRemarks(''); setValidName(false);
			
		} catch (err) { //setSuccess(false); 
			if (!err?.data) { 
              //  console.log(err); 
			  //setErrMsg('No Server Response');
                setSuccess("Successfully saved Single Asset1");
			} else if (err.data?.status === 409) {
				setErrMsg('Username Taken');
			} else {
				setErrMsg('Insert/Update Failed');
			}
			//elementRef.current.focus();
		}
	}
    const saveData = function() {
        var data = {
            "id":transferId,
            "tag":tag,
            "service_provider_name":serviceProviderName,
            "address":address,
            "contact_person_name":contactPersonName,
            "contact_number":contactNumber,
            "mail_id":mailId,
            "mobile_number":mobileNumber,
            "service_under":serviceUnder,
            "expected_return_date":expectedReturnDate?.dbFormatedDate(),
            "actual_return_date":actualReturnDate?.dbFormatedDate(),
            "invoice_number":invoiceNumber,
            "invoice_amount":invoiceAmount,
            "invoice_date":invoiceDate?.dbFormatedDate(),
            "recipt_remark":receiptRemark,
            "recipt_date":receiptDate?.dbFormatedDate(),
            "recived_by":recivedBy,
            "initiated_by":transferInitiatedBy,
            "initiated_date":initiationDate?.dbFormatedDate(),
            "approved_by":transferApprovedBy,
            "approved_date":approvalDate?.dbFormatedDate(),
            "transfer_type":transferType,
            "employee_id":employeeId,
            "employee_name":employeeName,
            "date_of_extension":extensionDate?.dbFormatedDate(),
            "extended_date":extendedReturnDate?.dbFormatedDate(),
            "extension_reason":extensionReason,
            "extension_approved_by":extensionApprovedBy,
            "remarks":remarks
        }
        saveTemproryTransferData(data).then(function(res) {
            console.log(res);
        })
    }
    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h5>Recive Repair</h5>
                </div>
                {success ? (
				<div className="alert alert-success" role="alert"><i className="bi bi-check-circle-fill"></i>{success}</div>
			        ) : ( errMsg ? (<div className="alert alert-danger" role="alert"><i className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>):('')
                ) }
            </div>
            {/*<div className='row'>
                <div className='col'><h5 className='align-right'>TAG </h5></div>
                <div className='col'><input onChange={function(event){setTag(event.target.value)}} type="text" className="form-control"  placeholder='Enter the asset TAG'/></div>
                <div className='col'><button onClick={getTagInfo} className='btn btn-primary'> Search </button></div>
                </div>*/}
            <div className='row '>
            <div className='col-12'>&nbsp;</div>
            {
            (Tag!=='' && Tag!==null) ? <div className='row'><div className='col-10'></div> <div className='col-2'></div></div> : <div className='row'><div className='col'><h5 className='align-right'>TAG </h5></div>
            <div className='col'><input onChange={function(event){setTag(event.target.value)}} inputValue={assetInfo.tag} type="text" className="form-control"  placeholder='Enter the asset TAG' /><p></p></div>
            <div className='col'><button onClick={getTagInfo} className='btn btn-primary'> Search </button></div></div>
            }
            </div>
            
            <div className="row">
                <div className="col-6">
                    <FromFieldLabel  
                        label="Financial Classification" 
                        inputValue={assetInfo.financials_classification}
                        /> 
                </div>
                <div className="col-6">
                    <FromFieldLabel
                        label="Invoice Date" 
                        inputValue={getFormatedDate(assetInfo.invoice_date)}
                        /> 
                </div>
            </div>
            <div className="row border-bottom">
                <div className="col-6">
                    <FromFieldLabel  
                        label="Supplier Name" 
                        inputValue={assetInfo.supplier_name}
                        /> 
                </div>
                <div className="col-6">
                    <FromFieldLabel
                        label="Supplier Invoice No" 
                        inputValue={assetInfo.invoice_no} 
                        /> 
                </div>
            </div>
            <form onSubmit={handleSubmit} > 
            <div className="row">
                <div className="col-6">
                    <FromFieldText  star="*"
                        label="a. Transfer Initiated By" 
                        placeholder="Transfer Initiated By" 
                        inputValue={transferInitiatedBy}
                        setInputValue={setTransferInitiatedBy}
                        setErrorMsg={setErrTransferInitiatedBy}
                        /> 
                </div>
                <div className="col-6">
                    <FromFieldDate star="*"
                        label="Date of Initiation" 
                        inputValue={initiationDate}
                        setInputValue={setInitiationDate} 
                        setErrorMsg={setErrInitiationDate}
                        /> 
                </div>
            </div>

            <div className="row">
                <div className="col-6">
                    <FromFieldText  star="*"
                        label="a. Transfer Approved By" 
                        placeholder="Transfer Approved By" 
                        inputValue={transferApprovedBy}
                        setInputValue={setTransferApprovedBy}
                        setErrorMsg={setErrTransferApprovedBy}
                        /> 
                </div>
                <div className="col-6">
                    <FromFieldDate star="*"
                        label="Date of Approval" 
                        inputValue={approvalDate}
                        setInputValue={setApprovalDate} 
                        setErrorMsg={setErrApprovalDate}
                        /> 
                </div>
            </div>

            <div className="row bottom-border">
                <div className="col-6">
                <FromFieldSelect star="*"
                    label="a. Type of Transfer"
                    placeholder="Select Type of Transfer"
                    selectedValue={transferType}
                    setSelectedValue={setTransferType}
                    values={["Repair","Testing"]}
                    setErrorMsg={setErrTransferType}
                />
                </div>
                <div className="col-6">
                    
                </div>
            </div>

            <div className="row">
                <div className="col-6">
                    <FromFieldText  star="*"
                        label="i. Service Provider Name" 
                        placeholder="Service Provider Name" 
                        inputValue={serviceProviderName}
                        setInputValue={setServiceProviderName}
                        setErrorMsg={setErrServiceProviderName}
                        /> 
                </div>
                <div className="col-6">
                    <FromFieldText  star="*"
                        label="ii. Address" 
                        placeholder="Address" 
                        inputValue={address}
                        setInputValue={setAddress}
                        setErrorMsg={setErrAddress}
                        /> 
                </div>
            </div>

            <div className="row">
                <div className="col-6">
                    <FromFieldText  star="*"
                        label="iii. Contact Person Name" 
                        placeholder="Contact Person Name" 
                        inputValue={contactPersonName}
                        setInputValue={setContactPersonName}
                        setErrorMsg={setErrContactPersonName}
                        /> 
                </div>
                <div className="col-6">
                    <FromFieldText  star="*"
                        label="iv. Contact Number" 
                        placeholder="Contact Number" 
                        inputValue={contactNumber}
                        setInputValue={setContactNumber}
                        setErrorMsg={setErrContactNumber}
                        /> 
                </div>
            </div>

            <div className="row">
                <div className="col-6">
                    <FromFieldText  star="*"
                        label="v. Mail Id" 
                        placeholder="Mail Id" 
                        inputValue={mailId}
                        setInputValue={setMailId}
                        setErrorMsg={setErrMailId}
                        /> 
                </div>
                <div className="col-6">
                    <FromFieldText  star="*"
                        label="vi. Mobile Number" 
                        placeholder="Mobile Number" 
                        inputValue={mobileNumber}
                        setInputValue={setMobileNumber}
                        setErrorMsg={setErrMobileNumber}
                        /> 
                </div>
            </div>

            <div className="row bottom-border">
                <div className="col-6">
                    <FromFieldSelect star="*"
                        label="vii. Service Under"
                        placeholder="Select Service Under"
                        selectedValue={serviceUnder}
                        setSelectedValue={setServiceUnder}
                        values={["Waranty","AMC Coverage","Insurance Coverage","Out of Pocket","Not Applicable","Free Of Cost"]}
                        setErrorMsg={setErrServiceUnder}
                    />
                </div>
                <div className="col-6">
                    <FromFieldDate star="*"
                        label="vii Expected Date Of Return" 
                        inputValue={expectedReturnDate}
                        setInputValue={setExpectedReturnDate} 
                        setErrorMsg={setErrExpectedReturnDate}
                        />
                </div>
            </div>

            <div className="row">                
                <div className="col-6">
                    <FromFieldDate star="*"
                        label="viii Actual Date Of Return" 
                        inputValue={actualReturnDate}
                        setInputValue={setActualReturnDate} 
                        setErrorMsg={setErrActualReturnDate}
                        />
                </div>
                <div className="col-6">
                    <FromFieldText  star="*"
                        label="ix. Invoice No" 
                        placeholder="Invoice No" 
                        inputValue={invoiceNumber}
                        setInputValue={setInvoiceNumber}
                        setErrorMsg={setErrInvoiceNumber}
                        /> 
                </div>
            </div>

            <div className="row">
                <div className="col-6">
                    <FromFieldText  star="*"
                        label="x. Invoice Amount" 
                        placeholder="Invoice Amount" 
                        inputValue={invoiceAmount}
                        setInputValue={setInvoiceAmount}
                        setErrorMsg={setErrInvoiceAmount}
                        /> 
                </div>
                <div className="col-6">
                    <FromFieldDate star="*"
                        label="xi Invoice Date" 
                        inputValue={invoiceDate}
                        setInputValue={setInvoiceDate} 
                        setErrorMsg={setErrInvoiceDate}
                        />
                </div>                
            </div>

            <div className="row">
                <div className="col-6">
                    <FromFieldSelect star="*"
                        label="xii. Receipt Remark"
                        placeholder="Select Receipt Remark"
                        selectedValue={receiptRemark}
                        setSelectedValue={setReceiptRemark}
                        values={["Received with Working Condition",
                                "Received with Not Working Condition",
                                "Received in Damaged Condition",
                                "Received Unrepaired",
                                "Received as it is",
                                "Asset Lost"]}
                        setErrorMsg={setErrReceiptRemark}
                    />
                </div>
                <div className="col-6">
                    <FromFieldDate star="*"
                        label="xiii. Date of Receipt" 
                        inputValue={receiptDate}
                        setInputValue={setReceiptDate}
                        setErrorMsg={setErrReceiptDate} 
                        />
                </div>
            </div>
            <div className="row ">
                <div className="col-6">
                    <FromFieldText  star="*"
                        label="x. Recived By" 
                        placeholder="Recived By" 
                        inputValue={recivedBy}
                        setInputValue={setRecivedBy}
                        setErrorMsg={setErrRecivedBy}
                        /> 
                </div>
                <div className="col-6">
                </div>
            </div>
    

            <div className="row">
                <div className="col-6"></div>
                <div className="col-6"><button onClick={saveData} className='btn btn-primary'> SAVE </button></div>
            </div>
            </form>
        </div>
    )
}

export default TemproryTransfer