import React, {useEffect, useState} from 'react';
import {DataGrid} from '@mui/x-data-grid';
import {getAllGatePassList} from '../../services/asset.service'
import * as CryptoJS from "crypto-js";

function getFormatedDate(dateString) {
    if (dateString != null) {
        let dte = new Date(dateString);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }
        return year + '-' + month + '-' + day;
        //return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
    } else {
        return null;
    }
}


function GatePassList() {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    const columns = [
        {"field": "id", "editable": false, "hide": false, "headerName": "Sl No", "width": 130},
        {"field": "cat", "editable": false, "hide": false, "headerName": "CAT", "width": 130},
        {"field": "gatePassType", "editable": false, "hide": false, "headerName": "Gate Pass Type", "width": 130},
        {"field": "tag", "editable": false, "hide": false, "headerName": "TAG Number", "width": 130},
        {"field": "asset_description", "editable": false, "hide": false, "headerName": "Descriptions", "width": 230},
        {"field": "qty", "editable": false, "hide": false, "headerName": "Qty", "width": 230},
        {"field": "invoice_currency", "editable": false, "hide": false, "headerName": "Currency Type", "width": 230},
        {"field": "value_of_asset", "editable": false, "hide": false, "headerName": "Value", "width": 230},
        {"field": "invoice_no", "editable": false, "hide": false, "headerName": "Invoice No", "width": 230},
        {"field": "remark", "editable": false, "hide": false, "headerName": "Remarks", "width": 230},
        {"field": "employeeID", "editable": false, "hide": false, "headerName": "If employee, Employee ID", "width": 230},
        {"field": "costCenter", "editable": false, "hide": false, "headerName": "If employee, Cost Centre", "width": 230},
        {"field": "titles", "editable": false, "hide": false, "headerName": "Smt / Sri", "width": 230},
        {"field": "designation", "editable": false, "hide": false, "headerName": "Designation", "width": 230},
        {"field": "gatePassNo", "editable": false, "hide": false, "headerName": "Gate Pass Number", "width": 230},
        {
            "field": "gatePassDate", "editable": false, "hide": false, "headerName": "Gate Pass Date", "width": 250,
            renderCell: (params) => (
                <p>{getFormatedDate(params.row.gatePassDate)}</p>
            )
        },
        {"field": "vendorName", "editable": false, "hide": false, "headerName": "Vendor Company Name", "width": 230},
        {"field": "address", "editable": false, "hide": false, "headerName": "Material taken to (Address)", "width": 230},
        {"field": "destinationCity", "editable": false, "hide": false, "headerName": "Destination City", "width": 230},
        {"field": "destinationCountry", "editable": false, "hide": false, "headerName": "Destination Country", "width": 230},
        {
            "field": "expectedDate", "editable": false, "hide": false, "headerName": "Expected Date of Return", "width": 250,
            renderCell: (params) => (
                <p>{getFormatedDate(params.row.expectedDate)}</p>
            )
        },
        {"field": "reasonTakingOut", "editable": false, "hide": false, "headerName": "Reason for Taking Out", "width": 230},
        {"field": "status", "editable": false, "hide": false, "headerName": "Main Status", "width": 230},
        {"field": "sub_status", "editable": false, "hide": false, "headerName": "Sub Status", "width": 230},
    ];

    useEffect(() => {
        // encrypted data
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            // 'param_data':obj,
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = { 'token': EncryptData };

        // Show loader when the request starts
        setLoading(true);

        // Fetch division list
        getAllGatePassList(oData)
            .then((res) => {
                if (res?.data?.data?.length) {
                    setItems(res.data.data);
                }
            })
            .catch((error) => {
                console.error('Error fetching rfid list:', error);
            })
            .finally(() => {
                // Hide loader when the request completes (either success or failure)
                setLoading(false);
            });
    }, []);  // Empty dependency array means this effect runs once on mount


    // readDivision();
    return (
        <div className='container'>
            <div className='row'>
                <div className='col-md-6'><h5>List of Gate Pass</h5></div>

            </div>

            {loading ? (
                <div className="text-center">
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            ) : (
                <DataGrid
                    rows={items}
                    getRowId={(row) => row.id}
                    columns={columns}
                    pageSize={50}
                    autoHeight
                    rowsPerPageOptions={[50]}
                />
            )}

        </div>
    )
}

export default GatePassList
