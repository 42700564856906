import React from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function  FromFieldDateHari(props) {

  return (
    <div className="row">
        <div className="col-md-6" > 
            <DatePicker wrapperClassName="cutom-form-control" className="form-control"
                placeholderText={(props.placeholder)? props.placeholder : 'Select Date'}
                selected={props.inputValue} 
                dateFormat="yyyy-MM-dd"
                onChange={(date) => props.setInputValue(date)} 
                readOnly={props.readOnly}
                minDate={props.minDate}
                maxDate={props.maxDate}
            />
            <span className="invalid-feedback"> {props.setErrorMsg} </span>
        </div>
    </div>
  )
}

export default FromFieldDateHari;