import React,{useState,useEffect,useRef} from 'react'
import { useLocation } from "react-router-dom";
import FromFieldText from '../../../atoms/FromFieldText';
import { getDivisionList, getDepartmentList } from '../../../services/user.service';
import { getBranchTransferInfo, updateBranchTransfer } from '../../../services/transfer.service';
import  * as CryptoJS  from "crypto-js";

  Date.prototype.dbFormatedDate = function () {
    
    let year = this.getFullYear();
    let month = this.getMonth() + 1;
    let day = this.getDate();
    if (month < 10) {
      month = '0' + month;
    }if (day < 10) {
      day = '0' + day;
    }
    return year + '-' + month + '-' + day;
    //return `${this.getFullYear()}-${this.getMonth()+1}-${this.getDate()-1}`
  }
  function getFormatedDate(dateString) {
    if(dateString!==null) {
        let dte = new Date(dateString);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        if (month < 10) {
          month = '0' + month;
        }if (day < 10) {
          day = '0' + day;
        }
        return year + '-' + month + '-' + day;
        //return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
    } else {
        return null;
    }   
  }
  const TEXT_REGEX = /^[A-Za-z0-9-_/.@= ]{1,155}$/;
//const NUMBER_REGEX = /^[0-9]{1,15}$/;

function PendingTransferView() {
    
    const [assetInfo, setAssetInfo] = useState({});
    const [validName, setValidName] = useState(false);
    const [errMsg, setErrMsg] = useState('');
	const [success, setSuccess] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [tag, setTag] = useState(0);
    const [ErrTag, setErrTag] = useState(false);

    const [division,setDivision] = useState('');
    const [ErrDivision, setErrDivision] = useState(false);

    const [CatOfFlag,setCatOfFlag] = useState('');
    const [assetName,setAssetName] = useState('');
    const [purpose,setPurpose] = useState('');
    const [status,setStatus] = useState('');
    const [flagId,setFlagId] = useState('');
    const [invoiceDate,setInvoiceDate] = useState('');
    const [remarks,setRemarks] = useState('');

    const [newDepartmentId,setNewDepartmentId] = useState('');
    const [newDepartment,setNewDepartment] = useState('');
    const [existingDepartmentId,setExistingDepartmentId] = useState('');
    const [existingDepartment,setExistingDepartment] = useState('');

    const [ownershipId,setOwnershipId] = useState('');
    const [ErrOwnershipId,setErrOwnershipId] = useState(false);
    const [departments, setDepartments] = useState([]);

    const [divisions, setDivisions] = useState([]);
    //const [initiationDate,setInitiationDate] = useState('');
    const [financialsClassification,setFinancialsClassification] = useState('');
    //const [approvalDate,setApprovalDate] = useState('');
    //const [movementReason,setMovementReason] = useState('');


    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const Tag = query.get('tag'); 
    const elementRef = useRef();
    useEffect(() => {
        if(Tag!=='' && Tag!==null){
            
            const decryptedBytes = CryptoJS.AES.decrypt(Tag.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
            // Convert the decrypted bytes to a string
            const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
            const decryptedTag = decrypt_data.tag;
            console.log(decryptedTag);
            getTagAssetInfo(decryptedTag);
            //alert(Tag);
        }
        //encrypted data
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            //'param_data':{'company_id':company_value},
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData2 = {'token':EncryptData}
        
        getDivisionList(oData2).then(res=>{
            if(res?.data?.data?.length) setDivisions(res.data.data);
          });
        getDepartmentList(oData2).then(res=>{
            if(res?.data?.data?.length) setDepartments(res.data.data);
          });
    }, [])
    const getTagAssetInfo = function(Tag) {
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data':{'tag':Tag},
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token':EncryptData}
        getBranchTransferInfo(oData).then(function(res){
            console.log(res.data.data);
            if(res?.data?.data.length) {
                var Info = res.data.data[0]; 
                console.log(Info);

                setDivision(Info.existing_division);
                setCatOfFlag(Info.cat_of_flag);
                setAssetName(Info.asset_name);
                setPurpose(Info.purpose_of_grouping);
                setStatus(Info.status);
                setFlagId(Info.id);
                setExistingDepartmentId(Info.existing_ownership_id);
                setExistingDepartment(Info.existing_ownership);
                setNewDepartmentId(Info.new_ownership_id);
                setNewDepartment(Info.new_ownership);
                setRemarks(Info.remarks);
                setInvoiceDate(getFormatedDate(Info.invoice_date));
                setFinancialsClassification(Info.financials_classification);
                setTag(Info.tag);
            }
        })
    }
    
    const handleDivision = (e) => {
        let name= e.target.name;
        let value= e.target.value;
        setDivision(e.target.value);
    }
    const handleOwnership = (e) => {
        let name= e.target.name;
        let value= e.target.value;
        setOwnershipId(e.target.value);
    }
    const handleSubmit = async (e) => { 
		e.preventDefault(); var valid_Name=true;
		// if button enabled with JS hack
		//const v1 = TEXT_REGEX.test(division); 
        //alert(tag);
        if (!TEXT_REGEX.test(tag) && tag!==0) { 
			setErrTag('TAG is required'); valid_Name=false;
			//return;
		}else{ setErrTag('');  }
		
        if (!TEXT_REGEX.test(ownershipId)) {
			setErrOwnershipId('Control Department is required'); valid_Name=false;
			//return;
		}else{ setErrOwnershipId(''); }
        if (!TEXT_REGEX.test(division)) { alert('division');
			setErrDivision('Branch is required'); valid_Name=false;
			//return;
		}else{  setErrDivision(''); }
        //alert(valid_Name);
        if (valid_Name) { //alert('test1');
            saveData(); //submit form
		}else{  
            return false;
        }
    }

    const saveData = async () => {
        var obj = {

            'tag': tag,
            'flag_id': flagId,
            'flag_status': '6',
            'new_ownership_id':ownershipId,
            'new_division_id':division,
            'created_by': sessionStorage.getItem('LoggedInUserId'),
        };
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': obj,
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token': EncryptData}
        try {
            //setLoading('..'); // Set loading before sending API request
            updateBranchTransfer(oData).then(function (res) {
                setValidName(false);
                //setLoading(false); // Stop loading
                //console.log(res);
                setSuccess(res.data.message); //alert('a');
                //elementRef.current.focus();
            });
        } catch (err) {
            setSuccess(false);
            //setLoading(false); // Stop loading
            if (!err?.data1) {
                console.log(err);
                //setErrMsg('No Server Response');
                //setSuccess("Successfully saved Single Asset");
            } else if (err.data1?.status === 409) {
                //setErrMsg('Record already exist');
            } else {
                setErrMsg('Insert/Update Failed');
            }
            //elementRef.current.focus();
        }
    }
    return (
        <div className="container">
            
            <div className="row">
                <div className="col">
                    <h5>View Transfer Process</h5>
                </div>
                
            </div>
            { 
            (Tag!='' && Tag!=null) ? <div className='row'><div className='col-10'></div> <div className='col-2'><a href={'/pending-transfer' }><button  className='btn btn-secondary btn-sm'><i className="bi bi-arrow-left"></i> Back</button></a>&nbsp;</div></div> : <div className='row'><div className='col'><h5 className='align-right'>TAG </h5></div>
            <div className='col'><input onChange={function(event){setTag(event.target.value)}} inputValue={assetInfo.tag} type="text" className="form-control"  placeholder='Enter the asset TAG'/></div>
            <div className='col'></div></div>
            }  
            
            <div className='row'></div>
            <form onSubmit={handleSubmit} >
            <div className="row">
                <div className="col-6">
                    <FromFieldText  
                        label="TAG" 
                        inputValue={tag}
                        readOnly="readonly"
                        /> 
                </div><div className='col-6'></div>
                <div className="col-6">
                    
                </div>
            </div>
            
            <div className="row">
                <div className="col-6">
                    <FromFieldText 
                        label="Asset Name" 
                        inputValue={assetName}
                        readOnly="readonly"
                        /> 
                </div>
                <div className="col-6"></div>
                <div className="col-6">
                    <FromFieldText 
                        label="Allocation Status" 
                        inputValue={status} 
                        readOnly="readonly"
                        /> 
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <FromFieldText 
                        label="Invoice Date" 
                        inputValue={invoiceDate}
                        readOnly="readonly"
                        /> 
                </div><div className="col-6"></div>
                <div className="col-6">
                    <FromFieldText  
                        label="CAT of Flag" 
                        inputValue={CatOfFlag} 
                        readOnly="readonly"
                        /> 
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <FromFieldText  
                        label="Purpose" 
                        inputValue={purpose}
                        readOnly="readonly"
                        /> 
                </div><div className="col-6"></div>
                <div className="col-6">
                    <FromFieldText   
                        label="Remarks" 
                        inputValue={remarks} 
                        readOnly="readonly"
                        /> 
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                <FromFieldText
                        label="Existing Control Department" 
                        inputValue={existingDepartment} 
                        readOnly="readonly"
                        /> 
                </div><div className="col-6"></div>
                <div className="col-6">
                    
                </div>
            </div>
            <div className="row">
            <div className="col-6">
            <div className="row">
                <div className="col-md-6">
                <label>New Control Department </label>/{/*<span className="star error"> *</span>*/}
                </div>
                <div className="col-6">
                    <select onChange={handleOwnership} className="form-select form-select-sm">
                    <option value="">Select Control Department</option>
                        {departments && departments.length > 0 ? (
                            departments.map((option) => (
                            <option value={option.id} selected={(newDepartmentId==option.id)? "selected" : ''}>{option.department_name}</option>
                            ))
                        ) : (
                            <option value="">No Data</option>
                        )}
                    </select>
                    <span className="invalid-feedback"> {ErrOwnershipId} </span>
                        
                </div>
            </div>
            </div>
            <div className="col-6"></div>
            </div>
            <div className="row">
                <div className="col-6">
                <FromFieldText
                        label="Existing Branch" 
                        inputValue={sessionStorage.getItem('LoggedInDivisionName')} 
                        readOnly="readonly"
                        /> 
                </div><div className="col-6"></div>
                <div className="col-6">
                    
                </div>
            </div>
            <div className="row">
            <div className="col-6">
            {/*<div className="row">
                <div className="col-md-6">
                <label>New Branch </label>
                </div>
                <div className="col-6">
                    <select onChange={handleDivision} className="form-select form-select-sm">
                    <option value="">Select Branch</option>
                        {divisions && divisions.length > 0 ? (
                            divisions.map((option) => (
                            <option value={option.id} >{option.division_name}</option>
                            ))
                        ) : (
                            <option value="">No Data</option>
                        )}
                    </select>
                    <span className="invalid-feedback"> {ErrDivision} </span>
                        
                </div>
            </div>*/}
            </div>
            <div className="col-6"></div>
            </div>
            
            <div className="row">
            <div className="col-3"></div>
                <div className="col-3">
                { (Tag!=='' && Tag!==null) ? <div><button className="btn btn-primary btn-md"  disabled={disabled}>Update</button>&nbsp;&nbsp;<a href={'/pending-transfer' } className="btn btn-secondary btn-md">Cancel</a></div>
                 : <button id="btn-submit" className="btn btn-primary btn-md" disabled={disabled}> SAVE </button>
                 }  
                    </div>
                <div className="col-6"></div>
            </div>
            </form>
        </div>
    )
}

export default PendingTransferView


