import React, { useState,useEffect } from 'react';
import './atom.css';
import FromFieldText from './FromFieldText';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from 'react-modal';
import { getAuditLogChangeView } from '../services/master.service';
import  * as CryptoJS  from "crypto-js";

const TEXT_REGEX = /^[A-Za-z0-9-_/.@= ]{1,255}$/;
const TEXT_SPCL_REGEX = /^[A-Za-z0-9-_.,\/\[\]\-()"'+&'%$#@*!= ]{1,500}$/;
const AuditLogChangeView = ({ isLogId,isVersion, isOpen, onClose, onConfirm }) => {
  const [loading, setLoading] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  const [ErrSelectionModel, setErrSelectionModel] = useState('');
  const [items, setItems] = useState([]);

  useEffect(() => {
    const getTransferInfo = async function (log_id) {
      setLoading(true);
      let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
        'param_data': { 'log_id': log_id },
        'authToken': sessionStorage.getItem('LoggedInToken'),
      }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
      let oData1 = { 'token': EncryptData };
      try {
        const res = await getAuditLogChangeView(oData1);
        if (res?.data?.data.length) {
            console.log(res.data.data[0]);
          setItems(res.data.data[0]);
          
        }
      } catch (error) {
        // Handle error
        console.error("Error fetching transfer info:", error);
      } finally {
        setLoading(false);
      }
    }
    if (isLogId) {
      getTransferInfo(isLogId);
    }
  }, [isLogId]);

  const handleConfirm = async (e) => {
    e.preventDefault();
    var valid_Name = true;

    if (!valid_Name) { //alert(valid_Name);
      return false;
    } else { //alert(mon); alert(fy);
      onConfirm();
      onClose();
    }
  };
  const olddataObject = (items.old_values?JSON.parse(items.old_values):'');
  const olddataArray = Object.entries(olddataObject);
  const newdataObject = (items.new_values?JSON.parse(items.new_values):'');
  const newdataArray = Object.entries(newdataObject);
  
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Confirm Box"
      style={modalStyles}
    >
      <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
        <h5 class="modal-title">Differences Log Version</h5>
        <button type="button" class="btn-close" onClick={onClose}></button>
        </div>
        <div class="modal-body" style={modalBody}>
        <div className='row'>
        <div className='col-6 p-3'> 
        <table className='border border-secondary' width="100%"><tr className='border border-secondary p-1'><th width="50%">&nbsp;Activity </th><th width="50%"> { (items.event)?.replace(/\b\w/g, char => char.toUpperCase()) } </th></tr> 
        <tr className='border border-secondary'><th><strong>&nbsp;Version </strong></th><th> { (isVersion)?(isVersion-1):''} </th></tr>
        <tr className='border border-secondary'><th><strong>&nbsp;User Name </strong></th><th> {items.user_name} </th></tr>
        </table>
        <table className='border border-secondary mt-3' width="100%"><tr className="border border-secondary p-1"><th width="50%"><strong>&nbsp;Particulars </strong></th><th width="50%"> Values </th></tr>
        {olddataArray && olddataArray.length > 0 ? ( olddataArray.map(([key, value], index) => (
          <tr key={index} className={olddataArray[index] && olddataArray[index][1] != value ? 'btn-danger' : ''}>
            <td className="p-1" key={index}><strong>{key?.replace(/_/g, ' ')?.replace(/\b\w/g, char => char.toUpperCase()) }</strong></td><td> {value}</td></tr>
        )) ):''}
        </table>
        </div>
        <div className='col-6 p-3'>
        <table className='border border-secondary' width="100%"><tr className='border border-secondary p-1'>
          <th width="50%"><strong>&nbsp;Activity </strong></th><th width="50%"> { (items.event)?.replace(/\b\w/g, char => char.toUpperCase()) } </th></tr>
        <tr className='border border-secondary'><th><strong>&nbsp;Version </strong></th><th> {isVersion} </th></tr>
        <tr className='border border-secondary'><th><strong>&nbsp;User Name </strong></th><th> {items.user_name} </th></tr>
        </table>
        <table className="border border-secondary mt-3" width="100%">
            <tr className="border border-secondary p-1"><th width="50%"><strong>&nbsp;Particulars </strong></th><th width="50%"> Values </th></tr>
        {newdataArray && newdataArray.length > 0 ? ( newdataArray.map(([key, value], index) => ( ( (key != "company_id")?(
            <tr key={index} className={olddataArray[index] && olddataArray[index][1] != value ? 'btn-danger' : ''}>
            <td className="p-1" key={index}><strong>{key?.replace(/_/g, ' ')?.replace(/\b\w/g, char => char.toUpperCase()) }</strong></td><td> {value}</td></tr>):'')
        )) ):''}
        </table>
        </div>
        </div>
        </div>
        <div class="modal-footer mt-10">
        <div className='col-10'></div>
        <div className='col-2 pt-3'>
        <button class="btn btn-secondary btn-sm" onClick={onClose}> Close </button>&nbsp;&nbsp;
        </div>
        
        
      </div>
      </div>
      </div>
    </Modal>
  );
};

const modalStyles = {
  content: {
    width: '1000px',
    min_height: '600px',
    margin: 'auto',
  },
};
const  modalBody ={
    content: {
        position: 'relative',
        flex: '1 1 auto',
        padding: '10px !important'
    },
}
export default AuditLogChangeView;