import React, {useEffect, useState} from 'react';
import {DataGrid} from '@mui/x-data-grid';
import {getAssignedTickets} from "../../services/tickets.service";
import * as CryptoJS from "crypto-js";
import TicketDeatilsPopUpBox from "../../atoms/TicketDeatilsPopUpBox";
import TicketStatusUpdatePopUpBox from "../../atoms/TicketStatusUpdatePopUpBox";
import TicketAssignPopUpBox from "../../atoms/TicketAssignPopUpBox";

function getFormatedDateTime(dateString) {
    if (dateString != null) {
        let dte = new Date(dateString);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        let hours = dte.getHours();
        let minutes = dte.getMinutes();
        let seconds = dte.getSeconds();

        // Pad single digit month, day, hours, minutes, and seconds with leading zero
        month = month < 10 ? '0' + month : month;
        day = day < 10 ? '0' + day : day;
        hours = hours < 10 ? '0' + hours : hours;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        seconds = seconds < 10 ? '0' + seconds : seconds;

        return day + '-' + month + '-' + year + ' ' + hours + ':' + minutes + ':' + seconds;
    } else {
        return null;
    }
}

function ViewAssignedTickets() {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [ticketCode, setTicketCode] = useState('');
    const [ticketAssignedTo, setTicketAssignedTo] = useState('');
    const [ticketStatus, setTicketStatus] = useState('');
    const [isTicketStatusOpen, setIsTicketStatusOpen] = useState(false);
    const [isTicketAssignOpen, setIsTicketAssignOpen] = useState(false);
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);

    useEffect(() => {
        getTicketDetailInfo();
    }, []);

    const getTicketDetailInfo = async function () {
        setLoading(true);

        //encrypted data
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'authToken': sessionStorage.getItem('LoggedInToken', 'LoggedInUserId'),
            'param_data': {'ticket_assigned_to': sessionStorage.getItem('LoggedInUserId', 'LoggedInToken')},
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

        let oData = {'token': EncryptData};

        // Set loading to true before making the request
        setLoading(true);

        // Fetch data
        getAssignedTickets(oData)
            .then(res => {
                if (res?.data?.data?.length) {
                    setItems(res.data.data);
                }
            })
            .finally(() => {
                // Set loading to false after the request is completed (success or failure)
                setLoading(false);
            });

    }

    const handleConfirmOpen = async (ticketCode) => {
        setTicketCode(ticketCode);
        setIsConfirmOpen(true);
    };

    const handleConfirmClose = () => {
        setIsConfirmOpen(false);
    };


    const handleTicketStatusOpen = async (ticketCode, ticketStatus) => {
        setTicketCode(ticketCode);
        setTicketStatus(ticketStatus);
        setIsTicketStatusOpen(true);
    };

    const handleTicketStatusClose = () => {
        setIsTicketStatusOpen(false);
        getTicketDetailInfo();
    };

    const handleTicketAssignOpen = async (ticketCode, ticketAssignedTo, ticketStatus) => {
        setTicketCode(ticketCode);
        setTicketAssignedTo(ticketAssignedTo);
        setTicketStatus(ticketStatus);
        setIsTicketAssignOpen(true);
    };

    const handleTicketAssignClose = () => {
        setIsTicketAssignOpen(false);
        getTicketDetailInfo();
    };


    const columns =  [
        {
            "field": "ticket_code", "editable": false, "hide": false, "headerName": "Ticket Code", "width": 180,
            renderCell: (params) => (
                <div>
                    <button className="btn btn-primary btn-md text-right"
                            onClick={() => handleConfirmOpen(params.row.ticket_code)}>
                        {params.row.ticket_code}
                    </button>
                </div>
            )
        },

        {
            "field": "assignedToName",
            "editable": false,
            "hide": false,
            "headerName": "Assigned To Name",
            "width": 250,
            renderCell: (params) => (
                <div>
                    <button
                        className="btn btn-danger btn-sm text-right"
                        onClick={() => handleTicketAssignOpen(params.row.ticket_code, params.row.assignedToName, params.row.ticket_status)}>
                        {params.row.assignedToName}
                    </button>
                </div>

            )
        },

        {field: "assignedByName", hide: false, headerName: "Assigned By Name", width: 250},

        {
            "field": "ticket_status",
            "editable": false,
            "hide": false,
            "headerName": "Ticket Status",
            "width": 200,
            renderCell: (params) => (
                <div>
                    {params.row.ticket_status === 'Open' ? (
                        <button
                            className="btn btn-danger btn-sm text-right"
                            onClick={() => handleTicketStatusOpen(params.row.ticket_code, params.row.ticket_status)}>
                            {params.row.ticket_status}
                        </button>
                    ) : ''}

                    {params.row.ticket_status === 'Assigned' ? (
                        <button
                            className="btn btn-info btn-sm text-right"
                            onClick={() => handleTicketStatusOpen(params.row.ticket_code, params.row.ticket_status)}>
                            {params.row.ticket_status}
                        </button>
                    ) : ''}

                    {params.row.ticket_status === 'Comments added by agent' ? (
                        <button
                            className="btn btn-primary btn-md text-right"
                            onClick={() => handleTicketStatusOpen(params.row.ticket_code, params.row.ticket_status)}>
                            {params.row.ticket_status}
                        </button>
                    ) : ''}

                    {params.row.ticket_status === 'Comments added by user' ? (
                        <button
                            className="btn btn-primary btn-md text-right"
                            onClick={() => handleTicketStatusOpen(params.row.ticket_code, params.row.ticket_status)}>
                            {params.row.ticket_status}
                        </button>
                    ) : ''}

                    {params.row.ticket_status === 'Completed and closed' ? (
                        <button
                            className="btn btn-success btn-sm text-right"
                            onClick={() => handleTicketStatusOpen(params.row.ticket_code, params.row.ticket_status)}>
                            {params.row.ticket_status}
                        </button>
                    ) : ''}

                    {params.row.ticket_status === 'Closed on user request' ? (
                        <button
                            className="btn btn-success btn-sm text-right"
                            onClick={() => handleTicketStatusOpen(params.row.ticket_code, params.row.ticket_status)}>
                            {params.row.ticket_status}
                        </button>
                    ) : ''}

                    {params.row.ticket_status === 'Cancelled' ? (
                        <button
                            className="btn btn-dark btn-sm text-right"
                            onClick={() => handleTicketStatusOpen(params.row.ticket_code, params.row.ticket_status)}>
                            {params.row.ticket_status}
                        </button>
                    ) : ''}

                    {params.row.ticket_status === 'Deferred' ? (
                        <button
                            className="btn btn-secondary btn-sm text-right"
                            onClick={() => handleTicketStatusOpen(params.row.ticket_code, params.row.ticket_status)}>
                            {params.row.ticket_status}
                        </button>
                    ) : ''}

                </div>

            )
        },

        {
            "field": "created_at", "editable": true, "hide": false, "headerName": "Assign Date", "width": 170,
            renderCell: (params) => (
                <p>{getFormatedDateTime(params.row.created_at)}</p>
            )
        },
    ];


    return (
        <div className='container'>
            <div className='col-md-6'><h5>View My Assigned Ticket List</h5></div>
            {loading ? (
                <div className="text-center">
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            ) : (
                <DataGrid
                    rows={items}
                    getRowId={(row) => row.id}
                    columns={columns}
                    pageSize={50}
                    autoHeight
                    rowsPerPageOptions={[50]}
                />
            )}
            <TicketDeatilsPopUpBox
                ticketCode={ticketCode}
                isOpen={isConfirmOpen}
                onClose={handleConfirmClose}
            />
            <TicketStatusUpdatePopUpBox
                ticketCode={ticketCode}
                ticketStatus={ticketStatus}
                isOpen={isTicketStatusOpen}
                onClose={handleTicketStatusClose}
            />

            <TicketAssignPopUpBox
                ticketCode={ticketCode}
                ticketAssignedTo={ticketAssignedTo}
                ticketStatus={ticketStatus}
                isOpen={isTicketAssignOpen}
                onClose={handleTicketAssignClose}
            />

        </div>
    )
}

export default ViewAssignedTickets;
