import React from 'react';

function allocationLogView(props) {
    var block = props.show? "display-block": "";
    
    var data = [...props.data];
    return (
        <div className={block +" custom-modal width-90 height-90"}>
            <div className="custom-close" > <span onClick={ ()=>{props.showsetter(false)} } className="bi bi-x-circle-fill"></span> </div>
            <div className="title">
                <h5>{props.title}</h5>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>
                            <input type="text" className="form-control" value="" placeholder="Name"/>
                        </th>
                        <th>
                            <input type="text" className="form-control" value="" placeholder="Number"/>
                        </th>
                        <th>
                            <input type="text" className="form-control" value="" placeholder="Mail Id"/>
                        </th>
                        <th>
                            <input type="text" className="form-control" value="" placeholder="Access for Department"/>
                        </th>
                        <th>
                            
                            </th>
                    </tr>
                    
                </thead>
                
            </table>
        </div>
    )
}

export default allocationLogView