// CustomModal.js
import React from 'react';
import Modal from 'react-modal';
import { Document, Page } from 'react-pdf';

Modal.setAppElement('#root'); // Set the root element for accessibility

const CustomModal = ({ isOpen, onRequestClose, content, contentType }) => {
  return (<div className='col-12 mt-3'>
    <Modal 
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Image/PDF Modal"
    > <div className='col-12 mt-3 text-right'><button className='btn btn-secondary btn-sm' onClick={onRequestClose}>Close</button></div>
      <div className='col-12 text-center'>
      {contentType === 'image' && <img src={content} alt="Modal Content" />}
      {contentType === 'pdf' && (<div>
        <Document file={content}>
          <Page pageNumber={1} />
        </Document></div>
      )}
      </div>
    </Modal></div>
  );
};

export default CustomModal;