import React,{useState,useEffect} from 'react';
import { useLocation } from "react-router-dom";
import FromFieldLabel from '../../atoms/FromFieldLabel';
//import FromFieldText from '../../atoms/FromFieldText';


import "./StpiForm.css";
import { saveSTPI,getSTPI } from '../../services/asset.service';
//import FromFieldDate from '../../atoms/FromFieldDate';
//import FromFieldSelect from '../../atoms/FromFieldSelect';
/*
Date.prototype.dbFormatedDate = function () {
  return `${this.getFullYear()}-${this.getMonth()+1}-${this.getDate()}`
}
*/
function getFormatedDate(dateString) {
  if(dateString!=null) {
      var dte = new Date(dateString);
      return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
  } else {
      return null;
  }   
}

function StpiForm() {
  const [tag, setTag] = useState(0);
  const [assetInfo, setAssetInfo] = useState([]);
  const [stpiInfo, setStpiInfo] = useState([]);
  const [bondDate, setBondDate] = useState('');
  const [debondDate, setDebondDate] = useState('');
  const [approvalDate, setApprovalDate] = useState('');
  const [bondNumber,setBondNumber] = useState('');
  const [assetType,setAssetType] = useState('');
  const [transportClause,setTransportClause] = useState('');
  const [importType,setImportType] = useState('');
  const [cifApprovalValue,setCIFApprovalValue] = useState('');
  const [stpiApprovalNo,setSTPIApprovalNo] = useState('');
  const [assessedValue,setAssessedValue] = useState('');
  const [customDutyForgone,setCustomDutyForgone] = useState('');
  const [totalDutyForgone,setTotalDutyForgone] = useState('');
  const [igstForgone,setIGSTForgone] = useState('');
  const [debondingRefNo,setDebondingRefNo] = useState('');

  const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const Tag = query.get('tag'); 
    const Mode = query.get('mode'); 
    useEffect(() => {
        if(Tag!=='' && Tag!==null){
            getTagAssetInfo(Tag);
            //alert(Tag);
        }
        
    }, [])
    const getTagAssetInfo = function(Tag) {
      getSTPI(Tag).then(function(res){
          if(res?.data?.data) {
            var {stpiInfo,assetInfo} = res.data.data;
            //console.log(assetInfo);
            setAssetInfo(assetInfo);
            setStpiInfo(stpiInfo); 
              
                            
          }
      });
    }
    const getTagInfo = function() {
      getSTPI(tag).then(function(res){
        if(res?.data?.data) {
          var {stpiInfo,assetInfo} = res.data.data;
          setAssetInfo(assetInfo);
          setStpiInfo(stpiInfo);
          /*if(stpiInfo) { 
            setTag(stpiInfo.tag);
            setBondDate((new Date(stpiInfo.date_of_bond)));
            setDebondDate((new Date(stpiInfo.debond_date)));
            setApprovalDate((new Date(stpiInfo.stpi_approval_date)));
            setBondNumber(stpiInfo.bond_number);
            setAssetType(stpiInfo.asset_type);
            setTransportClause(stpiInfo.transport_clause);
            setImportType(stpiInfo.import_type);
            setCIFApprovalValue(stpiInfo.approval_value_cif);
            setSTPIApprovalNo(stpiInfo.stpi_approval_number);
            setAssessedValue(stpiInfo.assessed_value);
            setCustomDutyForgone(stpiInfo.custom_duty_forgone);
            setTotalDutyForgone(stpiInfo.total_duty_forgone);
            setIGSTForgone(stpiInfo.igst_forgone);
            setDebondingRefNo(stpiInfo.debond_ref_number);
          }*/
        }

      })
    }

  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <h5>STPI Form For Single Asset Updation</h5>
        </div>
      </div>
      {
            (Tag!='' && Tag!=null) ? <div className='row'><div className='col-10'></div> <div className='col-2'><a href={'/stpi?mode=edit&tag='+ Tag }><button  className='btn btn-info btn-sm'>Edit</button></a></div></div> : <div className='row'><div className='col'><h5 className='align-right'>TAG </h5></div>
            <div className='col'><input onChange={function(event){setTag(event.target.value)}} inputValue={assetInfo.tag} type="text" className="form-control"  placeholder='Enter the asset TAG'/></div>
            <div className='col'></div></div>
            }
            <div className='row'>
                <div className='col'><h5 className='align-right'>&nbsp; </h5></div>
            </div>

      {/*<div className='row'>
          <div className='col'><h5 className='align-right'>TAG </h5></div>
          <div className='col'><input onChange={function(event){setTag(event.target.value)}} type="text" className="form-control"  placeholder='Enter the asset TAG'/></div>
          <div className='col'><button onClick={getTagInfo} className='btn btn-primary'> Search </button></div>
      </div>
  */}
      <div className="row">
        <div className="col-6">
          <FromFieldLabel  
            label="Financial Classification" 
            inputValue={assetInfo.financials_classification}
          /> 
        </div>
        <div className="col-6">
         <FromFieldLabel
            label="Date of Capitalization" 
            inputValue={getFormatedDate(assetInfo.asset_in_use_date)}
          /> 
        </div>
      
      </div>

      <div className="row bottom-border">
        <div className="col-6">
          <FromFieldLabel  
            label="Supplier Name" 
            inputValue={assetInfo.supplier_name}
          /> 
        </div>
        <div className="col-6">
         <FromFieldLabel
            label="Supplier Invoice No" 
            inputValue={assetInfo.invoice_no} 
          /> 
        </div>
      
      </div>

      <div className="row">
        <div className="col-6">
        <FromFieldLabel  
            label="a. Bond Number" 
            //placeholder="Bond Number" 
            inputValue={stpiInfo.bond_number} 
            //setInputValue={setBondNumber} 
          /> 
        </div>
        <div className="col-6">
          <FromFieldLabel
            label="b. Date of Bonding"
             inputValue={stpiInfo.date_of_bond} 
            //setInputValue={setBondDate} 
          /> 
        </div>
      
      </div>

      <div className="row">
        <div className="col-6">
          <FromFieldLabel 
              label="c. Type of Asset"
              //setSelectedValue={setAssetType}
              //selectedValue={assetType}
              //values={["Bonded","Outright Purchase","Others"]}
              inputValue={stpiInfo.asset_type} 
            />
        </div>
        <div className="col-6">
          <FromFieldLabel 
              label="d. Transport Clause"
              //setSelectedValue={setTransportClause}
              //selectedValue={transportClause}
              //values={["FO","CIF C&I","C&F"]}
              inputValue={stpiInfo.transport_clause} 
            />
        </div>
      
      </div>

      <div className="row">
        <div className="col-6">
          <FromFieldLabel 
              label="e. Type of Import"
              //setSelectedValue={setImportType}
              //selectedValue={importType}
              //values={["Loan Basis","Lease Basis","Free of Charge","Outright Purchase","Other"]}
              inputValue={stpiInfo.import_type} 
            />
        </div>
        <div className="col-6">
         <FromFieldLabel
            label="f. Approval Value in CIF" 
            //placeholder="Approval Value in CIF" 
            inputValue={stpiInfo.approval_value_cif} 
            //setInputValue={setCIFApprovalValue} 
          /> 
        </div>
      
      </div>

      <div className="row">
        <div className="col-6">
          <FromFieldLabel  
            label="g. STPI Approval No"
            //placeholder="STPI Approval No" 
            inputValue={stpiInfo.stpi_approval_number} 
            //setInputValue={setSTPIApprovalNo} 
          /> 
        </div>
        <div className="col-6">
          <FromFieldLabel
              label="h. STPI Approval Date"
              inputValue={stpiInfo.stpi_approval_date} 
              //setInputValue={setApprovalDate} 
            /> 
        </div>
      
      </div>

      <div className="row">
        <div className="col-6">
          <FromFieldLabel  
            label="i. Assessed Value"
            //placeholder="Assessed Value" 
            inputValue={stpiInfo.assessed_value} 
            //setInputValue={setAssessedValue} 
          /> 
        </div>
        <div className="col-6">
         <FromFieldLabel
            label="j. Customs Duty Forgone"
            //placeholder="Customs Duty Forgone" 
            inputValue={stpiInfo.custom_duty_forgone} 
            //setInputValue={setCustomDutyForgone} 
          /> 
        </div>
      
      </div>

      <div className="row bottom-border">
        <div className="col-6">
          <FromFieldLabel  
            label="k. Total Duty Forgone"
            //placeholder="Total Duty Forgone" 
            inputValue={stpiInfo.total_duty_forgone} 
            //setInputValue={setTotalDutyForgone} 
          /> 
        </div>
        <div className="col-6">
         <FromFieldLabel
            label="l. IGST Forgone"
            //placeholder="IGST Forgone" 
            inputValue={stpiInfo.igst_forgone} 
            //setInputValue={setIGSTForgone} 
          /> 
        </div>
      
      </div>



      <div className="row">
        <div className="col-6">
          <FromFieldLabel  
            label="i. Debonding Ref No"
            //placeholder="Debonding Ref No" 
            inputValue={stpiInfo.debond_ref_number} 
            //setInputValue={setDebondingRefNo} 
          /> 
        </div>
        <div className="col-6">

         <FromFieldLabel
            label="ii. Debonding Date" 
            inputValue={stpiInfo.debond_date} 
            //setInputValue={setDebondDate} 
          /> 
        </div>
      
      </div>
      <div className="row">
        <div className="col-6"></div>
        <div className="col-6">
           {/* <button onClick={saveData} className='btn btn-primary'> SAVE </button>*/}
            </div>
      </div>


   
    </div>
  )
}

export default StpiForm;

