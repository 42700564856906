import React,{useState,useEffect} from 'react';
import { useLocation } from "react-router-dom";
import { getAssetDisposal, saveAssetDisposal } from '../../../services/asset.service';
import FromFieldLabel from '../../../atoms/FromFieldLabel';
import FromFieldText from '../../../atoms/FromFieldText';
import FromFieldDate from '../../../atoms/FromFieldDate';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import  * as CryptoJS  from "crypto-js";
Date.prototype.dbFormatedDate = function () {
    
    let year = this.getFullYear();
    let month = this.getMonth() + 1;
    let day = this.getDate();
    if (month < 10) {
      month = '0' + month;
    }if (day < 10) {
      day = '0' + day;
    }
    return year + '-' + month + '-' + day;
    //return `${this.getFullYear()}-${this.getMonth()+1}-${this.getDate()-1}`
  }
  function getFormatedDate(dateString) {
    if(dateString!==null) {
        let dte = new Date(dateString);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        if (month < 10) {
          month = '0' + month;
        }if (day < 10) {
          day = '0' + day;
        }
        return year + '-' + month + '-' + day;
        //return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
    } else {
        return null;
    }   
  }
function AssetDisposal() {

    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const Tag = query.get('tag'); 
    const Mode = query.get('mode'); 
    useEffect(() => {
        if(Tag!=='' && Tag!==null){

            const decryptedBytes = CryptoJS.AES.decrypt(Tag.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
            // Convert the decrypted bytes to a string
            const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
            const decryptedRoleid = decrypt_data.tag;

            getTagAssetInfo(decryptedRoleid);
            //alert(Tag);
        }
        
    }, []);
    const getTagAssetInfo = function() {
        getAssetDisposal(Tag).then(function(result) {
            setAssetInfo(result?.data?.data);
            // assetInfo(); 
        }).catch(function(error) {
            console.log(error);
        });
    }
    const getTagInfo = function() {
        getAssetDisposal(tag).then(function(result) {
            setAssetInfo(result?.data?.data);
            // assetInfo(); 
        }).catch(function(error) {
            console.log(error);
        });
    }

    var buyerInfoObj = {
        'name' : '',
        'address' : '',
        'pan' : '',
        'gstn' : '',
        'priceQuoted' : '',
        'quotedRecivedDate' : '',
        'quotedExpireDate' : '',

    }

    const [tag, setTag] = useState(0);
    const [buyer, setBuyer] = useState([]);
    const [assetInfo, setAssetInfo] = useState({});
    const [saleInitiatedBy,setSaleInitiatedBy] = useState('');
    const [initiationDate,setInitiationDate] = useState('');
    const [saleAuthorizedBy,setSaleAuthorizedBy] = useState('');
    const [authorizationDate,setAuthorizationDate] = useState('');
    const [disposalReason,setDisposalReason] = useState('');
    const [finalSaleValue,setFinalSaleValue] = useState('');
    const [saleDate,setSaleDate] = useState('');
    const [finalBuyerName,setFinalBuyerName] = useState('');
    const [finalApprovalDate,setFinalApprovalDate] = useState('');
    const [finalApprovalBy,setFinalApprovalBy] = useState('');

    const setBuyerData = function(index,value,key) {
        var tempDate = [...buyer];
        if(tempDate[index] === undefined) tempDate[index] = {key:value};
        tempDate[index][key] = value;
        setBuyer(tempDate);
    }

    const saveData = function(event) {
        
        var payLoad = {
            "tag" : tag,
            "buyer" : JSON.stringify(buyer),
            "sale_initiated_by" : saleInitiatedBy,
            "initiation_date" : initiationDate?.dbFormatedDate(),
            "sale_authorized_by" : saleAuthorizedBy,
            "authorization_date" : authorizationDate?.dbFormatedDate(),
            "disposal_reason" : disposalReason,
            "final_sale_value" : finalSaleValue,
            "sale_date" : saleDate?.dbFormatedDate(),
            "final_buyer_name" : finalBuyerName,
            "final_approval_date" : finalApprovalDate?.dbFormatedDate(),
            "final_approval_by" : finalApprovalBy
        }

        saveAssetDisposal(payLoad).then(function(result) {
            console.log(result);
        }).catch(function(error) {
            console.log(error);
        })
    }
    
    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h5>Single Asset Disposal Form</h5>
                </div>
            </div>
            <div className='row'>
            {
            (Tag!=='' && Tag!==null) ? <div className='row'><div className='col-10'></div> <div className='col-2'></div></div> : <div className='row'><div className='col'><h5 className='align-right'>TAG </h5></div>
            <div className='col'><input onChange={function(event){setTag(event.target.value)}} inputValue={assetInfo.tag} type="text" className="form-control"  placeholder='Enter the asset TAG' /></div>
            <div className='col'><button onClick={getTagInfo} className='btn btn-info btn-md'><i className="bi bi-search"></i> Search </button></div></div>
            }
            </div>
            <div className="row">
                <div className="col-6">
                    <FromFieldLabel  
                        label="Financial Classification" 
                        inputValue={assetInfo.financials_classification}
                        /> 
                </div>
                <div className="col-6">
                    <FromFieldLabel
                        label="Invoice Date" 
                        inputValue={getFormatedDate(assetInfo.invoice_date)}
                        /> 
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <FromFieldLabel  
                        label="Supplier Name" 
                        inputValue={assetInfo.supplier_name}
                        /> 
                </div>
                <div className="col-6">
                    <FromFieldLabel
                        label="Supplier Invoice No" 
                        inputValue={assetInfo.invoice_no} 
                        /> 
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <FromFieldLabel  
                        label="Capitalized Value" 
                        inputValue={assetInfo.value_of_asset}
                        /> 
                </div>
                <div className="col-6">
                    <FromFieldLabel
                        label="Acc Dep upto Date" 
                        inputValue={""}
                        /> 
                </div>
            </div>
            <div className="row bottom-border">
                <div className="col-6">
                    <FromFieldLabel  
                        label="WDV Value on Date" 
                        inputValue={""}
                        /> 
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <FromFieldText  
                        label="a. Sale Initiated By" 
                        placeholder="Sale Initiated By" 
                        inputValue={saleInitiatedBy}
                        setInputValue={setSaleInitiatedBy}
                        /> 
                </div>
                <div className="col-6">
                    <FromFieldDate
                        label="b Date of Initiation" 
                        inputValue={initiationDate}
                        setInputValue={setInitiationDate} 
                        /> 
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <FromFieldText  
                        label="c. Sale Authorized By" 
                        placeholder="Sale Authorized By" 
                        inputValue={saleAuthorizedBy}
                        setInputValue={setSaleAuthorizedBy}
                        /> 
                </div>
                <div className="col-6">
                    <FromFieldDate
                        label="d Date of Authorization" 
                        inputValue={authorizationDate}
                        setInputValue={setAuthorizationDate} 
                        /> 
                </div>
            </div>
            <div className="row bottom-border">
                <div className="col-6">
                    <FromFieldText  
                        label="e. Reason for Disposal" 
                        placeholder="Reason for Disposal" 
                        inputValue={disposalReason}
                        setInputValue={setDisposalReason}
                        /> 
                </div>
                <div className="col-6">
                    
                </div>
            </div>
            <div className='row form-fields'>
                <div className='col-3'>
                    <label>Buyer Information</label>
                </div>
                <div className='col-3'>
                    <label> Buyer 1</label>
                </div>
                <div className='col-3'>
                    <label> Buyer 2</label>
                </div>
                <div className='col-3'>
                    <label> Buyer 3</label>
                </div>
            </div>
            <div className='row form-fields'>
                <div className='col-3'>
                    <label>i. Name</label>
                </div>
                <div className='col-3'>
                    <input className='form-control' type="text" placeholder='Name' value={(buyer[0]!==undefined)? buyer[0].name : ''} onChange={(event)=> setBuyerData(0,event.target.value,'name')} />
                </div>
                <div className='col-3'>
                    <input className='form-control' type="text" placeholder='Name' value={(buyer[1]!==undefined)? buyer[1].name : ''} onChange={(event)=> setBuyerData(1,event.target.value,'name')} />
                </div>
                <div className='col-3'>
                    <input className='form-control' type="text" placeholder='Name' value={(buyer[2]!==undefined)? buyer[2].name : ''} onChange={(event)=> setBuyerData(2,event.target.value,'name')} />
                </div>
            </div>
            <div className='row form-fields'>
                <div className='col-3'>
                    <label>iI. Address</label>
                </div>
                <div className='col-3'>
                    <input className='form-control' type='text' placeholder='Address' value={(buyer[0]!==undefined)? buyer[0].address:''} onChange={(event)=> setBuyerData(0,event.target.value,'address')} />
                </div>
                <div className='col-3'>
                    <input className='form-control' type='text' placeholder='Address' value={(buyer[1]!==undefined)? buyer[1].address:''} onChange={(event)=> setBuyerData(1,event.target.value,'address')} />
                </div>
                <div className='col-3'>
                    <input className='form-control' type='text' placeholder='Address' value={(buyer[2]!==undefined)? buyer[2].address:''} onChange={(event)=> setBuyerData(2,event.target.value,'address')} />
                </div>
            </div>
            <div className='row form-fields'>
                <div className='col-3'>
                    <label>iii. Pan</label>
                </div>
                <div className='col-3'>
                    <input className='form-control' type='text' placeholder='PAN' value={(buyer[0]!==undefined && buyer[0]?.pan)? buyer[0].pan:''} onChange={(event)=> setBuyerData(0,event.target.value,'pan')} />
                </div>
                <div className='col-3'>
                    <input className='form-control' type='text' placeholder='PAN' value={(buyer[1]!==undefined && buyer[1]?.pan)? buyer[1].pan:''} onChange={(event)=> setBuyerData(1,event.target.value,'pan')} />
                </div>
                <div className='col-3'>
                    <input className='form-control' type='text' placeholder='PAN' value={(buyer[2]!==undefined && buyer[2]?.pan)? buyer[2].pan:''} onChange={(event)=> setBuyerData(2,event.target.value,'pan')} />
                </div>
            </div>
            <div className='row form-fields'>
                <div className='col-3'>
                    <label>iv. GSTN</label>
                </div>
                <div className='col-3'>
                    <input className='form-control' type='text' placeholder='GSTN' value={(buyer[0]!==undefined && buyer[0]?.gstn)? buyer[0].gstn : ''} onChange={(event)=> setBuyerData(0,event.target.value,'gstn')} />
                </div>
                <div className='col-3'>
                    <input className='form-control' type='text' placeholder='GSTN' value={(buyer[1]!==undefined && buyer[1]?.gstn)? buyer[1].gstn : ''} onChange={(event)=> setBuyerData(1,event.target.value,'gstn')} />
                </div>
                <div className='col-3'>
                    <input className='form-control' type='text' placeholder='GSTN' value={(buyer[2]!==undefined && buyer[2]?.gstn)? buyer[2].gstn : ''} onChange={(event)=> setBuyerData(2,event.target.value,'gstn')} />
                </div>
            </div>
            <div className='row form-fields'>
                <div className='col-3'>
                    <label>v. Price Quoted</label>
                </div>
                <div className='col-3'>
                    <input className='form-control' type='text' placeholder='Price Quoted' value={(buyer[0]!==undefined && buyer[0]?.priceQuoted)? buyer[0].priceQuoted : ''} onChange={(event)=> setBuyerData(0,event.target.value,'priceQuoted')} />
                </div>
                <div className='col-3'>
                    <input className='form-control' type='text' placeholder='Price Quoted' value={(buyer[1]!==undefined && buyer[1]?.priceQuoted)? buyer[1].priceQuoted : ''} onChange={(event)=> setBuyerData(1,event.target.value,'priceQuoted')} />
                </div>
                <div className='col-3'>
                    <input className='form-control' type='text' placeholder='Price Quoted' value={(buyer[2]!==undefined && buyer[2]?.priceQuoted)? buyer[2].priceQuoted : ''} onChange={(event)=> setBuyerData(2,event.target.value,'priceQuoted')} />
                </div>
            </div>
            <div className='row form-fields'>
                <div className='col-3'>
                    <label>vi. Quoted Recived Date</label>
                </div>
                <div className='col-3'>
                    <DatePicker wrapperClassName="cutom-form-control" 
                        placeholderText='Select Date'
                        selected={(buyer[0]!==undefined && buyer[0]?.quotedRecivedDate)? buyer[0].quotedRecivedDate : ''} 
                        dateFormat="yyyy/MM/dd"
                        onChange={(date) => setBuyerData(0,date,'quotedRecivedDate')} />
                </div>
                <div className='col-3'>
                    <DatePicker wrapperClassName="cutom-form-control" 
                        placeholderText='Select Date'
                        selected={(buyer[1]!==undefined && buyer[1]?.quotedRecivedDate)? buyer[1].quotedRecivedDate : ''} 
                        dateFormat="yyyy/MM/dd"
                        onChange={(date) => setBuyerData(1,date,'quotedRecivedDate')} />
                </div>
                <div className='col-3'>
                    <DatePicker wrapperClassName="cutom-form-control" 
                        placeholderText='Select Date'
                        selected={(buyer[2]!==undefined && buyer[2]?.quotedRecivedDate)? buyer[2].quotedRecivedDate : ''} 
                        dateFormat="yyyy/MM/dd"
                        onChange={(date) => setBuyerData(2,date,'quotedRecivedDate')} />
                </div>
            </div>
            <div className='row form-fields bottom-border'>
                <div className='col-3'>
                    <label>vii. Quoted Expiry Date</label>
                </div>
                <div className='col-3'>
                    <DatePicker wrapperClassName="cutom-form-control" 
                        placeholderText='Select Date'
                        selected={(buyer[0]!==undefined && buyer[0]?.quotedExpireDate)? buyer[0].quotedExpireDate:''} 
                        dateFormat="yyyy/MM/dd"
                        onChange={(date) => setBuyerData(0,date,'quotedExpireDate')} />
                </div>
                <div className='col-3'>
                    <DatePicker wrapperClassName="cutom-form-control" 
                        placeholderText='Select Date'
                        selected={(buyer[1]!==undefined && buyer[1]?.quotedExpireDate)? buyer[1].quotedExpireDate:''} 
                        dateFormat="yyyy/MM/dd"
                        onChange={(date) => setBuyerData(1,date,'quotedExpireDate')} />
                </div>
                <div className='col-3'>
                    <DatePicker wrapperClassName="cutom-form-control" 
                        placeholderText='Select Date'
                        selected={(buyer[2]!==undefined && buyer[2]?.quotedExpireDate)? buyer[2].quotedExpireDate:''} 
                        dateFormat="yyyy/MM/dd"
                        onChange={(date) => setBuyerData(2,date,'quotedExpireDate')} />
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <FromFieldText  
                        label="a. Final Sale Value" 
                        placeholder="Final Sale Value" 
                        inputValue={finalSaleValue}
                        setInputValue={setFinalSaleValue}
                        /> 
                </div>
                <div className="col-6">
                    <FromFieldDate
                        label="b Date of Sale" 
                        inputValue={saleDate}
                        setInputValue={setSaleDate} 
                        /> 
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <FromFieldText  
                        label="c. Final Buyer Name" 
                        placeholder="Final Buyer Name" 
                        inputValue={finalBuyerName}
                        setInputValue={setFinalBuyerName}
                        /> 
                </div>
                <div className="col-6">
                    <FromFieldDate
                        label="d Final Approval Date" 
                        inputValue={finalApprovalDate}
                        setInputValue={setFinalApprovalDate} 
                        /> 
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <FromFieldText  
                        label="E. Final Approval By" 
                        placeholder="Final Approval By" 
                        inputValue={finalApprovalBy}
                        setInputValue={setFinalApprovalBy}
                        /> 
                </div>
                <div className="col-6">
                    
                </div>
            </div>
            <div className="row">
            <div className="col-4"></div>
                <div className="col-4">
                { (Tag!=='' && Tag!==null) ? <div><button className="btn btn-primary btn-md" >Update</button>&nbsp;&nbsp;<a href={'/internal-transfer-view?tag='+ Tag } className="btn btn-secondary btn-md">Cancel</a></div>
                 : <button className="btn btn-primary btn-md" > SAVE </button>
                 } 
                 
                </div><div className="col-4"></div>
            </div>
        </div>
    )
}

export default AssetDisposal;