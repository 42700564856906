import React,{useState,useEffect,useRef} from 'react';
import { useLocation } from "react-router-dom";
import { getTransferData, saveTransferData } from '../../../services/asset.service';
import FromFieldLabel from '../../../atoms/FromFieldLabel';
import FromFieldText from '../../../atoms/FromFieldText';
import FromFieldDate from '../../../atoms/FromFieldDate';

function getFormatedDate(dateString) {
    if(dateString!=null) {
        var dte = new Date(dateString);
        return `${dte.getDate()}/${dte.getMonth()+1}/${dte.getFullYear()}`;
    } else {
        return null;
    }   
}
/*
Date.prototype.dbFormatedDate = function () {
    return `${this.getFullYear()}-${this.getMonth()+1}-${this.getDate()}`
}*/
const TEXT_REGEX = /^[a-zA-Z0-9-_ ]{1,23}$/;
const NUMBER_REGEX = /^[0-9]{1,15}$/;

function InternalTransfer() {
    const [tag, setTag] = useState(0);
    const [assetInfo, setAssetInfo] = useState({});
    const [division,setDivision] = useState('');
    const [ErrDivision,setErrDivision] = useState('');
    const [premises,setPremises] = useState('');
    const [ErrPremises,setErrPremises] = useState('');
    const [floor,setFloor] = useState('');
    const [ErrFloor,setErrFloor] = useState('');
    const [siteId,setSiteId] = useState('');
    const [ErrSiteId,setErrSiteId] = useState('');
    const [userType,setUserType] = useState('');
    const [ErrUserType,setErrUserType] = useState('');
    const [userDepartment,setUserDepartment] = useState('');
    const [ErrUserDepartment,setErrUserDepartment] = useState('');
    const [employeeId,setEmployeeId] = useState('');
    const [ErrEmployeeId,setErrEmployeeId] = useState('');
    const [employeeName,setEmployeeName] = useState('');
    const [ErrEmployeeName,setErrEmployeeName] = useState('');
    const [movementInitiatedBy,setMovementInitiatedBy] = useState('');
    const [ErrMovementInitiatedBy,setErrMovementInitiatedBy] = useState('');
    const [initiationDate,setInitiationDate] = useState('');
    const [ErrInitiationDate,setErrInitiationDate] = useState('');
    const [movementApprovedBy,setMovementApprovedBy] = useState('');
    const [ErrMovementApprovedBy,setErrMovementApprovedBy] = useState('');
    const [approvalDate,setApprovalDate] = useState('');
    const [ErrApprovalDate,setErrApprovalDate] = useState('');
    const [movementReason,setMovementReason] = useState('');
    const [ErrMovementReason,setErrMovementReason] = useState('');
    const errRef = useRef();
    const [validName, setValidName] = useState(false);
    const [ErrTAG, setErrTag] = useState(false);
    const [errMsg, setErrMsg] = useState('');
	const [success, setSuccess] = useState(false);

    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const Tag = query.get('tag'); 
    const Mode = query.get('mode'); 
    useEffect(() => {
        if(Tag!=='' && Tag!==null){
            getTagAssetInfo(Tag);
            //alert(Tag);
        }
        
    }, []);
    const getTagAssetInfo = function() {
        var data1= {
            'tag':Tag,
            'company_id':sessionStorage.getItem('LoggedInCompanyId'),
            'division_id':sessionStorage.getItem('LoggedInDivisionId'),
          }
        getTransferData(data1).then(function(res){
            if(res?.data?.data) {
                var internalInfo = res.data.data; 
                setAssetInfo(res.data.data);
                //console.log(internalInfo); 
                setDivision(internalInfo.division);setPremises(internalInfo.premises); setFloor(internalInfo.floor); setSiteId(internalInfo.siteId);
        setUserType(internalInfo.user_type); setUserDepartment(internalInfo.user_department);setEmployeeId(internalInfo.employee_id);
        setEmployeeName(internalInfo.employee_name);setMovementInitiatedBy(internalInfo.movement_initiated_by);
        setInitiationDate((new Date(internalInfo.initiation_date))); setMovementApprovedBy(internalInfo.movement_approved_by);setApprovalDate((new Date(internalInfo.approval_date)));setMovementReason(internalInfo.movement_reason);            
            }
        })
    }

    const getTagInfo = function() {
        var data1= {
            'tag':tag,
            'company_id':sessionStorage.getItem('LoggedInCompanyId'),
            'division_id':sessionStorage.getItem('LoggedInDivisionId'),
          }
        getTransferData(data1).then(function(res){
            if(res?.data?.data) {
                var internalInfo = res.data.data; 
                setAssetInfo(res.data.data);
                //console.log(internalInfo); 
                setDivision(internalInfo.division);setPremises(internalInfo.premises); setFloor(internalInfo.floor); setSiteId(internalInfo.siteId);
        setUserType(internalInfo.user_type); setUserDepartment(internalInfo.user_department);setEmployeeId(internalInfo.employee_id);
        setEmployeeName(internalInfo.employee_name);setMovementInitiatedBy(internalInfo.movement_initiated_by);
        setInitiationDate((new Date(internalInfo.initiation_date))); setMovementApprovedBy(internalInfo.movement_approved_by);setApprovalDate((new Date(internalInfo.approval_date)));setMovementReason(internalInfo.movement_reason);            
            }
        
        })
    }
    const handleSubmit = async (e) => {
		e.preventDefault();
		// if button enabled with JS hack
        //const v1 = TEXT_REGEX.test(yearaddition);
		if (!TEXT_REGEX.test(division)) {
			setErrDivision('Division is required'); setValidName(false);
			//return;
		}else{ setErrDivision(''); setValidName(true); }
        if (!TEXT_REGEX.test(premises)) {
			setErrPremises('Premises is required'); setValidName(false);
			//return;
		}else{ setErrPremises(''); setValidName(true); }
        if (!TEXT_REGEX.test(floor)) {
			setErrFloor('Floor is required'); setValidName(false);
			//return;
		}else{ setErrFloor(''); setValidName(true); } 
        if (!TEXT_REGEX.test(siteId)) {
			setErrSiteId('Site id is required'); setValidName(false);
			//return;
		}else{ setErrSiteId(''); setValidName(true); }
        if (!TEXT_REGEX.test( userType)) {
			setErrUserType('User type is required'); setValidName(false);
			//return;
		}else{ setErrUserType(''); setValidName(true); }
        
        if (!TEXT_REGEX.test(userDepartment)) {
			setErrUserDepartment('User department is required'); setValidName(false);
			//return;
		}else{ setErrUserDepartment(''); setValidName(true); }
        if (!TEXT_REGEX.test(employeeId)) {
			setErrEmployeeId('Employee id is required'); setValidName(false);
			//return;
		}else{ setErrEmployeeId(''); setValidName(true); }
        if (!TEXT_REGEX.test(employeeName)) {
			setErrEmployeeName('Employee Name of the Asset is required'); setValidName(false);
			//return;
		}else{ setErrEmployeeName(''); setValidName(true); }
        if (!TEXT_REGEX.test(movementInitiatedBy )) {
			setErrMovementInitiatedBy('Movement initiated by is required'); setValidName(false);
			//return;
		}else{ setErrMovementInitiatedBy(''); setValidName(true); }
        if (!TEXT_REGEX.test(initiationDate)) {
			setErrInitiationDate('Initiation Date is required'); setValidName(false);
			//return;
		}else{ setErrInitiationDate(''); setValidName(true); }
        if (!TEXT_REGEX.test(movementApprovedBy)) {
			setErrMovementApprovedBy('Movement approved by is required'); setValidName(false);
			//return;
		}else{ setErrMovementApprovedBy(''); setValidName(true); }
        if (!TEXT_REGEX.test(approvalDate)) {
			setErrApprovalDate ('Approval date is required'); setValidName(false);
			//return;
		}else{ setErrApprovalDate(''); setValidName(true); }
        if (!TEXT_REGEX.test(movementReason)) {
			setErrMovementReason('Movement reason is required'); setValidName(false);
			//return;
		}else{ setErrMovementReason(''); setValidName(true); }

        if (!validName) {
			return false;
		}
		try { //const response = saveData();
            saveData().then(res => {
                setSuccess(res.data.message);
                //elementRef.current.focus();
              });
			// TODO: remove console.logs before deployment
			//console.log(JSON.stringify(response?.data));
            //setSuccess("Successfully saved Single Asset");
			//clear state and controlled inputs
		setErrDivision('');setErrPremises(''); setErrFloor(''); setErrSiteId('');
        setErrUserType(''); setErrUserDepartment('');setErrEmployeeId('');setErrEmployeeName('');setErrMovementInitiatedBy('');
        setErrInitiationDate(''); setErrMovementApprovedBy('');setErrApprovalDate('');setErrMovementReason(''); setErrTag('');
            setValidName(false);
            //alert('a');
		} catch (err) { setSuccess(false);
			if (!err?.data) {
                console.log(err);
				//setErrMsg('No Server Response');
                setSuccess("Successfully saved Single Asset");
			} else if (err.data?.status === 409) {
				setErrMsg('Username Taken');
			} else {
				setErrMsg('Insert/Update Failed');
			}
			//elementRef.current.focus();
		}
	};
    const saveData = function() {
        var obj = {
            'division' : division,
            'premises' : premises,
            'floor' : floor,
            'site_id' : siteId,
            'user_type' : userType,
            'user_department' : userDepartment,
            'employee_id' : employeeId,
            'employee_name' : employeeName,
            'movement_initiated_by' : movementInitiatedBy,
            'initiation_date' : initiationDate?.dbFormatedDate(),
            'movement_approved_by' : movementApprovedBy,
            'approval_date' : approvalDate?.dbFormatedDate(),
            'movement_reason' : movementReason,
            'tag' : tag 
        }
        saveTransferData(obj).then(function(res) {
            console.log(res);
        })
    }
    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h5>Single Asset Internal Movement</h5>
                </div>
            </div>
            <div className='row'>
            {
            (Tag!=='' && Tag!==null) ? <div className='row'><div className='col-10'></div> <div className='col-2'></div></div> : <div className='row'><div className='col'><h5 className='align-right'>TAG </h5></div>
            <div className='col'><input onChange={function(event){setTag(event.target.value)}} inputValue={assetInfo.tag} type="text" className="form-control"  placeholder='Enter the asset TAG' /></div>
            <div className='col'><button onClick={getTagInfo} className='btn btn-primary'> Search </button></div></div>
            }
            {
              (Mode!=='' && Mode!==null)  ?<div className="col-6"><div className="row form-fields"><div className="label col-6"><label> Tag </label></div><div className="item col-6"><label>{assetInfo.tag}</label></div></div></div> : ''
            }
            </div>
            <div className="row">
                <div className="col-6">
                    <FromFieldLabel  
                        label="Financial Classification" 
                        inputValue={assetInfo.financials_classification}
                        /> 
                </div>
                <div className="col-6">
                    <FromFieldLabel
                        label="Invoice Date" 
                        inputValue={getFormatedDate(assetInfo.invoice_date)}
                        /> 
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <FromFieldLabel  
                        label="Supplier Name" 
                        inputValue={assetInfo.supplier_name}
                        /> 
                </div>
                <div className="col-6">
                    <FromFieldLabel
                        label="Supplier Invoice No" 
                        inputValue={assetInfo.invoice_no} 
                        /> 
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <FromFieldLabel  
                        label="Current Division" 
                        inputValue={assetInfo.division}
                        /> 
                </div>
                <div className="col-6">
                    <FromFieldLabel
                        label="Current Premises" 
                        inputValue={assetInfo.premises} 
                        /> 
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <FromFieldLabel  
                        label="Current Floor" 
                        inputValue={assetInfo.floor}
                        /> 
                </div>
                <div className="col-6">
                    <FromFieldLabel
                        label="Current Site ID" 
                        inputValue={assetInfo.site_id} 
                        /> 
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <FromFieldLabel  
                        label="Current User Type" 
                        inputValue={assetInfo.user_type}
                        /> 
                </div>
                <div className="col-6">
                    <FromFieldLabel
                        label="Current User Department" 
                        inputValue={assetInfo.user_department} 
                        /> 
                </div>
            </div>
            <div className="row bottom-border">
                <div className="col-6">
                    <FromFieldLabel  
                        label="Current Employee ID" 
                        inputValue={assetInfo.employee_id}
                        /> 
                </div>
                <div className="col-6">
                    <FromFieldLabel
                        label="Current Employee Name" 
                        inputValue={assetInfo.employee_name} 
                        /> 
                </div>
            </div>
            <form onSubmit={handleSubmit} > 
            <div className="row">
                <div className="col-6">
                    <FromFieldText star="*"
                        label="Movement Initiated By" 
                        placeholder="Movement Initiated By" 
                        //inputValue={movementInitiatedBy}
                        inputValue={assetInfo.movement_initiated_by}
                        setInputValue={setMovementInitiatedBy}
                        setErrorMsg={ErrMovementInitiatedBy}
                        /> 
                </div>
                <div className="col-6">
                    <FromFieldDate star="*"
                        label="Date of Initiation" 
                        inputValue={initiationDate}
                        setInputValue={setInitiationDate} 
                        setErrorMsg={ErrInitiationDate}
                        /> 
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <FromFieldText  star="*"
                        label="Movement Approved By" 
                        placeholder="Movement Approved By" 
                        inputValue={movementApprovedBy}
                        setInputValue={setMovementApprovedBy}
                        setErrorMsg={ErrMovementApprovedBy}
                        /> 
                </div>
                <div className="col-6">
                    <FromFieldDate  star="*"
                        label="Date of Approval" 
                        inputValue={approvalDate}
                        setInputValue={setApprovalDate} 
                        setErrorMsg={ErrApprovalDate}
                        /> 
                </div>
            </div>
            <div className="row bottom-border">
                <div className="col-6">
                    <FromFieldText  star="*"
                        label="Reason For Movement" 
                        placeholder="Reason For Movement" 
                        inputValue={movementReason}
                        setInputValue={setMovementReason}
                        setErrorMsg={ErrMovementReason}
                        /> 
                </div>
                <div className="col-6">
                    {/* <FromFieldText
                        label="Movement ID" 
                        inputValue={premises}
                        setInputValue={setPremises} 
                        />  */}
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <FromFieldText  star="*"
                        label="New Division" 
                        placeholder="New Division" 
                        inputValue={division}
                        setInputValue={setDivision}
                        setErrorMsg={ErrDivision}
                        /> 
                </div>
                <div className="col-6">
                    <FromFieldText star="*"
                        label="New Premises" 
                        placeholder="New Premises" 
                        inputValue={premises}
                        setInputValue={setPremises} 
                        setErrorMsg={ErrPremises}
                        /> 
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <FromFieldText  star="*"
                        label="New Floor" 
                        placeholder="New Floor" 
                        inputValue={floor}
                        setInputValue={setFloor}
                        setErrorMsg={ErrFloor}
                        /> 
                </div>
                <div className="col-6">
                    <FromFieldText star="*"
                        label="New Site ID" 
                        placeholder="New Site ID" 
                        inputValue={siteId}
                        setInputValue={setSiteId} 
                        setErrorMsg={ErrSiteId}
                        /> 
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <FromFieldText  star="*"
                        label="New User Type" 
                        placeholder="New User Type" 
                        inputValue={userType}
                        setInputValue={setUserType}
                        setErrorMsg={ErrUserType}
                        /> 
                </div>
                <div className="col-6">
                    <FromFieldText star="*"
                        label="New User Department" 
                        placeholder="New User Department" 
                        inputValue={userDepartment}
                        setInputValue={setUserDepartment} 
                        setErrorMsg={ErrUserDepartment}
                        /> 
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <FromFieldText  star="*"
                        label="New Employee ID" 
                        placeholder="New Employee ID" 
                        inputValue={employeeId}
                        setInputValue={setEmployeeId}
                        setErrorMsg={ErrEmployeeId}
                        /> 
                </div>
                <div className="col-6">
                    <FromFieldText star="*"
                        label="New Employee Name" 
                        placeholder="New Employee Name" 
                        inputValue={employeeName}
                        setInputValue={setEmployeeName} 
                        setErrorMsg={ErrEmployeeName}
                        /> 
                </div>
            </div>
            
            <div className="row">
            <div className="col-4"></div>
                <div className="col-4">
                { (Tag!=='' && Tag!==null) ? <div><button className="btn btn-primary" >Update</button>&nbsp;&nbsp;<a href={'/internal-transfer-view?tag='+ Tag } className="btn btn-secondary btn-md">Cancel</a></div>
                 : <button className="btn btn-primary" > SAVE </button>
                } 
                 
                </div><div className="col-4"></div>
            </div>
            </form>
        </div>
    )
}

export default InternalTransfer


