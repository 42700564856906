
import React from 'react'

function FromFieldEmail(props) {
  
  return (
    <div className="row">
        <div className="col-md-6" > 
            <label> {props.label} <span className="star error"> {props.star} </span></label>
             
        </div>
        <div className="col-md-6" > 
            <input type="email" 
                className="form-control" 
                placeholder={props.placeholder} 
                value={props.inputValue} // /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/;
                onChange={ (event)=>props.setInputValue( (/^[A-Za-z0-9._%+-@]+$/.test(event.target.value) )? event.target.value.slice(0,50):'' ) } 
                //onBlur={(event)=>props.checkInputValue(event.target.value)}
                readOnly={props.readOnly}
                />
          <span className="invalid-feedback"> {props.setErrorMsg} </span>
        </div>
    </div>
  )
}

export default FromFieldEmail