import React,{useEffect,useState} from 'react';
import { useLocation } from "react-router-dom";
import './AssetDetails.css';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FromFieldText from '../../../atoms/FromFieldText';
import { getRepositoryDetails,getAssetsInvoices } from '../../../services/asset.service';
import { getDepartmentList} from '../../../services/user.service';
import { DataGrid,GridToolbarContainer, GridToolbarExport  } from '@mui/x-data-grid';
//import FromFieldNumber from '../../../atoms/FromFieldNumber';
import  * as CryptoJS  from "crypto-js";
import AWS from 'aws-sdk';
AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
  region: process.env.REACT_APP_AWS_BUCKET_REGION,
});
const s3 = new AWS.S3();
Date.prototype.dbFormatedDate = function () {
    let year = this.getFullYear();
    let month = this.getMonth() + 1;
    let day = this.getDate();
    if (month < 10) {
      month = '0' + month;
    }if (day < 10) {
      day = '0' + day;
    }
    return year + '-' + month + '-' + day;
  }
  function getFormatedDate(dateString) {
    if(dateString!==null) {
        let dte = new Date(dateString);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        if (month < 10) {
          month = '0' + month;
        }if (day < 10) {
          day = '0' + day;
        }
        return year + '-' + month + '-' + day;
        //return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
    } else {
        return null;
    }   
  }


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
const handleDownloadFile = async (id, imageId) => {
    // Use the S3 getObject method to get the image and initiate the download
    console.log('imageId'+imageId);
    imageId = 'invoice/'+imageId;
    s3.getObject({
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
      Key: imageId, //imageId,
    }, (err, data) => {
      if (err) {
        console.error('Error downloading image:', err);
      } else { console.log(data.Body);
        const url = URL.createObjectURL(new Blob([data.Body]));
        const a = document.createElement('a');
        a.href = url;
        a.download = imageId;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    });
  };

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}

function RepositoryBasicDetails() {

    const [value, setValue] = useState(0);
    const [tag, setTag] = useState(0);
    //const [edittag, setEditTag] = useState(0);
    const [assetInfo, setAssetInfo] = useState(0);
    const [assetDepriciation, setAssetDepriciation] = useState([]);
    const [loading, setLoading] = useState(false);
    const [allocationInfo, setAllocationInfo] = useState(0);
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const Tag = query.get('tag');
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
    //const [roles,setRoles] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [items, setItems] = useState([]);
    useEffect(() => {
        if(Tag!=='' && Tag!==null){
        const decryptedBytes = CryptoJS.AES.decrypt(Tag.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
        // Convert the decrypted bytes to a string
        const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
        const decryptedTag = decrypt_data.tag;
        getTagAutoInfo(decryptedTag);
        //alert(Tag);
        }
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            //'param_data':obj,
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token':EncryptData}
        setLoading(true); // Show loader before fetching data
        getAssetsInvoices(oData).then(res => {
            if (res?.data?.data?.length) {
                setItems(res.data.data);
            }
        }).catch(error => {
            console.error('Error fetching data:', error);
            // Handle error if needed
        }).finally(() => {
            setLoading(false); // Hide loader when data fetching is done (success or failure)
        });
        getDepartmentList(oData).then(res=>{
            if(res?.data?.data?.length) setDepartments(res.data.data);
        });

    }, [])        
   

    function CustomToolbar() {
    return (
        <GridToolbarContainer>
        
        </GridToolbarContainer>
    );
    }
    const columns = [
        {"field":"sl_no","hideable":true,"hide":true,"editable":false,"headerName":"Sl No","width":50},
        {"field":"tag","editable":false,"hide":false ,"headerName":"TAG","width":250,},
        {"field":"parent_tag","hideable":true,"hide":true ,"editable":true,"headerName":"Parent TAG","width":250},
        {"field":"supplier_code","editable":true,"hide":false ,"headerName":"Supplier Code","width":130},
        {"field":"supplier_name","editable":true,"hide":false ,"headerName":"Supplier Name","width":130},
        {"field":"invoice_no","editable":true,"hide":false ,"headerName":"Invoice No","width":130},
        {"field":"invoice_date","editable":true,"hide":false ,"headerName":"Invoice Date","width":130,
        renderCell: (params) => (
        <p>{ getFormatedDate(params.row.invoice_date)}</p>
        ) },
        {"field":"created_at","editable":true,"hide":false ,"headerName":"Created Date","width":130,
        renderCell: (params) => (
            <p>{ getFormatedDate(params.row.created_at)}</p>
        ) },
        {"field":"doc_type","editable":false,"hide":false ,"headerName":"Doc Type","width":100 },
        { field: 'asset_invoice', headerName: 'Actions', width: 200,
            renderCell: (params) => (<div><a href="#" onClick={() => handleDownloadFile(params.row.id, params.row.asset_invoice) } >Download</a> &nbsp;
            </div>
        ) },
    ];
    const handleChange = (event, newValue) => {
        console.log(newValue)
        setValue(newValue);
    };
    var onCellEditStopFn = (event,newCell) => {
        var obj = {};
        obj[newCell.field] = event.target.value;
        console.log(obj)
    }
    const getTagAutoInfo = function(Tag) {
        //alert(Tag);
            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data':{'tag':Tag,'department_group':sessionStorage.getItem('LoggedIndepartmentGroup')},
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData = {'token':EncryptData }

        getRepositoryDetails(oData).then(function(res){
            if(res?.data?.data) { setErrMsg('');
              var {assetInfo} = res.data.data;
              setAssetInfo(assetInfo);
              
            }else{setAssetInfo({});setErrMsg('Assets Not Found');} 
        })
        

    }
    const getTagInfo = function() {
        if(tag==''){
            setErrMsg('Enter Assets'); 
        }else{ setErrMsg('');
        
        
        }
    }
    return (
        <div className="container ">
            
             { (Tag!=='' && Tag!==null) ? <div className='row'><div className='col-10'></div> <div className='col-2'><a href={'/my-asset-repository' }><button  className='btn btn-secondary btn-sm'><i className="bi bi-arrow-left"></i> Back</button></a>&nbsp;</div></div> : <div></div>  }
              
            
            <div className='row h-100'>
                <div className='col h-100'>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Information" {...a11yProps(0)} />
                            <Tab label="Documentation" {...a11yProps(1)} />
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0}>
                        <div className='row'>
                                <div className='col-6'><FromFieldText readOnly="readonly"  label="TAG" inputValue={assetInfo.tag} /></div>
                                <div className='col-6'><FromFieldText readOnly="readonly"  label="Parent TAG" inputValue={assetInfo.parent_tag} /></div>
                            
                                <div className='col-6'><FromFieldText readOnly="readonly"  label="Year of Addition" inputValue={assetInfo.year_of_addition} />	</div>
                                <div className='col-6'><FromFieldText readOnly="readonly"  label="Type of Addition" inputValue={assetInfo.type_of_addition} />	</div>
                            
                                <div className='col'><FromFieldText readOnly="readonly"  label="Project Marking" inputValue={assetInfo.project_marking} /></div>
                                <div className='col-6'><FromFieldText readOnly="readonly"  label="PO No (If Any)" inputValue={assetInfo.po_no} /></div>
                            
                                <div className='col-6'><FromFieldText readOnly="readonly"  label="Supplier Code (if Any)" inputValue={assetInfo.supplier_code} />	</div>
                                <div className='col-6'><FromFieldText readOnly="readonly"  label="Name of the Supplier" inputValue={assetInfo.supplier_name} />	</div>
                            
                                <div className='col-6'><FromFieldText readOnly="readonly"  label="Invoice No" inputValue={assetInfo.invoice_no} />	</div>
                                <div className='col-6'><FromFieldText readOnly="readonly"  label="Invoice Date" inputValue={ assetInfo.invoice_date?getFormatedDate(assetInfo.invoice_date):''} />	</div>

                            <div className='col-6'><FromFieldText readOnly="readonly" label="Bill of Entry No"
                                                                  inputValue={assetInfo.boe_no}/></div>
                            <div className='col-6'><FromFieldText readOnly="readonly" label="Date of BOE"
                                                                  inputValue={assetInfo.boe_date ? getFormatedDate(assetInfo.boe_date) : ''}/>
                            </div>

                            <div className='col-6'><FromFieldText readOnly="readonly" label="Shipping / AWB No"
                                                                  inputValue={assetInfo.shipping_awb_no}/></div>
                            <div className='col-6'><FromFieldText readOnly="readonly" label="Date of Shipping/AWB"
                                                                  inputValue={assetInfo.shipping_awb_date ? getFormatedDate(assetInfo.shipping_awb_date) : ''}/>
                            </div>
                            
                                <div className='col-6'><FromFieldText readOnly="readonly"  label="Accounting Voucher No." inputValue={assetInfo.accounting_voucher_no} />	</div>
                                <div className='col-6'><FromFieldText readOnly="readonly"  label="Internal Approval No" inputValue={ assetInfo.internal_approval_no} />	</div>
                            
                                <div className='col-6'><FromFieldText readOnly="readonly"  label="Cost Center Code" inputValue={assetInfo.cost_center_code} />	</div>
                                <div className='col-6'><FromFieldText readOnly="readonly"  label="Cost Center Name" inputValue={ assetInfo.cost_center_name} />	</div>
                            
                                <div className='col-6'><FromFieldText readOnly="readonly"  label="Qty" inputValue={assetInfo.qty} /></div>
                                
                            
                                <div className='col-6'><FromFieldText readOnly="readonly"  label="Unit Of Measure" inputValue={assetInfo.unit_of_measure} />	</div>
                                <div className='col-6'><FromFieldText readOnly="readonly"  label="Name of the Asset (as per Invoice)" inputValue={assetInfo.asset_name} />	</div>
                                
                                <div className='col-6'><FromFieldText readOnly="readonly"  label="Detailed Description of the Asset" inputValue={assetInfo.asset_description} />	</div>
                                <div className='col-6'><FromFieldText readOnly="readonly"  label="Nature of Presence" inputValue={assetInfo.nature_of_presence} />	</div>
                            
                                <div className='col-6'><FromFieldText readOnly="readonly"  label="Nature of Addition" inputValue={assetInfo.nature_of_addition} />	</div>
                                <div className='col-6'><FromFieldText readOnly="readonly"  label="Principal Asset to which Cost is Added" inputValue={assetInfo.principal_asset_tag} />	</div>
                            
                                <div className='col-6'><FromFieldText readOnly="readonly"  label="Unique Sl No of the Asset" inputValue={assetInfo.asset_serial_no} /></div>
                                <div className='col-6'><FromFieldText readOnly="readonly"  label="Make / Manufacturer" inputValue={assetInfo.manufacturer} /></div>
                             
                                <div className='col-6'><FromFieldText readOnly="readonly"  label="Model No" inputValue={assetInfo.model_no} />	</div>
                                <div className='col-6'><FromFieldText readOnly="readonly"  label="Part No" inputValue={assetInfo.part_no} />	</div>
                             
                                <div className='col-6'><FromFieldText readOnly="readonly"  label="TAG" inputValue={assetInfo.tag} />	</div>
                                <div className='col-6'><FromFieldText readOnly="readonly"  label="Comp Ref. No." inputValue={assetInfo.comp_ref_no} />	</div>
                            
                                <div className='col-6'><FromFieldText readOnly="readonly"  label="Asset TAG Name" inputValue={assetInfo.tag_name} />	</div>
                                <div className='col-6'><FromFieldText readOnly="readonly"  label="Asset TAG Branch" inputValue={assetInfo.tag_division} />	</div>
                            <div className='col-6'><FromFieldText readOnly="readonly" label="Security Seal Date"
                                                                  inputValue={assetInfo.security_seal_date ? getFormatedDate(assetInfo.security_seal_date) : ''}/></div>
                                <div className='col-6'><FromFieldText readOnly="readonly"  label="Control Department" inputValue={
                                    
                                    (departments && departments.length) > 0 ? (
                                        departments.map((option) => ((assetInfo.ownership_id===option.id)?(option.department_name.replace(',',' ')) : ''))
                                                    ) : ('') } /></div>
                                <div className='col'>	</div>
                            <div className='col-6'></div>
                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <div className='row'>
                                <div className='col'>
                                    <Box sx={{height: 500, width: '100%'}}>
                                        <DataGrid
                                            rows={assetDepriciation}
                                            getRowId={(row) => row.id}
                                            columns={columns}
                                            pageSize={50}
                                            columnBuffer={50}
                                            //autoHeight
                                            rowsPerPageOptions={[50]}
                                            onCellEditStop={onCellEditStopFn}
                                            components={{
                                                Toolbar: CustomToolbar,
                                            }}
                                        /></Box>
                                </div>
                            </div>
                        </TabPanel>
                    </Box>
                </div>
            </div>
        </div>
    )
}

export default RepositoryBasicDetails