import React,{useState,useEffect,useRef} from 'react'
import { useLocation } from "react-router-dom";
import FromFieldText from '../../../atoms/FromFieldText';
//import FromFieldDate from '../../../atoms/FromFieldDate';
import { getAllocation } from '../../../services/asset.service';
import  * as CryptoJS  from "crypto-js";
/*Date.prototype.dbFormatedDate = function () {
    return `${this.getFullYear()}-${this.getMonth()+1}-${this.getDate()}`
  }
  */
  Date.prototype.dbFormatedDate = function () {
    
    let year = this.getFullYear();
    let month = this.getMonth() + 1;
    let day = this.getDate();
    if (month < 10) {
      month = '0' + month;
    }if (day < 10) {
      day = '0' + day;
    }
    return year + '-' + month + '-' + day;
    //return `${this.getFullYear()}-${this.getMonth()+1}-${this.getDate()-1}`
  }
  function getFormatedDate(dateString) {
    if(dateString!==null) {
        let dte = new Date(dateString);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        if (month < 10) {
          month = '0' + month;
        }if (day < 10) {
          day = '0' + day;
        }
        return year + '-' + month + '-' + day;
        //return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
    } else {
        return null;
    }   
  }
  const TEXT_REGEX = /^[A-Za-z0-9-_/.@= ]{1,155}$/;
//const NUMBER_REGEX = /^[0-9]{1,15}$/;

function SingleViewAllocation() {
    
    const [assetInfo, setAssetInfo] = useState({});
    const [validName, setValidName] = useState(false);
    const [errMsg, setErrMsg] = useState('');
	const [success, setSuccess] = useState(false);
    
    const [tag, setTag] = useState(0);
    const [ErrTag, setErrTag] = useState(false);

    const [division,setDivision] = useState('');
    const [ErrDivision, setErrDivision] = useState(false);

    const [premises,setPremises] = useState('');
    const [ErrPremises, setErrPremises] = useState(false);

    const [floor,setFloor] = useState('');
    const [ErrFloor, setErrFloor] = useState(false);

    const [siteId,setSiteId] = useState('');
    const [ErrSiteId, setErrSiteId] = useState(false);

    const [userType,setUserType] = useState('');
    const [ErrUserType, setErrUserType] = useState(false);

    const [userDepartment,setUserDepartment] = useState('');
    const [ErrUserDepartment, setErrUserDepartment] = useState(false);

    const [employeeId,setEmployeeId] = useState('');
    const [ErrEmployeeId, setErrEmployeeId] = useState(false);

    const [employeeName,setemployeeName] = useState('');
    const [ErrEmployeeName, setErrEmployeeName] = useState(false);

    const [rackId,setRackId] = useState('');
    const [ErrRackId, setErrRackId] = useState(false);

    const [userMailId, setUserMailId] = useState('');
    const [ErrUserMailId, setErrUserMailId] = useState(false);

    const [alternativeVerification, setAlternativeVerification] = useState('');
    const [ErrAlternativeVerification, setErrAlternativeVerification] = useState(false);

    //const [invoiceDate,setInvoiceDate] = useState('');

    //const [initiationDate,setInitiationDate] = useState('');
    const [financialsClassification,setFinancialsClassification] = useState('');
    //const [approvalDate,setApprovalDate] = useState('');
    //const [movementReason,setMovementReason] = useState('');


    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const Tag = query.get('tag'); 
    const elementRef = useRef();
    useEffect(() => {
        if(Tag!=='' && Tag!==null){
            
            const decryptedBytes = CryptoJS.AES.decrypt(Tag.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
            // Convert the decrypted bytes to a string
            const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
            const decryptedTag = decrypt_data.tag;
            console.log(decryptedTag);
            getTagAssetInfo(decryptedTag);
            //alert(Tag);
        }
        
    }, [])
    const getTagAssetInfo = function(Tag) {
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data':{'tag':Tag},
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token':EncryptData}
          getAllocation(oData).then(function(res){
            if(res?.data?.data?.transferInfo) {
                var Info = res.data.data.transferInfo; 
                console.log(Info);
                setDivision(Info.division);
                setPremises(Info.premises);
                setFloor(Info.floor);
                setSiteId(Info.site_id);
                setUserType(Info.user_type);
                setUserDepartment(Info.user_department);
                setEmployeeId(Info.employee_id);
                setemployeeName(Info.employee_name);
                setRackId(Info.rack_id);
                setUserMailId(Info.user_mail_id);
                setAlternativeVerification(Info.alternative_verification);
                //setInvoiceDate((new Date(Info.invoice_date)));
                setFinancialsClassification(Info.financials_classification);
                setTag(Info.tag);
            }
        })
    }
    const getTagInfo = function() {
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data':{'tag':tag},
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData1 = {'token':EncryptData}
          getAllocation(oData1).then(function(res){
            if(res?.data?.data) {
                var Info = res.data.data; 
                //console.log(Info);
                setDivision(Info.division);
                setPremises(Info.premises);
                setFloor(Info.floor);
                setSiteId(Info.site_id);
                setUserType(Info.user_type);
                setUserDepartment(Info.user_department);
                setEmployeeId(Info.employee_id);
                setemployeeName(Info.employee_name);
                setRackId(Info.rack_id);
                //setInvoiceDate((new Date(Info.invoice_date)));
                setFinancialsClassification(Info.financials_classification);
                setTag(Info.tag);
                setUserMailId(Info.user_mail_id);
                setAlternativeVerification(Info.alternative_verification);
            }
        })
    }

    return (
        <div className="container">
            
            <div className="row">
                <div className="col">
                    <h5>Single Allocation</h5>
                </div>
                
            </div>
            { 
            (Tag!='' && Tag!=null) ? <div className='row'><div className='col-10'></div> <div className='col-2'><a href={'/single-allocation?tag='+ Tag.replace(/ /g, '+') }><button  className='btn btn-info btn-sm'><i className="bi bi-pencil-square"></i> Edit</button></a>&nbsp;<a href={'/view-allocation' }><button  className='btn btn-secondary btn-sm'><i className="bi bi-arrow-left"></i> Back</button></a>&nbsp;</div></div> : <div className='row'><div className='col'><h5 className='align-right'>TAG </h5></div>
            <div className='col'><input onChange={function(event){setTag(event.target.value)}} inputValue={assetInfo.tag} type="text" className="form-control"  placeholder='Enter the asset TAG'/></div>
            <div className='col'><button onClick={getTagInfo} className='btn btn-primary btn-md'><i className="bi bi-search"></i> Search </button></div></div>
            }  
            
            <div className='row'></div>
            
            <div className="row">
                <div className="col-6">
                    <FromFieldText  
                        label="TAG" 
                        inputValue={tag}
                        readOnly="readonly"
                        /> 
                </div><div className='col-6'></div>
                <div className="col-6">
                    {/*<FromFieldText
                        label="Invoice Date" 
                        inputValue={getFormatedDate(invoiceDate)}
                        //setInputValue={setInvoiceDate}
        /> */}
                </div>
            </div>
            
            <div className="row">
                <div className="col-6">
                    <FromFieldText 
                        label="Division" 
                        inputValue={division}
                        readOnly="readonly"
                        /> 
                </div>
                <div className="col-6"></div>
                <div className="col-6">
                    <FromFieldText 
                        label="Premises" 
                        inputValue={premises} 
                        readOnly="readonly"
                        /> 
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <FromFieldText 
                        label="Floor" 
                        inputValue={floor}
                        readOnly="readonly"
                        /> 
                </div><div className="col-6"></div>
                <div className="col-6">
                    <FromFieldText  
                        label="Site ID" 
                        inputValue={siteId} 
                        readOnly="readonly"
                        /> 
                </div>
            </div>


            <div className="row">
                <div className="col-6">
                    <FromFieldText
                        label="Rack ID"
                        inputValue={rackId}
                        readOnly="readonly"
                    />
                </div><div className="col-6"></div>
                <div className="col-6"></div>
            </div>

            <div className="row">
                <div className="col-6">
                    <FromFieldText  
                        label="User Type" 
                        inputValue={userType}
                        readOnly="readonly"
                        /> 
                </div><div className="col-6"></div>
                <div className="col-6">
                    <FromFieldText   
                        label="User Department" 
                        inputValue={userDepartment} 
                        readOnly="readonly"
                        /> 
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <FromFieldText  
                        label="Employee ID" 
                        inputValue={employeeId}
                        readOnly="readonly"
                        /> 
                        
                </div><div className="col-6"></div>
                <div className="col-6">
                    <FromFieldText
                        label="Employee Name" 
                        inputValue={employeeName} 
                        readOnly="readonly"
                        /> 
                </div>
            </div>


            <div className="row">
                <div className="col-6">
                    <FromFieldText
                        label="User Mail ID"
                        inputValue={userMailId}
                        readOnly="readonly"
                    />

                </div><div className="col-6"></div>
                <div className="col-6">
                    <FromFieldText
                        label="Eligible for Alternative Verification"
                        inputValue={alternativeVerification}
                        readOnly="readonly"
                    />
                </div>
            </div>
             
            
            <div className="row">
            <div className="col-3"></div>
                <div className="col-3">
                
                    </div>
                <div className="col-6"></div>
            </div>
            
        </div>
    )
}

export default SingleViewAllocation


