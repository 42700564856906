import React, {useEffect, useState} from 'react';
import * as CryptoJS from "crypto-js";
import {createTicket} from "../../services/tickets.service";
//import * as CryptoJS from "crypto-js";
import {updateMyPassword} from "../../services/user.service";

function ManageMyPassword() {

    const PASSWORD_REGEX = /^[A-Za-z0-9._%+-@#$&!*)(=;]+$/;

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    const [oldPassword, setOldPassword] = useState('');
    const [ErrOldPassword, setErrOldPassword] = useState('');

    const [newPassword, setNewPassword] = useState('');
    const [ErrNewPassword, setErrNewPassword] = useState('');

    const [reEnterNewPassword, setReEnterNewPassword] = useState('');
    const [ErrReEnterNewPassword, setErrReEnterNewPassword] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        let valid_Name = true;

        if (!PASSWORD_REGEX.test(oldPassword)) {
            setErrOldPassword('Old password is required');
            valid_Name = false;
        } else {
            setErrOldPassword('');
        }

        if (!PASSWORD_REGEX.test(newPassword)) {
            setErrNewPassword('New Password is required');
            valid_Name = false;
        } else {
            setErrNewPassword('');
        }

        if (!PASSWORD_REGEX.test(reEnterNewPassword)) {
            setErrReEnterNewPassword('Confirm New password is required');
            valid_Name = false;
        } else if (newPassword !== reEnterNewPassword) {
            setErrReEnterNewPassword('Password and Confirm Passwords do not match');
            valid_Name = false;
        } else {
            setErrReEnterNewPassword('');
        }

        if (valid_Name) {
            savePassword();
        } else {
            return false;
        }
    }

    const savePassword = function () {

        try {
            var obj = {
                'old_password': oldPassword,
                'password': newPassword,
                'created_by': sessionStorage.getItem('LoggedInUserId'),
            }
            //encrypted data
            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': obj,
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData = {'token': EncryptData}
            updateMyPassword(oData).then(function (res) {
                //console.log(res);
                if ((typeof (res.data.errors) != "undefined") && res.data.errors.errno > 0) {
                    setErrMsg(res.data.message);
                    setSuccess(false);
                } else {
                    setSuccess(res.data.message);
                    resetPasswordField();
                }
            });

            //setValidName(false);
        } catch (err) {
            setSuccess(false);
            if (!err?.data) {
                console.log(err);
                setErrMsg('No Server Response');
            } else if (err.data?.status === 409) {
                setErrMsg('data already exist');
            } else {
                setErrMsg('Insert/Update Failed');
            }
            //elementRef.current.focus();
        }
    }

    const resetPasswordField = function () {
        setOldPassword('');
        setNewPassword('');
        setReEnterNewPassword('');
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h5>Update Password</h5>
                </div>
                <div className="invalid-feedback" role="alert">
                    <i className="fa fa-info-circle"></i>
                    Please Fill all mandatory field.
                </div>

                {success ? (
                    <div className="alert alert-success" role="alert"><i
                        className="bi bi-check-circle-fill"></i>{success}</div>
                ) : (errMsg ? (<div className="alert alert-danger" role="alert"><i
                        className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>) : ('')
                )}
            </div>

            <div className='row'>
                <div className='col-12'>&nbsp;</div>

            </div>

            <form onSubmit={handleSubmit}>

                <div className="row mb-3">
                    <div className="col-3">Old Password</div>
                    <div className="col-3">
                        <input
                            type="password"
                            className="form-control"
                            placeholder="Enter current password"
                            value={oldPassword}
                            onChange={(event) => {
                                setOldPassword(event.target.value)
                            }}
                        />
                        <span className="invalid-feedback"> {ErrOldPassword} </span>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-3">Enter Password</div>
                    <div className="col-3">
                        <input
                            type="password"
                            className="form-control"
                            placeholder="Enter new password"
                            value={newPassword}
                            onChange={(event) => {
                                setNewPassword(event.target.value)
                            }}
                        />
                        <span className="invalid-feedback"> {ErrNewPassword} </span>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-3">Confirm Password</div>
                    <div className="col-3">
                        <input
                            type="password"
                            className="form-control"
                            placeholder="Confirm new password"
                            value={reEnterNewPassword}
                            onChange={(event) => {
                                setReEnterNewPassword(event.target.value)
                            }}
                        />
                        <span className="invalid-feedback"> {ErrReEnterNewPassword} </span>
                    </div>
                </div>

                <div className="row m-2">
                    <div className="col-6">
                        <button id="btn-submit" className="btn btn-primary btn-md"> UPDATE</button>
                    </div>
                </div>

            </form>
        </div>
    )
}

export default ManageMyPassword