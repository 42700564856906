import React, {useEffect, useState} from 'react';
import './atom.css';
import Box from '@mui/material/Box';
import {DataGrid, GridOverlay, GridToolbarContainer} from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from 'react-modal';
import {savePartialCaptalizedValue} from '../services/asset.service';
import * as CryptoJS from "crypto-js";
import FromFieldTextArea from "./FromFieldTextArea";
import FromFieldNumber from "./FromFieldNumber";


const PartialSpecifyPopupBox = ({din_no, tag, disposalcaptilizedvalue, isOpen, onClose, onConfirm}) => {
    const [loading, setLoading] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [items, setItems] = useState([]);

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    const TEXT_REGEX = /^[a-zA-Z0-9-_ ]{1,500}$/;

    //const [tag, setTag] = useState('');

    const [partialCapitalizedValue, setPartialCapitalizedValue] = useState(disposalcaptilizedvalue);
    const [ErrPartialCapitalizedValue, setErrPartialCapitalizedValue] = useState(false);
    //setPartialCapitalizedValue(disposalcaptilizedvalue);
    const handleSubmit = async (e) => {
        e.preventDefault();
        var valid_Name = true;

        if (!TEXT_REGEX.test(partialCapitalizedValue)) {
            setErrPartialCapitalizedValue('Please Update Status of QR Label Pasting');
            valid_Name = false;
            //return;
        } else {
            setErrPartialCapitalizedValue('');
        }

        if (valid_Name) { //alert('test1');
            saveData(); //submit form
        } else {
            return false;
        }
    }

    const saveData = function () {
        if (din_no !== '' && din_no !== null) {

            // Convert the decrypted bytes to a string

            var obj = {
                'din_no': din_no,
                'disposal_captilized_value': partialCapitalizedValue,
                'created_by': sessionStorage.getItem('LoggedInUserId')
            }
        }

        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': obj,
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token': EncryptData}
        try {
            savePartialCaptalizedValue(oData).then(function (res) {
                console.log(res);
                if ((typeof (res.data.errors) != "undefined") && res.data.errors.errno > 0) {
                    setErrMsg(res.data.message);
                    setSuccess(false);
                } else {
                    setSuccess(res.data.message);
                    resetAssetInfo();
                }
            });

            //setValidName(false);
        } catch (err) {
            setSuccess(false);
            if (!err?.data) {
                //console.log(err);
                setErrMsg('No Server Response');
            } else if (err.data?.status === 409) {
                setErrMsg('data already exist');
            } else {
                setErrMsg('Insert/Update Failed');
            }
            //elementRef.current.focus();
        }
    }

    const resetAssetInfo = function () {
        setPartialCapitalizedValue('');
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Confirm Box"
            style={modalStyles}
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Specify capitalized rate for partial disposition</h5>
                        <button type="button" class="btn-close" onClick={onClose}></button>
                    </div>
                    <div class="modal-body">
                        <h6 className="modal-title">TAG No - {tag} | Din No. - {din_no}</h6>
                    </div>
                    <div style={modalBody}>


                        {success ? (
                            <div className="alert alert-success" role="alert"><i
                                className="bi bi-check-circle-fill"></i>{success}</div>
                        ) : (errMsg ? (<div className="alert alert-danger" role="alert"><i
                                className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>) : ('')
                        )}

                        <form onSubmit={handleSubmit}>


                            <div className="row">

                                <div className="col-8 mt-4 text-center">

                                    <FromFieldNumber
                                        star="*"
                                        label="Please specify partial disposal rate as a number(<= 100)"
                                        placeholder={disposalcaptilizedvalue}
                                        inputValue={partialCapitalizedValue}
                                        setInputValue={(value) => {
                                            // Validate that the entered value is a number and less than or equal to 100
                                            const numericValue = Number(value);

                                            if (!isNaN(numericValue) && numericValue >= 1 && numericValue <= 100) {
                                                setPartialCapitalizedValue(value);
                                                setErrPartialCapitalizedValue("");
                                            } else {
                                                setErrPartialCapitalizedValue("Please enter a valid number (<= 100).");
                                            }
                                        }}
                                        setErrorMsg={ErrPartialCapitalizedValue}
                                    />


                                </div>

                                <div className="col-4 text-center mt-4">
                                    {(din_no !== '' && din_no !== null) ?
                                        (<button className="btn btn-primary btn-md"> Update </button>) : <button className="btn btn-primary btn-md"> SAVE </button> }

                                </div>

                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

const modalStyles = {
    content: {
        width: '550px',
        height: '250px',
        textAlign: 'center',
    },
};
const modalBody = {
    content: {
        position: 'relative',
        flex: '1 1 auto',
        padding: '10px !important'
    },
}
export default PartialSpecifyPopupBox;