import React, { useEffect, useState } from 'react';
import './atom.css';
import Box from '@mui/material/Box';
import Modal from 'react-modal';
import { getTicketDetails, getTicketReply, replyTicket } from "../services/tickets.service";
import * as CryptoJS from "crypto-js";
import FromSpFieldTextArea from "./FromSpFieldTextArea";
import AWS from "aws-sdk";

AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    region: process.env.REACT_APP_AWS_BUCKET_REGION,
});
const s3 = new AWS.S3();

function getFormatedDateTime(dateString) {
    if (dateString != null) {
        let dte = new Date(dateString);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        let hours = dte.getHours();
        let minutes = dte.getMinutes();
        let seconds = dte.getSeconds();

        // Pad single digit month, day, hours, minutes, and seconds with leading zero
        month = month < 10 ? '0' + month : month;
        day = day < 10 ? '0' + day : day;
        hours = hours < 10 ? '0' + hours : hours;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        seconds = seconds < 10 ? '0' + seconds : seconds;

        return day + '-' + month + '-' + year + ' ' + hours + ':' + minutes + ':' + seconds;
    } else {
        return null;
    }
}

const TEXT_REGEX = /^[A-Za-z0-9-_/.@= ]{1,155}$/;
const TicketDeatilsPopUpBox = ({ ticketCode, isOpen, onClose }) => {
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [replyItems, setReplyItems] = useState([]);
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
    const [ticketReply, setTicketReply] = useState('');
    const [ErrTicketReply, setErrTicketReply] = useState(false);
    const [uploadMail, setUploadMail] = useState('');
    const [uploadInvoiceError, setUploadInvoiceError] = useState('');

    useEffect(() => {
        if (ticketCode) {
            getTicketDetailInfo(ticketCode);
        }
    }, [ticketCode]);

    const getTicketDetailInfo = async (ticketCode) => {
        setLoading(true);

        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': { 'ticket_code': ticketCode },
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData1 = { 'token': EncryptData };

        try {
            const res = await getTicketDetails(oData1);
            if (res?.data?.data.length) {
                setItems(res.data.data[0]);
            }

            const res_reply = await getTicketReply(oData1);
            setReplyItems([]);
            if (res_reply?.data?.data.length) {
                setReplyItems(res_reply.data.data);
            }
        } catch (error) {
            console.error("Error fetching ticket details:", error);
        } finally {
            setLoading(false);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        var valid_Name = true;

        if (!TEXT_REGEX.test(ticketReply)) {
            setErrTicketReply('Ticket reply is required');
            valid_Name = false;
        } else {
            setErrTicketReply('');
        }

        if (valid_Name) {
            saveData();
        } else {
            return false;
        }
    }

    const handleUploadInvoice = (e) => {
        const file = e.target.files[0];
        const fileTypes = ['image/jpeg', 'image/png', 'application/pdf'];
        const maxSize = 2 * 1024 * 1024; // 2 MB

        if (!fileTypes.includes(file.type)) {
            setUploadInvoiceError('Only JPEG, PNG, and PDF files are allowed.');
            setUploadMail(null);
            return;
        }

        if (file.size > maxSize) {
            setUploadInvoiceError('File size should not exceed 2 MB.');
            setUploadMail(null);
            return;
        }

        setUploadMail(e.target.files);
        setUploadInvoiceError('');
    };

    const saveData = async () => {
        try {
            const file = uploadMail[0];
            let img_name = '';

            if (file) {
                const fileName = file.name;
                img_name = fileName;
                await uploadTicketFile(file, img_name);  // Ensure the file is uploaded
            }

            const obj = {
                'ticketReply': ticketReply,
                'ticket_code': ticketCode,
                'upload_file': file ? img_name : '',
                'created_by': sessionStorage.getItem('LoggedInUserId'),
            };

            // Encrypt data
            const EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': obj,
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

            const oData = { 'token': EncryptData };

            const res = await replyTicket(oData);

            if (res?.data?.errors && res.data.errors.errno > 0) {
                setErrMsg(res.data.message);
                setSuccess(false);
            } else {
                console.log("Setting success message:", res.data.message);
                setSuccess(res.data.message);  // Set success message
                getTicketDetailInfo(ticketCode);  // Fetch latest replies
                resetForm();
            }
        } catch (err) {
            setSuccess(false);
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 409) {
                setErrMsg('Data already exists');
            } else {
                setErrMsg('Insert/Update Failed');
            }
        }
    };

    const uploadTicketFile = (file, img_name) => {
        const params = {
            Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
            Key: 'image/' + img_name,
            Body: file,
        };
        s3.upload(params, (err, data) => {
            if (err) {
                console.error('Error uploading file:', err);
                setErrMsg('Error uploading file');
            } else {
                console.log('File uploaded successfully:', data);
            }
        });
    };

    const resetForm = () => {
        setTicketReply('');
        setUploadMail('');
        setErrMsg('');
        //setSuccess(false);
        setErrTicketReply(false);
        setUploadInvoiceError('');
    }

    const handleCancel = () => {
        resetForm();
        onClose();
    };

    const handleDownloadFile = async (imageId) => {
        imageId = 'image/' + imageId;
        s3.getObject({
            Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
            Key: imageId,
        }, (err, data) => {
            if (err) {
                console.error('Error downloading image:', err);
            } else {
                const url = URL.createObjectURL(new Blob([data.Body]));
                const a = document.createElement('a');
                a.href = url;
                a.download = imageId;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }
        });
    };

    function getStatusButtonClass(status) {
        switch (status) {
            case 'Open':
                return 'btn btn-danger btn-md'; // red
            case 'Assigned':
            case 'Comments added by agent':
            case 'Comments added by user':
                return 'btn btn-orange btn-md'; // orange
            case 'Completed and closed':
                return 'btn btn-success btn-md'; // green
            case 'Closed on user request':
                return 'btn btn-success btn-md';
            case 'Cancelled':
                return 'btn btn-grey btn-md';
            case 'Deferred':
                return 'btn btn-yellow btn-md';
            default:
                return 'btn btn-primary btn-md'; // default
        }
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Confirm Box"
            style={modalStyles}
        >
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Ticket Details - ({ticketCode})</h5>
                        <button type="button" className="btn-close" onClick={onClose}></button>
                    </div>
                    <div className="modal-body" style={modalBody}>
                        <Box sx={{ height: 700, width: '100%' }}>
                            <div className="row">
                                <div className="col-3"><b>Created By:</b> {items.createdByName}</div>
                                <div className="col-4"><b>Created At:</b> {getFormatedDateTime(items.created_at)}</div>
                                <div className="col-5">
                                    <b>Status: &nbsp;</b>
                                    <button
                                        type="button"
                                        className={`btn ${getStatusButtonClass(items.ticket_status)}`}
                                        style={{
                                            padding: '8px 12px',
                                            cursor: 'pointer',
                                            borderRadius: '4px',
                                            border: 'none',
                                            color: '#ffffff',
                                        }}
                                    >
                                        {items.ticket_status}
                                    </button>
                                </div>
                            </div>

                            <div className="row border-box-ticket mt-2">
                                <div className="row">
                                    <div className="col-12"><b>Subject:</b> {items.ticketSubject}</div>
                                </div>
                                <div className="row">
                                    <div className="col-12"><b>Description:</b> {items.ticketDesc}</div>
                                </div>

                                <div className="row">
                                    <div className="col-8"><b>Assigned To:</b> {items.assignedToName}</div>
                                </div>
                            </div>

                            {items.upload_file && (
                                <div className="row">
                                    <div className="col-8"><b>Attachment:</b> <a href="#/" onClick={() => handleDownloadFile(items.upload_file)}>Download</a></div>
                                </div>
                            )}

                            {replyItems.map((replyItem, index) => (
                                <div key={index}>
                                    <div className="row border-box-ticket mt-3">
                                        <div className="row">
                                            <div className="col-4"><b>Replied By:</b> {replyItem.repliedByName}</div>
                                            <div className="col-4"><b>Replied At:</b> {getFormatedDateTime(replyItem.created_at)}</div>
                                            <div className="col-12"><b>Reply:</b> {replyItem.ticketReply}</div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-12">
                                                {replyItem.upload_file && (
                                                    <div className="row">
                                                        <div className="col-8">
                                                            <b>Attachment:</b> <a href="#/" onClick={() => handleDownloadFile(replyItem.upload_file)}>Download</a>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        {replyItem.upload_file && index !== replyItems.length - 1 && <hr />}
                                    </div>
                                </div>
                            ))}

                            {success && (
                                <div className="alert alert-success" role="alert">
                                    <i className="bi bi-check-circle-fill"></i> {success}
                                </div>
                            )}

                            {errMsg && (
                                <div className="alert alert-danger" role="alert">
                                    <i className="bi bi-exclamation-triangle-fill"></i> {errMsg}
                                </div>
                            )}


                            <form onSubmit={handleSubmit}>
                                <div className="row mt-3">
                                    <div className="col-12">
                                        <FromSpFieldTextArea
                                            star="*"
                                            numRows="3"
                                            maxLength="500"
                                            label="Ticket reply"
                                            inputValue={ticketReply}
                                            setInputValue={setTicketReply}
                                            setErrorMsg={ErrTicketReply}
                                        />
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-12">
                                        <label style={{ marginRight: '10px' }}>
                                            Upload image/screenshot (if any)
                                            <br />
                                            <small className="text-muted">Upload JPEG, PNG, PDF files only. File size should not be greater than 2 MB.</small>
                                        </label>
                                        <input type="file" name='uploadInvoice' multiple onChange={handleUploadInvoice} />
                                        <span className="invalid-feedback">{uploadInvoiceError}</span>
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <div className="col-6 mb-3">
                                        <button className="btn btn-primary btn-md"> Reply</button>
                                        &nbsp;&nbsp;
                                        <button type="button" className="btn btn-secondary btn-md" onClick={handleCancel}>Cancel</button>
                                    </div>
                                    <div className="col-3"></div>
                                </div>
                            </form>
                        </Box>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

const modalStyles = {
    content: {
        width: '750px',
        height: '500px',
        margin: 'auto',
    },
};

const modalBody = {
    content: {
        position: 'relative',
        flex: '1 1 auto',
        padding: '10px !important'
    },
}

export default TicketDeatilsPopUpBox;
