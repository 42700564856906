import React, {useEffect, useState} from 'react';
import {getFilterReaderData, getReaderList} from '../../services/asset.service';
import Box from '@mui/material/Box';
import {DataGrid, GridToolbarContainer, GridToolbarExport} from '@mui/x-data-grid';
import * as CryptoJS from "crypto-js";
import FromFieldDate from "../../atoms/FromFieldDate";

function getFormatedDate(dateString) {
    if (dateString != null) {
        let dte = new Date(dateString);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }
        return year + '-' + month + '-' + day;
    } else {
        return null;
    }
}

const columns = [
    {"field": "sl_no", "hideable": true, "hide": false, "editable": false, "headerName": "Sl No", "width": 50},
    {"field": "tag", "hideable": true, "hide": false, "editable": false, "headerName": "TAG", "width": 250},
    {"field": "epc_no", "hideable": true, "hide": false, "editable": false, "headerName": "EPC No", "width": 250},
    {
        "field": "created_at", "editable": true, "hide": false, "headerName": "Date", "width": 150,
        renderCell: (params) => (
            <p>{getFormatedDate(params.row.created_at)}</p>
        )
    },
    {"field": "status", "hideable": true, "hide": false, "editable": false, "headerName": "Status", "width": 100},
    {"field": "sl_no3", "hideable": true, "hide": false, "editable": false, "headerName": "Time Out", "width": 100},
    {"field": "location", "hideable": true, "hide": false, "editable": false, "headerName": "Location", "width": 150},
    {
        "field": "rfid_reader_name",
        "hideable": true,
        "hide": false,
        "editable": false,
        "headerName": "Reader Name",
        "width": 150
    },
];

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport/>
        </GridToolbarContainer>
    );
}

function AllReaderLog() {
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);

    const [readerId, setReaderId] = useState('');
    const [ErrReaderId, setErrReaderId] = useState('');

    const [readers, setReaders] = useState([]);

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const handleReaderId = (e) => {
        setReaderId(e.target.value);
    };

    useEffect(() => {
        const fetchCompanyProfileList = async () => {
            try {
                const EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                    'authToken': sessionStorage.getItem('LoggedInToken'),
                }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
                const oData = { 'token': EncryptData };

                const res = await getReaderList(oData);
                if (res?.data?.data?.length) setReaders(res.data.data);
            } catch (error) {
                console.error("Error fetching company profile list: ", error);
            }
        };

        fetchCompanyProfileList();
    }, []); // Add dependencies if necessary

    const getTagInfo = async () => {
        setLoading(true);
        let obj = {
            'startDate': startDate ? getFormatedDate(startDate) : '',
            'endDate': endDate ? getFormatedDate(endDate) : '',
            'reader_Id': readerId ? readerId : '',

        };

        // Encrypt data
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': obj,
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

        let oData = {'token': EncryptData};

        try {
            const res = await getFilterReaderData(oData);
            setItems(res?.data?.data || []);
        } catch (error) {
            console.error('Error fetching reader log data:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h5>Reader Log</h5>
                </div>
            </div>

            <div className="row form-fields mt-4">
                <div className="label col-3">
                    <label>Reader </label><span className="star error"> *</span>
                </div>
                <div className="item col-3">
                    <select className="form-select form-select-sm mb-3" onChange={handleReaderId}
                            value={readerId}>
                        <option value="">Select Reader</option>
                        {readers && readers.length > 0 ? (
                            readers.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.rfid_reader_name}
                                </option>
                            ))
                        ) : (
                            <option value="">No Data</option>
                        )}
                    </select>
                    <span className="invalid-feedback"> {ErrReaderId} </span>
                </div>
            </div>

            <div className="row">
                <div className="col-4">
                    <FromFieldDate
                        label="Start Date"
                        placeholder="Select Start Date"
                        inputValue={startDate}
                        setInputValue={setStartDate}
                    />
                </div>
                <div className="col-4">
                    <FromFieldDate
                        label="End Date"
                        placeholder="Select End Date"
                        inputValue={endDate}
                        setInputValue={setEndDate}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-4 text-center mt-2 mb-3">
                    <button onClick={getTagInfo} className='btn btn-info btn-md'>
                        <i className="bi bi-search"></i> Search
                    </button>
                </div>
            </div>

            <div className='row'>
                <Box sx={{height: 500, width: '100%'}}>
                    {loading ? (
                        <div className="text-center">
                            <div className="spinner-border text-primary" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    ) : (
                        <DataGrid
                            rows={items}
                            getRowId={(row) => row.sl_no}
                            columns={columns}
                            pageSize={50}
                            rowsPerPageOptions={[50]}
                            components={{
                                Toolbar: CustomToolbar,
                            }}
                        />
                    )}
                </Box>
            </div>
            <div id='print_qr'></div>
        </div>
    )
}

export default AllReaderLog;
