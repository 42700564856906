import React, { useState, useEffect } from 'react';
import { getAssets, saveAssetDisposal } from '../../../services/asset.service';
import { DataGrid } from '@mui/x-data-grid';
import  * as CryptoJS  from "crypto-js";

const columns = [

    {"field":"sl_no","hideable":true,"hide":true,"headerName":"Sl No","width":50},
    {
        "field": "tag", "hide": false, "headerName": "TAG", "width": 80,
        renderCell: (params) => (
            <a href={`/asset-disposal?tag=${params.value}`}>{params.value}</a>
        )
    },
    { "field": "sale_initiated", "hideable": true, "hide": false,  "headerName": "Sale initiated By", "width": 130 },
    { "field": "date_of_initiation",  "hide": false, "headerName": "Date of Initiation", "width": 130 },
    { "field": "sale_authorized_by",  "hide": false, "headerName": "Sale Authorized By", "width": 130 },
    { "field": "date_of_authorization",  "hide": false, "headerName": "Date of Authorization", "width": 130 },
    { "field": "reason_for_disposal",  "hide": false, "headerName": "Reason for Disposal", "width": 130 },
    { "field": "type_date_of_disposal",  "hide": false, "headerName": "Target Date for Disposal", "width": 130 },
    { "field": "type_of_disposal",  "hide": false, "headerName": "Type of Disposal", "width": 130 },
    { "field": "partial_specify",  "hide": false, "headerName": "If Partial, Specify as % of Capitalized Value", "width": 130 },
    { "field": "sub_status",  "hide": false, "headerName": "Sub Status", "width": 130 },
];

function BulkUploadList() {
    const [items, setItems] = useState([]);
    var onCellEditStopFn = (event, newCell) => {
        var obj = {};
        obj[newCell.field] = event.target.value;
    }

    useEffect(() => {
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            //'param_data':{'tag':tag},
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token':EncryptData}
        getAssets(oData).then(function (res) {
            //if(res?.data?.data) {
            if (res?.data?.data?.length) setItems(res.data.data);
        })
    }, [])

    // readAssets();
    return (
        <div className='container'>
            <h3>
                <h2> Bulk Disposal - List</h2>

            </h3>
            <DataGrid
                rows={items}
                getRowId={(row) => row.sl_no}
                columns={columns}
                pageSize={5}
                autoHeight
                rowsPerPageOptions={[5]}
                onCellEditStop={onCellEditStopFn}
            />

            <div className="row mt-4">
                <div className="col-12 text-center">
                    <button className="btn btn-primary btn-md me-2"> Edit </button>
                    <button className="btn btn-primary btn-md me-2"> Cancel </button>
                    <button className="btn btn-primary btn-md"> INITIATE  / UPDATE </button>
                </div>
            </div>

        </div>
    )
}

export default BulkUploadList


