import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import FromFieldText from '../../../atoms/FromFieldText';
import {getIncomeInfo, saveIncomeTax} from '../../../services/asset.service';
import {getCompanyProfileList, getRoleInfo, saveRole} from '../../../services/user.service';
import * as CryptoJS from "crypto-js";
import FromFieldNumber from "../../../atoms/FromFieldNumber";

const TEXT_REGEX = /^[a-zA-Z0-9-_ ]{1,255}$/;
//const Email_REGEX = /^[a-zA-Z0-9@.-_ ]{1,255}$/;
//const Mobile_REGEX = /^[0-9]{10,10}$/;


function AddIncomeTaxMaster() {

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    const [companyId, setCompanyId] = useState('');
    const [ErrCompanyId, setErrCompanyId] = useState(false);

    const [incomeTaxClassification, setIncomeTaxClassification] = useState('');
    const [ErrIncomeTaxClassification, setErrIncomeTaxClassification] = useState(false);

    const [incomeTaxRate, setIncomeTaxRate] = useState('');
    const [ErrIncomeTaxRate, setErrIncomeTaxRate] = useState(false);
    const [companies, setCompanies] = useState([]);
    const resetUserInfo = function () {
        setIncomeTaxClassification('');
        setIncomeTaxRate('');
        setCompanyId('');
    }
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const IncomeID = query.get('income_id');

    useEffect(() => {

        if (IncomeID != '' && IncomeID != null) {

            const decryptedBytes = CryptoJS.AES.decrypt(IncomeID.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
            // Convert the decrypted bytes to a string
            const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
            console.log(decrypt_data);
            const decryptedIncomeID = decrypt_data.income_id;

            // Use decryptedRoleid in the getRoleInfoData function
            getIncomeInfoData(decryptedIncomeID);

        }
        //encrypted data
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            //'param_data':obj,
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token': EncryptData}
        getCompanyProfileList(oData).then(res => {
            if (res?.data?.data?.length) setCompanies(res.data.data);
        })
    }, [])

    const getIncomeInfoData = function (decryptedIncomeID) {
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': {'income_id': decryptedIncomeID},
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData1 = {'token': EncryptData}
        getIncomeInfo(oData1).then(function (res) {

            if (res?.data?.data) {
                var incomeInfo = res.data.data[0];
                setCompanyId(incomeInfo.company_id);
                setIncomeTaxClassification(incomeInfo.income_tax_classification);
                setIncomeTaxRate(incomeInfo.income_tax_rate);
            }
        })
    }
    const handleCompanyId = (e) => {
        //let name= e.target.name;
        // let value= e.target.value;
        setCompanyId(e.target.value);
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        var valid_Name = true;
        // if button enabled with JS hack
        //const v1 = TEXT_REGEX.test(name);
        if (!TEXT_REGEX.test(companyId)) { //alert(name);
            setErrCompanyId('Company name is required');
            valid_Name = false; //alert(valid_Name);
            //return;
        } else {
            setErrCompanyId('');
        }
        if (!TEXT_REGEX.test(incomeTaxClassification)) { //alert(name);
            setErrIncomeTaxClassification('Income tax classification is required');
            valid_Name = false; //alert(valid_Name);
            //return;
        } else {
            setErrIncomeTaxClassification('');
        }
        if (!TEXT_REGEX.test(incomeTaxRate)) { //alert(companyId);
            setErrIncomeTaxRate('Income tax rate is required');
            valid_Name = false;
            //return;
        } else {
            setErrIncomeTaxRate('');
        }
        if (valid_Name) { //alert('test1');
            saveData(); //submit form
        } else {  //alert('fa');
            return false;
        }
    }

    const saveData = function () {
        try {

            if (IncomeID !== '' && IncomeID !== null) {

                const decryptedBytes = CryptoJS.AES.decrypt(IncomeID.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
                // Convert the decrypted bytes to a string
                const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
                const decryptedIncomeId = decrypt_data.income_id;
                var obj = {
                    'income_ids': decryptedIncomeId,
                    'income_tax_classification': incomeTaxClassification,
                    'income_tax_rate': incomeTaxRate,
                    'company_id': companyId,
                    'status': '1',
                    'created_by': sessionStorage.getItem('LoggedInUserId'),
                }
            } else {
                var obj = {
                    'income_tax_classification': incomeTaxClassification,
                    'income_tax_rate': incomeTaxRate,
                    'company_id': companyId,
                    'status': '1',
                    'created_by': sessionStorage.getItem('LoggedInUserId'),
                }
            }


            //encrypted data
            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': obj,
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData = {'token': EncryptData}
            saveIncomeTax(oData).then(function (res) {
                //console.log(res);
                if ((typeof (res.data.errors) != "undefined") && res.data.errors.errno > 0) {
                    setErrMsg(res.data.message);
                    setSuccess(false);
                } else {
                    setSuccess(res.data.message);
                    resetUserInfo();
                }
            });

            //setValidName(false);
        } catch (err) {
            setSuccess(false);
            if (!err?.data) {
                console.log(err);
                setErrMsg('No Server Response');
            } else if (err.data?.status === 409) {
                setErrMsg('data already exist');
            } else {
                setErrMsg('Insert/Update Failed');
            }
            //elementRef.current.focus();
        }
    }

    return (
        <div className="container">

            <div className="row">
                <div className="col-6">
                    <h5>Add Income Tax Details</h5>
                </div>
                <div className="invalid-feedback" role="alert">
                    <i className="fa fa-info-circle"></i>
                    Please Fill all mandatory field.
                </div>

                {success ? (
                    <div className="alert alert-success" role="alert"><i
                        className="bi bi-check-circle-fill"></i>{success}</div>
                ) : (errMsg ? (<div className="alert alert-danger" role="alert"><i
                        className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>) : ('')
                )}
            </div>

            <div className='row '>
                <div className='col-12'>&nbsp;</div>

            </div>
            <form onSubmit={handleSubmit}>
                <div className="row form-fields">
                    <div className="label col-3">
                        <label>Company Name </label><span className="star error"> *</span>
                    </div>

                    <div className="item col-3">
                        <select className="form-select form-select-sm mb-3" onChange={handleCompanyId}>
                            <option value="">Select Company</option>
                            {companies && companies.length > 0 ? (
                                companies.map((option) => (
                                    <option value={option.company_id}
                                            selected={(option.company_id == companyId) ? "selected" : ''}>{option.company_name}</option>
                                ))
                            ) : (
                                <option value="">No Data</option>
                            )}
                        </select>
                        <span className="invalid-feedback"> {ErrCompanyId} </span>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <FromFieldText star="*"
                                       label="Income Tax Classification"
                                       inputValue={incomeTaxClassification}
                                       setInputValue={setIncomeTaxClassification}
                                       setErrorMsg={ErrIncomeTaxClassification}
                        />
                    </div>
                    <div className="col-6"></div>
                    <div className="col-6">



                        <FromFieldNumber
                            star="*"
                            label="Income Tax Rate (<= 100)"
                            placeholder=''
                            inputValue={incomeTaxRate}
                            setInputValue={(value) => {
                                // Validate that the entered value is a number and less than or equal to 100
                                const numericValue = Number(value);

                                if (!isNaN(numericValue) && numericValue >= 1 && numericValue <= 100) {
                                    setIncomeTaxRate(value);
                                    setErrIncomeTaxRate("");
                                } else {
                                    setErrIncomeTaxRate("Please enter a valid number (<= 100).");
                                }
                            }}
                            setErrorMsg={setErrIncomeTaxRate}
                        />


                    </div>
                </div>

                <div className="row">
                    <div className="col-3"></div>
                    <div className="col-6">
                        <button className="btn btn-primary btn-md"> SAVE</button>


                    </div>
                    <div className="col-3"></div>
                </div>
            </form>
        </div>
    )
}

export default AddIncomeTaxMaster


