import React, { useState,useEffect } from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { getUserList } from '../../services/user.service'
import  * as CryptoJS  from "crypto-js";

const columns = [
    
    {"field":"id","editable":false,"hide":false ,"headerName":"S.No.","width":130 },
    {"field":"name","editable":true,"hide":false ,"headerName":"Name","width":230 ,
        renderCell: (params) => {
            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'userid': params.row.id,
                'compid': params.row.company_id,
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

            return (
                <span>
            <a href={`/userview/?data=${EncryptData}&compid=${EncryptData}`}>{params.row.name}</a>
        </span>
            );
        }
    },
    {"field":"email","editable":false,"hide":false ,"headerName":"Email","width":280},
    {"field":"mobile","editable":false,"hide":false ,"headerName":"Mobile","width":130},
    {"field":"company_name","editable":false,"hide":false ,"headerName":"Company","width":350},
    {"field":"role_name","editable":false,"hide":false ,"headerName":"Role","width":280},
    {"field":"division_name","editable":false,"hide":false ,"headerName":"Division","width":280},
    {"field":"department_name","editable":false,"hide":false ,"headerName":"Department","width":280},
    {"field":"sub_department_name","editable":false,"hide":false ,"headerName":"Sub Department Name","width":280},
    {"field":"status","editable":false,"hide":false ,"headerName":"Status","width":60,
      renderCell: (params) => (
        <span>{(params.row.status==="1")?'Active':'Deactive'}</span>
    ) },
    {
        field: 'actions', headerName: 'Actions', width: 80, 
        renderCell: (params) => {
            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'userid': params.row.id,
                'compid': params.row.company_id,
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

            return (
                <span>
            <a href={`/register/?data=${EncryptData}&compid=${EncryptData}`}>
                <button type="button" className="btn btn-primary btn-md">
                    <i className="bi bi-pencil"></i>
                </button>
            </a>
        </span>
            );
        }

    }
    
    
];

function UsersList() {
  const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
  /*var onCellEditStopFn = (event,newCell) => {
    var obj = {};
    obj[newCell.field] = event.target.value;
  }*/

    useEffect(() => {
        //encrypted data
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            //'param_data':obj,
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = { 'token': EncryptData };

        setLoading(true); // Set loading to true when starting the API request

        getUserList(oData)
            .then(res => {
                if (res?.data?.data?.length) {
                    setItems(res.data.data);
                }
            })
            .finally(() => {
                setLoading(false); // Set loading to false when the API request is completed (regardless of success or failure)
            });
    }, []);


    // readUser();
  return (


      <div className='container'>
          <div className='row'>
              <div className='col-md-12'>
                  <h5>User List</h5>
              </div>
          </div>
          <div className='row'>
              <div className='col-md-12'>

                      {loading ? (
                          <div className="text-center">
                              <div className="spinner-border text-primary" role="status">
                                  <span className="visually-hidden">Loading...</span>
                              </div>
                          </div>
                      ) : (
                          <DataGrid
                              rows={items}
                              getRowId={(row) => row.id}
                              columns={columns}
                              pageSize={50}
                              autoHeight
                              rowsPerPageOptions={[50]}
                          />
                      )}

              </div>
          </div>
      </div>

  )
}

export default UsersList
