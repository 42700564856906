import React, {useEffect, useRef, useState} from 'react'
import {useLocation} from "react-router-dom";
import Select from 'react-select';
import FromFieldText from '../../atoms/FromFieldText';
import FromSpFieldText from '../../atoms/FromSpFieldText';
import FromFieldDate from '../../atoms/FromFieldDate';
import FromFieldNumber from '../../atoms/FromFieldNumber';
import FromSpFieldTextArea from '../../atoms/FromSpFieldTextArea';
import '../ExcelUploads/AssetInfo/SingleAsset.css';
import {getBondingConfigure,saveBondingConfigure} from '../../services/stpi.service';
import * as CryptoJS from "crypto-js";

function getFormatedDate(dateString) {
    if (dateString != null) {
        let dte = new Date(dateString);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }
        return year + '-' + month + '-' + day;
    } else {
        return null;
    }
}

const TEXT_REGEX = /^[A-Za-z0-9-_/.@= ]{1,155}$/;
const TEXT_SPCL_REGEX = /^[A-Za-z0-9-_.,\/\[\]\-()"'+&'%$#@*!= ]{1,500}$/;
const NUMBER_REGEX = /^[0-9.]{1,15}$/;
const TEXT_DATE = /^[a-zA-Z0-9-_+:/() ]{1,35}$/;
export default function BondingConfigure() {
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const BondConfigId = query.get('bondConfigId');
    const BackUrl = query.get('back_url');
    const elementRef = useRef();
    const [selectedYearOptions, setSelectedYearOptions] = useState(null);
    useEffect(() => {
        /*if (BondConfigId !== '' && BondConfigId !== null) {

            const decryptedBytes = CryptoJS.AES.decrypt(BondConfigId.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
            // Convert the decrypted bytes to a string
            const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
            const decryptedBondConfigId = decrypt_data.bondConfigId;
            getBondConfigIdAasetInfo(decryptedBondConfigId);
            //alert(BondConfigId);
        }*/
        getBondConfigIdAasetInfo();

        
    }, [])

    function reset() {
        setNameAsPerSTPI('');
        setAddressAsPerSTPI('');
        setLopNumber('');
        setStpiLopDate('');
        setRenewalOfLOP('');
        setRenewalOfLopDate('');
        setLegalUndertakingDate('');
        setPbwhlNo('');
        setGstinNo('');
        setEmailIdSTPI('');
        setPICforSTPI('');
        setContNumforSTPI('');
    }

    const setOldBondingConfigure = function (auditInfo) {
        var AuditOldData = {
            'bondConfigId': auditInfo.bondConfigId,
            'name_as_per_stpi': auditInfo.name_as_per_stpi,
            'address_as_per_stpi': auditInfo.address_as_per_stpi,
            'lop_number': auditInfo.lop_number,
            'stpi_lop_date': (auditInfo.stpi_lop_date ? getFormatedDate(auditInfo.stpi_lop_date) : ''),
            'renewal_of_lop': auditInfo.renewal_of_lop,
            'renewal_of_lop_date': (auditInfo.renewal_of_lop_date ? getFormatedDate(auditInfo.renewal_of_lop_date) : ''),
            'legal_undertaking_date': (auditInfo.legal_undertaking_date ? getFormatedDate(auditInfo.legal_undertaking_date) : ''),
            'pbwhl_number': auditInfo.pbwhl_number,
            'gstin_number': auditInfo.gstin_number,
            'email_stpi': auditInfo.email_stpi,
            'pic_for_stpi': auditInfo.pic_for_stpi,
            'contact_for_stpi': auditInfo.contact_for_stpi,
            'company_id': auditInfo.company_id,
            'division_id': auditInfo.division_id,
            'created_by': auditInfo.created_by,
        };
        setAuditInfo(AuditOldData);
    }
    const getBondConfigIdAasetInfo = function (BondConfigId) {

        try {
            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                //'param_data': {'bondConfigId': BondConfigId},
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData1 = {'token': EncryptData}
            getBondingConfigure(oData1).then(function (res) {
                //console.log(res.data.data);
                if (res?.data?.data?.data) {
                    var configInfo = res.data.data.data;
                    setNameAsPerSTPI(configInfo.name_as_per_stpi);
                    setAddressAsPerSTPI(configInfo.address_as_per_stpi);
                    setLopNumber(configInfo.lop_number);
                    setStpiLopDate(new Date(configInfo.stpi_lop_date));
                    setRenewalOfLOP(configInfo.renewal_of_lop);
                    setRenewalOfLopDate(new Date(configInfo.renewal_of_lop_date));
                    setLegalUndertakingDate(new Date(configInfo.legal_undertaking_date));
                    setPbwhlNo(configInfo.pbwhl_number);
                    setGstinNo(configInfo.gstin_number);
                    setEmailIdSTPI(configInfo.email_stpi);
                    setPICforSTPI(configInfo.pic_for_stpi);
                    setContNumforSTPI(configInfo.contact_for_stpi);
                }
            })
        } catch (err) {
            //setAssetInfo('');
        }

    }
    //const userRef = useRef();
    const errRef = useRef();
    const [validName, setValidName] = useState(true);
    const [disabled, setDisabled] = useState(false);
    const [auditInfo, setAuditInfo] = useState([]);
    
    const [nameAsPerSTPI, setNameAsPerSTPI] = useState('');
    const [ErrNameAsPerSTPI, setErrNameAsPerSTPI] = useState(false);

    const [addressAsPerSTPI, setAddressAsPerSTPI] = useState('');
    const [ErrAddressAsPerSTPI, setErrAddressAsPerSTPI] = useState(false);

    const [LopNumber, setLopNumber] = useState('');
    const [ErrLopNumber, setErrLopNumber] = useState(false);

    const [StpiLopDate, setStpiLopDate] = useState('');
    const [ErrStpiLopDate, setErrStpiLopDate] = useState(false);

    const [RenewalOfLOP, setRenewalOfLOP] = useState('');
    const [ErrRenewalOfLOP, setErrRenewalOfLOP] = useState(false);

    const [RenewalOfLopDate, setRenewalOfLopDate] = useState('');
    const [ErrRenewalOfLopDate, setErrRenewalOfLopDate] = useState(false);

    const [LegalUndertakingDate, setLegalUndertakingDate] = useState('');
    const [ErrLegalUndertakingDate, setErrLegalUndertakingDate] = useState(false);

    const [PbwhlNo, setPbwhlNo] = useState('');
    const [ErrPbwhlNo, setErrPbwhlNo] = useState(false);

    const [GstinNo, setGstinNo] = useState('');
    const [ErrGstinNo, setErrGstinNo] = useState(false);

    const [emailIdSTPI, setEmailIdSTPI] = useState('');
    const [ErrEmailIdSTPI, setErrEmailIdSTPI] = useState(false);

    const [PICforSTPI, setPICforSTPI] = useState('');
    const [ErrPICforSTPI, setErrPICforSTPI] = useState(false);

    const [ContNumforSTPI, setContNumforSTPI] = useState('');
    const [ErrContNumforSTPI, setErrContNumforSTPI] = useState(false);

    const [bondConfigId, setBondConfigId] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);


    const handleSubmit = async (e) => {
        e.preventDefault();
        var valid_Name = true; //alert(valid_Name);
        // if button enabled with JS hack
        //const v1 = TEXT_REGEX.test(yearaddition);
        if (!TEXT_REGEX.test(nameAsPerSTPI)) {
            setErrNameAsPerSTPI('Name As Per STPI is required');
            valid_Name = false; //alert('1');
            //return;
        } else {
            setErrNameAsPerSTPI('');
        }
        if (!TEXT_REGEX.test(addressAsPerSTPI)) {
            setErrAddressAsPerSTPI('Address As Per STPI is required');
            valid_Name = false; //alert('2');
            //return;
        } else {
            setErrAddressAsPerSTPI('');
        }
        if (!TEXT_REGEX.test(LopNumber)) {
            setErrLopNumber('STPI Letter of Permission (LOP) Number is required');
            valid_Name = false; //alert('3');
            //return;
        } else {
            setErrLopNumber('');
        }
        if (StpiLopDate!="") {
            setErrStpiLopDate('');
            //return;
        } else { 
            setErrStpiLopDate('STPI LOP Date is required');
            valid_Name = false; //alert('3');
        }
        if (!TEXT_REGEX.test(RenewalOfLOP)) {
            setErrRenewalOfLOP('Renewal of LOP is required');
            valid_Name = false; //alert('3');
        } else {
            setErrRenewalOfLOP('');
        }
        if (RenewalOfLopDate!="") {
            setErrRenewalOfLopDate('');
            
        } else { 
            setErrRenewalOfLopDate('Renewal of LOP Date is required');
            valid_Name = false; //alert('3');
        }
        if (LegalUndertakingDate!="") {
            setErrLegalUndertakingDate('');
        } else { 
            setErrLegalUndertakingDate('Legal Undertaking Date is required');
            valid_Name = false; //alert('3');
        }
        if (!TEXT_REGEX.test(PbwhlNo)) {
            setErrPbwhlNo('PBWHL No. is required');
            valid_Name = false; //alert('3');
            //return;
        } else {
            setErrPbwhlNo('');
        }
        if (!TEXT_REGEX.test(GstinNo)) {
            setErrGstinNo('GSTIN No. is required');
            valid_Name = false; //alert('3');
            //return;
        } else {
            setErrGstinNo('');
        }
       
        if (!TEXT_REGEX.test(emailIdSTPI)) {
            setErrEmailIdSTPI('Email ID for STPI is required');
            valid_Name = false;
        } else {
            setErrEmailIdSTPI('');
        }
        if (!TEXT_REGEX.test(ContNumforSTPI)) {
            setErrContNumforSTPI('Contact Number for STPI is required');
            valid_Name = false; //alert('3');
            //return;
        } else {
            setErrContNumforSTPI('');
        }
        if (!TEXT_REGEX.test(PICforSTPI)) {
            setErrPICforSTPI('PIC for STPI is required');
            valid_Name = false; //alert('3');
            //return;
        } else {
            setErrPICforSTPI('');
        }
        
        if (valid_Name) { //alert('validName'); 
            saveData(); //submit form
        } else {
            //alert('valid_Name false');
            return false;
        }
    }
    
    const saveData = function () {
        var data = {
            'bondConfigId': bondConfigId,
            'name_as_per_stpi': nameAsPerSTPI,
            'address_as_per_stpi': addressAsPerSTPI,
            'lop_number': LopNumber,
            'stpi_lop_date': getFormatedDate(StpiLopDate),
            'renewal_of_lop': RenewalOfLOP,
            'renewal_of_lop_date': getFormatedDate(RenewalOfLopDate),
            'legal_undertaking_date': getFormatedDate(LegalUndertakingDate),
            'pbwhl_number': PbwhlNo,
            'gstin_number': GstinNo,
            'email_stpi': emailIdSTPI,
            'pic_for_stpi': PICforSTPI,
            'contact_for_stpi': ContNumforSTPI,
            'company_id': sessionStorage.getItem('LoggedInCompanyId'),
            'division_id': sessionStorage.getItem('LoggedInDivisionId'),
            'created_by': sessionStorage.getItem('LoggedInUserId'),
            
        };
        if (BondConfigId !== '' && BondConfigId !== null) {
            var objAudit = {
                'audit_module': 'Addition',
                'audit_method': 'updated',
                'audit_bondConfigId': bondConfigId,
                'old_values': auditInfo,
                'new_values': data
            }
        } else {
            var objAudit = {
                'audit_module': 'Addition',
                'audit_method': 'created',
                'audit_bondConfigId': bondConfigId,
                'old_values': '',
                'new_values': data
            }
        }

        try {
            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': data, 'audit_params': objAudit,
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData2 = {'token': EncryptData}
            saveBondingConfigure(oData2).then(function (res) {
                if ((typeof (res.data.errors) !== "undefined") && res.data.errors.errno > 0) {
                    setErrMsg(res.data.message);
                    setSuccess(false);
                } else {
                    setSuccess(res.data.message);
                    setDisabled(true);
                    setErrMsg(false);
                    reset();
                }
            });
        } catch (err) {
            setSuccess(false);
            if (!err?.data) {
                console.log(err);
                setErrMsg('No Server Response');
            } else if (err.data?.status === 409) {
                setErrMsg('Username Taken');
            } else {
                setErrMsg('Insert/Update Failed');
            }
            //elementRef.current.focus();
        }
    }

    return (
        <div className='container' id="single-asset">
            <div className='row' ref={elementRef}>
                <div className="col">
                    <h5>Bonding Configure Form</h5>
                </div>

                <div className="invalid-feedback" role="alert">
                    <i className="fa fa-info-circle"></i>
                    Please Fill all mandatory field.
                </div>

                {success ? (
                    <div className="alert alert-success" role="alert"><i
                        className="bi bi-check-circle-fill"></i>{success}</div>
                ) : (errMsg ? (<div className="alert alert-danger" role="alert"><i
                        className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>) : ('')
                )}
            </div>
            <form onSubmit={handleSubmit} encType="multipart/form-data" method="post">
                
                <div className='row m-2'>
                    <div className='col-md-6'>
                        <FromSpFieldText label="Name as per STPI Records"
                                         inputValue={nameAsPerSTPI}
                                         setInputValue={setNameAsPerSTPI}
                                         setErrorMsg={ErrNameAsPerSTPI}
                        />
                    </div>
                    <div className='col-md-6'>
                        <FromSpFieldText label="Address as per STPI Records"
                                        inputValue={addressAsPerSTPI}
                                        setInputValue={setAddressAsPerSTPI}
                                        setErrorMsg={ErrAddressAsPerSTPI}
                        />
                    </div>
                </div>

                <div className='row m-2'>
                    <div className='col-md-6'>
                        <FromSpFieldText label="STPI Letter of Permission (LOP) Number "
                                         inputValue={LopNumber}
                                         setInputValue={setLopNumber}
                                         setErrorMsg={ErrLopNumber}

                        />
                    </div>
                    <div className='col-md-6'>
                        <FromFieldDate label="STPI LOP Date" star="*" 
                                         placeholder="YYYY-MM-DD"
                                         inputValue={StpiLopDate}
                                         setInputValue={setStpiLopDate}
                                         setErrorMsg={ErrStpiLopDate}
                        />
                    </div>
                </div>

                <div className='row m-2'>
                    <div className='col-md-6'>
                        <FromSpFieldText label="Renewal of LOP" star="*"
                                         inputValue={RenewalOfLOP}
                                         setInputValue={setRenewalOfLOP}
                                         setErrorMsg={ErrRenewalOfLOP}
                        />
                    </div>
                    <div className='col-md-6'>
                        <FromFieldDate
                            label="Renewal of LOP Date" star="*" 
                            placeholder="YYYY-MM-DD"
                            inputValue={RenewalOfLopDate}
                            setInputValue={setRenewalOfLopDate}
                            setErrorMsg={ErrRenewalOfLopDate}
                        />
                    </div>
                </div>


                <div className='row m-2'>
                    <div className='col-md-6'>
                        <FromFieldDate label="Legal Undertaking (LUT) Date" star="*"
                                        placeholder="YYYY-MM-DD"
                                         inputValue={LegalUndertakingDate}
                                         setInputValue={setLegalUndertakingDate}
                                         setErrorMsg={ErrLegalUndertakingDate}
                        />
                    </div>
                    <div className='col-md-6'>
                        <FromFieldText
                            label="Private Bonded Warehouse License (PBWHL) No" 
                            star="*" 
                            inputValue={PbwhlNo}
                            setInputValue={setPbwhlNo}
                            setErrorMsg={ErrPbwhlNo}
                        />
                    </div>
                </div>

                <div className='row m-2'>
                    <div className='col-md-6'>
                        <FromSpFieldText label="GSTIN No." star="*"
                                         inputValue={GstinNo}
                                         setInputValue={setGstinNo}
                                         setErrorMsg={ErrGstinNo}
                        />
                    </div>
                    <div className='col-md-6'>
                        <FromSpFieldText
                            label="Email ID for STPI" star="*" 
                            inputValue={emailIdSTPI}
                            setInputValue={setEmailIdSTPI}
                            setErrorMsg={ErrEmailIdSTPI}
                        />
                    </div>
                </div>

                <div className='row m-2'>
                    <div className='col-md-6'>
                        <FromFieldText label="Contact Number for STPI" //star="*"
                                         inputValue={ContNumforSTPI}
                                         setInputValue={setContNumforSTPI}
                                         setErrorMsg={ErrContNumforSTPI}
                            //readOnly="readonly"
                        />
                    </div>
                    <div className='col-md-6'>
                        <FromSpFieldText label="PIC for STPI" //star="*"
                                         inputValue={PICforSTPI}
                                         setInputValue={setPICforSTPI}
                                         setErrorMsg={ErrPICforSTPI}
                            //readOnly="readonly"
                        />
                    </div>
                </div>
                

                
                <div className='row save__button text-center'>
                    <div className='col-md-12'>
                        {(BondConfigId !== '' && BondConfigId !== null) ? <div>
                                <button className="btn btn-primary btn-md" disabled={disabled}>Update</button>
                                &nbsp;&nbsp;
                                {(BackUrl !== '' && BackUrl !== null) ?( <a href={'/consumable-asset-details?bondConfigId=' + BondConfigId}
                                               className="btn btn-secondary btn-md">Cancel</a>) :(<a href={'/asset-details?bondConfigId=' + BondConfigId } className="btn btn-secondary btn-md">Cancel</a>)}
                                </div>
                            : <button id="btn-submit" className="btn btn-primary btn-md"
                                      disabled={disabled}> SAVE </button>
                        }

                    </div>
                </div>
            </form>
        </div>
    )
}
