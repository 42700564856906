import React, {useEffect, useState} from 'react'
import {useLocation} from "react-router-dom";
import FromFieldLabel from '../../../atoms/FromFieldLabel';
//import FromFieldText from '../../../atoms/FromFieldText';
import FromFieldTextArea from '../../../atoms/FromFieldTextArea';
import FromFieldDate from '../../../atoms/FromFieldDate';
import {getAllocation, saveUnallocated} from '../../../services/asset.service';
import FromSpFieldTextArea from '../../../atoms/FromSpFieldTextArea';
import * as CryptoJS from "crypto-js";

/*Date.prototype.dbFormatedDate = function () {
    return `${this.getFullYear()}-${this.getMonth()+1}-${this.getDate()}`
  }
 */
Date.prototype.dbFormatedDate = function () {

    let year = this.getFullYear();
    let month = this.getMonth() + 1;
    let day = this.getDate();
    if (month < 10) {
        month = '0' + month;
    }
    if (day < 10) {
        day = '0' + day;
    }
    return year + '-' + month + '-' + day;
    //return `${this.getFullYear()}-${this.getMonth()+1}-${this.getDate()-1}`
}

function getFormatedDate(dateString) {
    if (dateString !== null) {
        let dte = new Date(dateString);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }
        return year + '-' + month + '-' + day;
        //return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
    } else {
        return null;
    }
}

const TEXT_REGEX = /^[a-zA-Z0-9-_ ]{1,500}$/;
const TEXT_SPCL_REGEX = /^[A-Za-z0-9-_.,\/\[\]\-()"'+&'%$#@*!= ]{1,500}$/;

//const NUMBER_REGEX = /^[0-9]{1,15}$/;

function SingleNonAllocation() {

    const [assetInfo, setAssetInfo] = useState({});
    const [validName, setValidName] = useState(true);
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    const [tag, setTag] = useState('');
    const [ErrTag, setErrTag] = useState(false);

    const [division, setDivision] = useState('');
    const [premises, setPremises] = useState('');
    const [floor, setFloor] = useState('');
    const [siteId, setSiteId] = useState('');
    const [userType, setUserType] = useState('');
    const [userDepartment, setUserDepartment] = useState('');
    const [employeeId, setEmployeeId] = useState('');
    const [ErrEmployeeId, setErrEmployeeId] = useState(false);
    const [employeeName, setemployeeName] = useState('');

    const [dateUnallocation, setDateUnallocation] = useState('');
    const [ErrDateUnallocation, setErrDateUnallocation] = useState(false);

    const [reason, setReason] = useState('');
    const [ErrReason, setErrReason] = useState(false);

    const [assetinusedate, setAssetInuseDate] = useState('');

    const [childAllocation, setChildAllocation] = useState('');
    const [ErrChildAllocation, setErrChildAllocation] = useState(false);
    const [auditInfo, setAuditInfo] = useState([]);
    const resetAssetInfo = function () {
        setTag('');
        setDateUnallocation('');
        setReason('');
        setChildAllocation('');
    }

    const handleChildAllocation = (e) => {
        setChildAllocation(e.target.value);
    }

    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const Tag = query.get('tag');
    //const elementRef = useRef();
    useEffect(() => {
        if (Tag !== '' && Tag !== null) {
            const decryptedBytes = CryptoJS.AES.decrypt(Tag.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
            // Convert the decrypted bytes to a string
            const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
            const decryptedTag = decrypt_data.tag;
            getTagAssetInfo(decryptedTag);
            //alert(Tag);
        } else {
            resetAssetInfo();
        }

    }, [])
    const getTagAssetInfo = function (decryptedTag) {
        resetAssetInfo();
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': {'tag': decryptedTag},
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token': EncryptData}
        getAllocation(oData).then(function (res) {
            //getTransferData(Tag).then(function(res){
            if (res?.data?.data?.assetInfo) {
                var aInfo = res.data.data.assetInfo;
                var aTranInfo = res.data.data.transferInfo;
                var aLogInfo = res.data.data.logInfo;
                console.log(aInfo);
                setAssetInfo(aInfo);
                
                //setInvoiceDate((new Date(Info.invoice_date)));
                setTag(aInfo.tag);
                //console.log(Info);
                if (res.data.data?.logInfo && (aLogInfo.unallocate_date !== null) && (aLogInfo.unallocate_date > aInfo.asset_in_use_date)) {
                    //console.log('aLogInfo.unallocate_date');
                    setAssetInuseDate(aLogInfo.unallocate_date ? (new Date(aLogInfo.unallocate_date)) : '');
                } else { //(res.data.data?.logInfo && (aLogInfo.effective_date !== null)) {
                    setAssetInuseDate(aTranInfo.effective_date ? (new Date(aTranInfo.effective_date)) : '');
                    console.log('aInfo.effective_date');console.log(aTranInfo.effective_date);
                } 
            }
            if (res?.data?.data?.transferInfo) {
                var Info = res.data.data.transferInfo;
                setAuditInfo(Info);
                //setInvoiceDate((new Date(aInfo.invoice_date)));
                setTag(Info.tag);
                setDivision(Info.division);
                setPremises(Info.premises);
                setFloor(Info.floor);
                setSiteId(Info.site_id);
                setUserType(Info.user_type);
                setUserDepartment(Info.user_department);
                setEmployeeId(Info.employee_id);
                setemployeeName(Info.employee_name);
                setDateUnallocation(Info.unallocate_date);
                setReason(Info.reason);
            }
        })
    }
    const getTagInfo = function () {
        resetAssetInfo();
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': {'tag': Tag},
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData1 = {'token': EncryptData}
        getAllocation(oData1).then(function (res) {
            console.log(res.data.data);
            var aInfo = {};
            //getTransferData(tag).then(function(res){
            if (res?.data?.data?.assetInfo) {
                aInfo = res.data.data.assetInfo;
                var aLogInfo = res.data.data.logInfo;
                setAssetInfo(aInfo);
                setTag(aInfo.tag);
                if ((aLogInfo.unallocate_date !== null) && (aLogInfo.unallocate_date > aInfo.asset_in_use_date)) {
                    //console.log('aLogInfo.unallocate_date');
                    setAssetInuseDate(aLogInfo.unallocate_date ? (new Date(aLogInfo.unallocate_date)) : '');
                }
            } else {
                setAssetInfo({});
            }
            if (res?.data?.data?.transferInfo) {
                var Info = res.data.data.transferInfo;
                setAuditInfo(Info);
                setTag(Info.tag);
                setDivision(Info.division);
                setPremises(Info.premises);
                setFloor(Info.floor);
                setSiteId(Info.site_id);
                setUserType(Info.user_type);
                setUserDepartment(Info.user_department);
                setEmployeeId(Info.employee_id);
                setemployeeName(Info.employee_name);
                setDateUnallocation(Info.unallocate_date);
                setReason(Info.premises);
                setChildAllocation(Info.child_allocation);
            }
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        var valid_Name = true;

        if (!TEXT_REGEX.test(tag) && tag !== 0) {
            setErrTag('Tag is required');
            valid_Name = false;
            //return;
        } else {
            setErrTag('');
        }
        if (!dateUnallocation) {
            setErrDateUnallocation('Date of Unallocation is required');
            valid_Name = false; //alert(valid_Name);
            //return;
        } else {
            setErrDateUnallocation('');
        }
        if (!TEXT_SPCL_REGEX.test(reason)) {
            setErrReason('Reason is required');
            valid_Name = false;
            //return;
        } else {
            setErrReason('');
        }

        //alert(valid_Name);
        if (valid_Name) { //alert('test1');
            saveData(); //submit form
        } else {
            return false;
        }
    }

    const saveData = function () {
        try {
            var obj = {
                'division': division,
                'premises': premises,
                'floor': floor,
                'site_id': siteId,
                'user_type': userType,
                'user_department': userDepartment,
                'employee_id': employeeId,
                'employee_name': employeeName,
                'tag': tag,
                'company_id': sessionStorage.getItem('LoggedInCompanyId'),
                'division_id': sessionStorage.getItem('LoggedInDivisionId'),
                'unallocate_date': getFormatedDate(dateUnallocation),
                'child_allocation': childAllocation,
                'reason': reason,
                'created_by': sessionStorage.getItem('LoggedInUserId'),

            }
            var objAudit = {'audit_module':'Alloction','audit_method':'unallocated',
            'audit_tag':tag,'old_values':auditInfo,'new_values':obj}

            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': obj, 'audit_params':objAudit,
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData = {'token': EncryptData}
            saveUnallocated(oData).then(function (res) {
                //console.log(res);
                if ((typeof (res.data.errors) != "undefined") && res.data.errors.errno > 0) {
                    setErrMsg(res.data.message);
                    setSuccess(false);
                } else {
                    setSuccess(res.data.message);
                    resetAssetInfo();
                }
            });

            //setValidName(false);
        } catch (err) {
            setSuccess(false);
            if (!err?.data) {
                console.log(err);
                setErrMsg('No Server Response');
            } else if (err.data?.status === 409) {
                setErrMsg('data already exist');
            } else {
                setErrMsg('Insert/Update Failed');
            }
            //elementRef.current.focus();
        }
    }
    return (
        <div className="container">

            <div className="row">
                <div className="col">
                    <h5>UnAllocate Asset</h5>
                </div>
                <div className="invalid-feedback" role="alert">
                    <i className="fa fa-info-circle"></i>
                    Please Fill all mandatory field.
                </div>

                {success ? (
                    <div className="alert alert-success" role="alert"><i
                        className="bi bi-check-circle-fill"></i>{success}</div>
                ) : (errMsg ? (<div className="alert alert-danger" role="alert"><i
                        className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>) : ('')
                )}
            </div>

            <div className='row '>
                <div className='col-12'>&nbsp;</div>
                {
                    (Tag !== '' && Tag !== null) ? <div className='row'>
                        <div className='col-10'></div>
                        <div className='col-2'></div>
                    </div> : <div className='row'>
                        <div className='col'><h5 className='align-right'>TAG </h5></div>
                        <div className='col'><input onChange={function (event) {
                            setTag(event.target.value)
                        }} inputValue={assetInfo.tag} type="text" className="form-control"
                                                    placeholder='Enter the asset TAG'/><p>{ErrTag}</p></div>
                        <div className='col'>
                            <button onClick={getTagInfo} className='btn btn-info btn-md'><i className="bi bi-search"></i> Search
                            </button>
                        </div>
                    </div>
                }

            </div>
            <div className='row'></div>
            <div className="row">

                <div className="col-6">
                    <FromFieldLabel
                        label="Tag"
                        inputValue={assetInfo.tag ? assetInfo.tag : ''}
                    />
                </div>
                <div className='col-6'></div>
            </div>
            {/*<div className="row">
                
                <div className="col-6">
                    <FromFieldLabel
                        label="Invoice Date" 
                        inputValue={assetInfo.invoice_date?getFormatedDate(assetInfo.invoice_date):'' }
                        //setInputValue={setInvoiceDate}
                        /> 
                </div>
                
        </div>*/}
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-6">
                        <input type='hidden' name='tag' value={tag}/>
                        <input type='hidden' name='division' value={division}/>
                        <input type='hidden' name='premises' value={premises}/>
                        <input type='hidden' name='floor' value={floor}/>
                        <input type='hidden' name='site_id' value={siteId}/>
                        <input type='hidden' name='user_type' value={userType}/>
                        <input type='hidden' name='user_department' value={userDepartment}/>
                        <input type='hidden' name='employee_id' value={employeeId}/>
                        <input type='hidden' name='employee_name' value={employeeName}/>

                        <FromFieldDate star="*"
                                       minDate={assetinusedate}
                                       label="Date of Un Allocation"
                                       inputValue={dateUnallocation}
                                       setInputValue={setDateUnallocation}
                                       setErrorMsg={ErrDateUnallocation}

                        />
                    </div>
                    <div className="col-6">


                    </div>

                </div>
                <div className="row">
                    <div className="col-6">
                        <FromSpFieldTextArea maxLength = "500" numRows = "3" star="*"
                                           label="Reason for Unallocation"
                                           inputValue={reason}
                                           setInputValue={setReason}
                                           setErrorMsg={ErrReason}
                        />
                    </div>
                    <div className="col-6"></div>

                </div>

                <div className="row">
                    <div className="col-6">

                    </div>
                    <div className="col-6"></div>

                    <div className="col-6">
                        <div className="row">
                            <div className="col-md-6">
                                <label> Select for child Un-allocation </label>
                                <span className="star error">  </span>
                            </div>
                            <div className="col-md-6">
                                <select onChange={handleChildAllocation} name="childAllocation"
                                        className="form-select form-select-sm">
                                    <option value="">Select for child allocation</option>
                                    <option value="Yes"
                                            selected={childAllocation === "Yes" ? "selected" : ''}>Yes
                                    </option>
                                    <option value="No"
                                            selected={childAllocation === "No" ? "selected" : ''}>No
                                    </option>
                                </select>
                                <span className="invalid-feedback">{ErrChildAllocation} </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-3"></div>
                    <div className="col-6">
                        {(Tag !== '' && Tag !== null) ?
                            <div>
                                <button className="btn btn-primary btn-md"> Update</button>
                                &nbsp;&nbsp;<a href={'/view-allocation'} className="btn btn-secondary btn-md">Cancel</a>
                            </div>
                            : <button className="btn btn-primary btn-md"> SAVE </button>
                        }

                    </div>
                    <div className="col-3"></div>
                </div>
            </form>
        </div>
    )
}

export default SingleNonAllocation


