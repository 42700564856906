import React,{useState,useEffect,useRef} from 'react'
import { useLocation } from "react-router-dom";
//import FromFieldLabel from '../../atoms/FromFieldLabel';
import FromFieldText from '../../../atoms/FromFieldText';
//import FromFieldDate from '../../../atoms/FromFieldDate';
import { saveTagMaster,getTagMaster,getCompanyProfileList,getDivisionList,getClasificationsList} from '../../../services/user.service';
import { getYearOfAdditionList } from '../../../services/master.service';
//import FromFieldSelect from '../../atoms/FromFieldSelect';
import  * as CryptoJS  from "crypto-js";
import FromFieldNumber from '../../../atoms/FromFieldNumber';
const TEXT_REGEX = /^[a-zA-Z0-9-_ ]{1,255}$/;

function TagMaster() {
   
    const [disabled, setDisabled] = useState(false);
    const [errMsg, setErrMsg] = useState('');
	const [success, setSuccess] = useState(false);
    const [prefix,setPrefix] = useState('');
    const [ErrPrefix, setErrPrefix] = useState(false);
    const [sufix,setSufix] = useState('');
    const [ErrSufix, setErrSufix] = useState(false);
    const [userStatus, setUserStatus] = useState(false);
    const [ErrUserStatus, setErrUserStatus] = useState(false);
    const [division,setDivision] = useState('');
    const [ErrDivision, setErrDivision] = useState(false);
    const [companyId,setCompanyId] = useState('');
    const [ErrCompanyId, setErrCompanyId] = useState(false);
    const [companies, setCompanies] = useState([]);
    const [divisions, setDivisions] = useState([]);
    const [resetStatus, setResetStatus] = useState(1);
    const [resetYear, setResetYear] = useState('');
    //const [useCategory, setUseCategory] = useState('');
    const [usePrefix, setUsePrefix] = useState(1);
    const [useSufix, setUseSufix] = useState(1);
    const [ErrUseSufix, setErrUseSufix] = useState('');
    const [ErrResetStatus, setErrResetStatus] = useState('');
    const [classifications, setClasifications] = useState([]);
    const [assetCat,setAssetCat] = useState(false);
    const [fnclassification, setfnclassification] = useState('');
    const [ErrFnClassification, setErrFnClassification] = useState(false);
    const [year_of_addition_items, setYear_of_Addition_Items] = useState([]);
    const [yearaddition, setyearaddition, ] = useState('');
    const [ErrYearAddition, setErrYearAddition] = useState(false);
    const resetUserInfo = function() { 
         setPrefix(''); setSufix(''); setDivision(''); setCompanyId('');
    }

    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const tag_master_id = query.get('tag_master_id');
    const compid = query.get('compid'); 
    const elementRef = useRef();
    useEffect(()=>{
        //encrypted data
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            //'param_data':obj,
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token':EncryptData}

        getCompanyProfileList(oData).then(res=>{
          if(res?.data?.data?.length) setCompanies(res.data.data);
        });
        
        if(tag_master_id!=='' && tag_master_id!==null && compid!=='' && compid!==null){
            getTagMasterInfo(tag_master_id);
            //encrypted data
            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data':{'company_id':compid},
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData1 = {'token':EncryptData}
            
            getDivisionList(oData1).then(res=>{
                if(res?.data?.data?.length) setDivisions(res.data.data);
              });
            getClasificationsList(oData1).then(res=>{
                if(res?.data?.data?.length) setClasifications(res.data.data);
              });
            getYearOfAdditionList(oData1).then(res=>{
                if(res?.data?.data?.length) setYear_of_Addition_Items(res.data.data);
            });
        }
      },[]);
      
      
    //console.log(modulesData);
    const getTagMasterInfo = function(tag_master_id) { 
        try{
            var data1= {'tag_master_id':tag_master_id,}
            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data':data1,
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData1 = {'token':EncryptData}
            getTagMaster(oData1).then(function(res){ 
                if(res?.data?.data.length) {
                  let userInfo = res.data.data[0];
                  setCompanyId(userInfo.company_id);
                  //setAccess(userInfo.full_access);
                  setDivision(userInfo.division_id);
                  setPrefix(userInfo.prefix); 
                  setSufix(userInfo.sufix);
                  
                }
                })
            }catch (err) { 
            //setAssetInfo('');
        }
    }
    const handleCompanyId = (e) => {
        let name= e.target.name;
        let company_value= e.target.value;
        setCompanyId(company_value);
        //encrypted data
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data':{'company_id':company_value},
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData2 = {'token':EncryptData}
        
        getDivisionList(oData2).then(res=>{
            if(res?.data?.data?.length) setDivisions(res.data.data);
          });
        getClasificationsList(oData2).then(res=>{
            if(res?.data?.data?.length) setClasifications(res.data.data);
          });
        getYearOfAdditionList(oData2).then(res=>{
            if(res?.data?.data?.length) setYear_of_Addition_Items(res.data.data);
        });
        
    }
    
    const handleDivision = (e) => {
        let name= e.target.name;
        let value= e.target.value;
        setDivision(e.target.value);
    }
    const handle_fnclassification = (e) => {
        let name= e.target.name;
        let value= e.target.value;
        setfnclassification(e.target.value);
      }
    const handleYearAddition = (e) => {
        let name= e.target.name;
        let value= e.target.value;
        setyearaddition(e.target.value);
    } 
    const handleUserStatus = (e) => {
        let name= e.target.name;
        let value= e.target.value;
        setUserStatus(e.target.value);
    } 
    const handleSubmit = async (e) => { 
		e.preventDefault(); var valid_Name=true;
		// if button enabled with JS hack
		//const v1 = TEXT_REGEX.test(name); 
        if (!TEXT_REGEX.test(prefix)) { //alert(name);
			setErrPrefix('Prefix is required'); valid_Name=false; //alert(valid_Name);
			//return;
		}else{ setErrPrefix('');  }
        if (!TEXT_REGEX.test(sufix)) { //alert(name);
			setErrSufix('Sufix is required'); valid_Name=false; //alert(valid_Name);
			//return;
		}else{ setErrPrefix('');  }
        if (!TEXT_REGEX.test(companyId)) { //alert(companyId);
			setErrCompanyId('company is required'); valid_Name=false;
			//return;
		}else{ setErrCompanyId('');  }
        
        if (!TEXT_REGEX.test(resetStatus)) { //alert(name);
			setErrResetStatus('Reset Status is required'); valid_Name=false; //alert(valid_Name);
			//return;
		}else{ setErrResetStatus('');  }
        
        //alert(valid_Name);
        if (valid_Name) { //alert(userid);
            if (tag_master_id!=='' && tag_master_id!==null) { 
                saveData(); // update data
            }else{ 
                saveData();  // submit form
            }
		}else{  //alert('fa');
            return false;
        }
    }
    const handleUsePrefix = (e) => {
        let name= e.target.name;
        let value= e.target.value;
        setUsePrefix(e.target.value);
    }
    const handleUseSufix = (e) => {
        let name= e.target.name;
        let value= e.target.value;
        setUseSufix(e.target.value);
    }
    const handleResetStatus = (e) => {
        let name= e.target.name;
        let value= e.target.value;
        setResetStatus(e.target.value);
    }
    
    const saveData = function() {
        try { 
            if (tag_master_id!=='' && tag_master_id!==null) {
                var obj = {
                    'tag_master_id':tag_master_id,
                    'company_id' : companyId,
                    'division_id' : division,
                    'use_prefix' : usePrefix,
                    'prefix' : prefix,
                    'reset_status' : resetStatus,
                    'reset_year' : resetYear,
                    'use_category' : assetCat,
                    'category' : fnclassification,
                    'use_sufix' : useSufix,
                    'sufix' : sufix,
                    'created_by' : sessionStorage.getItem('LoggedInUserId'),
                }
            }else{
                var obj = {
                    'company_id' : companyId,
                    'division_id' : division,
                    'use_prefix' : usePrefix,
                    'prefix' : prefix,
                    'reset_status' : resetStatus,
                    'reset_year' : resetYear,
                    'use_category' : assetCat,
                    'category' : fnclassification,
                    'use_sufix' : useSufix,
                    'sufix' : sufix,
                    'created_by' : sessionStorage.getItem('LoggedInUserId'),
                }  
            }
            
            //encrypted data
            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data':obj,
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData3 = {'token':EncryptData}

			saveTagMaster(oData3).then(function(res) {
                //console.log(res);
                if((typeof(res.data.errors)!= "undefined") && res.data.errors.errno>0){ setErrMsg(res.data.message);setSuccess(false);
                }else{ 
                setSuccess(res.data.message); 
                setDisabled(true); setErrMsg(false);
                resetUserInfo(); 
                }
            });
             
			//setValidName(false);
		} catch (err) { setSuccess(false);
			if (!err?.data) {
                console.log(err);
				setErrMsg('No Server Response');
			} else if (err.data?.status === 409) {
				setErrMsg('data already exist');
			} else {
				setErrMsg('Insert/Update Failed');
			}
			//elementRef.current.focus();
		}
	}
    
    return (
        <div className="container">
            
            <div className="row m-2">
                <div className="col-6">
                    
                    { (tag_master_id!=='' && tag_master_id!==null) ? <h5>Update User</h5>
                 : <h5>Tag Master</h5> }
                </div>
                <div className="invalid-feedback" role="alert">
                    <i className="fa fa-info-circle"></i>
                    Please Fill all mandetory field.
                </div>

                {success ? (
				<div className="alert alert-success" role="alert"><i className="bi bi-check-circle-fill"></i>{success}</div>
			        ) : ( errMsg ? (<div className="alert alert-danger" role="alert"><i className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>):('')
                ) }
            </div>
            
            <div className='row'>
            <div className='col-12'>&nbsp;</div>
            
            </div>
            <form onSubmit={handleSubmit}> 
            
            <div className="row m-2">
            <div className="col-6">
            <div className="row">
                <div className="col-md-6">
                <label>Company </label><span className="star error"> *</span>
                </div>
               
                <div className="col-md-6">
                    
                    <select onChange={handleCompanyId} className="form-select form-select-sm">
                      <option value="">Select Company</option>
                        {companies && companies.length > 0 ? (
                            companies.map((option) => (
                            <option value={option.company_id} selected={(companyId===option.company_id)? "selected" : ''}>{option.company_name}</option>
                            ))
                        ) : (
                            <option value="">No Data</option>
                        )}
                    </select>
                    <span className="invalid-feedback"> {ErrCompanyId} </span>
                </div>
            </div>
            </div>
            <div className="col-6"></div>
            </div>
            
           
            <div className="row m-2">
            <div className="col-6">
                <div className="row">
                <div className="col-md-6">
                    <label>Branch (Division) </label><span className="star error"> *</span>
                    </div>
                <div className="col-md-6">
                    <select onChange={handleDivision} className="form-select form-select-sm">
                    <option value="">Select Division</option>
                        {divisions && divisions.length > 0 ? (
                            divisions.map((option) => (
                            <option value={option.id} selected={(division===option.id)? "selected" : ''}>{option.division_name}</option>
                            ))
                        ) : (
                            <option value="">No Data</option>
                        )}
                    </select>
                  <span className="invalid-feedback"> {ErrDivision} </span>
                </div>
                </div>
                </div>
            </div>
            <div className="row m-2">
            <div className="col-md-6">
            <div className="row">
                <div className="col-md-6">
                <label> Do you want to Use Prefix </label>
            <span className="star error">  </span>
            </div>
            <div className="col-md-6">
            
            <select onChange={handleUsePrefix} name="user_status" className="form-select form-select-sm">
            <option value="">Select One</option>
                <option value="1"  selected={(usePrefix=="1")? "selected" : ''}>Yes</option>
                <option value="0"  selected={(usePrefix=="0")? "selected" : ''}>No</option>
            </select> <span className="invalid-feedback"> {ErrResetStatus} </span>
            </div></div><div className="col-md-6"></div>
            </div></div>


            <div className="row m-2">
                <div className="col-6">
                    <FromFieldText  star="*"
                        label="Enter Prefix" 
                        inputValue={prefix}
                        setInputValue={setPrefix}
                        setErrorMsg={ErrPrefix}
                        /> 
                </div>
                <div className="col-6"></div>
            </div>
            <div className="row m-2">
            <div className="col-md-6">
            <div className="row">
                <div className="col-md-6">
                    <label> When Do you Want to reset the ID </label>
                </div>
                <div className="col-md-6">
                <select onChange={handleResetStatus} name="user_status" className="form-select form-select-sm">
                <option value="">Select One</option>
                    <option value="M"  selected={(resetStatus=="M")? "selected" : ''}>Monthly</option>
                    <option value="Y"  selected={(resetStatus=="Y")? "selected" : ''}>Yearly</option>
                </select> <span className="invalid-feedback"> {ErrResetStatus} </span>
                </div>
            </div><div className="col-md-6"></div>
            </div>
            </div>        

            <div className="row m-2">
            <div className="col-6">
                <div className="row">
                <div className="col-md-6">
                    <label>Add year </label><span className="star error"> *</span>
                    </div>
                <div className="col-md-6">
                <select onChange={handleYearAddition} className="form-select form-select-sm">
                <option value="">Select Year of Addition</option>
                    {year_of_addition_items && year_of_addition_items.length > 0 ? (
                        year_of_addition_items.map((option) => (
                        <option value={option.year_of_addition} selected={(yearaddition==option.year_of_addition)? "selected" : ''}>{option.year_of_addition}</option>
                        ))
                    ) : (
                        <option value="">No Data</option>
                    )}
                    </select>
                  <span className="invalid-feedback"> {ErrDivision} </span>
                </div>
                </div>
                </div>
            </div>

            <div className="row m-2">
            <div className="col-md-6">
            <div className="row">
                <div className="col-md-6">
                <label> Do you want to Use Asset CAT </label>
                </div>
                <div className="col-md-6">
                <select onChange={handleUserStatus} name="user_status" className="form-select form-select-sm">
                <option value="">Select One</option>
                    <option value="1"  selected={(userStatus=="1")? "selected" : ''}>Yes</option>
                    <option value="0"  selected={(userStatus=="0")? "selected" : ''}>No</option>
                </select> <span className="invalid-feedback"> {ErrUserStatus} </span>
            </div></div><div className="col-md-6"></div>
            </div></div>

            <div className="row m-2">
            <div className="col-6">
                <div className="row">
                <div className="col-md-6">
                    <label>Category </label><span className="star error"> *</span>
                    </div>
                <div className="col-md-6">
                <select onChange={handle_fnclassification} className="form-select form-select-sm">
                    <option value="">Select Financials Classification</option>
                        { classifications && classifications.length > 0 ? (
                            classifications.map((option) => ( 
                            <option value={option.id} selected={(option.id==fnclassification)? "selected" : ''}>{option.category_short_name}</option>
                            ))
                        ) : ( <option value="">No Data</option> ) }
                    </select>
                  <span className="invalid-feedback"> {ErrDivision} </span>
                </div>
                </div>
                </div>
            </div>
            <div className="row m-2">
            <div className="col-md-6">
            <div className="row">
                <div className="col-md-6">
                <label> Do you want to Use Sufix "0 </label>
            <span className="star error">  </span>
            </div>
            <div className="col-md-6">
            
            <select onChange={handleUseSufix} name="user_status" className="form-select form-select-sm">
            <option value="">Select One</option>
                <option value="1"  selected={(useSufix=="1")? "selected" : ''}>Yes</option>
                <option value="0"  selected={(useSufix=="0")? "selected" : ''}>No</option>
            </select> <span className="invalid-feedback"> {ErrUseSufix} </span>
            </div></div><div className="col-md-6"></div>
            </div></div>


            <div className="row m-2">
                <div className="col-6">
                    <FromFieldNumber  star="*"
                        label="how Many Digit" 
                        inputValue={sufix}
                        setInputValue={setSufix}
                        setErrorMsg={ErrSufix}
                        /> 
                </div>
                <div className="col-6"></div>
            </div>



            <div className="row">
            <div className="col-3"></div>
                <div className="col-6">
                 { (tag_master_id!=='' && tag_master_id!==null) ? <div><button className="btn btn-primary btn-md"  disabled={disabled}>Update</button>&nbsp;&nbsp;<a href={'/user/list/?tag='+ tag_master_id } className="btn btn-secondary btn-md">Cancel</a></div>
                 : <button id="btn-submit" className="btn btn-primary btn-md" disabled={disabled}> SAVE </button>
                 }
                 </div>
                <div className="col-3"></div>
            </div>
            </form>
        </div>
    )
}

export default TagMaster


