import React, {useEffect, useState} from 'react';
import {DataGrid, GridOverlay, GridToolbarContainer, GridToolbarExport} from '@mui/x-data-grid';
import {getAllScanSerialNoData} from '../../../services/asset.service';

import * as CryptoJS from "crypto-js";
import Box from "@mui/material/Box";
import {FormControl, InputLabel, MenuItem, Pagination, Select} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

function getFormatedDate(dateString) {
    if (dateString != null) {
        let dte = new Date(dateString);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }
        return year + '-' + month + '-' + day;
    } else {
        return null;
    }
}

const columns = [
    {"field": "id", "editable": false, "hide": false, "headerName": "SNo.", "width": 50},
    {"field": "batch_no", "editable": false, "hide": false, "headerName": "Batch No", "width": 100},
    {"field": "supplier_name", "editable": false, "hide": false, "headerName": "Supplier Name", "width": 250},
    {"field": "invoice_no", "editable": false, "hide": false, "headerName": "Invoice no", "width": 150},
    {
        "field": "invoice_date", "editable": false, "hide": false, "headerName": "Invoice Date", "width": 120,
        renderCell: (params) => (
            <p>{getFormatedDate(params.row.invoice_date)}</p>
        )
    },
    {"field": "no_of_records", "editable": false, "hide": false, "headerName": "No of Records", "width": 130},
    {
        "field": "date_of_creation", "editable": false, "hide": false, "headerName": "Date of Addition", "width": 150,
        renderCell: (params) => (
            <p>{getFormatedDate(params.row.date_of_creation)}</p>
        )
    },
    {"field": "created_by_name", "editable": false, "hide": false, "headerName": "Created By User Name", "width": 180},
    {
        field: 'actions', headerName: 'Actions', width: 150, renderCell: (params) => {
            let EncryptID = CryptoJS.AES.encrypt(JSON.stringify({
                'scanid': params.row.id,
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            return (
                <span><a href={`/view-scan-tag-list/?scan_ID=${EncryptID}`}><button type="button"
                                                                                    className="btn btn-primary btn-md">View/Download</button></a></span>
            );
        }
    }
];

const CustomLoader = () => (
    <GridOverlay>
        <CircularProgress color="primary" size={30}/>
    </GridOverlay>
);

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport/>
        </GridToolbarContainer>
    );
}

const calculateOffset = (page, pageSize) => (page - 1) * pageSize;

function ViewScanSerialNo() {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);

    const [page, setPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [pageSize, setPageSize] = useState(50);

    useEffect(() => {
        const offset = calculateOffset(page, pageSize);
        const EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            param_data: {'offset': offset, 'pageSize': pageSize},
            authToken: sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

        let oData = {'token': EncryptData};

        getAllScanSerialNoData(oData).then(res => {
            if (res?.data?.data?.length) setItems(res.data.data);
            setTotalItems(res.data.total || 0);
            setLoading(false); // Set loading to false when data is fetched
        }).catch(() => {
            setLoading(false); // Ensure loading is false even if there's an error
        });
    }, [page, pageSize]); // Add dependencies to re-fetch data on page or pageSize change

    const handlePageSizeChange = (value) => {
        setPageSize(value);
        setPage(1);
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const totalPages = Math.ceil(totalItems / pageSize);

    return (
        <div className='container'>
            <div className='row'>
                <div className='col-md-6'><h5>Scan Serial No List</h5></div>
            </div>

            <Box>
                <DataGrid
                    rows={items}
                    getRowId={(row) => row.id}
                    columns={columns}
                    autoHeight
                    components={{
                        LoadingOverlay: CustomLoader,
                        Toolbar: CustomToolbar,
                        Pagination: () => (
                            <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2}}>
                                <Box sx={{mr: 4}}>
                                    Showing {items.length} records out of {totalItems}
                                </Box>
                                <FormControl variant="outlined" sx={{minWidth: 120}}>
                                    <InputLabel>Rows per page</InputLabel>
                                    <Select
                                        value={pageSize}
                                        onChange={(event) => handlePageSizeChange(Number(event.target.value))}
                                        label="Rows per page"
                                    >
                                        {[50, 100, 200, 500].map((size) => (
                                            <MenuItem key={size} value={size}>
                                                {size}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <Pagination
                                    count={totalPages}
                                    page={page}
                                    onChange={handlePageChange}
                                    variant="outlined"
                                    shape="rounded"
                                />
                            </Box>
                        ),
                    }}
                    loading={loading}
                />
            </Box>
        </div>
    )
}

export default ViewScanSerialNo;
