import React, {useEffect, useRef, useState} from 'react';
import {DataGrid, GridToolbarContainer, GridToolbarExport} from '@mui/x-data-grid';

import FromFieldText from "../../atoms/FromFieldText";
import * as CryptoJS from "crypto-js";
import {getGatePassData, getGatePassList} from "../../services/asset.service";
import {useLocation} from "react-router-dom";


function getFormatedDate(dateString) {
    if (dateString != null) {
        let dte = new Date(dateString);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }
        return year + '-' + month + '-' + day;
        //return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
    } else {
        return null;
    }
}

function PrintGatePass() {
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(true);

    const [gatePass, setGatePass] = useState('');
    const [ErrGatePass, setErrGatePass] = useState(false);

    const [tag, setTag] = useState('');
    const [ErrTag, setErrTag] = useState(false);

    const [assetInfo, setAssetInfo] = useState({});

    const [selectionModel, setSelectionModel] = useState([]);

    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const Tag = query.get('tag');
    const allocate = query.get('allocate');
    const elementRef = useRef();

    const handleSelectionModelChange = (newSelection) => {
        setSelectionModel(newSelection);
    };

    const columns = [
        {"field":"id","hideable":true,"hide":true,"headerName":"Sl No","width":50},
        {"field": "tag", "hide": false, "editable": false, "headerName": "TAG", "width": 250},
        {"field": "tag_name", "hide": false, "editable": false, "headerName": "TAG Name", "width": 250},
        {
            "field": "gatePassNo", "hideable": true, "hide": false, "editable": false, "headerName": "Gate Pass No", "width": 250,
            renderCell: (params) => {
                let EncryptID = CryptoJS.AES.encrypt(JSON.stringify({
                    'gatePassNo': params.row.gatePassNo,
                }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
                return (<a href={`/gate-pass-print-page?gatePassNo=${EncryptID}`}>{params.row.gatePassNo}</a>)
            }
        },
        {
            "field": "gatePassDate", "editable": false, "hide": false, "headerName": "Gate Pass Date", "width": 250,
            renderCell: (params) => (
                <p>{getFormatedDate(params.row.gatePassDate)}</p>
            )
        },
    ];

    useEffect(() => {
        // encrypted data
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            // 'param_data':obj,
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = { 'token': EncryptData };

        // Show loader when the request starts
        setLoading(true);

        // Fetch division list
        getGatePassList(oData)
            .then((res) => {
                if (res?.data?.data?.length) {
                    setItems(res.data.data);
                }
            })
            .catch((error) => {
                console.error('Error fetching gate pass list:', error);
            })
            .finally(() => {
                // Hide loader when the request completes (either success or failure)
                setLoading(false);
            });
    }, []);

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport/>
            </GridToolbarContainer>
        );
    }

    const [items, setItems] = useState([]);

    const getTagInfo = function () {
        //resetAssetInfo();
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': {'tag': tag},
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token': EncryptData}
        getGatePassData(oData).then(function (res) {
            console.log(res.data.data);

            if (res?.data?.data) {
                var Info = res.data.data;
                console.log(Info);
                setAssetInfo(Info[0]);
            }
        })
    }

    return (
        <div className='container'><h5> Print Gate Pass </h5>

            <div className="row">
                <div className="invalid-feedback" role="alert">
                    <i className="fa fa-info-circle"></i>
                    Please Fill all mandatory field.
                </div>

                {success ? (
                    <div className="alert alert-success" role="alert"><i
                        className="bi bi-check-circle-fill"></i>{success}</div>
                ) : (errMsg ? (<div className="alert alert-danger" role="alert"><i
                        className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>) : ('')
                )}
            </div>

            <div className='row '>
                <div className='col-12'>&nbsp;</div>
                {
                    (Tag !== '' && Tag !== null) ? <div className='row'>
                        <div className='col-10'></div>
                        <div className='col-2'></div>
                    </div> : <div className='row'>
                        <div className='col'><h5 className='align-right'>TAG </h5></div>
                        <div className='col'><input onChange={function (event) {
                            setTag(event.target.value)
                        }} inputValue={assetInfo.tag} type="text" className="form-control"
                                                    placeholder='Enter the asset TAG'/><p
                            className='invalid-feedback'>{ErrTag}</p></div>
                        <div className='col'>
                            <button onClick={getTagInfo} className='btn btn-info btn-sm'><i
                                className="bi bi-search"></i> Search
                            </button>
                            &nbsp;<a href={'/print-gate-pass'}>
                            <button className='btn btn-info btn-sm'>Reset</button>
                        </a>&nbsp;</div>
                    </div>
                }
            </div>

            <div className='row'></div>
            <div className="row">
                <div className="col-6">
                    <FromFieldText
                        label="TAG"
                        inputValue={assetInfo.tag ? assetInfo.tag : ''}
                        readOnly="readonly"
                    />
                </div>
                <div className='col-6'></div>
            </div>

            <div className='row mb-4'>
                <div className='col-6'>
                    <button onClick='' className='btn btn-info btn-sm'><i
                        className="bi bi-search"></i> Print
                    </button>
                </div>
                <div className='col-6'></div>

            </div>

            <DataGrid
                rows={items}
                getRowId={(row) => row.id}
                columns={columns}
                pageSize={50}
                checkboxSelection
                selectionModel={selectionModel}
                onSelectionModelChange={handleSelectionModelChange}
                //height={500}
                autoHeight
                //stickyHeaders={true}
                rowsPerPageOptions={[50]}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    )
}

export default PrintGatePass
;