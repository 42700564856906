import React, { useState,useEffect,useCallback } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { DataGrid } from '@mui/x-data-grid';
// import axios from "axios";
// import { read } from "xlsx";
import { getAllTransferData } from '../../../services/asset.service'

//import './AssetInfo.css';



const columns = [
    {"field":"sl_no","hideable":true,"hide":true,"editable":false,"headerName":"Sl No","width":50},
    {"field":"tag","editable":false,"hide":false ,"headerName":"TAG","width":80,
    renderCell: (params) => (
      <a href={`/internal-transfer-view?tag=${params.value}`}>{params.value}</a>
    ) },
    
    {"field":"movement_initiated_by","editable":true,"hide":false ,"headerName":"Movement Initiated By","width":130},
    {"field":"initiation_date","editable":true,"hide":false ,"headerName":"Initiation Date","width":130},
    {"field":"movement_approved_by","editable":true,"hide":false ,"headerName":"Movement Approved By","width":130},
    {"field":"approval_date","editable":true,"hide":false ,"headerName":"Approval Date","width":130},
    {"field":"movement_reason","editable":true,"hide":false ,"headerName":"Movement Reason","width":130},
    {"field":"movement_id","editable":true,"hide":false ,"headerName":"Movement Id","width":130},

    {"field":"division","editable":true,"hide":false ,"headerName":"Division","width":130},
    {"field":"premises","editable":true,"hide":false ,"headerName":"Premises","width":130},
    {"field":"floor","editable":true,"hide":false ,"headerName":"Floor","width":130},
    {"field":"site_id","editable":true,"hide":false ,"headerName":"site id","width":130},
    {"field":"user_type","editable":true,"hide":false ,"headerName":"user_type","width":130},
    {"field":"user_department","editable":true,"hide":false ,"headerName":"user_department","width":130},
    {"field":"employee_id","editable":true,"hide":false ,"headerName":"employee_id","width":130},
    {"field":"employee_name","editable":true,"hide":false ,"headerName":"employee_name","width":130},

    {"field":"movement_reason","editable":true,"hide":false ,"headerName":"movement reason","width":130},
    {"field":"approval_date","editable":true,"hide":true ,"headerName":"approval date","width":130},
    {"field":"movement_approved_by","editable":true,"hide":true ,"headerName":"movement approved by","width":130},
    {"field":"initiation_date","editable":true,"hide":true ,"headerName":"initiation date","width":130},
    {"field":"movement_initiated_by","editable":true,"hide":true ,"headerName":"movement initiated by","width":130},
   
];

function readTemplate() {
    // axios.get(templatePath).then((res)=>{
    //     console.log("res",res)
    // })
}

function AssetList() {
  const [items, setItems] = useState([]);
  var onCellEditStopFn = (event,newCell) => {
    var obj = {};
    obj[newCell.field] = event.target.value;
  }
  
  useEffect(()=>{
    getAllTransferData().then(res=>{
      if(res?.data?.data?.length) setItems(res.data.data);
    })
  },[])
  
  // readAssets();
  return (
    <div className='container'>
      <h3>
        <h2> Internal Transfer List</h2>
        
      </h3>
      <DataGrid
        rows={items}
        getRowId={(row) => row.sl_no}
        columns={columns}
        pageSize={5}
        autoHeight 
        rowsPerPageOptions={[5]}
        onCellEditStop={onCellEditStopFn}
      />
    </div>
  )
}

export default AssetList
