import React, { useState,useRef,useEffect,useCallback } from 'react';
import Button from '@mui/material/Button';
//import { uploadAllocation } from '../../../services/asset.service';
import { deleteUploadTAG } from '../../../services/asset.service';
import  * as CryptoJS  from "crypto-js";
import './AssetDetails.css';
const templatePath = "/exceltemplates/Asset_Delete_Template.xlsx";

function BulkTagDelete() {
    const errRef = useRef();
    const [validName, setValidName] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);

    var onCellEditStopFn = (event,newCell) => {
        var obj = {};
        obj[newCell.field] = event.target.value;
    }

    useEffect(()=>{
        /*getAssets().then(res=>{
          if(res?.data?.data?.length) setItems(res.data.data);
        })*/
    },[])
    const onChangeFile = async (event) => {
        event.preventDefault();
        console.log(event.target.files[0]);
        if (event.target.files[0]?.name.split('.').pop() == 'undefined' || event.target.files[0]?.name.split('.').pop() !== 'xlsx') {
            //alert('Please upload valid format. excel file only allowed.');
            setErrMsg("Please upload valid format. excel file only allowed.");
            return false;
        }else{ //alert('validName');
            //submit form
            try {
                const data = new FormData()
                data.append('file', event.target.files[0]);
                data.append('company_id', sessionStorage.getItem('LoggedInCompanyId'));
                data.append('division_id', sessionStorage.getItem('LoggedInDivisionId'));
                data.append('created_by', sessionStorage.getItem('LoggedInUserId'));

                setLoading('..');
                deleteUploadTAG(data).then(res => {
                    if((typeof(res.data.errors)!== "undefined") && res.data.errors.errno>0){
                        setErrMsg(res.data.message);setLoading(false);
                        setSuccess(false);
                    }else{
                        setSuccess(res.data.message);
                        setLoading(false);  // Stop loading
                    }
                    //setSuccess(res.data.message);
                })

            } catch (error) {
                setLoading(false); // Stop loading in case of error
                console.error(error);
                setErrMsg(error.data.message);
            }
        } //end else
    };
    // readAssets();
    return (
        <div className='container'>
            <div className='row single__assets'>
                <div className="col-9">
                    <h5>Bulk TAG Delete</h5>
                </div>

                {success ? (
                    <div className="alert alert-success" role="alert"><i className="bi bi-check-circle-fill"></i>{success}</div>
                ) : ( errMsg ? (<div className="alert alert-danger" role="alert"><i className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>):('')
                ) }
            </div>

            <div className="row form-fields mb-5">
                <div className='col-9'>
                    <div className="row form-fields">
                        <div className="label col-3">
                            <label>Select Delete TAG Excel </label>
                            <span className="star error"> *</span>
                        </div>
                        <div className="float-start col-3">
                            <Button variant='contained' component='label' className="btn btn-primary btn-md">
                                <i className="bi bi-upload"></i>&nbsp;Upload
                                <input hidden type='file' onChange={onChangeFile} />
                            </Button>
                            {loading ? (<div className="spinner-border text-primary" role="status">
                                <span className="sr-only">{loading}</span>
                            </div>) : ('') }
                        </div>
                    </div>
                </div>

                <div className='col-3 form-fields'>

                    <a href={templatePath} className="text-decoration-none btn btn-secondary btn-sm">
                        Download Delete TAG Template <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"></path>
                        <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"></path>
                    </svg></a>
                </div>
            </div>

        </div>
    )
}

export default BulkTagDelete
