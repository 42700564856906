import React, { useState, useRef, useEffect,useCallback } from 'react';
import { createMonthDepreciation,lockMonthDepreciation,getCreateMonthData } from '../../../services/asset.service';
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import './AssetInfo.css';
import  * as CryptoJS  from "crypto-js";
export default function AssetDepreciation() {
  const errRef = useRef();
  // const [validName, setValidName] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState('');
  const [fnSession, setFnSession] = useState('');
  const [ErrSession, setErrSession] = useState('');
  const [month, setMonth] = useState('');
  const [ErrMonth, setErrMonth] = useState('');
  const [items, setItems] = useState('');
  const [aprilMonth, setAprilMonth] = useState('');
  const [mayMonth, setMayMonth] = useState('');
  const [juneMonth, setJuneMonth] = useState('');
  const [julyMonth, setJulyMonth] = useState('');
  const [augustMonth, setAugustMonth] = useState('');
  const [septemberMonth, setSeptemberMonth] = useState('');
  const [octoberMonth, setOctoberMonth] = useState('');
  const [novemberMonth, setNovemberMonth] = useState('');
  const [decemberMonth, setDecemberMonth] = useState('');
  const [januaryMonth, setJanuaryMonth] = useState('');
  const [februaryMonth, setFebruaryMonth] = useState('');
  const [marchMonth, setMarchMonth] = useState('');

  useEffect(()=>{
    setErrSession('');
    setItems('');
    /*getAssets().then(res=>{
      if(res?.data?.data?.length) setItems(res.data.data);
    })*/
  },[]);

  const handleFinancialYear = (e) => {
    let value= e.target.value;
    setFnSession(value);  setErrSession(fnSession); //alert(fnSession);
    if(value!==''){
      var obj = {
        'fy_year': parseInt(value) +'-'+ (parseInt(value)+1),
        'token': sessionStorage.getItem('LoggedInToken'),
      };
      let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
        'param_data':obj,
        'authToken': sessionStorage.getItem('LoggedInToken'),
      }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
      let oData = {'token':EncryptData}
      //console.log(data1);
      getCreateMonthData(oData).then(res=>{
        console.log(res.data.data); setItems('');
        if(res?.data?.data?.res?.length){setItems(res.data.data.res);} 
        //console.log('items');
        //console.log(items);
      });
      if(items?.length){
      const april  = items.find(obj => { return obj.month == 4; });
      setAprilMonth(april);
      const may  = items.find(obj => { return obj.month == 5; });
      setMayMonth(may);
      const june  = items.find(obj => { return obj.month == 6; });
      setJuneMonth(june);
      const july  = items.find(obj => { return obj.month == 7; });
      setJulyMonth(july);
      const august  = items.find(obj => { return obj.month == 8; });
      setAugustMonth(august);
      const september  = items.find(obj => { return obj.month == 9; });
      setSeptemberMonth(september);
      const october  = items.find(obj => { return obj.month == 10; });
      setOctoberMonth(october);
      const november  = items.find(obj => { return obj.month == 11; });
      setNovemberMonth(november);
      const december  = items.find(obj => { return obj.month == 12; });
      setDecemberMonth(december);
      const january  = items.find(obj => { return obj.month == 1; });
      setJanuaryMonth(january);
      const february  = items.find(obj => { return obj.month == 2; });
      setFebruaryMonth(february);
      const march  = items.find(obj => { return obj.month == 3; });
      setMarchMonth(march);
      //  console.log(may);
      }else{ 
      setAprilMonth('');
      setMayMonth('');
      setJuneMonth('');
      setJulyMonth('');
      setAugustMonth('');
      setSeptemberMonth('');
      setOctoberMonth('');
      setNovemberMonth('');
      setDecemberMonth('');
      setJanuaryMonth('');
      setFebruaryMonth('');
      setMarchMonth('');
      }
    }
    //calInvValInrExRate() 
  } 
  const createMonthTable = (e) => {
    let value= e.target.value;
    // alert(value);
    if(fnSession!=='' && value!==''){ setErrSession(''); //alert(fnSession);
      setMonth(parseInt(value));
      try {
      var obj = {
        'fy_year': parseInt(fnSession)+'-'+ ((parseInt(fnSession)+1)),
        'year': (value=="1"||value=="2"||value=="3")?parseInt(fnSession)+1:parseInt(fnSession),
        'month': value,
        'creation_flag': 'Y',
        'lock_status': 'N',
        'company_id':sessionStorage.getItem('LoggedInCompanyId'),
        'division_id':sessionStorage.getItem('LoggedInDivisionId'),
        'created_by':sessionStorage.getItem('LoggedInUserId'),
        'token': sessionStorage.getItem('LoggedInToken'),
      };
      let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
        'param_data':obj,
        'authToken': sessionStorage.getItem('LoggedInToken'),
      }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
      let oData1 = {'token':EncryptData}
        createMonthDepreciation(oData1).then(function(res){
          if((typeof(res.data.errors)!= "undefined") && res.data.errors.errno>0){
            setErrMsg(res.data.message);setSuccess(false);
          }else{ 
          setSuccess(res.data.message);  
          }
          //setSuccess(res.data.message);
        });
      } catch (err) { setSuccess(false); setErrMsg(err.data.message);
        if (!err?.data) {
                  console.log(err);
          setErrMsg('No Server Response');
        } else if (err.data?.status === 409) {
          setErrMsg('Username Taken');
        } else {
          setErrMsg('Insert/Update Failed');
        }
        //elementRef.current.focus();
      }
    }else{
        setErrSession('Please Select Financial Year')
      }
    //calInvValInrExRate() 
  }
  const lockMontTable = (e) => {
    let value= e.target.value;
    // alert(value);
    if(fnSession!=='' && value!==''){ 
    setErrSession(''); //alert(fnSession);
    confirmAlert({
      title: "Are you sure lock this month?",
      //message: "Are you sure want to lock this month?",
      buttons: [
        {
          label: "Yes",
          onClick: () => lockMontSubmitTable(value) //alert("Click Yes")
        },
        {
          label: "No"
          // onClick: () => alert("Click No")
        }
      ]
    });
    }else{
      setErrSession('Please Select Financial Year')
    }
  }
  
  function lockMontSubmitTable(value) {
    
    try {  //alert('abc');
			//const response = saveData();
      var obj = {
        'fy_year': parseInt(fnSession)+'-'+ ((parseInt(fnSession)+1)),
        'year': (value=="1"||value=="2"||value=="3")?parseInt(fnSession)+1:parseInt(fnSession),
        'month': value,
        'creation_flag': 'Y',
        'lock_status': 'Y',
        'company_id':sessionStorage.getItem('LoggedInCompanyId'),
        'division_id':sessionStorage.getItem('LoggedInDivisionId'),
        'created_by':sessionStorage.getItem('LoggedInUserId'),
        'token': sessionStorage.getItem('LoggedInToken'),
      };
      let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
        'param_data':obj,
        'authToken': sessionStorage.getItem('LoggedInToken'),
      }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
      let oData2 = {'token':EncryptData}
      lockMonthDepreciation(oData2).then(function(res){
        setSuccess(res.data.message);
      });
    } catch (err) { setSuccess(false); setErrMsg(err.data.message);
      if (!err?.data) {
                console.log(err);
        setErrMsg('No Server Response');
      } else if (err.data?.status === 409) {
        setErrMsg('Username Taken');
      } else {
        setErrMsg('Insert/Update Failed');
      }
      //elementRef.current.focus();
    }
    //calInvValInrExRate() 
  }
  const unlockMontTable = (e) => {
    let value= e.target.value;
    // alert(value);
    if(fnSession!=='' && value!==''){ 
    setErrSession(''); //alert(fnSession);
    confirmAlert({
      title: "Are you sure unlock this month?",
      //message: "Are you sure want to lock this month?",
      buttons: [
        {
          label: "Yes",
          onClick: () => unlockMontSubmitTable(value) //alert("Click Yes")
        },
        {
          label: "No"
          // onClick: () => alert("Click No")
        }
      ]
    });
    }else{
      setErrSession('Please Select Financial Year')
    }
  }
  function unlockMontSubmitTable(value) {
    try {  //alert('abc');
      var obj = {
        'fy_year': parseInt(fnSession)+'-'+ ((parseInt(fnSession)+1)),
        'year': (value=="1"||value=="2"||value=="3")?parseInt(fnSession)+1:parseInt(fnSession),
        'month': value,
        'creation_flag': 'Y',
        'lock_status': 'N',
        'company_id':sessionStorage.getItem('LoggedInCompanyId'),
        'division_id':sessionStorage.getItem('LoggedInDivisionId'),
        'created_by':sessionStorage.getItem('LoggedInUserId'),
        'token': sessionStorage.getItem('LoggedInToken'),
      };
			//const response = saveData();
      let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
        'param_data':obj,
        'authToken': sessionStorage.getItem('LoggedInToken'),
      }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
      let oData3 = {'token':EncryptData}
      lockMonthDepreciation(oData3).then(function(res){
        setSuccess(res.data.message);
      });
    } catch (err) { setSuccess(false); setErrMsg(err.data.message);
      if (!err?.data) {
                console.log(err);
        setErrMsg('No Server Response');
      } else if (err.data?.status === 409) {
        setErrMsg('Username Taken');
      } else {
        setErrMsg('Insert/Update Failed');
      }
      //elementRef.current.focus();
    }
  }
  // readAssets();
  return (
    <div className='container'>
      <div className='row single__assets'>
        <div className="col">
            <h5>Generate Depreciation</h5>
        </div>

        { /*<div className="alert alert-warning" role="alert">
            <i className="fa fa-info-circle"></i>
            Please Fill all mandetory field.
        </div> */ }
      {success ? (
				<div className="alert alert-success" role="alert"><i className="bi bi-check-circle-fill"></i>{success}</div>
			        ) : ( errMsg ? (<div className="alert alert-danger" role="alert"><i className="bi bi-exclamation-triangle-fill"></i> {errMsg}</div>):('')
                ) }
        
        </div>
            
    <div className="row">
    <div className="col-2"><label> Select Financial Year </label></div>
      <div className="col-4">
        <select onChange={handleFinancialYear} name="nature_of_addition" className="form-select form-select-sm mb-3">
            <option value="">Select Financial Year</option>
            <option value="2005">FY-2005-06</option>
            <option value="2006">FY-2006-07</option>
            <option value="2007">FY-2007-08</option>
            <option value="2008">FY-2008-09</option>
            <option value="2009">FY-2009-10</option>
            <option value="2010">FY-2010-11</option>
            <option value="2011">FY-2011-12</option>
            <option value="2012">FY-2012-13</option>
            <option value="2013">FY-2013-14</option>
            <option value="2014">FY-2014-15</option>
            <option value="2015">FY-2015-16</option>
            <option value="2016">FY-2016-17</option>
            <option value="2017">FY-2017-18</option>
            <option value="2018">FY-2018-19</option>
            <option value="2019">FY-2019-20</option>
            <option value="2020">FY-2020-21</option>
            <option value="2021" >FY-2021-22</option>
            <option value="2022" >FY-2022-23</option>
            <option value="2023" >FY-2023-24</option>
            <option value="2024" >FY-2024-25</option>
            </select>
            <span className="invalid-feedback"> {ErrSession} </span>
        </div>
        <div className="col-6">
        <button onClick={handleFinancialYear} value={fnSession} className='btn btn-info btn-md'><i className="bi bi-search"></i> Fetch </button>
        </div>
      </div>
      
    <div className="row">
      
      { aprilMonth?( 
      <div className="col-4">
      { (aprilMonth.lock_status==='Y')?(<div><div className='month-div month-g'><h1>April</h1></div>
        <div className='lock-div'><button type="submit" value="04" className="btn btn-info btn-sm disabled">Create Month</button>
            <button type="submit" value="04"  className="btn btn-primary btn-md" onClick={unlockMontTable}>UnLock Month</button> </div></div>
            ):(<div><a href={('/generate-depreciation?m=4&fy=')+fnSession}><div className='month-div month-y'><h1>April</h1></div></a>
            <div className='lock-div'>
              <button type="submit" value="04"  className="btn btn-info btn-sm disabled">Create Month</button>
            <button type="submit" value="04" onClick={lockMontTable} className="btn btn-secondary btn-sm">Lock Month</button>
            </div></div>)
      }
      </div>
      ):(
      <div className="col-4">
          <div className='month-div'><h1>April</h1></div>
        <div className='lock-div'>
        <button type="submit" value="04" onClick={createMonthTable} className="btn btn-info btn-sm">Create Month</button>
        <button type="submit" value="04" className="btn btn-secondary btn-sm disabled">Lock Month</button>
        </div>
      </div>) }

      
      { mayMonth?( 
      <div className="col-4">
      { (mayMonth.lock_status==='Y')?(<div><div className='month-div month-g'><h1>May</h1></div>
        <div className='lock-div'><button type="submit" value="05" className="btn btn-info btn-sm disabled">Create Month</button>
            <button type="submit" value="05"  className="btn btn-primary btn-md" onClick={unlockMontTable}>UnLock Month</button> </div></div>
            ):(<div><a href={('/generate-depreciation?m=5&fy=')+fnSession}><div className='month-div month-y'><h1>May</h1></div></a>
            <div className='lock-div'>
              <button type="submit" value="05" className="btn btn-info btn-sm disabled">Create Month</button>
            <button type="submit" value="05" onClick={lockMontTable} className="btn btn-secondary btn-sm">Lock Month</button>
            </div></div>)
      }
      </div>
      ):(
      <div className="col-4">
          <div className='month-div'><h1>May</h1></div>
        <div className='lock-div'>
        <button type="submit" value="05" onClick={createMonthTable} className="btn btn-info btn-sm">Create Month</button>
        <button type="submit" value="05" className="btn btn-secondary btn-sm disabled">Lock Month</button>
        </div>
      </div>) }
      { juneMonth?( 
      <div className="col-4">
      { (juneMonth.lock_status==='Y')?(<div><div className='month-div month-g'><h1>June</h1></div>
        <div className='lock-div'><button type="submit" value="06" className="btn btn-info btn-sm disabled">Create Month</button>
            <button type="submit" value="06"  className="btn btn-primary btn-md" onClick={unlockMontTable}>UnLock Month</button> </div></div>
            ):(<div><a href={('/generate-depreciation?m=6&fy=')+fnSession}><div className='month-div month-y'><h1>June</h1></div></a>
            <div className='lock-div'>
              <button type="submit" value="06" className="btn btn-info btn-sm disabled">Create Month</button>
            <button type="submit" value="06" onClick={lockMontTable} className="btn btn-secondary btn-sm">Lock Month</button>
            </div></div>)
      }
      </div>
      ):(
      <div className="col-4">
      <div className='month-div'><h1>June</h1></div>
        <div className='lock-div'>
        <button type="submit" value="06" onClick={createMonthTable} className="btn btn-info btn-sm">Create Month</button>
        <button type="submit" value="06" className="btn btn-secondary btn-sm disabled">Lock Month</button>
        </div>
      </div>)}
    </div>
    <div className="row">
    { julyMonth?( 
      <div className="col-4">
      { (julyMonth.lock_status==='Y')?(<div><div className='month-div month-g'><h1>July</h1></div>
        <div className='lock-div'><button type="submit" value="07" className="btn btn-info btn-sm disabled">Create Month</button>
            <button type="submit" value="07"  className="btn btn-primary btn-md" onClick={unlockMontTable}>UnLock Month</button> </div></div>
            ):(<div><a href={('/generate-depreciation?m=7&fy=')+fnSession}><div className='month-div month-y'><h1>July</h1></div></a>
            <div className='lock-div'>
              <button type="submit" value="07" className="btn btn-info btn-sm disabled">Create Month</button>
            <button type="submit" value="07" onClick={lockMontTable} className="btn btn-secondary btn-sm">Lock Month</button>
            </div></div>)
      }
      </div>
      ):(
      <div className="col-4">
      <div className='month-div'><h1>July</h1></div>
        <div className='lock-div'>
        <button type="submit" value="07" onClick={createMonthTable} className="btn btn-info btn-sm">Create Month</button>
        <button type="submit" value="07" className="btn btn-secondary btn-sm disabled">Lock Month</button>
        </div>
      </div>)}
      { augustMonth?( 
      <div className="col-4">
      { (augustMonth.lock_status==='Y')?(<div><div className='month-div month-g'><h1>August</h1></div>
        <div className='lock-div'><button type="submit" value="08" className="btn btn-info btn-sm disabled">Create Month</button>
            <button type="submit" value="08"  className="btn btn-primary btn-md" onClick={unlockMontTable}>UnLock Month</button> </div></div>
            ):(<div><a href={('/generate-depreciation?m=8&fy=')+fnSession}><div className='month-div month-y'><h1>August</h1></div></a>
            <div className='lock-div'>
              <button type="submit" value="08" className="btn btn-info btn-sm disabled">Create Month</button>
            <button type="submit" value="08" onClick={lockMontTable} className="btn btn-secondary btn-sm">Lock Month</button>
            </div></div>)
      }
      </div>
      ):(
      <div className="col-4">
        <div className='month-div'><h1>August</h1></div>
        <div className='lock-div'>
        <button type="submit" value="08" onClick={createMonthTable} className="btn btn-info btn-sm">Create Month</button>
        <button type="submit" value="08" className="btn btn-secondary btn-sm disabled">Lock Month</button>
        </div>
      </div>)}
      { septemberMonth?( 
      <div className="col-4">
      { (septemberMonth.lock_status==='Y')?(<div><div className='month-div month-g'><h1>September</h1></div>
        <div className='lock-div'><button type="submit" value="09" className="btn btn-info btn-sm disabled">Create Month</button>
            <button type="submit" value="09"  className="btn btn-primary btn-md" onClick={unlockMontTable}>UnLock Month</button> </div></div>
            ):(<div><a href={('/generate-depreciation?m=9&fy=')+fnSession}><div className='month-div month-y'><h1>September</h1></div></a>
            <div className='lock-div'>
              <button type="submit" value="09" className="btn btn-info btn-sm disabled">Create Month</button>
            <button type="submit" value="09" onClick={lockMontTable} className="btn btn-secondary btn-sm">Lock Month</button>
            </div></div>)
      }
      </div>
      ):(
      <div className="col-4">
      <div className='month-div'><h1>September</h1></div>
        <div className='lock-div'>
        <button type="submit" value="09" onClick={createMonthTable} className="btn btn-info btn-sm">Create Month</button>
        <button type="submit" value="09" className="btn btn-secondary btn-sm disabled">Lock Month</button>
        </div>
      </div>
      )}
    </div>
    <div className="row">
      { octoberMonth?( 
      <div className="col-4">
      { (octoberMonth.lock_status==='Y')?(<div><div className='month-div month-g'><h1>October</h1></div>
        <div className='lock-div'><button type="submit" value="10" className="btn btn-info btn-sm disabled">Create Month</button>
            <button type="submit" value="10"  className="btn btn-primary btn-md" onClick={unlockMontTable}>UnLock Month</button> </div></div>
            ):(<div><a href={('/generate-depreciation?m=10&fy=')+fnSession}><div className='month-div month-y'><h1>October</h1></div></a>
            <div className='lock-div'>
              <button type="submit" value="10" className="btn btn-info btn-sm disabled">Create Month</button>
            <button type="submit" value="10" onClick={lockMontTable} className="btn btn-secondary btn-sm">Lock Month</button>
            </div></div>)
      }
      </div>
      ):(
      <div className="col-4">
      <div className='month-div'><h1>October</h1></div>
        <div className='lock-div'>
        <button type="submit" value="10" onClick={createMonthTable} className="btn btn-info btn-sm">Create Month</button>
        <button type="submit" value="10" className="btn btn-secondary btn-sm disabled">Lock Month</button>
        </div>
      </div>)}
      { novemberMonth?( 
      <div className="col-4">
      { (novemberMonth.lock_status==='Y')?(<div><div className='month-div month-g'><h1>November</h1></div>
        <div className='lock-div'><button type="submit" value="11" className="btn btn-info btn-sm disabled">Create Month</button>
            <button type="submit" value="11"  className="btn btn-primary btn-md" onClick={unlockMontTable}>UnLock Month</button> </div></div>
            ):(<div><a href={('/generate-depreciation?m=11&fy=')+fnSession}><div className='month-div month-y'><h1>November</h1></div></a>
            <div className='lock-div'>
              <button type="submit" value="11" className="btn btn-info btn-sm disabled">Create Month</button>
            <button type="submit" value="11" onClick={lockMontTable} className="btn btn-secondary btn-sm">Lock Month</button>
            </div></div>)
      }
      </div>
      ):(
      <div className="col-4">
        <div className='month-div'><h1>November</h1></div>
        <div className='lock-div'>
        <button type="submit" value="11" onClick={createMonthTable} className="btn btn-info btn-sm">Create Month</button>
        <button type="submit" value="11" className="btn btn-secondary btn-sm disabled">Lock Month</button>
        </div>
      </div>
      )}
      { decemberMonth?( 
      <div className="col-4">
      { (decemberMonth.lock_status==='Y')?(<div><div className='month-div month-g'><h1>December</h1></div>
        <div className='lock-div'><button type="submit" value="12" className="btn btn-info btn-sm disabled">Create Month</button>
            <button type="submit" value="12"  className="btn btn-primary btn-md" onClick={unlockMontTable}>UnLock Month</button> </div></div>
            ):(<div><a href={('/generate-depreciation?m=12&fy=')+fnSession}><div className='month-div month-y'><h1>December</h1></div></a>
            <div className='lock-div'>
              <button type="submit" value="12" className="btn btn-info btn-sm disabled">Create Month</button>
            <button type="submit" value="12" onClick={lockMontTable} className="btn btn-secondary btn-sm">Lock Month</button>
            </div></div>)
      }
      </div>
      ):(
      <div className="col-4">
      <div className='month-div'><h1>December</h1></div>
        <div className='lock-div'>
        <button type="submit" value="12" onClick={createMonthTable} className="btn btn-info btn-sm">Create Month</button>
        <button type="submit" value="12" className="btn btn-secondary btn-sm disabled">Lock Month</button>
        </div>
      </div>)}
    </div>
    <div className="row">
    { januaryMonth?( 
      <div className="col-4">
      { (januaryMonth.lock_status==='Y')?(<div><div className='month-div month-g'><h1>January</h1></div>
        <div className='lock-div'><button type="submit" value="01" className="btn btn-info btn-sm disabled">Create Month</button>
            <button type="submit" value="01"  className="btn btn-primary btn-md" onClick={unlockMontTable}>UnLock Month</button> </div></div>
            ):(<div><a href={('/generate-depreciation?m=1&fy=')+(parseInt(fnSession)+1)}><div className='month-div month-y'><h1>January</h1></div></a>
            <div className='lock-div'>
              <button type="submit" value="01" className="btn btn-info btn-sm disabled">Create Month</button>
            <button type="submit" value="01" onClick={lockMontTable} className="btn btn-secondary btn-sm">Lock Month</button>
            </div></div>)
      }
      </div>
      ):(
      <div className="col-4">
      <div className='month-div'><h1>January</h1></div>
        <div className='lock-div'>
        <button type="submit" value="01" onClick={createMonthTable} className="btn btn-info btn-sm">Create Month</button>
        <button type="submit" value="01" className="btn btn-secondary btn-sm disabled">Lock Month</button>
        </div>
      </div>)}
      { februaryMonth?( 
      <div className="col-4">
      { (februaryMonth.lock_status==='Y')?(<div><div className='month-div month-g'><h1>February</h1></div>
        <div className='lock-div'><button type="submit" value="02" className="btn btn-info btn-sm disabled">Create Month</button>
            <button type="submit" value="02"  className="btn btn-primary btn-md" onClick={unlockMontTable}>UnLock Month</button> </div></div>
            ):(<div><a href={('/generate-depreciation?m=2&fy=')+(parseInt(fnSession)+1)}><div className='month-div month-y'><h1>February</h1></div></a>
            <div className='lock-div'>
              <button type="submit" value="02" className="btn btn-info btn-sm disabled">Create Month</button>
            <button type="submit" value="02" onClick={lockMontTable} className="btn btn-secondary btn-sm">Lock Month</button>
            </div></div>)
      }
      </div>
      ):(
      <div className="col-4">
        <div className='month-div'><h1>February</h1></div>
        <div className='lock-div'>
        <button type="submit" value="02" onClick={createMonthTable} className="btn btn-info btn-sm">Create Month</button>
        <button type="submit" value="02" className="btn btn-secondary btn-sm disabled">Lock Month</button>
        </div>
      </div>)}
      { marchMonth?( 
      <div className="col-4">
      { (marchMonth.lock_status==='Y')?(<div><div className='month-div month-g'><h1>March</h1></div>
        <div className='lock-div'><button type="submit" value="03" className="btn btn-info btn-sm disabled">Create Month</button>
            <button type="submit" value="03"  className="btn btn-primary btn-md" onClick={unlockMontTable}>UnLock Month</button> </div></div>
            ):(<div><a href={('/generate-depreciation?m=3&fy=')+(parseInt(fnSession)+1)}><div className='month-div month-y'><h1>March</h1></div></a>
            <div className='lock-div'>
              <button type="submit" value="03" className="btn btn-info btn-sm disabled">Create Month</button>
            <button type="submit" value="03" onClick={lockMontTable} className="btn btn-secondary btn-sm">Lock Month</button>
            </div></div>)
      }
      </div>
      ):(
      <div className="col-4">
      <div className='month-div'><h1>March</h1></div>
        <div className='lock-div'>
        <button type="submit" value="03" onClick={createMonthTable} className="btn btn-info btn-sm">Create Month</button>
        <button type="submit" value="03" className="btn btn-secondary btn-sm disabled">Lock Month</button>
        </div>
      </div>)}
    </div>
      <div className='row save__button text-center'>
                <div className='col-md-12'>
                    {/*<button type="submit" className="btn btn-primary btn-md" > Genrate </button>*/}
                </div>
            </div>
    </div>
  )
}

