import React,{ useRef, useState, useEffect, useContext } from 'react';
import AuthContext from "../../context/AuthProvider";
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate } from "react-router-dom";
import './Login.css'
import { loginUser,verifyToken }  from '../../services/user.service';
const TEXT_REGEX = /^[A-Za-z0-9-_/.@= ]{1,155}$/;
const Email_REGEX = /^[a-zA-Z0-9@.-_]{1,255}$/;
//const NUMBER_REGEX = /^[0-9]{1,15}$/;
const PASSWORD_REGEX = /^[A-Za-z0-9._%+-@#$&!*)(?><^"'}|{=:;]+$/
function Login(props) {
    const { setAuth } = useContext(AuthContext);
    const userRef = useRef();
    const errRef = useRef();
    const navigate  = useNavigate();
    const [userName,setUserName] = useState('');
    const [ErrUserName, setErrUserName] = useState(false);
    const [userPWD,setUserPWD] = useState('');
    const [ErrUserCaptcha, setErrUserCaptcha] = useState('');
    const [ErrUserPWD, setErrUserPWD] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState('');
    //ReCaptcha 
    const captchaRef = useRef(null);
    
    useEffect(() => {
        //userRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [userName, userPWD])

    const handleSubmit = async (e) => {
		e.preventDefault(); var valid_Name=true; //alert(valid_Name);
		// if button enabled with JS hack
		//const v1 = TEXT_REGEX.test(yearaddition);
		if (!Email_REGEX.test(userName)) { 
			setErrUserName('Username is required'); valid_Name=false;
            //alert(valid_Name);
			//return;
		}else{ setErrUserName('');  }
        if (!PASSWORD_REGEX.test(userPWD)) { //alert(valid_Name);
			setErrUserPWD('Password is required'); valid_Name=false;
			//return;
		}else{ setErrUserPWD('');  }

        if (valid_Name) { 
          let token = captchaRef.current.getValue();
          //alert(token);
            if(token){
              var data1 = { 
                'secret': process.env.REACT_APP_CAPTCHA_SECRET_KEY,
                'token':token, 
              };
                //let valid_token = await verifyToken(token);
                verifyToken(data1).then(function(result){
                  console.log(result.data);
                  if(result.data.success){  //alert(token);
                      //console.log(result.data.data);
                      checkLogin(); //submit form
                      //setSuccess(result.data.message);
                    }else if(result.data){ 
                      setErrMsg(result.data.message);
                      setSuccess(false);
                    }
                /*if(valid_token.success){
                setMessage("Hurray!! you have submitted the form");
                checkLogin(); //submit form
                }else{
                setError("Sorry!! Token invalid");
                }*/ });
            }else{ 
              setErrUserCaptcha('You must confirm you are not a robot');
                //alert("You must confirm you are not a robot");
            }
		    }else{
            //alert('valid_Name1');
            return false;
        }
    }
    
    const checkLogin = function() {
        var data = { 
            'Email': userName,
            'password':userPWD, 
        };
        console.log(data);
        try { 
            loginUser(data).then(function(res){
                //console.log(res.data.data.length);
             if((typeof(res.data.errors)!= "undefined") && res.data.errors.errno>0){ 
                    setErrMsg(res.data.message);setSuccess(false);
              }else if(res.data?.data?.length){ 
                    //console.log('Login-Data');
                    //console.log(res.data.data);
                    if(res.data.data[0].pwd_update=='Y'){ //check user is not updated password
                    setSuccess(res.data.message);
                    sessionStorage.setItem('isLoggedIn',true);
                    sessionStorage.setItem('LoggedInName', res.data.data[0].name);
                    sessionStorage.setItem('LoggedInEmail', res.data.data[0].email);
                    sessionStorage.setItem('LoggedInRoleName', res.data.data[0].role_name);
                    sessionStorage.setItem('LoggedInCompanyName', res.data.data[0].company_name);
                    sessionStorage.setItem('LoggedInCompanyShortName', res.data.data[0].company_short_name);
                    sessionStorage.setItem('LoggedInDivisionName', res.data.data[0].division_name);
                    sessionStorage.setItem('LoggedInDivisionShortName', res.data.data[0].division_short_name);
                    sessionStorage.setItem('LoggedInDivisionCode', res.data.data[0].division_code);
                    sessionStorage.setItem('LoggedInUserId', res.data.data[0].id);
                    sessionStorage.setItem('LoggedInCompanyId', res.data.data[0].company_id);
                    sessionStorage.setItem('LoggedInRoleId', res.data.data[0].role_id);
                    sessionStorage.setItem('LoggedInDivisionId', res.data.data[0].division_id);
                    sessionStorage.setItem('LoggedInDepartmentId', res.data.data[0].department_id);
                    sessionStorage.setItem('LoggedInDepartmentName', res.data.data[0].department_name);
                    sessionStorage.setItem('LoggedInDepartmentShortName', res.data.data[0].department_short_name);
                    sessionStorage.setItem('LoggedInDepartmentGroup', res.data.data[0].department_group);
                    sessionStorage.setItem('LoggedInModuleId', res.data.data[0].module_id);
                    sessionStorage.setItem('LoggedInSubModuleId', res.data.data[0].sub_module_id);
                    sessionStorage.setItem('LoggedInPwdStatus', res.data.data[0].pwd_update);
                    sessionStorage.setItem('LoggedInToken', res.data.data[0].token);
                    props.setter(true)
                    navigate("/");
                    window.location.reload(false);
                    }else{ 
                      sessionStorage.setItem('ForgotEmail', res.data.data[0].email);
                      sessionStorage.setItem('Forgot_OTP', 'success');
                      sessionStorage.setItem('Forgot_Email','')
                      navigate("/resetpassword");
                      window.location.reload(false);
                    }
                  }else{ setErrMsg("Invalid Credentials OR URL. Please try with valid Credentials OR URL");setSuccess(false); }
                });
              } catch (err) { setSuccess(false); setErrMsg(err.data.message);
                if (!err?.data) {
                          console.log(err);
                  setErrMsg('No Server Response');
                } else if (err.data?.status === 409) {
                  setErrMsg('Unauthorized');
                } else {
                  setErrMsg('Login Failed');
                }
                //elementRef.current.focus();
            }
       /* try { 
            const response = await loginUser.post(LOGIN_URL,
                JSON.stringify({ user, pwd }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            console.log(JSON.stringify(response?.data));
            //console.log(JSON.stringify(response));
            const accessToken = response?.data?.accessToken;
            const roles = response?.data?.roles;
            setAuth({ userName, userPWD, roles, accessToken });
            setUser('');
            setPwd('');
            setSuccess(true);
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Login Failed');
            }
            errRef.current.focus();
        }*/
    }
  return (
    <div className='login-page'> 
      
      <div className='form shadow-lg p-3 mb-5 rounded'>
      <div className='login-logo'>{/*<img  src='Microchip_logo.png'/>*/}</div>
          <h5 className="sign-in-header">Sign In</h5>
          <div className="row"><div className="col-3">&nbsp;</div><div className="col-6"><p className="sign-in-para">Hey Enter Your Details To Get Sign In To Your Account</p></div><div className="col-3">&nbsp;</div></div>
          <form onSubmit={handleSubmit} > 
          <div className="mb-12">
            
          {success ? (
				<div className="alert alert-success" role="alert"><i className="bi bi-check-circle-fill"></i>{success}</div>
			        ) : ( errMsg ? (<div className="alert alert-danger" role="alert"><i className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>):('')
                ) }
          </div><div className="mb-2 input-group">
            <label className="input-group-text">Email address</label>
            <input
              type="email"
              className="form-control"
              placeholder="Enter email"
              value={userName}
              onChange={(event) => {setUserName(event.target.value)}}
            />
            <span className="invalid-feedback"> {ErrUserName} </span>
          </div>
          <p className="forgot-password text-right">
             <a href="/forgotpassword">Forgot Password?</a>
          </p>
          <div className="mb-3 input-group">
            <label className="input-group-text">Password &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
            <input
              type="password"
              className="form-control"
              placeholder="Enter password"
              value={userPWD}
              onChange={(event) => {setUserPWD(event.target.value)}}
            />
            <span className="invalid-feedback"> {ErrUserPWD} </span>
          </div>
          <div className="mb-3">
            
          </div>
          <div className="input-group">
            <div className='col-4'>
              <div className="form-check">
                {/*<inputs
                  type="checkbox"
                  className="form-check-input"
                  id="customCheck1"
                />
                <label className="form-check-label" htmlFor="customCheck1">
                  Remember me
                </label>*/}
              </div>
            </div>
            <div className='col-8'>
              <ReCAPTCHA sitekey="6Le5KyEmAAAAAHX7fDPb6n8V_iG0sEmONEmb_qjP" ref={captchaRef}  />
              <span className="invalid-feedback"> {ErrUserCaptcha} </span>
            </div>
          </div>
          <div className="input-group">
            <div className='col-3'></div><div className='col-2'></div>
            <div className='col-12'><button className="btn btn-primary btn-lg mt-2 mb-2 w-100"> Log In <i class="bi bi-box-arrow-in-right"></i></button></div>
          </div>
          
          </form>
        </div>
    </div>
  )
}

export default Login
