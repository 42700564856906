import React, { useState, useEffect } from 'react';
import FromFieldText from '../../../atoms/FromFieldText';
import FromFieldDate from '../../../atoms/FromFieldDate';
import "react-datepicker/dist/react-datepicker.css";



function InitiateSingleTransferOne() {



    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h5>Initiate Single Disposal- One</h5>
                </div>
            </div>


            <div className="row mt-4">
                <div className="col-6">
                    <div className="list-group">
                        <a href="#" className="list-group-item list-group-item-action">Furniture-001</a>
                        <a href="#" className="list-group-item list-group-item-action">Furniture-002</a>
                        <a href="#" className="list-group-item list-group-item-action">Furniture-003</a>
                        <a href="#" className="list-group-item list-group-item-action">Furniture-004</a>
                    </div>
                </div>
                <div className="col-6">
                    <div className="list-group">
                        <a href="#" className="list-group-item list-group-item-action">Furniture-001</a>
                        <a href="#" className="list-group-item list-group-item-action">Furniture-002</a>
                        <a href="#" className="list-group-item list-group-item-action">Furniture-003</a>
                        <a href="#" className="list-group-item list-group-item-action">Furniture-004</a>
                    </div>
                </div>
            </div>

            <div className="row mt-4 bgcolor">
                <div className="col-6 ">
                    <FromFieldText
                        label="Financial Classification"
                        placeholder="Financial Classification"
                        inputValue=''
                        setInputValue=''
                    />
                </div>
                <div className="col-6">
                    <FromFieldDate
                        label="Invoice Date"
                        inputValue=''
                        setInputValue=''
                    />
                </div>
            </div>

            <div className="row bgcolor">
                <div className="col-6">
                    <FromFieldText
                        label="Supplier Name"
                        placeholder="Supplier Name"
                        inputValue=''
                        setInputValue=''
                    />
                </div>
                <div className="col-6">
                    <FromFieldText
                        label="Supplier Invoice No"
                        placeholder="Supplier Invoice No"
                        inputValue=''
                        setInputValue=''
                    />
                </div>
            </div>

            <div className="row bgcolor">
                <div className="col-6">
                    <FromFieldText
                        label="Current Division"
                        placeholder="Current Division"
                        inputValue=''
                        setInputValue=''
                    />
                </div>
                <div className="col-6">
                <FromFieldText
                        label="Current Premises"
                        placeholder="Current Premises"
                        inputValue=''
                        setInputValue=''
                    />
                </div>
            </div>

            <div className="row bgcolor">
                <div className="col-6">
                    <FromFieldText
                        label="Current Floor"
                        placeholder="Current Floor"
                        inputValue=''
                        setInputValue=''
                    />
                </div>
                <div className="col-6">
                <FromFieldText
                        label="Current Site ID"
                        placeholder="Current Site ID"
                        inputValue=''
                        setInputValue=''
                    />
                </div>
            </div>

            <div className="row bgcolor">
                <div className="col-6">
                    <FromFieldText
                        label="Current User Type"
                        placeholder="Current User Type"
                        inputValue=''
                        setInputValue=''
                    />
                </div>
                <div className="col-6">
                <FromFieldText
                        label="Current User Department"
                        placeholder="Current User Department"
                        inputValue=''
                        setInputValue=''
                    />
                </div>
            </div>

            <div className="row bgcolor">
                <div className="col-6">
                    <FromFieldText
                        label="Current Employee ID"
                        placeholder="Current Employee ID"
                        inputValue=''
                        setInputValue=''
                    />
                </div>
                <div className="col-6">
                <FromFieldText
                        label="Current Employee Name"
                        placeholder="Current Employee Name"
                        inputValue=''
                        setInputValue=''
                    />
                </div>
            </div>


            <div className="row mt-4">
                <div className="col-4"></div>
                <div className="col-4 text-center">
                    <button className="btn btn-primary" > Next </button>
                </div>
                <div className="col-4"></div>
            </div>



        </div>
    )
}

export default InitiateSingleTransferOne;