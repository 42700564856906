import React,{useState,useEffect} from 'react';
import { useLocation } from "react-router-dom";
import FromFieldLabel from '../../atoms/FromFieldLabel';
import FromFieldText from '../../atoms/FromFieldText';


import "./StpiForm.css";
import { saveSTPI,getSTPI } from '../../services/asset.service';
import FromFieldDate from '../../atoms/FromFieldDate';
import FromFieldSelect from '../../atoms/FromFieldSelect';
/*
Date.prototype.dbFormatedDate = function () {
  return `${this.getFullYear()}-${this.getMonth()+1}-${this.getDate()}`
}
*/
function getFormatedDate(dateString) {
  if(dateString!=null) {
      var dte = new Date(dateString);
      return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
  } else {
      return null;
  }   
}
function StpiForm() {
  const [tag, setTag] = useState(0);
  const [assetInfo, setAssetInfo] = useState([]);
  const [bondDate, setBondDate] = useState('');
  const [debondDate, setDebondDate] = useState('');
  const [approvalDate, setApprovalDate] = useState('');
  const [bondNumber,setBondNumber] = useState('');
  const [assetType,setAssetType] = useState('');
  const [transportClause,setTransportClause] = useState('');
  const [importType,setImportType] = useState('');
  const [cifApprovalValue,setCIFApprovalValue] = useState('');
  const [stpiApprovalNo,setSTPIApprovalNo] = useState('');
  const [assessedValue,setAssessedValue] = useState('');
  const [customDutyForgone,setCustomDutyForgone] = useState('');
  const [totalDutyForgone,setTotalDutyForgone] = useState('');
  const [igstForgone,setIGSTForgone] = useState('');
  const [debondingRefNo,setDebondingRefNo] = useState('');

  const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const Tag = query.get('tag'); 
    const Mode = query.get('mode'); 
    useEffect(() => {
        if(Tag!=='' && Tag!==null){
          getSTPI(Tag).then(function(res){
            if(res?.data?.data) {
              var {stpiInfo,assetInfo} = res.data.data;
              setAssetInfo(assetInfo);
              if(stpiInfo) { 
                setTag(stpiInfo.Tag);
                setBondDate((new Date(stpiInfo.date_of_bond)));
                setDebondDate((new Date(stpiInfo.debond_date)));
                setApprovalDate((new Date(stpiInfo.stpi_approval_date)));
                setBondNumber(stpiInfo.bond_number);
                setAssetType(stpiInfo.asset_type);
                setTransportClause(stpiInfo.transport_clause);
                setImportType(stpiInfo.import_type);
                setCIFApprovalValue(stpiInfo.approval_value_cif);
                setSTPIApprovalNo(stpiInfo.stpi_approval_number);
                setAssessedValue(stpiInfo.assessed_value);
                setCustomDutyForgone(stpiInfo.custom_duty_forgone);
                setTotalDutyForgone(stpiInfo.total_duty_forgone);
                setIGSTForgone(stpiInfo.igst_forgone);
                setDebondingRefNo(stpiInfo.debond_ref_number);
              }
            }
          })
        }
        
    }, []);

  const getTagInfo = function() {
    getSTPI(tag).then(function(res){
      if(res?.data?.data) {
        var {stpiInfo,assetInfo} = res.data.data;
        setAssetInfo(assetInfo);
        if(stpiInfo) { 
          setTag(stpiInfo.tag);
          setBondDate((new Date(stpiInfo.date_of_bond)));
          setDebondDate((new Date(stpiInfo.debond_date)));
          setApprovalDate((new Date(stpiInfo.stpi_approval_date)));
          setBondNumber(stpiInfo.bond_number);
          setAssetType(stpiInfo.asset_type);
          setTransportClause(stpiInfo.transport_clause);
          setImportType(stpiInfo.import_type);
          setCIFApprovalValue(stpiInfo.approval_value_cif);
          setSTPIApprovalNo(stpiInfo.stpi_approval_number);
          setAssessedValue(stpiInfo.assessed_value);
          setCustomDutyForgone(stpiInfo.custom_duty_forgone);
          setTotalDutyForgone(stpiInfo.total_duty_forgone);
          setIGSTForgone(stpiInfo.igst_forgone);
          setDebondingRefNo(stpiInfo.debond_ref_number);
        }
      }
    })
  }

  const saveData = function() {
    var data = {
      'company_id':sessionStorage.getItem('LoggedInCompanyId'),
      'division_id':sessionStorage.getItem('LoggedInDivisionId'),
      'created_by':sessionStorage.getItem('LoggedInUserId'),
      'tag': tag, 
      'bond_number':bondNumber, 
      'date_of_bond':bondDate?.dbFormatedDate(), 
      'asset_type':assetType,
      'transport_clause':transportClause, 
      'import_type':importType,
      'approval_value_cif':cifApprovalValue, 
      'stpi_approval_number':stpiApprovalNo, 
      'stpi_approval_date':approvalDate?.dbFormatedDate(), 
      'assessed_value':assessedValue, 
      'custom_duty_forgone':customDutyForgone, 
      'total_duty_forgone':totalDutyForgone, 
      'igst_forgone':igstForgone, 
      'debond_ref_number':debondingRefNo,
      'debond_date':debondDate?.dbFormatedDate()
    };
    saveSTPI(data).then(function(res){
      console.log(res);
    })
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <h5>STPI Form For Single Asset Updation</h5>
        </div>
      </div>
      {
            (Tag!=='' && Tag!==null) ? <div className='row'><div className='col-10'></div> <div className='col-2'></div></div> : <div className='row'><div className='col'><h5 className='align-right'>TAG </h5></div>
            <div className='col'><input onChange={function(event){setTag(event.target.value)}} inputValue={assetInfo.tag} type="text" className="form-control"  placeholder='Enter the asset TAG' /></div>
            <div className='col'><button onClick={getTagInfo} className='btn btn-primary'> Search </button></div></div>
            }
      {/*<div className='row'>
          <div className='col'><h5 className='align-right'>TAG </h5></div>
          <div className='col'><input onChange={function(event){setTag(event.target.value)}} type="text" className="form-control"  placeholder='Enter the asset TAG'/></div>
          <div className='col'><button onClick={getTagInfo} className='btn btn-primary'> Search </button></div>
      </div>*/}

      <div className="row">
        <div className="col-6">
          <FromFieldLabel  
            label="Financial Classification" 
            inputValue={assetInfo.financials_classification}
          /> 
        </div>
        <div className="col-6">
         <FromFieldLabel
            label="Date of Capitalization" 
            inputValue={getFormatedDate(assetInfo.asset_in_use_date)}
          /> 
        </div>
      
      </div>

      <div className="row bottom-border">
        <div className="col-6">
          <FromFieldLabel  
            label="Supplier Name" 
            inputValue={assetInfo.supplier_name}
          /> 
        </div>
        <div className="col-6">
         <FromFieldLabel
            label="Supplier Invoice No" 
            inputValue={assetInfo.invoice_no} 
          /> 
        </div>
      
      </div>

      <div className="row">
        <div className="col-6">
        <FromFieldText   star="*"
            label="a. Bond Number" 
            placeholder="Bond Number" 
            inputValue={bondNumber} 
            setInputValue={setBondNumber} 
          /> 
        </div>
        <div className="col-6">
          <FromFieldDate  star="*"
            label="b. Date of Bonding"
             inputValue={bondDate} 
            setInputValue={setBondDate} 
          /> 
        </div>
      
      </div>

      <div className="row">
        <div className="col-6">
          <FromFieldSelect  star="*"
              label="c. Type of Asset"
              setSelectedValue={setAssetType}
              selectedValue={assetType}
              values={["Bonded","Outright Purchase","Others"]}
            />
        </div>
        <div className="col-6">
          <FromFieldSelect  star="*"
              label="d. Transport Clause"
              setSelectedValue={setTransportClause}
              selectedValue={transportClause}
              values={["FO","CIF C&I","C&F"]}
            />
        </div>
      
      </div>

      <div className="row">
        <div className="col-6">
          <FromFieldSelect  star="*"
              label="e. Type of Import"
              setSelectedValue={setImportType}
              selectedValue={importType}
              values={["Loan Basis","Lease Basis","Free of Charge","Outright Purchase","Other"]}
            />
        </div>
        <div className="col-6">
         <FromFieldText  star="*"
            label="f. Approval Value in CIF" 
            placeholder="Approval Value in CIF" 
            inputValue={cifApprovalValue} 
            setInputValue={setCIFApprovalValue} 
          /> 
        </div>
      
      </div>

      <div className="row">
        <div className="col-6">
          <FromFieldText   star="*"
            label="g. STPI Approval No"
            placeholder="STPI Approval No" 
            inputValue={stpiApprovalNo} 
            setInputValue={setSTPIApprovalNo} 
          /> 
        </div>
        <div className="col-6">
          <FromFieldDate  star="*"
              label="h. STPI Approval Date"
              inputValue={approvalDate} 
              setInputValue={setApprovalDate} 
            /> 
        </div>
      
      </div>

      <div className="row">
        <div className="col-6">
          <FromFieldText  star="*"
            label="i. Assessed Value"
            placeholder="Assessed Value" 
            inputValue={assessedValue} 
            setInputValue={setAssessedValue} 
          /> 
        </div>
        <div className="col-6">
         <FromFieldText star="*"
            label="j. Customs Duty Forgone"
            placeholder="Customs Duty Forgone" 
            inputValue={customDutyForgone} 
            setInputValue={setCustomDutyForgone} 
          /> 
        </div>
      
      </div>

      <div className="row bottom-border">
        <div className="col-6">
          <FromFieldText star="*"
            label="k. Total Duty Forgone"
            placeholder="Total Duty Forgone" 
            inputValue={totalDutyForgone} 
            setInputValue={setTotalDutyForgone} 
          /> 
        </div>
        <div className="col-6">
         <FromFieldText  star="*"
            label="l. IGST Forgone"
            placeholder="IGST Forgone" 
            inputValue={igstForgone} 
            setInputValue={setIGSTForgone} 
          /> 
        </div>
      
      </div>



      <div className="row">
        <div className="col-6">
          <FromFieldText   star="*"
            label="i. Debonding Ref No"
            placeholder="Debonding Ref No" 
            inputValue={debondingRefNo} 
            setInputValue={setDebondingRefNo} 
          /> 
        </div>
        <div className="col-6">

         <FromFieldDate  star="*"
            label="ii. Debonding Date" 
            inputValue={debondDate} 
            setInputValue={setDebondDate} 
          /> 
        </div>
      
      </div>
      <div className="row">
        <div className="col-6"></div>
        <div className="col-6">
        { (Tag!=='' && Tag!==null) ? <div><button className="btn btn-primary" >Update</button>&nbsp;&nbsp;<a href={'/stpi-view?tag='+ Tag } className="btn btn-secondary btn-sm">Cancel</a></div>
                 : <button className="btn btn-primary" > SAVE </button>
                 }
          {/*<button onClick={saveData} className='btn btn-primary'> SAVE </button>*/}
          </div>

      </div>


   
    </div>
  )
}

export default StpiForm;

