import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import {DataGrid} from '@mui/x-data-grid';
import {getSeekExtRequest} from '../../../services/asset.service';
import * as CryptoJS from "crypto-js";

function getFormatedDate(dateString) {
    if (dateString != null) {
        let dte = new Date(dateString);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }
        return year + '-' + month + '-' + day;
        //return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
    } else {
        return null;
    }
}

const onEditClick = (e, row) => {
    e.stopPropagation();
    //alert(row.id)
    //do whatever you want with the row
};

const columns = [
    {
        "field": "phy_varify_table_name", "editable": false, "hide": false, "headerName": "PV table name", "width": 130,
        renderCell: (params) => (
            <a href={`/physical-verfication-master/view?pvid=${params.row.id}`}>{params.value}</a>
        )
    },
    {"field": "reason", "editable": false, "hide": false, "headerName": "Reason", "width": 130},
    {
        "field": "extension_date", "editable": false, "hide": false, "headerName": "Extended Date", "width": 130,
        renderCell: (params) => (
            <p>{getFormatedDate(params.row.extension_date)}</p>
        )
    },
    {"field": "company_name", "editable": false, "hide": true, "headerName": "Company Name", "width": 350},
    {"field": "division_name", "editable": false, "hide": true, "headerName": "Division Name", "width": 350},
    {"field": "start_date", "editable": false, "hide": true, "headerName": "Start Date", "width": 130},
    {"field": "end_date", "editable": false, "hide": true, "headerName": "End Date", "width": 130},
    {"field": "created_at", "editable": false, "hide": false, "headerName": "Create Date", "width": 130},
    {
        field: 'actions', headerName: 'Actions', width: 400, renderCell: (params) => {
            return (
                <span><button type="button" className="btn btn-primary btn-md">
                    <i className='bi bi-check-circle-fill' onClick={(e) => onEditClick(e, params.row)} variant="contained">
        </i></button> </span>
            );
        }
    }
];

function SeekExtensionRequest() {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    var onCellEditStopFn = (event, newCell) => {
        var obj = {};
        obj[newCell.field] = event.target.value;
    }

    useEffect(() => {
        let isMounted = true;  // To prevent state updates if the component is unmounted

        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = { 'token': EncryptData };

        setLoading(true);
        getSeekExtRequest(oData)
            .then(res => {
                if (isMounted) {
                    if (res?.data?.data?.length) {
                        setItems(res.data.data);
                    }
                    setLoading(false);
                }
            })
            .catch(error => {
                if (isMounted) {
                    console.error('Error fetching data:', error);
                    setLoading(false);
                }
            });

        // Cleanup function to handle component unmounting
        return () => {
            isMounted = false;
        };
    }, []);

    // readDivision();
    return (
        <div className='container'>
            <div className='row'>
                <div className='col-md-6'><h5>Seek Extension Request</h5></div>

            </div>
            <Box sx={{height: 500, width: '100%'}}>

                {loading ? (
                    <div className="text-center">
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ) : (
                    <DataGrid
                        rows={items}
                        getRowId={(row) => row.id}
                        columns={columns}
                        pageSize={50}
                        //autoHeight
                        rowsPerPageOptions={[50]}
                    />
                )}

            </Box>
        </div>
    )
}

export default SeekExtensionRequest
