import React,{useCallback,useState,useEffect,useRef} from 'react'
import FromFieldLabel from '../../../atoms/FromFieldLabel';
//import FromFieldSelect from '../../../atoms/FromFieldSelect';
import FromFieldDate from '../../../atoms/FromFieldDate';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import '../../Home/Home.css';
import { getDepresiationSummery } from '../../../services/asset.service';
import {getClasificationsList} from '../../../services/user.service';
import  * as CryptoJS  from "crypto-js";
Date.prototype.dbFormatedDate = function () {
    
    let year = this.getFullYear();
    let month = this.getMonth() + 1;
    let day = this.getDate();
    if (month < 10) {
      month = '0' + month;
    }if (day < 10) {
      day = '0' + day;
    }
    return year + '-' + month + '-' + day;
    //return `${this.getFullYear()}-${this.getMonth()+1}-${this.getDate()-1}`
  }
  function getFormatedDate(dateString) {
    if(dateString!==null) {
        let dte = new Date(dateString);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        if (month < 10) {
          month = '0' + month;
        }if (day < 10) {
          day = '0' + day;
        }
        return year + '-' + month + '-' + day;
        //return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
    } else {
        return null;
    }   
  }
  
  const printSummary = function () {
    let printContents = document.getElementById('print_summary').innerHTML;
        //let originalContents = document.body.innerHTML;
        //document.body.innerHTML = printContents;


      //startLoad();
      var docprint=window.open();
      //popup.document.write('<html><head></head><body>'+printContents+"</body></html>");
        docprint.document.write('<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN"');
        docprint.document.write('"http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">');
        docprint.document.write('<html xmlns="http://www.w3.org/1999/xhtml" xml:lang="en">');
        docprint.document.write('<head><title>Depreciation Summary</title>');
        docprint.document.write('<style type="text/css">thead tr:nth-child(2) th:nth-child(1) { background: #44546a; width: 135px;#dep_summary{ text-align: right;}}</style>');
        docprint.document.write('</head><body onLoad="self.print()"><center>');
        docprint.document.write(printContents);
        docprint.document.write('</center></body></html>');
        docprint.document.close();
        docprint.focus();
        docprint.print();
   //docprint.close();
   //endLoad();
   // window.print();
   // document.body.innerHTML = "<html><head></head><body>"+originalContents+"</body></html>"; 
    document.getElementById('print_qr').innerHTML='';
    }
  
function DepreciationSummary() {
    const [fromperiod, setFromPeriod] = useState('');
    const [ErrFromPeriod, setErrFromPeriod] = useState('');
    const [toperiod, setToPeriod] = useState();
    const [ErrToPeriod, setErrToPeriod] = useState(''); 
    //const fincancialClasifications = ['Land','Building','Building Improvements','Furniture and Fixtures','Plant and Machinery','Office Equipments','Computer','Intangible','Vehicles','Electrical Installations']
    const [classifications, setClasifications] = useState([]);
    const [assetInfo, setAssetInfo] = useState([]);
    useEffect(() => { //alert(fromperiod);
        //get category list and set classification
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            //'param_data':obj,
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token':EncryptData}
        getClasificationsList(oData).then(res=>{
            if(res?.data?.data?.length) setClasifications(res.data.data);
          });
    }, []);
    /*const handleFromDate = (e) => {
        //let value= e.target.date;
        setFromPeriod(e);
        if(fromperiod!=='' && fromperiod!==null && toperiod!==null && toperiod!==''){
            //setEditTag(Tag); 
            //alert('fg');
            getSummeryInfo();
            //alert(Tag);
            
        }
    };
    const handleToDate = (e1) => {
        //let value= e.target.value;
        setToPeriod(e1);
        if(fromperiod!=='' && fromperiod!==null && toperiod!==null && toperiod!==''){
            //setEditTag(Tag); 
            alert('fg2');
            getSummeryInfo();
            //alert(Tag);
            
        }
    };*/
    const getSummeryInfo = useCallback(async () => { 
    //const getSummeryInfo = function() {
        const aSummery = [];
        if(fromperiod!=='' && fromperiod!==null){
            setErrFromPeriod('Please select From Date'); 
        }else{
            setErrFromPeriod('');
        }
        if(toperiod!==null && toperiod!==''){
            setErrToPeriod('Please select To Date'); 
        }else{
            setErrToPeriod('');
        }
        //map classification
        classifications.map(function(val,i) {
        //classifications.map((option) => ( 
        let obj={
            'fnclass':val.id, 
            'fnclasscategory':val.category_name,
            'from_date':getFormatedDate(fromperiod),
            'to_date':getFormatedDate(toperiod),
        }; //alert(val);
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data':obj,
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData1 = {'token':EncryptData}
        getDepresiationSummery(oData1).then(function(res){ 
            let asset_ob = 0; let asset_cb = 0;let asset_added_value =0; let asset_deleted_value = 0;
            let depreciation_added_value = 0; let depreciation_adjusted_value = 0;let depreciation_deleted_value =0; let depreciation_ob = 0;
            let new_depreciation_cb = 0; let wdv_ob = 0;let wdv_cb =0; 
          if(res?.data?.data?.assetDeprisiation){
            var aAssetInfo = res.data.data.assetDeprisiation;
                console.log('aSummery'); 
                aAssetInfo.map((oInfo, i) => {
                    asset_ob = oInfo.asset_ob + asset_ob;
                    asset_cb = oInfo.asset_cb + asset_cb;
                    asset_added_value = oInfo.asset_added_value + asset_added_value;
                    asset_deleted_value = oInfo.asset_deleted_value + asset_deleted_value;
                    depreciation_added_value = oInfo.depreciation_added_value + depreciation_added_value;
                    depreciation_adjusted_value = oInfo.depreciation_adjusted_value + depreciation_adjusted_value;
                    depreciation_deleted_value = oInfo.depreciation_deleted_value + depreciation_deleted_value;
                    depreciation_ob = oInfo.depreciation_ob + depreciation_ob;
                    new_depreciation_cb = oInfo.new_depreciation_cb + new_depreciation_cb;
                    wdv_ob = oInfo.wdv_ob + wdv_ob;
                    wdv_cb = oInfo.wdv_cb + wdv_cb;
                    })
            } //alert(asset_ob);
                aSummery.push({
                    'fnclass':val.category_name,
                    'asset_ob':asset_ob.toFixed(2),
                    'asset_cb':asset_cb.toFixed(2),
                    'asset_added_value':asset_added_value.toFixed(2),
                    'asset_deleted_value':asset_deleted_value.toFixed(2),
                    'depreciation_added_value':depreciation_added_value.toFixed(2),
                    'depreciation_adjusted_value':depreciation_adjusted_value.toFixed(2),
                    //'depreciation_cb':depreciation_cb,
                    'depreciation_deleted_value':depreciation_deleted_value.toFixed(2),
                    'depreciation_ob':depreciation_ob.toFixed(2),
                    'depreciation_cb':new_depreciation_cb.toFixed(2),
                    'wdv_cb':wdv_cb.toFixed(2),
                    'wdv_ob':wdv_ob.toFixed(2),
                });
            }); //console.log(aSummery);
        });
        //console.log(aSummery);
        setAssetInfo(aSummery); 
        console.log(assetInfo);
    //}
    }, [fromperiod,toperiod, assetInfo]);
    return ( 
        <div id="home">
    <div  className="container">
        <h5>IGAAP Depreciation</h5>
        <div className='row'>
            <div className='col'>
                {/*<FromFieldLabel label="User Division : " inputValue={""} />
            <br/>
    <FromFieldLabel label="User Name : " inputValue={""} />*/}
            </div>
            <div className='col'>
                {/*<FromFieldSelect 
                    label=" From :"
                    placeholder="Select Month"
                    labelAlign="right"
                    setSelectedValue={setPeriod}
                    selectedValue={period}
                    values={["JAN","Feb","MAR","APR","MAY","JUN","JUL","AUG","SEP","OCT","NOV","DEC"]}
                />*/}
                <FromFieldDate star="*"
                    label="From Date" 
                    // placeholder="Date of Capitalization / Asset Ready To Use" 
                    inputValue={fromperiod}
                    setInputValue={setFromPeriod} 
                    setErrorMsg={setErrFromPeriod}
                /> 
                {/*<div className="row">
        <div className="col-md-6" > 
            <label> From Date  </label><span className="star error"> * </span>
        </div>
        <div className="col-md-6" > 
            <DatePicker wrapperClassName="cutom-form-control" className="form-control"
                placeholderText='Select Date'
                selected={fromperiod} 
                dateFormat="yyyy-MM-dd"
                onChange={handleFromDate} 
            />
        <span className="invalid-feedback"> {ErrFromPeriod} </span>
        </div>
    </div>*/}
                {/*<FromFieldSelect 
                    label=" To :"
                    placeholder="Select Year"
                    labelAlign="right"
                    setSelectedValue={setPeriod}
                    selectedValue={period}
                    values={["2017","2018","2019","2020","2021","2022","2023","2024","2025","2026","2027","2028",]}
                />*/}
                <FromFieldDate star="*"
                    label="To Date" 
                    // placeholder="Date of Capitalization / Asset Ready To Use" 
                    inputValue={toperiod}
                    setInputValue={setToPeriod} 
                    setErrorMsg={setErrToPeriod}
                /> 
               {/* <div className="row">
        <div className="col-md-6" > 
            <label> To Date  </label><span className="star error"> * </span>
        </div>
        <div className="col-md-6" > 
            <DatePicker wrapperClassName="cutom-form-control" className="form-control"
                placeholderText='Select Date'
                selected={toperiod} 
                dateFormat="yyyy-MM-dd"
                onChange={handleToDate} 
            />
        <span className="invalid-feedback"> {ErrToPeriod} </span>
        </div>
    </div>*/}
                <button className="btn btn-primary btn-md" onClick={getSummeryInfo}> Fetch Data </button>
            </div>
            <div className='col'>
            <button className="btn btn-secondary btn-sm" onClick={printSummary}><i className="bi bi-printer"></i> Print Summary </button><br/>
            {/*<button className="btn btn-secondary btn-sm" ><i className="bi bi-download"></i> Export Summary </button><br/>
            <button className="btn btn-secondary btn-sm" ><i className="bi bi-download"></i> Export Data </button>*/}
            </div>
        </div>
        <div className='row' id='print_summary'>
            <div className='col table-responsive' id="dep_summary">
                <table>
                    <thead>
                        <tr>
                            <th colSpan={12}> 
                                IGAAP Depreciation
                            </th>
                        </tr>
                        <tr>
                            <th rowSpan={2}> Financial Classification </th>
                            <th colSpan={4}> Asset Value </th>
                            <th colSpan={5}> Depreciation </th>
                            <th colSpan={2}> Net Block </th>
                        </tr>
                        <tr>
                            <th>Opening Value</th>
                            <th>Addition</th>
                            <th>Deletion</th>
                            <th>Closing Value</th>
                            <th className="bg-warning">Opening Acc Dep</th>
                            <th className="bg-warning">Addition for the Period</th>
                            <th className="bg-warning">Deletion for the Period</th>
                            <th className="bg-warning">Adjustment for the Period</th>
                            <th className="bg-warning">Closing Acc Dep</th>
                            <th className="bg-info">Opening WDV</th>
                            <th className="bg-info">Closing WDV</th>
                        </tr>
                    </thead>
                    <tbody>
                        { 
                            assetInfo.map((asset_info)=> {
                                //alert('i1');
                                //console.log(asset_info);
                                //assetInfo = assetInfo[i];
                                return (
                                    
                                    <tr>   
                                        <td className='fnclass'>{asset_info.fnclass?asset_info.fnclass:0}</td>
                                        <td className='price'>{asset_info.asset_ob?asset_info.asset_ob:0}</td>
                                        <td className='price'>{asset_info.asset_added_value?asset_info.asset_added_value:0}</td>
                                        <td className='price'>{asset_info.asset_deleted_value?asset_info.asset_deleted_value:0}</td>
                                        <td className='price'>{asset_info.asset_cb?asset_info.asset_cb:0}</td>
                                        <td className='price'>{asset_info.depreciation_ob?asset_info.depreciation_ob:0}</td>
                                        <td className='price'>{asset_info.depreciation_added_value?asset_info.depreciation_added_value:0}</td>
                                        <td className='price'>{asset_info.depreciation_deleted_value?asset_info.depreciation_deleted_value:0}</td>
                                        <td className='price'>{asset_info.depreciation_adjusted_value?asset_info.depreciation_adjusted_value:0}</td>
                                        <td className='price'>{asset_info.depreciation_cb?asset_info.depreciation_cb:0}</td>
                                        <td className='price'>{asset_info.wdv_ob?asset_info.wdv_ob:0}</td>
                                        <td className='price'>{asset_info.wdv_cb?asset_info.wdv_cb:0}</td>
                                    </tr>
                                )
                                    
                            })
                        }
                        <tr>
                            <td> Grand Total </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div className='row'><br></br></div>
     </div>
     </div>
      
  )
}

export default DepreciationSummary