import React, {useEffect, useState} from 'react';
import * as CryptoJS from "crypto-js";
import FromFieldText from "../../atoms/FromFieldText";
import {getMyProfileDetails} from '../../services/user.service';

function ManageMyProfile() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [role, setRole] = useState('');
    const [division, setDivision] = useState('');
    const [department, setDepartment] = useState('');
    const [company, setCompanyName] = useState('');

    useEffect(() => {
        //encrypted data
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'authToken': sessionStorage.getItem('LoggedInToken', 'LoggedInUserId'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

        let oData = {'token': EncryptData};

        // Fetch data
        getMyProfileDetails(oData).then(function (res) {
            if (res?.data?.data.length) {
                let userInfo = res.data.data[0];

                setRole(userInfo.role_name ? userInfo.role_name : '');
                setCompanyName(userInfo.company_name ? userInfo.company_name : '');
                setDivision(userInfo.division_name ? userInfo.division_name : '');
                setDepartment(userInfo.department_name ? userInfo.department_name : '');
                setName(userInfo.username);
                setEmail(userInfo.email);
                setMobile(userInfo.mobile);
            }
        });

    }, []); // Empty dependency array


    return (
        <div className="container">

            <div className="row m-2">
                <div className="col-6">
                    <h5>View My Profile</h5>
                </div>
            </div>
            <div className='row'>
                <div className='col-12'>&nbsp;</div>
            </div>
            <div>
                <div className="row m-2">
                    <div className="col-6">
                        <FromFieldText
                            label="Company"
                            inputValue={company}
                            setInputValue={setCompanyName} readOnly='readOnly'
                        />
                    </div>
                    <div className="col-6"></div>
                </div>
                <div className="row m-2">
                    <div className="col-6">
                        <FromFieldText
                            label="Role"
                            inputValue={role}
                            setInputValue={setRole} readOnly='readOnly'
                        />
                    </div>
                </div>
                <div className="row m-2">
                    <div className="col-6">
                        <FromFieldText
                            label="Control Department"
                            inputValue={department}
                            setInputValue={setDepartment} readOnly='readOnly'
                        />
                    </div>
                </div>
                <div className="row m-2">
                    <div className="col-6">
                        <FromFieldText
                            label="Division"
                            inputValue={division}
                            setInputValue={setDivision} readOnly='readOnly'
                        />
                    </div>
                </div>
                <div className="row m-2">
                    <div className="col-6">
                        <FromFieldText
                            label="Name"
                            inputValue={name}
                            setInputValue={setName} readOnly='readOnly'
                        />
                    </div>
                    <div className="col-6"></div>
                </div>
                <div className="row m-2">
                    <div className="col-6">
                        <FromFieldText
                            label="Email"
                            inputValue={email}
                            setInputValue={setEmail} readOnly='readOnly'
                        />
                    </div>
                </div>
                <div className="row m-2">
                    <div className="col-6">
                        <FromFieldText
                            label="Mobile"
                            inputValue={mobile}
                            setInputValue={setMobile} readOnly='readOnly'
                        />
                    </div>
                    <div className="col-6"></div>
                </div>
                <div className="row m-2">
                    <div className="col-6 text-center">
                    <a href={'/'}><button id="btn-submit" className="btn btn-secondary btn-md"> Cancel</button></a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ManageMyProfile