import React, { useState, useEffect } from 'react';


function GenerateReversalEntry() {

    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h5>Generate Reversal Entry</h5>
                </div>
            </div>

            <div className='row mt-4'>
                <div className='table-responsive'>
                    <table className='table-bordered w-100 text-center'>
                        <thead>
                            <tr>
                                <td style={{fontWeight:700,color:'#fff',background:'#000531',padding:'5px'}}>DIN</td>
                                <td style={{fontWeight:700,color:'#fff',background:'#000531',padding:'5px'}}>TAG</td>
                                <td style={{fontWeight:700,color:'#fff',background:'#000531',padding:'5px'}}>Asset CB Value on Final Date of Disposal</td>
                                <td style={{fontWeight:700,color:'#fff',background:'#000531',padding:'5px'}}>Acc Dep as on Final Date of Disposal</td>
                                <td style={{fontWeight:700,color:'#fff',background:'#000531',padding:'5px'}}>Final Sale Price W/o GST</td>
                                <td style={{fontWeight:700,color:'#fff',background:'#000531',padding:'5px'}}>Evaluation</td>
                                <td style={{fontWeight:700,color:'#fff',background:'#000531',padding:'5px'}}>Comment</td>
                                
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>Option 1</td>
                                <td>Loss</td>
                            </tr>
                            <tr>
                                <td colSpan={5}></td>
                                <td>Option 2</td>
                                <td>Profit</td>
                            </tr>
                            
                        </tbody>
                    </table>
                </div>
            </div>


            <div className="row mt-4">
                <div className="col-12 text-center">
                    <button className="btn btn-primary me-2" > PRINT EVALUATION </button>
                </div>
            </div>

            <div className='row mt-4'>
                <div className='col-6'>
                    <p style={{fontWeight:'700',fontSize:'15px'}}>Option 1</p>
                    <ul>
                        <li>By Bank A/c Dr</li>
                        <li>Loss on Sale of TAG No Dr</li>
                        <li>By Acc Dep as on Date of disposal TAG No Dr</li>
                        <li>To Asset CB Value of TAG No Cr</li>
                    </ul>
                </div>
                <div className='col-6'>
                    <p style={{fontWeight:'700',fontSize:'15px'}}>Option 2</p>
                    <ul>
                        <li>By Bank A/c Dr</li>
                        <li>By Acc Dep as on Date of disposal TAG No Dr</li>
                        <li>To Asset CB Value of TAG No Cr</li>
                        <li>To Profit on Sale of TAG No Cr</li>
                    </ul>
                </div>
            </div>


        </div>
    )
}

export default GenerateReversalEntry;