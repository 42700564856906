import React, { useState, useRef, useEffect,useCallback } from 'react';
import { useLocation } from "react-router-dom";
import FromFieldText from '../../../atoms/FromFieldText';
import FromMoveList from '../../../molecules/FromMoveList';
import FromMoveList2 from '../../../molecules/FromMoveList2';
import { getAssets,getCapitalized } from '../../../services/asset.service'
import  * as CryptoJS  from "crypto-js";
import './AssetInfo.css';
//const templatePath = "/exceltemplates/Asset_Detail_Template.xlsx"

export default function AssetGenrateDepreciation() {
  const errRef = useRef();
  const [mon, setMon] = useState(false);
  const [fy, setFY] = useState(false);
  const [assetPopDisplay1, setAssetPopDisplay1] = useState(false);
  const [assetsData1, setAssetsData1] = useState([]);
  const [assetPopDisplay2, setAssetPopDisplay2] = useState(false);
  const [assetsData2, setAssetsData2] = useState([]);
  const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const Mon = query.get('m');
    const FY = query.get('fy'); 
    //const elementRef = useRef();
    useEffect(() => {
        if(Mon!='' && Mon!=null){
          setMon(Mon);
        }
        if(FY!='' && FY!=null){
            setFY(FY);
          }
          let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            //'param_data':obj,
            'authToken': sessionStorage.getItem('LoggedInToken'),
          }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
          let oData = {'token':EncryptData}
          getCapitalized(oData).then(res=>{
            if(res?.data?.data?.length) setAssetsData1(res.data.data);setAssetsData2(res.data.data);
          })
    }, []);
  function getMonthName(monthNumber) {
      const date = new Date()
      date.setMonth(monthNumber-1) // starts with 0, so 0 is January
      return date.toLocaleString('en-EN', { month: "long" })
    }
  
  /*
  const handleFinancialYear = (e) => {
    let value= e.target.value;
    //setFinancialYear(e.target.value)
  } 
  const handleSubmit = async (e) => { 
		e.preventDefault(); var valid_Name=true;
    if(fy ===''){
        setValidName(false);
        alert("Financial year is missing"); valid_Name=false;
	}
    if(mon ===''){ 
        setValidName(false);
        alert('Month is missing'); valid_Name=false;
	}
    if (!valid_Name) { alert(valid_Name); 
			return false;
		}else{ //alert(mon); alert(fy);
            saveData();
        }
		
	}
  
  const saveData = function() {
    var data = {
      //'tag': tag, 
      'session':fy, 
      'month':mon,      
    };
    try {   
        calculateDepreciation(data).then(function(res){
            console.log(res);
            setSuccess(res.data.message); //alert('a');
            //elementRef.current.focus();
        }); setValidName(false);
    } catch (err) { setSuccess(false);
        if (!err?.data) {
            console.log(err);
            //setErrMsg('No Server Response');
            //setSuccess("Successfully saved Single Asset");
        } else if (err.data?.status === 409) {
            //setErrMsg('Record already exist');
        } else {
            setErrMsg('Insert/Update Failed');
        }
        //elementRef.current.focus();
    }
    
  }*/
  // readAssets();
  return (
    <div className='container'>
      <div className='row deprec single_assets'>
        <div className="col-6">
            <h5>Generate Depriciation</h5>
        </div>
        <div className="col-3">
        <FromFieldText 
        label="Month " 
        inputValue={getMonthName(mon)}
        readOnly="readonly"
      />
      <FromFieldText
        label="Year :" 
        inputValue={FY} 
        readOnly="readonly"
      />
         {/*} <strong>Month :</strong> {getMonthName(mon)}; <strong>Year : </strong>{FY}*/}</div>

        <div className="col-3"><a href={'/asset-depreciation?month='+mon+'&year='+ FY } className='btn btn-secondary btn-md'><i className="bi bi-arrow-left"></i> Back</a>&nbsp;<a className='btn btn-info btn-md btn-md' href=''><i class="bi bi-arrow-clockwise"></i> Refresh</a></div>
        </div>
            
    
      <div className="row gen_deprec">
      <div className="col-6"><strong>Depreciation Type</strong></div>
      <div className="col-3"><strong>Current Status</strong></div>
      <div className="col-3"><strong>Action</strong></div>
      </div>
         
      <div className="row deprec">
        <div className="col-6">I-GAAP Depreciation SLM Year Wise - Month wise</div>
        <div className="col-2">Not generated</div>
        
        <div className="col-4">
        <FromMoveList
            label1="I-GAAP Depreciation SLM Year Wise - Month wise" 
            showPopUp1={assetPopDisplay1} 
            setShowPopUp1={setAssetPopDisplay1} 
            data1={assetsData1} 
            datasetter1={setAssetsData1} />
        </div>
      </div>
      
      {/*<div className="row deprec">
        <div className="col-6">I-GAAP Depreciation SLM Year Wise - Day wise</div>
        <div className="col-3">Not generated</div>
        
        <div className="col-3">
            <button type="submit"  className="btn btn-primary btn-md">Generate for the Month</button>
        </div>
      </div>

      <div className="row deprec">
        <div className="col-6">I-GAAP Depreciation SLM % Wise - Month wise</div>
        <div className="col-3">Not generated</div>
        
        <div className="col-3">
            <button type="submit"  className="btn btn-primary btn-md">Generate for the Month</button>
        </div>
      </div>

      <div className="row deprec">
        <div className="col-6">I-GAAP Depreciation SLM % Wise - Day wise</div>
        <div className="col-3">Not generated</div>
        
        <div className="col-3">
            <button type="submit"  className="btn btn-primary btn-md">Generate for the Month</button>
        </div>
      </div>*/}
      
      <div className="row deprec">
        <div className="col-6">Other GAAP Depreciation SLM Year Wise Only - Month Wise</div>
        <div className="col-2">Not generated</div>
        
        <div className="col-4">
        <FromMoveList2
            label2="Other GAAP Depreciation SLM Year Wise Only - Month Wise" 
            showPopUp2={assetPopDisplay2} 
            setShowPopUp2={setAssetPopDisplay2} 
            data2={assetsData2} 
            datasetter2={setAssetsData2} 
        />
        </div>
      </div>
      
      {/*
      <div className="row deprec">
        <div className="col-6">Other GAAP Depreciation SLM Year Wise Only - Day Wise</div>
        <div className="col-3">Not generated</div>
        
        <div className="col-3">
            <button type="submit"  className="btn btn-primary btn-md">Generate for the Month</button>
        </div>
      </div>

      <div className="row deprec">
        <div className="col-6">Income Tax Depreciation (%)- Calculate Annually Show Day Wise (Ref Example on Right Side)</div>
        <div className="col-3">Not generated</div>
        
        <div className="col-3">
            <button type="submit"  className="btn btn-primary btn-md">Generate for the Month</button>
        </div>
      </div>


      <div className="row deprec">
        <div className="col-6">Single Extra Shift Depreciation</div>
        <div className="col-3">Not generated</div>
        
        <div className="col-3">
            <button type="submit"  className="btn btn-primary btn-md">Generate for the Month</button>
        </div>
      </div>

      <div className="row deprec">
        <div className="col-6">Double Extra Shift Depreciation</div>
        <div className="col-3">Not generated</div>
        
        <div className="col-3">
            <button type="submit"  className="btn btn-primary btn-md">Generate for the Month</button>
        </div>
      </div>

      */}
      

      <div className='row deprec save__button text-center'>
                <div className='col-md-12'>
                    {/*<button type="submit" className="btn btn-primary btn-md" > Genrate </button>*/}
                </div>
            </div>
    </div>
  )
}

