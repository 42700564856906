import React, { useState, useEffect } from 'react';



function InitiateSingleTransferTwo() {

    const myStyle = {
        fontWeight: 700,
        color: '#fff',
        background: '#000531',
        padding: '5px'
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h5>Initiate Single Disposal- Two</h5>
                </div>
            </div>

            <div className='row mt-4'>
                <div className='table-responsive'>
                    <table className='table-bordered w-100 text-center'>
                        <thead>
                            <tr>
                                <td style={myStyle}>Sl No</td>
                                <td style={myStyle}>TIN</td>
                                <td style={myStyle}>TAG No</td>
                                <td style={myStyle}>Financial Classification</td>
                                <td style={myStyle}>Supplier Name</td>
                                <td style={myStyle}>Invoice Date</td>
                                <td style={myStyle}>Supplier Invoice No</td>
                                <td style={myStyle}>Current Division</td>
                                <td style={myStyle}>Current Premises</td>
                                <td style={myStyle}>Current Floor</td>
                                <td style={myStyle}>Current Site ID</td>
                                <td style={myStyle}>Current User Department</td>
                                <td style={myStyle}>Current Employee ID</td>
                                <td style={myStyle}>Current Employee Name</td>
                                <td style={myStyle}>Action</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ padding: '10px' }}>
                                    <button type='button' className='btn btn-warning'>Remove</button>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ padding: '10px' }}>
                                    <button type='button' className='btn btn-warning'>Remove</button>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ padding: '10px' }}>
                                    <button type='button' className='btn btn-warning'>Remove</button>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ padding: '10px' }}>
                                    <button type='button' className='btn btn-warning'>Remove</button>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ backgroundColor: '#e9ecef', opacity: 1 }}></td>
                                <td style={{ padding: '10px' }}>
                                    <button type='button' className='btn btn-warning'>Remove</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-12 text-center">
                    <button className="btn btn-primary me-2 p-3" > VIEW AND INITIATE INTERNAL TRANSFER
                    </button>
                    <button className="btn btn-primary p-3" > VIEW AND INITIATE EXTERNAL TRANSFER
                    </button>
                </div>
            </div>

        </div>
    )
}

export default InitiateSingleTransferTwo;