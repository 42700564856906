import React from 'react';
import MultiListPopUp2 from '../atoms/MultiListPopUp2';
function FromMoveList2(props) {
  return (
    <div className="row form-fields">
        <div className="label col-6" > 
            <label>  </label> 
        </div>                   
        <div className="item col-6" > 
            <button onClick={()=> {props.setShowPopUp2(true);} } className="btn btn-primary mb-2">
                    Continue <span className="bi bi-gear-fill"></span>
            </button>

            <MultiListPopUp2 
                title2={props.label2} 
                show2={props.showPopUp2} 
                showsetter2={props.setShowPopUp2} 
                data2={props.data2} 
                datasetter2={props.datasetter2} />
        </div>
    </div>
  )
}

export default FromMoveList2