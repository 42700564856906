import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import {DataGrid} from '@mui/x-data-grid';
import {getCompanyProfileList} from '../../services/user.service'
import * as CryptoJS from "crypto-js";
import {deleteCompanyProfile} from "../../services/asset.service";

Date.prototype.dbFormatedDate = function () {

    let year = this.getFullYear();
    let month = this.getMonth() + 1;
    let day = this.getDate();
    if (month < 10) {
        month = '0' + month;
    }
    if (day < 10) {
        day = '0' + day;
    }
    return year + '-' + month + '-' + day;
    //return `${this.getFullYear()}-${this.getMonth()+1}-${this.getDate()-1}`
}

function getFormatedDate(dateString) {
    if (dateString !== null) {
        let dte = new Date(dateString);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }
        return year + '-' + month + '-' + day;
        //return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
    } else {
        return null;
    }
}



function CompanyProfileList() {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    const columns = [

        {"field": "company_id",  "hide": false, "headerName": "Company Id", "width": 100},
        {"field": "company_name",  "hide": false, "headerName": "Company Name", "width": 250},
        {"field": "company_short_name",  "hide": false, "headerName": "Company Short Name", "width": 200},
        {"field": "company_address",  "hide": false, "headerName": "Company Address", "width": 130},
        {"field": "pan_number",  "hide": false, "headerName": "PAN Number", "width": 130},
        {"field": "gst_number",  "hide": false, "headerName": "GST Number", "width": 130},
        {"field": "website_name",  "hide": false, "headerName": "Website Name", "width": 130},
        {
            "field": "name_authorized_signatory",
            
            "hide": false,
            "headerName": "name Authorized Signatory",
            "width": 130
        },
        {
            "field": "contact_person_name",
            
            "hide": false,
            "headerName": "Contact Person Name",
            "width": 130
        },
        {"field": "landline_no",  "hide": false, "headerName": "Landline no", "width": 130},
        {"field": "authorized_mail_id",  "hide": false, "headerName": "Authorized Mail id", "width": 130},
        {"field": "Contact mail id",  "hide": false, "headerName": "Contact Mail id", "width": 130},
        {
            "field": "contact_mobile_number",
            
            "hide": false,
            "headerName": "Contact Mobile Number",
            "width": 130
        },
        {
            "field": "date_of_contract",  "hide": false, "headerName": "Date of Contract", "width": 130,
            renderCell: (params) => (
                <p>{getFormatedDate(params.row.date_of_contract)}</p>
            )
        },
        {"field": "signed_by",  "hide": false, "headerName": "Signed by", "width": 130},
        {
            "field": "data_from_date",  "hide": false, "headerName": "Data From Date", "width": 130,
            renderCell: (params) => (
                <p>{getFormatedDate(params.row.data_from_date)}</p>
            )
        },
        {
            "field": "data_to_date",  "hide": false, "headerName": "Data To Date", "width": 130,
            renderCell: (params) => (
                <p>{getFormatedDate(params.row.data_to_date)}</p>
            )
        },
        {
            "field": "contract_from_date",
            
            "hide": false,
            "headerName": "Contract From Date",
            "width": 130,
            renderCell: (params) => (
                <p>{getFormatedDate(params.row.contract_from_date)}</p>
            )
        },
        {
            "field": "contract_end_date",  "hide": false, "headerName": "Contract End Date", "width": 130,
            renderCell: (params) => (
                <p>{getFormatedDate(params.row.contract_end_date)}</p>
            )
        },

        {
            field: 'actions', headerName: 'Actions', width: 120, renderCell: (params) => {
                let EncryptID = CryptoJS.AES.encrypt(JSON.stringify({
                    'company_id': params.row.company_id,
                }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
                return (
                    <span>
                    <a href={`/companyprofile?companyid=${EncryptID}`} className="p-2"><button type="button" className="btn btn-primary btn-md">
                <i className="bi bi-pencil"></i></button></a>
                    <button
                        type="button"
                        className="btn btn-danger btn-sm ml-2"
                        onClick={() => handleDeleteClick(params.row.company_id)}
                    >
                            <i className="bi bi-trash"></i>
                        </button>
                </span>
                );
            }
        }
    ];

    const handleDeleteClick = (costId) => {
        //alert(costId);
        //console.log(`Deleting cost center with ID: ${costId}`);


        const confirmDelete = window.confirm("Are you sure you want to delete this cost center?");

        if (confirmDelete) {

            //encrypted data
            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': {'companyId': costId},
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData = {'token': EncryptData}

            deleteCompanyProfile(oData).then(function (res) {
                //console.log(res);
                if ((typeof (res.data.errors) != "undefined") && res.data.errors.errno > 0) {
                    setErrMsg(res.data.message);
                    setSuccess(false);
                } else {
                    setSuccess(res.data.message);
                    window.location.reload();
                }
            });

        } else {
            window.location.reload();
        }



    };

    useEffect(() => {
        //encrypted data
        let EncryptData = CryptoJS.AES.encrypt(
            JSON.stringify({
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }),
            process.env.REACT_APP_ENCRYPT_SECRET_KEY
        ).toString();
        let oData = { 'token': EncryptData };

        setLoading(true);

        getCompanyProfileList(oData)
            .then((res) => {
                if (res?.data?.data?.length) setItems(res.data.data);
                setSuccess(true);
            })
            .catch((error) => {
                setErrMsg(error.message);
                setSuccess(false);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    // readCompanyProfile();
    return (
        <div className='container1'>
            <div className='row'>
                <div className='col-md-6'>
                    <h5>Company List</h5>
                </div>
            </div>

            {loading ? (
                <div className="text-center">
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            ) : (
                <DataGrid
                    rows={items}
                    getRowId={(row) => row.company_id}
                    columns={columns}
                    pageSize={50}
                    autoHeight
                    rowsPerPageOptions={[50]}
                />
            )}


        </div>
    );
}

export default CompanyProfileList
