import React, { useState } from 'react';
import Modal from 'react-modal';
const TEXT_REGEX = /^[A-Za-z0-9-_/.@= ]{1,155}$/;
const ConfirmBox = ({ isOpen, onClose, onConfirm }) => {
  const [inputValue, setInputValue] = useState('');
  const [ErrInputValue, setErrInputValue] = useState('');
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleConfirm = async (e) => {
    e.preventDefault();
    var valid_Name = true;
    if (!TEXT_REGEX.test(inputValue)) { 
      setErrInputValue('Reason is required'); valid_Name=false;
    }else{ setErrInputValue('');  }
    if (!valid_Name) { //alert(valid_Name);
      return false;
    } else { //alert(mon); alert(fy);
      onConfirm(inputValue);
      onClose();
      setInputValue('');
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Confirm Box"
      style={modalStyles}
    >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
        <h5 class="modal-title">Confirmation !</h5>
        <button type="button" class="btn-close" onClick={onClose}></button>
        </div>
        <div class="modal-body" style={modalBody}>
            <label>Please enter the reason:</label>
            <textarea className='form-control' rows={3} value={inputValue} onChange={handleInputChange}>{inputValue}</textarea>
            <span className="invalid-feedback"> {ErrInputValue} </span>
        </div>
        <div class="modal-footer">
        <button  class="btn btn-primary btn-md" onClick={handleConfirm}>Confirm</button>
        <button class="btn btn-secondary btn-sm" onClick={onClose}>Cancel</button>
      </div>
      </div>
      </div>
      
    </Modal>
  );
};

const modalStyles = {
  content: {
    width: '400px',
    height: '400px',
    margin: 'auto',
  },
};
const  modalBody ={
    content: {
        position: 'relative',
        flex: '1 1 auto',
        padding: '10px !important'
    },
}
export default ConfirmBox;