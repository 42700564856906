import React from 'react'

function FromFieldSelect(props) {
    var labelAlign = (props.labelAlign=="right")? "align-right" : "";
  return (
    <div className="row">
        <div className={labelAlign + "col-6"}> 
            <label> {props.label} <span className="star error"> {props.star} </span></label>
             
        </div>                   
        <div className="col-md-6" > 
            <select  onChange={(event)=>{ if(event.target.value!="") props.setSelectedValue(event.target.value)}} 
                    value={(props.selectedValue=='')? "select" : props.selectedValue} readOnly={props.readOnly} disabled={props.disabled} className="form-select form-select-sm">
                <option value="select"> Select One</option>
                {
                    props.values.map((elm,idx)=> <option key={idx} value={elm}> 
                                {elm.toUpperCase()} 
                            </option>
                    )
                }
            </select>
            <span className="invalid-feedback"> {props.setErrorMsg} </span>
        </div>
    </div>
  )
}

export default FromFieldSelect;