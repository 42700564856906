import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import FromFieldText from '../../atoms/FromFieldText';
import { getCompanyProfileList, getRFIDKeyInfo, saveRFIDKey } from '../../services/user.service';
import * as CryptoJS from "crypto-js";

const TEXT_REGEX = /^[a-zA-Z0-9-_ ]{1,255}$/;

function AddRFIDKeyGenerate() {
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    const [companyId, setCompanyId] = useState('');
    const [ErrCompanyId, setErrCompanyId] = useState('');

    const [rfidkey, setRfidKey] = useState('');
    const [ErrRfidKey, setErrRfidKey] = useState('');

    const [companies, setCompanies] = useState([]);

    const resetUserInfo = () => {
        setRfidKey('');
        setCompanyId('');
    };

    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const rfid_ID = query.get('rfid_ID');

    useEffect(() => {
        const fetchData = async () => {
            if (rfid_ID !== '' && rfid_ID != null) {
                try {
                    const decryptedBytes = CryptoJS.AES.decrypt(rfid_ID.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
                    const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
                    const rfidId = decrypt_data.rfid_id;
                    await getRFIDData(rfidId);
                } catch (error) {
                    console.error("Error decrypting rfid_ID: ", error);
                }
            }

            try {
                const EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                    'authToken': sessionStorage.getItem('LoggedInToken'),
                }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
                const oData = { 'token': EncryptData };

                const res = await getCompanyProfileList(oData);
                if (res?.data?.data?.length) setCompanies(res.data.data);
            } catch (error) {
                console.error("Error fetching company profile list: ", error);
            }
        };

        fetchData();
    }, [rfid_ID]);

    const getRFIDData = async (rfidId) => {
        try {
            const EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': { 'rfid_data_id': rfidId },
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            const oData1 = { 'token': EncryptData };

            const res = await getRFIDKeyInfo(oData1);
            if (res?.data?.data) {
                const rfidInfo = res.data.data[0];
                setCompanyId(rfidInfo.company_id);
                setRfidKey(rfidInfo.rfid_key);
            }
        } catch (error) {
            console.error("Error fetching RFID data: ", error);
        }
    };

    const handleCompanyId = (e) => {
        setCompanyId(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let valid = true;

        if (!companyId) {
            setErrCompanyId('Company name is required');
            valid = false;
        } else {
            setErrCompanyId('');
        }

        if (!rfidkey) {
            setErrRfidKey('RFID key is required');
            valid = false;
        } else {
            setErrRfidKey('');
        }

        if (valid) {
            saveData();
        }
    };

    const saveData = () => {
        try {

            if (rfid_ID !== '' && rfid_ID !== null) {
                const decryptedBytes = CryptoJS.AES.decrypt(rfid_ID.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
                // Convert the decrypted bytes to a string
                const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
                const decryptedRFIDId = decrypt_data.rfid_id;
                var obj = {
                    'rfidID': decryptedRFIDId,
                    'rfid_key': rfidkey,
                    'company_id': companyId,
                    'status': '1',
                    'created_by': sessionStorage.getItem('LoggedInUserId'),
                }
            } else {
                var obj = {
                    'rfid_key': rfidkey,
                    'company_id': companyId,
                    'status': '1',
                    'created_by': sessionStorage.getItem('LoggedInUserId'),
                }
            }

            if (rfid_ID !== '' && rfid_ID !== null) {
                const decryptedBytes = CryptoJS.AES.decrypt(rfid_ID.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
                const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
                obj.rfid_ids = decrypt_data.rfid_ID;
            }

            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': obj,
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData = {'token': EncryptData};

            saveRFIDKey(oData).then(res => {
                if (res.data.errors && res.data.errors.errno > 0) {
                    setErrMsg(res.data.message);
                    setSuccess(false);
                } else {
                    setSuccess(res.data.message);
                    resetUserInfo();
                }
            });
        } catch (err) {
            setSuccess(false);
            if (!err?.data) {
                setErrMsg('No Server Response');
            } else if (err.data?.status === 409) {
                setErrMsg('Data already exists');
            } else {
                setErrMsg('Insert/Update Failed');
            }
        }
    };

    const generateKey = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let key = '';
        for (let i = 0; i < 12; i++) {
            key += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        // Add hyphen every 4 characters
        key = key.match(/.{1,4}/g).join('-');
        setRfidKey(key);
    };


    return (
        <div className="container">
            <div className="row">
                <div className="col-6">
                    <h5>Generate RFID Key</h5>
                </div>
                <div className="invalid-feedback" role="alert">
                    <i className="fa fa-info-circle"></i>
                    Please Fill all mandatory fields.
                </div>

                {success ? (
                    <div className="alert alert-success" role="alert">
                        <i className="bi bi-check-circle-fill"></i>{success}
                    </div>
                ) : (
                    errMsg && (
                        <div className="alert alert-danger" role="alert">
                            <i className="bi bi-exclamation-triangle-fill"></i>{errMsg}
                        </div>
                    )
                )}
            </div>

            <div className='row'>
                <div className='col-12'>&nbsp;</div>
            </div>

            <form onSubmit={handleSubmit}>
                <div className="row form-fields">
                    <div className="label col-3">
                        <label>Company </label><span className="star error"> *</span>
                    </div>
                    <div className="item col-3">
                        <select className="form-select form-select-sm mb-3" onChange={handleCompanyId}
                                value={companyId}>
                            <option value="">Select Company</option>
                            {companies && companies.length > 0 ? (
                                companies.map((option) => (
                                    <option key={option.company_id} value={option.company_id}>
                                        {option.company_name}
                                    </option>
                                ))
                            ) : (
                                <option value="">No Data</option>
                            )}
                        </select>
                        <span className="invalid-feedback"> {ErrCompanyId} </span>
                    </div>
                </div>

                <div className="row">
                    <div className="col-6">
                        <FromFieldText
                            readOnly={true}
                            star="*"
                            label="Reader Key"
                            inputValue={rfidkey}
                            setInputValue={setRfidKey}
                            setErrorMsg={setErrRfidKey}
                            errorMsg={ErrRfidKey}
                        />
                    </div>
                    <div className="col-6">
                        <button type="button" className="btn btn-primary btn-sm" onClick={generateKey}>Generate Key
                        </button>
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-3"></div>
                    <div className="col-6">
                        <button type="submit" className="btn btn-primary btn-sm">SAVE</button>
                    </div>
                    <div className="col-3"></div>
                </div>
            </form>
        </div>
    );
}

export default AddRFIDKeyGenerate;