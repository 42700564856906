import React, { useState,useEffect } from 'react';
import './atom.css';
import Box from '@mui/material/Box';
import { DataGrid,GridToolbarContainer,GridOverlay } from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from 'react-modal';
import { getTransferMadeByTinNo } from '../services/transfer.service';
import  * as CryptoJS  from "crypto-js";

   
const ReceviedConfirmBox = ({ isTinNo, isOpen, onClose, onConfirm }) => {
  const [loading, setLoading] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  const [items, setItems] = useState([]);
  const columns = [
    {"field":"sl_no","hideable":true,"hide":true,"editable":false,"headerName":"Sl No","width":50},
    {"field":"tag","editable":false,"hide":false ,"headerName":"TAG No","width":250},
    {"field":"tin_no","hideable":true,"hide":false,"editable":false,"headerName":"TIN No","width":280},
  ];

  useEffect(() => {
    const getTransferInfo = async function (decryptedTinNo) {
      setLoading(true);
      let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
        'param_data': { 'tin_no': decryptedTinNo },
        'authToken': sessionStorage.getItem('LoggedInToken'),
      }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
      let oData1 = { 'token': EncryptData };
      try {
        const res = await getTransferMadeByTinNo(oData1);
        if (res?.data?.data.length) {
          setItems(res.data.data);
        }
      } catch (error) {
        // Handle error
        console.error("Error fetching transfer info:", error);
      } finally {
        setLoading(false);
      }
    }
    if (isTinNo) {
      getTransferInfo(isTinNo);
    }
  }, [isTinNo]);
  const CustomLoader = () => (
    <GridOverlay>
      <CircularProgress color="primary" size={30} />
    </GridOverlay>
  );
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
      </GridToolbarContainer>
    );
  }
  

    const getTinNoInfo = function(tin_no) { //resetTransferInfo();
    let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
        'param_data':{'tin_no':tin_no},
        'authToken': sessionStorage.getItem('LoggedInToken'),
    }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
    let oData = {'token':EncryptData}
    getTransferMadeByTinNo(oData).then(function(res){
        console.log('res.data.data');
        //console.log(res.data.data[0]);
        if (res?.data?.data?.length){
            setItems(res.data.data);
        }
    })
    }
  
return(
    <Modal 
        isOpen={isOpen}
        onRequestClose={onClose}
        contentLabel="Confirm Box"
        style={modalStyles}
    >
    <div class="modal-dialog">
    <div class="modal-content">
        <div class="modal-header">
        <h5 class="modal-title">Transfer Made Asset Listing</h5>
        <button type="button" class="btn-close" onClick={onClose}></button>
        </div>
        <div class="modal-body" style={modalBody}>
            <Box sx={{ height: 500, width: '100%' }}>
                <DataGrid
                    rows={items}
                    getRowId={(row) => row.id}
                    columns={columns}
                    pageSize={50}
                    //height={500}
                    //autoHeight
                    //stickyHeaders={true}
                    rowsPerPageOptions={[50]}
                    components={{
                        LoadingOverlay: CustomLoader,
                        Toolbar: CustomToolbar,
                    }}
                    loading={loading}
                />
            </Box>
        </div>
    </div>
    </div>
    </Modal>
  );
};

const modalStyles = {
  content: {
    width: '400px',
    height: '400px',
    margin: 'auto',
  },
};
const  modalBody ={
    content: {
        position: 'relative',
        flex: '1 1 auto',
        padding: '10px !important'
    },
}
export default ReceviedConfirmBox;