import React,{ useState } from "react"
import { Link } from "react-router-dom";

export default function SidebarItem({item}){
    const [open, setOpen] = useState(false);
    //console.log(item);
    //get all sub modules
let aSubModuleId =[];
var sSubModuleId = sessionStorage.getItem('LoggedInSubModuleId');
if(sSubModuleId!=null){
  aSubModuleId = sSubModuleId.split(','); 
 }
 //console.log(aSubModuleId);
 
    if(item.childrens){
        return (
            <div className={open ? "sidebar-item open" : "sidebar-item"}>
                <div className="sidebar-title">
                    <span>
                        { item.icon && <i className={item.icon}></i> }
                        {item.title}    
                    </span> 
                    <i className="bi-chevron-down toggle-btn" onClick={() => setOpen(!open)}></i>
                </div>
                <div className="sidebar-content">
                    { item.childrens.map((child, index) => { if(aSubModuleId?.indexOf(child.sub_id) > -1){ return  <SidebarItem key={index} item={child} /> } } ) }
                </div>
            </div>
        )
    }else{
        return (
            <Link to={item.path? item.path: "#"} className="sidebar-item plain">
                { item.icon && <i className={item.icon}></i> }
                {item.title}
            </Link>
        )
    }
}