import React,{ useRef, useState } from 'react';
import SidebarItem from "../atoms/SidebarItem.js";
import items from "../data/sidebar.json";
import { Link } from "react-router-dom";

let aAccessModuleId =[]; let aSubModuleId =[];
var sModuleId = sessionStorage.getItem('LoggedInModuleId');
//console.log(sModuleId);
if(sModuleId!=null){
 aAccessModuleId = sModuleId.split(','); 
}
//get all sub modules
var sSubModuleId = sessionStorage.getItem('LoggedInSubModuleId');
if(sSubModuleId!=null){
  aSubModuleId = sSubModuleId.split(','); 
 }
 
//if(aAccessModuleId.indexOf(item.id) > -1){

//console.log(aSubModuleId);
export default function Sidebar(){
  const [open, setOpen] = useState(false);
    return (
        <div className="sidebar">
          { items.map((item, index) => { if(aAccessModuleId.indexOf(item.id) > -1){ 
            return <SidebarItem key={index} item={item}  />
            
          } } ) }
        </div>
    )
}