import React, { useRef, useState, useEffect } from 'react'
import { useLocation } from "react-router-dom";
import Select from 'react-select';
import FromFieldText from '../../../atoms/FromFieldText';
import './SingleAsset.css';
import { saveSingleExcel,getAssetsImages, getSTPI, getAllAssetsTag, getSuppliers, getInvoices }  from '../../../services/asset.service';
//import FromFieldLabel from '../../../atoms/FromFieldLabel';
import  * as CryptoJS  from "crypto-js";
const TEXT_REGEX = /^[A-Za-z0-9-_/.@= ]{1,155}$/;

export default function UploadAssetImage() {
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const Tag = query.get('tag'); 
    const elementRef = useRef();

    useEffect(() => {
        if(Tag!=='' && Tag!==null){
            getAssetsImagesInfo(Tag);
            //alert(Tag);
        }
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            //'param_data':obj,
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token':EncryptData}
        getAllAssetsTag(oData).then(res=>{
            if(res?.data?.data?.length) setAllAssetsData(res.data.data);
            });
        
        getSuppliers(oData).then(res=>{
            if(res?.data?.data?.length) setSuppliersData(res.data.data);
            });
        
    }, []);
    const getAssetsImagesInfo = function(Tag) {
        try{
            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data':{'tag':Tag},
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData1 = {'token':EncryptData}
            getAssetsImages(oData1).then(function(res){ 
                if(res?.data?.data) {
                console.log(res.data.data);
                //setImgDesc(stpiInfo.year_of_addition);
                //setTag(stpiInfo.tag);
                }
            });
        }catch (err) { 
            //setAssetInfo(''); 
        }
        
    }
    //const userRef = useRef();
    const errRef = useRef();
    const [imgDesc, setImgDesc, ] = useState('');
    const [ErrImgDesc, setErrImgDesc] = useState(false);
    const [uploadassetimage, setUploadAssetImage] = useState([]);
    const [ErrUploadAssetImage, setErrUploadAssetImage] = useState(false);
    
    const [tag, setTag] = useState('');
    const [supplier, setSupplier] = useState('');
    const [invoice, setInvoice] = useState('');

    const [ErrTag, setErrTag] = useState(false);
    const [ErrInvoice, setErrInvoice] = useState(false);
    const [errMsg, setErrMsg] = useState('');
	const [success, setSuccess] = useState(false);
    const [validName, setValidName] = useState(true);
    const [disabled, setDisabled] = useState(false);
    const [assetInfo, setAssetInfo] = useState({});
    const [allAssets,setAllAssetsData] = useState([]);
    const [suppliers ,setSuppliersData] = useState([]);
    const [invoices,setInvoicesData] = useState([]);
    const handleUploadImage = (e) => {
        setUploadAssetImage(e.target.files[0]);
            //alert(uploadassetimage.length);
    };
      /*const handleUploadImage = (e) => {
        setFile1(e.target.files[0]);
        setFileName1(e.target.files[0].name);
      };*/
    //get asset list
    const optionList = [];
    allAssets && allAssets.length > 0 ? (
        allAssets.map((option) => (
            optionList.push({value:option.tag,label:option.tag})
        ))
    ) : (
        optionList.push({value:"",label:"No Data"})
    )
    
    const [selectedOptions, setSelectedOptions] = useState();
    function handleSelect(data) {
        setSelectedOptions(data);
        console.log(data.value);
        setTag(data.value);
      }
    //get supplier list
    const supplierList = [];
    suppliers && suppliers.length > 0 ? (
        suppliers.map((option) => (
            supplierList.push({value:option.supplier_name,label:option.supplier_name})
        ))
    ) : (
        supplierList.push({value:"",label:"No Data"})
    )
    
    const [supplierOptions, setSupplierOptions] = useState();
    function handleSupplier(data) {
        setSupplierOptions(data);
        //console.log(data.value);
        setSupplier(data.value);
        //get invoice_number
        var oParam1= { 'supplier_name':data.value,}
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data':oParam1,
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData1 = {'token':EncryptData}
        getInvoices(oData1).then(res=>{
            if(res?.data?.data?.length) setInvoicesData(res.data.data);
            });
      }
    //get Invoice list
    const invoiceList = [];
    invoices && invoices.length > 0 ? (
        invoices.map((option) => (
            invoiceList.push({value:option.invoice_no,label:option.invoice_no})
        ))
    ) : (
        invoiceList.push({value:"",label:"No Data"})
    )
    
    const [invoiceOptions, setInvoiceOptions] = useState();
    function handleInvoice(data) {
        setInvoiceOptions(data);
        //console.log(data.value);
        setInvoice(data.value);
      }
    //subit image form
    const handleSubmit = async (e) => {
		e.preventDefault(); var valid_Name=true; //alert(valid_Name);
		// if button enabled with JS hack
		//const v1 = TEXT_REGEX.test(imgDesc);
        if(tag==""&&invoice==''){
            setErrInvoice('Tag OR Invoice is required'); valid_Name=false;
        }else{ 
            setErrInvoice(''); 
        }
		if (!TEXT_REGEX.test(imgDesc)) { 
			setErrImgDesc('Image description is required'); valid_Name=false;
		}else{ setErrImgDesc('');  }
        console.log(uploadassetimage.name.split('.').pop());
        if (uploadassetimage=='') {
			setErrUploadAssetImage('Upload asset images is required'); valid_Name=false;
			//return;
		}else if (uploadassetimage.name.split('.').pop() !== 'xlsx') {
            setErrUploadAssetImage('Upload valid format like like excel.'); valid_Name=false;
        }else{ setErrUploadAssetImage('');  }
        if (valid_Name) { alert('validName');
            saveData(); //submit form
		}else{ 
            alert(valid_Name);
            return false;
        }
    }
    const resetAssetInfo = function() { setTag('');
        setUploadAssetImage('');setImgDesc('');
    }
    const getTagInfo = function() { resetAssetInfo();
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data':{'tag':tag},
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData2 = {'token':EncryptData}
        getSTPI(oData2).then(function(res){
            //console.log(res.data.data);
            var aInfo={};
            if(res?.data?.data?.assetInfo) { setErrMsg('');
                aInfo = res.data.data.assetInfo;
                setAssetInfo(aInfo);
                setTag(aInfo.tag);
            }else{
                setAssetInfo({});setErrMsg('Assets Not Found');
            } 
            
        });
    }
    const saveData = function() {
        try { 
            
            const formData = new FormData();
                formData.append('tag',tag);
                formData.append('invoice_no',invoice);
                formData.append('asset_image_desc',imgDesc); 
                formData.append('company_id',sessionStorage.getItem('LoggedInCompanyId'));
                formData.append('division_id',sessionStorage.getItem('LoggedInDivisionId'));
                formData.append('upload_dir','asset_images');
                //for (let i = 0; i < uploadassetimage.length; i++) {
                // formData.append('file', uploadassetimage[i]);
                //}
                formData.append('file', uploadassetimage);
                let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                    'param_data':formData,
                    'authToken': sessionStorage.getItem('LoggedInToken'),
                }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
                let oData3 = {'token':EncryptData}
                saveSingleExcel(oData3).then(function(res){ 
                if((typeof(res.data.errors)!== "undefined") && res.data.errors.errno>0){ setErrMsg(res.data.message);setSuccess(false);
                }else{ 
            setSuccess(res.data.message);setDisabled(true); setErrMsg(false);
            
            setErrTag(''); resetAssetInfo(); }
            });
		} catch (err) { setSuccess(false);
			if (!err?.data) {
                console.log(err);
				setErrMsg('No Server Response');
			} else if (err.data?.status === 409) {
				setErrMsg('Username Taken');
			} else {
				setErrMsg('Insert/Update Failed');
			}
			//elementRef.current.focus();
		}
    }

    return (
        <div className='container'>
            <div className='row single__assets' ref={elementRef}>
                <div className="col">
                    <h5>Upload Asset Images</h5>
                </div>
                <div className="invalid-feedback" role="alert">
                    <i className="fa fa-info-circle"></i>
                    Please Fill all mandetory field.
                </div>
                {success ? (
				<div className="alert alert-success" role="alert"><i className="bi bi-check-circle-fill"></i>{success}</div>
			        ) : ( errMsg ? (<div className="alert alert-danger" role="alert"><i className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>):('')
                ) }
            </div>
            <div className='row '>
            <div className='col-12'>&nbsp;</div>
            {
            (Tag!=='' && Tag!==null) ? <div className='row'><div className='col-10'></div> <div className='col-2'></div></div> : <div className='row'><div className='col-4'><h5 className='align-right'>TAG </h5></div>
            <div className='col-4'>
                {/*<input onChange={function(event){setTag(event.target.value)}} inputValue={assetInfo.tag} type="text" className="form-control"  placeholder='Enter the asset TAG' />*/}
                <Select options={optionList} placeholder="Select One" value={selectedOptions} onChange={handleSelect} />
                <span className="invalid-feedback"> {ErrTag} </span></div>

            <div className='col-4'>
                <button onClick={getTagInfo} className='btn btn-info btn-md'><i className="bi bi-search"></i> Search </button>&nbsp;<a href={'/upload-image' }><button  className='btn btn-info btn-sm'>Reset</button></a>&nbsp;</div>
            <div className='col-2'>
                <label>Search by Supplier</label>
            </div>
            <div className='col-3'>
                <Select options={supplierList} placeholder="Select One" value={supplierOptions} onChange={handleSupplier} />
            </div><div className='col-2'>
                <label>Search by Invoice No.</label>
            </div>
            <div className='col-3'>
                <Select options={invoiceList} placeholder="Select One" value={invoiceOptions} onChange={handleInvoice} /><span className="invalid-feedback"> {ErrInvoice} </span>
            </div>
            <div className='col-3'>
                
            </div>
            </div>
            
            
            
            }

            </div>
            <div className='row'></div>
            <div className="row">
                
                <div className="col-6">
                    <FromFieldText  
                        label="TAG" 
                        inputValue={tag?tag:''}
                        readOnly="readonly"
                        /> 
                </div><div className='col-6'></div>
            </div>
            <form onSubmit={handleSubmit} encType="multipart/form-data"> 
            <div className='row single__asset__form'>
                <div className="col-6">
                <FromFieldText label="Image Description" star="*"
                        inputValue={imgDesc}
                        setInputValue={setImgDesc}
                        //checkInputValue={checkCharcterOnly}
                        setErrorMsg={ErrImgDesc}
                    />
                
                </div>
                <div className='col-6'>
                    
                </div>
            </div>
            <div className="col-6">
            <div className='row single__asset__form'>
                <div className="col-6">
                    <div className="label col-6">
                    <label> Upload Images </label>
                    <span className="star error">  </span>
                    </div>
                    
                </div>
                <div className='col-6'>
                <input type="file" name='asset_image' multiple onChange={handleUploadImage} />
                    <span className="invalid-feedback"> {ErrUploadAssetImage} </span> 
                </div>
            </div></div>
            
            
            <div className='row save__button text-center'>
                <div className='col-md-12'>
                { (Tag!=='' && Tag!==null) ? <div><button className="btn btn-primary btn-md" >Update</button>&nbsp;&nbsp;<a href={'/asset-details?tag='+ Tag } className="btn btn-secondary btn-md">Cancel</a></div>
                 : <button id="btn-submit" className="btn btn-primary btn-md" disabled={disabled}> SAVE </button>
                 }   
                    
                </div>
            </div>
        </form>
        </div>
    )
}
