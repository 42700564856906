import React, { useState, useEffect } from 'react';
import FromFieldText from '../../../atoms/FromFieldText';
import FromFieldDate from '../../../atoms/FromFieldDate';


function ConfirmDisposalConfirm() {

    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h5>Confirm Disposal Confirm</h5>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-6">
                    <FromFieldDate
                        label="Date of Receipt by Buyer"
                        inputValue=''
                        setInputValue=''
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <FromFieldText
                        label="Confirmed by Venfor PIC"
                        placeholder="Confirmed by Venfor PIC"
                        inputValue=''
                        setInputValue=''
                    />
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-12 text-center">
                    <button className="btn btn-primary btn-md me-2" > Edit </button>
                    <button className="btn btn-primary btn-md me-2" > Cancel </button>
                    <button className="btn btn-primary btn-md" > Submit </button>
                </div>
            </div>

            



        </div>
    )
}

export default ConfirmDisposalConfirm;