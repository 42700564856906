import React, { useEffect, useRef, useState } from 'react';
import FromFieldText from '../../atoms/FromFieldText';
import FromFieldDate from "../../atoms/FromFieldDate";
import FromSpFieldTextGatePass from "../../atoms/FromSpFieldTextAreaGatePass";
import { useLocation } from "react-router-dom";
import * as CryptoJS from "crypto-js";
import { getGatePassDetailByGatePassNo } from "../../services/asset.service";
//import './GatePassPrint.css';
import QRCode from "qrcode";

function getFormatedDate(dateString) {
    if (dateString != null) {
        let dte = new Date(dateString);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }
        return year + '-' + month + '-' + day;
    } else {
        return null;
    }
}

function GatePassPrint() {
    const [currentDate, setCurrentDate] = useState('');
    const [info, setInfo] = useState([]);
    const [destinationCity, setDestinationCity] = useState('');
    const [ErrDestinationCity, setErrDestinationCity] = useState('');
    const [destinationCountry, setDestinationCountry] = useState('');
    const [ErrDestinationCountry, setErrDestinationCountry] = useState('');
    const [gatePassNumber, setGatePassNumber] = useState('');
    const [reasonForTakingOut, setReasonForTakingOut] = useState('');
    const [ErrReasonForTakingOut, setErrReasonForTakingOut] = useState('');
    const [material, setMaterials] = useState([{ slNo: 1, tagNo: '', tagName: '', description: '', qty: '', currencyType: '', value: '', invoiceNo: '', remarks: '' }]);
    const [mInfo, setMInfo] = useState([]);

    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const gatePassNo = query.get('gatePassNo');
    const elementRef = useRef();

    useEffect(() => {
        if (gatePassNo !== '' && gatePassNo !== null) {
            const decryptedBytes = CryptoJS.AES.decrypt(gatePassNo.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
            const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
            const decryptedGatePassNo = decrypt_data.gatePassNo;
            getTransferInfo(decryptedGatePassNo);
        }
    }, []);

    const getTransferInfo = function (decryptedGatePassNo) {
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': { 'gatePassNo': decryptedGatePassNo },
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData1 = { 'token': EncryptData };
        getGatePassDetailByGatePassNo(oData1).then(function (res) {
            if (res?.data?.data) {
                var aInfo = res.data.data.gate_pass_data;
                setMInfo(res.data.data.tag_details);

                setGatePassNumber(decryptedGatePassNo);
                setCurrentDate(new Date(aInfo.gatePassDate));
                setDestinationCity(aInfo.destinationCity);
                setDestinationCountry(aInfo.destinationCountry);
                setReasonForTakingOut(aInfo.reasonTakingOut);
                setInfo(aInfo);
            }
        });
    };

    useEffect(() => {
        if (mInfo.length > 0) {
            const mappedMaterials = mInfo.map(material => ({
                tag: material.tag,
                tag_name: material.tag_name,
                asset_description: material.asset_description,
                qty: material.qty,
                invoice_currency: material.invoice_currency,
                invoice_no: material.invoice_no,
                value_of_asset: material.value_of_asset,
                remark: material.remark
            }));

            setMaterials(mappedMaterials);
        }
    }, [mInfo]);

    const handleMaterialChange = (index, event) => {
        const { name, value } = event.target;
        const updatedMaterials = material.map((material, i) => (
            i === index ? { ...material, [name]: value } : material
        ));
        setMaterials(updatedMaterials);
    };

    const printGatePass = () => {
        const printContents = document.getElementById('printGatePass').innerHTML;
        const docprint = window.open();

        docprint.document.write('<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN"');
        docprint.document.write('"http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">');
        docprint.document.write('<html xmlns="http://www.w3.org/1999/xhtml" xml:lang="en">');
        docprint.document.write('<head><title>Print QR Codes</title>');
        docprint.document.write('<style type="text/css">@media screen, print { @page { margin: 0mm; } html, body { margin: 0px;padding: 0px; } #qrcode-container { width: 100%;}.qrcodes-div { width: 100%; height: 100%;margin: 1mm auto;page-break-after: always;} .col:first-child { display: inline-block; width: 80px; padding-top: 0px; padding-left: 1px; }.col:last-child { display: inline-block; width: 260px; padding-top: 0px; float: right; } }</style>');
        docprint.document.write('</head><body onload="self.print()"><center>');
        docprint.document.write(printContents);
        docprint.document.write('</center></body></html>');
        docprint.document.close();
        docprint.focus();
        docprint.print();
    };

    return (
        <div className="container">

            <div id="printGatePass">

                <div className="row mb-4 mt-2">
                    <div className="col-12 text-center">
                        <img width="250px;" src={"Microchip_logo.png"} alt="Logo"/>
                    </div>
                </div>

                <div className="row text-center">
                    <div className="col-12">
                        <h5>Employee / Vendor - Returnable / Non Returnable - Gate Pass</h5>
                    </div>
                </div>

                <div className="row">
                    <div className="col-6">
                        <div className="row">
                            <div className="col-12 mt-4">
                                <FromFieldText
                                    readOnly={true}
                                    star="*"
                                    label="Gate Pass No"
                                    inputValue={gatePassNumber}
                                    setInputValue={setGatePassNumber}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 mt-4">
                                <FromFieldDate
                                    readOnly={true}
                                    label="Date" star="*" placeholder="YYYY-MM-DD"
                                    inputValue={currentDate}
                                    setInputValue={setCurrentDate}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-6">
                        <div className="row">
                            <div className="col-12 text-right">
                                <img width="200px;" height="200px;" src={"qr_code_sample.png"} alt="QR Code"/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col-6">
                        <FromFieldText placeholder="Enter Destination City"
                                       star="*"
                                       readOnly
                                       label="Destination City"
                                       inputValue={destinationCity}
                                       setInputValue={setDestinationCity}
                                       setErrorMsg={setErrDestinationCity}
                        />
                    </div>

                    <div className='col-md-6'>
                        <FromFieldText
                            star="*"
                            readOnly
                            label="Destination Country" star="*" placeholder="Enter Destination Country"
                            inputValue={destinationCountry}
                            setInputValue={setDestinationCountry}
                            setErrorMsg={setErrDestinationCountry}
                        />
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col-12">
                        Security Office may please allow Smt /
                        Sri <b>{info.titles}</b> Designation <b>{info.designation} </b> Of
                        <b> {info.vendorName}</b> to take out the following materials
                        from <b>{info.division_name}</b>.<br/>
                        The probable Date of return of the material is <b>{getFormatedDate(info.expectedDate)}</b>.
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col-md-3">
                        <label>Reason for Taking Out<span className="error">*</span></label>
                    </div>
                    <div className='col-md-9'>
                        <FromSpFieldTextGatePass maxLength="5000" numRows="10"
                                                 readOnly
                                                 placeholder="Enter Reason for Taking Out"
                                                 inputValue={reasonForTakingOut}
                                                 setInputValue={setReasonForTakingOut}
                                                 setErrorMsg={setErrReasonForTakingOut}
                        />
                    </div>
                </div>

                <div className="row mt-4">
                    <div style={{maxHeight: '200px', overflowY: 'auto', marginBottom: '15px'}}>
                        <table border="1" width="100%"
                               style={{borderCollapse: 'separate', borderSpacing: '10px', textAlign: 'center'}}>
                            <thead>
                            <tr>
                                <th>Sl No</th>
                                <th>TAG No</th>
                                <th>TAG Name</th>
                                <th>Descriptions</th>
                                <th>Qty</th>
                                <th>Currency Type</th>
                                <th>Value</th>
                                <th>Invoice No</th>
                                <th>Remarks</th>
                            </tr>
                            </thead>
                            <tbody>
                            {material.map((material, index) => (
                                <tr key={index}>
                                    <td>{material.slNo}</td>
                                    <td><input readOnly type="text" name="tag" value={material.tag}
                                               onChange={(e) => handleMaterialChange(index, e)}/></td>
                                    <td><input readOnly type="text" name="tag_name" value={material.tag_name}
                                               onChange={(e) => handleMaterialChange(index, e)}/></td>
                                    <td><input readOnly type="text" name="asset_description"
                                               value={material.asset_description}
                                               onChange={(e) => handleMaterialChange(index, e)}/></td>
                                    <td><input readOnly type="number" name="qty" value={material.qty}
                                               onChange={(e) => handleMaterialChange(index, e)}/></td>
                                    <td><input readOnly type="text" name="invoice_currency"
                                               value={material.invoice_currency}
                                               onChange={(e) => handleMaterialChange(index, e)}/></td>
                                    <td><input readOnly type="number" name="value_of_asset"
                                               value={material.value_of_asset}
                                               onChange={(e) => handleMaterialChange(index, e)}/></td>
                                    <td><input readOnly type="text" name="invoice_no" value={material.invoice_no}
                                               onChange={(e) => handleMaterialChange(index, e)}/></td>
                                    <td><input readOnly type="text" name="remark" value={material.remark}
                                               onChange={(e) => handleMaterialChange(index, e)}/></td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>

            <div className="row mt-4 mb-2">
                <div className="col-md-12 d-flex justify-content-center">
                    <button onClick={printGatePass} className='btn btn-secondary btn-sm'>
                        <i className="bi bi-printer"></i> Print
                    </button>
                </div>
            </div>

        </div>
    );
}

export default GatePassPrint;
