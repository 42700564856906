import React, {useState} from 'react';
import FromFieldText from '../../../atoms/FromFieldText';
import FromFieldDate from '../../../atoms/FromFieldDate';
import "react-datepicker/dist/react-datepicker.css";
import * as CryptoJS from "crypto-js";
import {getDisposalAssetDetails, getBuyerQuotedPrice, saveFinalisedDeal} from "../../../services/disposal.service";


function getFormatedDate(dateString) {
    if (dateString != null) {
        let dte = new Date(dateString);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }
        return year + '-' + month + '-' + day;
        //return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
    } else {
        return null;
    }
}

function SingleFinalizeDeal() {

    const [items, setItems] = useState([]);

    const [activeTab, setActiveTab] = useState(0);

    const [formSubmitted, setFormSubmitted] = useState(false);

    const [success, setSuccess] = useState(false);

    const [errMsg, setErrMsg] = useState('');

    const [dinno, setDinno] = useState('');
    const [dinnoError, setdinNoError] = useState('');

    const [finalSalePrice, setFinalSalePrice] = useState('');
    const [finalSalePriceError, setFinalSalePriceError] = useState('');

    const [quotedReceivedDate, setQuotedReceivedDate] = useState('');
    const [quotedReceivedDateError, setQuotedReceivedDateError] = useState('');

    const [finalApprovalDate, setFinalApprovalDate] = useState('');
    const [finalApprovalDateError, setFinalApprovalDateError] = useState('');

    const [finalApprovalBy, setFinalApprovalBy] = useState('');
    const [finalApprovalByError, setFinalApprovalByError] = useState('');

    const [selectedBuyer, setSelectedBuyer] = useState('');


    const [assetInfo, setAssetInfo] = useState({});

    const [buyer, setBuyer] = useState('');
    const [setbuyer, buyerError] = useState('');

    const [buyerNameOptions, setBuyerNameOptions] = useState([]);

    const updatedDinNo = assetInfo.din_no ? `${assetInfo.din_no}-F` : '';


    const handleBuyerDetails = (e) => {
        let bname = e.target.name;
        let value = e.target.value;
        //alert(value);


        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': {'din_no': dinno, 'buyer_name': value},
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token': EncryptData}

        getBuyerQuotedPrice(oData).then(function (res) {
            var aInfo1 = {};
            if (res?.data?.data?.res) {
                setErrMsg('');
                aInfo1 = res.data.data.res[0]['buyer_price_quoted'];
                setBuyer(aInfo1);
            } else {
                setAssetInfo({});
                setErrMsg('Quoted Price Not found');
            }
        })
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        var valid_Name = true;

        // Validate the inputs before submitting the form

        if (!(dinno) && dinno !== 0) {
            setdinNoError('DIN no is required');
            valid_Name = false;
        } else {
            setdinNoError('');
        }


        if (valid_Name) {

            handleNextClick();
        } else {
            return false;
        }
        // Perform form submission logic here
    };

    const handleNextClick = () => {
        // Handle the "Next" button click to move to the next tab
        setActiveTab(activeTab + 1);
    };

    const resetAssetDisposalInfo = function () {
        setDinno('');
    }

    const getTagInfo = function () {

        if (!(dinno) && dinno !== 0) {
            setdinNoError('DIN no is required');
        } else {
            setdinNoError('');


            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': {'din_no': dinno},
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData = {'token': EncryptData}

            getDisposalAssetDetails(oData).then(function (res) {
                var aInfo = {};
                //getTransferData(tag).then(function(res){
                if (res?.data?.data?.res) {
                    setErrMsg('');
                    aInfo = res.data.data.res;
                    setAssetInfo(aInfo[0]);
                    //setTag(aInfo.tag);
                    setBuyerNameOptions(aInfo);
                    //console.log(buyerNameOptions);
                } else {
                    setAssetInfo({});
                    setErrMsg('Din no not found');
                }

            })
        }
    }

    const handleFormSubmitFinal = (e) => {
        e.preventDefault();
        var valid_Name1 = true;

        if (!(finalSalePrice) && finalSalePrice !== 0) {
            setFinalSalePriceError('Final sale price is required');
            valid_Name1 = false;
        } else {
            setFinalSalePriceError('');
        }
        if (!(buyer) && buyer !== 0) {
            buyerError('Final sale price is required');
            valid_Name1 = false;
        } else {
            buyerError('');
        }
        if (!(quotedReceivedDate) && quotedReceivedDate !== 0) {
            setQuotedReceivedDateError('Quoted Received Date is required');
            valid_Name1 = false;
        } else {
            setQuotedReceivedDateError('');
        }
        if (!(finalApprovalDate) && finalApprovalDate !== 0) {
            setFinalApprovalDateError('Quoted Approval Date is required');
            valid_Name1 = false;
        } else {
            setFinalApprovalDateError('');
        }
        if (!(finalApprovalBy) && finalApprovalBy !== 0) {
            setFinalApprovalByError('Final approval by is required');
            valid_Name1 = false;
        } else {
            setFinalApprovalByError('');
        }
        if (!selectedBuyer) {
            buyerError('Buyer is required');
            valid_Name1 = false;
        } else {
            buyerError('');
        }

        if (valid_Name1) {
            //alert('test1');
            saveFinalDeal(); //submit form
        } else {
            return false;
        }
        // Perform form submission logic here
    };

    const saveFinalDeal = function () {
        try {

            var obj = {
                'tag': assetInfo.tag,
                'din_no': assetInfo.din_no,

                'buyer_name': selectedBuyer,
                'buyer_price_quoted': buyer,
                'buyer_final_price': finalSalePrice,
                'buyer_quote_received_date': (quotedReceivedDate ? getFormatedDate(quotedReceivedDate) : ''),
                'final_approval_date': (finalApprovalDate ? getFormatedDate(finalApprovalDate) : ''),
                'final_approval_by': finalApprovalBy,
                'gen_ref_no': updatedDinNo,

            }

            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': obj, 'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData = {'token': EncryptData}

            saveFinalisedDeal(oData).then(function (res) {
                //console.log(res);
                if ((typeof (res.data.errors) != "undefined") && res.data.errors.errno > 0) {
                    setErrMsg(res.data.message);
                    setSuccess(false);
                } else {
                    setSuccess(res.data.message);
                    resetAssetDisposalInfo();
                    setFormSubmitted(true);
                    setTimeout(function () {
                        // Redirect to secondpage.js after 10 seconds
                        window.location.href = 'singlefinalizedeal';
                    }, 500); // 10000 milliseconds = 10 seconds
                }
            });

            //setValidName(false);
        } catch (err) {
            setSuccess(false);
            if (!err?.data) {
                console.log(err);
                setErrMsg('No Server Response');
            } else if (err.data?.status === 409) {
                setErrMsg('data already exist');
            } else {
                setErrMsg('Insert/Update Failed');
            }
            //elementRef.current.focus();
        }
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h5>Single Finalize Deal</h5>
                </div>
            </div>


            {success ? (<div className="alert alert-success" role="alert"><i
                className="bi bi-check-circle-fill"></i> {success}</div>) : (errMsg ? (
                <div className="alert alert-danger" role="alert"><i
                    className="bi bi-exclamation-triangle-fill"></i> {errMsg}</div>) : (''))}


            <div className="tab-content">

                <div className={`tab-pane ${activeTab === 0 ? 'active' : ''}`}>

                    <div className='row '>
                        <div className='col-12'>&nbsp;</div>
                        <div className='row'>
                            <div className='col-10'></div>
                            <div className='col-2'></div>
                        </div>
                        : <div className='row'>
                        <div className='col'><h5 className='align-right'>DIN No. </h5></div>

                        <div className='col'>
                            <input onChange={function (event) {
                                setDinno(event.target.value)
                            }} inputValue={assetInfo.tag} type="text"
                                   setInputValue={setDinno}
                                   setErrorMsg={setdinNoError}
                                   className="form-control"
                                   placeholder='Enter DIN No.'/>
                            <p className='invalid-feedback'>{dinnoError}</p></div>
                        <div className='col'>
                            <button onClick={getTagInfo} className='btn btn-primary btn-md'><i
                                className="bi bi-search"></i> Search
                            </button>
                        </div>
                    </div>

                    </div>

                    <div className="row mt-4">
                        <div className="col-6">
                            <FromFieldText
                                label="DIN No."
                                placeholder="DIN No."
                                inputValue={assetInfo?.din_no}// Use optional chaining here
                                readOnly="readonly"
                            />
                        </div>
                        <div className="col-6">
                            <FromFieldText
                                readOnly="readonly"
                                label="Invoice Date"
                                inputValue={assetInfo?.created_date ? getFormatedDate(assetInfo.created_date) : ''} // Use optional chaining here
                            />
                        </div>
                    </div>


                    <div className="row mt-4">
                        <div className="col-4"></div>
                        <form name="" onSubmit={handleFormSubmit}>
                            <button className="btn btn-primary btn-md"> Next</button>
                        </form>
                        <div className="col-4"></div>
                    </div>

                </div>


                <div className={`tab-pane ${activeTab === 1 ? 'active' : 'hidden'}`}>

                    <div className="row mt-4">
                        <div className="col-6">
                            <FromFieldText
                                label="TAG"
                                placeholder="TAG"
                                inputValue={assetInfo.tag ? assetInfo.tag : ''}
                                readOnly="readonly"
                            />
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-4">

                            <label htmlFor="selectBuyer">Select Buyer Name</label>
                            <select
                                id="selectBuyer"
                                className="form-select"
                                onChange={(e) => {
                                    setSelectedBuyer(e.target.value);
                                    handleBuyerDetails(e);
                                }}
                                required
                                value={selectedBuyer}
                            >
                                <option value="">----- Select Buyer -----</option>
                                {buyerNameOptions && buyerNameOptions.length > 0 ? (
                                    buyerNameOptions.map((option) => (
                                        <option key={option.buyer_name} value={option.buyer_name}>
                                            {option.buyer_name}
                                        </option>
                                    ))
                                ) : (
                                    <option value="">No Data</option>
                                )}
                            </select>


                        </div>
                    </div>


                    <div className="row mt-4">
                        <div className="col-4">
                            <FromFieldText
                                label="Price Quoted"
                                inputValue={buyer}
                                setInputValue={setbuyer}
                                setErrorMsg={buyerError}
                                readOnly
                            />
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-4">
                            <FromFieldText
                                label="Final Sale Price W/o GST"
                                inputValue={finalSalePrice}
                                setInputValue={setFinalSalePrice}
                                setErrorMsg={finalSalePriceError}
                            />
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-4">
                            <FromFieldDate
                                label="Final Sale Date"
                                placeholder="Final Sale Date"
                                inputValue={quotedReceivedDate}
                                setInputValue={setQuotedReceivedDate}
                                setErrorMsg={quotedReceivedDateError}
                            />
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-4">
                            <FromFieldDate
                                label="Final Approval Date"
                                placeholder="Final Approval Date"
                                inputValue={finalApprovalDate}
                                setInputValue={setFinalApprovalDate}
                                setErrorMsg={finalApprovalDateError}
                            />
                        </div>
                        <div className="col-4">
                            <FromFieldText
                                label="Final Approval By"
                                placeholder="Final Approval By"
                                inputValue={finalApprovalBy}
                                setInputValue={setFinalApprovalBy}
                                setErrorMsg={finalApprovalByError}
                            />
                        </div>
                        <div className="col-4">
                            <FromFieldText
                                label="Gen Ref No"
                                inputValue={updatedDinNo}
                                readOnly="readonly"
                            />
                        </div>
                    </div>

                    <div className="row mt-6">
                        <div className='text-center'>

                            <form name="" onSubmit={resetAssetDisposalInfo}>
                                <button className="btn btn-primary btn-md float-end me-2"> Cancel</button>
                            </form>

                            {!formSubmitted && (
                                <form name="" onSubmit={handleFormSubmitFinal}>
                                    <button className="btn btn-primary btn-md float-end">Submit</button>
                                </form>
                            )}

                        </div>
                    </div>


                </div>


            </div>


        </div>
    )
}

export default SingleFinalizeDeal;