import React, { useState, useEffect } from 'react';
import FromFieldText from '../../../atoms/FromFieldText';
import FromFieldDate from '../../../atoms/FromFieldDate';


function ConfirmDisposalAdd() {

    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h5>Confirm Disposal Add</h5>
                </div>
            </div>

            <div className='row mt-4'>
                <div className='table-responsive'>
                    <table className='table-bordered w-100 text-center'>
                        <thead>
                            <tr>
                                <td style={{fontWeight:700,color:'#fff',background:'#000531',padding:'5px'}}>Sl No</td>
                                <td style={{fontWeight:700,color:'#fff',background:'#000531',padding:'5px'}}>DIN No</td>
                                <td style={{fontWeight:700,color:'#fff',background:'#000531',padding:'5px'}}>TAG No</td>
                                
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-6">
                    <FromFieldText
                        label="Mode of Transport"
                        placeholder="Mode of Transport"
                        inputValue=''
                        setInputValue=''
                    />
                </div>
                <div className="col-6">
                    <FromFieldText
                        label="Transport Ref No"
                        placeholder="Transport Ref No"
                        inputValue=''
                        setInputValue=''
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-6">
                    <FromFieldDate
                        label="Date of Transport"
                        inputValue=''
                        setInputValue=''
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <label style={{ marginRight: '10px' }}> Upload Transport Doc </label>
                    <input type="file" name="asset_invoice" />
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-12 text-center">
                    <button className="btn btn-primary me-2" > Edit </button>
                    <button className="btn btn-primary me-2" > Cancel </button>
                    <button className="btn btn-primary" > Submit </button>
                </div>
            </div>


        </div>
    )
}

export default ConfirmDisposalAdd;