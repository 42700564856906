import React, {useEffect, useState} from 'react'
import {useLocation} from "react-router-dom";
import FromFieldText from '../../atoms/FromFieldText';
import {getCompanyProfileList, getRFIDInfo, saveRFIDReader} from '../../services/user.service';
import * as CryptoJS from "crypto-js";

const TEXT_REGEX = /^[a-zA-Z0-9-_ ]{1,255}$/;

function AddRFIDReader() {
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    const [companyId, setCompanyId] = useState('');
    const [ErrCompanyId, setErrCompanyId] = useState('');

    const [rfidreadername, setRfidReaderName] = useState('');
    const [ErrRfidReaderName, setErrRfidReaderName] = useState('');

    const [rfidreaderlocation, setRfidReaderLocation] = useState('');
    const [ErrRfidReaderLocation, setErrRfidReaderLocation] = useState('');

    const [rfidreadermake, setRfidReaderMake] = useState('');
    const [ErrRfidReaderMake, setErrRfidReaderMake] = useState('');

    const [rfidreadermodelno, setRfidReaderModelNo] = useState('');
    const [ErrRfidReaderModelNo, setErrRfidReaderModelno] = useState('');

    const [rfidreadersrno, setRfidReaderSrno] = useState('');
    const [ErrRfidReaderSrno, setErrRfidReaderSrno] = useState('');

    const [rfidreaderip, setRfidReaderIp] = useState('');
    const [ErrRfidReaderIp, setErrRfidReaderIp] = useState('');

    const [companies, setCompanies] = useState([]);

    const resetUserInfo = () => {
        setRfidReaderName('');
        setRfidReaderLocation('');
        setRfidReaderMake('');
        setRfidReaderModelNo('');
        setRfidReaderSrno('');
        setRfidReaderIp('');
        setCompanyId('');
    };

    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const rfid_ID = query.get('rfid_ID');

    useEffect(() => {
        const fetchData = async () => {
            if (rfid_ID !== '' && rfid_ID != null) {
                try {
                    const decryptedBytes = CryptoJS.AES.decrypt(rfid_ID.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
                    const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
                    const rfidId = decrypt_data.rfid_id;
                    await getRFIDData(rfidId);
                } catch (error) {
                    console.error("Error decrypting rfid_ID: ", error);
                }
            }

            try {
                const EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                    'authToken': sessionStorage.getItem('LoggedInToken'),
                }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
                const oData = {'token': EncryptData};

                const res = await getCompanyProfileList(oData);
                if (res?.data?.data?.length) setCompanies(res.data.data);
            } catch (error) {
                console.error("Error fetching company profile list: ", error);
            }
        };

        fetchData();
    }, [rfid_ID]);

    const getRFIDData = async (rfidId) => {
        try {
            const EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': {'rfid_data_id': rfidId},
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            const oData1 = {'token': EncryptData};

            const res = await getRFIDInfo(oData1);
            if (res?.data?.data) {
                const rfidInfo = res.data.data[0];
                setCompanyId(rfidInfo.company_id);
                setRfidReaderName(rfidInfo.rfid_reader_name);
                setRfidReaderLocation(rfidInfo.rfid_reader_location);
                setRfidReaderMake(rfidInfo.rfid_reader_make);
                setRfidReaderModelNo(rfidInfo.rfid_reader_model_no);
                setRfidReaderSrno(rfidInfo.rfid_reader_sr_no);
                setRfidReaderIp(rfidInfo.rfid_reader_ip);
            }
        } catch (error) {
            console.error("Error fetching RFID data: ", error);
        }
    };

    const handleCompanyId = (e) => {
        setCompanyId(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let valid = true;

        if (!companyId) {
            setErrCompanyId('Company name is required');
            valid = false;
        } else {
            setErrCompanyId('');
        }
        if (!rfidreadername) {
            setErrRfidReaderName('RFID reader name is required');
            valid = false;
        } else {
            setErrRfidReaderName('');
        }
        if (!rfidreaderlocation) {
            setErrRfidReaderLocation('RFID location is required');
            valid = false;
        } else {
            setErrRfidReaderLocation('');
        }
        if (!rfidreadermake) {
            setErrRfidReaderMake('RFID make is required');
            valid = false;
        } else {
            setErrRfidReaderMake('');
        }
        if (!rfidreadermodelno) {
            setErrRfidReaderModelno('RFID model is required');
            valid = false;
        } else {
            setErrRfidReaderModelno('');
        }
        if (!rfidreadersrno) {
            setErrRfidReaderSrno('RFID serial no is required');
            valid = false;
        } else {
            setErrRfidReaderSrno('');
        }
        if (!rfidreaderip) {
            setErrRfidReaderIp('RFID IP is required');
            valid = false;
        } else {
            setErrRfidReaderIp('');
        }

        if (valid) {
            saveData();
        }
    };

    const saveData = () => {
        try {

            if (rfid_ID !== '' && rfid_ID !== null) {
                const decryptedBytes = CryptoJS.AES.decrypt(rfid_ID.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
                // Convert the decrypted bytes to a string
                const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
                const decryptedRFIDId = decrypt_data.rfid_id;
                var obj = {
                    'rfidID': decryptedRFIDId,
                    'rfid_reader_name': rfidreadername,
                    'rfid_reader_location': rfidreaderlocation,
                    'rfid_reader_make': rfidreadermake,
                    'rfid_reader_model_no': rfidreadermodelno,
                    'rfid_reader_sr_no': rfidreadersrno,
                    'rfid_reader_ip': rfidreaderip,
                    'company_id': companyId,
                    'status': '1',
                    'created_by': sessionStorage.getItem('LoggedInUserId'),
                }
            } else {
                var obj = {
                    'rfid_reader_name': rfidreadername,
                    'rfid_reader_location': rfidreaderlocation,
                    'rfid_reader_make': rfidreadermake,
                    'rfid_reader_model_no': rfidreadermodelno,
                    'rfid_reader_sr_no': rfidreadersrno,
                    'rfid_reader_ip': rfidreaderip,
                    'company_id': companyId,
                    'status': '1',
                    'created_by': sessionStorage.getItem('LoggedInUserId'),
                }
            }

            if (rfid_ID !== '' && rfid_ID !== null) {
                const decryptedBytes = CryptoJS.AES.decrypt(rfid_ID.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
                const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
                obj.rfid_ids = decrypt_data.rfid_ID;
            }

            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': obj,
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData = {'token': EncryptData};

            saveRFIDReader(oData).then(res => {
                if (res.data.errors && res.data.errors.errno > 0) {
                    setErrMsg(res.data.message);
                    setSuccess(false);
                } else {
                    setSuccess(res.data.message);
                    resetUserInfo();
                }
            });
        } catch (err) {
            setSuccess(false);
            if (!err?.data) {
                setErrMsg('No Server Response');
            } else if (err.data?.status === 409) {
                setErrMsg('Data already exists');
            } else {
                setErrMsg('Insert/Update Failed');
            }
        }
    };

    return (
        <div className="container">
            <div className="row">
                <div className="col-6">
                    <h5>Add RFID Reader</h5>
                </div>
                <div className="invalid-feedback" role="alert">
                    <i className="fa fa-info-circle"></i>
                    Please Fill all mandatory fields.
                </div>

                {success ? (
                    <div className="alert alert-success" role="alert">
                        <i className="bi bi-check-circle-fill"></i>{success}
                    </div>
                ) : (
                    errMsg && (
                        <div className="alert alert-danger" role="alert">
                            <i className="bi bi-exclamation-triangle-fill"></i>{errMsg}
                        </div>
                    )
                )}
            </div>

            <div className='row'>
                <div className='col-12'>&nbsp;</div>
            </div>

            <form onSubmit={handleSubmit}>
                <div className="row form-fields">
                    <div className="label col-3">
                        <label>Company </label><span className="star error"> *</span>
                    </div>
                    <div className="item col-3">
                        <select className="form-select form-select-sm mb-3" onChange={handleCompanyId}
                                value={companyId}>
                            <option value="">Select Company</option>
                            {companies && companies.length > 0 ? (
                                companies.map((option) => (
                                    <option key={option.company_id} value={option.company_id}>
                                        {option.company_name}
                                    </option>
                                ))
                            ) : (
                                <option value="">No Data</option>
                            )}
                        </select>
                        <span className="invalid-feedback"> {ErrCompanyId} </span>
                    </div>
                </div>

                <div className="row">
                    <div className="col-6">
                        <FromFieldText
                            star="*"
                            label="Reader Name"
                            inputValue={rfidreadername}
                            setInputValue={setRfidReaderName}
                            setErrorMsg={setErrRfidReaderName}
                            errorMsg={ErrRfidReaderName}
                        />
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-6">
                        <FromFieldText
                            star="*"
                            label="Reader Location"
                            inputValue={rfidreaderlocation}
                            setInputValue={setRfidReaderLocation}
                            setErrorMsg={setErrRfidReaderLocation}
                            errorMsg={ErrRfidReaderLocation}
                        />
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-6">
                        <FromFieldText
                            star="*"
                            label="Reader Make"
                            inputValue={rfidreadermake}
                            setInputValue={setRfidReaderMake}
                            setErrorMsg={setErrRfidReaderMake}
                            errorMsg={ErrRfidReaderMake}
                        />
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-6">
                        <FromFieldText
                            star="*"
                            label="Model Number"
                            inputValue={rfidreadermodelno}
                            setInputValue={setRfidReaderModelNo}
                            setErrorMsg={setErrRfidReaderModelno}
                            errorMsg={ErrRfidReaderModelNo}
                        />
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-6">
                        <FromFieldText
                            star="*"
                            label="Serial Number"
                            inputValue={rfidreadersrno}
                            setInputValue={setRfidReaderSrno}
                            setErrorMsg={setErrRfidReaderSrno}
                            errorMsg={ErrRfidReaderSrno}
                        />
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-6">
                        <FromFieldText
                            star="*"
                            label="IP Address"
                            inputValue={rfidreaderip}
                            setInputValue={setRfidReaderIp}
                            setErrorMsg={setErrRfidReaderIp}
                            errorMsg={ErrRfidReaderIp}
                        />
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-3"></div>
                    <div className="col-6">
                        <button className="btn btn-primary btn-sm"> SAVE</button>
                    </div>
                    <div className="col-3"></div>
                </div>
            </form>
        </div>
    );
}

export default AddRFIDReader;


