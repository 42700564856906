import React,{useState,useEffect} from 'react'
import { getAllDepreciation } from '../../../services/asset.service';
import Box from '@mui/material/Box';
import { DataGrid,GridToolbarContainer, GridToolbarExport  } from '@mui/x-data-grid';
import  * as CryptoJS  from "crypto-js";
//var months = ['JAN','FEB','MAR','APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC'];
var months = {'1':'JAN','2':'FEB','3':'MAR','4':'APR','5':'MAY','6':'JUN','7':'JUL',
'8':'AUG','9':'SEP','10':'OCT','11':'NOV','12':'DEC'};
const columns = [
  {"field":"id","hide":true,"headerName":"Sl No"},
  {"field":"tag","hide":false ,"headerName":"Tag",width:250},
  {"field":"parent_tag","hide":false ,"headerName":"Parent Tag",width:250},
  {"field":"days","hide":false ,"headerName":"No. of Days",width:100},
  {"field":"year","hide":false ,"headerName":"Year",width:100},
  {"field":"month","hide":false ,"headerName":"Month", valueGetter : (parms) => months[parms.row.month],width:100},
  {"field":"asset_ob","hide":false ,"headerName":"Asset OB",width:150},
  {"field":"asset_added_value","hide":false ,"headerName":"Asset Added Value", width:150},
  {"field":"asset_transfered","hide":false ,"headerName":"Transfered Value", width:150},
  {"field":"asset_deleted_value","hide":false ,"headerName":"Asset Deleted Value",width:150},
  {"field":"asset_cb","hide":false ,"headerName":"Asset CB",width:150},
  {"field":"depreciation_ob","hide":false ,"headerName":"Depreciation OB",width:150},
  {"field":"depreciation_added_value","hide":false ,"headerName":"Depreciation Added Value",width:250},
  {"field":"depreciation_transfered","hide":false ,"headerName":"Transfered Value",width:250},
  {"field":"depreciation_deleted_value","hide":false ,"headerName":"Depreciation Deleted Value",width:250},
  {"field":"depreciation_adjusted_value","editable":true,"hide":false ,"headerName":"Depreciation Adjusted Value",width:250},
  
  {"field":"depreciation_cb","hide":false ,"headerName":"Depreciation CB",width:150},
  {"field":"wdv_ob","hide":false ,"headerName":"WDV OB",width:150},
  {"field":"wdv_cb","hide":false ,"headerName":"WDV CB",width:150}
];


function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

function DepreciationInfo() {
    const [loading, setLoading] = useState(true);
  const [assetsDepriciation,setAssetsDepriciation] = useState([]);

    useEffect(() => {
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

        let oData = { 'token': EncryptData };

        getAllDepreciation(oData)
            .then((result) => {
                const data = result.data.data;
                if (data?.assetDeprisiation && data.assetDeprisiation.length > 0) {
                    setAssetsDepriciation(data.assetDeprisiation);
                }
            })
            .finally(() => {
                setLoading(false); // Set loading to false, whether data fetching is successful or not
            });
    }, []);

  return (
        <div className='container'>
          <div className="row">
              <div className="col">
                  <h5>Depreciation Of Assets</h5>
              </div>
          </div>
          <div className='row'>
            <div className='col'>
                {loading ? (
                    <div className="text-center">
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ) : (
                    <DataGrid
                        rows={assetsDepriciation}
                        getRowId={(row) => row.id}
                        columns={columns}
                        //pageSize={50}
                        autoHeight
                        components={{
                            Toolbar: CustomToolbar,
                        }}
                    />
                )}
            </div>
          </div>
        </div>
  )
}

export default DepreciationInfo