import React, { useState,useEffect,useCallback } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { DataGrid } from '@mui/x-data-grid';
// import axios from "axios";
// import { read } from "xlsx";
import { getAllSTPI } from '../../services/asset.service'

//import './AssetInfo.css';

const columns = [
    {"field":"id","hideable":true,"hide":true,"editable":false,"headerName":"Sl No","width":50},
    {"field":"tag","editable":false,"hide":false ,"headerName":"TAG","width":80,
    renderCell: (params) => (
      <a href={`/Stpi-view?tag=${params.value}`}>{params.value}</a>
    ) },
    {"field":"bond_number","editable":true,"hide":false ,"headerName":"Bond Number","width":130},
    {"field":"date_of_bond","editable":true,"hide":false ,"headerName":"Date of Bond","width":130},
    {"field":"asset_type","editable":true,"hide":false ,"headerName":"Asset Type","width":130},
    {"field":"transport_clause","editable":true,"hide":false ,"headerName":"Transport Clause","width":130},
    {"field":"import_type","editable":true,"hide":false ,"headerName":"Import Ttype","width":130},
    {"field":"approval_value_cif","editable":true,"hide":false ,"headerName":"Approval Value cif","width":130},
    {"field":"stpi_approval_number","editable":true,"hide":false ,"headerName":"Stpi Approval Number","width":130},
    {"field":"stpi_approval_date","editable":true,"hide":false ,"headerName":"Stpi Approval Date","width":130},
    {"field":"assessed_value","editable":true,"hide":false ,"headerName":"Assessed Value","width":130},
    {"field":"custom_duty_forgone","editable":true,"hide":false ,"headerName":"Custom Duty Forgone","width":130},
    {"field":"total_duty_forgone","editable":true,"hide":false ,"headerName":"Total Duty Forgone","width":130},
    {"field":"igst_forgone","editable":true,"hide":false ,"headerName":"igst Forgone","width":130},
    {"field":"debond_date","editable":true,"hide":false ,"headerName":"Debond Date","width":130},
    {"field":"debond_ref_number","editable":true,"hide":false ,"headerName":"Debond Ref Number","width":130},
   
];

function StpiList() {
  const [items, setItems] = useState([]);
  var onCellEditStopFn = (event,newCell) => {
    var obj = {};
    obj[newCell.field] = event.target.value;
  }
  
  useEffect(()=>{
    getAllSTPI().then(res=>{
      if(res?.data?.data?.length) setItems(res.data.data);
    })
  },[])
  
  // readAssets();
  return (
    <div className='container'>
      <h3>
        <h2> STPI Form List</h2>
      </h3>
      <DataGrid
        rows={items}
        getRowId={(row) => row.id}
        columns={columns}
        pageSize={5}
        autoHeight 
        rowsPerPageOptions={[5]}
        onCellEditStop={onCellEditStopFn}
      />
    </div>
  )
}

export default StpiList
