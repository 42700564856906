import React, {useEffect, useState} from 'react';
import {DataGrid, GridOverlay, GridToolbarContainer, GridToolbarExport} from '@mui/x-data-grid';

import {getAllNotificationList} from '../services/notification.service';

import * as CryptoJS from "crypto-js";
import Box from "@mui/material/Box";
import {FormControl, InputLabel, MenuItem, Pagination, Select} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

function getFormattedDate(dateString) {
    if(dateString!=null) {
        let dte = new Date(dateString);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }
        return year + '-' + month + '-' + day;
    } else {
        return null;
    }
}

const columns = [
    {"field": "module", "editable": false, "hide": false, "headerName": "Module", "width": 220},
    {"field": "message", "editable": false, "hide": false, "headerName": "Message", "width": 500},
    {"field": "createdByName", "editable": false, "hide": false, "headerName": "Username", "width": 250,},
    {"field":"updated_at","editable":false,"hide":false ,"headerName":"Date & Time","width":250,
        renderCell: (params) => (
            <p>{ getFormattedDate(params.row.updated_at)}</p>
        ) },
];

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport/>
        </GridToolbarContainer>
    );
}

const CustomLoader = () => (
    <GridOverlay>
        <CircularProgress color="primary" size={30}/>
    </GridOverlay>
);

function NotificationsList() {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);

    const [page, setPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [pageSize, setPageSize] = useState(50);

    const calculateOffset = (page, pageSize) => (page - 1) * pageSize;

    useEffect(() => {
        setLoading(true);

        const offset = calculateOffset(page, pageSize);
        const EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            param_data: { 'offset': offset, 'pageSize': pageSize },
            authToken: sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

        let oData = {'token': EncryptData};

        getAllNotificationList(oData).then(res => {
            if (res?.data?.data?.length) {
                setItems(res.data.data);
                setTotalItems(res.data.total || 0);
            }
            setLoading(false); // Set loading to false when the request completes
        });

    }, [page, pageSize]); // Depend on page and pageSize so it re-runs when they change

    const handlePageSizeChange = (event) => {
        setPageSize(Number(event.target.value));
        setPage(1);
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const totalPages = Math.ceil(totalItems / pageSize);

    return (
        <div className='container'>
            <div className='row'>
                <div className='col-md-6'><h5>Notification List</h5></div>
            </div>

            <Box>
                <DataGrid
                    rows={items}
                    getRowId={(row) => row.id}
                    columns={columns}
                    autoHeight
                    components={{
                        LoadingOverlay: CustomLoader,
                        Toolbar: CustomToolbar,
                        Pagination: () => (
                            <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2}}>
                                <Box sx={{mr: 4}}>
                                    Showing {items.length} records out of {totalItems}
                                </Box>
                                <FormControl variant="outlined" sx={{minWidth: 120}}>
                                    <InputLabel>Rows per page</InputLabel>
                                    <Select
                                        value={pageSize}
                                        onChange={handlePageSizeChange}
                                        label="Rows per page"
                                    >
                                        {[50, 100, 200, 500].map((size) => (
                                            <MenuItem key={size} value={size}>
                                                {size}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <Pagination
                                    count={totalPages}
                                    page={page}
                                    onChange={handlePageChange}
                                    variant="outlined"
                                    shape="rounded"
                                />
                            </Box>
                        ),
                    }}
                    loading={loading}
                />
            </Box>
        </div>
    )
}

export default NotificationsList;
