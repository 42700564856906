import React, {useEffect, useState} from 'react';
import './atom.css';
import Modal from 'react-modal';
import {updateQRStatus} from '../services/asset.service';
import * as CryptoJS from "crypto-js";


const StickerPasteStatusPopupBox = ({qrPasteStatus, tag, isOpen, onClose}) => {

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    const TEXT_REGEX = /^[a-zA-Z0-9-_ ]{1,500}$/;

    const [qrPasteStatusPop, setQRPasteStatus] = useState(qrPasteStatus);
    const [ErrQRPasteStatus, setErrQRPasteStatus] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        var valid_Name = true;

        if (!TEXT_REGEX.test(qrPasteStatusPop)) {
            setErrQRPasteStatus('Please select QR Paste Status');
            valid_Name = false;
            //return;
        } else {
            setErrQRPasteStatus('');
        }

        if (valid_Name) { //alert('test1');
            updateData(); //submit form
        } else {
            return false;
        }
    }

    const updateData = function () { setSuccess(false);
        if (tag !== '' && tag !== null) {
            // Convert the decrypted bytes to a string
            var obj = {
                'tag': tag,
                'qr_paste_status': qrPasteStatusPop,
                'created_by': sessionStorage.getItem('LoggedInUserId')
            }
        }
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': obj,
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token': EncryptData}
        try {
            updateQRStatus(oData).then(function (res) {
                console.log(res);
                if ((typeof (res.data.errors) != "undefined") && res.data.errors.errno > 0) {
                    setErrMsg(res.data.message);
                    setSuccess(false);
                } else {
                    setSuccess(res.data.message); 
                    setQRPasteStatus('');
                }
            });

            //setValidName(false);
        } catch (err) {
            setSuccess(false);
            if (!err?.data) {
                //console.log(err);
                setErrMsg('No Server Response');
            } else if (err.data?.status === 409) {
                setErrMsg('data already exist');
            } else {
                setErrMsg('Insert/Update Failed');
            }
            //elementRef.current.focus();
        }
    }

    const resetAssetInfo = function () {
        setQRPasteStatus('');
    }

    const handleGrouping = (e) => {
        setQRPasteStatus(e.target.value);
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Confirm Box"
            style={modalStyles}
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Update Status of QR Label Pasting ({qrPasteStatus})</h5>
                        <button type="button" class="btn-close" onClick={onClose}></button>
                    </div>
                    <div class="modal-body">
                        <h6 className="modal-title">TAG No - {tag} | Current Status -
                            {qrPasteStatus === 'N' ? (
                                "Not Pasted"
                            ) : (
                                "Pasted"
                            )}
                        </h6>
                    </div>
                    <div style={modalBody}>
                        {success ? (
                            <div className="alert alert-success" role="alert"><i
                                className="bi bi-check-circle-fill"></i>{success}</div>
                        ) : (errMsg ? (<div className="alert alert-danger" role="alert"><i
                                className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>) : ('')
                        )}
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-8 ms-2">
                                    <div className="label col-3 mt-2">
                                        <label>QR Paste Status </label><span className="star error"> * </span>
                                    </div>
                                    <div className="col-12 text-center">
                                        <select onChange={handleGrouping} name="alternative_verification"
                                                className="form-select form-select-sm">
                                            <option value="">Select QR Paste Status</option>
                                            <option value="Y"
                                                    selected={qrPasteStatus === "Y" ? "selected" : ''}>Pasted
                                            </option>
                                            <option value="N"
                                                    selected={qrPasteStatus === "N" ? "selected" : ''}>Not Pasted
                                            </option>
                                        </select>
                                        <span className="invalid-feedback"> {ErrQRPasteStatus} </span>
                                    </div>
                                </div>
                                <div className="col-3 text-center mt-4">
                                    {(tag !== '' && tag !== null) ?
                                        (<button className="btn btn-primary btn-md"> Update </button>) :
                                        <button className="btn btn-primary btn-md"> SAVE </button>}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

const modalStyles = {
    content: {
        width: '550px',
        height: '250px',
        textAlign: 'center',
    },
};
const modalBody = {
    content: {
        position: 'relative',
        flex: '1 1 auto',
        padding: '10px !important'
    },
}
export default StickerPasteStatusPopupBox;