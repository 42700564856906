import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { getUserManual } from '../../services/user.service'
import  * as CryptoJS  from "crypto-js";
import AWS from 'aws-sdk';
AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
  region: process.env.REACT_APP_AWS_BUCKET_REGION,
});
const s3 = new AWS.S3();


const handleDownloadFile = async (id, manual_doc) => {
    // Use the S3 getObject method to get the image and initiate the download
    console.log('manual_doc'+manual_doc);
    manual_doc = 'manual/'+manual_doc;
    s3.getObject({
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
      Key: manual_doc, //manual_doc,
    }, (err, data) => {
      if (err) {
        console.error('Error downloading image:', err);
      } else { console.log(data.Body);
        const url = URL.createObjectURL(new Blob([data.Body]));
        const a = document.createElement('a');
        a.href = url;
        a.download = manual_doc;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    });
  };
const columns = [
    
    /*{"field":"id","editable":false,"hide":false ,"headerName":"S.No.","width":80 },*/
    {"field":"name","editable":true,"hide":false ,"headerName":"Manual Name","width":230 },
    {"field":"version","editable":false,"hide":false ,"headerName":"Manual Version","width":180},
    {"field":"remarks","editable":false,"hide":false ,"headerName":"Remark","width":250},
    {"field":"manual_doc","editable":false,"hide":false ,"headerName":"Manual Document","width":230,
        renderCell: (params) => (<div><a href="#" onClick={() => handleDownloadFile(params.row.id, params.row.manual_doc) } >Download</a> &nbsp;
          </div>
      ) },
    {"field":"status","editable":false,"hide":false ,"headerName":"Status","width":60,
      renderCell: (params) => (
        <span>{(params.row.status==="1")?'Active':'Deactive'}</span>
    ) },
    
];

function UsersList() {
  const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
  /*var onCellEditStopFn = (event,newCell) => {
    var obj = {};
    obj[newCell.field] = event.target.value;
  }*/

    useEffect(() => {
        //encrypted data
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data':{'role_id':sessionStorage.getItem('LoggedInRoleId')},
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = { 'token': EncryptData };

        setLoading(true); // Set loading to true when starting the API request

        getUserManual(oData)
            .then(res => {
                if (res?.data?.data?.length) {
                    setItems(res.data.data);
                }
            })
            .finally(() => {
                setLoading(false); // Set loading to false when the API request is completed (regardless of success or failure)
            });
    }, []);


    // readUser();
  return (


      <div className='container'>
          <div className='row pd-2'>
              <div className='col-md-10'>
                  <h5>User Guide Documents</h5>
              </div>
              <div className='col-md-2 p-2'>
              <button className='btn btn-secondary btn-md' onClick={() => navigate(-1)}><i className="bi bi-arrow-left"></i> Back</button>
              </div>
          </div>
          <div className='row'>
              <div className='col-md-12'>

                      {loading ? (
                          <div className="text-center">
                              <div className="spinner-border text-primary" role="status">
                                  <span className="visually-hidden">Loading...</span>
                              </div>
                          </div>
                      ) : (
                          <DataGrid
                              rows={items}
                              getRowId={(row) => row.id}
                              columns={columns}
                              pageSize={50}
                              autoHeight
                              rowsPerPageOptions={[50]}
                          />
                      )}

              </div>
          </div>
      </div>

  )
}

export default UsersList
