import React, { useState } from 'react';

function FromSpFieldTextHari(props) {
    const [charCount, setCharCount] = useState(props.maxLength);

    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        const remainingChars = props.maxLength - inputValue.length;
        setCharCount(remainingChars);
        props.setInputValue(inputValue);
    };

    return (
        <div className="col-md-6">
                <textarea
                    className="form-control"
                    rows={props.numRows}
                    placeholder={props.placeholder}
                    value={props.inputValue}
                    onChange={handleInputChange}
                    readOnly={props.readOnly}
                ></textarea>
            <small className="text-muted">Characters remaining: {charCount}</small>
            <span className="invalid-feedback"> {props.setErrorMsg} </span>
        </div>
    );
}

export default FromSpFieldTextHari;
