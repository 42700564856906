import React, { useState, useEffect } from 'react';
import { getQuoteList } from '../../../services/disposal.service';

import { DataGrid } from '@mui/x-data-grid';
import * as CryptoJS from "crypto-js";

function getFormatedDate(dateString) {
    if (dateString != null) {
        let dte = new Date(dateString);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }
        return year + '-' + month + '-' + day;
        //return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
    } else {
        return null;
    }
}

const columns = [

    { "field": "din_no", "hideable": true, "hide": false,  "headerName": "DIN No", "width": 220 },
    /*{ "field": "tag", "hideable": true, "hide": false,  "headerName": "TAG", "width": 220 },*/
    {
        "field": "tag", "editable": false, "hide": false, "headerName": "TAG", "width": 250,
        renderCell: (params) => {
            let EncryptID = CryptoJS.AES.encrypt(JSON.stringify({
                'tag': params.row.tag, 'back_url':'listquote'
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            return (<a href={`/asset-basic-details?tag=${EncryptID}`}>{params.value}</a>);
        }
    },
    { "field": "buyer_name", "hideable": true, "hide": false,  "headerName": "Buyer Name", "width": 220 },
    { "field": "buyer_address",  "hide": false, "headerName": "Buyer Address", "width": 220 },
    { "field": "buyer_PAN",  "hide": false, "headerName": "PAN", "width": 220 },
    { "field": "buyer_GSTN",  "hide": false, "headerName": "GSTN", "width": 220 },
    { "field": "buyer_price_quoted",  "hide": false, "headerName": "Quoted Price", "width": 220 },
    { "field": "buyer_price_quoted_date",  "hide": false, "headerName": "Quote Date", "width": 220,
        renderCell: (params) => (
        <p>{ getFormatedDate(params.row.buyer_price_quoted_date)}</p>
      ) },
    { "field": "buyer_price_quoted_expiry_date",  "hide": false, "headerName": "Quote Expiry Date", "width": 220,
        renderCell: (params) => (
        <p>{ getFormatedDate(params.row.buyer_price_quoted_expiry_date)}</p>
      ) },
    {"field":"created_date","editable":true,"hide":false ,"headerName":"Created Date","width":180,
        renderCell: (params) => (
            <p>{ getFormatedDate(params.row.created_date)}</p>
        ) },

];

function ListQuote() {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);

    var onCellEditStopFn = (event, newCell) => {
        var obj = {};
        obj[newCell.field] = event.target.value;
    }

    useEffect(() => {
        // encrypted data
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            // 'param_data':obj,
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

        let oData = { 'token': EncryptData };

        setLoading(true);

        getQuoteList(oData)
            .then(res => {
                if (res?.data?.data?.length) {
                    setItems(res.data.data);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    // readAssets();
    return (
        <div className='container'>
            <div class="row"><div class="col">
                <h5>List of Quote</h5>
            </div></div>
            {loading ? (
                <div className="text-center">
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            ) : (
                <DataGrid
                    rows={items}
                    getRowId={(row) => row.id}
                    columns={columns}
                    pageSize={5}
                    autoHeight
                    rowsPerPageOptions={[5]}
                    onCellEditStop={onCellEditStopFn}
                />
            )}

        </div>
    )
}

export default ListQuote


