import React,{useState,useEffect} from 'react'
import { getAllDepreciationLog } from '../../../services/asset.service';
import Box from '@mui/material/Box';
import { DataGrid,GridToolbarContainer, GridToolbarExport  } from '@mui/x-data-grid';
import  * as CryptoJS  from "crypto-js";
var months = ['JAN','FEB','MAR','APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC'];

const columns = [
    {"field":"id","hideable":true,"hide":true,"editable":false,"headerName":"Sl No"},
    {"field":"tag","hideable":true,"hide":false ,"headerName":"TAG",width:330},
    {"field":"year","hideable":true,"hide":false ,"headerName":"Year"},
    {"field":"month","hide":false ,"headerName":"Month", valueGetter : (parms) => months[parms.row.month]},
    {"field":"asset_added_value","hide":true ,"headerName":"Asset Added Value"},
    {"field":"asset_deleted_value","hide":true ,"headerName":"Asset Deleted Value"},
    {"field":"asset_ob","hide":true ,"headerName":"Asset OB"},
    {"field":"asset_cb","hide":true ,"headerName":"Asset CB"},
    {"field":"depreciation_added_value","hide":true ,"headerName":"Depreciation Added Value"},
    {"field":"depreciation_deleted_value","hide":true ,"headerName":"Depreciation Deleted Value"},
    {"field":"depreciation_adjusted_value","editable":false,"hide":false ,"headerName":"Adjustment Amount",width:330},
    {"field":"depreciation_ob","hide":true ,"headerName":"Depreciation OB"},
    {"field":"depreciation_cb","hide":true ,"headerName":"Depreciation CB"},
    {"field":"wdv_ob","hide":true ,"headerName":"WDV OB"},
    {"field":"wdv_cb","hide":true ,"headerName":"WDV CB"}
];


function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

function DepreciationAdjTable() {
    const [loading, setLoading] = useState(true);
  const [assetsDepriciation,setAssetsDepriciation] = useState([]);

    useEffect(() => {
        setLoading(true);  // Set loading to true when fetching data starts

        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = { 'token': EncryptData };

        getAllDepreciationLog(oData)
            .then(function (result) {
                const data = result.data.data;
                console.log(data);

                if (data?.length > 0) {
                    setAssetsDepriciation(data.assetDeprisiation ? data.assetDeprisiation : {});
                }
            })
            .catch(function (error) {
                console.error('Error fetching data:', error);
            })
            .finally(() => {
                setLoading(false);  // Set loading to false when fetching is complete (either success or error)
            });
    }, []);

  return (
        <div className='container'>
          <div className="row">
              <div className="col">
                  <h5>I-GAAP - Adjustment Table</h5>
              </div>
          </div>
          <div className='row'>
            <div className='col'>
            <Box sx={{ height: 500, width: '100%' }}>

                {loading ? (
                    <div className="text-center">
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ) : (
                    <DataGrid
                        rows={assetsDepriciation}
                        getRowId={(row) => row.id}
                        columns={columns}
                        pageSize={50}
                        autoHeight
                        rowsPerPageOptions={[50]}
                        components={{
                            Toolbar: CustomToolbar,
                        }}
                    />
                )}

            </Box>
            </div>
          </div>
        </div>
  )
}

export default DepreciationAdjTable