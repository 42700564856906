import React,{useState, useEffect, useRef} from 'react';
import { useLocation } from "react-router-dom";
import FromFieldLabel from '../../../atoms/FromFieldLabel';
import FromFieldText from '../../../atoms/FromFieldText';
import FromFieldDate from '../../../atoms/FromFieldDate';
import FromFieldSelect from '../../../atoms/FromFieldSelect';
import { saveDeletion,getAdjustment,getSTPI } from '../../../services/asset.service';
import  * as CryptoJS  from "crypto-js";

Date.prototype.dbFormatedDate = function () {
    
  let year = this.getFullYear();
  let month = this.getMonth() + 1;
  let day = this.getDate();
  if (month < 10) {
    month = '0' + month;
  }if (day < 10) {
    day = '0' + day;
  }
  return year + '-' + month + '-' + day;
  //return `${this.getFullYear()}-${this.getMonth()+1}-${this.getDate()-1}`
}
function getFormatedDate(dateString) {
  if(dateString!==null) {
      let dte = new Date(dateString);
      let year = dte.getFullYear();
      let month = dte.getMonth() + 1;
      let day = dte.getDate();
      if (month < 10) {
        month = '0' + month;
      }if (day < 10) {
        day = '0' + day;
      }
      return year + '-' + month + '-' + day;
      //return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
  } else {
      return null;
  }   
}
function getFormatedDate1(dateString) {
  if(dateString!=null) {
      var dte = new Date(dateString);
      return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
  } else {
      return null;
  }   
}
const TEXT_REGEX = /^[A-Za-z0-9-_/.@= ]{1,155}$/;
const NUMBER_REGEX = /^[0-9]{1,15}$/;

function DisposalForm() {
  const [tag, setTag] = useState('');
  const [assetInfo, setAssetInfo] = useState([]);
  const [ErrTAG, setErrTag] = useState(false);
  const [errMsg, setErrMsg] = useState('');
	const [success, setSuccess] = useState(false);
  const [assetinusedate, setAssetInuseDate] = useState('');
  const [ErrAssetInuseDate, setErrAssetInuseDate] = useState(false);
  
  const [typeofdisposal, setTypeOfDisposal] = useState('');
  const [percentage, setPercentage] = useState(100);

  const [depreciationtype, setTypeOfDepreciation] = useState('');
  const [depDate, setDepDate] = useState('');

  const [ErrDepDate, setErrDepDate] = useState(false);
  const [astAddVal, setAstAddVal] = useState();
  const [assetOB, setAssetOB] = useState();
  const [astDelVal, setAstDelVal] = useState();
  const [assetCB, setAssetCB] = useState();
  const [depOB, setDepOB] = useState();
  const [depAddVal, setDepAddVal] = useState();
  const [depDelVal, setDepDelVal] = useState();
  
  const [depradjval, setDepAdjValue] = useState();
  const [deprclosingval, setDeprClosingVal] = useState();
  const [openingwdv, setOpeningWDV] = useState();
  const [closingwdv, setClosingWDV] = useState();
  //const [ErrDepreciationRate, setErrDepreciationRate] = useState(false);

  //const errRef = useRef();
  const [validName, setValidName] = useState(false);

  const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const Tag = query.get('tag');
    const Mode = query.get('mode'); 
    //const elementRef = useRef();
    useEffect(() => {
        if(Tag!='' && Tag!=null){
          getTagCapInfo(Tag);
            //alert(Tag);
        }
        
    }, []);

    const getTagCapInfo = function(Tag) {
      let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
        'param_data':{'tag':Tag},
        'authToken': sessionStorage.getItem('LoggedInToken'),
      }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
      let oData = {'token':EncryptData}
      getSTPI(oData).then(function(res){
        if(res?.data?.data) {
          var {assetInfo,assetInfo} = res.data.data;
          //console.log(assetInfo);
          setAssetInfo(assetInfo);
          
          if(assetInfo.tag){
            setTag(assetInfo.tag);
            setAssetInuseDate((new Date(assetInfo.asset_in_use_date)));
            //setDepreciationRate(assetInfo.depreciation_rate);
          }
        }
  
      });
  }
  const getTagInfo = function() {
    let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
      'param_data':{'tag':tag},
      'authToken': sessionStorage.getItem('LoggedInToken'),
  }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
  let oData1 = {'token':EncryptData}
    getSTPI(oData1).then(function(res){
      if(res?.data?.data) {
        var {assetInfo,assetInfo} = res.data.data;
        //console.log(assetInfo); 
        setAssetInfo(assetInfo);
        if(assetInfo.tag) { 
          setTag(assetInfo.tag);
          let curMonthDate = (new Date(assetInfo.asset_in_use_date))
           curMonthDate.setMonth(curMonthDate.getMonth()+1,0);
           //alert(curMonthDate);
          setAssetInuseDate(curMonthDate);
          //setDepreciationRate(assetInfo.depreciation_rate);
        }
      }
    });
  }
  const adjustment_handler = (e) => {
    setDepAdjValue(parseFloat(e.target.value));
    //deprclosingval = depradjval+deprclosingval
    
  }
  const handleSubmit = async (e) => {
		e.preventDefault(); var valid_Name=true;
		if (tag ==='') {
			setErrDepDate('Tag is required'); valid_Name=false;
			//return;
		}else{ setErrTag(''); }
    
    if (assetinusedate ==='') {
			setErrAssetInuseDate('Final Sale Date is required'); valid_Name=false;
			//return;
		}else{ setErrAssetInuseDate('');  }

    
    if (valid_Name) {
      
      saveData(); //submit form
    }else{
          return false;
    }
		
	}
   
  const saveData = function() {
    let data1={
      //'fnclass':'Furniture',
      'from_date':getFormatedDate1(assetinusedate),
      'tag':tag,
    }
    let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
      'param_data':data1,
      'authToken': sessionStorage.getItem('LoggedInToken'),
    }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
    let oData2 = {'token':EncryptData}
    getAdjustment(oData2).then(function(res1){
    if(res1?.data?.data?.assetDeprisiation){
      var assetInfo2 = res1.data.data.assetDeprisiation;
      assetInfo2 = assetInfo2[0];
      //console.log("assetInfo2=>");console.log(assetInfo2);
      
      //setAssetInfo(assetInfo2);
      setAstAddVal(assetInfo2.asset_added_value);
      setAssetOB(assetInfo2.asset_ob);
      setAstDelVal(assetInfo2.asset_deleted_value);
      
      setDepOB(assetInfo2.depreciation_ob);
      setDepAddVal(assetInfo2.depreciation_added_value);
      let depDelVal=parseFloat(((parseFloat(assetInfo2.depreciation_ob)+parseFloat(assetInfo2.depreciation_added_value)+parseFloat(assetInfo2.depreciation_adjusted_value))*percentage)/100);
      setDepAdjValue(assetInfo2.depreciation_adjusted_value);
      //setDeprClosingVal(assetInfo2.depreciation_cb)
      //setOpeningWDV(assetInfo2.wdv_ob);
      //setClosingWDV(assetInfo2.wdv_cb);
      let dep_deleted_value= parseFloat(((parseFloat(assetInfo2.asset_ob)+parseFloat(assetInfo2.asset_added_value))*percentage)/100);
      let Asset_CB = (parseFloat(assetInfo2.asset_ob)+parseFloat(assetInfo2.asset_added_value)-parseFloat(dep_deleted_value));

      let DeClosingVal =(parseFloat(assetInfo2.depreciation_ob)+parseFloat(assetInfo2.depreciation_added_value)+parseFloat(assetInfo2.depreciation_adjusted_value)-depDelVal);
      let opening_WDV = (parseFloat(assetInfo2.asset_ob) - parseFloat(assetInfo2.depreciation_ob));
      let closing_WDV = (parseFloat(Asset_CB) - (parseFloat(assetInfo2.depreciation_ob)+parseFloat(assetInfo2.depreciation_added_value)+parseFloat(assetInfo2.depreciation_adjusted_value)-depDelVal));
      //console.log(assetInfo);
    try {
    let final_data = {'data': {
      'tag': tag, 
      'asset_ob':assetInfo2.asset_ob,
      'asset_added_value':assetInfo2.asset_added_value,
      'asset_deleted_value':dep_deleted_value,
      'asset_cb':Asset_CB,
      'depreciation_added_value':assetInfo2.depreciation_added_value,
      'depreciation_adjusted_value':assetInfo2.depreciation_adjusted_value,
      'depreciation_deleted_value':depDelVal,
      'depreciation_ob':assetInfo2.depreciation_ob,
      'depreciation_cb':DeClosingVal,
      'wdv_ob':opening_WDV,
      'wdv_cb':closing_WDV,
      'company_id':sessionStorage.getItem('LoggedInCompanyId'),
      'division_id':sessionStorage.getItem('LoggedInDivisionId'),
      'created_by':sessionStorage.getItem('LoggedInUserId'),
      },
      'depreciation_date':getFormatedDate1(assetinusedate),
    }; 
    let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
      'param_data':final_data,
      'authToken': sessionStorage.getItem('LoggedInToken'),
    }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
    let oData3 = {'token':EncryptData}
      //console.log("data=>12");console.log(final_data);
      saveDeletion(oData3).then(function(res){
        if((typeof(res.data.errors)!= "undefined") && res.data.errors.errno>0){ 
          setErrMsg(res.data.message);setSuccess(false);
        }else{ 
         setSuccess(res.data.message);
         //elementRef.current.focus();
         setErrTag(''); setErrDepDate(''); //setDepreciationRate(''); 
         setValidName(false);
        }
      });
      } catch (err) { setSuccess(false); setErrMsg(err.data.message);
        if (!err?.data) {
                  console.log(err);
          setErrMsg('No Server Response');
        } else if (err.data?.status === 409) {
          setErrMsg('Username Taken');
        } else {
          setErrMsg('Insert/Update Failed');
        }
      }
    }
  });
    }
  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <h5>Disposal Form</h5>
        </div>
        {success ? (
				<div className="alert alert-success" role="alert"><i className="bi bi-check-circle-fill"></i>{success}</div>
			        ) : ( errMsg ? (<div className="alert alert-danger" role="alert"><i className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>):('')
                ) }
      </div>
      <div className='row '>
            <div className='col-12'>&nbsp;</div>
            {
            (Tag!=='' && Tag!==null) ? <div className='row'><div className='col-10'></div> <div className='col-2'></div></div> : <div className='row'><div className='col'><h5 className='align-right'>TAG </h5></div>
            <div className='col'><input onChange={function(event){setTag(event.target.value)}} inputValue={assetInfo.tag} type="text" className="form-control"  placeholder='Enter the asset TAG' /><p></p></div>
            <div className='col'><button onClick={getTagInfo} className='btn btn-primary btn-md'> Search </button></div></div>
            }

            </div>
      <form onSubmit={handleSubmit} > 
      <div className="row">
      <div className="col-6">
      <FromFieldLabel  star="*"
        label="Tag" 
        inputValue={assetInfo.tag} 
      />
      </div>
      <div className="col-6">
      <FromFieldLabel  star="*"
        label="Parent Tag" 
        inputValue={assetInfo.parent_tag} 
      />
      </div>
      </div>
      <div className="row">
      <div className="col-6">
         <FromFieldDate star="*"
            label="Final Sale Date" 
           // placeholder="Date of Capitalization / Asset Ready To Use" 
            inputValue={assetinusedate}
            setInputValue={setAssetInuseDate} 
            setErrorMsg={ErrAssetInuseDate}
          /> 
        </div>
        <div className="col-6">
          <FromFieldSelect 
              label="Type of Disposal"
              placeholder="Select Type of Disposal"
              selectedValue={typeofdisposal}
              setSelectedValue={setTypeOfDisposal}
              values={["Full","Partial"]}
          />
        </div>
      </div>
      <div className="row">
      <div className="col-6">
      {(typeofdisposal=="Full") ? (
          <FromFieldText star="*"
            label="Percentage" 
           // placeholder="Percentage" 
            inputValue={percentage}
            setInputValue={setPercentage} 
            //setErrorMsg={ErrPercentage}
            readOnly="readonly"
          /> ) : <div className='row form-fields'><div className='col-6'><label> Percentage <span className="star error"> * </span></label></div>
            <div className="col-6">
              <input onChange={function(event){setPercentage(event.target.value)}}  type="text" className="form-control"  placeholder="" />
            </div> </div>
              }
        </div>
        <div className="col-6">
        </div>
      </div>
      <div className="row">
        <div className="col-6"></div>
        <div className="col-6">
        { (Tag!=='' && Tag!==null) ? <div><button className="btn btn-primary btn-md" >Update</button>&nbsp;&nbsp;<a href={'/assets-capitalization-view?tag='+ Tag } className="btn btn-secondary btn-md">Cancel</a></div>
                 : <button className="btn btn-primary btn-md" > SAVE </button>
                 } 
                 {/*   <button onClick={saveData} className='btn btn-primary btn-md'> SAVE </button>*/}
        </div>
      </div>


    </form>
    </div>
  )
}

export default DisposalForm;

