import React from 'react';
import MultiListPopUp from '../atoms/MultiListPopUp';
function FromMoveList(props) {
  return (
    <div className="row form-fields">
        <div className="label col-6" > 
            <label>  </label> 
        </div>                   
        <div className="item col-6" > 
            <button onClick={()=> {props.setShowPopUp1(true);} } className="btn btn-primary mb-2">
                    Continue <span className="bi bi-gear-fill"></span>
            </button>

            <MultiListPopUp 
                title1={props.label1} 
                show1={props.showPopUp1} 
                showsetter1={props.setShowPopUp1} 
                data1={props.data1} 
                datasetter1={props.datasetter1} />
        </div>
    </div>
  )
}

export default FromMoveList