import React, { useState, useEffect } from 'react';



function ITEvaluation() {

    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h5>IT Evaluation</h5>
                </div>
            </div>

            <div className='row mt-4'>
                <div className='table-responsive'>
                    <table className='table-bordered w-100 text-center'>
                        <thead>
                            <tr>
                                <td style={{fontWeight:700,color:'#fff',background:'#000531',padding:'5px'}}>Financial Classification</td>
                                <td style={{fontWeight:700,color:'#fff',background:'#000531',padding:'5px'}}>Check if</td>
                                <td style={{fontWeight:700,color:'#fff',background:'#000531',padding:'5px'}}>Status</td>
                                <td style={{fontWeight:700,color:'#fff',background:'#000531',padding:'5px'}}>Action</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Furniture</td>
                                <td>Block Is Nil</td>
                                <td>Yes / No</td>
                                <td style={{padding: '10px'}}>
                                    <button type='button' className='btn btn-primary'>NEXT if "Yes"</button>
                                </td>
                            </tr>
                            <tr>
                                <td>Building</td>
                                <td>Block Is Nil</td>
                                <td>Yes / No</td>
                                <td style={{padding: '10px'}}>
                                    <button type='button' className='btn btn-primary'>NEXT if "Yes"</button>
                                </td>
                            </tr>
                            <tr>
                                <td>Computer</td>
                                <td>Block Is Nil</td>
                                <td>Yes / No</td>
                                <td style={{padding: '10px'}}>
                                    <button type='button' className='btn btn-primary'>NEXT if "Yes"</button>
                                </td>
                            </tr>
                            <tr>
                                <td>Office Equipment</td>
                                <td></td>
                                <td>Yes / No</td>
                                <td style={{padding: '10px'}}>
                                    <button type='button' className='btn btn-primary'>NEXT if "Yes"</button>
                                </td>
                            </tr>
                           
                           
                        </tbody>
                    </table>
                </div>
            </div>

            



        </div>
    )
}

export default ITEvaluation;