import React, {useEffect, useState} from 'react'
//import Chart from "chart.js/auto";
//import { CategoryScale } from "chart.js";
import {
    Bar,
    BarChart,
    Cell,
    LabelList,
    Legend,
    Pie,
    PieChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from 'recharts';
import {getDashboardDetails} from '../../services/asset.service';
import './Dashboard.css';
import * as CryptoJS from "crypto-js";

//import { Bar, Pie } from 'react-chartjs-2';
function Dashboard1() {
    const [barChartData, setBarChartData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [assetInfo, setAssetInfo] = useState([]);
    const [BangaloreInfo, setBangaloreDataInfo] = useState([]);
    const [ChennaiInfo, setChennaiDataInfo] = useState([]);
    const [HyderabadInfo, setHyderabadDataInfo] = useState([]);
    const [allocatedInfo, setAllocatedDataInfo] = useState([]);
    const [notAllocatedInfo, setNotAllocatedDataInfo] = useState([]);
    const [yearOfAdditionInfo, setYearOfAdditionDataInfo] = useState([]);
    const [financialsClassificationInfo, setFinancialsClassificationDataInfo] = useState([]);
    const [allocableInfo, setallocableDataInfo] = useState([]);
    const [nonAllocableInfo, setNonAllocableDataInfo] = useState([]);
    const [newFreeInfo, setNewFreeDataInfo] = useState([]);
    const [capexInfo, setCapexDataInfo] = useState([]);
    const [opexInfo, setOpexDataInfo] = useState([]);
    useEffect(() => {
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            //'param_data':obj,
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token': EncryptData}
        setLoading(true); // Show loader before fetching data
        getDashboardDetails(oData).then(res => {

            if (res?.data?.data?.assetInfo) { //res.data.data
                setAssetInfo(res.data.data.assetInfo);
                setBangaloreDataInfo(res.data.data.bangaloreData);
                setChennaiDataInfo(res.data.data.chennaiData);
                setHyderabadDataInfo(res.data.data.hyderabadData);
                setAllocatedDataInfo(res.data.data.allocatedCountData);
                setNotAllocatedDataInfo(res.data.data.notAllocatedCountData);
                setYearOfAdditionDataInfo(res.data.data.yearOfAdditionData);
                setFinancialsClassificationDataInfo(res.data.data.financialsClassificationData);
                setallocableDataInfo(res.data.data.allocableData);
                setNonAllocableDataInfo(res.data.data.nonAllocableData);
                setNewFreeDataInfo(res.data.data.newFreeData);
                setCapexDataInfo(res.data.data.capexData);
                setOpexDataInfo(res.data.data.opexData);
            }
        }).catch(error => {
            console.error('Error fetching data:', error);
            // Handle error if needed
        }).finally(() => {
            setLoading(false); // Hide loader when data fetching is done (success or failure)
        });
    }, []);
    const sSub_Module_Id = sessionStorage.getItem('LoggedInSubModuleId');
    const aSub_Module_Id = sSub_Module_Id.split(',');
    //console.log('aSub_Module_Id'); console.log(aSub_Module_Id);
    const data = [
        {name: 'Category 1', value: 30},
        {name: 'Category 2', value: 50},
        {name: 'Category 3', value: 20},
    ];

    const IdentifyData = [
        {name: 'Identifiable', value: assetInfo?.count_assets - allocatedInfo?.allocated_tag},
        {name: 'Non Identifiable', value: notAllocatedInfo.not_allocated_tag},

    ];

    const fcData = financialsClassificationInfo.map(item => ({
        name: item.financial_classification_name,
        value: item.count_asset,
        asset_value: parseFloat(item.asset_value).toFixed(2) ,
    }));

    const yearAdditionData = yearOfAdditionInfo.map(item => ({
        name: item.year_short_name,
        value: item.count_asset,
    }));

    const allocableData = [
        {name: 'Allocable', value: allocableInfo?.count_allocable},
        {name: 'Non-Allocable', value: (allocatedInfo?.allocated_tag > 0) ? allocatedInfo?.allocated_tag : 0},
    ];

    const allocableData2 = [
        {name: 'Allocated', value: allocableInfo?.count_allocable},
        {name: 'Free', value: (allocatedInfo?.allocated_tag > 0) ? allocatedInfo?.allocated_tag : 0},
        {name: 'New Free', value: newFreeInfo},
    ];

    const capexData = [
        {name: 'Capex', value: capexInfo?.count_capex},
        {name: 'Opex', value: opexInfo?.count_opex},
    ];

    const COLORS = ['#0088FE', '#00C49F'];
    const COLORS1 = ['#0088FE', '#00C49F', '#FFBB28'];
    const COLORS_HARI = ["#0088FE", "#FFC300", "#01A36A", "#9AECDB", "#85C1E9", "#A569BD", "#F1948A", "#BB8FCE", "#82E0AA", "#F8C471"];


    return (
        <div id="Dashboard">
            <div className="container">
                <div className="row gx-lg-5">
                    <div className="col-lg-3 mb-5 mb-lg-0">
                        <div className="card" style={{ backgroundColor: '#a2b9bc' }}>
                            <div className="card-body">
                                <h5 className="card-title">Total Book Assets</h5>
                                <p className="card-text"><b>Count:</b> {assetInfo.count_assets}</p>
                                <a href="#!" className="btn"
                                   style={{backgroundColor: '#878f99', color: '#fff'}}>Cost: {assetInfo.total_cost}</a>
                            </div>
                        </div>


                    </div>
                    <div className='col-lg-3 mb-5 mb-lg-0'>
                        <div className="card" style={{ backgroundColor: '#b2ad7f' }}>
                            <div className="card-body">
                                <h5 class="card-title">IDC - Bangalore</h5>
                                <p class="card-text"><b>Count:</b> {BangaloreInfo?.count_assets}</p>
                                <a href="#!" class="btn" style={{backgroundColor: '#6b5b95', color: '#fff'}}>Cost: {BangaloreInfo?.total_cost}</a>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3 mb-5 mb-lg-0'>
                        <div className="card" style={{ backgroundColor: '#a2b9bc' }}>
                            <div className="card-body">
                                <h5 class="card-title">IDC - Chennai</h5>
                                <p class="card-text"><b>Count:</b> {ChennaiInfo?.count_assets}</p>
                                <a href="#!" class="btn" style={{backgroundColor: '#878f99', color: '#fff'}}>Cost: {ChennaiInfo?.total_cost}</a>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3 mb-5 mb-lg-0'>
                        <div className="card" style={{ backgroundColor: '#b2ad7f' }}>
                            <div className="card-body">
                                <h5 class="card-title">IDC - Hyderabad</h5>
                                <p class="card-text"><b>Count:</b> {HyderabadInfo?.count_assets}</p>
                                <a href="#!" class="btn" style={{backgroundColor: '#6b5b95', color: '#fff'}}>Cost: {HyderabadInfo?.total_cost}</a>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="row gx-lg-5"><br></br></div>
                <div className="row gx-lg-5">
                    {(aSub_Module_Id.indexOf('114') > -1) ? (<>
                        <div className='col-lg-6 mb-5 mb-lg-0'>
                            <div className="card">
                                <div className="card-body">
                                    <h5 style={{textAlign: 'center'}}>YEAR WISE ADDITION</h5>
                                    <ResponsiveContainer width="100%" height={300}>
                                        <BarChart data={yearAdditionData} barGap={5} barCategoryGap={10}>
                                            <XAxis dataKey="name"/>
                                            <YAxis/>
                                            <Tooltip/>
                                            <Legend/>
                                            <Bar dataKey="value" fill="#8884d8" barSize={25} name="Tag Count"/>
                                        </BarChart>
                                    </ResponsiveContainer>

                                </div>
                            </div>
                        </div></>):(<></>)}
                    {(aSub_Module_Id.indexOf('115') > -1) ? (<>
                        <div className="col-lg-6 mb-5 mb-lg-0">
                            <div className="card">
                                <div className="card-body">
                                    <h5 style={{textAlign: 'center'}}>Identifiable Vs Non Identifiable</h5>
                                    <ResponsiveContainer width="100%" height={300}>
                                        <PieChart>
                                            <Pie dataKey="value"
                                                data={IdentifyData}
                                                cx={200}
                                                cy={150}
                                                outerRadius={80}
                                                fill="#8884d8"
                                                label
                                            >
                                                {data.map((entry, index) => (
                                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>
                                                ))}
                                            </Pie>

                                            <Tooltip/>
                                            <Legend/>
                                        </PieChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                        </div>
                    </>):(<></>)}
                </div>

                <div className="row gx-lg-5 mt-3">
                    {(aSub_Module_Id.indexOf('116') > -1) ? (<>
                    <div className='col-lg-12 mb-5 mb-lg-0'>
                        <div className="card">
                            <div className="card-body">
                                <h5 style={{textAlign: 'center'}}>FS Classification Wise</h5>

                                <ResponsiveContainer width="100%" height={400}>
                                    <BarChart data={fcData} barGap={15} barCategoryGap={25} margin={{ left: 30 }}>
                                        {/* Remove the CartesianGrid component */}
                                        <XAxis dataKey="name"/>
                                        <YAxis domain={[0, 'dataMax + 1000000000']}/> {/* Adjust the domain to increase bar height */}
                                        <Tooltip/>
                                        <Legend/>

                                        {/* Bar for 'asset_value' */}
                                        <Bar dataKey="asset_value" fill="#8884d8" barSize={30} name="Cost">
                                            {/* Render each bar with a different color */}
                                            {fcData.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={COLORS_HARI[index % COLORS_HARI.length]} />
                                            ))}
                                            {/* Display label lists on top of each bar */}
                                            <LabelList dataKey="asset_value" position="top" formatter={(value) => `INR ${value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`} />
                                        </Bar>
                                    </BarChart>
                                </ResponsiveContainer>

                            </div>
                        </div>
                    </div>
                    </>):(<></>)}  
                </div>

                <div className="row gx-lg-5 mt-3">
                    {(aSub_Module_Id.indexOf('117') > -1) ? (<>
                    <div className="col-lg-4 mb-5 mb-lg-0">
                        <div className="card">
                            <div className="card-body">
                                <h5 style={{textAlign: 'center'}}>Allocable VS Non-Allocable</h5>
                                <ResponsiveContainer width="100%" height={300}>
                                    <PieChart>
                                        <Pie dataKey="value"
                                             data={allocableData}
                                             cx={200}
                                             cy={150}
                                             outerRadius={80}
                                             fill="#8884d8"
                                             label
                                        >
                                            {data.map((entry, index) => (
                                                <Cell key={`cell-${index}`}
                                                      fill={COLORS_HARI[index % COLORS_HARI.length]}/>
                                            ))}
                                        </Pie>

                                        <Tooltip/>
                                        <Legend/>
                                    </PieChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    </div>
                    </>):(<></>)} 
                    {(aSub_Module_Id.indexOf('118') > -1) ? (<>
                    <div className="col-lg-4 mb-5 mb-lg-0">
                        <div className="card">
                            <div className="card-body">
                                <h5 style={{textAlign: 'center'}}>Active Usage of Allocable Assets</h5>
                                <ResponsiveContainer width="100%" height={300}>
                                    <PieChart>
                                        <Pie data={allocableData2} dataKey="value" nameKey="name" cx="50%" cy="50%"
                                             innerRadius={50} outerRadius={70} fill="#82ca9d" label>
                                            {
                                                allocableData2.map((entry, index) => (
                                                    <Cell key={`cell-${index}`}
                                                          fill={COLORS_HARI[index % COLORS_HARI.length]}/>
                                                ))
                                            }
                                        </Pie>
                                        <Tooltip/>
                                        <Legend align="center" verticalAlign="bottom" layout="horizontal"/>
                                    </PieChart>
                                </ResponsiveContainer>
                            </div>

                        </div>
                    </div>
                    </>):(<></>)} 
                    {(aSub_Module_Id.indexOf('119') > -1) ? (<>
                    <div className="col-lg-4 mb-5 mb-lg-0">
                        <div className="card">
                            <div className="card-body">
                                <h5 style={{textAlign: 'center'}}>Capex Vs Opex</h5>
                                <ResponsiveContainer width="100%" height={300}>
                                    <PieChart>
                                        <Pie dataKey="value"
                                             data={capexData}
                                             cx={200}
                                             cy={150}
                                             outerRadius={80}
                                             fill="#8884d8"
                                             label
                                        >
                                            {data.map((entry, index) => (
                                                <Cell key={`cell-${index}`}
                                                      fill={COLORS_HARI[index % COLORS_HARI.length]}/>
                                            ))}
                                        </Pie>

                                        <Tooltip/>
                                        <Legend/>
                                    </PieChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    </div>
                    </>):(<></>)} 
                </div>


            </div>
        </div>
    );
}

export default Dashboard1