import React, { useState, useEffect } from 'react';

function InitiateSingleTransferThree() {

    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h5>Initiate Single Disposal- Three</h5>
                </div>
            </div>

            <div className="row mt-4 single__asset__form bgcolor">
                <div className="col-6">
                    <div className="row form-fields">
                        <div className="label col-6" >
                            <label> New Division </label>
                            <span className="star error">* </span>
                        </div>
                        <div className="item col-6" >
                            <input type='text'
                                name=""
                                value=''
                                className="form-control"
                                placeholder=''
                            />
                            <span className="invalid-feedback"> </span>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="row form-fields">
                        <div className="label col-6" >
                            <label> New Premises </label>
                            <span className="star error">* </span>
                        </div>
                        <div className="item col-6" >
                            <input type='text'
                                name=""
                                value=''
                                className="form-control"
                                placeholder=''
                            />
                            <span className="invalid-feedback"> </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row single__asset__form bgcolor">
                <div className="col-6">
                    <div className="row form-fields">
                        <div className="label col-6" >
                            <label> New Floor </label>
                            <span className="star error">* </span>
                        </div>
                        <div className="item col-6" >
                            <input type='text'
                                name=""
                                value=''
                                className="form-control"
                                placeholder=''
                            />
                            <span className="invalid-feedback"> </span>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="row form-fields">
                        <div className="label col-6" >
                            <label> New Site ID </label>
                            <span className="star error">* </span>
                        </div>
                        <div className="item col-6" >
                            <input type='text'
                                name=""
                                value=''
                                className="form-control"
                                placeholder=''
                            />
                            <span className="invalid-feedback"> </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row single__asset__form bgcolor">
                <div className="col-6">
                    <div className="row form-fields">
                        <div className="label col-6" >
                            <label> New User Type </label>
                            <span className="star error">* </span>
                        </div>
                        <div className="item col-6" >
                            <input type='text'
                                name=""
                                value=''
                                className="form-control"
                                placeholder=''
                            />
                            <span className="invalid-feedback"> </span>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="row form-fields">
                        <div className="label col-6" >
                            <label> New User Department </label>
                            <span className="star error">* </span>
                        </div>
                        <div className="item col-6" >
                            <input type='text'
                                name=""
                                value=''
                                className="form-control"
                                placeholder=''
                            />
                            <span className="invalid-feedback"> </span>
                        </div>
                    </div>
                </div>
            </div>


            <div className="row single__asset__form bgcolor">
                <div className="col-6">
                    <div className="row form-fields">
                        <div className="label col-6" >
                            <label> New Employee ID </label>
                        </div>
                        <div className="item col-6" >
                            <input type='text'
                                name=""
                                value=''
                                className="form-control"
                                placeholder=''
                            />
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="row form-fields">
                        <div className="label col-6" >
                            <label> New Employee Name </label>
                        </div>
                        <div className="item col-6" >
                            <input type='text'
                                name=""
                                value=''
                                className="form-control"
                                placeholder=''
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-12 text-center">
                    <button className="btn btn-primary me-2" > Edit </button>
                    <button className="btn btn-primary me-2" > Cancel </button>
                    <button className="btn btn-primary" > Submit </button>
                </div>
            </div>


        </div>
    )
}

export default InitiateSingleTransferThree;