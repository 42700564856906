import React,{useState, useEffect, useRef} from 'react';
import { useLocation } from "react-router-dom";
import FromFieldLabel from '../../../atoms/FromFieldLabel';
import FromFieldText from '../../../atoms/FromFieldText';
import FromFieldDate from '../../../atoms/FromFieldDate';
import FromFieldSelect from '../../../atoms/FromFieldSelect';
import '../AssetInfo/SingleAsset.css';
import {saveAdjustment,getAdjustment,getSTPI } from '../../../services/asset.service';
import  * as CryptoJS  from "crypto-js";
//import FromFieldSelect from '../../../atoms/FromFieldSelect';
Date.prototype.dbFormatedDate = function () {
    
  let year = this.getFullYear();
  let month = this.getMonth() + 1;
  let day = this.getDate();
  if (month < 10) {
    month = '0' + month;
  }if (day < 10) {
    day = '0' + day;
  }
  return year + '-' + month + '-' + day;
  //return `${this.getFullYear()}-${this.getMonth()+1}-${this.getDate()-1}`
}
function getFormatedDate(dateString) {
  if(dateString!==null) {
      let dte = new Date(dateString);
      let year = dte.getFullYear();
      let month = dte.getMonth() + 1;
      let day = dte.getDate();
      if (month < 10) {
        month = '0' + month;
      }if (day < 10) {
        day = '0' + day;
      }
      return year + '-' + month + '-' + day;
      //return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
  } else {
      return null;
  }   
}
const TEXT_REGEX = /^[A-Za-z0-9-_/.@= ]{1,155}$/;
const NUMBER_REGEX = /^[0-9]{1,15}$/;

function IGaapAdjustment() {
  const [tag, setTag] = useState('');
  const [assetInfo, setAssetInfo] = useState([]);

  const [ErrTAG, setErrTag] = useState(false);
  const [errMsg, setErrMsg] = useState('');
	const [success, setSuccess] = useState(false);
  const [depreciationtype, setTypeOfDepreciation] = useState('');
  const [depDate, setDepDate] = useState('');
  const [ErrDepDate, setErrDepDate] = useState(false);
  const [astAddVal, setAstAddVal] = useState();
  const [assetOB, setAssetOB] = useState();
  const [astDelVal, setAstDelVal] = useState();
  const [assetCB, setAssetCB] = useState();
  const [depOB, setDepOB] = useState();
  const [depAddVal, setDepAddVal] = useState();
  const [depDelVal, setDepDelVal] = useState();
  
  const [depradjval, setDepAdjValue] = useState();
  const [deprclosingval, setDeprClosingVal] = useState();
  const [openingwdv, setOpeningWDV] = useState();
  const [closingwdv, setClosingWDV] = useState();
  //const errRef = useRef();
  const [validName, setValidName] = useState(false);

  const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const Tag = query.get('tag');
    const Mode = query.get('mode'); 
    //const elementRef = useRef();
    useEffect(() => {
        if(Tag!='' && Tag!=null){
          getTagCapInfo(tag);
            //alert(Tag);
        }
        
    }, []);
    const adjustment_handler = (e) => { setDepAdjValue(e.target.value);
      if(parseFloat(e.target.value)<=0 || parseFloat(e.target.value)>=0 ){  //console.log(parseFloat(e.target.value));
      setDepAdjValue(e.target.value);
      //deprclosingval = depradjval+deprclosingval
      setDeprClosingVal(parseFloat(assetInfo.depreciation_ob)+parseFloat(assetInfo.depreciation_added_value)+parseFloat(assetInfo.depreciation_deleted_value)+parseFloat(depradjval));
      setOpeningWDV(parseFloat(assetInfo.asset_ob) - parseFloat(assetInfo.depreciation_ob));
      setClosingWDV(parseFloat(assetInfo.asset_cb) - (parseFloat(assetInfo.depreciation_ob)+parseFloat(assetInfo.depreciation_added_value)+parseFloat(assetInfo.depreciation_deleted_value)+parseFloat(depradjval)));
      } else{setDeprClosingVal(parseFloat(assetInfo.depreciation_ob)+parseFloat(assetInfo.depreciation_added_value)+parseFloat(assetInfo.depreciation_deleted_value)+parseFloat(depradjval));
        setOpeningWDV(parseFloat(assetInfo.asset_ob) - parseFloat(assetInfo.depreciation_ob));
        setClosingWDV(parseFloat(assetInfo.asset_cb) - (parseFloat(assetInfo.depreciation_ob)+parseFloat(assetInfo.depreciation_added_value)+parseFloat(assetInfo.depreciation_deleted_value)+parseFloat(depradjval)));
        }
    }
    const getTagCapInfo = function() {
      let data1= {'tag':tag,
                'created_by':sessionStorage.getItem('LoggedInUserId'),
              }
      let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
          'param_data':data1,
          'authToken': sessionStorage.getItem('LoggedInToken'),
      }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
      let oData = {'token':EncryptData}
      getSTPI(oData).then(function(res){
        if(res?.data?.data) {
          var assetInfo1 = res.data.data.assetInfo;
          //console.log(assetInfo1);
          if(assetInfo1.tag) { 
            setTag(assetInfo1.tag);
            setTypeOfDepreciation(assetInfo1.type_of_depreciation)
            setDepDate((new Date(assetInfo1.asset_in_use_date)));
            //setDepreciationRate(assetInfo.depreciation_rate);
          }
        }
  
      });
    }
      
    const showDepreciaton = function() {

        let data2={
          //'fnclass':'Furniture',
          'from_date':getFormatedDate(depDate),
          'tag':tag,
        }
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
          'param_data':data2,
          'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData1 = {'token':EncryptData}
        getAdjustment(oData1).then(function(res){
        if(res?.data?.data?.assetDeprisiation){
          var assetInfo = res.data.data.assetDeprisiation;
          assetInfo = assetInfo[0];
          setAssetInfo(assetInfo);
          setAstAddVal(assetInfo.asset_added_value);
          setAssetOB(assetInfo.asset_ob);
          setAstDelVal(assetInfo.asset_deleted_value);
          setAssetCB(assetInfo.asset_cb)
          setDepOB(assetInfo.depreciation_ob);
          setDepAddVal(assetInfo.depreciation_added_value);
          setDepDelVal(assetInfo.depreciation_deleted_value);
          setDepAdjValue(assetInfo.depreciation_adjusted_value);
          setDeprClosingVal(assetInfo.depreciation_cb)
          setOpeningWDV(assetInfo.wdv_ob);
          setClosingWDV(assetInfo.wdv_cb);
          //console.log(assetInfo);
        }
  
      });
    }
  
 
  const handleSubmit = async (e) => {
		e.preventDefault(); var valid_Name=true;
		if (tag ==='') {
			setErrDepDate('Tag is required'); valid_Name=false;
			//return;
		}else{ setErrTag(''); }
    if (depDate ==='') {
			setErrDepDate('Date is required'); valid_Name=false;
			//return;
		}else{ setErrDepDate(''); }
    if (valid_Name) {
      saveData(); //submit form
    }else{
          return false;
    }
		
	}
   
  const saveData = function() {
    try {
    let data2 = {'data': {
      'tag': tag, 
      'asset_ob':assetOB,
      'asset_added_value':astAddVal,
      'asset_deleted_value':astDelVal,
      'asset_cb':assetCB,
      'depreciation_added_value':depAddVal,
      'depreciation_adjusted_value':depradjval,
      'depreciation_deleted_value':depDelVal,
      'depreciation_ob':depOB,
      'depreciation_cb':deprclosingval,
      'wdv_ob':openingwdv,
      'wdv_cb':closingwdv,
      'company_id':sessionStorage.getItem('LoggedInCompanyId'),
      'division_id':sessionStorage.getItem('LoggedInDivisionId'),
      'created_by':sessionStorage.getItem('LoggedInUserId'),
      },
      'depreciation_date':getFormatedDate(depDate),
    };
      let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
        'param_data':data2,
        'authToken': sessionStorage.getItem('LoggedInToken'),
      }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
      let oData2 = {'token':EncryptData}
    saveAdjustment(oData2).then(function(res){
      if((typeof(res.data.errors)!= "undefined") && res.data.errors.errno>0){ 
        setErrMsg(res.data.message);setSuccess(false);
      }else{ 
       setSuccess(res.data.message);
       //elementRef.current.focus();
       setErrTag(''); setErrDepDate(''); //setDepreciationRate(''); 
       setValidName(false);
      }
    })
    } catch (err) { setSuccess(false); setErrMsg(err.data.message);
      if (!err?.data) {
                console.log(err);
        setErrMsg('No Server Response');
      } else if (err.data?.status === 409) {
        setErrMsg('Username Taken');
      } else {
        setErrMsg('Insert/Update Failed');
      }
    }
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <h5>I-GAAP - Adjustment</h5>
        </div>
        {success ? (
				<div className="alert alert-success" role="alert"><i className="bi bi-check-circle-fill"></i>{success}</div>
			        ) : ( errMsg ? (<div className="alert alert-danger" role="alert"><i className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>):('')
                ) }
      </div>
      <div className='row '>
            <div className='col-12'>&nbsp;</div>
            {
            (Tag!=='' && Tag!==null) ? <div className='row col-12'><div className='col-10'></div> <div className='col-2'></div></div> : <div className='row'><div className='col'><h6 className='align-right'>TAG </h6></div>
            <div className='col'><input onChange={function(event){setTag(event.target.value)}} inputValue={assetInfo.tag} type="text" className="form-control"  placeholder='Enter the asset TAG' /><p></p></div>
            <div className='col'><button onClick={getTagCapInfo} className='btn btn-info btn-md'> <i className="bi bi-search"></i> Search </button></div></div>
            }

            </div>
       
      <div className="row col-12">
      <div className='col-5'>
          <div className="row form-fields"><div className="label col-6"><label>Type of Depreciation</label></div>
          <div className="col-6">
          <FromFieldLabel
          label=""
          inputValue={depreciationtype}
          />
          </div></div>
      </div>  
      <div className="col-5">
      <FromFieldDate 
            label="Select last date of the month:"
            placeholder="Select Date"
            inputValue={depDate}
            setInputValue={setDepDate}
            setErrorMsg={ErrDepDate}
        />
        </div>
        <div className="col-2"><button onClick={showDepreciaton} className='btn btn-primary btn-md'> Fetch </button></div>         
        </div>
        
        <form onSubmit={handleSubmit} id='price'>
      <div className="row  form-fields col-12 bg-info summary_title">
        <div className="col-3"><strong>Particulars</strong></div>
        <div className="col-3"><strong>Current Month Values</strong></div>
        <div className="col-3"><strong>Adustment</strong></div>
        <div className="col-3"><strong>Current Month Revised Value</strong></div>
      </div>
      <div className="row col-12" >
      <div className="col-6" >

      <div className="item col-12" >
          <FromFieldText //star="*"
            label="Opening Value" 
            inputValue={assetInfo.asset_ob} 
            readOnly="readonly" 
          />
        </div>
      
        </div>
        <div className="form-fields col-3">
        <input inputValue=""  type="text" className="form-control" />
        </div>
        <div className="form-fields col-3">
        <input value={assetOB} type="text" className="form-control" readOnly="readonly"/>
          {/*<FromFieldText
            //label="Addition" 
            inputValue={assetOB} 
            readOnly="readonly"
          />*/} 
          </div>
      </div>
      <div className="row col-12">
        <div className="col-6">
          <FromFieldText
            label="Addition" 
            inputValue={assetInfo.asset_added_value} 
            readOnly="readonly"
          />
        </div>
        
        <div className="form-fields col-3">
        <input inputValue=""  type="text" className="form-control" />
        </div>
        <div className="form-fields col-3">
        <input value={astAddVal} type="text" className="form-control" readOnly="readonly"/>
          {/*<FromFieldText
            //label="Deletion"
            inputValue={astAddVal} 
            readOnly="readonly"
          />*/} 
        </div>
      </div><div className="row col-12">
        <div className="col-6">
          <FromFieldText
            label="Deletion"
            inputValue={assetInfo.asset_deleted_value} 
            readOnly="readonly"
          />
        </div>
        
        <div className="form-fields col-3">
        <input inputValue=""  type="text" className="form-control" />
        </div>
        <div className="form-fields col-3">
        <input value={astDelVal} type="text" className="form-control" readOnly="readonly"/>
          {/*<FromFieldText
           // label="Closing Value" 
            inputValue={astDelVal} 
            readOnly="readonly"
          />*/} </div>
      </div><div className="row col-12">
        <div className="col-6">
          <FromFieldText
            label="Closing Value" 
            inputValue={assetInfo.asset_cb} 
            readOnly="readonly"
          />
        </div>
        
        <div className="form-fields col-3">
        <input inputValue=""  type="text" className="form-control" />
        </div>
        <div className="form-fields col-3">
        <input value={assetCB} type="text" className="form-control" readOnly="readonly"/>
        {/*<FromFieldText
          //  label="Opening Acc Dep" 
            inputValue={assetCB} 
            readOnly="readonly"
        />*/}</div>
      </div><div className="row col-12">
        <div className="col-6">
          <FromFieldText
            label="Opening Acc Dep" 
            inputValue={assetInfo.depreciation_ob} 
            readOnly="readonly"
          />
        </div>
        
        <div className="form-fields col-3">
        <input inputValue=""  type="text" className="form-control" />
        </div>
        <div className="form-fields col-3">
        <input value={depOB} type="text" className="form-control" readOnly="readonly"/>
        {/*<FromFieldText
            //label="Addition for the Period"
            inputValue={depOB} 
            readOnly="readonly" 
      />*/}</div>
      </div><div className="row col-12">
        <div className="col-6">
          <FromFieldText
            label="Addition for the Period"
            inputValue={assetInfo.depreciation_added_value} 
            readOnly="readonly" 
          />
        </div>
        
        <div className="form-fields col-3">
        <input inputValue=""  type="text" className="form-control" />
        </div>
        <div className="form-fields col-3">
        <input value={depAddVal} type="text" className="form-control" readOnly="readonly"/>
          {/*<FromFieldText
            //label="Deletion for the Period" 
            inputValue={depAddVal} 
            readOnly="readonly" 
          />*/} </div>
      </div><div className="row col-12">
        <div className="col-6">
          <FromFieldText
            label="Deletion for the Period" 
            inputValue={assetInfo.depreciation_deleted_value} 
            readOnly="readonly" 
          />
        </div>
        
        <div className="form-fields col-3">
        <input inputValue=""  type="text" className="form-control" />
        </div>
        <div className="form-fields col-3">
        <input value={depDelVal} type="text" className="form-control" readOnly="readonly"/>
          {/*<FromFieldText //star="*"
            //label="Adjustment for the Period" 
            inputValue={depDelVal} 
            readOnly="readonly" 
          />*/} </div>
      </div><div className="row col-12">
        <div className="col-6">
          <FromFieldText star="*"
            label="Adjustment for the Period" 
            inputValue={assetInfo.depreciation_adjusted_value} 
            readOnly="readonly" 
          />
        </div>
        
        <div className="form-fields col-3">
        <input onChange={ adjustment_handler} onBlur ={adjustment_handler} value={depradjval}  type="number" placeholder="Enter Adjustment"  className="form-control" />
        </div>
        <div className="form-fields col-3">
        <input value={depradjval} type="text" className="form-control" readOnly="readonly"/>
        {/*<FromFieldText
            //label="Closing Acc Dep" 
            inputValue={depradjval} 
           // readOnly="readonly" 
        />*/} </div>
      </div><div className="row col-12">
        <div className="col-6">
          <FromFieldText
            label="Closing Acc Dep" 
            inputValue={assetInfo.depreciation_cb} 
           // readOnly="readonly" 
          />
        </div>
        
        <div className="form-fields col-3">
        <input inputValue=""  type="text" className="form-control" />
        </div>
        <div className="form-fields col-3">
        <input value={deprclosingval} type="text" className="form-control" readOnly="readonly"/>
        {/*<FromFieldText
           // label="Opening WDV" 
            inputValue={deprclosingval} 
            readOnly="readonly" 
        />*/} </div>
      </div><div className="row col-12">
        <div className="col-6">
          <FromFieldText
            label="Opening WDV" 
            inputValue={assetInfo.wdv_ob} 
            readOnly="readonly" 
          />
        </div>
        
        <div className="form-fields col-3">
        <input inputValue=""  type="text" className="form-control" />
        </div>
        <div className="form-fields col-3">
        <input value={openingwdv} type="text" className="form-control" readOnly="readonly"/>
        {/*<FromFieldText 
            //label="Closing WDV"
            inputValue={openingwdv}
            readOnly="readonly" 
        />*/}
        </div>
      </div><div className="row col-12">
        <div className="col-6">
          <FromFieldText 
            label="Closing WDV"
            inputValue={assetInfo.wdv_cb}
          />
        </div>
        
        <div className="form-fields col-3">
        <input inputValue="" type="text" className="form-control" />
        </div>
        <div className="form-fields col-3">
        <input value={closingwdv} type="text" className="form-control" readOnly="readonly"/>
          {/*<FromFieldText 
           // label="Closing WDV"
            inputValue={closingwdv}
          />*/} </div>
      </div>

      <div className="row">
        <div className="col-6"></div>
        <div className="col-6">
        { (Tag!=='' && Tag!==null) ? <div><button className="btn btn-primary btn-md" >Update</button>&nbsp;&nbsp;<a href={'/assets-capitalization-view?tag='+ Tag } className="btn btn-secondary btn-md">Cancel</a></div>
                 : <div><button className="btn btn-primary btn-md" > SAVE </button> <a href={'/igaap-adjustment' } className="btn btn-secondary btn-md">Reset</a></div>
                 } 
                 {/*   <button onClick={saveData} className='btn btn-primary btn-md'> SAVE </button>*/}
        </div>
      </div>
    </form>
    </div>
  )
}
export default IGaapAdjustment;

