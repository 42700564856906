
import React from 'react'

function FromFieldLabel(props) {
  return (
    <div className="row">
        <div className="col-md-6" > 
            <label> {props.label} </label> 
        </div>
        <div className="col-md-6" > 
            <label>{props.inputValue}</label>
        </div>
    </div>
  )
}

export default FromFieldLabel