import React, {useEffect, useRef, useState} from 'react'
import {useLocation} from "react-router-dom";
import FromFieldText from '../../atoms/FromFieldText';
import FromSpFieldText from '../../atoms/FromSpFieldText';
import FromFieldDate from '../../atoms/FromFieldDate';
import FromFieldNumber from '../../atoms/FromFieldNumber';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import '../ExcelUploads/AssetInfo/SingleAsset.css';
import {getCgApproval,getBondingConfigure,saveCgApproval,} from '../../services/stpi.service';
import * as CryptoJS from "crypto-js";

function getFormatedDate(dateString) {
    if (dateString != null) {
        let dte = new Date(dateString);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }
        return year + '-' + month + '-' + day;
    } else {
        return null;
    }
}

const TEXT_REGEX = /^[A-Za-z0-9-_/.@= ]{1,155}$/;
const TEXT_SPCL_REGEX = /^[A-Za-z0-9-_.,\/\[\]\-()"'+&'%$#@*!= ]{1,500}$/;
const NUMBER_REGEX = /^[0-9.]{1,15}$/;
const TEXT_DATE = /^[a-zA-Z0-9-_+:/() ]{1,35}$/;
export default function CgApproval() {
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const CG_ID = query.get('cg_id');
    const BackUrl = query.get('back_url');
    const elementRef = useRef();
    
    useEffect(() => {

        if (CG_ID && CG_ID !== null) {
                try {
                // Log the original value before decryption
                const encryptedString = CG_ID.replace(/ /g, '+');
                const decryptedBytes = CryptoJS.AES.decrypt(encryptedString, process.env.REACT_APP_ENCRYPT_SECRET_KEY);
                // Convert the decrypted bytes to a UTF-8 string
                const decryptedString = decryptedBytes.toString(CryptoJS.enc.Utf8);
                // Parse the decrypted string as JSON and extract CG_ID
                const decrypt_data = JSON.parse(decryptedString);
                const decryptedCG_ID = decrypt_data.cg_id;
                //console.log('Decrypted CG_ID:', decryptedCG_ID);
                if (decryptedCG_ID) {
                    getCgApprovalInfo(decryptedCG_ID);
                    setCgId(decryptedCG_ID);
                } else {
                    console.error('Decryption failed or CG_ID is missing.');
                }
            } catch (error) {
                console.error('Decryption error:', error);
            }
        }
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData1 = {'token': EncryptData}
        getBondingConfigure(oData1).then(function (res) {
            if (res?.data?.data?.data) {
                var configInfo = res.data.data.data;
                setBondConfigId(configInfo.bonding_id);
            }
        });

    }, [])

    function reset() {
        setRsCgApproved('');
        setAdditionalCgApproved('');
        setCgDateOfApproval('');
        //setRsTotalApproved('');
        //setAdditionalDateOfApproval('');
    }

    
    const getCgApprovalInfo = (decryptedCG_ID) => {
        //console.log(decryptedCG_ID);
        try {
            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': {'cg_id':decryptedCG_ID},
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData1 = {'token': EncryptData}
            
            getCgApproval(oData1).then(function (res) {
                console.log(res.data.data);
                if (res?.data?.data?.data) {
                    var configInfo = res.data.data.data;
                    setBondConfigId(configInfo.bonding_id);
                    setRsCgApproved(configInfo.cg_approval_number);
                    setAdditionalCgApproved(configInfo.additional_approved_price);
                    setCgDateOfApproval(new Date(configInfo.cg_approval_date));
                    //setRsTotalApproved(configInfo.cg_total_approved_price);
                    //setAdditionalDateOfApproval(configInfo.additional_approval_date);
                }
            })
        } catch (err) {
            //setAssetInfo('');
        }

    }
    //const userRef = useRef();
    const errRef = useRef();
    const [validName, setValidName] = useState(true);
    const [disabled, setDisabled] = useState(false);
    const [auditInfo, setAuditInfo] = useState([]);
    const [CgId, setCgId] = useState([]);
    
    const [RsCgApproved, setRsCgApproved] = useState('');
    const [ErrRsCgApproved, setErrRsCgApproved] = useState(false);

    const [AdditionalCgApproved, setAdditionalCgApproved] = useState('');
    const [ErrAdditionalCgApproved, setErrAdditionalCgApproved] = useState(false);

    const [CgDateOfApproval, setCgDateOfApproval] = useState('');
    const [ErrCgDateOfApproval, setErrCgDateOfApproval] = useState(false);

    //const [RsTotalApproved, setRsTotalApproved] = useState('');
    //const [ErrRsTotalApproved, setErrRsTotalApproved] = useState(false);

    const [bondConfigId, setBondConfigId] = useState('');

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    //const [inputList, setInputList] = useState([{ text: '' }]);
    //const [dateList, setDateList] = useState([{ text: '' }]);
    // Handle input change
    /* const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
        calculateTotal();
    };
    const handleInputChange1 = (e, index) => {
        const { name, value } = e.target;
        const list1 = [...dateList];
        list1[index][name] = value;
        setDateList(list1);
    };
    // Handle click event of the "More" button
    const handleAddClick = () => {
        setInputList([...inputList, { text: '' }]);
        setDateList([...dateList, { date: '' }]);
        calculateTotal();
    };
    const handleRemoveField = (index) => {
        const values = [...inputList];
        values.splice(index, 1);
        setInputList(values);
        calculateTotal();
    }; 
    // Function to calculate the total of all input values
    const calculateTotal = () => {
        // Ensure the inputs are converted to numbers before summing
        const total = inputList.reduce((acc, curr) => {
            return acc + (parseFloat(curr.text) || 0); // Convert to float, default to 0 if not a number
        }, 0);
        setRsTotalApproved( parseFloat(total)+ parseFloat(RsCgApproved));
        //return total;
    };*/

    const handleSubmit = async (e) => {
        e.preventDefault();
        var valid_Name = true; //alert(valid_Name);
        // if button enabled with JS hack
        //const v1 = TEXT_REGEX.test(yearaddition);
        if (!TEXT_REGEX.test(RsCgApproved)) {
            setErrRsCgApproved('CG APPROVED IN Rs. is required');
            valid_Name = false; //alert('1');
            //return;
        } else {
            setErrRsCgApproved('');
        }
        if (!TEXT_REGEX.test(AdditionalCgApproved)) {
            setErrAdditionalCgApproved('AdditionalCG APPROVED IN Rs. is required');
            valid_Name = false; //alert('3');
            //return;
        } else {
            setErrAdditionalCgApproved('');
        }
        if ((CgDateOfApproval!="")) {
            setErrCgDateOfApproval('');
            //return;
        } else {
            setErrCgDateOfApproval('Date of Approval IN Rs. is required');
            valid_Name = false;
        }
        /*if (!TEXT_REGEX.test(RsTotalApproved)) {
            setErrRsTotalApproved('Total Approved IN Rs. is required');
            valid_Name = false; //alert('1');
            //return;
        } else {
            setErrRsTotalApproved('');
        }
        
        if (AdditionalDateOfApproval!="") {
            setErrAdditionalDateOfApproval('');
            //return;
        } else { 
            setErrAdditionalDateOfApproval('STPI LOP Date is required');
            valid_Name = false; //alert('3');
        }*/
        
        if (valid_Name) { //alert('validName'); 
            saveData(); //submit form
        } else {
            //alert('valid_Name false');
            return false;
        }
    }
    
    const saveData = function () {
        
        var data = {
            'config_id': bondConfigId,
            'cg_approval_number': RsCgApproved,
            'additional_approved_price': AdditionalCgApproved,
            'cg_approval_date': CgDateOfApproval,
            //'additional_approval_date': dateList.map(item => item['date']).join(','),
            //'cg_total_approved_price': RsTotalApproved,
            'created_by': sessionStorage.getItem('LoggedInUserId'),
            'company_id': sessionStorage.getItem('LoggedInCompanyId'),
            'division_id': sessionStorage.getItem('LoggedInDivisionId'),
        };
        if(CG_ID !== '' && CG_ID !== null){
            data.cg_id=CgId;
        }
        try {
            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': data, 
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData2 = {'token': EncryptData}
            saveCgApproval(oData2).then(function (res) {
                if ((typeof (res.data.errors) !== "undefined") && res.data.errors.errno > 0) {
                    setErrMsg(res.data.message);
                    setSuccess(false);
                } else {
                    setSuccess(res.data.message);
                    setDisabled(true);
                    setErrMsg(false);
                    reset();
                }
            });
        } catch (err) {
            setSuccess(false);
            if (!err?.data) {
                console.log(err);
                setErrMsg('No Server Response');
            } else if (err.data?.status === 409) {
                setErrMsg('Username Taken');
            } else {
                setErrMsg('Insert/Update Failed');
            }
            //elementRef.current.focus();
        }
    }

    return (
        <div className='container' id="single-asset">
            <div className='row' ref={elementRef}>
                <div className="col">
                    <h5>Add CG Approval</h5>
                </div>

                <div className="invalid-feedback" role="alert">
                    <i className="fa fa-info-circle"></i>
                    Please Fill all mandatory field.
                </div>

                {success ? (
                    <div className="alert alert-success" role="alert"><i
                        className="bi bi-check-circle-fill"></i>{success}</div>
                ) : (errMsg ? (<div className="alert alert-danger" role="alert"><i
                        className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>) : ('')
                )}
            </div>
            <form onSubmit={handleSubmit} encType="multipart/form-data" method="post">
                <div className='row m-2'>
                    <div className='col-md-6'>
                        <FromSpFieldText label="CG APPROAL Number" star="*"
                            inputValue={RsCgApproved}
                            setInputValue={setRsCgApproved}
                            setErrorMsg={ErrRsCgApproved}
                        />
                    </div>
                    <div className='col-md-4'>
                    </div>
                </div>
                <div className='row m-2'>
                    <div className='col-md-6'>
                    <FromFieldText label="AdditionalCG APPROVED IN Rs." star="*" 
                            //placeholder="YYYY-MM-DD"
                            inputValue={AdditionalCgApproved}
                            setInputValue={setAdditionalCgApproved}
                            setErrorMsg={ErrAdditionalCgApproved}
                        />
                    </div>
                    <div className='col-md-4'>
                        
                    </div>
                </div>
                <div className='row m-2'>
                    <div className='col-md-6'>
                    <FromFieldDate label="Date of Approval" star="*" 
                            //placeholder="YYYY-MM-DD"
                            inputValue={CgDateOfApproval}
                            setInputValue={setCgDateOfApproval}
                            setErrorMsg={ErrCgDateOfApproval}
                        />
                        
                    </div>
                    <div className='col-md-4'>
                        
                    </div>
                </div>
        {/*  {inputList.map((x, i) => (
          <div className='row m-2' key={i}>
            <div className='col-md-3'>
                <label>Additional CG APPROVED</label><span class="star error"> * </span>
            </div>
            <div className='col-md-3'>
                <input className="form-control"
                name="text"
                placeholder="Enter text"
                value={x.text}
                onChange={(e) => handleInputChange(e, i)}
                />
            </div>
            <div className='col-md-2'>
                <label>Date of Approval</label><span class="star error"> * </span>
            </div>
            <div className='col-md-2'>
                <input type='date' className="form-control"
                name="date"
                placeholder="YYYY-MM-DD"
                value={x.date}
                onChange={(e1) => handleInputChange1(e1, i)}
                />
            </div>
            <div className='col-md-2'>
                <button className="btn btn-primary " type="button" onClick={handleAddClick}>ADD</button>
                {(i > 0)?(
                <button className="btn btn-danger " type="button" onClick={() => handleRemoveField(i)}>Remove</button>
                ):'' }
            </div>
          </div>
        ))}   
                    
                        
                    <div className='row m-2'>
                    <div className='col-md-6'>
                        <FromSpFieldText label="Total Approval in Rs." star="*"
                            inputValue={RsTotalApproved}
                            setInputValue={setRsTotalApproved}
                            setErrorMsg={ErrRsTotalApproved}
                        />
                    </div>
                    <div className='col-md-6'>
                        
                    </div>

                </div>*/}
                <div className='row save__button text-center'>
                <div className='col-md-8'>
                        {(CG_ID !== '' && CG_ID !== null) ? <div>
                                <button className="btn btn-primary btn-md" disabled={disabled}>Update</button>
                                &nbsp;&nbsp;
                                <a href={'/cg-approval-list' } className="btn btn-secondary btn-md">Cancel</a>
                                </div>
                            : <button id="btn-submit" className="btn btn-primary btn-md"
                                      disabled={disabled}> SAVE </button>
                        }

                    </div>
                    
                </div>
            </form>
        </div>
    )
}
