import React, {useEffect, useState} from 'react';
import {DataGrid, GridToolbarContainer, GridToolbarExport} from '@mui/x-data-grid';
//import {getUnitOfMeasureList} from '../../services/master.service'
import {getAllCommentData} from '../../../services/asset.service';

import * as CryptoJS from "crypto-js";
import CommentListPopupBox from "../../../atoms/CommentListPopupBox";

function getFormatedDate(dateString) {
    if(dateString!=null) {
        let dte = new Date(dateString);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        if (month < 10) {
            month = '0' + month;
        }if (day < 10) {
            day = '0' + day;
        }
        return year + '-' + month + '-' + day;
        //return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
    } else {
        return null;
    }
}
function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport/>
        </GridToolbarContainer>
    );
}

function CommentList() {
    const [items, setItems] = useState([]);
    const [tag, setTag] = useState('');
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    const handleConfirmOpen = async (tag) => {
        setTag(tag);
        setIsConfirmOpen(true);
    };

    const handleConfirmClose = () => {
        setIsConfirmOpen(false);
    };

    const columns = [

        {
            "field": "tag", "editable": false, "hide": false, "headerName": "TAG", "width": 350,
            renderCell: (params) => {
                let EncryptID = CryptoJS.AES.encrypt(JSON.stringify({
                    'tag': params.row.tag, 'back_url':'view-comments'
                }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
                return (<a href={`/asset-basic-details?tag=${EncryptID}`}>{params.value}</a>);
            }
        },

        {
            "field": "created_date", "editable": true, "hide": false, "headerName": "Date of Addition", "width": 130,
            renderCell: (params) => (
                <p>{getFormatedDate(params.row.created_date)}</p>
            )
        },
        {
            "field": "view_comment", "editable": false, "hide": false, "headerName": "View Comment List", "width": 180,
            renderCell: (params) => (
                <div>
                    <button class="btn btn-primary btn-md text-right"
                            onClick={() => handleConfirmOpen(params.row.tag)}>View Comment
                    </button>
                </div>
            )
        },

    ];

    useEffect(() => {
        //encrypted data
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            //'param_data':obj,
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token': EncryptData}

        getAllCommentData(oData).then(res => {
            if (res?.data?.data?.length) setItems(res.data.data);
        })
    }, []);


    return (
        <div className='container'>
            <div className='row'>
                <div className='col-md-6'><h5>Additional Comment List</h5></div>

            </div>
            <DataGrid
                rows={items}
                getRowId={(row) => row.id}
                columns={columns}
                pageSize={50}
                autoHeight
                rowsPerPageOptions={[50]}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />

            <CommentListPopupBox
                tag={tag}
                isOpen={isConfirmOpen}
                onClose={handleConfirmClose}
            />

        </div>
    )
}

export default CommentList
