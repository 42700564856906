import React, {useEffect, useRef, useState} from 'react';
import {useLocation} from "react-router-dom";
import FromFieldText from '../../../atoms/FromFieldText';
import * as CryptoJS from "crypto-js";
import FromFieldTextArea from "../../../atoms/FromFieldTextArea";
import {getCostCenterInfo, saveCostCenter} from "../../../services/asset.service";
import {getCompanyProfileList} from '../../../services/user.service';

function AddCostCenter() {
    const TEXT_REGEX = /^[a-zA-Z0-9-_ ]{1,500}$/;

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    const [companyId, setCompanyId] = useState('');
    const [ErrCompanyId, setErrCompanyId] = useState(false);

    const [companies, setCompanies] = useState([]);

    const [costcentercode, setCostCenterCode] = useState('');
    const [ErrCostCenterCode, setErrCostCenterCode] = useState(false);

    const [costcentername, setCostCenterName] = useState('');
    const [ErrCostCenterName, setErrCostCenterName] = useState(false);


    const resetCostCenterInfo = function () {

        setCostCenterCode('');
        setCostCenterName('');
        setCompanyId('');
    }

    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const Tag = query.get('tag');

    const cost_id = query.get('cost_ID');

    useEffect(() => {
        if (cost_id !== '' && cost_id !== null) {
            const decryptedBytes = CryptoJS.AES.decrypt(cost_id.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
            // Convert the decrypted bytes to a string
            const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
            const decryptCostId = decrypt_data.costid;
            getCostInfo(decryptCostId); // get comment info
            //alert(Tag);
        }
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token': EncryptData}

        getCompanyProfileList(oData).then(res => {
            console.log(oData);
            if (res?.data?.data?.length) setCompanies(res.data.data);
        })

    }, [])
    const getCostInfo = function (decryptCostId) {
        resetCostCenterInfo();

        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': {'costid': decryptCostId},
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData1 = {'token': EncryptData}
        getCostCenterInfo(oData1).then(function (res) {
            //getTransferData(Tag).then(function(res){
            if (res?.data?.data) {
                var aInfo = res.data.data[0];
                setCompanyId(aInfo.company_id);
                setCostCenterCode(aInfo.cost_center_code);
                setCostCenterName(aInfo.cost_center_name);


            } else {
                setErrMsg('cost center Not Found');
            }

        })
    }

    const handleCompanyId = (e) => {
        //let name= e.target.name;
        //let value= e.target.value;
        setCompanyId(e.target.value);
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        var valid_Name = true;

        if (!TEXT_REGEX.test(companyId)) { //alert(companyId);
            setErrCompanyId('Company name is required');
            valid_Name = false; //alert(valid_Name);
            //return;
        } else {
            setErrCompanyId('');
        }

        if (!TEXT_REGEX.test(costcentername)) {
            setErrCostCenterName('cost center name is required');
            valid_Name = false;
            //return;
        } else {
            setErrCostCenterName('');
        }

        if (!TEXT_REGEX.test(costcentercode)) {
            setErrCostCenterCode('cost center code is required');
            valid_Name = false;
            //return;
        } else {
            setErrCostCenterCode('');
        }

        if (valid_Name) { //alert('test1');
            saveData(); //submit form
        } else {
            return false;
        }
    }

    const saveData = function () {

        if (cost_id !== '' && cost_id !== null) {
            const decryptedBytes = CryptoJS.AES.decrypt(cost_id.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
            // Convert the decrypted bytes to a string
            const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
            const decryptedYearId = decrypt_data.costid;
            var obj = {
                'cost_id': decryptedYearId,
                'cost_center_code': costcentercode,
                'cost_center_name': costcentername,
                'company_id': companyId,
                'status': '1',
                'created_by': sessionStorage.getItem('LoggedInUserId'),
            }
        }else {
            var obj = {
                'cost_center_code': costcentercode,
                'cost_center_name': costcentername,
                'company_id': companyId,
                'status': '1',
                'created_by': sessionStorage.getItem('LoggedInUserId'),
            }
        }


        //encrypted data
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': obj,
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token': EncryptData}


        try {

            saveCostCenter(oData).then(function (res) {
                //console.log(res);
                if ((typeof (res.data.errors) != "undefined") && res.data.errors.errno > 0) {
                    setErrMsg(res.data.message);
                    setSuccess(false);
                } else {
                    setSuccess(res.data.message);
                    resetCostCenterInfo();
                }
            });
            //setValidName(false);
        } catch (err) {
            setSuccess(false);
            if (!err?.data) {
                console.log(err);
                setErrMsg('No Server Response');
            } else if (err.data?.status === 409) {
                setErrMsg('data already exist');
            } else {
                setErrMsg('Insert/Update Failed');
            }
            //elementRef.current.focus();
        }
    }


    return (
        <div className="container">

            <div className="row">
                <div className="col">
                    <h5>Add Cost center</h5>
                </div>
                <div className="invalid-feedback" role="alert">
                    <i className="fa fa-info-circle"></i>
                    Please Fill all mandatory field.
                </div>

                {success ? (
                    <div className="alert alert-success" role="alert"><i
                        className="bi bi-check-circle-fill"></i>{success}</div>
                ) : (errMsg ? (<div className="alert alert-danger" role="alert"><i
                        className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>) : ('')
                )}
            </div>



            <div className='row'></div>

            <form onSubmit={handleSubmit}>

                <div className="row form-fields">
                    <div className="label col-3">
                        <label>Company </label><span className="star error"> *</span>
                    </div>

                    <div className="item col-3">
                        <select className="form-select form-select-sm mb-3" onChange={handleCompanyId}>
                            <option value="">Select Company</option>
                            {companies && companies.length > 0 ? (
                                companies.map((option) => (
                                    <option value={option.company_id}
                                            selected={(option.company_id == companyId) ? "selected" : ''}>{option.company_name}</option>
                                ))
                            ) : (
                                <option value="">No Data</option>
                            )}
                        </select>
                        <span className="invalid-feedback"> {ErrCompanyId} </span>
                    </div>
                </div>
                <div className="row">

                    <div className="col-6">
                        <FromFieldTextArea star="*"
                                       label="Cost center code"
                                       inputValue={costcentercode}
                                       setInputValue={setCostCenterCode}
                                       setErrorMsg={ErrCostCenterCode}
                        />
                    </div>
                </div>

                <div className="row">

                    <div className="col-6">
                        <FromFieldTextArea star="*"
                                           label="Cost center Name"
                                           inputValue={costcentername}
                                           setInputValue={setCostCenterName}
                                           setErrorMsg={ErrCostCenterName}
                        />
                    </div>
                </div>


                <div className="row">
                    <div className="col-3"></div>
                    <div className="col-6">
                        {(cost_id !== '' && cost_id !== null) ?
                        (<button className="btn btn-primary btn-md"> Update </button>) : <button className="btn btn-primary btn-md"> SAVE </button> }

                    </div>
                    <div className="col-3"></div>
                </div>
            </form>
        </div>
    )
}

export default AddCostCenter


