import React, {useCallback, useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import {DataGrid, GridOverlay, GridToolbarContainer, GridToolbarExport} from '@mui/x-data-grid';
import {getPendingCapitalizedCSV, getPendingCapitalized} from '../../../services/asset.service';
import * as CryptoJS from "crypto-js";
import './AssetInfo.css';
import {FormControl, InputLabel, MenuItem, Pagination, Select} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import FromFieldDate from "../../../atoms/FromFieldDate";
import {Parser} from "json2csv";

Date.prototype.dbFormatedDate = function () {

    let year = this.getFullYear();
    let month = this.getMonth() + 1;
    let day = this.getDate();
    if (month < 10) {
        month = '0' + month;
    }
    if (day < 10) {
        day = '0' + day;
    }
    return year + '-' + month + '-' + day;
    //return `${this.getFullYear()}-${this.getMonth()+1}-${this.getDate()-1}`
}

function getFormatedDate(dateString) {
    if (dateString != null) {
        let dte = new Date(dateString);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }
        return year + '-' + month + '-' + day;
        //return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
    } else {
        return null;
    }
}

const columns = [
    {"field": "sl_no", "hideable": true, "hide": true,  "headerName": "Sl No", "width": 50},

    {
        field: 'tag',
        editable: false,
        hide: false,
        headerName: 'TAG',
        width: 350,
        renderCell: (params) => {
            let encryptedTag = CryptoJS.AES.encrypt(JSON.stringify({
                'tag': params.value,
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

            return (
                <a href={`/assets-capitalization-view?tag=${encryptedTag}`}>{params.value}</a>
            );
        }
    },

    {
        "field": "parent_tag",
        "hideable": true,
        "hide": false,
        "editable": true,
        "headerName": "Parent TAG",
        "width": 350
    },
    {/*} {
        "field": "asset_in_use_date",
        "editable": true,
        "hide": false,
        "headerName": "Date of Capitalization / Asset Ready To Use",
        "width": 130,
        renderCell: (params) => (
            <p>{params.value ? getFormatedDate(params.value) : ''}</p>
        )
    },
    {"field": "cap_type_of_asset", "editable": true, "hide": false, "headerName": "Type of Asset", "width": 130},
    {
        "field": "type_of_depreciation",
        "editable": true,
        "hide": false,
        "headerName": "Type of Depreciation",
        "width": 130
    },
    {
        "field": "depreciation_end_date", "editable": true, "hide": false, "headerName": "End Date", "width": 250,
        renderCell: (params) => (
            <p>{getFormatedDate(params.row.depreciation_end_date)}</p>
        )
    },
    {
        "field": "depreciation_rate",
        "editable": true,
        "hide": false,
        "headerName": "Depreciation Rate (No. of years) ",
        "width": 250
    },
    {
        "field": "accounting_code",
        "editable": true,
        "hide": false,
        "headerName": "Accounting Code of Asset",
        "width": 250
    },
    {
        "field": "accounting_name",
        "editable": true,
        "hide": false,
        "headerName": "Accounting Name of the Asset",
        "width": 250
    },
    {
        "field": "category_name",
        "editable": true,
        "hide": false,
        "headerName": "Financials Classification",
        "width": 250
    },
    {"field": "class_of_asset", "editable": true, "hide": false, "headerName": "Class of Asset", "width": 250},
    {
        "field": "capitalization_flag",
        "editable": true,
        "hide": false,
        "headerName": "Final Asset Capitalization Flag",
        "width": 250
    },
    {"field": "is_tax_capitalized", "editable": true, "hide": false, "headerName": "Is Tax Capitalized", "width": 250},
    {
        "field": "is_100_perc_depreciable",
        "editable": true,
        "hide": false,
        "headerName": "Is 100% Depreciable Asset",
        "width": 250
    },
    {
        "field": "pre_capitalized_asset_value",
        "editable": true,
        "hide": false,
        "headerName": "Pre Capitalized Asset Value",
        "width": 250
    },
    {
        "field": "is_any_other_accg_gaap_applicable",
        "editable": false,
        "hide": false,
        "headerName": "Is any other Accg GAAP Applicable",
        "width": 250
    },
    {
        "field": "igaap_limit_amount",
        "editable": true,
        "hide": false,
        "headerName": "I-GAAP - Limit for 100% Depreciable Asset (Rs)",
        "width": 250
    },
    {
        "field": "othergaap_limit_amount",
        "editable": true,
        "hide": false,
        "headerName": "Other-GAAP - Limit for 100% Depreciable Asset (Rs)",
        "width": 250
    },
    {"field": "salvage_value", "editable": true, "hide": false, "headerName": "Salvage Value", "width": 250},
    {
        "field": "igaap_capitalized_value",
        "editable": true,
        "hide": false,
        "headerName": "Capitalized Value (I-GAAP)",
        "width": 250
    },
    {
        "field": "othergaap_capitalized_value",
        "editable": true,
        "hide": false,
        "headerName": "Capitalized Value (Other GAAP)",
        "width": 250
    },
    {"field": "cap_exchange_rate", "editable": true, "hide": true, "headerName": "exchange rate", "width": 130},
    {"field": "record_start_date", "editable": true, "hide": true, "headerName": "Record Start Date", "width": 130},
    {"field": "put_to_use_date", "editable": true, "hide": true, "headerName": "Put to Use Date", "width": 130},
    */ },
    {"field":"created_at","editable":false,"hide":false ,"headerName":"Created Date","width":180,
        renderCell: (params) => (
            <p>{ getFormatedDate(params.row.created_at)}</p>
        ) },

];

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport/>
        </GridToolbarContainer>
    );
}
const CustomLoader = () => (
    <GridOverlay>
        <CircularProgress color="primary" size={30}/>
    </GridOverlay>
);

function AssetList() {
    const [items, setItems] = useState([]);
    const [itemsCSV, setItemsCSV] = useState([]);

    const [searchTag, setSearchTag] = useState('');
    const [ErrSearchTag, setErrSearchTag] = useState(false);

    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [pageSize, setPageSize] = useState(50);

    const [filterCriteria, setFilterCriteria] = useState('');
    const [ErrFilterCriteria, setErrFilterCriteria] = useState(false);

    const [filterOperator, setFilterOperator] = useState('');
    const [ErrFilterOperator, setErrFilterOperator] = useState(false);

    const [startDate, setStartDate] = useState('');
    const [ErrStartDate, setErrStartDate] = useState(false);

    const [endDate, setEndDate] = useState('');
    const [ErrEndDate, setErrEndDate] = useState(false);

    const [hasData, setHasData] = useState(false);
    const calculateOffset = (page, pageSize) => (page - 1) * pageSize;

    const fetchAssets = useCallback(async () => {
        setLoading(true);

        const offset = calculateOffset(page, pageSize);
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify({
            param_data: { 'offset': offset, 'pageSize': pageSize },
            authToken: sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

        const requestData = {token: encryptedData};

        try {
            const res = await getPendingCapitalized(requestData);
            if (res?.data?.data?.length) {
                setItems(res.data.data);
                setTotalItems(res.data.total || 0);
            }else{
                setItems('');
            }
        } catch (error) {
            console.error('Error fetching assets:', error);
        } finally {
            setLoading(false);
        }
    }, [page, pageSize]);

    const fetchAssets1 = async () => {
        setLoading(true);

        const offset = calculateOffset(page, pageSize);
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify({
            param_data: { 'offset': offset, 'pageSize': pageSize, 'searchTag': searchTag, 'filterCriteria': filterCriteria, 'filterOperator': filterOperator, 'startDate': startDate, 'endDate': endDate },
            authToken: sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

        const requestData = { token: encryptedData };

        try {
            const res = await getPendingCapitalized(requestData);
            if (res?.data?.data?.length) {
                setItems(res.data.data);
                setTotalItems(res.data.total || 0);
            } else {
                setItems('');
            }
        } catch (error) {
            console.error('Error fetching assets:', error);
        } finally {
            setLoading(false);
        }
    };

// Call this function whenever you need to fetch assets, such as inside a useEffect hook or in response to an event


    const fetchAssetsCSV = useCallback(async () => {
        setLoading(true);

        const encryptedData = CryptoJS.AES.encrypt(
            JSON.stringify({authToken: sessionStorage.getItem('LoggedInToken')}),
            process.env.REACT_APP_ENCRYPT_SECRET_KEY
        ).toString();

        const requestData = {token: encryptedData};

        try {
            const resCSV = await getPendingCapitalizedCSV(requestData);
            if (resCSV?.data?.data?.length) {
                setItemsCSV(resCSV.data.data);
                setHasData(true);
            }else{
                setHasData(false);
            }
        } catch (error) {
            console.error('Error fetching assets CSV:', error);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchAssets();
        //fetchAssets1();
    }, [fetchAssets]);

    useEffect(() => {
        if (itemsCSV.length > 0) {
            generateCSV();
        }
    }, [itemsCSV]);

    const handlePageSizeChange = (value) => {
        setPageSize(value);
        setPage(1);
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const totalPages = Math.ceil(totalItems / pageSize);

    const handleExportToCSV = async () => {
        await fetchAssetsCSV();
        if (hasData) {
            generateCSV();
        }
    };

    const generateCSV = () => {
        try {
            const jsonData = itemsCSV.map(item => ({
                tag: item.tag ? item.tag : '',
                parent_tag: item.parent_tag ? item.parent_tag : '',
                asset_in_use_date: item.asset_in_use_date ? getFormatedDate(item.asset_in_use_date) : '',
                cap_type_of_asset: item.cap_type_of_asset ? item.cap_type_of_asset : '',
                depreciation_end_date: item.depreciation_end_date ? getFormatedDate(item.depreciation_end_date) : '',
                accounting_code: item.accounting_code ? item.accounting_code : '',
                accounting_name: item.accounting_name ? item.accounting_name : '',
                financials_classification: item.financials_classification ? item.financials_classification : '',
                class_of_asset: item.class_of_asset ? item.class_of_asset : '',
                is_tax_capitalized: item.is_tax_capitalized ? item.is_tax_capitalized : '',
                pre_capitalized_asset_value: item.pre_capitalized_asset_value ? item.pre_capitalized_asset_value : '',
                is_any_other_accg_gaap_applicable: item.is_any_other_accg_gaap_applicable ? item.is_any_other_accg_gaap_applicable : '',
                igaap_limit_amount: item.igaap_limit_amount ? item.igaap_limit_amount : '',
                othergaap_limit_amount: item.othergaap_limit_amount ? item.othergaap_limit_amount : '',
                salvage_value: item.salvage_value ? item.salvage_value : '',
                othergaap_capitalized_value: item.othergaap_capitalized_value ? item.othergaap_capitalized_value : '',
                cap_exchange_rate: item.cap_exchange_rate ? item.cap_exchange_rate : '',
                put_to_use_date: item.put_to_use_date ? getFormatedDate(item.put_to_use_date) : '',
                created_at: item.created_at ? getFormatedDate(item.created_at) : '',
            }));

            const json2csvParser = new Parser();
            const csv = json2csvParser.parse(jsonData);

            const blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Capitalized_List.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error exporting data to CSV:', error);
        }
    };

    const handleSearch = () => {
        let isValid = true;
        if (!filterCriteria) {
            setErrFilterCriteria('Please select a filter criteria');
            isValid = false;
        } else {
            setErrFilterCriteria('');
        }

        if (filterCriteria !== 'created_at' && !filterOperator) {
            setErrFilterOperator('Please select a filter operator');
            isValid = false;
        } else {
            setErrFilterOperator('');
        }

        if (!searchTag && filterCriteria != 'created_at' ) {
            setErrSearchTag('Please enter a search text');
            isValid = false;
        }else{
            setErrSearchTag('');
        }
        if (!startDate && filterCriteria == 'created_at' ) {
            setErrStartDate('Please select start date');
            isValid = false;
        }else{
            setErrStartDate('');
        }
        if (!endDate && filterCriteria == 'created_at' ) {
            setErrEndDate('Please select end date');
            isValid = false;
        }else{
            setErrEndDate('');
        }

        if (isValid) {
            setPage(1);
            fetchAssets1();
        }

    };

    // Define options and values
    const options = [
        "Year Of Addition", "Type of Addition", "PO Number", "Supplier Name", "Invoice Number", "Asset Name", "Make",
        "Model Number", "Asset Serial Number", "Part Number", "TAG Number", "TAG Name", "Nature of Presence", "Date Range"
    ];

    const values = [
        "year_of_addition", "type_of_addition", "po_no", "supplier_name", "invoice_no", "asset_name", "manufacturer", "model_no", "asset_serial_no", "part_no", "tag", "tag_name", "nature_of_presence", "created_at"
    ];

    // Combine options and values into an array of objects
    const combinedOptions = options.map((option, index) => ({
        label: option,
        value: values[index]
    }));

    // Handle change event
    const handleSelectChange = (e) => {
        const selectedOption = combinedOptions.find(option => option.value === e.target.value);
        setFilterCriteria(selectedOption ? selectedOption.value : '');
        setErrFilterCriteria(''); // Reset error message on change
    };

    const optionsFilter = [
        "Contains", "Equals", "Not Contains", "Not Equals", "Blank", "Non Blank"
    ];

    const valuesFilter = [
        "LIKE", "=", "NOT LIKE", "!=", "IS NULL", "IS NOT NULL"
    ];

    // Combine options and values into an array of objects
    const combinedOptionsFilter = optionsFilter.map((option, index) => ({
        label: option,
        value: valuesFilter[index]
    }));

    // Handle operator change event
    const handleOperatorChange = (e) => {
        setFilterOperator(e.target.value);
        setErrFilterOperator(''); // Reset error message on change
    };
    const handelSearchText = (e) => {
        setSearchTag(e.target.value);
    };

    return (
        <div className='container'>


            <div className='row'>
                <div className='col-md-8'><h5>Pending for Capitalization</h5></div>
                <div className='col-md-4'><a href='/assets-capitalized'
                                             className='btn btn-info btn-sm btn-sm'>Capitalized</a> <a
                    href='/assets-notcapitalized' className='btn btn-info btn-sm btn-sm'>Not Capitalized</a></div>
            </div>

            <div className='filter-container' id="single-asset">
                <div className='row m-2'>

                    <div className="col-md-3">
                        <div className="form-group">
                            <label>
                                Select Criteria <span className="star error">*</span>
                            </label>
                            <select
                                className="form-control"
                                value={filterCriteria}
                                onChange={handleSelectChange}
                            >
                                <option value="" disabled>Select Filter Criteria</option>
                                {combinedOptions.map((option, index) => (
                                    <option key={index} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                            {ErrFilterCriteria && <span className="invalid-feedback">{ErrFilterCriteria}</span>}
                        </div>
                    </div>

                    {filterCriteria != 'created_at' && (
                        <div className="col-md-3">
                            <div className="form-group">
                                <label>
                                    Select Operator <span className="star error">*</span>
                                </label>
                                <select
                                    className="form-control"
                                    value={filterOperator}
                                    onChange={handleOperatorChange}
                                >
                                    <option value="" disabled>Select Operator</option>
                                    {combinedOptionsFilter.map((option, index) => (
                                        <option key={index} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                                {ErrFilterOperator && <span className="invalid-feedback">{ErrFilterOperator}</span>}
                            </div>
                        </div>
                    )}

                    {filterCriteria != 'created_at' && (
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>
                                    Enter Criteria <span className="star error">*</span>
                                </label>

                                <input
                                    type="text" className="form-control"
                                    placeholder="Search..."
                                    inputValue={searchTag}
                                    //setInputValue={setSearchTag}
                                    onChange={handelSearchText}
                                    style={{marginRight: '10px'}}
                                />
                                {ErrSearchTag && <span className="invalid-feedback">{ErrSearchTag}</span>}
                            </div>
                        </div>
                    )}
                </div>

                {filterCriteria === 'created_at' && (
                    <div className="row m-2">
                        <div className="col-6">

                            <FromFieldDate
                                label="Start Date"
                                placeholder="Select Start Date"
                                inputValue={startDate}
                                setInputValue={setStartDate}
                                setErrorMsg={ErrStartDate}
                            />

                        </div>
                        <div className="col-6">

                            <FromFieldDate
                                label="End Date"
                                placeholder="Select End Date"
                                inputValue={endDate}
                                setInputValue={setEndDate}
                                setErrorMsg={ErrEndDate}
                            />

                        </div>
                    </div>
                )}
                <div className="row mt-2">
                    <div className="col-6 text-center">
                        <button onClick={handleSearch} className='btn btn-info btn-md'>
                            <i className="bi bi-search"></i> Search
                        </button>

                    </div>
                    <div className="col-6 text-center">
                        <button
                            onClick={handleExportToCSV}
                            className='btn btn-primary btn-md'
                            disabled={loading}
                        >
                            <i className="bi bi-download"></i> {loading ? 'Exporting...' : (hasData ? 'Export All Data to CSV' : 'Export All Data to CSV')}
                        </button>
                    </div>
                </div>
            </div>

            <Box>
                <DataGrid
                    rows={items}
                    getRowId={(row) => row.sl_no}
                    columns={columns}
                    autoHeight
                    components={{
                        LoadingOverlay: CustomLoader,
                        Toolbar: CustomToolbar,
                        Pagination: () => (
                            <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2}}>
                                <Box sx={{mr: 4}}>
                                    Showing {items.length} records out of {totalItems}
                                </Box>
                                <FormControl variant="outlined" sx={{minWidth: 120}}>
                                    <InputLabel>Rows per page</InputLabel>
                                    <Select
                                        value={pageSize}
                                        onChange={(event) => handlePageSizeChange(Number(event.target.value))}
                                        label="Rows per page"
                                    >
                                        {[50, 100, 200, 500].map((size) => (
                                            <MenuItem key={size} value={size}>
                                                {size}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <Pagination
                                    count={totalPages}
                                    page={page}
                                    onChange={handlePageChange}
                                    variant="outlined"
                                    shape="rounded"
                                />
                            </Box>
                        ),
                    }}
                    loading={loading}
                />
            </Box>

        </div>
    )
}

export default AssetList
