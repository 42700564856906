import React,{useState,useEffect} from 'react'
import { useLocation } from "react-router-dom";
import { getTransferData, saveTransferData } from '../../../services/asset.service';
import FromFieldLabel from '../../../atoms/FromFieldLabel';
const getFormatedDate = function(dateString) {
    if(dateString!=null) {
        let dte = new Date(dateString);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        if (month < 10) {
          month = '0' + month;
        }if (day < 10) {
          day = '0' + day;
        }
        return day + '-' + month + '-' + year;
        //return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
    } else {
        return null;
    }   
  }

function InternalTransfer() {
    const [tag, setTag] = useState(0);
    const [assetInfo, setAssetInfo] = useState({});
    const [division,setDivision] = useState('');
    const [premises,setPremises] = useState('');
    const [floor,setFloor] = useState('');
    const [siteId,setSiteId] = useState('');
    const [userType,setUserType] = useState('');
    const [userDepartment,setUserDepartment] = useState('');
    const [employeeId,setEmployeeId] = useState('');
    const [employeeName,setemployeeName] = useState('');
    const [movementInitiatedBy,setMovementInitiatedBy] = useState('');
    const [initiationDate,setInitiationDate] = useState('');
    const [movementApprovedBy,setMovementApprovedBy] = useState('');
    const [approvalDate,setApprovalDate] = useState('');
    const [movementReason,setMovementReason] = useState('');

    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const Tag = query.get('tag'); 
    
    useEffect(() => {
        if(Tag!=='' && Tag!==null){
            getTagAssetInfo(Tag);
            //alert(Tag);
        }
        
    }, [])
    const getTagAssetInfo = function() {
        var data1= {
            'tag':Tag,
            'company_id':sessionStorage.getItem('LoggedInCompanyId'),
            'division_id':sessionStorage.getItem('LoggedInDivisionId'),
          }
        getTransferData(data1).then(function(res){
            if(res?.data?.data) {
                var Info = res.data.data; 
                console.log(Info);
                setAssetInfo(Info);                
            }
        });
    }
    const getTagInfo = function() {
        var data1= {
            'tag':tag,
            'company_id':sessionStorage.getItem('LoggedInCompanyId'),
            'division_id':sessionStorage.getItem('LoggedInDivisionId'),
          }
        getTransferData(data1).then(function(res){
            if(res?.data?.data) {
                var Info = res.data; 
                console.log(Info);
                setAssetInfo(Info);                
            }
        });
    }

    const handleSubmit = async (e) => {
		e.preventDefault();
        
        
        saveData(); //submit form
        
    }

    const saveData = function() {
        
        var obj = {
            'division' : division,
            'premises' : premises,
            'floor' : floor,
            'site_id' : siteId,
            'user_type' : userType,
            'user_department' : userDepartment,
            'employee_id' : employeeId,
            'employee_name' : employeeName,
            'movement_initiated_by' : movementInitiatedBy,
            'initiation_date' : initiationDate?.dbFormatedDate(),
            'movement_approved_by' : movementApprovedBy,
            'approval_date' : approvalDate?.dbFormatedDate(),
            'movement_reason' : movementReason,
            'tag' : tag 
        }
        saveTransferData(obj).then(function(res) {
            console.log(res);
        })
    }
    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h5>Single Asset Internal Movement</h5>
                </div>
            </div>
            {
            (Tag!='' && Tag!=null) ? <div className='row'><div className='col-10'></div> <div className='col-2'><a href={'/internal-transfer?mode=edit&tag='+ Tag }><button  className='btn btn-info'>Edit</button></a></div></div> : <div className='row'><div className='col'><h5 className='align-right'>TAG </h5></div>
            <div className='col'><input onChange={function(event){setTag(event.target.value)}} inputValue={assetInfo.tag} type="text" className="form-control"  placeholder='Enter the asset TAG'/></div>
            <div className='col'></div></div>
            }
            <div className='row'>
                <div className='col'><h5 className='align-right'>&nbsp; </h5></div>
            </div>
            {/*<div className='row'>
                <div className='col'><h5 className='align-right'>TAG </h5></div>
                <div className='col'><input onChange={function(event){setTag(event.target.value)}} type="text" value={Tag} className="form-control"  placeholder='Enter the asset TAG'/></div>
                <div className='col'><button onClick={getTagInfo} className='btn btn-primary'> Search </button></div>
            </div>*/}
            <div className="row">
            <div className="col-6">
                    <FromFieldLabel  
                        label="Tag" 
                        inputValue={assetInfo.tag}
                        /> 
                </div>
                <div className="col-6">&nbsp;</div>
                <div className="col-6">
                    <FromFieldLabel  
                        label="Financial Classification" 
                        inputValue={assetInfo.financials_classification}
                        /> 
                </div>
                <div className="col-6">
                    <FromFieldLabel
                        label="Invoice Date" 
                        inputValue={getFormatedDate(assetInfo.invoice_date)}
                        /> 
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <FromFieldLabel  
                        label="Supplier Name" 
                        inputValue={assetInfo.supplier_name}
                        /> 
                </div>
                <div className="col-6">
                    <FromFieldLabel
                        label="Supplier Invoice No" 
                        inputValue={assetInfo.invoice_no} 
                        /> 
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <FromFieldLabel  
                        label="Current Division" 
                        inputValue={assetInfo.division}
                        /> 
                </div>
                <div className="col-6">
                    <FromFieldLabel
                        label="Current Premises" 
                        inputValue={assetInfo.premises} 
                        /> 
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <FromFieldLabel  
                        label="Current Floor" 
                        inputValue={assetInfo.floor}
                        /> 
                </div>
                <div className="col-6">
                    <FromFieldLabel
                        label="Current Site ID" 
                        inputValue={assetInfo.site_id} 
                        /> 
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <FromFieldLabel  
                        label="Current User Type" 
                        inputValue={assetInfo.user_type}
                        /> 
                </div>
                <div className="col-6">
                    <FromFieldLabel
                        label="Current User Department" 
                        inputValue={assetInfo.user_department} 
                        /> 
                </div>
            </div>
            <div className="row bottom-border">
                <div className="col-6">
                    <FromFieldLabel  
                        label="Current Employee ID" 
                        inputValue={assetInfo.employee_id}
                        /> 
                </div>
                <div className="col-6">
                    <FromFieldLabel
                        label="Current Employee Name" 
                        inputValue={assetInfo.employee_name} 
                        /> 
                </div>
            </div>
            <form onSubmit={handleSubmit} > 
            <div className="row">
                <div className="col-6">
                    <FromFieldLabel  
                        label="Movement Initiated By" 
                        //placeholder="Movement Initiated By" 
                        inputValue={assetInfo.movement_initiated_by}
                        //setInputValue={setMovementInitiatedBy}
                        /> 
                </div>
                <div className="col-6">
                    <FromFieldLabel
                        label="Date of Initiation" 
                        inputValue={assetInfo.initiation_date}
                        //setInputValue={setInitiationDate} 
                        /> 
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <FromFieldLabel  
                        label="Movement Approved By" 
                        //placeholder="Movement Approved By" 
                        inputValue={assetInfo.movement_approved_by}
                        //setInputValue={setMovementApprovedBy}
                        /> 
                </div>
                <div className="col-6">
                    <FromFieldLabel
                        label="Date of Approval" 
                        inputValue={assetInfo.approval_date}
                        //setInputValue={setApprovalDate} 
                        /> 
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <FromFieldLabel  
                        label="Reason For Movement" 
                        //placeholder="Reason For Movement" 
                        inputValue={assetInfo.movement_reason}
                        //setInputValue={setMovementReason}
                        /> 
                </div>
                <div className="col-6">
                    
                </div>
            </div>
            
            <div className="row">
                <div className="col-6"></div>
                <div className="col-6">
                
                 
                </div>
            </div>
            </form>
        </div>
    )
}

export default InternalTransfer


