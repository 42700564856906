import React, { useState, useEffect } from 'react';



function GSTEvaluation() {

    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h5>GST Evaluation</h5>
                </div>
            </div>

            <div className='row mt-4'>
                <div className='table-responsive'>
                    <table className='table-bordered w-100 text-center'>
                        <thead>
                            <tr>
                                <td style={{ fontWeight: 700, color: '#fff', background: '#000531', padding: '5px' }}>DIN</td>
                                <td style={{ fontWeight: 700, color: '#fff', background: '#000531', padding: '5px' }}>TAG</td>
                                <td style={{ fontWeight: 700, color: '#fff', background: '#000531', padding: '5px' }}>Final Sale Price W/o GST</td>
                                <td style={{ fontWeight: 700, color: '#fff', background: '#000531', padding: '5px' }}>Type of GST</td>
                                <td style={{ fontWeight: 700, color: '#fff', background: '#000531', padding: '5px' }}>Rate</td>
                                <td style={{ fontWeight: 700, color: '#fff', background: '#000531', padding: '5px' }}>Output IGST</td>
                                <td style={{ fontWeight: 700, color: '#fff', background: '#000531', padding: '5px' }}>Output CGST</td>
                                <td style={{ fontWeight: 700, color: '#fff', background: '#000531', padding: '5px' }}>Output SGST</td>
                                <td style={{ fontWeight: 700, color: '#fff', background: '#000531', padding: '5px' }}>Total Sale Price</td>
                                <td style={{ fontWeight: 700, color: '#fff', background: '#000531', padding: '5px' }}>Total Output Tax</td>
                                <td style={{ fontWeight: 700, color: '#fff', background: '#000531', padding: '5px' }}>Total Input Tax Availed</td>
                                <td style={{ fontWeight: 700, color: '#fff', background: '#000531', padding: '5px' }}>Useful Life of the Fixed Asset (Months)</td>
                                <td style={{ fontWeight: 700, color: '#fff', background: '#000531', padding: '5px' }}>Period of Fixed Asset Used (Months)</td>
                                <td style={{ fontWeight: 700, color: '#fff', background: '#000531', padding: '5px' }}>Unused Period of Fixed Asset (Months)</td>
                                <td style={{ fontWeight: 700, color: '#fff', background: '#000531', padding: '5px' }}>Tax on Pro Rate basis for unused</td>
                                <td style={{ fontWeight: 700, color: '#fff', background: '#000531', padding: '5px' }}>Evaluation</td>
                                <td style={{ fontWeight: 700, color: '#fff', background: '#000531', padding: '5px' }}>Comment</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td></td>
                                <td></td>
                                <td>60000</td>
                                <td>IGST / CGST SGST</td>
                                <td>18</td>
                                <td></td>
                                <td>5,400.00</td>
                                <td>5,400.00</td>
                                <td>70,800.00</td>
                                <td>10,800.00</td>
                                <td>18,000.00</td>
                                <td>60</td>
                                <td>26</td>
                                <td>34</td>
                                <td>10,200.00</td>
                                <td>Total Output Tax "Greater Than" Tax on Pro Rate basis for unused</td>
                                <td>GST is payable as per Sale Value</td>
                            </tr>
                            <tr>
                                <td colSpan={13}></td>
                                <td>Periods i.e 40 months</td>
                                <td>(18000/60*40)</td>
                                <td>Total Output Tax "Less Than" Tax on Pro Rate basis for unused</td>
                                <td>GST is pavable as per valuation Rule</td>

                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            
            <div className='row mt-4'>
                <div className='col-12 text-center'>
                    <button type='button' className='btn btn-primary'>PRINT EVALUATION</button>
                </div>
            </div>





        </div>
    )
}

export default GSTEvaluation;