import React,{useState,useEffect,useRef} from 'react';
import { useLocation } from "react-router-dom";
import FromFieldText from '../../atoms/FromFieldText';
import { saveUnitOfMeasure, getUnitOfMeasureInfo } from '../../services/master.service';
import  * as CryptoJS  from "crypto-js";
import {getCompanyProfileList} from "../../services/user.service";
const TEXT_REGEX = /^[a-zA-Z0-9-_ ]{1,255}$/;
//const Email_REGEX = /^[a-zA-Z0-9@.-_ ]{1,255}$/;
//const Mobile_REGEX = /^[0-9]{10,10}$/;

function AddUnitOfMeasure() {

    //const [categoryInfo, setCategoryInfo] = useState({});
    //const [validName, setValidName] = useState(true);
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
    const [companyId,setCompanyId] = useState('');
    const [ErrCompanyId, setErrCompanyId] = useState(false);
    const [unitofmeasure,setUnitOfMeasure] = useState('');
    const [ErrUnitOfMeasure, setErrUnitOfMeasure] = useState(false);
    const [companies, setCompanies] = useState([]);
    const resetUserInfo = function() {
        setUnitOfMeasure('');
        setCompanyId('');
    }

    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const unitofmeasureid = query.get('unitofmeasureid');
    const elementRef = useRef();
    useEffect(()=>{
        if(unitofmeasureid!='' && unitofmeasureid!=null){
            const decryptedBytes = CryptoJS.AES.decrypt(unitofmeasureid.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
            // Convert the decrypted bytes to a string
            const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
            const decryptedRoleid = decrypt_data.unitofmeasure_id;
            getTagDivisionInfo(decryptedRoleid);
            //alert(Tag);
        }

        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            //'param_data':obj,
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token':EncryptData}
        getCompanyProfileList(oData).then(res=>{
            if(res?.data?.data?.length) setCompanies(res.data.data);
        })
    },[])

    const getTagDivisionInfo = function(unitofmeasureid) {
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': {'unitofmeasureid': unitofmeasureid},
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData1 = {'token': EncryptData};
        getUnitOfMeasureInfo(oData1).then(function(res) {
            console.log(res.data.data);

            if (res?.data?.data && res.data.data.length > 0) {
                var tagInfo = res.data.data[0];
                // Check if the 'company_id' property exists before setting the state
                if ('company_id' in tagInfo) {
                    setCompanyId(tagInfo.company_id);
                }
                // Check if the 'tag_division_name' property exists before setting the state
                if ('tag_unit_of_measure_name' in tagInfo) {
                    setUnitOfMeasure(tagInfo.tag_unit_of_measure_name);
                }
            } else {
                // Handle the case where the API response does not contain the expected data
                console.error("Invalid API response format");
            }
        }).catch(function(error) {
            // Handle API request errors here
            console.error("Error fetching data from API: ", error);
        });
    };

    const handleCompanyId = (e) => {
        //let name= e.target.name;
        //let value= e.target.value;
        setCompanyId(e.target.value);
    }
    const handleSubmit = async (e) => {
        e.preventDefault(); var valid_Name=true;
        // if button enabled with JS hack
        //const v1 = TEXT_REGEX.test(name);
        if (!TEXT_REGEX.test(companyId)) { //alert(name);
            setErrCompanyId('Company name is required'); valid_Name=false; //alert(valid_Name);
            //return;
        }else{ setErrCompanyId('');  }
        if (!TEXT_REGEX.test(unitofmeasure)) { //alert(name);
            setErrUnitOfMeasure('Unit of measure name is required'); valid_Name=false; //alert(valid_Name);
            //return;
        }else{ setErrUnitOfMeasure('');  }
        if (valid_Name) { //alert('test1');
            saveData(); //submit form
        }else{  //alert('fa');
            return false;
        }
    }

    const saveData = function() {
        try {
            if (unitofmeasureid !== '' && unitofmeasureid !== null) {
                const decryptedBytes = CryptoJS.AES.decrypt(unitofmeasureid.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
                // Convert the decrypted bytes to a string
                const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
                const decryptedUnitID = decrypt_data.unitofmeasure_id;
                var objParams = {
                    'unit_id': decryptedUnitID,
                    'tag_unit_of_measure_name' : unitofmeasure,
                    'company_id' : companyId,
                    'status' : '1',
                    'created_by' : sessionStorage.getItem('LoggedInUserId'),
                }
            }else {
                var objParams = {
                    'tag_unit_of_measure_name' : unitofmeasure,
                    'company_id' : companyId,
                    'status' : '1',
                    'created_by' : sessionStorage.getItem('LoggedInUserId'),
                }
            }

            //encrypted data
            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data':objParams,
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData = {'token':EncryptData}

            saveUnitOfMeasure(oData).then(function(res) {
                //console.log(res);
                if((typeof(res.data.errors)!= "undefined") && res.data.errors.errno>0){ setErrMsg(res.data.message);setSuccess(false);
                }else{
                    setSuccess(res.data.message); resetUserInfo();
                }
            });

            //setValidName(false);
        } catch (err) { setSuccess(false);
            if (!err?.data) {
                console.log(err);
                setErrMsg('No Server Response');
            } else if (err.data?.status === 409) {
                setErrMsg('data already exist');
            } else {
                setErrMsg('Insert/Update Failed');
            }
            //elementRef.current.focus();
        }
    }

    return (
        <div className="container">

            <div className="row">
                <div className="col-6">
                    <h5>Add Unit Of Measure</h5>
                </div>
                <div className="invalid-feedback" role="alert">
                    <i className="fa fa-info-circle"></i>
                    Please Fill all mandatory field.
                </div>

                {success ? (
                    <div className="alert alert-success" role="alert"><i className="bi bi-check-circle-fill"></i>{success}</div>
                ) : ( errMsg ? (<div className="alert alert-danger" role="alert"><i className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>):('')
                ) }
            </div>

            <div className='row '>
                <div className='col-12'>&nbsp;</div>

            </div>
            <form onSubmit={handleSubmit} >
                <div className="row form-fields">
                    <div className="label col-3">
                        <label>Company </label><span className="star error"> *</span>
                    </div>

                    <div className="item col-3">
                        <select className="form-select form-select-sm mb-3" onChange={handleCompanyId}>
                            <option value="">Select Company</option>
                            {companies && companies.length > 0 ? (
                                companies.map((option) => (
                                    <option value={option.company_id} selected={(option.company_id == companyId)? "selected" : ''}>{option.company_name}</option>
                                ))
                            ) : (
                                <option value="">No Data</option>
                            )}
                        </select>
                        <span className="invalid-feedback"> {ErrCompanyId} </span>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <FromFieldText  star="*" label="Unit Of Measure Name" inputValue={unitofmeasure} setInputValue={setUnitOfMeasure} setErrorMsg={ErrUnitOfMeasure}
                        />
                    </div>
                    <div className="col-6"></div>

                </div>

                <div className="row">
                    <div className="col-3"></div>
                    <div className="col-6">
                        <button className="btn btn-primary  btn-sm" > SAVE </button>
                    </div>
                    <div className="col-3"></div>
                </div>
            </form>
        </div>
    )
}

export default AddUnitOfMeasure


