import React, { useState, useEffect } from 'react';

function Confirm() {
    const myStyle = {
        fontWeight: 700,
        color: '#fff',
        background: '#000531',
        padding: '5px'
    }
  
    return (
        <div className='container'>
            <h3>
                <h2> Confirm</h2>

            </h3>

            <div className='row mt-5'>
                <div className='table-responsive'>
                    <table className='table-bordered w-100 text-center'>
                        <thead>
                            <tr>
                                <td style={myStyle}>TIN No</td>
                                <td style={myStyle}>Date of TIN</td>
                                <td style={myStyle}>Status</td>
                                <td style={myStyle}>Confirmation</td>
                            </tr>
                        </thead>
                        <tbody>
                           <tr>
                            <td></td>
                            <td></td>
                            <td>Pending</td>
                            <td style={{padding:'10px'}}><button type="button" className="btn btn-primary btn-md">View</button></td>
                           </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    )
}

export default Confirm


