import axios from "axios";
var baseURL = process.env.REACT_APP_API_BASE_URL;
var apis = {
    'getMyIRAssignmentList' : `${baseURL}repair/getmyirassignmentlist`,
    'getIRAssignmentReportList' : `${baseURL}repair/getirassignmentreportlist`,
    'getERAssignmentReportList' : `${baseURL}repair/geterassignmentreportlist`,
}

export function getMyIRAssignmentList(data) {
    //return axios.get(`${apis.getCompanyProfileList}`);
    return axios.put(apis.getMyIRAssignmentList,data);
}
export function getIRAssignmentReportList(data) {
    //return axios.get(`${apis.getCompanyProfileList}`);
    return axios.put(apis.getIRAssignmentReportList,data);
}
export function getERAssignmentReportList(data) {
    //return axios.get(`${apis.getCompanyProfileList}`);
    return axios.put(apis.getERAssignmentReportList,data);
}

