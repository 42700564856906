import React,{useState,useEffect,useRef} from 'react';
import { useLocation } from "react-router-dom";
import { getTemproryTransferData, saveTemproryTransferData } from '../../../services/asset.service';
import FromFieldLabel from '../../../atoms/FromFieldLabel';
import FromFieldText from '../../../atoms/FromFieldText';
import FromFieldDate from '../../../atoms/FromFieldDate';
import FromFieldSelect from '../../../atoms/FromFieldSelect';

const TEXT_REGEX = /^[a-zA-Z0-9-_ ]{1,23}$/;
const NUMBER_REGEX = /^[0-9]{1,15}$/;
const getFormatedDate = function(dateString) {
    if(dateString!=null) {
        let dte = new Date(dateString);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        if (month < 10) {
          month = '0' + month;
        }if (day < 10) {
          day = '0' + day;
        }
        return day + '-' + month + '-' + year;
        //return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
    } else {
        return null;
    }   
  }

function TemproryTransfer() {
    const [tag, setTag] = useState(0);
    const [assetInfo, setAssetInfo] = useState({
        "financials_classification": "",
        "invoice_date": "",
        "supplier_name": "",
        "invoice_no": ""
    });

    var transferId = '';
    const errRef = useRef();
    const [validName, setValidName] = useState(true);
    const [disabled, setDisabled] = useState(false);
    const [transferInitiatedBy, setTransferInitiatedBy] = useState('');
    const [ErrTransferInitiatedBy, setErrTransferInitiatedBy] = useState('');
    const [initiationDate, setInitiationDate] = useState('');
    const [ErrInitiationDate, setErrInitiationDate] = useState('');
    const [transferApprovedBy, setTransferApprovedBy] = useState('');
    const [ErrTransferApprovedBy, setErrTransferApprovedBy] = useState('');
    const [approvalDate, setApprovalDate] = useState('');
    const [ErrApprovalDate, setErrApprovalDate] = useState('');
    const [transferType, setTransferType] = useState('');
    const [ErrTransferType, setErrTransferType] = useState('');
    const [serviceProviderName, setServiceProviderName] = useState('');
    const [ErrServiceProviderName, setErrServiceProviderName] = useState('');
    const [address, setAddress] = useState('');
    const [ErrAddress, setErrAddress] = useState('');

    const [contactPersonName, setContactPersonName] = useState('');
    const [ErrContactPersonName, setErrContactPersonName] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [ErrContactNumber, setErrContactNumber] = useState('');
    const [mailId, setMailId] = useState('');
    const [ErrMailId, setErrMailId] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [ErrMobileNumber, setErrMobileNumber] = useState('');

    const [serviceUnder, setServiceUnder] = useState('');
    const [ErrServiceUnder, setErrServiceUnder] = useState('');
    const [expectedReturnDate, setExpectedReturnDate] = useState('');
    const [ErrExpectedReturnDate, setErrExpectedReturnDate] = useState('');

    const [actualReturnDate, setActualReturnDate] = useState('');
    const [ErrActualReturnDate, setErrActualReturnDate] = useState('');
    const [invoiceNumber, setInvoiceNumber] = useState('');
    const [ErrInvoiceNumber, setErrInvoiceNumber] = useState('');
    const [invoiceAmount, setInvoiceAmount] = useState('');
    const [ErrInvoiceAmount, setErrInvoiceAmount] = useState('');
    const [invoiceDate, setInvoiceDate] = useState('');
    const [ErrInvoiceDate, setErrInvoiceDate] = useState('');
    const [receiptRemark, setReceiptRemark] = useState('');
    const [ErrReceiptRemark, setErrReceiptRemark] = useState('');
    const [receiptDate, setReceiptDate] = useState('');
    const [ErrReceiptDate, setErrReceiptDate] = useState('');
    const [recivedBy, setRecivedBy] = useState('');
    const [ErrRecivedBy, setErrRecivedBy] = useState('');

    const [employeeId, setEmployeeId] = useState('');
    const [ErrEmployeeId, setErrEmployeeId] = useState('');
    const [employeeName, setEmployeeName] = useState('');
    const [ErrEmployeeName, setErrEmployeeName] = useState('');
    // const [expectedReturnDate,setExpectedReturnDate] = useState('');
    const [extensionDate, setExtensionDate] = useState('')
    const [ErrExtensionDate, setErrExtensionDate] = useState('');
    const [extendedReturnDate, setExtendedReturnDate] = useState('')
    const [ErrExtendedReturnDate, setErrExtendedReturnDate] = useState('');
    const [extensionReason, setExtensionReason] = useState('')
    const [ErrExtensionReason, setErrExtensionReason] = useState('');
    const [extensionApprovedBy, setExtensionApprovedBy] = useState('')
    const [ErrExtensionApprovedBy, setErrExtensionApprovedBy] = useState('');
    const [remarks, setRemarks] = useState('')
    const [ErrRemarks, setErrRemarks] = useState('');

    const [movementReason, setMovementReason] = useState('');

    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const Tag = query.get('tag');

    useEffect(() => {
        if (Tag !== '' && Tag !== null) {
            getTagAssetInfo(Tag);
            //alert(Tag);
        }

    }, [])

    const getTagAssetInfo = function () {
        getTemproryTransferData(Tag).then(function (res) {
            if (res?.data?.data) {
                let data = res.data.data;
                setAssetInfo({
                    "financials_classification": data?.financials_classification,
                    "invoice_date": data?.invoice_date,
                    "supplier_name": data?.supplier_name,
                    "invoice_no": data?.invoice_no
                });
                transferId = data?.id;
                setTag(tag);
                setServiceProviderName(data?.service_provider_name);
                setAddress(data?.address);
                setContactPersonName(data?.contact_person_name);
                setContactNumber(data?.contact_number);
                setMailId(data?.mail_id);
                setMobileNumber(data?.mobile_number);
                setServiceUnder(data?.service_under);
                setExpectedReturnDate(data?.expected_return_date);
                setActualReturnDate(data?.actual_return_date);
                setInvoiceNumber(data?.invoice_number);
                setInvoiceAmount(data?.invoice_amount);
                setInvoiceDate(data?.invoice_date);
                setReceiptRemark(data?.recipt_remark);
                setReceiptDate(data?.recipt_date);
                setRecivedBy(data?.recived_by);
                setTransferInitiatedBy(data?.initiated_by);
                setInitiationDate(data?.initiated_date);
                setTransferApprovedBy(data?.approved_by);
                setApprovalDate(data?.approved_date);
                setTransferType(data?.transfer_type);
                setEmployeeId(data?.employee_id);
                setEmployeeName(data?.employee_name);
                setExtensionDate(data?.date_of_extension);
                setExtendedReturnDate(data?.extended_date);
                setExtensionReason(data?.extension_reason);
                setExtensionApprovedBy(data?.extension_approved_by);
                setRemarks(data?.remarks);
            }
        })
    }

    const getTagInfo = function () {
        getTemproryTransferData(tag).then(function (res) {
            if (res?.data?.data) {
                let data = res.data.data;
                setAssetInfo({
                    "financials_classification": data?.financials_classification,
                    "invoice_date": data?.invoice_date,
                    "supplier_name": data?.supplier_name,
                    "invoice_no": data?.invoice_no
                });
                transferId = data?.id;
                setTag(tag);
                setServiceProviderName(data?.service_provider_name);
                setAddress(data?.address);
                setContactPersonName(data?.contact_person_name);
                setContactNumber(data?.contact_number);
                setMailId(data?.mail_id);
                setMobileNumber(data?.mobile_number);
                setServiceUnder(data?.service_under);
                setExpectedReturnDate(data?.expected_return_date);
                setActualReturnDate(data?.actual_return_date);
                setInvoiceNumber(data?.invoice_number);
                setInvoiceAmount(data?.invoice_amount);
                setInvoiceDate(data?.invoice_date);
                setReceiptRemark(data?.recipt_remark);
                setReceiptDate(data?.recipt_date);
                setRecivedBy(data?.recived_by);
                setTransferInitiatedBy(data?.initiated_by);
                setInitiationDate(data?.initiated_date);
                setTransferApprovedBy(data?.approved_by);
                setApprovalDate(data?.approved_date);
                setTransferType(data?.transfer_type);
                setEmployeeId(data?.employee_id);
                setEmployeeName(data?.employee_name);
                setExtensionDate(data?.date_of_extension);
                setExtendedReturnDate(data?.extended_date);
                setExtensionReason(data?.extension_reason);
                setExtensionApprovedBy(data?.extension_approved_by);
                setRemarks(data?.remarks);
            }
        })
    }
    const handleSubmit = async (e) =>    {
        e.preventDefault();
        // if button enabled with JS hack
        //const v1 = TEXT_REGEX.test(division);
        if (!TEXT_REGEX.test(transferInitiatedBy)) {
            setErrTransferInitiatedBy('Transfer initiated by is required'); setValidName(false);
            //return;
        } else { setErrTransferInitiatedBy(''); }

        if (!TEXT_REGEX.test(initiationDate)) {
            setErrInitiationDate('Date of Initiation is required'); setValidName(false);
            //return;
        } else { setErrInitiationDate(''); }

        if (!TEXT_REGEX.test(transferApprovedBy)) {
            setErrTransferApprovedBy('Transfer Approved By is required'); setValidName(false);
            //return;
        } else { setErrTransferApprovedBy(''); }

        if (!TEXT_REGEX.test(approvalDate)) {
            setErrApprovalDate('Date of Approval is required'); setValidName(false);
            //return;
        } else { setErrApprovalDate(''); }

        if (!TEXT_REGEX.test(transferType)) {
            setErrTransferType('Type of Transfer is required'); setValidName(false);
            //return;
        } else { setErrTransferType(''); }


        if (!TEXT_REGEX.test(employeeId)) {
            setErrEmployeeId('New Employee ID is required'); setValidName(false);
            //return;
        } else { setErrEmployeeId(''); }

        if (!TEXT_REGEX.test(employeeName)) {
            setErrEmployeeName('New Employee Name is required'); setValidName(false);
            //return;
        } else { setErrEmployeeName(''); }

        if (!TEXT_REGEX.test(expectedReturnDate)) {
            setErrExpectedReturnDate('Expected Date of Return is required'); setValidName(false);
            //return;
        } else { setErrExpectedReturnDate(''); }

        if (!TEXT_REGEX.test(extensionDate)) {
            setErrExtensionDate('Date of Extension is required'); setValidName(false);
            //return;
        } else { setErrExtensionDate(''); }

        if (!TEXT_REGEX.test(extendedReturnDate)) {
            setErrExtendedReturnDate('Extended Date of Return is required'); setValidName(false);
            //return;
        } else { setErrExtendedReturnDate(''); }

        if (!TEXT_REGEX.test(extensionReason)) {
            setErrExtensionReason('Reason for Extension is required'); setValidName(false);
            //return;
        } else { setErrExtensionReason(''); }

        if (!TEXT_REGEX.test(extensionApprovedBy)) {
            setErrExtensionApprovedBy('Extension Approved By is required'); setValidName(false);
            //return;
        } else { setErrExtensionApprovedBy(''); }
        if (!TEXT_REGEX.test(remarks)) {
            setErrRemarks('Remarks is required'); setValidName(false);
            //return;
        } else { setErrRemarks(''); }

        //alert(division);
        if (validName) {
            saveData(); //submit form
        } else {
            return false;
        }
    }

    const saveData = function() {
        var data = {
            "id":transferId,
            "tag":tag,
            "service_provider_name":serviceProviderName,
            "address":address,
            "contact_person_name":contactPersonName,
            "contact_number":contactNumber,
            "mail_id":mailId,
            "mobile_number":mobileNumber,
            "service_under":serviceUnder,
            "expected_return_date":expectedReturnDate?.dbFormatedDate(),
            "actual_return_date":actualReturnDate?.dbFormatedDate(),
            "invoice_number":invoiceNumber,
            "invoice_amount":invoiceAmount,
            "invoice_date":invoiceDate?.dbFormatedDate(),
            "recipt_remark":receiptRemark,
            "recipt_date":receiptDate?.dbFormatedDate(),
            "recived_by":recivedBy,
            "initiated_by":transferInitiatedBy,
            "initiated_date":initiationDate?.dbFormatedDate(),
            "approved_by":transferApprovedBy,
            "approved_date":approvalDate?.dbFormatedDate(),
            "transfer_type":transferType,
            "employee_id":employeeId,
            "employee_name":employeeName,
            "date_of_extension":extensionDate?.dbFormatedDate(),
            "extended_date":extendedReturnDate?.dbFormatedDate(),
            "extension_reason":extensionReason,
            "extension_approved_by":extensionApprovedBy,
            "remarks":remarks
        }
        saveTemproryTransferData(data).then(function(res) {
            console.log(res);
        })
    }
    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h5>Send Testing</h5>
                </div>
            </div>
            <div className='row'>
                <div className='col'><h5 className='align-right'>TAG </h5></div>
                <div className='col'><input onChange={function(event){setTag(event.target.value)}} type="text" className="form-control"  placeholder='Enter the asset TAG'/></div>
                <div className='col'><button onClick={getTagInfo} className='btn btn-primary'> Search </button></div>
            </div>
            <div className="row">
                <div className="col-6">
                    <FromFieldLabel 
                        label="Financial Classification" 
                        inputValue={assetInfo.financials_classification}
                        /> 
                </div>
                <div className="col-6">
                    <FromFieldLabel 
                        label="Invoice Date" 
                        inputValue={getFormatedDate(assetInfo.invoice_date)}
                        /> 
                </div>
            </div>
            <div className="row border-bottom">
                <div className="col-6">
                    <FromFieldLabel 
                        label="Supplier Name" 
                        inputValue={assetInfo.supplier_name}
                        /> 
                </div>
                <div className="col-6">
                    <FromFieldLabel 
                        label="Supplier Invoice No" 
                        inputValue={assetInfo.invoice_no}
                      
                        /> 
                </div>
            </div>
            <form onSubmit={handleSubmit} >
            <div className="row">
                <div className="col-6">
                    <FromFieldText star='*'
                        label="a. Transfer Initiated By" 
                        placeholder="Transfer Initiated By" 
                        inputValue={transferInitiatedBy}
                        setInputValue={setTransferInitiatedBy}
                        setErrorMsg={ErrTransferInitiatedBy}
                        /> 
                </div>
                <div className="col-6">
                    <FromFieldDate star='*'
                        label="Date of Initiation" 
                        inputValue={initiationDate}
                        setInputValue={setInitiationDate}
                        setErrorMsg={ErrInitiationDate}
                        /> 
                </div>
            </div>

            <div className="row">
                <div className="col-6">
                    <FromFieldText star='*'
                        label="a. Transfer Approved By" 
                        placeholder="Transfer Approved By" 
                        inputValue={transferApprovedBy}
                        setInputValue={setTransferApprovedBy}
                        setErrorMsg={ErrTransferApprovedBy}
                        /> 
                </div>
                <div className="col-6">
                    <FromFieldDate star='*'
                        label="Date of Approval" 
                        inputValue={approvalDate}
                        setInputValue={setApprovalDate}
                        setErrorMsg={ErrApprovalDate}
                        /> 
                </div>
            </div>

            <div className="row bottom-border">
                <div className="col-6">
                    <FromFieldSelect star='*'
                    label="a. Type of Transfer"
                    placeholder="Select Type of Transfer"
                    selectedValue={transferType}
                    setSelectedValue={setTransferType}
                        values={["Repair", "Testing"]}
                        setErrorMsg={ErrTransferType}
                />
                </div>
                <div className="col-6">
                    
                </div>
            </div>

           
            <div className="row">
                <div className="col-6">
                    <FromFieldText star='*'
                        label="A. New Employee ID" 
                        placeholder="New Employee ID" 
                        inputValue={employeeId}
                        setInputValue={setEmployeeId}
                        setErrorMsg={ErrEmployeeId}
                        /> 
                </div>
                <div className="col-6">
                    <FromFieldText star='*'
                        label="B. New Employee Name" 
                        placeholder="New Employee Name" 
                        inputValue={employeeName}
                        setInputValue={setEmployeeName}
                        setErrorMsg={ErrEmployeeName}
                        /> 
                </div>
            </div>
            
            <div className="row">
                <div className="col-6">
                    <FromFieldDate star='*'
                        label="C. Expected Date of Return" 
                        inputValue={expectedReturnDate}
                        setInputValue={setExpectedReturnDate}
                        setErrorMsg={ErrExpectedReturnDate}
                        />
                </div>
                <div className="col-6">
                    <FromFieldDate star='*'
                        label="D. Date of Extension" 
                        inputValue={extensionDate}
                        setInputValue={setExtensionDate}
                        setErrorMsg={ErrExtensionDate}
                        />
                </div>
            </div>

            <div className="row">
                <div className="col-6">
                    <FromFieldDate star='*'
                        label="D. Extended Date of Return" 
                        inputValue={extendedReturnDate}
                        setInputValue={setExtendedReturnDate}
                        setErrorMsg={ErrExtendedReturnDate}
                        />
                </div>
                <div className="col-6">
                    <FromFieldText star='*'
                        label="F. Reason for Extension" 
                        placeholder="Reason for Extension" 
                        inputValue={extensionReason}
                        setInputValue={setExtensionReason}
                        setErrorMsg={ErrExtensionReason}
                        /> 
                </div>
            </div>

            <div className="row">
                <div className="col-6">
                    <FromFieldText star='*'
                        label="G. Extension Approved By" 
                        placeholder="Extension Approved By" 
                        inputValue={extensionApprovedBy}
                        setInputValue={setExtensionApprovedBy}
                        setErrorMsg={ErrExtensionApprovedBy}
                        />
                </div>
                <div className="col-6">
                    <FromFieldText star='*'
                        label="F. Remarks (If any)" 
                        placeholder="Remarks" 
                        inputValue={remarks}
                        setInputValue={setRemarks}
                        setErrorMsg={ErrRemarks}
                        /> 
                </div>
            </div>

            <div className="row">
                <div className="col-6"></div>
                <div className="col-6"><button onClick={saveData} className='btn btn-primary'> SAVE </button></div>
            </div>
             </form>
        </div>
    )
}

export default TemproryTransfer