    import React, { useState,useEffect } from 'react';
    import { useLocation } from "react-router-dom";
    import { getAllocationLog } from '../../../services/asset.service';
    import Box from '@mui/material/Box';
    import allocationLogView from '../../../atoms/allocationLogView';
    import { DataGrid } from '@mui/x-data-grid';
    import  * as CryptoJS  from "crypto-js";
    // import axios from "axios";
    // import { read } from "xlsx";
    
    import '../../ExcelUploads/AssetInfo/AssetInfo.css';
  import { colors } from '@mui/material';
   
    function getFormatedDate(dateString) {
      if(dateString!=null) {
          let dte = new Date(dateString);
          let year = dte.getFullYear();
          let month = dte.getMonth() + 1;
          let day = dte.getDate();
          if (month < 10) {
            month = '0' + month;
          }if (day < 10) {
            day = '0' + day;
          }
          return year + '-' + month + '-' + day;
          //return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
      } else {
          return null;
      }   
    }
    {/* {"field":"tag","hide":false ,"headerName":"Action","width":80,
        renderCell: (params) => (
          <a href={`/single-nonallocated?tag=${params.value}`} className='btn btn-info btn-sm'>Not Allocated</a>
        ) }, */}
        const onActiveStatusClick = (e, row) => {
          e.stopPropagation(); //alert(row.id)
          //window.open.url
          //do whatever you want with the row
        };
        const onDeactiveStatusClick = (e, row) => {
          e.stopPropagation(); //alert(row.tag)
          
        };
    
    const columns = [
        {"field":"id","hideable":true,"hide":true,"headerName":"S. No.","width":80},
        {"field":"serialNumber","hideable":true,"hide":false,"headerName":"S. No.","width":80},
        
        {"field":"tag","hide":false ,"headerName":"TAG","width":250},
        
        {"field":"Action","hide":false , "headerName":"Action","width":250,
        renderCell: (params) => {
          let EncryptTag = CryptoJS.AES.encrypt(JSON.stringify({
              'tag': params.row.tag,
          }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let EncryptLogId = CryptoJS.AES.encrypt(JSON.stringify({
              'log_id': params.row.id,
          }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
          return (<a style={(colors)} href={`/allocation-log-details?tag=${EncryptTag}&LogId=${EncryptLogId}`}><i classname="bi bi-ticket-detailed-fill"> View Details</i></a>);
        } },
        
        {"field":"reason","hide":false , "headerName":"Form Type","width":130,
        renderCell: (params) => ( (params.row.reason=='' || params.row.reason=="allocate" || params.row.reason=="Allocate from Web" || params.row.reason=="Allocate"||params.row.reason=="Re-allocate")?'Allocate':'Unallocate')
         },
         {"field":"effective_date","hide":false ,"headerName":"Effective Date","width":130,
         renderCell: (params) => (
           <p>{ (params.row.effective_date!=null)?getFormatedDate(params.row.effective_date):getFormatedDate(params.row.unallocate_date)}</p>
         ) },
        {"field":"created_at","hide":false ,"headerName":"Created Date","width":130,
        renderCell: (params) => (
          <p>{ getFormatedDate(params.row.created_at)}</p>
        ) },
    ];
 
    function AllocationLog() {
      const [items, setItems] = useState([]);
      

      var onCellEditStopFn = (event,newCell) => {
        var obj = {};
        obj[newCell.field] = event.target.value;
      }
      const useQuery = () => new URLSearchParams(useLocation().search);
      const query = useQuery();
      const Tag = query.get('tag');
      useEffect(()=>{
        const decryptedBytes = CryptoJS.AES.decrypt(Tag.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
        // Convert the decrypted bytes to a string
        const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
        const decryptedTag = decrypt_data.tag;

        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
          'param_data':{'tag':decryptedTag},
          'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token':EncryptData}
        getAllocationLog(oData).then(function(res){
          console.log(res.data.data);
          //if(res?.data?.data) {
          if(res?.data?.data?.length) setItems(res.data.data);
        })
      },[])
      
      const dataWithSerialNumbers = items.map((item, index) => ({
        ...item,
        serialNumber: index + 1, // Add serial number starting from 1
    }));
      // readAssets();
      return (
      <div className='container'>
        <div className='row'>
        <div className='col-md-10'><h5> Allocation Log </h5></div>
        <div className='col-md-2'>
          <a title='Back' href={`/view-allocation`} className="btn btn-secondary btn-sm"><i className="bi bi-arrow-left"></i> Back</a>
        </div>
        </div>
        
          <DataGrid
            //rows={items}
            rows={dataWithSerialNumbers}
            getRowId={(row) => row.id}
            columns={columns}
            pageSize={50}
            autoHeight 
            
            rowsPerPageOptions={[50]}
            onCellEditStop={onCellEditStopFn}
          />
      </div>
      )
    }
    
    export default AllocationLog
    

