import axios from "axios";
var baseURL = process.env.REACT_APP_API_BASE_URL;
var apis = {
    'getNotificationList' : `${baseURL}notification/getnotifylist`,
    'createNotifications' : `${baseURL}notification/create`,
    'getAllNotificationList' : `${baseURL}notification/getallnotifylist`,
}

export function getNotificationList(data) {
    return axios.put(apis.getNotificationList,data);
}

export function createNotifications(data) {
    return axios.put(apis.createNotifications,data);
}

export function getAllNotificationList(data) {
    return axios.put(apis.getAllNotificationList,data);
}
