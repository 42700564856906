import React, { useState, useEffect } from 'react';



function ConfirmDisposal() {

    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h5>Confirm Disposal</h5>
                </div>
            </div>

            <div className='row mt-4'>
                <div className='table-responsive'>
                    <table className='table-bordered w-100 text-center'>
                        <thead>
                            <tr>
                                <td style={{fontWeight:700,color:'#fff',background:'#000531',padding:'5px'}}>Final Buyer Name</td>
                                <td style={{fontWeight:700,color:'#fff',background:'#000531',padding:'5px'}}>Ref No</td>
                                <td style={{fontWeight:700,color:'#fff',background:'#000531',padding:'5px'}}>Date of Sale</td>
                                <td style={{fontWeight:700,color:'#fff',background:'#000531',padding:'5px'}}>Status</td>
                                <td style={{fontWeight:700,color:'#fff',background:'#000531',padding:'5px'}}>Transport Data</td>
                                <td style={{fontWeight:700,color:'#fff',background:'#000531',padding:'5px'}}>Confirmation</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>Pending</td>
                                <td style={{padding: '10px'}}>
                                    <button type='button' className='btn btn-primary btn-md'>View</button>
                                </td>
                                <td style={{padding: '10px'}}>
                                    <button type='button' className='btn btn-primary btn-md'>View</button>
                                </td>
                            </tr>
                            
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    )
}

export default ConfirmDisposal;