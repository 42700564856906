import React, { useState,useEffect,useCallback } from 'react';
import Box from '@mui/material/Box';
import download from 'downloadjs';
import { DataGrid,GridToolbarContainer, GridToolbarExport  } from '@mui/x-data-grid';
import { getAssetsImages,downloadFile } from '../../../services/asset.service';
import  * as CryptoJS  from "crypto-js";
import './AssetInfo.css';
import CustomModal from '../../../atoms/ViewImagePdfModal';
import AWS from 'aws-sdk';
AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
  region: process.env.REACT_APP_AWS_BUCKET_REGION,
});
const s3 = new AWS.S3();
Date.prototype.dbFormatedDate = function () {
  let year = this.getFullYear();
  let month = this.getMonth() + 1;
  let day = this.getDate();
  if (month < 10) {
    month = '0' + month;
  }if (day < 10) {
    day = '0' + day;
  }
  return year + '-' + month + '-' + day;
  //return `${this.getFullYear()}-${this.getMonth()+1}-${this.getDate()-1}`
}
function getFormatedDate(dateString) {
  if(dateString!=null) {
      let dte = new Date(dateString);
      let year = dte.getFullYear();
      let month = dte.getMonth() + 1;
      let day = dte.getDate();
      if (month < 10) {
        month = '0' + month;
      }if (day < 10) {
        day = '0' + day;
      }
      return year + '-' + month + '-' + day;
      //return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
  } else {
      return null;
  }   
}
function AssetImageList() {
    const [loading, setLoading] = useState(true);
/*const handleDownloadFile = async (id, path) => {
  var obj = {
    'token': sessionStorage.getItem('LoggedInToken'),
    'id':id,
    'path':path,
    //'mimetype':"image/jpg",
  }
  let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
    'param_data':obj,
    'authToken': sessionStorage.getItem('LoggedInToken'),
  }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
  let oData = {'token':EncryptData}
  downloadFile(oData).then(res=>{
    //if(res?.data?.data?.length) { 
    //  alert('sdf'); //setItems(res.data.data);
    //}
    const split = path.split('/');
    const filename = split[split.length - 1];
    return download(res.data, filename);

  });
};*/
const handlePreview = async (id, imageId) => {
  imageId = 'image/'+imageId;

  try {
    const data = await s3.getObject({
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
      Key: imageId, // 'path/to/your/image.jpg',
    }).promise();
    
    console.log(data.ContentType);
    //const url = URL.createObjectURL(new Blob([data.Body]));
    const signedUrl = s3.getSignedUrl('getObject', {
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
      Key: imageId,
    });
    //setImageUrl(signedUrl);
    console.log(signedUrl);
    if (data.ContentType==='image/jpeg' || data.ContentType==='image/jpg' || data.ContentType==='image/png'|| data.ContentType==='application/octet-stream') {
    //if (url.endsWith('.jpg') || url.endsWith('.png')) {
      setModalContentType('image');
    } else  if (data.ContentType==='application/pdf'){ // if (url.endsWith('.pdf')) {
      setModalContentType('pdf');
    }
    setModalContent(signedUrl);
    setModalIsOpen(true);
  } catch (error) {
    console.error('Error fetching image:', error);
  }
};
const handleDownloadFile = async (id, imageId) => {
  // Use the S3 getObject method to get the image and initiate the download
  console.log('imageId'+imageId);
  imageId = 'image/'+imageId;
  s3.getObject({
    Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
    Key: imageId, //imageId,
  }, (err, data) => {
    if (err) {
      console.error('Error downloading image:', err);
    } else { console.log(data.Body);
      const url = URL.createObjectURL(new Blob([data.Body]));
      const a = document.createElement('a');
      a.href = url;
      a.download = imageId;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  });
};
const columns = [
    {"field":"sl_no","hideable":true,"hide":true,"editable":false,"headerName":"Sl No","width":50},
    {"field":"tag","editable":false,"hide":false ,"headerName":"TAG","width":250,},
    {"field":"parent_tag","hideable":true,"hide":true ,"editable":true,"headerName":"Parent TAG","width":250},
    {"field":"supplier_code","editable":true,"hide":false ,"headerName":"Supplier Code","width":130},
    {"field":"supplier_name","editable":true,"hide":false ,"headerName":"Supplier Name","width":130},
    {"field":"invoice_no","editable":true,"hide":false ,"headerName":"Invoice No","width":130},
    {"field":"invoice_date","editable":true,"hide":false ,"headerName":"Invoice Date","width":130,
    renderCell: (params) => (
      <p>{ getFormatedDate(params.row.invoice_date)}</p>
    ) },
    {"field":"created_at","editable":true,"hide":false ,"headerName":"Date of Addition","width":130,
      renderCell: (params) => (
        <p>{ getFormatedDate(params.row.created_at)}</p>
      ) },

    {"field":"doc_type","editable":false,"hide":false ,"headerName":"Doc Type","width":100 },
    {"field":"asset_image","editable":false,"hide":false ,"headerName":"Action","width":200,

    renderCell: (params) => (
      /*<a href={`/upload-image?tag=${params.row.asset_image}`} >Download</a>*/
      <div><a href="#/" onClick={() => handleDownloadFile(params.row.id, params.row.asset_image) } >Download</a> &nbsp;
      {/*<a href="#/" onClick={() => handlePreview(params.row.id, params.row.asset_image) } >Preview</a>*/}</div>
    ) },
    
    
];

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      
    </GridToolbarContainer>
  );
}
const [items, setItems] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
const [modalContent, setModalContent] = useState('');
const [modalContentType, setModalContentType] = useState('');
  
  var onCellEditStopFn = (event,newCell) => {
    var obj = {};
    obj[newCell.field] = event.target.value;
  }

    useEffect(() => {
        let EncryptData = CryptoJS.AES.encrypt(
            JSON.stringify({
                //'param_data':{'tag':tag},
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }),
            process.env.REACT_APP_ENCRYPT_SECRET_KEY
        ).toString();
        let oData = { 'token': EncryptData };

        setLoading(true); // Set loading to true when starting the request

        getAssetsImages(oData)
            .then((res) => {
                if (res?.data?.data?.length) {
                    setItems(res.data.data);
                }
            })
            .finally(() => {
                setLoading(false); // Set loading to false regardless of success or failure
            });
    }, []);
  
  // readAssets();
  return (
    <div className='container' >
      <h5> Asset Images </h5>
      <Box>

          {loading ? (
              <div className="text-center">
                  <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden">Loading...</span>
                  </div>
              </div>
          ) : (
              <DataGrid
                  rows={items}
                  getRowId={(row) => row.id}
                  columns={columns}
                  pageSize={50}
                  autoHeight
                  rowsPerPageOptions={[50]}
                  components={{
                      Toolbar: CustomToolbar,
                  }}
              />
          )}

      </Box>
      <CustomModal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        content={modalContent}
        contentType={modalContentType}
      />
    </div>
  )
}

export default AssetImageList
