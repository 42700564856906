import axios from "axios";
var baseURL = process.env.REACT_APP_API_BASE_URL;

var apis = {
    'saveBondingConfigure' : `${baseURL}assetstpi/savebondingconfigure`,
    'getBondingConfigure' :`${baseURL}assetstpi/getbondingconfigure`,
    'getRunningBalance' :`${baseURL}assetstpi/getrunningbalance`,
    'saveCgApproval' : `${baseURL}assetstpi/savecgapproval`,
    'getCgApproval' :`${baseURL}assetstpi/getcgapproval`,
    'saveAddBond' : `${baseURL}assetstpi/saveaddbond`,
    'getAddBond' :`${baseURL}assetstpi/getaddbond`,
    'getCgApprovalList' :`${baseURL}assetstpi/getcgapprovallist`,
    'getBondListing' :`${baseURL}assetstpi/getbondlisting`,
}
export function saveBondingConfigure(data) {
    return axios.put(apis.saveBondingConfigure,data);
}
export function getBondingConfigure(data) {
    return axios.put(apis.getBondingConfigure,data);
}
export function getRunningBalance(data) {
    return axios.put(apis.getRunningBalance,data);
}
export function saveCgApproval(data) {
    return axios.put(apis.saveCgApproval,data);
}
export function getCgApproval(data) {
    return axios.put(apis.getCgApproval,data);
}
export function getCgApprovalList(data) {
    return axios.put(apis.getCgApprovalList,data);
}
export function saveAddBond(data) {
    return axios.put(apis.saveAddBond,data);
}
export function getAddBond(data) {
    return axios.put(apis.getAddBond,data);
}
export function getBondListing(data) {
    return axios.put(apis.getBondListing,data);
}