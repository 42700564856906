import React, {useEffect, useRef, useState} from 'react';
import {useLocation} from "react-router-dom";
import {getSinglePhyVeriMaster, savePhyVeriMaster} from '../../../services/asset.service';
import {getDivisionList} from '../../../services/user.service';
import FromFieldText from '../../../atoms/FromFieldText';
import FromFieldDate from '../../../atoms/FromFieldDate';
import * as CryptoJS from "crypto-js";
import Select from "react-select";

const TEXT_REGEX = /^[A-Za-z0-9-_/.@= ]+$/;

Date.prototype.dbFormatedDate = function () {

    let year = this.getFullYear();
    let month = this.getMonth() + 1;
    let day = this.getDate();
    if (month < 10) {
        month = '0' + month;
    }
    if (day < 10) {
        day = '0' + day;
    }
    return year + '-' + month + '-' + day;
    //return `${this.getFullYear()}-${this.getMonth()+1}-${this.getDate()-1}`
}

function getFormatedDate(dateString) {
    if (dateString !== null) {
        let dte = new Date(dateString);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }
        return year + '-' + month + '-' + day;
        //return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
    } else {
        return null;
    }
}

function PhysicalVerification() {
    const textRef = useRef();
    const errRef = useRef();
    const [pvtableno, setPvTableNo] = useState('');
    const [ErrPvTableNo, setErrPvTableNo] = useState('');
    const [companyId, setCompanyId] = useState(sessionStorage.getItem('LoggedInCompanyId'));
    const [divisions, setDivisions] = useState([]);

    const [division, setDivision] = useState('');
    const [ErrDivision, setErrDivision] = useState(false);
    
    const [startdate, setStartDate] = useState('');
    const [ErrStartDate, setErrStartDate] = useState('');
    const [enddate, setEndDate] = useState('');
    const [ErrEndDate, setErrEndDate] = useState('');
    const [extendedenddate, setExtendedEndDate] = useState('');

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
    const [assetInfo, setAssetInfo] = useState({});
    const resetAssetInfo = function () {
        setPvTableNo('');
        setDivision('');
        setStartDate('');
        setEndDate('');
    }
    const optionListDivision = [];
    //console.log(divisions.length);
    
    const [selectedDivisionsOptions, setSelectedDivisionsOptions] = useState(null);

    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const masterid = query.get('mid');
    useEffect(() => {
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            //'param_data':obj,
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token': EncryptData}

        getDivisionList(oData).then(res => {
            if (res?.data?.data?.length) setDivisions(res.data.data);
        });

        if (masterid !== '' && masterid !== null) {

            const decryptedBytes = CryptoJS.AES.decrypt(masterid.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
            // Convert the decrypted bytes to a string
            const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
            //console.log(decrypt_data);
            const decryptMasterId = decrypt_data.masterid;
            getPhyVerMasterId(decryptMasterId); // get comment info
            //alert(decryptMasterId);
        } else {
            resetAssetInfo();
        }
        
        

    }, []);
    
    divisions && divisions.length > 0 ? (
        divisions.map((option) => (
            optionListDivision.push({value: option.id, label: option.division_name})
        ))
    ) : (
        optionListDivision.push({value: "", label: "No Data"})
    ); //console.log(optionListDivision);

    function handleDivision(data) { console.log(data);
        setSelectedDivisionsOptions(data);
        setDivision(data.value);
    }
    const getPhyVerMasterId = function (decryptMasterId) {
        resetAssetInfo();
        console.log('decryptMasterId');
        console.log(decryptMasterId);
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': {'master_id': decryptMasterId},
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData1 = {'token': EncryptData}

        getSinglePhyVeriMaster(oData1).then(function (res) {
            if (res?.data?.data) {
                var aInfo = res.data.data[0];
                setAssetInfo(aInfo);
                console.log(aInfo);
                setPvTableNo(aInfo.phy_varify_table_name);
                setDivision(aInfo.division);
                setStartDate((new Date(aInfo.start_date)));
                setEndDate((new Date(aInfo.end_date)));
                setExtendedEndDate((new Date(aInfo.extended_end_date)));
                const selectedOption = optionListDivision.find(option => option.value == aInfo.division);
                console.log(optionListDivision);
                setSelectedDivisionsOptions({ value: aInfo.division, label: aInfo.division });
            } else {
                setErrMsg('Details Not Found');
            }
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        var valid_Name = true;
        // if button enabled with JS hack
        //const v1 = TEXT_REGEX.test(name);
        if (!TEXT_REGEX.test(pvtableno)) { //alert(pvtableno);
            setErrPvTableNo('PV Table no is required');
            valid_Name = false; //alert(valid_Name);
            //return;
        } else {
            setErrPvTableNo('');
        }
        if (!TEXT_REGEX.test(division)) { //alert(companyId);
            setErrDivision('Division name is required');
            valid_Name = false;
            //return;
        } else {
            setErrDivision('');
        }
        if (startdate == '') {
            setErrStartDate('Start Date is required');
            valid_Name = false;
            //return;
        } else {
            setErrStartDate('');
        }
        if (enddate == '') {
            setErrEndDate('End Date is required');
            valid_Name = false;
            //return;
        } else {
            setErrEndDate('');
        }
        if (valid_Name) { //alert('test1');
            saveData(); //submit form
        } else {  //alert('fa');
            return false;
        }
    }
    const saveData = function () {
        try {
            var obj = {
                'company_id': sessionStorage.getItem('LoggedInCompanyId'),
                'phy_varify_table_name': pvtableno,
                'division': division,
                'start_date': getFormatedDate(startdate),
                'end_date': getFormatedDate(enddate),
                'extended_end_date': getFormatedDate(extendedenddate),
                'created_by': sessionStorage.getItem('LoggedInUserId'),
            }
            if (masterid !== '' && masterid !== null) {
                const decryptedBytes = CryptoJS.AES.decrypt(masterid.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
                // Convert the decrypted bytes to a string
                const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
                //console.log(decrypt_data);
                const decryptMasterId = decrypt_data.masterid;
                obj.master_id = decryptMasterId;
            }
            //encrypted data
            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': obj,
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData1 = {'token': EncryptData}
            savePhyVeriMaster(oData1).then(function (res) {
                //console.log(res);
                if ((typeof (res.data.errors) != "undefined") && res.data.errors.errno > 0) {
                    setErrMsg(res.data.message);
                    setSuccess(false);
                } else {
                    setSuccess(res.data.message);
                    setPvTableNo('');
                    setDivisions([]);
                    setStartDate();
                    setEndDate('');
                    setExtendedEndDate('');
                }
            });
        } catch (err) {
            setSuccess(false);
            setErrMsg(err.data.message);
            if (!err?.data) {
                console.log(err);
                setErrMsg('No Server Response');
            } else if (err.data?.status === 409) {
                setErrMsg('Username Taken');
            } else {
                setErrMsg('Insert/Update Failed');
            }
            //elementRef.current.focus();
        }
    }

    

    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h5>Create Physical Verification Table </h5>
                </div>
            </div>
            <div className='row'>
                <div className='col-12'>
                    {success ? (
                        <div className="alert alert-success" role="alert"><i
                            className="bi bi-check-circle-fill"></i>{success}</div>
                    ) : (errMsg ? (<div className="alert alert-danger" role="alert"><i
                            className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>) : ('')
                    )}
                </div>
            </div>
            <div className='row'></div>
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-6">
                        <FromFieldText
                            label="Physical Table Name"
                            inputValue={pvtableno}
                            setInputValue={setPvTableNo}
                            setErrorMsg={ErrPvTableNo}
                            required
                        />
                    </div>
                    <div className="col-6"></div>
                    <div className='col-6'>
                        <div className="row">
                            <div className="col-md-6">
                                <label>Division </label><span className="star error"> *</span>
                            </div>
                            <div className="col-md-6">
                                <Select
                                    options={optionListDivision}
                                    placeholder="Select One"
                                    value={selectedDivisionsOptions}
                                    onChange={handleDivision}
                                />
                                <span className="invalid-feedback"> {ErrDivision} </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <FromFieldDate
                            label="Start Date"
                            inputValue={startdate}
                            setInputValue={setStartDate}
                            setErrorMsg={ErrStartDate}
                        />
                    </div>
                    <div className="col-6"></div>
                    <div className="col-6">
                        <FromFieldDate
                            label="End Date"
                            inputValue={enddate}
                            setInputValue={setEndDate}
                            setErrorMsg={ErrEndDate}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <FromFieldDate
                            label="Extended End Date"
                            inputValue={extendedenddate}
                            setInputValue={setExtendedEndDate}
                        />
                    </div>
                    <div className="col-6"></div>
                </div>
                <div className="row">
                    <div className="col-3"></div>
                    <div className="col-3">
                        {(masterid !== '' && masterid !== null) ? <div>
                                <button className="btn btn-primary btn-md">Update</button>
                                &nbsp;&nbsp;<a href={'/physical-verfication-list'}
                                               className="btn btn-secondary btn-md">Cancel</a></div>
                            : <button className='btn btn-primary btn-md'> SAVE </button>
                        }

                    </div>
                    <div className="col-6"></div>
                </div>
            </form>
        </div>
    )
}

export default PhysicalVerification


