import React,{useState, useRef, useEffect} from 'react'
import { getTransferData, savePhyVeriData } from '../../../services/asset.service';
import FromFieldLabel from '../../../atoms/FromFieldLabel';
import FromFieldText from '../../../atoms/FromFieldText';
import FromFieldDate from '../../../atoms/FromFieldDate';
import FromFieldSelect from '../../../atoms/FromFieldSelect';
import  * as CryptoJS  from "crypto-js";
const TEXT_REGEX = /^[a-z0-9-_ ]$/;
Date.prototype.dbFormatedDate = function () {
    
    let year = this.getFullYear();
    let month = this.getMonth() + 1;
    let day = this.getDate();
    if (month < 10) {
      month = '0' + month;
    }if (day < 10) {
      day = '0' + day;
    }
    return year + '-' + month + '-' + day;
    //return `${this.getFullYear()}-${this.getMonth()+1}-${this.getDate()-1}`
  }
  function getFormatedDate(dateString) {
    if(dateString!==null) {
        let dte = new Date(dateString);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        if (month < 10) {
          month = '0' + month;
        }if (day < 10) {
          day = '0' + day;
        }
        return year + '-' + month + '-' + day;
        //return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
    } else {
        return null;
    }   
  }
function PhysicalVerification() {
    const textRef = useRef();
    const errRef = useRef();

    const [tag, setTag] = useState(0);
    const [assetInfo, setAssetInfo] = useState({});

    const [pvtableno,setPvTableNo] = useState('');
    const [ErrPvTableNo,setErrPvTableNo] = useState('');
    const [division,setDivision] = useState('');
    const [ErrDivision,setErrDivision] = useState('');
    const [validText, setValidText] = useState(false);
    
    const [method,setMethod] = useState('');
    const [ErrMethod,setErrMethod] = useState('');
    const [lastverifydate,setLastVerifyDate] = useState('');
    const [ErrLastVerifyDate,setErrLastVerifyDate] = useState('');
    const [lastverifyremark,setLastVerifyRemark] = useState('');
    const [ErrLastVerifyRemark,setErrLastVerifyRemark] = useState('');
    const [userlocation,setUserLocation] = useState('');
    const [ErrUserLocation,setErrUserLocation] = useState('');
    const [reasonforchhange,setReasonForChhange] = useState('');
    const [ErrReasonForChange,setErrReasonForChange] = useState('');
    const [verifystatus,setVerifyStatus] = useState('');
    const [ErrVerifyStatus,setErrVerifyStatus] = useState('');
    const [assetstatus,setAssetStatus] = useState('');
    const [ErrAssetStatus,setErrAssetStatus] = useState('');
    

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    useEffect(() => {
       // textRef.current.focus();
    }, [])

    useEffect(() => {
        setValidText(TEXT_REGEX.test(division));
    }, [division])

    

    const handleClick = async (e) => {
        e.preventDefault(); var valid_Name=true;

        if (pvtableno ==='') {
			setErrPvTableNo('Pv Table is required'); valid_Name=false;
			//return;
		}else{ setErrPvTableNo('');  }
        if (division ==='') {
			setErrDivision('Division is required'); valid_Name=false;
			//return;
		}else{ setErrDivision('');  }
        if (method ==='') {
			setErrMethod('Method is required'); valid_Name=false;
			//return;
		}else{ setErrMethod('');  }
        if (lastverifydate==='') {
            setErrLastVerifyDate('Last Verification Date is required'); valid_Name=false;
            //return;
        }else{ setErrLastVerifyDate('');  }
        if (lastverifyremark==='') {
            setErrLastVerifyRemark('Last Verification remark is required'); valid_Name=false;
            //return;
        }else{ setErrLastVerifyRemark('');  }
        if (userlocation ==='') {
			setErrUserLocation('User Location is required'); valid_Name=false;
			//return;
		}else{ setErrUserLocation('');  }
        if (reasonforchhange ==='') {
			setErrReasonForChange('Reason For Change is required'); valid_Name=false;
			//return;
		}else{ setErrReasonForChange('');  }
        if (verifystatus ==='') {
			setErrVerifyStatus('Status of verification is required'); valid_Name=false;
			//return;
		}else{ setErrVerifyStatus('');  }
        if (assetstatus ==='') {
			setErrAssetStatus('Status is required'); valid_Name=false;
			//return;
		}else{ setErrAssetStatus('');  }
        if (valid_Name) { //alert('validName');
            saveData(); //submit form
        }else{ 
            //alert(valid_Name);
            return false;
        }
    }
    const resetPVInfo = function() { setTag('');
        setDivision('');setPvTableNo('');
        setLastVerifyDate('');
        setLastVerifyRemark('');
        setUserLocation('');
        setReasonForChhange('');
        setVerifyStatus('');
    }
    const getTagInfo = function() {
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data':{'tag':tag},
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token':EncryptData}
          getTransferData(oData).then(function(res){
            if(res?.data?.data) {
                setAssetInfo(res.data.data);    

            }else{setAssetInfo({});setErrMsg('Assets Not Found');} 
        })
    }
    const saveData = function() {
        try {
        var obj = {
            'param_data':{
            'tag' : tag,
            'company_id':sessionStorage.getItem('LoggedInCompanyId'),
            'division_id':sessionStorage.getItem('LoggedInDivisionId'),
            'division' : division,
            'pv_table_no' : pvtableno,
            'last_verification_date' : lastverifydate,
            'method' : method,
            'last_verification_remarks' : lastverifyremark,
            'change_user_location' : userlocation,
            'reason_for_change':reasonforchhange,
            'status_of_verification':verifystatus,
            'status':assetstatus,
        }, 'token': sessionStorage.getItem('LoggedInToken') }
        //encrypted data
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data':obj,
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData1 = {'token':EncryptData}
        savePhyVeriData(oData1).then(function(res) {
            console.log(res);
            if((typeof(res.data.errors)!= "undefined") && res.data.errors.errno>0){ 
                setErrMsg(res.data.message);setSuccess(false);
              }else{
                setSuccess(res.data.message); setPvTableNo('');
                resetPVInfo(); 
              }
            });
        } catch (err) { setSuccess(false); setErrMsg(err.data.message);
            if (!err?.data) {
                      console.log(err);
              setErrMsg('No Server Response');
            } else if (err.data?.status === 409) {
              setErrMsg('Username Taken');
            } else {
              setErrMsg('Insert/Update Failed');
            }
            //elementRef.current.focus();
          }
    }
    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h5>Physical Verification Create FORM</h5>
                </div>
            </div>
            <div className='row '>
            <div className='col-12'>&nbsp;
            
            {success ? (
				<div className="alert alert-success" role="alert"><i className="bi bi-check-circle-fill"></i>{success}</div>
			        ) : ( errMsg ? (<div className="alert alert-danger" role="alert"><i className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>):('')
                ) }
            </div>
            <div className="col-3"><h6 >TAG </h6></div>
                <div className='col-3'><input onChange={function(event){setTag(event.target.value)}} type="text" className="form-control"  placeholder='Enter the asset TAG'/></div>
                <div className='col-6'><button onClick={getTagInfo} className='btn btn-primary btn-md'> Search </button></div>
                <div className='col-12'>&nbsp;</div>
            </div>
            <div className='row'></div>
            <div className="row">
                <div className="col-6">
                <FromFieldSelect 
                label="PV Table NO"
                setSelectedValue={assetInfo.pvtableno}
                selectedValue={assetInfo.setPvTableNo}
                values={["Table 01","Table 02","Table 03"]}
                setErrorMsg={ErrPvTableNo}
                />
                </div><div className='col-6'></div>
                <div className="col-6">
                <FromFieldSelect 
                label="Method"
                setSelectedValue={assetInfo.method}
                selectedValue={assetInfo.setMethod}
                values={["Flor to File","File to floor"]}
                setErrorMsg={ErrMethod}
                /> 
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                <FromFieldDate
                    label="Last Verification Date"
                    inputValue={lastverifydate} 
                    setInputValue={setLastVerifyDate} 
                    setErrorMsg={ErrLastVerifyDate}
                />
                </div><div className='col-6'></div>
                <div className="col-6">
                <FromFieldText
                        label="Last Verification Remark" 
                        inputValue={lastverifyremark}
                        setInputValue={setLastVerifyRemark}
                        setErrorMsg={ErrLastVerifyRemark}
                    />
                    
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                <FromFieldSelect 
                    label="Change User Location"
                    setSelectedValue={assetInfo.userlocation}
                    selectedValue={assetInfo.setUserLocation}
                    values={["Yes","No"]}
                    setErrorMsg={ErrUserLocation}
                />
                </div><div className='col-6'>
                <FromFieldText
                    label="Division" 
                    inputValue={division}
                    setInputValue={setDivision}
                    setErrorMsg={ErrDivision}
                    />
                </div>
                <div className="col-6">
                <FromFieldSelect 
                label="Reason For Change"
                setSelectedValue={assetInfo.reasonforchhange}
                selectedValue={assetInfo.setReasonForChhange}
                values={["Asset have some problem","Asset have no problem"]}
                setErrorMsg={ErrReasonForChange}
                />
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                <FromFieldSelect 
                label="Verification Status"
                setSelectedValue={assetInfo.verifystatus}
                selectedValue={assetInfo.setVerifyStatus}
                values={["Asset Fond in working Condition","Asset Fond in Non-working Condition","Asset Not found","Other"]}
                setErrorMsg={ErrVerifyStatus}
                />
                
                </div><div className='col-6'></div>
                <div className="col-6">
                <FromFieldSelect 
                label="Status"
                setSelectedValue={assetInfo.assetstatus}
                selectedValue={assetInfo.setAssetStatus}
                values={["Verified","Not Verified"]}
                setErrorMsg={ErrAssetStatus}
                />
                    
                </div>
            </div>
            
            
            <div className="row">
            <div className="col-3"></div>
                <div className="col-3"><button onClick={saveData} className='btn btn-primary btn-md'> SAVE </button></div>
                <div className="col-6"></div>
            </div>
        </div>
    )
}

export default PhysicalVerification


