
import React from 'react'

function FromFieldTextArea(props) {
  return (
    <div className="row form-fields">
        <div className="label col-6" > 
            <label> {props.label} </label>
            <span className="star error"> {props.star} </span>
        </div>
        <div className="item col-6" > 
          <textarea className="form-control"
            onChange={(event)=>props.setInputValue(event.target.value)}
            placeholder={props.placeholder} value={props.inputValue} 
            readOnly={props.readOnly} >
            </textarea>
            <span className="invalid-feedback"> {props.setErrorMsg} </span>
        </div>
    </div>
  )
}

export default FromFieldTextArea