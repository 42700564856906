import React, { useState,useRef,useEffect,useCallback } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { DataGrid } from '@mui/x-data-grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import axios from "axios";
// import { read } from "xlsx";
import { uploadAllocation } from '../../../services/asset.service'


const templatePath = "/exceltemplates/Asset_Allocation_Template.xlsx"


const columns = [
    {"field":"tag","hide":false ,"headerName":"TAG","width":130},
    {"field":"division","hide":false ,"headerName":"Division","width":130},
    {"field":"premises","hide":false ,"headerName":"Premises","width":130},
    {"field":"floor","hide":false ,"headerName":"Floor","width":130},
    {"field":"site_id","hide":false ,"headerName":"Site ID","width":130},
    {"field":"user_type","hide":false ,"headerName":"User Type","width":130},
    {"field":"user_department","hide":false ,"headerName":"User Department","width":130},
    {"field":"employee_id","hide":false ,"headerName":"Employee ID","width":130},
    {"field":"employee_name","hide":false ,"headerName":"Employee Name","width":130},
    
    {"field":"deletion_status","hide":true ,"headerName":"Deletion Status","width":130},
    {"field":"date_of_deletion","hide":true ,"headerName":"Date of Deletion","width":130},
    {"field":"reason_for_deletion","hide":true ,"headerName":"Reason for Deletion","width":130}
];

function readTemplate() {
    // axios.get(templatePath).then((res)=>{
    //     console.log("res",res)
    // })
}

function BulkUpload() {
  const errRef = useRef();
  const [validName, setValidName] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);

  var onCellEditStopFn = (event,newCell) => {
    var obj = {};
    obj[newCell.field] = event.target.value;
  }
  
  useEffect(()=>{
    /*getAssets().then(res=>{
      if(res?.data?.data?.length) setItems(res.data.data);
    })*/
  },[])
  const onChangeFile = async (event) => {
    try { setLoading(true);
    const data = new FormData() 
    data.append('file', event.target.files[0]);
    uploadAllocation(data).then(res => {

      if(res?.data?.message){
        setSuccess(res.data.message);
      }
      //setSuccess(res.data.message);
    })
    setLoading(false); // Stop loading
    } catch (error) {
    setLoading(false); // Stop loading in case of error
    console.error(error);
    setErrMsg(error.data.message);
    }
  };
  // readAssets();
  return (
    <div className='container'>
      <div className='row single__assets'>
                <div className="col">
                    <h5>Bulk Disposal</h5>
                </div>
                {success ? (
				<div className="alert alert-success" role="alert"><i className="bi bi-check-circle-fill"></i>{success}</div>
			        ) : ( errMsg ? (<div className="alert alert-danger" role="alert"><i className="bi bi-exclamation-triangle-fill"></i> {errMsg}</div>):('')
                ) }
      </div>
            
      <div className="row form-fields">
        <div className='col-6'>
        <div className="row form-fields">
        <div className="label col-6"> 
            <label>Upload Excel </label>
            <span className="star error"> *</span> 
        </div>
            <div className="float-start col-6">
                <Button variant='contained' component='label' className="btn btn-primary btn-md">
                <i className="bi bi-upload"></i> upload
                    <input hidden type='file' onChange={onChangeFile} />
                </Button>
                {loading ?  <div className="spinner-border text-primary" role="status">
                              <span className="sr-only">.</span>
                            </div> : <></> }
            </div>
         </div>             
        </div>
        
        <div className='col-6'>
                
        <a href={templatePath} className="text-decoration-none btn btn-secondary btn-sm">
        Download Template <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"></path>
        <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"></path>
        </svg></a>
        </div>
      </div>
      
    </div>
  )
}

export default BulkUpload
