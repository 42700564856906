import React, { useState, useEffect } from 'react';
import { getAssets, saveAssetDisposal } from '../../../services/asset.service';
import FromFieldText from '../../../atoms/FromFieldText';
import FromFieldDate from '../../../atoms/FromFieldDate';
import "react-datepicker/dist/react-datepicker.css";

import { DataGrid } from '@mui/x-data-grid';
// import axios from "axios";
// import { read } from "xlsx";



const columns = [

    { "field": "sl_no", "hideable": true, "hide": false,  "headerName": "Sl No", "width": 50 },
    { "field": "dIN_no", "hideable": true, "hide": false,  "headerName": "DIN No", "width": 130 },
    {
        "field": "tag", "hide": false, "headerName": "TAG", "width": 80,
        renderCell: (params) => (
            <a href={`/asset-disposal?tag=${params.value}`}>{params.value}</a>
        )
    },
    { "field": "tAG_no", "hideable": true, "hide": false,  "headerName": "TAG No", "width": 130 },
    { "field": "final_buyer_no",  "hide": false, "headerName": "Final Buyer No", "width": 130 },
    { "field": "final_buyer_name",  "hide": false, "headerName": "Final Buyer Name", "width": 130 },
    { "field": "price_quoted",  "hide": false, "headerName": "Price Quoted", "width": 130 },
    { "field": "final_sale_price_w/o_GST",  "hide": false, "headerName": "Final Sale Price W/o GST", "width": 130 },
    { "field": "final_date_of_sale",  "hide": false, "headerName": "Final Date of Sale", "width": 130 },
];

function SingleFinalizeDealList() {
    const [items, setItems] = useState([]);
    var onCellEditStopFn = (event, newCell) => {
        var obj = {};
        obj[newCell.field] = event.target.value;
    }

    useEffect(() => {
        getAssets().then(function (res) {
            //if(res?.data?.data) {
            if (res?.data?.data?.length) setItems(res.data.data);
        })
    }, [])

    // readAssets();
    return (
        <div className='container'>
            <h3>
                <h2> Single Finalize Deal List</h2>

            </h3>
            <DataGrid
                rows={items}
                getRowId={(row) => row.sl_no}
                columns={columns}
                pageSize={5}
                autoHeight
                rowsPerPageOptions={[5]}
                onCellEditStop={onCellEditStopFn}
            />
            <div className="row mt-4">
                <div className="col-4">
                    <FromFieldDate
                        label="Final Approval Date"
                        inputValue=''
                        setInputValue=''
                    />
                </div>
                <div className="col-4">
                    <FromFieldText
                        label="Final Approval By"
                        placeholder='Raghu'
                        inputValue=''
                        setInputValue=''
                    />
                </div>
                <div className="col-4">
                    <FromFieldText
                        label="Generate Ref No"
                        placeholder=''
                        inputValue=''
                        setInputValue=''
                    />
                </div>
            </div>

            <div className="row mt-4">
                <div className='col-12 text-center'>
                    <button className="btn btn-primary me-2"> Edit </button>
                    <button className="btn btn-primary me-2"> Cancel </button>
                    <button className="btn btn-primary"> Submit </button>
                </div>
            </div>

        </div>
    )
}

export default SingleFinalizeDealList


