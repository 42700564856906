import React, { useState,useRef,useEffect,useCallback } from 'react';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import { getClasificationsList } from '../../../services/user.service'
import { uploadAssetsCapt } from '../../../services/asset.service'
import  * as CryptoJS  from "crypto-js";
import './AssetInfo.css';

const templatePath = "/exceltemplates/Parent_Asset_capitalization_Template.xlsx"
const childTemplatePath = "/exceltemplates/Child_Asset_capitalization_Template.xlsx"

const columns = [
  {"field":"id","editable":false,"hide":true ,"headerName":"Category Id","width":100},
  {"field":"category_name","editable":false,"hide":false ,"headerName":"Financials Classification","width":350},
  {"field":"company_details","editable":false,"hide":true ,"headerName":"Category Details","width":350},
];

function AssetInfo() {

  const errRef = useRef();
  const [validName, setValidName] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [items, setItems] = useState([]);
  var onCellEditStopFn = (event,newCell) => {
    var obj = {};
    obj[newCell.field] = event.target.value;
  }
  
  useEffect(()=>{
    //encrypted data
    let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
      //'param_data':obj,
      'authToken': sessionStorage.getItem('LoggedInToken'),
    }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
    let oData = {'token':EncryptData}

    getClasificationsList(oData).then(res=>{
      if(res?.data?.data?.length) setItems(res.data.data);
    })
  },[]);

  const onChangeFile = async (event) => {
    event.preventDefault(); 
    console.log(event.target.files[0]);
    if (event.target.files[0]?.name.split('.').pop() == 'undefined' || event.target.files[0]?.name.split('.').pop() !== 'xlsx') {
      //alert('Please upload valid format. excel file only allowed.'); 
      setErrMsg("Please upload valid format. excel file only allowed."); 
      return false;
    }else{ //alert('validName');
    //submit form
    
    try {
      const data = new FormData() 
      data.append('file', event.target.files[0]);
      data.append('capitalization_status', 'Y');
      data.append('company_id', sessionStorage.getItem('LoggedInCompanyId'));
        data.append('division_id', sessionStorage.getItem('LoggedInDivisionId'));
        data.append('created_by', sessionStorage.getItem('LoggedInUserId'));
      data.append('child_capt', 'no');
        setLoading('..'); // Set loading before sending API request
      /*  let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
          'param_data':data,
          'authToken': sessionStorage.getItem('LoggedInToken'),
      }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
      let oData = {'token':EncryptData}*/
        uploadAssetsCapt(data).then(res => {
          if((typeof(res.data.errors)!== "undefined") && res.data.errors.errno>0){ 
            setErrMsg(res.data.message);setLoading(false);
            setSuccess(false);
          }else{
          setSuccess(res.data.message);
          setLoading(false); // Stop loading
          }
        })
        
        } catch (error) {
        setLoading(false); // Stop loading in case of error
        console.error(error);
        setErrMsg(error.data.message);
        }
    }//end else

  };

  // for child bulk upload
  const onChangeFile1 = async (event) => {
    event.preventDefault(); 
    console.log(event.target.files[0]);
    if (event.target.files[0]?.name.split('.').pop() == 'undefined' || event.target.files[0]?.name.split('.').pop() !== 'xlsx') {
      //alert('Please upload valid format. excel file only allowed.'); 
      setErrMsg("Please upload valid format. excel file only allowed."); 
      return false;
    }else{ //alert('validName');
    //submit form
    try {
    const data = new FormData() 
    data.append('file', event.target.files[0]);
    data.append('capitalization_status','Y');
    data.append('company_id', sessionStorage.getItem('LoggedInCompanyId'));
        data.append('division_id', sessionStorage.getItem('LoggedInDivisionId'));
        data.append('created_by', sessionStorage.getItem('LoggedInUserId'));
    data.append('child_capt', 'yes');
        setLoading1('..'); // Set loading before sending API request
        uploadAssetsCapt(data).then(res1 => {
          if((typeof(res1.data.errors)!== "undefined") && res1.data.errors.errno>0){ 
            setErrMsg(res1.data.message);setLoading1(false);
            setSuccess(false);
          }else{
          setSuccess(res1.data.message);
          setLoading1(false); // Stop loading
          }
        })
        
        } catch (error) {
        setLoading1(false); // Stop loading in case of error
        console.error(error);
        setErrMsg(error.data.message);
        }
      }//end else

  };
  // readAssets();
  return ( <div>
    <div className='container'>
      <div className='row single__assets'>
                <div className="col-9">
                    <h5>Bulk Capitalization</h5>
                </div>
                <div className="col-3"><a href={'/assets-capitalization'} className="btn btn-link"><i className='bi bi-list'></i> Capitalization List</a></div>
                {success ? (
				<div className="alert alert-success" role="alert"><i className="bi bi-check"></i>{success}</div>
			        ) : ( errMsg ? (<div className="alert alert-danger" role="alert"><i className="bi bi-exclamation-triangle-fill"></i> {errMsg}</div>):('')
                ) }
      </div>
        <hr></hr>    
      <div className="row form-fields ">
        <div className='col-7'>
        <div className="row form-fields">
        <div className="label col-5"> 
            <label>Select Parent Capitalization Excel <span className="star error"> *</span></label>
             
        </div>
            <div className="float-start col-4">
            
                <Button variant='contained' component='label' className="btn btn-primary btn-md btn-sm">
                <i className="bi bi-upload"></i> upload
                    <input hidden type='file' onChange={onChangeFile} />
                </Button>
                {loading ? (<div className="spinner-border text-primary" role="status">
                              <span className="sr-only">{loading}</span>
                            </div>) : ('') }
            </div>
         </div>             
        </div>
        
        <div className='col-5'>
        <a href={templatePath} className="text-decoration-none btn btn-secondary btn-sm">
        Download Parent Capitalization Template <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"></path>
  <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"></path>
</svg></a>
      </div>
      </div>
    <hr></hr>
      <div className="row form-fields">
        <div className='col-7'>
        <div className="row form-fields">
        <div className="label col-5"> 
            <label>Select Child Capitalization Excel<span className="star error"> *</span></label>
             
        </div>
            <div className="float-start col-4">
            
                <Button variant='contained' component='label' className="btn btn-primary btn-md">
                <i className="bi bi-upload"></i> upload
                    <input hidden type='file' onChange={onChangeFile1} />
                </Button>
                {loading1 ? (<div className="spinner-border text-primary" role="status">
                              <span className="sr-only">{loading1}</span>
                            </div>) : ('') }
            </div>
         </div>             
        </div>
        
        <div className='col-5'>  <a href={childTemplatePath} className="text-decoration-none btn btn-secondary btn-sm">
        Download Child Capitalization Template <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"></path>
  <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"></path>
</svg></a>
        </div>
      </div>

    </div>
    <div className="row form-fields">
    <div className='col-12'>
        <div className='row'>
        <b>Note:</b>
        <p>1. For Dates, always use YYYY-MM-DD.</p>
        <p>2. Not to Leave Blank on any cell, please use "NA" in place of text and use "0" in place of "Numbers" as appropriate.</p>
        <p>3. For company name use " {sessionStorage.getItem("LoggedInCompanyName")} " and For branch name use " {sessionStorage.getItem("LoggedInDivisionName")} ". </p>
        </div>
      </div>
    <div className='col-6'>
    <div className='row'>
  <div className='col-md-6'><h5>Financials Classification</h5></div>
  </div>
  <DataGrid
    rows={items}
    getRowId={(row) => row.id}
    columns={columns}
    pageSize={50}
    autoHeight 
    rowsPerPageOptions={[50]}
    //onCellEditStop={onCellEditStopFn}
  />
    </div>
  </div>
  </div>
  )
}

export default AssetInfo
