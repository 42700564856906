import React, { useState, useEffect } from 'react';

function InitiateSingleTransferFour() {

    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h5>Initiate Single Disposal- Four</h5>
                </div>
            </div>

            <div className="row mt-4 single__asset__form bgcolor">
                <div className="col-6">
                    <div className="row form-fields">
                        <div className="label col-6" >
                            <label> Mode of Transport </label>
                            <span className="star error">* </span>
                        </div>
                        <div className="item col-6" >
                            <input type='text'
                                name=""
                                value=''
                                className="form-control"
                                placeholder=''
                            />
                            <span className="invalid-feedback"> </span>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="row form-fields">
                        <div className="label col-6" >
                            <label> Transporter Name </label>
                            <span className="star error">* </span>
                        </div>
                        <div className="item col-6" >
                            <input type='text'
                                name=""
                                value=''
                                className="form-control"
                                placeholder=''
                            />
                            <span className="invalid-feedback"> </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row single__asset__form bgcolor">
                <div className="col-6">
                    <div className="row form-fields">
                        <div className="label col-6" >
                            <label> AWB/Shipping Doc No </label>
                            <span className="star error">* </span>
                        </div>
                        <div className="item col-6" >
                            <input type='text'
                                name=""
                                value=''
                                className="form-control"
                                placeholder=''
                            />
                            <span className="invalid-feedback"> </span>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="row form-fields">
                        <div className="label col-6" >
                            <label> Date of Shipping </label>
                            <span className="star error">* </span>
                        </div>
                        <div className="item col-6" >
                            <input type='text'
                                name=""
                                value=''
                                className="form-control"
                                placeholder=''
                            />
                            <span className="invalid-feedback"> </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row single__asset__form bgcolor">
                <div className="col-6">
                    <div className="row form-fields">
                        <div className="label col-6" >
                            <label> Contact Person </label>
                            <span className="star error">* </span>
                        </div>
                        <div className="item col-6" >
                            <input type='text'
                                name=""
                                value=''
                                className="form-control"
                                placeholder=''
                            />
                            <span className="invalid-feedback"> </span>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="row form-fields">
                        <div className="label col-6" >
                            <label> Contact Number </label>
                            <span className="star error">* </span>
                        </div>
                        <div className="item col-6" >
                            <input type='text'
                                name=""
                                value=''
                                className="form-control"
                                placeholder=''
                            />
                            <span className="invalid-feedback"> </span>
                        </div>
                    </div>
                </div>
            </div>


            <div className="row single__asset__form bgcolor">
                <div className="col-6">
                    <div className="row form-fields">
                        <div className="label col-6" >
                            <label> Receiver of Consignment </label>
                            <span className="star error">* </span>
                        </div>
                        <div className="item col-6" >
                            <input type='text'
                                name=""
                                value=''
                                className="form-control"
                                placeholder=''
                            />
                            <span className="invalid-feedback"> </span>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="row form-fields">
                        <div className="label col-6" >
                            <label> Mail ID of Receiver </label>
                            <span className="star error">* </span>
                        </div>
                        <div className="item col-6" >
                            <input type='text'
                                name=""
                                value=''
                                className="form-control"
                                placeholder=''
                            />
                            <span className="invalid-feedback"> </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row single__asset__form bgcolor">
                <div className="col-6">
                    <div className="row form-fields">
                        <div className="label col-6" >
                            <label> Mobile of Receiver </label>
                            <span className="star error">* </span>
                        </div>
                        <div className="item col-6" >
                            <input type='text'
                                name=""
                                value=''
                                className="form-control"
                                placeholder=''
                            />
                            <span className="invalid-feedback"> </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-12 text-center">
                    <button className="btn btn-primary me-2" > Edit </button>
                    <button className="btn btn-primary me-2" > Cancel </button>
                    <button className="btn btn-primary" > Submit </button>
                </div>
            </div>


        </div>
    )
}

export default InitiateSingleTransferFour;