import React, {useState} from 'react';
import './atom.css';
import Modal from 'react-modal';
import {updateTicketStatus} from "../services/tickets.service";
import * as CryptoJS from "crypto-js";


const TicketStatusUpdatePopUpBox = ({ticketCode, ticketStatus, isOpen, onClose, onConfirm}) => {
    const [loading, setLoading] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [items, setItems] = useState([]);

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    const TEXT_REGEX = /^[a-zA-Z0-9-_ ]{1,500}$/;

    const [selectedStatus, setSelectedStatus] = useState('');

    const [ErrTicketStatus, setErrticketStatus] = useState(false);

    const handleStatusChange = (e) => {
        setSelectedStatus(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        var valid_Name = true;

        if (!TEXT_REGEX.test(selectedStatus)) {
            setErrticketStatus('Please Select Status to update Ticket Status');
            valid_Name = false;
            //return;
        } else {
            setErrticketStatus('');
        }

        if (valid_Name) { //alert('test1');
            saveData(); //submit form
        } else {
            return false;
        }
    }

    const saveData = function () {
        if (ticketCode !== '' && ticketCode !== null) {

            // Convert the decrypted bytes to a string

            var obj = {
                'ticket_code': ticketCode,
                'ticket_status': selectedStatus
            }
        }

        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': obj,
            //'param_data': {'ticket_code': ticketCode},
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token': EncryptData}
        try {
            updateTicketStatus(oData).then(function (res) {
                console.log(res);
                if ((typeof (res.data.errors) != "undefined") && res.data.errors.errno > 0) {
                    setErrMsg(res.data.message);
                    setSuccess(false);
                } else {
                    setSuccess(res.data.message);
                    resetAssetInfo();
                    setItems();
                }
            });

            //setValidName(false);
        } catch (err) {
            setSuccess(false);
            if (!err?.data) {
                //console.log(err);
                setErrMsg('No Server Response');
            } else if (err.data?.status === 409) {
                setErrMsg('data already exist');
            } else {
                setErrMsg('Insert/Update Failed');
            }
            //elementRef.current.focus();
        }
    }

    const resetAssetInfo = function () {
        setSelectedStatus('');
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Confirm Box"
            style={modalStyles}
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Ticket Status Update</h5>
                        <button type="button" class="btn-close" onClick={onClose}></button>
                    </div>
                    <div class="modal-body">
                        <h6 className="modal-title">Ticket Code - {ticketCode} | Current Status - {ticketStatus}</h6>
                    </div>
                    <div style={modalBody}>


                        {success ? (
                            <div className="alert alert-success" role="alert"><i
                                className="bi bi-check-circle-fill"></i>{success}</div>
                        ) : (errMsg ? (<div className="alert alert-danger" role="alert"><i
                                className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>) : ('')
                        )}

                        <form onSubmit={handleSubmit}>

                            <div className="row mt-3">

                                <div className="label col-3">
                                    <label>Ticket Status</label><span className="star error"> *</span>
                                </div>

                                <div className="col-6">
                                    <select value={selectedStatus} onChange={handleStatusChange}>
                                        <option >--- Select Status ---</option>
                                        <option value="Open">Open</option>
                                        <option value="Assigned">Assigned</option>
                                        <option value="Comments added by agent">Comments added by agent</option>
                                        <option value="Comments added by user">Comments added by user</option>
                                        <option value="Completed and closed">Completed and closed</option>
                                        <option value="Closed on user request">Closed on user request</option>
                                        <option value="Cancelled">Cancelled</option>
                                        <option value="Deferred">Deferred</option>
                                    </select>
                                    <span className="invalid-feedback"> {ErrTicketStatus} </span>
                                </div>

                                <div className="col-3">
                                    {(ticketCode !== '' && ticketCode !== null) ?
                                        (<button className="btn btn-primary btn-md"> Update </button>) :
                                        <button className="btn btn-primary btn-md"> SAVE </button>}

                                </div>

                            </div>


                        </form>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

const modalStyles = {
    content: {
        width: '550px',
        height: '250px',
        textAlign: 'center',
    },
};
const modalBody = {
    content: {
        position: 'relative',
        flex: '1 1 auto',
        padding: '10px !important'
    },
}
export default TicketStatusUpdatePopUpBox;