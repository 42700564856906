import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

function FromFieldTimeHari(props) {
    return (
        <div className="row">
            <div className="col-md-6">
                <DatePicker
                    wrapperClassName="custom-form-control"
                    className="form-control"
                    placeholderText={props.placeholder ? props.placeholder : 'Select Time'}
                    selected={props.inputValue}
                    onChange={(time) => props.setInputValue(time)} // Handle time change
                    showTimeSelect // Enable time selection
                    showTimeSelectOnly // Only show the time picker
                    timeFormat="HH:mm" // Use 24-hour format
                    timeIntervals={15} // 15-minute intervals
                    dateFormat="HH:mm" // Format for time display
                    readOnly={props.readOnly}
                    minTime={props.minTime} // Set minimum time if needed
                    maxTime={props.maxTime} // Set maximum time if needed
                />
                <span className="invalid-feedback">{props.errorMsg}</span>
            </div>
        </div>
    );
}

export default FromFieldTimeHari;
