import React, { useState, useEffect } from 'react';

function InitiateBulkTransferList() {
    const myStyle = {
        fontWeight: 700,
        color: '#fff',
        background: '#000531',
        padding: '5px'
    }
  
    return (
        <div className='container'>
            <h3>
                <h2> Initiate Bulk Transfer List</h2>

            </h3>

            <div className='row mt-4'>
                <p style={{marginBottom:'5px',fontWeight:'700',fontSize:'13px'}}>Internal Transfer Excel Format</p>
                <div className='table-responsive'>
                    <table className='table-bordered w-100 text-center'>
                        <thead>
                            <tr>
                                <td style={myStyle}>TAG No</td>
                                <td style={myStyle}>Movement Initiated By</td>
                                <td style={myStyle}>Date of Initiation</td>
                                <td style={myStyle}>Movement Approved By</td>
                                <td style={myStyle}>Date of Approval</td>
                                <td style={myStyle}>Reason for Movement</td>
                                <td style={myStyle}>Target Date for Completion</td>
                                <td style={myStyle}>New Division*</td>
                                <td style={myStyle}>New Premises*</td>
                                <td style={myStyle}>New Floor*</td>
                                <td style={myStyle}>New Site ID*</td>
                                <td style={myStyle}>New User Type*</td>
                                <td style={myStyle}>New User Department*</td>
                                <td style={myStyle}>New Employee ID</td>
                                <td style={myStyle}>New Employee Name</td>
                            </tr>
                        </thead>
                        <tbody>
                           <tr>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                           </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div className='row mt-5'>
                <p style={{marginBottom:'5px',fontWeight:'700',fontSize:'13px'}}>External Transfer Excel Format</p>
                <div className='table-responsive'>
                    <table className='table-bordered w-100 text-center'>
                        <thead>
                            <tr>
                                <td style={myStyle}>TAG No</td>
                                <td style={myStyle}>Movement Initiated By</td>
                                <td style={myStyle}>Date of Initiation</td>
                                <td style={myStyle}>Movement Approved By</td>
                                <td style={myStyle}>Date of Approval</td>
                                <td style={myStyle}>Reason for Movement</td>
                                <td style={myStyle}>Target Date for Completion</td>
                                <td style={myStyle}>Mode of Transport *</td>
                                <td style={myStyle}>Transporter Name *</td>
                                <td style={myStyle}>AWB/Shipping Doc No *</td>
                                <td style={myStyle}>Date of Shipping *</td>
                                <td style={myStyle}>Contact Person *</td>
                                <td style={myStyle}>Contact Number *</td>
                                <td style={myStyle}>Receiver of Consignment *</td>
                                <td style={myStyle}>Mail ID of Receiver *</td>
                                <td style={myStyle}>Mobile of Receiver *</td>
                            </tr>
                        </thead>
                        <tbody>
                           <tr>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                           </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    )
}

export default InitiateBulkTransferList


