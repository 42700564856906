import React, { useState, useEffect } from 'react';
import FromFieldLabel from '../../../atoms/FromFieldLabel';
import FromFieldText from '../../../atoms/FromFieldText';
import FromFieldDate from '../../../atoms/FromFieldDate';
import "react-datepicker/dist/react-datepicker.css";



function InitiateSingleTransfer() {

    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h5>Initiate Branch Transfer</h5>
                </div>
            </div>


            <div className="row">
                <div className="col-6">
                    <FromFieldText
                        label="TIN No"
                        placeholder="TIN No"
                        inputValue=''
                        setInputValue=''
                    />
                </div>
                <div className="col-6">
                    <FromFieldLabel
                        label="Transfer Identification No"
                        inputValue=''
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-6">
                    <FromFieldText
                        label="Movement Initiated By"
                        placeholder="Movement Initiated By"
                        inputValue=''
                        setInputValue=''
                    />
                </div>
                <div className="col-6">
                    <FromFieldDate
                        label="Date of Initiation"
                        inputValue=''
                        setInputValue=''
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-6">
                    <FromFieldText
                        label="Movement Approved By"
                        placeholder="Movement Approved By"
                        inputValue=''
                        setInputValue=''
                    />
                </div>
                <div className="col-6">
                    <FromFieldDate
                        label="Date of Approval"
                        inputValue=''
                        setInputValue=''
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-6">
                    <FromFieldText
                        label="Reason for Movement"
                        placeholder="Reason for Movement"
                        inputValue=''
                        setInputValue=''
                    />
                </div>
                <div className="col-6">
                    <FromFieldDate
                        label="Target Date for Completion"
                        inputValue=''
                        setInputValue=''
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <label style={{ marginRight: '10px' }}> Upload Approval Mail </label>
                    <input type="file" name="asset_invoice" />
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-4"></div>
                <div className="col-4 text-center">
                    <button className="btn btn-primary" > Next </button>
                </div>
                <div className="col-4"></div>
            </div>



        </div>
    )
}

export default InitiateSingleTransfer;