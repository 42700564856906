import React, { useState, useEffect } from 'react';
import { getAssets, saveAssetDisposal } from '../../../services/asset.service';

import { DataGrid } from '@mui/x-data-grid';
// import axios from "axios";
// import { read } from "xlsx";



const columns = [

    { "field": "sl_no", "hideable": true, "hide": false, "headerName": "Sl No", "width": 50 },
    {
        "field": "tag",  "hide": false, "headerName": "TAG", "width": 80,
        renderCell: (params) => (
            <a href={`/asset-disposal?tag=${params.value}`}>{params.value}</a>
        )
    },
    { "field": "supplier_name", "hideable": true, "hide": false, "headerName": "Supplier Name", "width": 130 },
    { "field": "financial_classification", "hide": false, "headerName": "Financial Classification", "width": 130 },
    { "field": "capitalized_value", "hide": false, "headerName": "Capitalized Value", "width": 130 },
    { "field": "acc_dep_upto_date", "hide": false, "headerName": "Acc Dep upto Date", "width": 130 },
    { "field": "wDV_value_on_date", "hide": false, "headerName": "WDV Value on Date", "width": 130 },
    { "field": "type_of_disposal", "hide": false, "headerName": "Type of Disposal", "width": 130 },
    { "field": "partial_specify", "hide": false, "headerName": "If Partial, Specify as % of Capitalized Value", "width": 130 },
    { "field": "sub_status", "hide": false, "headerName": "Sub Status", "width": 130 },
];

function FormList() {
    const [items, setItems] = useState([]);
    var onCellEditStopFn = (event, newCell) => {
        var obj = {};
        obj[newCell.field] = event.target.value;
    }

    useEffect(() => {
        /*getAssets().then(function (res) {
            //if(res?.data?.data) {
            if (res?.data?.data?.length) setItems(res.data.data);
        })*/
    }, [])

    // readAssets();
    return (
        <div className='container'>
            <div class="row"><div class="col">
                <h5>Initiate Single Disposal - List</h5>
            </div></div>
            <DataGrid
                rows={items}
                getRowId={(row) => row.sl_no}
                columns={columns}
                pageSize={5}
                autoHeight
                rowsPerPageOptions={[5]}
                onCellEditStop={onCellEditStopFn}
            />

            <div className="row mt-4">
                <div className="col-12 text-center">
                    <button className="btn btn-primary me-2"> Edit </button>
                    <button className="btn btn-primary me-2"> Cancel </button>
                    <button className="btn btn-primary"> INITIATE  / UPDATE </button>
                </div>
            </div>

        </div>
    )
}

export default FormList


