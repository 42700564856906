import React, {useEffect, useState} from 'react'
//import Chart from "chart.js/auto";
//import { CategoryScale } from "chart.js";
import {
    Bar,
    BarChart,
    CartesianGrid,
    Cell,
    Legend,
    Pie,
    PieChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from 'recharts';
import {getDashboardDetails} from '../../services/asset.service';
import './Dashboard.css';
import * as CryptoJS from "crypto-js";

//import { Bar, Pie } from 'react-chartjs-2';
function Dashboard() {
    const [barChartData, setBarChartData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [assetInfo, setAssetInfo] = useState([]);
    const [BangaloreInfo, setBangaloreDataInfo] = useState([]);
    const [ChennaiInfo, setChennaiDataInfo] = useState([]);
    const [HyderabadInfo, setHyderabadDataInfo] = useState([]);
    const [allocatedInfo, setAllocatedDataInfo] = useState([]);
    const [notAllocatedInfo, setNotAllocatedDataInfo] = useState([]);
    const [yearOfAdditionInfo, setYearOfAdditionDataInfo] = useState([]);
    useEffect(() => {
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            //'param_data':obj,
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token': EncryptData}
        setLoading(true); // Show loader before fetching data
        getDashboardDetails(oData).then(res => {

            if (res?.data?.data?.assetInfo) { //res.data.data
                //setBarChartData(res.data.data);
                console.log(res.data.data.assetInfo)
                //var {assetInfo} = res.data.data;
                //console.log(assetInfo)
                setAssetInfo(res.data.data.assetInfo);
                setBangaloreDataInfo(res.data.data.bangaloreData);
                setChennaiDataInfo(res.data.data.chennaiData);
                setHyderabadDataInfo(res.data.data.hyderabadData);
                setAllocatedDataInfo(res.data.data.allocatedCountData);
                setNotAllocatedDataInfo(res.data.data.notAllocatedCountData);
                setYearOfAdditionDataInfo(res.data.data.yearOfAdditionData);
            }
        }).catch(error => {
            console.error('Error fetching data:', error);
            // Handle error if needed
        }).finally(() => {
            setLoading(false); // Hide loader when data fetching is done (success or failure)
        });
    }, []);
    const data = [
        {name: 'Category 1', value: 30},
        {name: 'Category 2', value: 50},
        {name: 'Category 3', value: 20},
    ];
    const IdentifyData = [
        {name: 'Identifiable', value: assetInfo?.count_assets - allocatedInfo?.allocated_tag},
        {name: 'Non Identifiable', value: notAllocatedInfo.not_allocated_tag},

    ];
    const COLORS = ['#0088FE', '#00C49F'];
    const COLORS1 = ['#0088FE', '#00C49F', '#FFBB28'];
    return (

        <div className="row">
            <div className="col-md-12 mt-5 mb-5 text-center" style={{border: '2px solid', padding: '20px', marginLeft: '-5px', borderRadius: '10px'}}>
                <h3>Welcome</h3>
            </div>
        </div>




    );
}

export default Dashboard