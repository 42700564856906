import React, { useState,useEffect } from 'react';
import { getAssets, saveAssetDisposal } from '../../../services/asset.service';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import  * as CryptoJS  from "crypto-js";
import '../../ExcelUploads/AssetInfo/AssetInfo.css';

const columns = [
    {"field":"sl_no","hideable":true,"hide":true,"headerName":"Sl No","width":50},

    {
        field: 'tag',
        editable: false,
        hide: false,
        headerName: 'TAG',
        width: 250,
        renderCell: (params) => {
            let encryptedTag = CryptoJS.AES.encrypt(JSON.stringify({
                'tag': params.value,
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

            return (
                <a href={`/asset-disposal?tag=${encryptedTag}`}>{params.value}</a>
            );
        }
    },

    {"field":"division","hideable":true,"hide":false ,"headerName":"Division","width":130},
    {"field":"premises","hide":false ,"headerName":"Premises","width":130},
    {"field":"floor","hide":false ,"headerName":"Floor","width":130},
    {"field":"site_id","hide":false ,"headerName":"Site ID","width":130},
    {"field":"user_type","hide":false ,"headerName":"User Type","width":130},
    {"field":"user_department","hide":false ,"headerName":"User Department","width":130},
    {"field":"employee_id","hide":false ,"headerName":"Employee ID","width":130},
    {"field":"employee_name","hide":false ,"headerName":"Employee Name","width":130},
    {"field":"status","hide":true ,"headerName":"Status","width":130},
];

function AssetDisposalList() {
  const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
  var onCellEditStopFn = (event,newCell) => {
    var obj = {};
    obj[newCell.field] = event.target.value;
  }

    useEffect(() => {
        let EncryptData = CryptoJS.AES.encrypt(
            JSON.stringify({
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }),
            process.env.REACT_APP_ENCRYPT_SECRET_KEY
        ).toString();
        let oData = { 'token': EncryptData };

        setLoading(true);

        getAssets(oData)
            .then((res) => {
                if (res?.data?.data?.length) {
                    setItems(res.data.data);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);
  
  // readAssets();
  return (
    <div className='container'>
      <h5> Asset Disposal List</h5>
      <Box>
          {loading ? (
              <div className="text-center">
                  <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden">Loading...</span>
                  </div>
              </div>
          ) : (
              <DataGrid
                  rows={items}
                  getRowId={(row) => row.sl_no}
                  columns={columns}
                  pageSize={50}
                  autoHeight
                  rowsPerPageOptions={[50]}
                  onCellEditStop={onCellEditStopFn}
              />
          )}

      </Box>
    </div>
  )
}

export default AssetDisposalList


