import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import FromFieldText from '../../atoms/FromFieldText';
import FromSpFieldText from '../../atoms/FromSpFieldText';
import { getTAGMappingData, linkRFIDTAG } from '../../services/asset.service';
import * as CryptoJS from "crypto-js";

const TEXT_REGEX = /^[A-Za-z0-9-_/.@= ]{1,155}$/;

function LinkDlinkRFID() {
    const [assetInfo, setAssetInfo] = useState({});
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    const [tag, setTag] = useState('');
    const [ErrTag, setErrTag] = useState('');

    const [epcNo, setEpcNo] = useState('');
    const [ErrEpcNo, setErrEpcNo] = useState('');


    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const Tag = query.get('tag');

    const resetAssetInfo = () => {
        setTag(''); // Reset tag state
        setEpcNo(''); // Reset epcNo state
        setAssetInfo({}); // Reset assetInfo state
    };

    useEffect(() => {
        const fetchData = async () => {
            if (Tag) {
                try {
                    const decryptedBytes = CryptoJS.AES.decrypt(Tag.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
                    const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
                    const decryptedTag = decrypt_data.tag;
                    await getTagAssetInfo(decryptedTag);
                } catch (error) {
                    console.error("Error decrypting Tag: ", error);
                }
            } else {
                resetAssetInfo();
            }
        };
        fetchData();
    }, [Tag]);

    const getTagAssetInfo = async (decryptedTag) => {
        resetAssetInfo();
        try {
            const EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': { 'tag': decryptedTag },
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            const oData1 = { 'token': EncryptData };
            const res = await getTAGMappingData(oData1);

            if (res?.data?.data?.assetInfo) {
                const aInfo = res.data.data.assetInfo;
                setAssetInfo(aInfo);
                setTag(aInfo.tag);
                setEpcNo(aInfo.epc_no);
                setErrTag(''); // Clear the error when data is successfully fetched
            } else {
                setErrMsg('Assets Not Found');
            }
        } catch (error) {
            console.error("Error fetching allocation: ", error);
        }
    };

    const getTagInfo = async () => {
        resetAssetInfo();
        if (!tag.trim()) {
            setErrMsg('TAG is required');
            return;
        }
        try {
            const EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': { 'tag': tag.trim() },
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            const oData = { 'token': EncryptData };
            const res = await getTAGMappingData(oData);

            if (res?.data?.data?.assetInfo) {
                setErrMsg('');
                const aInfo = res.data.data.assetInfo;
                setAssetInfo(aInfo);
                setTag(aInfo.tag);
                setEpcNo(aInfo.epc_no);
            } else {
                setErrMsg('Assets Not Found');
            }
        } catch (error) {
            console.error("Error fetching tag info: ", error);
            setErrMsg('Error fetching tag info');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let valid_Name = true;

        if (!TEXT_REGEX.test(tag) && tag.trim() !== 0) {
            setErrTag('TAG is required');
            valid_Name = false;
        } else {
            setErrTag('');
        }
        if (!TEXT_REGEX.test(epcNo)) {
            setErrEpcNo('EPC no is required');
            valid_Name = false;
        } else {
            setErrEpcNo('');
        }

        if (valid_Name) {
            saveData();
        }
    };

    const saveData = async () => {
        try {
            let obj = {
                'tag': tag,
                'epc_no': epcNo,
                'created_by': sessionStorage.getItem('LoggedInUserId'),
            };

            if (assetInfo.epc_no) {
                obj = {
                    'tag': tag,
                    'epc_no': '',
                };
            }

            const EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': obj,
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

            const oData = { 'token': EncryptData };
            const res = await linkRFIDTAG(oData);

            if (res.data.errors && res.data.errors.errno > 0) {
                setErrMsg(res.data.message);
                setSuccess(false);
            } else {
                setSuccess(res.data.message);
                resetAssetInfo();
            }
        } catch (err) {
            setSuccess(false);
            if (!err?.data) {
                setErrMsg('No Server Response');
            } else if (err.data?.status === 409) {
                setErrMsg('Data already exists');
            } else {
                setErrMsg('Delete Failed');
            }
        }
    };


    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h5>Link / De-link RFID TAG</h5>
                </div>
                {success ? (
                    <div className="alert alert-success" role="alert"><i className="bi bi-check-circle-fill"></i>&nbsp;{success}</div>
                ) : errMsg ? (
                    <div className="alert alert-danger" role="alert"><i className="bi bi-exclamation-triangle-fill"></i>&nbsp;{errMsg}</div>
                ) : null}
            </div>

            <div className='row'>
                <div className='col-12'>&nbsp;</div>
                {Tag ? (
                    <div className='row'>
                        <div className='col-10'></div>
                        <div className='col-2'></div>
                    </div>
                ) : (
                    <div className='row'>
                        <div className='col'>
                            <h5 className='align-right'>TAG </h5>
                        </div>
                        <div className='col'>
                            <input onChange={(e) => {
                                setTag(e.target.value);
                                setErrTag(''); // Clear error message on input change
                            }} value={tag || ''} type="text" className="form-control" placeholder='Enter the asset TAG'/>
                            <p className='invalid-feedback'>{ErrTag}</p>
                        </div>
                        <div className='col'>
                            <button onClick={getTagInfo} className='btn btn-info btn-sm'><i className="bi bi-search"></i> Search</button>
                            &nbsp;&nbsp;&nbsp;
                            <a href={'/link-dlink-rfid'}>
                                <button className='btn btn-info btn-sm'>Reset</button>
                            </a>&nbsp;
                        </div>
                    </div>
                )}
            </div>

            <div className="row mt-2">
                <div className="col-6">
                    <FromFieldText label="TAG No" inputValue={assetInfo.tag || ''} readOnly="readonly" />
                </div>
            </div>

            <div className="row mt-2">
                <div className='col-6'>
                    <FromFieldText label="TAG Name" inputValue={assetInfo.tag_name || ''} readOnly="readonly" />
                </div>
            </div>

            <form onSubmit={handleSubmit}>

                <div className="row mt-2">
                    <div className="col-6">
                        <FromSpFieldText
                            star="*"
                            label="Enter EPC No"
                            inputValue={epcNo}
                            setInputValue={setEpcNo}
                            setErrorMsg={setErrEpcNo}
                        />
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-3"></div>

                    <div className="col-6">
                        {tag && (
                            <>
                                {!assetInfo.epc_no && (
                                    <>
                                        <button className="btn btn-primary btn-md" type="submit">Link</button>
                                        &nbsp;
                                    </>
                                )}
                                {assetInfo.epc_no && assetInfo.status === 'Active' ? (
                                    <button className="btn btn-primary btn-md" type="submit">De-Link</button>
                                ) : (
                                    <button className="btn btn-primary btn-md" type="submit">Link</button>
                                )}


                            </>
                        )}
                    </div>
                </div>
            </form>
        </div>
    );
}

export default LinkDlinkRFID;

