import React, { useState, useEffect } from 'react';
import { getAssets, saveAssetDisposal } from '../../../services/asset.service';

import { DataGrid } from '@mui/x-data-grid';
// import axios from "axios";
// import { read } from "xlsx";



const columns = [

    {"field":"sl_no","hideable":true,"hide":false,"headerName":"Sl No","width":50},
    {"field":"din_no","hideable":true,"hide":true,"headerName":"DIN No","width":50},
    {
        "field": "tag", "hide": false, "headerName": "TAG", "width": 80,
        renderCell: (params) => (
            <a href={`/asset-disposal?tag=${params.value}`}>{params.value}</a>
        )
    },
    { "field": "buyer_1_name", "hideable": true, "hide": false, "headerName": "Buyer 1 Name", "width": 130 },
    { "field": "buyer_1_address", "hide": false, "headerName": "Buyer 1 Address", "width": 130 },
    { "field": "buyer_1_pan", "hide": false, "headerName": "Buyer 1 PAN", "width": 130 },
    { "field": "buyer_1_gstn", "hide": false, "headerName": "Buyer 1 GSTN", "width": 130 },
    { "field": "buyer_1_price_quoted", "hide": false, "headerName": "Buyer 1 Price Quoted", "width": 130 },
    { "field": "buyer_1_quote_received_date", "hide": false, "headerName": "Buyer 1 Quote received Date", "width": 130 },
    { "field": "buyer_1_quote_expiry_date", "hide": false, "headerName": "Buyer 1 Quote Expiry Date", "width": 130 },
    { "field": "buyer_2_name", "hide": false, "headerName": "Buyer 2 Name", "width": 130 },
    { "field": "buyer_2_address", "hide": false, "headerName": "Buyer 2 Address", "width": 130 },
    { "field": "buyer_2_pan", "hide": false, "headerName": "Buyer 2 PAN", "width": 130 },
    { "field": "buyer_2_gstn", "hide": false, "headerName": "Buyer 2 GSTN", "width": 130 },
    { "field": "buyer_2_price_quoted", "hide": false, "headerName": "Buyer 2 Price Quoted", "width": 130 },
    { "field": "buyer_2_quote_received_date", "hide": false, "headerName": "Buyer 2 Quote received Date", "width": 130 },
    { "field": "buyer_2_quote_expiry_date", "hide": false, "headerName": "Buyer 2 Quote Expiry Date", "width": 130 },
    { "field": "buyer_3_name", "hide": false, "headerName": "Buyer 3 Name", "width": 130 },
    { "field": "buyer_3_address", "hide": false, "headerName": "Buyer 3 Address", "width": 130 },
    { "field": "buyer_3_pan", "hide": false, "headerName": "Buyer 3 PAN", "width": 130 },
    { "field": "buyer_3_gstn", "hide": false, "headerName": "Buyer 3 GSTN", "width": 130 },
    { "field": "buyer_3_price_quoted", "hide": false, "headerName": "Buyer 3 Price Quoted", "width": 130 },
    { "field": "buyer_3_quote_received_date", "hide": false, "headerName": "Buyer 3 Quote received Date", "width": 130 },
    { "field": "buyer_3_quote_expiry_date", "hide": false, "headerName": "Buyer 3 Quote Expiry Date", "width": 130 },

];

function BulkAssetQuoteList() {
    const [items, setItems] = useState([]);
    var onCellEditStopFn = (event, newCell) => {
        var obj = {};
        obj[newCell.field] = event.target.value;
    }

    useEffect(() => {
        var data1= {
                'company_id':sessionStorage.getItem('LoggedInCompanyId'),
                'division_id':sessionStorage.getItem('LoggedInDivisionId'),
              }
        getAssets(data1).then(function (res) {
            //if(res?.data?.data) {
            if (res?.data?.data?.length) setItems(res.data.data);
        })
    }, [])

    // readAssets();
    return (
        <div className='container'>
            <h3>
                <h2> Bulk Asset Quote - List</h2>

            </h3>
            <DataGrid
                rows={items}
                getRowId={(row) => row.sl_no}
                columns={columns}
                pageSize={5}
                autoHeight
                rowsPerPageOptions={[5]}
                onCellEditStop={onCellEditStopFn}
            />


        </div>
    )
}

export default BulkAssetQuoteList


