import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import FromFieldText from '../../../atoms/FromFieldText';
import {getAllocation, getComment, saveComment} from '../../../services/asset.service';
import * as CryptoJS from "crypto-js";
import FromFieldTextArea from "../../../atoms/FromFieldTextArea";


function AddComments() {
    const TEXT_REGEX = /^[A-Za-z0-9-_/.@= ]{1,155}$/;

    const [validName, setValidName] = useState(true);
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    const [suppliername, setSupplierName] = useState('');
    const [ErrSupplierName, setErrSupplierName] = useState(false);

    const [additionalcomments, setAdditionalComments] = useState('');
    const [ErrAdditionalComments, setErrAdditionalComments] = useState(false);

    const resetAssetInfo = function () {
        setSupplierName('');
        setAdditionalComments('');
    }

    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const supplier_name = query.get('supplier_name');

    const comment_id = query.get('comment_ID');

    useEffect(() => {
        if (comment_id !== '' && comment_id !== null) {
            const decryptedBytes = CryptoJS.AES.decrypt(comment_id.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
            // Convert the decrypted bytes to a string
            const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
            const decryptCommentId = decrypt_data.commentid;
            getCommentInfo(decryptCommentId); // get comment info

        } else {
            resetAssetInfo();
        }
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token': EncryptData}

    }, [])
    const getCommentInfo = function (decryptCommentId) {
        resetAssetInfo();

        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': {'comment_id': decryptCommentId},
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData1 = {'token': EncryptData}
        getComment(oData1).then(function (res) {
            //getTransferData(Tag).then(function(res){
            if (res?.data?.data) {
                var aInfo = res.data.data[0];
                //console.log(aInfo);
                setAdditionalComments(aInfo.comment);
                setSupplierName(aInfo.suppliername);

            } else {
                setErrMsg('TAG comment Not Found');
            }

        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        var valid_Name = true;
        // if button enabled with JS hack
        //const v1 = TEXT_REGEX.test(division);
        //alert(suppliername);
        if (!TEXT_REGEX.test(suppliername) && suppliername !== 0) {
            setErrSupplierName('Supplier Name is required');
            valid_Name = false;
            //return;
        } else {
            setErrSupplierName('');
        }

        if (!TEXT_REGEX.test(additionalcomments)) {
            setErrAdditionalComments('Additional comments are required');
            valid_Name = false;
            //return;
        } else {
            setErrAdditionalComments('');
        }

        if (valid_Name) { //alert('test1');
            saveData(); //submit form
        } else {
            return false;
        }
    }

    const saveData = function (decryptCommentId) {
        if (comment_id !== '' && comment_id !== null) {
            var obj = {
                'comment': additionalcomments,
                'suppliername': suppliername,
                'comment_id': decryptCommentId,
                'created_by': sessionStorage.getItem('LoggedInUserId')
            }
        } else {
            var obj = {
                'comment': additionalcomments,
                'suppliername': suppliername,
                'created_by': sessionStorage.getItem('LoggedInUserId')
            }
        }

        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': obj,
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token': EncryptData}
        try {
            saveComment(oData).then(function (res) {
                console.log(res);
                if ((typeof (res.data.errors) != "undefined") && res.data.errors.errno > 0) {
                    setErrMsg(res.data.message);
                    setSuccess(false);
                } else {
                    setSuccess(res.data.message);
                    resetAssetInfo();
                }
            });

            //setValidName(false);
        } catch (err) {
            setSuccess(false);
            if (!err?.data) {
                //console.log(err);
                setErrMsg('No Server Response');
            } else if (err.data?.status === 409) {
                setErrMsg('data already exist');
            } else {
                setErrMsg('Insert/Update Failed');
            }
            //elementRef.current.focus();
        }
    }
    return (
        <div className="container">

            <div className="row">
                <div className="col">
                    <h5>Add Scan Serial No</h5>
                </div>
                <div className="invalid-feedback" role="alert">
                    <i className="fa fa-info-circle"></i>
                    Please Fill all mandatory field.
                </div>

                {success ? (
                    <div className="alert alert-success" role="alert"><i
                        className="bi bi-check-circle-fill"></i>{success}</div>
                ) : (errMsg ? (<div className="alert alert-danger" role="alert"><i
                        className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>) : ('')
                )}
            </div>


            <div className='row'></div>
            <div className="row">

                <div className="col-6">
                    <FromFieldText
                        label="Supplier Name"
                        inputValue={suppliername}
                        setInputValue={setSupplierName}
                        setErrorMsg={ErrSupplierName}
                    />
                </div>
                <div className='col-6'>&nbsp;</div>
                <div className='col-6'>&nbsp;</div>
            </div>

            <form onSubmit={handleSubmit}>


                <div className="row">

                    <div className="col-6">
                        <FromFieldTextArea star="*"
                                           label="Additional Comments"
                                           inputValue={additionalcomments}
                                           setInputValue={setAdditionalComments}
                                           setErrorMsg={ErrAdditionalComments}
                        />
                    </div>
                </div>

                <div className="row">

                    <div className="col-6">
                        <FromFieldTextArea star="*"
                                           label="Invoice No"
                                           inputValue={additionalcomments}
                                           setInputValue={setAdditionalComments}
                                           setErrorMsg={ErrAdditionalComments}
                        />
                    </div>
                </div>

                <div className="row">

                    <div className="col-6">
                        <FromFieldTextArea star="*"
                                           label="Invoice Date"
                                           inputValue={additionalcomments}
                                           setInputValue={setAdditionalComments}
                                           setErrorMsg={ErrAdditionalComments}
                        />
                    </div>
                </div>

                <div className="row">

                    <div className="col-6">
                        <FromFieldTextArea star="*"
                                           label="No of Records"
                                           inputValue={additionalcomments}
                                           setInputValue={setAdditionalComments}
                                           setErrorMsg={ErrAdditionalComments}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-3"></div>
                    <div className="col-6">
                        {(comment_id !== '' && comment_id !== null) ?
                            (<button className="btn btn-primary btn-md"> Update </button>) :
                            <button className="btn btn-primary btn-md"> SAVE </button>}

                    </div>
                    <div className="col-3"></div>
                </div>
            </form>
        </div>
    )
}

export default AddComments


