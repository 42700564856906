import React, {useEffect, useState} from 'react';
import FromFieldLabel from '../../../atoms/FromFieldLabel';
import FromFieldText from '../../../atoms/FromFieldText';
import FromFieldDate from '../../../atoms/FromFieldDate';
import "react-datepicker/dist/react-datepicker.css";
import * as CryptoJS from "crypto-js";
import {
    getAssetsTransferData,
    getBranchTransferList,
    getLastTinNo,
    saveAssetTransfer
} from "../../../services/transfer.service";
import {getDepartmentList, getDivisionList} from '../../../services/user.service';
import {DataGrid} from '@mui/x-data-grid';
//import {getAssets, getAssetsTransfer, saveFlagAsset} from '../../../services/asset.service'
import {useLocation} from "react-router-dom";
import Select from "react-select";


function getFormatedDate(dateString) {
    if (dateString != null) {
        let dte = new Date(dateString);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }
        return year + '-' + month + '-' + day;
        //return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
    } else {
        return null;
    }
}

function InitiateTransfer() {

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    const [tinno, setTinno] = useState('');
    const [tinnoError, setTinnoError] = useState('');

    const [movementInitiatedBy, setMovementInitiatedBy] = useState('');
    const [movementInitiatedError, setMovementInitiatedByError] = useState('');

    const [movementApprovedBy, setMovementApprovedBy] = useState('');
    const [movementApprovedByError, setMovementApprovedByError] = useState('');

    const [dateInitiation, setDateInitiation] = useState('');
    const [dateInitiationError, setDateInitiationError] = useState('');

    const [dateApproval, setDateApproval] = useState('');
    const [dateApprovalError, setdateApprovalError] = useState('');

    const [reasonForMovement, setReasonForMovement] = useState('');
    const [reasonForMovementError, setReasonForMovementError] = useState('');

    const [targetDate, setTargetDate] = useState('');
    const [targetDateError, setTargetDateError] = useState('');

    const [uploadInvoice, setUploadInvoice] = useState('');
    const [uploadInvoiceError, setUploadInvoiceError] = useState('');

    const [ownershipId, setOwnershipId] = useState('');
    const [ErrOwnershipId, setErrOwnershipId] = useState(false);
    const [departments, setDepartments] = useState([]);

    const [division, setDivision] = useState('');
    const [ErrDivision, setErrDivision] = useState(false);
    const [divisions, setDivisions] = useState([]);

    const [foundAssets, setFoundAssets] = useState();
    const [transferTag, setTransferTag] = useState([]);
    const [name, setName] = useState('');
    const [loading, setLoading] = useState(false);
    const [listOptions1, setListData1] = useState([]);
    var onCellEditStopFn = (event, newCell) => {
        var obj = {};
        obj[newCell.field] = event.target.value;
    }

    const [items, setItems] = useState([]);

    const columns = [
        {field: 'tag', headerName: 'TAG No', width: 250},
        {field: 'asset_name', headerName: 'Asset Name', width: 250},
        {field: 'supplier_name', headerName: 'Supplier Name', width: 250},
        {field: 'allocation_status', headerName: 'Allocation Status', width: 150},
        {
            field: 'invoice_date', headerName: 'Invoice Date', width: 150,
            renderCell: (params) => (
                <p>{getFormatedDate(params.row.invoice_date)}</p>
            )
        },
        {field: 'invoice_no', headerName: 'Supplier Invoice No', width: 150},
        {field: 'cat', headerName: 'CAT', width: 150},
        {field: 'purpose', headerName: 'purpose', width: 150},
        {field: 'remark', headerName: 'remark', width: 150},
        {field: 'existing_ownership', headerName: 'Existing Control  Dept', width: 150},
        {field: 'new_ownership', headerName: 'New Control  Dept', width: 150},
        {field: 'financial_classification', headerName: 'Financial Classification', width: 200},
        {field: 'total_cost_without_tax', headerName: 'Capitalized Value', width: 200},
        {
            field: 'action',
            headerName: 'Action',
            width: 150,

        },
    ];

    const [activeTab, setActiveTab] = useState(0);

    const rows = transferTag.map((option) => ({
        id: option.tag, // Assuming tag is unique and can be used as the row id
        tag: option.tag,
        asset_name: option.asset_name,
        supplier_name: option.supplier_name,
        allocation_status: option.allocation_status,
        invoice_date: option.invoice_date,
        invoice_no: option.invoice_no,
        cat: option.cat,
        purpose: option.purpose,
        remark: option.remark,
        existing_ownership: option.existing_ownership,
        new_ownership: option.new_ownership,
        action: '', // You can customize this column based on your requirements
    }));

    useEffect(() => {
        //encrypted data
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': {'new_ownership_id': sessionStorage.getItem('LoggedInDepartmentId')},
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token': EncryptData}
        getDivisionList(oData).then(res => {
            if (res?.data?.data?.length) setDivisions(res.data.data);
        });
        // getDepartmentList(oData).then(res=>{
        //   if(res?.data?.data?.length) setDepartments(res.data.data);
        // });
        getLastTinNo(oData).then(res => {
            console.log(res.data.data.res.length);
            let lastTinId = ((res.data.data.res.length) ? res.data.data.res.length : 0) + 1;
            let newlastTinId = lastTinId.toString().padStart(3, '0');
            let currentYear = new Date().getFullYear();
            let divisionName = sessionStorage.LoggedInDivisionShortName;
            let setNewTinNo = divisionName + currentYear + newlastTinId;

            setTinno('BT' + setNewTinNo);
        });

        getBranchTransferList(oData).then(res => {
            if (res?.data?.data?.length) setFoundAssets(res.data.data);
        });
        //setFoundAssets(asset_data);
        setListData1(listOptions1);

    }, []);

    const handleUploadInvoice = (e) => {
        setUploadInvoice(e.target.files);
        //setFileName(e.target.files[0].name);
    };

    const handleDivision = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setDivision(e.target.value);
        let EncryptData1 = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': {'division_id': value},
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData1 = {'token': EncryptData1}
        getDepartmentList(oData1).then(res => {
            if (res?.data?.data?.length) setDepartments(res.data.data);
        });
    }
    const resetAssetTransferInfo = function () {
        setTinno('');
        setMovementInitiatedBy('');
        setMovementApprovedBy('');
        setDateInitiation('');
        setDateApproval('');
        setReasonForMovement('');
        setTargetDate('');
        setUploadInvoice('');
        setListData1([]);
    }
    const handleFormSubmit = (e) => {
        e.preventDefault();
        var valid_Name = true;

        // Validate the inputs before submitting the form

        if (!(tinno) && tinno !== 0) {
            setTinnoError('TIN is required');
            valid_Name = false;
        } else {
            setTinnoError('');
        }

        if (!(movementInitiatedBy) && movementInitiatedBy !== 0) {
            setMovementInitiatedByError('Movement initiated by is required');
            valid_Name = false;
        } else {
            setMovementInitiatedByError('');
        }

        if (!(dateInitiation) && dateInitiation !== 0) {
            setDateInitiationError('Date of initiation is required');
            valid_Name = false;
        } else {
            setDateInitiationError('');
        }

        if (!(dateApproval) && dateApproval !== 0) {
            setdateApprovalError('Date of authorization is required');
            valid_Name = false;
        } else {
            setdateApprovalError('');
        }

        if (!(movementApprovedBy) && movementApprovedBy !== 0) {
            setMovementApprovedByError('Movement authorized by is required');
            valid_Name = false;
        } else {
            setMovementApprovedByError('');
        }

        if (!(reasonForMovement) && reasonForMovement !== 0) {
            setReasonForMovementError('Reason for transfer by is required');
            valid_Name = false;
        } else {
            setReasonForMovementError('');
        }

        if (!(targetDate) && targetDate !== 0) {
            setTargetDateError('Transfer date is required');
            valid_Name = false;
        } else {
            setTargetDateError('');
        }

        if (valid_Name) {
            //alert('test1');
            //saveSingleTransfer(); //submit form
            handleNextClick();
        } else {
            return false;
        }
        // Perform form submission logic here
    };

    const handleFormSubmit2 = (e) => {
        e.preventDefault();

        var valid_Name1 = true;
        //alert(listOptions1.length);
        if (listOptions1.length < 1) {
            alert('Please select atleast one Asset.');
            valid_Name1 = false;
            return false;
        } else {
            //setTargetDateError('');
            handleNextClick();
        }

        // Perform form submission logic here
    };
    const handleFormSubmit3 = (e) => {
        e.preventDefault();
        var valid_Name1 = true;

        if (!(division) && division !== 0) {
            setErrDivision('Division is required');
            valid_Name1 = false;
        } else {
            setErrDivision('');
        }
        if (!(ownershipId) && ownershipId !== 0) {
            setErrOwnershipId('Control department is required');
            valid_Name1 = false;
        } else {
            setErrOwnershipId('');
        }
        if (valid_Name1) {
            saveSingleTransfer();
        } else {
            return false;
        }
        // Perform form submission logic here
    };
    const saveSingleTransfer = function () {
        var obj = {
            'tin_no': tinno,
            'tag': listOptions1,
            'company_id': sessionStorage.getItem('LoggedInCompanyId'),
            'division_id': sessionStorage.getItem('LoggedInDivisionId'),
            'new_division_id': division,
            'department_id': sessionStorage.getItem('LoggedInDepartmentId'),
            'new_department_id': ownershipId,
            'movement_initiated_by': movementInitiatedBy,
            'initiation_date': dateInitiation,
            'authorization_date': dateApproval,
            'movement_authorized_by': movementApprovedBy,
            'transfer_reason': reasonForMovement,
            'movement_date': targetDate,
        }
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': obj, 'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token': EncryptData}
        try {
            saveAssetTransfer(oData).then(function (res) {
                //console.log(res);
                if ((typeof (res.data.errors) != "undefined") && res.data.errors.errno > 0) {
                    setErrMsg(res.data.message);
                    setSuccess(false);
                } else {
                    setActiveTab(0);
                    setSuccess(res.data.message);
                    resetAssetTransferInfo();

                }
            });

            //setValidName(false);
        } catch (err) {
            setSuccess(false);
            if (!err?.data) {
                console.log(err);
                setErrMsg('No Server Response');
            } else if (err.data?.status === 409) {
                setErrMsg('data already exist');
            } else {
                setErrMsg('Insert/Update Failed');
            }
            //elementRef.current.focus();
        }
    }

    const handleNextClick = () => {
        // Handle the "Next" button click to move to the next tab
        setActiveTab(activeTab + 1);
    };

    const handlePrevClick = (e) => {
        e.preventDefault(); // Prevent the default form submission behavior
        setActiveTab(activeTab - 1);
    };


    const filter = (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
            const results = foundAssets.filter((asset) => {
                return asset.tag.startsWith(keyword);
            });
            setFoundAssets(results);
        } else {
            setFoundAssets(foundAssets);
            // If the text field is empty, show all assets
        }
        setName(keyword);
    };

    const handleAllMoveList1 = (e) => {
        //var listOptions = [];
        var options1 = document.getElementById('lstBox1').options;
        for (var i = 0, l = options1.length; i < l; i++) {
            var option1 = {
                label: options1[i].label,
                value: options1[i].value,
            }
            if (pushListOptions(option1)) {
                listOptions1.push(option1);
            }
            //listOptions1.push(option1);
        } //setListData2(listOptions1); console.log(listOptions2);
    }

    const handleMoveList1 = (e) => {
        const selectedOptions = [];

        var options1 = document.getElementById('lstBox1').options;
        //console.log(options1);
        for (var i = 0, l = options1.length; i < l; i++) {
            if (options1[i].selected) {
                var option1 = {
                    label: options1[i].label,
                    value: options1[i].value,
                }
                if (pushListOptions(option1)) {
                    //alert('dd');
                    listOptions1.push(option1);
                }
            }
        }


        setListData1((prevList) => prevList.filter((option) => !selectedOptions.find((selected) => selected.value === option.value)));
        const selectedTags = [];
        for (var i = 0, l = listOptions1.length; i < l; i++) {
            selectedTags[i] = "'" + listOptions1[i].value + "'";
        }

        //encrypted data
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': {'tag_ids': selectedTags},
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token': EncryptData}

        getAssetsTransferData(oData).then(res => {
            //console.log(res?.data?.data?.res?.length);
            if (res?.data?.data?.res?.length) {
                setTransferTag(res.data.data.res);
            }
        })
        console.log(transferTag);
        //setListData2((prevList) => [...prevList, ...selectedOptions]);
    }

    const pushListOptions = (obj) => {
        var flag = 0;
        setLoading('..');
        listOptions1.forEach((elem) => {
            if (obj.value === elem.value && obj.label === elem.label) {
                flag = 1;
            }
        });
        setLoading(false);
        if (flag === 1) {
            return false;
        } else {
            return true;
        }
    };

    const handleAllRemove = (e) => { //alert('dd');
        //var options2 = document.getElementById('lstBox1').options;
        //alert(options.length);
        setListData1([]);
    }

    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const removeTag = (value) => {
        const confirmRemove = window.confirm("Are you sure you want to remove TAG from list?");
        if (confirmRemove) {
            listOptions1.pop(value);
            const updatedList = listOptions1.filter((item) => item !== value);
            setListData1(updatedList);
        }
    }

    const optionListOwnership = [];
    departments && departments.length > 0 ? (
        departments.map((option) => (
            optionListOwnership.push({value: option.id, label: option.department_name})
        ))
    ) : (
        optionListOwnership.push({value: "", label: "No Data"})
    )
    const [selectedOwnershipOptions, setSelectedOwnershipOptions] = useState();

    function handleOwnership(data) { console.log(data);
        setSelectedOwnershipOptions(data);
        setOwnershipId(data.value);
    }

    const optionListDivisions = [];
    divisions && divisions.length > 0 ? (
        divisions.map((option) => (
            optionListDivisions.push({value: option.division_name, label: option.division_name})
        ))
    ) : (
        optionListDivisions.push({value: "", label: "No Data"})
    )
    const [selectedDivisionsOptions, setSelectedDivisionsOptions] = useState();

    function handleDivisions(data) {
        setSelectedDivisionsOptions(data);
        setDivisions(data.value);
    }

    return (
        <div className='container'>
            <div className="tab-content">
                <div className={`tab-pane ${activeTab === 0 ? 'active' : ''}`}>
                    <div className="row">
                        <div className="col">
                            <h5>Initiate Branch Transfer</h5>
                        </div>
                        {success ? (<div className="alert alert-success" role="alert"><i
                            className="bi bi-check-circle-fill"></i> {success}</div>) : (errMsg ? (
                            <div className="alert alert-danger" role="alert"><i
                                className="bi bi-exclamation-triangle-fill"></i> {errMsg}</div>) : (''))}
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <FromFieldText
                                label="TIN No"
                                placeholder="TIN No"
                                inputValue={tinno}
                                setInputValue={setTinno}
                                setErrorMsg={tinnoError}
                            />
                        </div>
                        <div className="col-6">
                            <FromFieldLabel
                                label="Transfer Identification No"
                                inputValue=''
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <FromFieldText
                                label="Movement Initiated By"
                                placeholder="Movement Initiated By"
                                inputValue={movementInitiatedBy}
                                setInputValue={setMovementInitiatedBy}
                                setErrorMsg={movementInitiatedError}
                            />
                        </div>
                        <div className="col-6">
                            <FromFieldDate
                                label="Date of Initiation"
                                placeholder="Date of Initiation"
                                inputValue={dateInitiation}
                                setInputValue={setDateInitiation}
                                setErrorMsg={dateInitiationError}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <FromFieldText
                                label="Movement Approved By"
                                placeholder="Movement Approved By"
                                inputValue={movementApprovedBy}
                                setInputValue={setMovementApprovedBy}
                                setErrorMsg={movementApprovedByError}
                            />
                        </div>
                        <div className="col-6">
                            <FromFieldDate
                                label="Date of Approval"
                                inputValue={dateApproval}
                                setInputValue={setDateApproval}
                                setErrorMsg={dateApprovalError}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <FromFieldText
                                label="Reason for Movement"
                                placeholder="Reason for Movement"
                                inputValue={reasonForMovement}
                                setInputValue={setReasonForMovement}
                                setErrorMsg={reasonForMovementError}
                            />
                        </div>
                        <div className="col-6">
                            <FromFieldDate
                                label="Target Date for Completion"
                                inputValue={targetDate}
                                setInputValue={setTargetDate}
                                setErrorMsg={targetDateError}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <label style={{marginRight: '10px'}}> Upload approval mail </label>
                            <input type="file" name='asset_invoice' multiple onChange={handleUploadInvoice}/>
                            <span className="invalid-feedback"> {uploadInvoiceError} </span>
                        </div>
                    </div>
                    <div className="row mt-4 text-center">
                        <form name="" onSubmit={handleFormSubmit}>
                            <button className="btn btn-primary btn-md"> Next</button>
                        </form>
                        <div className="col-4"></div>
                    </div>
                </div>

                <div className={`tab-pane ${activeTab === 1 ? 'active' : 'hidden'}`}>
                    <div className="row">
                        <div className="col">
                            <h5>Initiate Branch Transfer</h5>
                        </div>
                    </div>
                    <div className="subject-info-box-1">
                        <input type="search" value={name} onChange={filter} className="form-control"
                               placeholder="Filter"
                        />
                        <select multiple="multiple" id='lstBox1' className="form-select form-select-sm">
                            {foundAssets && foundAssets.length > 0 ? (
                                foundAssets.map((option) => (
                                    <option value={option.tag}>{option.tag}</option>
                                ))
                            ) : (
                                <h1>No results</h1>
                            )}
                        </select>
                    </div>

                    <div className="subject-info-arrows text-center">{loading ? (
                        <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">{loading}</span>
                        </div>) : ('')}

                        <input type='button' id='btnAllRight' onClick={handleAllMoveList1} value='Add All'
                               className="btn btn-default"/><br/>
                        <input type='button' id='btnRight' onClick={handleMoveList1} value='>'
                               className="btn btn-default"/><br/>

                        <input type='button' id='btnAllLeft' onClick={handleAllRemove} value='Remove All'
                               className="btn btn-default"/>
                    </div>

                    <div className="subject-info-box-2">
                        {/*<div className="subject-refresh">
            <button onClick={refrest_ListData1} value=""  className="form-control btn-info refresh">Refresh</button>
                </div>*/}
                        <select multiple="multiple" id='lstBox2' className="form-select form-select-sm">
                            {listOptions1.map((option) => (
                                <option value={option.value}>{option.label}</option>
                            ))}
                        </select>
                    </div>
                    <div className="clearfix"></div>
                    <div className="row mt-4 text-center">
                        <div className="col-md-4">
                            <form name="" onSubmit={handlePrevClick}>
                                <button className="btn btn-primary btn-md float-end"> Back</button>
                            </form>
                        </div>
                        <div className="col-md-4">
                            <form name="" onSubmit={handleFormSubmit2}>
                                <button className="btn btn-primary btn-md float-end"> Next</button>
                            </form>
                        </div>
                    </div>
                </div>

                <div className={`tab-pane ${activeTab === 2 ? 'active' : 'hidden'}`}>


                    <div className="row">
                        <div className="col">
                            <h5>Initiate Branch Transfer</h5>
                        </div>
                    </div>
                    <div style={{height: 400, width: '100%'}}>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            pageSize={5}
                        />
                    </div>
                    <div className="row mt-4">
                        <div className="col-md-4">
                            <form name="" onSubmit={handlePrevClick}>
                                <button className="btn btn-primary btn-md float-end"> Back</button>
                            </form>
                        </div>
                        <div className="col-md-2">
                            <form name="" onSubmit={resetAssetTransferInfo}>
                                <button className="btn btn-primary btn-md float-end"> Cancel</button>
                            </form>
                        </div>

                        <div className="col-md-2">
                            <select onChange={handleDivision} className="form-select form-select-sm">
                                <option value="">Select Division</option>
                                {divisions && divisions.length > 0 ? (
                                    divisions.map((option) => (
                                        <option value={option.id}
                                                selected={(division === option.id) ? "selected" : ''}>{option.division_name}</option>
                                    ))
                                ) : (
                                    <option value="">No Data</option>
                                )}
                            </select>

                            {/* <Select
                                options={optionListDivisions}
                                placeholder="Select One"
                                value={selectedDivisionsOptions}
                                onChange={handleDivisions}
                                /> */}

                            <span className="invalid-feedback"> {ErrDivision} </span>
                        </div>
                        <div className="col-md-2">

                            <Select
                                options={optionListOwnership}
                                placeholder="Select One"
                                value={selectedOwnershipOptions}
                                onChange={handleOwnership}
                            />

                            <span className="invalid-feedback"> {ErrOwnershipId} </span>

                        </div>
                        <div className="col-md-2">
                            <form name="" onSubmit={handleFormSubmit3}>
                                <button className="btn btn-primary btn-md float-end">INITIATE BRANCH TRANSFER</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default InitiateTransfer;