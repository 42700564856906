import React, { useState, useEffect } from 'react';
import FromFieldLabel from '../../../atoms/FromFieldLabel';
import FromFieldText from '../../../atoms/FromFieldText';
import FromFieldDate from '../../../atoms/FromFieldDate';
import "react-datepicker/dist/react-datepicker.css";
import { FormLabel } from '@mui/material';



function SingleAssetQuoteOne() {

    const mystyle = {
        background: "blanchedalmond",
        textAlign: "center",
        fontSize: "16px",
        padding: "5px",
        fontWeight:'700',
      };

      const mystylebutton = {
        background: "cadetblue",
        textAlign: "center",
        fontSize: "16px",
        padding: "5px",
        fontWeight:'700',
        color:'#fff',
        borderRadius:'5px'
      };


    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h5>Single Asset Quote - One</h5>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-6">
                    <FromFieldText
                        label="TAG"
                        placeholder="Furniture-001"
                        inputValue=''
                        setInputValue=''
                    />
                </div>
                <div className="col-6">
                    <FromFieldText
                        label="DIN"
                        placeholder="DIN"
                        inputValue=''
                        setInputValue=''
                    />
                </div>
            </div>

            <div className="row mt-4">
                <div className='col-3'></div>
                <div className='col-3'><p style={mystyle}>Buyer 1</p></div>
                <div className='col-3'><p style={mystyle}>Buyer 2</p></div>
                <div className='col-3'><p style={mystyle}>Buyer 3</p></div>
            </div>

            <div className='row form-fields'>
                <div className='col-3'>
                    <label>Name</label>
                </div>
                <div className='col-3'>
                    <input className='form-control' type='text' placeholder='Som'  />
                </div>
                <div className='col-3'>
                    <input className='form-control' type='text' placeholder='Atul' />
                </div>
                <div className='col-3'>
                    <input className='form-control' type='text' placeholder='Bhushan' />
                </div>
            </div>

            <div className='row form-fields'>
                <div className='col-3'>
                    <label>Address</label>
                </div>
                <div className='col-3'>
                    <input className='form-control' type='text' placeholder=''  />
                </div>
                <div className='col-3'>
                    <input className='form-control' type='text' placeholder='' />
                </div>
                <div className='col-3'>
                    <input className='form-control' type='text' placeholder='' />
                </div>
            </div>

            <div className='row form-fields'>
                <div className='col-3'>
                    <label>PAN</label>
                </div>
                <div className='col-3'>
                    <input className='form-control' type='text' placeholder=''  />
                </div>
                <div className='col-3'>
                    <input className='form-control' type='text' placeholder='' />
                </div>
                <div className='col-3'>
                    <input className='form-control' type='text' placeholder='' />
                </div>
            </div>

            <div className='row form-fields'>
                <div className='col-3'>
                    <label>GSTN</label>
                </div>
                <div className='col-3'>
                    <input className='form-control' type='text' placeholder=''  />
                </div>
                <div className='col-3'>
                    <input className='form-control' type='text' placeholder='' />
                </div>
                <div className='col-3'>
                    <input className='form-control' type='text' placeholder='' />
                </div>
            </div>

            <div className='row form-fields mt-4'>
                <div className='col-3'>
                    <label>Price Quoted</label>
                </div>
                <div className='col-3'>
                    <input className='form-control' type='text' placeholder='40000'  />
                </div>
                <div className='col-3'>
                    <input className='form-control' type='text' placeholder='50000' />
                </div>
                <div className='col-3'>
                    <input className='form-control' type='text' placeholder='55000' />
                </div>
            </div>

            <div className='row form-fields'>
                <div className='col-3'>
                    <label>Quoted Recived Date</label>
                </div>
                <div className='col-3'>
                    <input className='form-control' type='text' placeholder=''  />
                </div>
                <div className='col-3'>
                    <input className='form-control' type='text' placeholder='' />
                </div>
                <div className='col-3'>
                    <input className='form-control' type='text' placeholder='' />
                </div>
            </div>

            <div className='row form-fields'>
                <div className='col-3'>
                    <label>Quoted Expiry Date</label>
                </div>
                <div className='col-3'>
                    <input className='form-control' type='text' placeholder=''  />
                </div>
                <div className='col-3'>
                    <input className='form-control' type='text' placeholder='' />
                </div>
                <div className='col-3'>
                    <input className='form-control' type='text' placeholder='' />
                </div>
            </div>

            <div className="row mt-4">
                <div className='col-3'></div>
                <div className='col-3'><p style={mystylebutton}>UPDATE</p></div>
                <div className='col-3'><p style={mystylebutton}>UPDATE</p></div>
                <div className='col-3'><p style={mystylebutton}>UPDATE</p></div>
            </div>

            <div className="row mt-4">
                <div className='col-3'></div>
                <div className='col-3 text-center'> <button className="btn btn-primary"> Edit </button></div>
                <div className='col-3 text-center'> <button className="btn btn-primary"> Cancel </button></div>
                <div className='col-3 text-center'> <button className="btn btn-primary"> Submit </button></div>
            </div>
        
        </div>
    )
}

export default SingleAssetQuoteOne;