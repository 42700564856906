import React, {useEffect, useRef, useState} from 'react';
import {useLocation} from "react-router-dom";
import FromFieldText from '../../atoms/FromFieldText';
import {getCompanyProfileList, getRoleInfo, saveRole} from '../../services/user.service';
import {getAppModuleList, getAppSubModuleList, getModuleList, getSubModuleList} from '../../services/master.service';
import * as CryptoJS from "crypto-js";

const TEXT_REGEX = /^[a-zA-Z0-9-_ ]{1,255}$/;

function Role() {

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    const [companyId, setCompanyId] = useState('');
    const [ErrCompanyId, setErrCompanyId] = useState(false);

    const [rolename, setRoleName] = useState('');
    const [ErrRoleName, setErrRoleName] = useState(false);

    const [roleshortname, setRoleShortName] = useState('');
    const [ErrRoleShortName, setErrRoleShortName] = useState(false);

    const [roledescription, setRoleDescription] = useState('');
    const [ErrRoleDescription, setErrRoleDescription] = useState(false);

    const [modules, setModules] = useState([]);
    const [subModules, setSubModules] = useState([]);

    const [appModules, setAppModules] = useState([]);
    const [appSubModules, setAppSubModules] = useState([]);

    const [roleModules, setRoleModules] = useState([]);
    const [roleSubModules, setRoleSubModules] = useState([]);

    const [roleAppModules, setRoleAppModules] = useState([]);
    const [roleAppSubModules, setRoleAppSubModules] = useState([]);

    const [companies, setCompanies] = useState([]);
    const [activeTab, setActiveTab] = useState(1);
    const [modinfo, setModinfo] = useState({
        modls: [],
        response: [],
    });

    const [subModinfo, setSubModinfo] = useState({
        submodls: [],
        response: [],
    });

    const [app_modinfo, setAppModinfo] = useState({
        app_modls: [],
        response: [],
    });

    const [app_subModinfo, setAppSubModinfo] = useState({
        app_submodls: [],
        response: [],
    });

    const handleTabChange11 = (tabNumber) => {
        setActiveTab(tabNumber);
    };
    const resetUserInfo = function () {
        setRoleName('');
        setRoleShortName('');
        setRoleDescription('');
        setCompanyId('');
    }
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const Roleid = query.get('roleid');
    const compid = query.get('compid');
    const elementRef = useRef();

    useEffect(() => {
        if (Roleid !== '' && Roleid !== null) {
            const decryptedBytes = CryptoJS.AES.decrypt(Roleid.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
            const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
            const decryptedRoleid = decrypt_data.roleid;
            getRoleInfoData(decryptedRoleid);
        }

        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = { 'token': EncryptData };

        getCompanyProfileList(oData).then(res => {
            if (res?.data?.data?.length) setCompanies(res.data.data);
        });

        getModuleList(oData).then(res => {
            if (res?.data?.data?.length) setModules(res.data.data);
        });

        getSubModuleList(oData).then(res => {
            if (res?.data?.data?.length) setSubModules(res.data.data);
        });

        getAppModuleList(oData).then(res => {
            if (res?.data?.data?.length) setAppModules(res.data.data);
        });

        getAppSubModuleList(oData).then(res => {
            if (res?.data?.data?.length) setAppSubModules(res.data.data);
        });
    }, []);

    useEffect(() => {
        // Update modinfo and subModinfo when roleModules and roleSubModules change
        setModinfo(prevState => ({
            ...prevState,
            modls: roleModules,
            response: roleModules,
        }));

        setSubModinfo(prevState => ({
            ...prevState,
            submodls: roleSubModules,
            response: roleSubModules,
        }));

        setAppModinfo(prevState => ({
            ...prevState,
            app_modls: roleAppModules,
            response: roleAppModules,
        }));

        setAppSubModinfo(prevState => ({
            ...prevState,
            app_submodls: roleAppSubModules,
            response: roleAppSubModules,
        }));
    }, [roleModules, roleSubModules, roleAppModules, roleAppSubModules]);

    const getRoleInfoData = (decryptedRoleid) => {
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': { 'role_id': decryptedRoleid },
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData1 = { 'token': EncryptData };

        getRoleInfo(oData1).then(res => {
            if (res?.data?.data) {
                const roleInfo = res.data.data[0];
                setCompanyId(roleInfo.company_id);
                setRoleName(roleInfo.role_name);
                setRoleShortName(roleInfo.role_short_name);
                setRoleDescription(roleInfo.role_details);
                setRoleModules(roleInfo.module_id.split(',').map(Number));
                setRoleSubModules(roleInfo.sub_module_id.split(',').map(Number));
                setRoleAppModules(roleInfo.app_module_id.split(',').map(Number));
                setRoleAppSubModules(roleInfo.app_sub_module_id.split(',').map(Number));
            }
        });
    };


    const modulesData = modules.map(item => ({
        id: item.id,
        module_name: item.module_name,
        subMdlData: subModules.filter(subitem => subitem.module_id === item.id).map(subitem => ({
            id: subitem.id,
            module_id: subitem.module_id,
            sub_module_name: subitem.sub_module_name,
        })),
    }));

    const appModulesData = appModules.map(item => ({
        id: item.id,
        module_name: item.module_name,
        appSubMdlData: appSubModules.filter(subitem => subitem.module_id === item.id).map(subitem => ({
            id: subitem.id,
            module_id: subitem.module_id,
            sub_module_name: subitem.sub_module_name,
        })),
    }));


    const handleCompanyId = (e) => {
        //let name= e.target.name;
        // let value= e.target.value;
        setCompanyId(e.target.value);
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        var valid_Name = true;
        // if button enabled with JS hack
        //const v1 = TEXT_REGEX.test(name);
        if (!TEXT_REGEX.test(companyId)) { //alert(name);
            setErrCompanyId('Company name is required');
            valid_Name = false; //alert(valid_Name);
            //return;
        } else {
            setErrCompanyId('');
        }
        if (!TEXT_REGEX.test(rolename)) { //alert(name);
            setErrRoleName('Role name is required');
            valid_Name = false; //alert(valid_Name);
            //return;
        } else {
            setErrRoleName('');
        }
        if (!TEXT_REGEX.test(roledescription)) { //alert(companyId);
            setErrRoleDescription('Role description is required');
            valid_Name = false;
            //return;
        } else {
            setErrRoleDescription('');
        }
        if (!TEXT_REGEX.test(roleshortname)) { //alert(companyId);
            setErrRoleShortName('Role short name is required');
            valid_Name = false;
            //return;
        } else {
            setErrRoleShortName('');
        }
        if (valid_Name) { //alert('test1');
            saveData(); //submit form
        } else {  //alert('fa');
            return false;
        }
    }

    const handleChange = (e) => {
        const { value, checked } = e.target;
        setModinfo(prevState => ({
            ...prevState,
            modls: checked ? [...prevState.modls, Number(value)] : prevState.modls.filter(mod => mod !== Number(value)),
            response: checked ? [...prevState.response, Number(value)] : prevState.response.filter(mod => mod !== Number(value)),
        }));
    };

    const handleSubMdlChange = (e) => {
        const { value, checked } = e.target;
        setSubModinfo(prevState => ({
            ...prevState,
            submodls: checked ? [...prevState.submodls, Number(value)] : prevState.submodls.filter(submod => submod !== Number(value)),
            response: checked ? [...prevState.response, Number(value)] : prevState.response.filter(submod => submod !== Number(value)),
        }));
    };

    const handleAppModuleChange = (e) => {
        const { value, checked } = e.target;
        const valueNumber = Number(value);
        setAppModinfo(prevState => {
            const app_modls = prevState.app_modls || [];
            const updatedAppModls = checked ? [...app_modls, valueNumber] : app_modls.filter(mod => mod !== valueNumber);
            return {
                ...prevState,
                app_modls: updatedAppModls,
                response: updatedAppModls,
            };
        });
        setRoleAppModules(prevState => {
            return checked ? [...prevState, valueNumber] : prevState.filter(mod => mod !== valueNumber);
        });
    };

    const handleAppSubMdlChange = (e) => {
        const { value, checked } = e.target;
        const valueNumber = Number(value);
        setAppSubModinfo(prevState => {
            const app_submodls = prevState.app_submodls || [];
            const updatedAppSubModls = checked ? [...app_submodls, valueNumber] : app_submodls.filter(submod => submod !== valueNumber);
            return {
                ...prevState,
                app_submodls: updatedAppSubModls,
                response: updatedAppSubModls,
            };
        });
        setRoleAppSubModules(prevState => {
            return checked ? [...prevState, valueNumber] : prevState.filter(submod => submod !== valueNumber);
        });
    };


    const saveData = function () {
        try {

            if (Roleid !== '' && Roleid !== null) {
                const decryptedBytes = CryptoJS.AES.decrypt(Roleid.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
                // Convert the decrypted bytes to a string
                const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
                const decryptedRoleID = decrypt_data.roleid;
                var obj = {
                    'role_ids': decryptedRoleID,
                    'role_name': rolename,
                    'role_short_name': roleshortname,
                    'role_details': roledescription,
                    'company_id': companyId,

                    'module_id': (modinfo.modls ?? []).map((val, idx) => {
                        return `${val}`;
                    }).join(','),
                    'sub_module_id': (subModinfo.submodls ?? []).map((val, idx) => {
                        return `${val}`;
                    }).join(','),
                    'app_module_id': (app_modinfo.app_modls ?? []).map((val, idx) => {
                        return `${val}`;
                    }).join(','),
                    'app_sub_module_id': (app_subModinfo.app_submodls ?? []).map((val, idx) => {
                        return `${val}`;
                    }).join(','),

                    'status': '1',
                    'created_by': sessionStorage.getItem('LoggedInUserId'),
                }
            } else {
                var obj = {
                    'role_name': rolename,
                    'role_short_name': roleshortname,
                    'role_details': roledescription,
                    'company_id': companyId,

                    'module_id': (modinfo.modls ?? []).map((val, idx) => {
                        return `${val}`;
                    }).join(','),
                    'sub_module_id': (subModinfo.submodls ?? []).map((val, idx) => {
                        return `${val}`;
                    }).join(','),
                    'app_module_id': (app_modinfo.app_modls ?? []).map((val, idx) => {
                        return `${val}`;
                    }).join(','),
                    'app_sub_module_id': (app_subModinfo.app_submodls ?? []).map((val, idx) => {
                        return `${val}`;
                    }).join(','),

                    'status': '1',
                    'created_by': sessionStorage.getItem('LoggedInUserId'),
                }
            }


            //encrypted data
            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': obj,
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData = {'token': EncryptData}
            saveRole(oData).then(function (res) {
                //console.log(res);
                if ((typeof (res.data.errors) != "undefined") && res.data.errors.errno > 0) {
                    setErrMsg(res.data.message);
                    setSuccess(false);
                } else {
                    setSuccess(res.data.message);
                    resetUserInfo();
                }
            });

            //setValidName(false);
        } catch (err) {
            setSuccess(false);
            if (!err?.data) {
                console.log(err);
                setErrMsg('No Server Response');
            } else if (err.data?.status === 409) {
                setErrMsg('data already exist');
            } else {
                setErrMsg('Insert/Update Failed');
            }
            //elementRef.current.focus();
        }
    }

    return (
        <div className="container">

            <div className="row">
                <div className="col-6">
                    <h5>Add Role</h5>
                </div>
                <div className="invalid-feedback" role="alert">
                    <i className="fa fa-info-circle"></i>
                    Please Fill all mandatory field.
                </div>

                {success ? (
                    <div className="alert alert-success" role="alert"><i
                        className="bi bi-check-circle-fill"></i>{success}</div>
                ) : (errMsg ? (<div className="alert alert-danger" role="alert"><i
                        className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>) : ('')
                )}
            </div>

            <div className='row '>
                <div className='col-12'>&nbsp;</div>

            </div>
            <div style={{display: 'flex', gap: '10px'}}>
                <button style={{padding: '10px', backgroundColor: activeTab === 1 ? 'lightblue' : 'transparent'}}
                        onClick={() => handleTabChange11(1)}>Role Details
                </button>
                <button style={{padding: '10px', backgroundColor: activeTab === 2 ? 'lightblue' : 'transparent'}}
                        onClick={() => handleTabChange11(2)}>Web Module
                </button>
                <button style={{padding: '10px', backgroundColor: activeTab === 3 ? 'lightblue' : 'transparent'}}
                        onClick={() => handleTabChange11(3)}>App Module
                </button>

            </div>
            <form onSubmit={handleSubmit}>

                {activeTab === 1 && (
                    <div>
                        <div className="row form-fields mt-2">
                            <div className="label col-3">
                                <label>Company Name </label><span className="star error"> *</span>
                            </div>

                            <div className="item col-3">
                                <select className="form-select form-select-sm mb-3" onChange={handleCompanyId}>
                                    <option value="">Select Company</option>
                                    {companies && companies.length > 0 ? (
                                        companies.map((option) => (
                                            <option value={option.company_id}
                                                    selected={(option.company_id == companyId) ? "selected" : ''}>{option.company_name}</option>
                                        ))
                                    ) : (
                                        <option value="">No Data</option>
                                    )}
                                </select>
                                <span className="invalid-feedback"> {ErrCompanyId} </span>
                            </div>
                        </div>

                        <div className="row mb-2">
                            <div className="col-6">
                                <FromFieldText star="*"
                                               label="Role Code"
                                               inputValue={roleshortname}
                                               setInputValue={setRoleShortName}
                                               setErrorMsg={ErrRoleShortName}
                                />
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-6">
                                <FromFieldText star="*"
                                               label="Role Name"
                                               inputValue={rolename}
                                               setInputValue={setRoleName}
                                               setErrorMsg={ErrRoleName}
                                />
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-6">
                                <FromFieldText star="*"
                                               label="Role Description"
                                               inputValue={roledescription}
                                               setInputValue={setRoleDescription}
                                               setErrorMsg={ErrRoleDescription}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-3"></div>
                            <div className="col-6">
                                <button onClick={() => handleTabChange11(2)} className="btn btn-primary btn-md"> Next
                                </button>
                            </div>
                            <div className="col-3"></div>
                        </div>

                    </div>

                )}
                {activeTab === 2 && (

                    <div>
                        <div className="row m-2">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-md-3">
                                        <label> Modules to be assigned <span className="star error"> * </span></label>
                                    </div>

                                    <div className="col-9">
                                        {Roleid !== '' && Roleid !== null ? (
                                            modulesData.length > 0 ? (
                                                modulesData.map(option => (
                                                    <div key={option.id}>
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            name="modls"
                                                            value={option.id}
                                                            id={`flexCheckDefault-${option.id}`}
                                                            onChange={handleChange}
                                                            checked={modinfo.modls.includes(option.id)}
                                                        />
                                                        &nbsp;
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor={`flexCheckDefault-${option.id}`}
                                                        >
                                                            {option.module_name}
                                                        </label>
                                                        {option.subMdlData.map(subitem => (
                                                            <div className="col px-md-4" key={subitem.id}>
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    name="submodls"
                                                                    value={subitem.id}
                                                                    id={`flexCheckDefault-${subitem.id}`}
                                                                    onChange={handleSubMdlChange}
                                                                    checked={subModinfo.submodls.includes(subitem.id)}
                                                                />
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor={`flexCheckDefault-${subitem.id}`}
                                                                >
                                                                    {subitem.sub_module_name}
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                ))
                                            ) : (
                                                <div>No modules available</div>
                                            )
                                        ) : (
                                            <div>
                                                {modulesData.length > 0 ? (
                                                    modulesData.map(option => (
                                                        <div key={option.id}>
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                name="modls"
                                                                value={option.id}
                                                                id={`flexCheckDefault-${option.id}`}
                                                                onChange={handleChange}
                                                                checked={modinfo.modls.includes(option.id)}
                                                            />
                                                            &nbsp;
                                                            <strong
                                                                className="form-check-label"
                                                                htmlFor={`flexCheckDefault-${option.id}`}
                                                            >
                                                                {option.module_name}
                                                            </strong>
                                                            {option.subMdlData.map(subitem => (
                                                                <div className="col px-md-4" key={subitem.id}>
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        name="submodls"
                                                                        value={subitem.id}
                                                                        id={`flexCheckDefault-${subitem.id}`}
                                                                        onChange={handleSubMdlChange}
                                                                        checked={subModinfo.submodls.includes(subitem.id)}
                                                                    />
                                                                    <label
                                                                        className="form-check-label"
                                                                        htmlFor={`flexCheckDefault-${subitem.id}`}
                                                                    >
                                                                        {subitem.sub_module_name}
                                                                    </label>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    ))
                                                ) : (
                                                    <div>No modules available</div>
                                                )}
                                            </div>
                                        )}
                                        <span className="invalid-feedback"></span>
                                    </div>


                                </div>
                            </div>
                        </div>
                        <div className="col-6"></div>

                        <div className="row">
                            <div className="col-3"></div>
                            <div className="col-6">
                                <button onClick={() => handleTabChange11(3)} className="btn btn-primary btn-md"> Next
                                </button>
                            </div>
                            <div className="col-3"></div>
                        </div>

                    </div>

                )}
                {activeTab === 3 && (

                    <div>
                        <div className="row m-2">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-md-3">
                                        <label> Mobile App Modules to be assigned <span
                                            className="star error"> * </span></label>
                                    </div>

                                    <div className="col-9">
                                        {Roleid !== '' && Roleid !== null ? (
                                            appModulesData.length > 0 ? (
                                                appModulesData.map(option => (
                                                    <div key={option.id}>
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            name="app_modls"
                                                            value={option.id}
                                                            id={`flexCheckAppDefault-${option.id}`}
                                                            onChange={handleAppModuleChange}
                                                            checked={roleAppModules.includes(option.id)}
                                                        />
                                                        &nbsp;
                                                        <label className="form-check-label"
                                                               htmlFor={`flexCheckAppDefault-${option.id}`}>
                                                            {option.module_name}
                                                        </label>
                                                        {option.appSubMdlData.map(subitem => (
                                                            <div className="col px-md-4" key={subitem.id}>
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    name="app_submodls"
                                                                    value={subitem.id}
                                                                    id={`flexCheckAppSubDefault-${subitem.id}`}
                                                                    onChange={handleAppSubMdlChange}
                                                                    checked={roleAppSubModules.includes(subitem.id)}
                                                                />
                                                                <label className="form-check-label"
                                                                       htmlFor={`flexCheckAppSubDefault-${subitem.id}`}>
                                                                    {subitem.sub_module_name}
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                ))
                                            ) : (
                                                <div>No modules available</div>
                                            )
                                        ) : (
                                            <div>
                                                {appModulesData.length > 0 ? (
                                                    appModulesData.map(option => (
                                                        <div key={option.id}>
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                name="app_modls"
                                                                value={option.id}
                                                                id={`flexCheckAppDefault-${option.id}`}
                                                                onChange={handleAppModuleChange}
                                                                checked={roleAppModules.includes(option.id)}
                                                            />
                                                            &nbsp;
                                                            <strong className="form-check-label"
                                                                    htmlFor={`flexCheckAppDefault-${option.id}`}>
                                                                {option.module_name}
                                                            </strong>
                                                            {option.appSubMdlData.map(subitem => (
                                                                <div className="col px-md-4" key={subitem.id}>
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        name="app_submodls"
                                                                        value={subitem.id}
                                                                        id={`flexCheckAppSubDefault-${subitem.id}`}
                                                                        onChange={handleAppSubMdlChange}
                                                                        checked={roleAppSubModules.includes(subitem.id)}
                                                                    />
                                                                    <label className="form-check-label"
                                                                           htmlFor={`flexCheckAppSubDefault-${subitem.id}`}>
                                                                        {subitem.sub_module_name}
                                                                    </label>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    ))
                                                ) : (
                                                    <div>No modules available</div>
                                                )}
                                            </div>
                                        )}
                                        <span className="invalid-feedback"></span>
                                    </div>


                                </div>
                            </div>
                        </div>
                        <div className="col-6"></div>

                        <div className="row">
                            <div className="col-3"></div>
                            <div className="col-6">
                                <button className="btn btn-primary btn-md"> SAVE</button>
                            </div>
                            <div className="col-3"></div>
                        </div>
                    </div>

                )}

            </form>
        </div>
    )
}

export default Role


