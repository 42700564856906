import React,{useCallback,useState,useEffect,useRef} from 'react';
import {DataGrid, GridOverlay, GridToolbarContainer, GridToolbarExport} from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';
import FromFieldDate from '../../../atoms/FromFieldDate';
import "react-datepicker/dist/react-datepicker.css";
import '../../Home/Home.css';
import { getFarDepresiation,getFarDepreciationCSV } from '../../../services/asset.service';
import {Parser} from "json2csv";
import {Box, FormControl, InputLabel, MenuItem, Pagination, Select} from '@mui/material';
import  * as CryptoJS  from "crypto-js";

Date.prototype.dbFormatedDate = function () {
    let year = this.getFullYear();
    let month = this.getMonth() + 1;
    let day = this.getDate();
    if (month < 10) {
      month = '0' + month;
    }if (day < 10) {
      day = '0' + day;
    }
    return year + '-' + month + '-' + day;
  }
  function getFormatedDate(dateString) {
    if(dateString!==null) {
        let dte = new Date(dateString);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        if (month < 10) {
          month = '0' + month;
        }if (day < 10) {
          day = '0' + day;
        }
        return year + '-' + month + '-' + day;
        //return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
    } else {
        return null;
    }   
  }
function FarDepreciation() {
    const [items, setItems] = useState([]);
    const [itemsCSV, setItemsCSV] = useState([]);
    const [loading, setLoading] = useState(false);
    const [exporting, setExporting] = useState(false);
    const [page, setPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [pageSize, setPageSize] = useState(50);
    const [fromperiod, setFromPeriod] = useState('');
    const [ErrFromPeriod, setErrFromPeriod] = useState('');
    const [toperiod, setToPeriod] = useState('');
    const [ErrToPeriod, setErrToPeriod] = useState(''); 
    //const fincancialClasifications = ['Land','Building','Building Improvements','Furniture and Fixtures','Plant and Machinery','Office Equipments','Computer','Intangible','Vehicles','Electrical Installations']
    //const [classifications, setClasifications] = useState([]);
    //const [assetInfo, setAssetInfo] = useState([]);
    const calculateOffset = (page, pageSize) => (page - 1) * pageSize;

    const fetchDepreciation = useCallback(async () => { 
        if(fromperiod!=='' && fromperiod!==null){ 
            setErrFromPeriod('');
        }else{ setErrFromPeriod('Please select From Date'); return false; }
        if(toperiod!==null && toperiod!==''){
            setErrToPeriod(''); 
        }else{ setErrToPeriod('Please select To Date'); return false; }
        setLoading(true);
        //alert(fromperiod);
        const offset = calculateOffset(page, pageSize);
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify({
            param_data: {   'from_date':getFormatedDate(fromperiod),  
                    'to_date':getFormatedDate(toperiod),
                            'offset': offset,  'pageSize': pageSize 
                        },
            authToken: sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

        const requestData = {token: encryptedData};

        try {
            const res = await getFarDepresiation(requestData);
            if (res?.data?.data?.assetDeprisiation.length) {
                setItems(res.data.data.assetDeprisiation);
                setTotalItems(res.data.data.assetDeprisiation.total || 0);
            }else{
                setItems('');
            }
        } catch (error) {
            console.error('Error fetching assets:', error);
        } finally {
            setLoading(false);
        }
    }, [fromperiod,toperiod, page, pageSize]);

    const fetchAssetsCSV = useCallback(async () => {
        if(fromperiod!=='' && fromperiod!==null){ 
            setErrFromPeriod('');
        }else{ setErrFromPeriod('Please select From Date'); return false; }
        if(toperiod!==null && toperiod!==''){
            setErrToPeriod(''); 
        }else{ setErrToPeriod('Please select To Date'); return false; }
        setExporting(true);
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify({
            param_data: {   'from_date':getFormatedDate(fromperiod),  
                            'to_date':getFormatedDate(toperiod),
                            'offset': '',  'pageSize': '' 
                        },
            authToken: sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        
        const requestData = {token: encryptedData};
        try {
            const resCSV = await getFarDepreciationCSV(requestData);
            console.log('resCSV');console.log(resCSV.data.data);
            if (resCSV?.data?.data?.length) {
                setItemsCSV(resCSV.data.data);
            }
        } catch (error) {
            console.error('Error fetching assets CSV:', error);
        } finally {
            setExporting(false);
            
        }
    }, [fromperiod,toperiod]);
    useEffect(() => { 
        if (itemsCSV.length > 0) {
            generateCSV();
        }
    }, [itemsCSV]);

    
    const handlePageSizeChange = (value) => {
        setPageSize(value);
        setPage(1);
    };
    
    const handlePageChange = (event, value) => {
        setPage(value);
    };
    
    const totalPages = Math.ceil(totalItems / pageSize);
    
    const handleExportToCSV = async () => {
        await fetchAssetsCSV();
    };
    
    const generateCSV = () => {
        try {
            const jsonData = itemsCSV.map(item => ({
                "TAG": item.tag ? item.tag : '',
                "Parent Tag": item.parent_tag ? item.parent_tag : '',
                "Year of Addition": item.year_of_addition ? item.year_of_addition : '',
                "Type of Addition": item.type_of_addition ? item.type_of_addition : '',
                "Project Marking": item.project_marking ? item.project_marking : '',
                "PO No (If Any)": item.po_no ? item.po_no : '',
                "Supplier Code (if Any)": item.supplier_code ? item.supplier_code : '',
                "Name of the Supplier": item.supplier_name ? item.supplier_name : '',
                "Invoice No": item.invoice_no ? item.invoice_no : '',
                "Invoice Date": item.invoice_date ? getFormatedDate(item.invoice_date) : '',
                "Bill of Entry No": item.boe_no ? item.boe_no : '',
                "Date of BOE": item.boe_date ? getFormatedDate(item.boe_date) : '',
                "Shipping / AWB No": item.shipping_awb_no ? item.shipping_awb_no : '',
                "Date of Shipping/AWB": item.shipping_awb_date ? getFormatedDate(item.shipping_awb_date) : '',
                "Accounting Voucher No": item.accounting_voucher_no ? item.accounting_voucher_no : '',
                "Internal Approval No": item.internal_approval_no ? item.internal_approval_no : '',
                "Cost Center Code": item.cost_center_code ? item.cost_center_code : '',
                "Cost Center Name": item.cost_center_name ? item.cost_center_name : '',
                "Invoice Currency": item.invoice_currency ? item.invoice_currency : '',
                
                "Basic Cost p.u in Fx": item.invoice_value_fx ? item.invoice_value_fx : '',
                "Basic Cost p.u in INR": item.invoice_value_inr ? item.invoice_value_inr : '',
                "Forwarding & Insurance": item.forwarding_and_insurance ? item.forwarding_and_insurance : '',
                "Installation": item.installation ? item.installation : '',
                "Errection": item.errection ? item.errection : '',
                "Total Cost Without Tax p.u": item.total_cost_without_tax ? item.total_cost_without_tax : '',
                "Customs": item.customs ? item.customs : '',
                "CGST": item.cgst ? item.cgst : '',
                "SGST": item.sgst ? item.sgst : '',
                "IGST": item.igst ? item.igst : '',
                "Other Taxes": item.other_taxes ? item.other_taxes : '',
                "Total Tax p.u": item.total_tax ? item.total_tax : '',
                "Total Cost with Tax p.u": item.value_of_asset ? item.value_of_asset : '',
                "Qty": item.qty ? item.qty : '',
                "Unit Of Measure": item.unit_of_measure ? item.unit_of_measure : '',
                "Name of the Asset (as per Invoice)": item.tag_name ? item.tag_name : '',
                "Detailed Description of the Asset": item.asset_description ? item.asset_description : '',
                "Nature of Presence": item.nature_of_presence ? item.nature_of_presence : '',
                "Nature of Addition": item.nature_of_addition ? item.nature_of_addition : '',
                "Principal Asset to which Cost is Added": item.principal_asset_tag ? item.principal_asset_tag : '',
                "Unique Sl No of the Asset": item.asset_serial_no ? item.asset_serial_no : '',
                "Make / Manufacturer": item.manufacturer ? item.manufacturer : '',
                "Model No": item.model_no ? item.model_no : '',
                "Part No": item.part_no ? item.part_no : '',
                "Control Department": item.department_name ? item.department_name : '',
                "comp ref no": item.comp_ref_no ? item.comp_ref_no : '',
                "Asset TAG Name": item.tag_name ? item.tag_name : '',
                "Asset TAG Branch": item.tag_division ? item.tag_division : '',
                "Security Seal Date": item.security_seal_date ? getFormatedDate(item.security_seal_date) : '',
                
               
                
                "Class of Asset": item.class_of_asset ? item.class_of_asset : '',
                "Record Start Date": item.record_start_date ? getFormatedDate(item.record_start_date) : '',
                "Parent TAG": item.parent_tag ? item.parent_tag : '',
                "Depreciation mode": item.cap_type_of_asset ? item.cap_type_of_asset : '',
                "Date of Capitalization / Asset Ready To Use": item.asset_in_use_date ? getFormatedDate(item.asset_in_use_date) : '',
                "Type of Depreciation": item.type_of_depreciation ? item.type_of_depreciation : '',
                "Depreciation Rate(No. of years) [I-GAAP]": item.depreciation_rate ? item.depreciation_rate : '',
                "End Date [I-GAAP]": item.depreciation_end_date ? getFormatedDate(item.depreciation_end_date) : '',
                "Accounting Code of Asset": item.accounting_code ? item.accounting_code : '',
                "Accounting Name of the Asset": item.accounting_name ? item.accounting_name : '',
                "Financials Classification": item.category_name ? item.category_name : '',
                "Put to Use Date": item.put_to_use_date ? getFormatedDate(item.put_to_use_date) : '',
                "Final Asset Capitalization Flag": item.final_asset ? item.final_asset : '',
                "Is Tax Capitalized": item.is_tax_capitalized ? item.is_tax_capitalized : '',
                "Invoice Currency": item.invoice_currency ? item.invoice_currency : '',
                "Exchange Rate": item.exchange_rate ? item.exchange_rate : '',
                "Is 100% Depreciable Asset [I-GAAP]": item.is_100_perc_depreciable ? item.is_100_perc_depreciable : '',
                "Pre Capitalized Asset Value": item.pre_capitalized_asset_value ? item.pre_capitalized_asset_value : '',
                "Is any other Accg GAAP Applicable": item.is_any_other_accg_gaap_applicable ? item.is_any_other_accg_gaap_applicable : '',
                "I-GAAP - Limit for 100% Depreciable Asset (Rs)": item.igaap_limit_amount ? item.igaap_limit_amount : '',
                "Salvage Value": item.salvage_value ? item.salvage_value : '',
                "Capitalized Value (I-GAAP)": item.igaap_capitalized_value ? item.igaap_capitalized_value : '',
                "other_record_start_date": item.other_record_start_date ? getFormatedDate(item.other_record_start_date) : '',
                "Is 100% Depreciable Asset [Other-GAAP]": item.is_100_perc_other_depreciable ? item.is_100_perc_other_depreciable : '',
                "Depreciation Rate(No. of years) [Other-GAAP]": item.other_depreciation_rate ? item.other_depreciation_rate : '',
                "End Date [Other-GAAP]": item.depreciation_other_end_date ? getFormatedDate(item.depreciation_other_end_date) : '',
                "Other-GAAP - Limit for 100% Depreciable Asset (Rs)": item.othergaap_limit_amount ? item.othergaap_limit_amount : '',
                "Capitalized Value (Other GAAP)": item.othergaap_capitalized_value ? item.othergaap_capitalized_value : '',
               
                "Allocation Status": item.allocation_status ? item.allocation_status  : '',
                "Division": item.division ? item.division : '',
                "Premises": item.premises ? item.premises : '',
                "Floor": item.floor ? item.floor : '',
                "Site ID": item.site_id ? item.site_id : '',
                "Rack Id": item.rack_id ? item.rack_id : '',
                "User Type": item.user_type ? item.user_type : '',
                "User Department": item.user_department ? item.user_department : '',
                "Employee ID": item.employee_id ? item.employee_id : '',
                "Employee Name": item.employee_name ? item.employee_name : '',
                "User Mail ID": item.user_mail_id ? item.user_mail_id : '',
                "Alternative Verification": item.alternative_verification ? item.alternative_verification : '',
                "Child Allocation": item.child_allocation ? item.child_allocation : '',
                "Effective Date": item.effective_date ? item.effective_date : '',

                "TAG ": item.tag ? item.tag : '',
                "Opening Value": item.asset_ob ? item.asset_ob : '',
                "Addition": item.asset_added_value ? item.asset_added_value : '',
                "Deletion": item.asset_deleted_value ? item.asset_deleted_value : '',
                "Closing Value": item.asset_cb ? item.asset_cb : '',
                "Opening Acc Dep": item.depreciation_ob ? item.depreciation_ob : '',
                "Addition for the Period": item.depreciation_added_value ? item.depreciation_added_value : '',
                "Deletion for the Period": item.depreciation_deleted_value ? item.depreciation_deleted_value : '',
                "Adjustment for the Period": item.depreciation_adjusted_value ? item.depreciation_adjusted_value : '',
                "Closing Acc Dep": item.new_depreciation_cb ? item.new_depreciation_cb : '',
                "Opening WDV": item.wdv_ob ? item.wdv_ob : '',
                "Closing WDV": item.wdv_cb ? item.wdv_cb : '',

                "Created Date": item.created_at ? getFormatedDate(item.created_at) : '',
                // Add more fields as needed based on your item structure
            }));
    
            const json2csvParser = new Parser();
            const csv = json2csvParser.parse(jsonData);
    
            const blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'FAR_Depreciation.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error exporting data to CSV:', error);
        }
    };
    
    const columns = [
        {"field": "id", "hideable": true, "hide": true, "editable": false, "headerName": "Sl No", "width": 50},
        {"field": "asset_name", "hideable": true, "hide": false, "editable": false, "headerName": "Asset Name", "width": 250},
        {"field": "asset_description", "hideable": true, "hide": false, "editable": false, "headerName": "Asset Description", "width": 250},
        {"field": "year_of_addition", "hideable": true, "hide": false, "editable": false, "headerName": "Year of Addition", "width": 250},
        {"field": "type_of_addition", "hideable": true, "hide": false, "editable": false, "headerName": "Type of Addition", "width": 250},
        {"field": "project_marking", "hideable": true, "hide": false, "editable": false, "headerName": "Project Marking", "width": 250},
        {"field": "po_no", "hideable": true, "hide": false, "editable": false, "headerName": "PO No (If Any)", "width": 250},
        {"field": "supplier_code", "hideable": true, "hide": false, "editable": false, "headerName": "Supplier Code (if Any)", "width": 250},
        {"field": "supplier_name", "hideable": true, "hide": false, "editable": false, "headerName": "Name of the Supplier", "width": 250},
        {"field": "invoice_no", "hideable": true, "hide": false, "editable": false, "headerName": "Invoice No", "width": 250},
        {"field": "invoice_date", "hideable": true, "hide": false, "editable": false, "headerName": "Invoice Date", "width": 250,
            renderCell: (params) => (
                <p>{getFormatedDate(params.row.invoice_date)}</p>
            )
        },
        {"field": "boe_no", "hideable": true, "hide": false, "editable": false, "headerName": "Bill of Entry No", "width": 250},
        {"field": "boe_date", "hideable": true, "hide": false, "editable": false, "headerName": "Date of BOE", "width": 250,
            renderCell: (params) => (
                <p>{getFormatedDate(params.row.boe_date)}</p>
            )
        },
        {"field": "shipping_awb_no", "hideable": true, "hide": false, "editable": false, "headerName": "Shipping / AWB No", "width": 250},
        {"field": "shipping_awb_date", "hideable": true, "hide": false, "editable": false, "headerName": "Date of Shipping/AWB", "width": 250,
            renderCell: (params) => (
                <p>{getFormatedDate(params.row.shipping_awb_date)}</p>
            )
        },
        {"field": "accounting_voucher_no", "hideable": true, "hide": false, "editable": false, "headerName": "Accounting Voucher No", "width": 250},
        {"field": "internal_approval_no", "hideable": true, "hide": false, "editable": false, "headerName": "Internal Approval No", "width": 250},
        {"field": "cost_center_code", "hideable": true, "hide": false, "editable": false, "headerName": "Cost Center Code", "width": 250},
        {"field": "cost_center_name", "hideable": true, "hide": false, "editable": false, "headerName": "Cost Center Name", "width": 250},
        {"field": "invoice_currency", "hideable": true, "hide": false, "editable": false, "headerName": "Invoice Currency", "width": 250},
        {"field": "invoice_value_fx", "hideable": true, "hide": false, "editable": false, "headerName": "Basic Cost p.u in Fx", "width": 250},
        {"field": "invoice_value_inf", "hideable": true, "hide": false, "editable": false, "headerName": "Basic Cost p.u in INR", "width": 250},
        {"field": "forwarding_and_insurance", "hideable": true, "hide": false, "editable": false, "headerName": "Forwarding & Insurance", "width": 250},
        {"field": "installation", "hideable": true, "hide": false, "editable": false, "headerName": "Installation", "width": 250},
        {"field": "errection", "hideable": true, "hide": false, "editable": false, "headerName": "Errection", "width": 250},
        {"field": "total_cost_without_tax", "hideable": true, "hide": false, "editable": false, "headerName": "Total Cost Without Tax p.u", "width": 250},
        {"field": "customs", "hideable": true, "hide": false, "editable": false, "headerName": "Customs", "width": 250},
        {"field": "cgst", "hideable": true, "hide": false, "editable": false, "headerName": "CGST", "width": 250},
        {"field": "sgst", "hideable": true, "hide": false, "editable": false, "headerName": "SGST", "width": 250},
        {"field": "igst", "hideable": true, "hide": false, "editable": false, "headerName": "IGST", "width": 250},
        {"field": "other_taxes", "hideable": true, "hide": false, "editable": false, "headerName": "Other Taxes", "width": 250},
        {"field": "total_tax", "hideable": true, "hide": false, "editable": false, "headerName": "Total Tax p.u", "width": 250},
        {"field": "value_of_asset", "hideable": true, "hide": false, "editable": false, "headerName": "Total Cost with Tax p.u", "width": 250},
        {"field": "qty", "hideable": true, "hide": false, "editable": false, "headerName": "Qty", "width": 250},
        {"field": "unit_of_measure", "hideable": true, "hide": false, "editable": false, "headerName": "Unit Of Measure", "width": 250},
        
        {"field": "nature_of_presence", "hideable": true, "hide": false, "editable": false, "headerName": "Nature of Presence", "width": 250},
        {"field": "nature_of_addition", "hideable": true, "hide": false, "editable": false, "headerName": "Nature of Addition", "width": 250},
        {"field": "principal_asset_tag", "hideable": true, "hide": false, "editable": false, "headerName": "Principal Asset to which Cost is Added", "width": 250},
        {"field": "asset_serial_no", "hideable": true, "hide": false, "editable": false, "headerName": "Unique Sl No of the Asset", "width": 250},
        {"field": "manufacturer", "hideable": true, "hide": false, "editable": false, "headerName": "Make / Manufacturer", "width": 250},
        {"field": "model_no", "hideable": true, "hide": false, "editable": false, "headerName": "Model No", "width": 250},
        {"field": "part_no", "hideable": true, "hide": false, "editable": false, "headerName": "Part No", "width": 250},
        {"field": "department_name", "hideable": true, "hide": false, "editable": false, "headerName": "Control Department", "width": 250},
        {"field": "comp_ref_no", "hideable": true, "hide": false, "editable": false, "headerName": "comp ref no", "width": 250},
        {"field": "tag_name", "hideable": true, "hide": false, "editable": false, "headerName": "Asset TAG Name", "width": 250},
        {"field": "tag_division", "hideable": true, "hide": false, "editable": false, "headerName": "Asset TAG Branch", "width": 250},
        {"field": "security_seal_date", "hideable": true, "hide": false, "editable": false, "headerName": "Security Seal Date", "width": 250,
            renderCell: (params) => (
                <p>{getFormatedDate(params.row.security_seal_date)}</p>
            )
        },
        /* Finance Operation */
        {"field": "class_of_asset", "hideable": true, "hide": false, "editable": false, "headerName": "Class of Asset", "width": 250},
        {"field": "record_start_date", "hideable": true, "hide": false, "editable": false, "headerName": "Record Start Date", "width": 250,
            renderCell: (params) => (
                <p>{getFormatedDate(params.row.record_start_date)}</p>
            )
        },
        {"field": "parent_tag", "hideable": true, "hide": false, "editable": false, "headerName": "Parent TAG", "width": 250},
        {"field": "cap_type_of_asset", "hideable": true, "hide": false, "editable": false, "headerName": "Depreciation mode", "width": 250},
        {"field": "asset_in_use_date", "hideable": true, "hide": false, "editable": false, "headerName": "Date of Capitalization / Asset Ready To Use", "width": 250,
            renderCell: (params) => (
                <p>{getFormatedDate(params.row.asset_in_use_date)}</p>
            )
        },
        {"field": "type_of_depreciation", "hideable": true, "hide": false, "editable": false, "headerName": "Type of Depreciation", "Type of Depreciation": 250},
        {"field": "depreciation_rate", "hideable": true, "hide": false, "editable": false, "headerName": "Depreciation Rate(No. of years) [I-GAAP]", "width": 250},
        {"field": "depreciation_end_date", "hideable": true, "hide": false, "editable": false, "headerName": "End Date [I-GAAP]", "width": 250,
            renderCell: (params) => (
                <p>{getFormatedDate(params.row.depreciation_end_date)}</p>
            )
        },
        {"field": "accounting_code", "hideable": true, "hide": false, "editable": false, "headerName": "Accounting Code of Asset", "width": 250},
        {"field": "accounting_name", "hideable": true, "hide": false, "editable": false, "headerName": "Accounting Name of the Asset", "width": 250},
        {"field": "category_name", "hideable": true, "hide": false, "editable": false, "headerName": "Financials Classification", "width": 250},
        {"field": "put_to_use_date", "hideable": true, "hide": false, "editable": false, "headerName": "Put to Use Date", "width": 250,
            renderCell: (params) => (
                <p>{getFormatedDate(params.row.put_to_use_date)}</p>
            )
        },
        {"field": "final_asset", "hideable": true, "hide": false, "editable": false, "headerName": "Final Asset Capitalization Flag", "width": 250},
        {"field": "is_tax_capitalized", "hideable": true, "hide": false, "editable": false, "headerName": "Is Tax Capitalized", "width": 250},
        {"field": "	invoice_currency", "hideable": true, "hide": false, "editable": false, "headerName": "Invoice Currency", "width": 250},
        {"field": "exchange_rate", "hideable": true, "hide": false, "editable": false, "headerName": "Exchange Rate", "width": 250},
        {"field": "is_100_perc_depreciable", "hideable": true, "hide": false, "editable": false, "headerName": "Is 100% Depreciable Asset [I-GAAP] ", "width": 250},
        {"field": "pre_capitalized_asset_value", "hideable": true, "hide": false, "editable": false, "headerName": "Pre Capitalized Asset Value", "width": 250},
        {"field": "is_any_other_accg_gaap_applicable", "hideable": true, "hide": false, "editable": false, "headerName": "Is any other Accg GAAP Applicable", "width": 250},
        {"field": "igaap_limit_amount", "hideable": true, "hide": false, "editable": false, "headerName": "I-GAAP - Limit for 100% Depreciable Asset (Rs)", "width": 250},
        {"field": "salvage_value", "hideable": true, "hide": false, "editable": false, "headerName": "Salvage Value", "width": 250},
        {"field": "igaap_capitalized_value", "hideable": true, "hide": false, "editable": false, "headerName": "Capitalized Value (I-GAAP)", "width": 250},
        {"field": "other_record_start_date", "hideable": true, "hide": false, "editable": false, "headerName": "Record Start Date [Other-GAAP] ", "width": 250,
            renderCell: (params) => (
                <p>{getFormatedDate(params.row.other_record_start_date)}</p>
            )
        },
        {"field": "is_100_perc_other_depreciable", "hideable": true, "hide": false, "editable": false, "headerName": "Is 100% Depreciable Asset [Other-GAAP]", "width": 250},
        {"field": "other_depreciation_rate", "hideable": true, "hide": false, "editable": false, "headerName": "Depreciation Rate(No. of years) [Other-GAAP] ", "width": 250},
        {"field": "depreciation_other_end_date", "hideable": true, "hide": false, "editable": false, "headerName": "End Date [Other-GAAP]", "width": 250,
            renderCell: (params) => (
                <p>{getFormatedDate(params.row.depreciation_other_end_date)}</p>
            )
        },
        {"field": "othergaap_limit_amount", "hideable": true, "hide": false, "editable": false, "headerName": "Other-GAAP - Limit for 100% Depreciable Asset (Rs)", "width": 250},
        {"field": "othergaap_capitalized_value", "hideable": true, "hide": false, "editable": false, "headerName": "Capitalized Value (Other GAAP)", "width": 250},
        
        /* Allocation */

        {"field": "division", "hideable": true, "hide": false, "editable": false, "headerName": "Division", "width": 150},
        {"field": "premises", "hideable": true, "hide": false, "editable": false, "headerName": "Premises", "width": 150},
        {"field": "floor", "hideable": true, "hide": false, "editable": false, "headerName": "Floor", "width": 150},
        {"field": "site_id", "hideable": true, "hide": false, "editable": false, "headerName": "Site ID", "width": 150},
        {"field": "rack_id", "hideable": true, "hide": false, "editable": false, "headerName": "Rack Id", "width": 150},
        {"field": "user_type", "hideable": true, "hide": false, "editable": false, "headerName": "User Type", "width": 150},
        {"field": "user_department", "hideable": true, "hide": false, "editable": false, "headerName": "User Department", "width": 150},
        {"field": "employee_id", "hideable": true, "hide": false, "editable": false, "headerName": "Employee ID", "width": 150},
        {"field": "employee_name", "hideable": true, "hide": false, "editable": false, "headerName": "Employee Name", "width": 150},
        {"field": "user_mail_id", "hideable": true, "hide": false, "editable": false, "headerName": "User Mail ID", "width": 150},
        {"field": "alternative_verification", "hideable": true, "hide": false, "editable": false, "headerName": "Alternative Verification", "width": 150},
        {"field": "child_allocation", "hideable": true, "hide": false, "editable": false, "headerName": "Child Allocation ", "width": 150},
        {"field": "effective_date", "hideable": true, "hide": false, "editable": false, "headerName": "Effective Date", "width": 150},
        
        /* Fixed Asset Register-IGAAP */
        
        {"field": "tag", "hideable": true, "hide": false, "editable": false, "headerName": "TAG", "width": 150},
        {"field": "asset_ob", "hideable": true, "hide": false, "editable": false, "headerName": "Opening Value", "width": 150},
        {"field": "asset_added_value", "hideable": true, "hide": false, "editable": false, "headerName": "Addition", "width": 150},
        {"field": "asset_deleted_value", "hideable": true, "hide": false, "editable": false, "headerName": "Deletion", "width": 150},
        {"field": "asset_cb", "hideable": true, "hide": false, "editable": false, "headerName": "Closing Value", "width": 150},
        {"field": "depreciation_ob", "hideable": true, "hide": false, "editable": false, "headerName": "Opening Acc Dep", "width": 150},
        {"field": "depreciation_added_value", "hideable": true, "hide": false, "editable": false, "headerName": "Addition for the Period", "width": 250},
        {"field": "depreciation_deleted_value", "hideable": true, "hide": false, "editable": false, "headerName": "Deletion for the Period", "width": 250},
        {"field": "depreciation_adjusted_value", "hideable": true, "hide": false, "editable": false, "headerName": "Adjustment for the Period", "width": 250},
        {"field": "new_depreciation_cb", "hideable": true, "hide": false, "editable": false, "headerName": "Closing Acc Dep", "width": 150},
        {"field": "wdv_ob", "hideable": true, "hide": false, "editable": false, "headerName": "Opening WDV", "width": 150},
        {"field": "wdv_cb", "hideable": true, "hide": false, "editable": false, "headerName": "Closing WDV", "width": 150},
        
    ];   
    const CustomToolbar = () => (
        <GridToolbarContainer>
            {/*<GridToolbarExport/>*/}
        </GridToolbarContainer>
    );
    
    const CustomLoader = () => (
        <GridOverlay>
            <CircularProgress color="primary" size={30}/>
        </GridOverlay>
    ); 
    return ( 
        <div id="home">
    <div  className="container">
        <h5>Fixed Asset Register-IGAAP</h5>
        <div className='row'>
            <div className='col'>
            </div>
            <div className='col'>
                <FromFieldDate star="*"
                    label="From Date" 
                    // placeholder="Date of Capitalization / Asset Ready To Use" 
                    inputValue={fromperiod}
                    setInputValue={setFromPeriod} 
                    setErrorMsg={ErrFromPeriod}
                />
                <FromFieldDate star="*"
                    label="From Date" 
                    // placeholder="Date of Capitalization / Asset Ready To Use" 
                    inputValue={toperiod}
                    setInputValue={setToPeriod} 
                    setErrorMsg={ErrToPeriod}
                /> 
                <button className="btn btn-primary btn-md" onClick={fetchDepreciation}> Fetch Data </button>
            </div>
            <div className='col'>
                        <button
                            onClick={fetchAssetsCSV}
                            className='btn btn-primary btn-md'
                            disabled={exporting}
                        ><i className="bi bi-download"></i> {exporting ? 'Exporting...' : 'Export All Data to CSV'}
                        </button>
            </div>
        </div>
        <div className='row' id='print_summary'>
            <div className='col table-responsive' id="dep_summary">
            <Box>
                <DataGrid
                    rows={items}
                    getRowId={(row) => row.id}
                    columns={columns}
                    autoHeight
                    components={{
                        LoadingOverlay: CustomLoader,
                        Toolbar: CustomToolbar,
                        Pagination: () => (
                            <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2}}>
                                <Box sx={{mr: 4}}>
                                    Showing {items.length} records out of {totalItems}
                                </Box>
                                <FormControl variant="outlined" sx={{minWidth: 120}}>
                                    <InputLabel>Rows per page</InputLabel>
                                    <Select value={pageSize} 
                                        onChange={(event) => handlePageSizeChange(Number(event.target.value))}
                                        label="Rows per page">
                                        {[50, 100, 200, 500].map((size) => (
                                            <MenuItem key={size} value={size}>
                                                {size}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <Pagination
                                    count={totalPages}
                                    page={page}
                                    onChange={handlePageChange}
                                    variant="outlined"
                                    shape="rounded"
                                />

                            </Box>
                        ),
                    }}
                    loading={loading}
                />
            </Box>
            </div>
        </div>
     </div>
    </div>
      
  )
}

export default FarDepreciation