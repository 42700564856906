import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import * as CryptoJS from "crypto-js";
import FromFieldText from "../../atoms/FromFieldText";
import {getUserDetails,generatePassword} from '../../services/user.service';

function ViewUser() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [role, setRole] = useState('');
    const [division, setDivision] = useState('');
    const [department, setDepartment] = useState('');
    const [company, setCompanyName] = useState('');
    const [companyId, setCompanyId] = useState('');
    const [tmpPassword, setTmpPassword] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const userid = query.get('data');
    const compid = query.get('compid');
    useEffect(() => {
        if (userid !== '' && userid !== null && compid !== '' && compid !== null) {
        const decryptedBytes = CryptoJS.AES.decrypt(userid.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
            // Convert the decrypted bytes to a string
            const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
            const decryptedUserid = decrypt_data.userid;
            //const decryptedCompId = decrypt_data.compid;
        //encrypted data
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data':{'user_id': decryptedUserid},
            'authToken': sessionStorage.getItem('LoggedInToken', 'LoggedInUserId'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

        let oData = {'token': EncryptData};
            // Fetch data
            getUserDetails(oData).then(function (res) {
                if (res?.data?.data.length) {
                    let userInfo = res.data.data[0];
                    setRole(userInfo.role_name ? userInfo.role_name : '');
                    setCompanyName(userInfo.company_name ? userInfo.company_name : '');
                    setDivision(userInfo.division_name ? userInfo.division_name : '');
                    setDepartment(userInfo.department_name ? userInfo.department_name : '');
                    setName(userInfo.name);
                    setEmail(userInfo.email);
                    setMobile(userInfo.mobile);
                }
            });
        }
    }, []); // Empty dependency array

    const reGenratePwdReq= async (mailstatus="") =>{
        try {
            const decryptedBytes = CryptoJS.AES.decrypt(userid.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
            // Convert the decrypted bytes to a string
            const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
            const decryptedUserid = decrypt_data.userid;
            const decryptedCompId = decrypt_data.compid;
            var obj = {
                'Email': email,
                'mail': mailstatus,
                'user_id': decryptedUserid,
                'company_id': decryptedCompId,
                'created_company_id': decryptedCompId,
                'created_by': sessionStorage.getItem('LoggedInUserId'),
            }
            //encrypted data
            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': obj,
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData3 = {'token': EncryptData}

            generatePassword(oData3).then(function (res) {
                //console.log(res);
                if ((typeof (res.data.errors) != "undefined") && res.data.errors.errno > 0) {
                    setErrMsg(res.data.message);
                    setSuccess(false);
                } else {
                    setSuccess(res.data.message);
                    setTmpPassword(res.data.data.tmp_password);
                    setDisabled(true);
                    setErrMsg(false);
                }
            });
            //setValidName(false);
        } catch (err) {
            setSuccess(false);
            if (!err?.data) {
                console.log(err);
                setErrMsg('No Server Response');
            } else if (err.data?.status === 409) {
                setErrMsg('data already exist');
            } else {
                setErrMsg('Insert/Update Failed');
            }
            //elementRef.current.focus();
        }
    }
    const handleGenrateView = async (e) => {
        e.preventDefault();
        reGenratePwdReq();
    }
    const handleGenrateMail = async (e) => {
        e.preventDefault();
        reGenratePwdReq('true');
    }
    const resetUserInfo = function () {
        window.location.reload(true);
    }
    return (
        <div className="container">

            <div className="row m-2">
                <div className="col-8">
                    <h5>View User Profile</h5>
                </div>
                <div className="col-2">
                <a href={'/user/list'}><button id="btn-submit" className="btn btn-secondary btn-sm"><i class="bi bi-arrow-left-short"></i> Back</button></a>
                </div>
            </div>
            <div className='row'>
                <div className='col-12'>&nbsp;
                {success ? (
                    <div className="alert alert-success" role="alert"><i
                        className="bi bi-check-circle-fill"></i>{success}</div>
                ) : (errMsg ? (<div className="alert alert-danger" role="alert"><i
                        className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>) : ('')
                )}
                </div>
            </div>
            <div>
                {/*<div className="row m-2">
                    <div className="col-6">
                        <FromFieldText
                            label="Company"
                            inputValue={company}
                            setInputValue={setCompanyName} readOnly='readOnly'
                        />
                    </div>
                    <div className="col-6">
                        <FromFieldText
                            label="Role"
                            inputValue={role}
                            setInputValue={setRole} readOnly='readOnly'
                        />
                    </div>
                </div>
                <div className="row m-2">
                    <div className="col-6">
                    <FromFieldText
                            label="Control Department"
                            inputValue={department}
                            setInputValue={setDepartment} readOnly='readOnly'
                        />
                    </div>
                    <div className="col-6">
                    <FromFieldText
                            label="Division"
                            inputValue={division}
                            setInputValue={setDivision} readOnly='readOnly'
                        />
                    </div>
                </div>
                */}
                <div className="row m-2">
                    <div className="col-6">
                        <FromFieldText
                            label="Name"
                            inputValue={name}
                            setInputValue={setName} readOnly='readOnly'
                        />
                    </div>
                    <div className="col-6">
                        <FromFieldText
                            label="Email"
                            inputValue={email}
                            setInputValue={setEmail} readOnly='readOnly'
                        />
                    </div>
                </div>
                
                <div className="row m-2">
                    <div className="col-6">
                        <FromFieldText
                            label="Mobile"
                            inputValue={mobile}
                            setInputValue={setMobile} readOnly='readOnly'
                        />
                    </div>
                    <div className="col-6"></div>
                </div>
                <div className="row m-2">
                    <div className="col-3 text-center">{ tmpPassword?(<span className="alert alert-info"><b>{tmpPassword}</b></span>):''}</div><div className="col-4 text-center"></div>
                </div>
                <div className="row m-2">
                    <div className="col-3 "><form onSubmit={handleGenrateView}>
                    <button id="btn-Regenrate" className="btn btn-outline-primary btn-md" disabled={disabled}><i class="bi bi-repeat"></i> Generate & View </button>
                    </form></div>
                    <div className="col-3 "><form onSubmit={handleGenrateMail}>
                    <button id="btn-submit" className="btn btn-outline-info btn-md" disabled={disabled} ><i class="bi bi-envelope"></i> Generate & Mail </button>
                    </form></div>
                    <div className="col-3 ">
                    <button id="btn-submit" onClick={resetUserInfo} className="btn btn-outline-secondary btn-md"> Cancel</button>
                    
                    </div>
                </div>
                <div className="row m-2">
                    
                </div>
            </div>
        </div>
    )
}

export default ViewUser