import React, { useState, useEffect } from 'react';



function ExceptionReport() {

    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h5>Exception Report</h5>
                </div>
            </div>

            <div className='row mt-4'>
                <div className='table-responsive'>
                    <table className='table-bordered w-100 text-center'>
                        <thead>
                            <tr>
                                <td style={{fontWeight:700,color:'#fff',background:'#000531',padding:'5px'}}>DIN No</td>
                                <td style={{fontWeight:700,color:'#fff',background:'#000531',padding:'5px'}}>TAG No</td>
                                <td style={{fontWeight:700,color:'#fff',background:'#000531',padding:'5px'}}>Highest Bid</td>
                                <td style={{fontWeight:700,color:'#fff',background:'#000531',padding:'5px'}}>Finalized Bid</td>
                                <td style={{fontWeight:700,color:'#fff',background:'#000531',padding:'5px'}}>Action</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>001</td>
                                <td>Furniture-001</td>
                                <td>55000</td>
                                <td>50000</td>
                                <td style={{padding: '10px'}}>
                                    <button type='button' className='btn btn-primary btn-md'>View</button>
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td style={{padding: '10px'}}>
                                    <button type='button' className='btn btn-primary btn-md'>View</button>
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td style={{padding: '10px'}}>
                                    <button type='button' className='btn btn-primary btn-md'>View</button>
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td style={{padding: '10px'}}>
                                    <button type='button' className='btn btn-primary btn-md'>View</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            



        </div>
    )
}

export default ExceptionReport;