import React, {useEffect, useRef, useState} from 'react'
import {useLocation} from "react-router-dom";
//import FromFieldLabel from '../../atoms/FromFieldLabel';
import FromFieldText from '../../atoms/FromFieldText';
import FromFieldTextArea from '../../atoms/FromFieldTextArea';
import {
    getCompanyProfileList,
    getRoleList,
    getManualDetails,
    saveUserManual,
    updateUserManual
} from '../../services/user.service';

import * as CryptoJS from "crypto-js";

import AWS from 'aws-sdk';
// Configure AWS with your credentials
AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    region: process.env.REACT_APP_AWS_BUCKET_REGION,
});
const s3 = new AWS.S3();

const TEXT_REGEX = /^[a-zA-Z0-9-_ ]{1,255}$/;
const TEXT_SPCL_REGEX = /^[A-Za-z0-9-_.,\/\[\]\-()"'+&'%$#@*!= ]{1,500}$/;



function AddUserManual() {
    const [assetInfo, setAssetInfo] = useState({});
    const [validName, setValidName] = useState(true);
    const [disabled, setDisabled] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    const [companyId, setCompanyId] = useState('');
    const [ErrCompanyId, setErrCompanyId] = useState(false);

    const [role, setRole] = useState('');
    const [ErrRole, setErrRole] = useState(false);

    const [name, setName] = useState('');
    const [ErrName, setErrName] = useState(false);

    const [version, setVersion] = useState('');
    const [ErrVersion, setErrVersion] = useState(false);

    const [remarks, setRemarks] = useState('');
    const [ErrRemarks, setErrRemarks] = useState(false);

    const [companies, setCompanies] = useState([]);
    const [roles, setRoles] = useState([]);
    const [manualInfo, setManualInfo] = useState([]);
    const [manualStatus, setManualStatus] = useState('');
    const [ErrManualStatus, setErrManualStatus] = useState('');

    const resetManualInfo = function () {
        setCompanyId('');
        setRole('');
        setName('');
        setVersion('');
        setRemarks('');
        setManualStatus('');
    }

    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const manualid = query.get('manualid');
    const elementRef = useRef();
    useEffect(() => {
        //encrypted data
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            //'param_data':obj,
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token': EncryptData}

        getCompanyProfileList(oData).then(res => {
            if (res?.data?.data?.length) setCompanies(res.data.data);
        });
        
        if (manualid !== '' && manualid !== null) {
            //alert(manualid);
            const decryptedBytes = CryptoJS.AES.decrypt(manualid.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
            // Convert the decrypted bytes to a string
            const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
            const decryptedManualid = decrypt_data.manualid;
            const comp_id = decrypt_data.compid;
            //alert(decryptedManualid);
            getManualInfo(decryptedManualid);
            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': {'company_id': comp_id},
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData1 = {'token': EncryptData}
            getRoleList(oData1).then(res => {
                if (res?.data?.data?.length) setRoles(res.data.data);
            });
        }
    }, []);

    const getManualInfo = function (manualid) {

        try {
            var data1 = { 'manual_id': manualid }
            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': data1,
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData1 = {'token': EncryptData}
            getManualDetails(oData1).then(function (res) {
                if (res?.data?.data.length) {
                    let manualInfo = res.data.data[0];
                    setManualInfo(manualInfo);
                    setCompanyId(manualInfo.company_id);
                    setRole(manualInfo.role_id);
                    setName(manualInfo.name);
                    setVersion(manualInfo.version);
                    setRemarks(manualInfo.remarks);
                    setManualStatus(manualInfo.status);
                }
            })
        } catch (err) {
            //setAssetInfo('');
        }

    }
    const handleCompanyId = (e) => {
        let name = e.target.name;
        let company_value = e.target.value;
        setCompanyId(company_value);
        //encrypted data
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': {'company_id': company_value},
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData2 = {'token': EncryptData}
        getRoleList(oData2).then(res => {
            if (res?.data?.data?.length) setRoles(res.data.data);
        });
    }
    const handleRole = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setRole(e.target.value);
    }
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        var valid_Name = true;
        // if button enabled with JS hack
        if (!TEXT_REGEX.test(companyId)) { //alert(companyId);
            setErrCompanyId('company is required');
            valid_Name = false;
            //return;
        } else {
            setErrCompanyId('');
        }
        if (!TEXT_REGEX.test(role)) { //alert(role);
            setErrRole('Role is required');
            valid_Name = false;
            //return;
        } else {
            setErrRole('');
        }
        if (!TEXT_REGEX.test(name)) { //alert(name);
            setErrName('Name is required');
            valid_Name = false; //alert(valid_Name);
            //return;
        } else {
            setErrName('');
        }
        if (!TEXT_SPCL_REGEX.test(version)) { //alert(version);
            setErrVersion('Version is required');
            valid_Name = false;
            //return;
        } else {
            setErrVersion('');
        }
        if (!TEXT_SPCL_REGEX.test(remarks)) { 
            setErrRemarks('Remarks is required');
            valid_Name = false;
            //return;
        } else {
            setErrRemarks('');
        }
        if (uploadassetimage == '') {
            setErrUploadManualDoc('Upload manual doc is required');
            valid_Name = false;
            //return;
        } else {
            setErrUploadManualDoc('');
        }
        if (!TEXT_REGEX.test(manualStatus)) { //alert(name);
            setErrManualStatus('Manual Status is required');
            valid_Name = false; //alert(manualStatus);
            //return;
        } else {
            setErrManualStatus('');
        }
        //alert(valid_Name);
        if (valid_Name) { //alert(manualid);
            if (manualid !== '' && manualid !== null) {
                updateData(); // update data
            } else {
                saveData();  // submit form
            }
        } else {  //alert('fa');
            return false;
        }
    }
    const handleManualStatus = (e) => {
        setManualStatus(e.target.value);
    }
    const [uploadassetimage, setUploadManualDoc] = useState([]);
    const [ErrUploadManualDoc, setErrUploadManualDoc] = useState(false);

    const handleUploadImage = (e) => {
        const files = e.target.files;
        // Clear error if validations pass
        setErrUploadManualDoc('');

        // Set the files in state
        setUploadManualDoc(files);
    };
    const uploadFile = (file, manual_doc_name) => {
        const params = {
            Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
            Key: 'manual/' + manual_doc_name, // +'.png', // Replace with your desired S3 key
            Body: file,
        };
        s3.upload(params, (err, data) => {
            if (err) {
                console.error('Error uploading file:', err);
                setErrMsg('Error uploading file');
            } else {
                console.log('File uploaded successfully:', data);
                return params.name;
            }
        });
    };
    
    const saveData = function () {
        try {
            var oData = {
                'company_id': companyId,
                'role_id': role,
                'name': name,
                'version': version,
                'remarks': remarks,
                'status': manualStatus,
                'created_by': sessionStorage.getItem('LoggedInUserId'),
            }
            const file = uploadassetimage[0];
                const fileName = file.name;
                //console.log(file);
                //const fileExtension = fileName.split('.').pop();
                const fileExtension = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
                let manual_doc_name = name + '-' + version  +  '.' + fileExtension;
                if (file) {
                    uploadFile(file, manual_doc_name);
                }
                oData.manual_doc = manual_doc_name;
            //encrypted data
            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': oData,
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData3 = {'token': EncryptData}

            saveUserManual(oData3).then(function (res) {
                //console.log(res);
                if ((typeof (res.data.errors) != "undefined") && res.data.errors.errno > 0) {
                    setErrMsg(res.data.message);
                    setSuccess(false);
                } else {
                    setSuccess(res.data.message);
                    setDisabled(true);
                    setErrMsg(false);
                    resetManualInfo();
                }
            });

            //setValidName(false);
        } catch (err) {
            setSuccess(false);
            if (!err?.data) {
                console.log(err);
                setErrMsg('No Server Response');
            } else if (err.data?.status === 409) {
                setErrMsg('data already exist');
            } else {
                setErrMsg('Insert/Update Failed');
            }
            //elementRef.current.focus();
        }
    }

    const updateData = function () {
        const decryptedBytes = CryptoJS.AES.decrypt(manualid.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
        // Convert the decrypted bytes to a string
        const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
        const decryptedManualid = decrypt_data.manualid;
        try {
            var obj = {
                'manual_id': decryptedManualid,
                'company_id': companyId,
                'role_id': role,
                'name': name,
                'version': version,
                'remarks': remarks,
                'status': manualStatus,
                'created_by': sessionStorage.getItem('LoggedInUserId'),
            }
            //encrypted data
            let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': obj,
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            let oData3 = {'token': EncryptData}

            updateUserManual(oData3).then(function (res) {
                //console.log(res);
                if ((typeof (res.data.errors) != "undefined") && res.data.errors.errno > 0) {
                    setErrMsg(res.data.message);
                    setSuccess(false);
                } else {
                    setSuccess(res.data.message);
                    setDisabled(true);
                    setErrMsg(false);
                    resetManualInfo();
                }
            });
            //setValidName(false);
        } catch (err) {
            setSuccess(false);
            if (!err?.data) {
                console.log(err);
                setErrMsg('No Server Response');
            } else if (err.data?.status === 409) {
                setErrMsg('data already exist');
            } else {
                setErrMsg('Insert/Update Failed');
            }
            //elementRef.current.focus();
        }
    }
    const handleTabChange11 = (tabNumber) => {
        setActiveTab(tabNumber);
    };
    const [activeTab, setActiveTab] = useState(1);

    return (
        <div className="container">
            <div className="row m-2">
                <div className="col-6">
                    {(manualid !== '' && manualid !== null) ? <h5>Update User Manual</h5>
                        : <h5>Add User Manual</h5>}
                </div>
                <div className="invalid-feedback" role="alert">
                    <i className="fa fa-info-circle"></i>
                    Please Fill all mandatory field.
                </div>
                {success ? (
                    <div className="alert alert-success" role="alert"><i
                        className="bi bi-check-circle-fill"></i>{success}</div>
                ) : (errMsg ? (<div className="alert alert-danger" role="alert"><i
                        className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>) : ('')
                )}
            </div>
            <div className='row'>
                <div className='col-12'>&nbsp;</div>
            </div>
            
            <form onSubmit={handleSubmit}>
                
                        <div className="row m-2">
                            <div className="col-6">
                                <div className="row">
                                    <div className="col-md-6">
                                        <label>Company </label><span className="star error"> *</span>
                                    </div>

                                    <div className="col-md-6">

                                        <select onChange={handleCompanyId} className="form-select form-select-sm">
                                            <option value="">Select Company</option>
                                            {companies && companies.length > 0 ? (
                                                companies.map((option) => (
                                                    <option value={option.company_id}
                                                            selected={(companyId === option.company_id) ? "selected" : ''}>{option.company_name}</option>
                                                ))
                                            ) : (
                                                <option value="">No Data</option>
                                            )}
                                        </select>
                                        <span className="invalid-feedback"> {ErrCompanyId} </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6"></div>
                        </div>
                        <div className="row m-2">
                            <div className="col-6">
                                <div className="row">
                                    <div className="col-md-6">
                                        <label>Role Code</label><span className="star error"> *</span>
                                    </div>
                                    <div className="col-md-6">
                                        <select onChange={handleRole} className="form-select form-select-sm"
                                                value={role}>
                                            <option value="">Select Role</option>
                                            {roles && roles.length > 0 ? (
                                                roles.map((option) => (
                                                    <option key={option.id} value={option.id}>
                                                        {option.role_name} / {option.role_short_name}
                                                    </option>
                                                ))
                                            ) : (
                                                <option value="">No Data</option>
                                            )}
                                        </select>
                                        <span className="invalid-feedback"> {ErrRole} </span>
                                    </div>
                                    <div className="col-6"></div>
                                </div>
                            </div>
                        </div>
                        
                        
                        <div className="row m-2">
                            <div className="col-6">
                                <FromFieldText star="*"
                                    label="User Manual Name"
                                    inputValue={name}
                                    setInputValue={setName}
                                    setErrorMsg={ErrName}
                                />
                            </div>
                            <div className="col-6"></div>
                        </div>
                        <div className="row m-2">
                            <div className="col-6">
                                <FromFieldText star="*"
                                    label="User Manual Version"
                                    inputValue={version}
                                    setInputValue={setVersion}
                                    setErrorMsg={ErrVersion}
                                />
                            </div>
                        </div>
                        <div className="row m-2">
                            <div className="col-6">
                                <FromFieldTextArea star="*"
                                    label="Remark"
                                    inputValue={remarks}
                                    setInputValue={setRemarks}
                                    setErrorMsg={ErrRemarks}
                                />
                            </div>
                        </div>
                        <div className="row m-2">
                            <div className="col-6">
                            <div className="row form-fields">
                                <div className="col-md-6">
                                    <label> Manual Document </label>
                                    <span className="star error"> *</span>
                                </div>
                                <div className="col-md-6">
                                    <input type="file" name='asset_image' multiple onChange={handleUploadImage}/>
                                    <span className="invalid-feedback"> {ErrUploadManualDoc} </span>
                                </div>
                            </div>
                            </div>
                            <div className="col-6"></div>
                        </div>
                        <div className="row m-2">
                            <div className="col-6">
                                <div className="row form-fields">
                                    <div className="col-md-6">
                                        <label> Manual Status </label>
                                        <span className="star error">  </span>
                                    </div>
                                    <div className="col-md-6">

                                        <select onChange={handleManualStatus} name="manual_status"
                                                className="form-select form-select-sm">
                                            <option value="">Select Status</option>
                                            <option value="1" selected={(manualStatus == "1") ? "selected" : ''}>Active
                                            </option>
                                            <option value="0"
                                                    selected={(manualStatus == "0") ? "selected" : ''}>Deactive
                                            </option>
                                        </select> <span className="invalid-feedback"> {ErrManualStatus} </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6"></div>
                        </div>
                        <div className="row">
                            <div className="col-3"></div>
                            <div className="col-6">
                                {(manualid !== '' && manualid !== null) ? <div>
                                        <button className="btn btn-primary btn-md m-2" disabled={disabled}>Update</button>
                                        &nbsp;&nbsp;<a href={'/manual/list/?tag=' + manualid}
                                                       className="btn btn-secondary btn-md">Cancel</a></div>
                                    : <button id="btn-submit" className="btn btn-primary btn-md m-2"
                                              disabled={disabled}> SAVE </button>
                                }
                            </div>
                            <div className="col-3"></div>
                        </div>

            </form>
        </div>
    )
}

export default AddUserManual