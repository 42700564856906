import React,{useState,useEffect} from 'react'
import { getPermanentTransferData, savePermanentTransferData } from '../../../services/asset.service';
import FromFieldLabel from '../../../atoms/FromFieldLabel';
import FromFieldText from '../../../atoms/FromFieldText';
import FromFieldDate from '../../../atoms/FromFieldDate';
import FromFieldTextArea from '../../../atoms/FromFieldTextArea';

const getFormatedDate = function(dateString) {
    if(dateString!=null) {
        let dte = new Date(dateString);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        if (month < 10) {
          month = '0' + month;
        }if (day < 10) {
          day = '0' + day;
        }
        return day + '-' + month + '-' + year;
        //return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
    } else {
        return null;
    }   
  }

function PermanentTransfer() {
    const [tag, setTag] = useState(0);
    var transferId = '';
    const [assetInfo, setAssetInfo] = useState({
        "financials_classification": "",
        "invoice_date": "",
        "supplier_name": "",
        "invoice_no": ""
    });
    const [division,setDivision] = useState('');
    const [premises,setPremises] = useState('');
    const [floor,setFloor] = useState('');
    const [siteId,setSiteId] = useState('');
    const [userType,setUserType] = useState('');
    const [userDepartment,setUserDepartment] = useState('');
    const [employeeId,setEmployeeId] = useState('');
    const [employeeName,setEmployeeName] = useState('');

    const [transferInitiatedBy,setTransferInitiatedBy] = useState('');
    const [initiationDate,setInitiationDate] = useState('');
    const [transferApprovedBy,setTransferApprovedBy] = useState('');
    const [approvalDate,setApprovalDate] = useState('');
    const [transferReason,setTransferReason] = useState('');
    
    const [transportMode,setTransportMode] = useState('');
    const [transporterName,setTransporterName] = useState('');
    const [shopingDocNo,setShopingDocNo] = useState('');
    const [shippingDate,setShippingDate] = useState('');
    const [contactPerson,setContactPerson] = useState('');
    const [contactNumber,setContactNumber] = useState('');
    const [receiverConsignment,setReceiverConsignment] = useState('');
    const [receiverMailId,setReceiverMailId] = useState('');
    const [receiverMobile,setReceiverMobile] = useState('');
    const [receiptDate,setReceiptDate] = useState('');
    const [receitRemarks,setReceitRemarks] = useState('');


    // useEffect(function() {

    // },[])
    const getTagInfo = function() {
        getPermanentTransferData(tag).then(function(res){
            if(res?.data?.data) {
                let data = res.data.data;
                setAssetInfo({
                    "financials_classification": data?.financials_classification,
                    "invoice_date": data?.invoice_date,
                    "supplier_name": data?.supplier_name,
                    "invoice_no": data?.invoice_no
                });
                transferId = data?.id;
                setTag(tag);
                setTransportMode(data?.transport_mode);
                setTransporterName(data?.transporter_name);
                setShopingDocNo(data?.shipping_doc_no);
               setShippingDate(data?.shipping_date);
                setContactPerson(data?.contact_person_name);
                setContactNumber(data?.contact_number);
                setReceiverConsignment(data?.receiver_consignment);
                setReceiverMailId(data?.receiver_mailid);
                setReceiverMobile(data?.receiver_mobile);
                setReceitRemarks(data?.recipt_remark);
              setReceiptDate(data?.recipt_date);
                setTransferInitiatedBy(data?.initiated_by);
               setInitiationDate(data?.initiated_date);
                setTransferApprovedBy(data?.approved_by);
               setApprovalDate(data?.approved_date);
                setTransferReason(data?.transfer_reason);
                setEmployeeId(data?.employee_id);
                setEmployeeName(data?.employee_name);
                setDivision(data?.division);
                setPremises(data?.premises);
                setFloor(data?.floor);
                setSiteId(data?.site_id);
                setUserType(data?.user_type);
                setUserDepartment(data?.user_department);
            }
        })
    }
    const saveData = function() {
        var obj = {
            "id":transferId,
            "tag":tag,
            "transport_mode":transportMode,
            "transporter_name":transporterName,
            "shipping_doc_no":shopingDocNo,
            "shipping_date":shippingDate?.dbFormatedDate(),
            "contact_person_name":contactPerson,
            "contact_number":contactNumber,
            "receiver_consignment":receiverConsignment,
            "receiver_mailid":receiverMailId,
            "receiver_mobile":receiverMobile,
            "recipt_remark":receitRemarks,
            "recipt_date":receiptDate?.dbFormatedDate(),
            "initiated_by":transferInitiatedBy,
            "initiated_date":initiationDate?.dbFormatedDate(),
            "approved_by":transferApprovedBy,
            "approved_date":approvalDate?.dbFormatedDate(),
            "transfer_reason":transferReason,
            "employee_id":employeeId,
            "employee_name":employeeName,
            "division":division,
            "premises":premises,
            "floor":floor,
            "site_id":siteId,
            "user_type":userType,
            "user_department":userDepartment,
        }
        savePermanentTransferData(obj).then(function(res) {
            console.log(res);
        })
    }
    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h5>Single Asset External Transfer</h5>
                </div>
            </div>
            <div className='row'>
                <div className='col'><h5 className='align-right'>TAG </h5></div>
                <div className='col'><input onChange={function(event){setTag(event.target.value)}} type="text" className="form-control"  placeholder='Enter the asset TAG'/></div>
                <div className='col'><button onClick={getTagInfo} className='btn btn-primary'> Search </button></div>
            </div>
            <div className="row">
                <div className="col-6">
                    <FromFieldLabel  
                        label="Financial Classification" 
                        inputValue={assetInfo.financials_classification}
                        /> 
                </div>
                <div className="col-6">
                    <FromFieldLabel
                        label="Invoice Date" 
                        inputValue={getFormatedDate(assetInfo.invoice_date)}
                        /> 
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <FromFieldLabel  
                        label="Supplier Name" 
                        inputValue={assetInfo.supplier_name}
                        /> 
                </div>
                <div className="col-6">
                    <FromFieldLabel
                        label="Supplier Invoice No" 
                        inputValue={assetInfo.invoice_no} 
                        /> 
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <FromFieldLabel  
                        label="Current Division" 
                        inputValue={assetInfo.division}
                        /> 
                </div>
                <div className="col-6">
                    <FromFieldLabel
                        label="Current Premises" 
                        inputValue={assetInfo.premises} 
                        /> 
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <FromFieldLabel  
                        label="Current Floor" 
                        inputValue={assetInfo.floor}
                        /> 
                </div>
                <div className="col-6">
                    <FromFieldLabel
                        label="Current Site ID" 
                        inputValue={assetInfo.site_id} 
                        /> 
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <FromFieldLabel  
                        label="Current User Type" 
                        inputValue={assetInfo.user_type}
                        /> 
                </div>
                <div className="col-6">
                    <FromFieldLabel
                        label="Current User Department" 
                        inputValue={assetInfo.user_department} 
                        /> 
                </div>
            </div>
            <div className="row bottom-border">
                <div className="col-6">
                    <FromFieldLabel  
                        label="Current Employee ID" 
                        inputValue={assetInfo.employee_id}
                        /> 
                </div>
                <div className="col-6">
                    <FromFieldLabel
                        label="Current Employee Name" 
                        inputValue={assetInfo.employee_name} 
                        /> 
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <FromFieldText  star="*"
                        label="Transfer Initiated By" 
                        placeholder="Transfer Initiated By" 
                        inputValue={transferInitiatedBy}
                        setInputValue={setTransferInitiatedBy}
                        /> 
                </div>
                <div className="col-6">
                    <FromFieldDate star="*"
                        label="Date of Initiation" 
                        inputValue={initiationDate}
                        setInputValue={setInitiationDate} 
                        /> 
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <FromFieldText  star="*"
                        label="Transfer Approved By" 
                        placeholder="Transfer Approved By" 
                        inputValue={transferApprovedBy}
                        setInputValue={setTransferApprovedBy}
                        /> 
                </div>
                <div className="col-6">
                    <FromFieldDate star="*"
                        label="Date of Approval" 
                        inputValue={approvalDate}
                        setInputValue={setApprovalDate} 
                        /> 
                </div>
            </div>
            <div className="row bottom-border">
                <div className="col-6">
                    <FromFieldText  star="*"
                        label="Reason For Transfer" 
                        placeholder="Reason For Transfer" 
                        inputValue={transferReason}
                        setInputValue={setTransferReason}
                        /> 
                </div>
                <div className="col-6">
                    {/* <FromFieldText
                        label="Movement ID" 
                        inputValue={premises}
                        setInputValue={setPremises} 
                        />  */}
                </div>
            </div>

            <div className="row">
                <div className="col-6">
                    <FromFieldText  star="*"
                        label="Mode of Transport" 
                        placeholder="Mode of Transport" 
                        inputValue={transportMode}
                        setInputValue={setTransportMode}
                        /> 
                </div>
                <div className="col-6">
                    <FromFieldText star="*"
                        label="Transporter Name" 
                        placeholder="Transporter Name" 
                        inputValue={transporterName}
                        setInputValue={setTransporterName} 
                        /> 
                </div>
            </div>

            <div className="row">
                <div className="col-6">
                    <FromFieldText  star="*"
                        label="AWB/Shipping Doc No" 
                        placeholder="AWB/Shipping Doc No" 
                        inputValue={shopingDocNo}
                        setInputValue={setShopingDocNo}
                        /> 
                </div>
                <div className="col-6">
                    <FromFieldDate star="*"
                        label="Date of Shipping"
                        inputValue={shippingDate}
                        setInputValue={setShippingDate} 
                        /> 
                </div>
            </div>

            <div className="row">
                <div className="col-6">
                    <FromFieldText  star="*"
                        label="Contact Person" 
                        placeholder="Contact Person" 
                        inputValue={contactPerson}
                        setInputValue={setContactPerson}
                        /> 
                </div>
                <div className="col-6">
                    <FromFieldText  star="*"
                        label="Contact Number" 
                        placeholder="Contact Number" 
                        inputValue={contactNumber}
                        setInputValue={setContactNumber} 
                        /> 
                </div>
            </div>

            <div className="row">
                <div className="col-6">
                    <FromFieldText  star="*"
                        label="Receiver of Consignment" 
                        placeholder="Receiver of Consignment" 
                        inputValue={receiverConsignment}
                        setInputValue={setReceiverConsignment}
                        /> 
                </div>
                <div className="col-6">
                    <FromFieldText  star="*"
                        label="Mail ID of Receiver" 
                        placeholder="Mail ID of Receiver" 
                        inputValue={receiverMailId}
                        setInputValue={setReceiverMailId} 
                        /> 
                </div>
            </div>

            <div className="row">
                <div className="col-6">
                    <FromFieldText  star="*"
                        label="Mobile of Receiver" 
                        placeholder="Mobile of Receiver" 
                        inputValue={receiverMobile}
                        setInputValue={setReceiverMobile}
                        /> 
                </div>
                <div className="col-6">
                     
                </div>
            </div>

            <div className="row bottom-border">
                
                <div className="col-6"><h6>Update Recieved Information</h6></div>
                <div className="col-6"></div>
                </div>
            <div className="row">
                <div className="col-6">
                <FromFieldDate  star="*"
                        label="Date of Receipt"
                        inputValue={receiptDate}
                        setInputValue={setReceiptDate} 
                        /> 
                </div>
                <div className="col-6">
                <FromFieldTextArea  star="*"
                        label="Receipt Remarks" 
                        placeholder="Receipt Remarks" 
                        inputValue={receitRemarks}
                        setInputValue={setReceitRemarks}
                        />
                </div>
            </div>
            

            {/*            
            <div className="row">
                <div className="col-6">
                    <FromFieldText  
                        label="New Division" 
                        placeholder="New Division" 
                        inputValue={division}
                        setInputValue={setDivision}
                        /> 
                </div>
                <div className="col-6">
                    <FromFieldText
                        label="New Premises" 
                        placeholder="New Premises" 
                        inputValue={premises}
                        setInputValue={setPremises} 
                        /> 
                </div>
            </div>
            
            <div className="row">
                <div className="col-6">
                    <FromFieldText  
                        label="New Floor" 
                        placeholder="New Floor" 
                        inputValue={floor}
                        setInputValue={setFloor}
                        /> 
                </div>
                <div className="col-6">
                    <FromFieldText
                        label="New Site ID" 
                        placeholder="New Site ID" 
                        inputValue={siteId}
                        setInputValue={setSiteId} 
                        /> 
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <FromFieldText  
                        label="New User Type" 
                        placeholder="New User Type" 
                        inputValue={userType}
                        setInputValue={setUserType}
                        /> 
                </div>
                <div className="col-6">
                    <FromFieldText
                        label="New User Department" 
                        placeholder="New User Department" 
                        inputValue={userDepartment}
                        setInputValue={setUserDepartment} 
                        /> 
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <FromFieldText  
                        label="New Employee ID" 
                        placeholder="New Employee ID" 
                        inputValue={employeeId}
                        setInputValue={setEmployeeId}
                        /> 
                </div>
                <div className="col-6">
                    <FromFieldText
                        label="New Employee Name" 
                        placeholder="New Employee Name" 
                        inputValue={employeeName}
                        setInputValue={setEmployeeName} 
                        /> 
                </div>
            </div>*/}
            
            <div className="row">
                <div className="col-6"></div>
                <div className="col-6"><button onClick={saveData} className='btn btn-primary'> SAVE </button></div>
            </div>
        </div>
    )
}

export default PermanentTransfer


