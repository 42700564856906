import React from 'react'

function FromFileUpload(props) {
  
  return (
    <div className="row form-fields">
        <div className="label col-6" > 
            <label> {props.label} <span className="star error"> {props.star} </span></label>
             
        </div>
        <div className="item col-6"> 
            <input type="file" multiple
                className="form-control" 
                placeholder={props.placeholder} 
                name={props.inputName}
                onChange={ (event)=>props.setSelectedFile( event.target.files[props.inputName] ) } 
                readOnly={props.readOnly}
                />
          <span className="invalid-feedback"> {props.setErrorMsg} </span>
        </div>
    </div>
  )
}

export default FromFileUpload