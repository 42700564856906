import axios from "axios";
var baseURL = process.env.REACT_APP_API_BASE_URL;
var apis = {
    'getTagDivisionList': `${baseURL}masters/tagdivisionlist`,
    'saveTagDivision': `${baseURL}masters/savetagdivision`,
    'getTagDivisionsInfo': `${baseURL}masters/tagdivisionsinfo`,
    'deleteTagDivision': `${baseURL}masters/deletetagdivision`,

    'getOtherTagDivisionList': `${baseURL}masters/othertagdivisionlist`,
    'saveOtherTagDivision': `${baseURL}masters/saveothertagdivision`,
    'getOtherTagDivisionsInfo': `${baseURL}masters/othertagdivisionsinfo`,
    'deleteOtherTagDivision': `${baseURL}masters/deleteothertagdivision`,
    
    'getInvoiceCurrencyList': `${baseURL}masters/invoicecurrencylist`,
    'saveInvoiceCurrency': `${baseURL}masters/saveinvoicecurrency`,
    'getInvoiceCurrenciesInfo': `${baseURL}masters/invoicecurrenciesinfo`,
    'deleteInvoiceCurrency': `${baseURL}masters/deleteinvoicecurrency`,
    'saveTypeOfAddition': `${baseURL}masters/savetypeofaddition`,
    'getTypeOfAdditionList': `${baseURL}masters/typeofadditionlist`,
    'getTypeOfAdditionInfo': `${baseURL}masters/typeofadditioninfo`,
    'deleteAdditionType': `${baseURL}masters/deleteadditiontype`,
    'getUnitOfMeasureList': `${baseURL}masters/unitofmeasurelist`,
    'deleteUnitMeasure': `${baseURL}masters/deleteunitmeasure`,
    'getUnitOfMeasureInfo': `${baseURL}masters/unitofmeasureinfo`,
    'saveUnitOfMeasure': `${baseURL}masters/saveunitofmeasure`,
    'getYearOfAdditionList': `${baseURL}masters/yearofadditionlist`,
    'getModuleList' : `${baseURL}masters/module`,
    'getSubModuleList' : `${baseURL}masters/submodule`,
    'getAppModuleList' : `${baseURL}masters/appmodule`,
    'getAppSubModuleList' : `${baseURL}masters/appsubmodule`,
    'getAuditLogsList' : `${baseURL}masters/auditlogslist`,
    'getAuditLogsView' : `${baseURL}masters/auditlogsview`,
    'getAuditLogChangeView' : `${baseURL}masters/auditlogchangeview`,
}

export function getTagDivisionList(data) {
    return axios.put(apis.getTagDivisionList,data);
}
export function deleteTagDivision(data) {
    return axios.put(apis.deleteTagDivision,data);
}
export function getTagDivisionsInfo(data) {
    return axios.put(apis.getTagDivisionsInfo,data);
}
export function saveTagDivision(data) {
    return axios.put(apis.saveTagDivision,data);
}
export function getOtherTagDivisionList(data) {
    return axios.put(apis.getOtherTagDivisionList,data);
}
export function saveOtherTagDivision(data) {
    return axios.put(apis.saveOtherTagDivision,data);
}
export function getOtherTagDivisionsInfo(data) {
    return axios.put(apis.getOtherTagDivisionsInfo,data);
}
export function deleteOtherTagDivision(data) {
    return axios.put(apis.deleteOtherTagDivision,data);
}
export function getInvoiceCurrencyList(data) {
    return axios.put(apis.getInvoiceCurrencyList,data);
}
export function deleteInvoiceCurrency(data) {
    return axios.put(apis.deleteInvoiceCurrency,data);
}
export function saveInvoiceCurrency(data) {
    return axios.put(apis.saveInvoiceCurrency,data);
}
export function getInvoiceCurrenciesInfo(data) {
    return axios.put(apis.getInvoiceCurrenciesInfo,data);
}
export function saveTypeOfAddition(data) {
    return axios.put(apis.saveTypeOfAddition,data);
}
export function getTypeOfAdditionList(data) {
    return axios.put(apis.getTypeOfAdditionList,data);
}
export function deleteAdditionType(data) {
    return axios.put(apis.deleteAdditionType,data);
}
export function getTypeOfAdditionInfo(data) {
    return axios.put(apis.getTypeOfAdditionInfo,data);
}
export function saveUnitOfMeasure(data) {
    return axios.put(apis.saveUnitOfMeasure,data);
}
export function getUnitOfMeasureList(data) {
    return axios.put(apis.getUnitOfMeasureList,data);
}
export function deleteUnitMeasure(data) {
    return axios.put(apis.deleteUnitMeasure,data);
}
export function getUnitOfMeasureInfo(data) {
    return axios.put(apis.getUnitOfMeasureInfo,data);
}
export function getYearOfAdditionList(data) {
    return axios.put(apis.getYearOfAdditionList,data);
}
export function getModuleList(data) {
    return axios.put(apis.getModuleList,data);
}
export function getSubModuleList(data) {
    return axios.put(apis.getSubModuleList,data);
}
export function getAppModuleList(data) {
    return axios.put(apis.getAppModuleList,data);
}
export function getAppSubModuleList(data) {
    return axios.put(apis.getAppSubModuleList,data);
}
export function getAuditLogsList(data) {
    return axios.put(apis.getAuditLogsList,data);
}
export function getAuditLogsView(data) {
    return axios.put(apis.getAuditLogsView,data);
}
export function getAuditLogChangeView(data) {
    return axios.put(apis.getAuditLogChangeView,data);
}

