import React, { useState, useEffect } from 'react';
import FromFieldText from '../../../atoms/FromFieldText';
import FromFieldDate from '../../../atoms/FromFieldDate';


function ConfirmOne() {

    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h5>Confirmation</h5>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-6">
                    <FromFieldDate
                        label="Date of Receipt"
                        inputValue=''
                        setInputValue=''
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-6">
                    <FromFieldText
                        label="Confirmed by PIC Name"
                        placeholder="Confirmed by PIC Name"
                        inputValue=''
                        setInputValue=''
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-6">
                    <FromFieldText
                        label="Upload Confirmation Mail"
                        placeholder="Upload Confirmation Mail"
                        inputValue=''
                        setInputValue=''
                    />
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-12 text-center">
                    <button className="btn btn-primary me-2" > Edit </button>
                    <button className="btn btn-primary me-2" > Cancel </button>
                    <button className="btn btn-primary" > Submit </button>
                </div>
            </div>


        </div>
    )
}

export default ConfirmOne;