import React,{useState,useEffect} from 'react'
import { useLocation } from "react-router-dom";
import FromFieldText from '../../atoms/FromFieldText';
import { saveDivision,getCompanyProfileList,getDivisionInfo } from '../../services/user.service';
import  * as CryptoJS  from "crypto-js";
const TEXT_REGEX = /^[a-zA-Z0-9-_ ]{1,255}$/;
//const Email_REGEX = /^[a-zA-Z0-9@.-_ ]{1,255}$/;
//const Mobile_REGEX = /^[0-9]{10,10}$/;

function Division() {
    
    //const [divisionInfo, setDivisionInfo] = useState({});
    //const [validName, setValidName] = useState(true);
    const [errMsg, setErrMsg] = useState('');
	const [success, setSuccess] = useState(false);
    
    const [companyId,setCompanyId] = useState('');

    const [ErrCompanyId, setErrCompanyId] = useState(false);
    
    const [divisionname,setDivisionName] = useState('');
    const [ErrDivisionName, setErrDivisionName] = useState(false);
    const [divisioncode,setDivisionCode] = useState('');
    const [ErrDivisionCode, setErrDivisionCode] = useState(false);
    const [divisionshortname,setDivisionShortName] = useState('');
    const [ErrDivisionShortName, setErrDivisionShortName] = useState(false);
    const [companies, setCompanies] = useState([]);
    const resetUserInfo = function() { 
        setDivisionName('');
        setDivisionCode('');
        setDivisionShortName('');
        setCompanyId('');
    }

    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const Division_Id = query.get('divisionid');
    
    
    //const elementRef = useRef();
    useEffect(()=>{
        
        if(Division_Id !=='' && Division_Id != null){
            const decryptedBytes = CryptoJS.AES.decrypt(Division_Id.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
            // Convert the decrypted bytes to a string
            const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
            const DivisionId = decrypt_data.division_id;
            //console.log(DivisionId);
            getDivisionData(DivisionId);
              //alert(Tag);
          }
        //encrypted data
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            //'param_data':obj,
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token':EncryptData}
        getCompanyProfileList(oData).then(res=>{
          if(res?.data?.data?.length) setCompanies(res.data.data);
        })
      },[])
    const getDivisionData = function(DivisionId) {

        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
          'param_data':{'division_id':DivisionId},
          'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData1 = {'token':EncryptData}

        getDivisionInfo(oData1).then(function(res){
            console.log(res.data.data);
          if(res?.data?.data) { 
            var divisInfo = res.data.data[0];
            console.log(divisInfo);
            setCompanyId(divisInfo.company_id);
            setDivisionName(divisInfo.division_name);
            setDivisionShortName(divisInfo.division_short_name);
            setDivisionCode(divisInfo.division_code);
          }
        })
      }
    const handleCompanyId = (e) => {
        //let name= e.target.name;
        //let value= e.target.value;
        setCompanyId(e.target.value);
    }
    const handleSubmit = async (e) => { 
		e.preventDefault(); var valid_Name=true;
		// if button enabled with JS hack
		//const v1 = TEXT_REGEX.test(name); 
        if (!TEXT_REGEX.test(companyId)) { //alert(name);
			setErrCompanyId('Company name is required'); valid_Name=false; //alert(valid_Name);
			//return;
		}else{ setErrCompanyId('');  }
        if (!TEXT_REGEX.test(divisionname)) { //alert(name);
			setErrDivisionName('Branch name is required'); valid_Name=false; //alert(valid_Name);
			//return;
		}else{ setErrDivisionName('');  }
        if (!TEXT_REGEX.test(divisioncode)) { //alert(name);
			setErrDivisionCode('Branch code is required'); valid_Name=false; //alert(valid_Name);
			//return;
		}else{ setErrDivisionCode('');  }
        if (!TEXT_REGEX.test(divisionshortname)) { //alert(companyId);
			setErrDivisionShortName('Branch short name is required'); valid_Name=false;
			//return;
		}else{ setErrDivisionShortName('');  }
        if (valid_Name) { //alert('test1');
            saveData(); //submit form
		}else{  //alert('fa');
            return false;
        }
    }

    const saveData = function() {
        try {

            if (Division_Id !== '' && Division_Id !== null) {
                const decryptedBytes = CryptoJS.AES.decrypt(Division_Id.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
                // Convert the decrypted bytes to a string
                const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
                const decryptedDivId = decrypt_data.division_id;
                var obj = {
                    'div_id' : decryptedDivId,
                    'division_name' : divisionname,
                    'division_short_name' : divisionshortname,
                    'division_code' : divisioncode,
                    'company_id' : companyId,
                    'status' : '1',
                    'created_by' : sessionStorage.getItem('LoggedInUserId'),
                }
            }else {
                var obj = {
                    'division_name' : divisionname,
                    'division_short_name' : divisionshortname,
                    'division_code' : divisioncode,
                    'company_id' : companyId,
                    'status' : '1',
                    'created_by' : sessionStorage.getItem('LoggedInUserId'),
                }
            }


        //encrypted data
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data':obj,
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token':EncryptData}

			saveDivision(oData).then(function(res) {
                //console.log(res);
                if((typeof(res.data.errors)!= "undefined") && res.data.errors.errno>0){ 
                    setErrMsg(res.data.message);setSuccess(false);
                }else{ 
                    setSuccess(res.data.message); resetUserInfo(); 
                }
            });
             
			//setValidName(false);
		} catch (err) { setSuccess(false);
			if (!err?.data) {
                console.log(err);
				setErrMsg('No Server Response');
			} else if (err.data?.status === 409) {
				setErrMsg('data already exist');
			} else {
				setErrMsg('Insert/Update Failed');
			}
			//elementRef.current.focus();
		}
	}
    return (
        <div className="container">
            
            <div className="row">
                <div className="col-6">
                    <h5>Add Branch</h5>
                </div>
                <div className="invalid-feedback" role="alert">
                    <i className="fa fa-info-circle"></i>
                    Please Fill all mandetory field.
                </div>

                {success ? (
				<div className="alert alert-success" role="alert"><i className="bi bi-check-circle-fill"></i>{success}</div>
			        ) : ( errMsg ? (<div className="alert alert-danger" role="alert"><i className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>):('')
                ) }
            </div>
            
            <div className='row '>
            <div className='col-12'>&nbsp;</div>
            
            </div>
            <form onSubmit={handleSubmit} > 
            <div className="row form-fields">
                <div className="label col-3">
                <label>Company </label><span className="star error"> *</span>
                </div>
               
                <div className="item col-3">
                <select className="form-select form-select-sm mb-3" onChange={handleCompanyId}>
                <option value="">Select Company</option>
                {companies && companies.length > 0 ? (
                    companies.map((option) => (
                    <option value={option.company_id}  selected={(option.company_id == companyId)? "selected" : ''}>{option.company_name}</option>
                    ))
                ) : (
                    <option value="">No Data</option>
                )}
                </select>
                <span className="invalid-feedback"> {ErrCompanyId} </span>
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <FromFieldText  star="*"
                        label="Branch Name" 
                        inputValue={divisionname}
                        setInputValue={setDivisionName}
                        setErrorMsg={ErrDivisionName}
                        /> 
                </div>
                <div className="col-6"></div>
                <div className="col-6">
                <FromFieldText  star="*"
                        label="Branch Short Name" 
                        inputValue={divisionshortname}
                        setInputValue={setDivisionShortName}
                        setErrorMsg={ErrDivisionShortName}
                        />
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <FromFieldText  star="*"
                        label="Branch Code" 
                        inputValue={divisioncode}
                        setInputValue={setDivisionCode}
                        setErrorMsg={ErrDivisionCode}
                        /> 
                </div>
                <div className="col-6"></div>
                <div className="col-6">
                
                </div>
            </div>
            <div className="row">
            <div className="col-3"></div>
                <div className="col-6">
                 <button className="btn btn-primary  btn-sm" > SAVE </button>
                
                 
                    </div>
                <div className="col-3"></div>
            </div>
            </form>
        </div>
    )
}

export default Division


