import React, {useEffect, useRef, useState} from 'react';
import {useLocation} from "react-router-dom";
import FromFieldText from '../../../atoms/FromFieldText';
import FromFieldDate from '../../../atoms/FromFieldDate';
import FromSpFieldText from '../../../atoms/FromSpFieldText';
import {getAllocation, saveAllocation} from '../../../services/asset.service';
import {getDivisionList} from '../../../services/user.service';
import * as CryptoJS from "crypto-js";

Date.prototype.dbFormatedDate = function () {

    let year = this.getFullYear();
    let month = this.getMonth() + 1;
    let day = this.getDate();
    if (month < 10) {
        month = '0' + month;
    }
    if (day < 10) {
        day = '0' + day;
    }
    return year + '-' + month + '-' + day;
    //return `${this.getFullYear()}-${this.getMonth()+1}-${this.getDate()-1}`
}



function getFormatedDate(dateString) {
    if (dateString !== null) {
        let dte = new Date(dateString);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }
        return year + '-' + month + '-' + day;
        //return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
    } else {
        return null;
    }
}

const TEXT_REGEX = /^[A-Za-z0-9-_/.@= ]{1,155}$/;

const Email_REGX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

//const NUMBER_REGEX = /^[0-9]{1,15}$/;

function SingleAllocation() {

    const [assetInfo, setAssetInfo] = useState({});
    const [validName, setValidName] = useState(true);
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
    //const [allocate, setAllocate] = useState('');
    const [tag, setTag] = useState('');
    const [ErrTag, setErrTag] = useState(false);

    const [division, setDivision] = useState('');
    const [divisions, setDivisions] = useState([]);
    const [ErrDivision, setErrDivision] = useState(false);
    const [allocation_status, setAllocationStatus] = useState();
    const [premises, setPremises] = useState('');
    const [ErrPremises, setErrPremises] = useState(false);

    const [floor, setFloor] = useState('');
    const [ErrFloor, setErrFloor] = useState(false);

    const [siteId, setSiteId] = useState('');
    const [ErrSiteId, setErrSiteId] = useState(false);

    const [userType, setUserType] = useState('');
    const [ErrUserType, setErrUserType] = useState(false);

    const [userDepartment, setUserDepartment] = useState('');
    const [ErrUserDepartment, setErrUserDepartment] = useState(false);

    const [employeeId, setEmployeeId] = useState('');
    const [ErrEmployeeId, setErrEmployeeId] = useState(false);

    const [employeeName, setemployeeName] = useState('');
    const [ErrEmployeeName, setErrEmployeeName] = useState(false);
    const [effectivedate, setEffectiveDate] = useState('');
    const [ErrEffectiveDate, setErrEffectiveDate] = useState(false);
    const [assetinusedate, setAssetInuseDate] = useState('');
    //const [invoiceDate,setInvoiceDate] = useState('');
    //const [initiationDate,setInitiationDate] = useState('');
    //const [financialsClassification,setFinancialsClassification] = useState('');
    //const [approvalDate,setApprovalDate] = useState('');
    //const [movementReason,setMovementReason] = useState('');


    const [userMailId, setUserMailId] = useState('');
    const [ErrUserMailId, setErrUserMailId] = useState(false);

    const [alternativeVerification, setAlternativeVerification] = useState('');
    const [ErrAlternativeVerification, setErrAlternativeVerification] = useState(false);

    const [rackId, setRackId] = useState('');
    const [ErrRackId, setErrRackId] = useState(false);

    const [childAllocation, setChildAllocation] = useState('');
    const [ErrChildAllocation, setErrChildAllocation] = useState(false);
    const [auditInfo, setAuditInfo] = useState([]); 
    const resetAssetInfo = function () {
        setTag('');
        setDivision('');
        setPremises('');
        setFloor('');
        setSiteId('');
        setUserType('');
        setUserDepartment('');
        setEmployeeId('');
        setemployeeName('');
        setEffectiveDate('');
        setChildAllocation('');
    }

    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const Tag = query.get('tag');
    const allocate = query.get('allocate');
    const elementRef = useRef();
    useEffect(() => {
        if (Tag !== '' && Tag !== null) {
            const decryptedBytes = CryptoJS.AES.decrypt(Tag.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
            // Convert the decrypted bytes to a string
            const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
            const decryptedTag = decrypt_data.tag;
            getTagAssetInfo(decryptedTag);
            //alert(Tag);
        } else {
            resetAssetInfo();
        }
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token': EncryptData}
        getDivisionList(oData).then(res => {
            if (res?.data?.data?.length) setDivisions(res.data.data);
        });
    }, [])
    const getTagAssetInfo = function (decryptedTag) {
        resetAssetInfo();
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': {'tag': decryptedTag},
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData1 = {'token': EncryptData}
        getAllocation(oData1).then(function (res) {
            //getTransferData(Tag).then(function(res){
            if (res?.data?.data?.assetInfo) {
                var aInfo = res.data.data.assetInfo;
                var aLogInfo = res.data.data.logInfo;
                console.log(Info);
                setAssetInfo(aInfo);
                setTag(aInfo.tag);
                setDivision(aInfo.division_id ? aInfo.division_id : '');
                setAllocationStatus(aInfo.allocation_status);

                setAssetInuseDate(aInfo.asset_in_use_date ? (new Date(aInfo.asset_in_use_date)) : '');
                console.log(assetinusedate);
                //console.log(allocation_status);
            } else {
                setErrMsg('Assets Not Found');
            }
            if (res?.data?.data?.transferInfo) {
                var aInfo = res.data.data.assetInfo;
                var aLogInfo = res.data.data.logInfo;
                var Info = res.data.data.transferInfo;
                setAuditInfo(Info);
                //setInvoiceDate((new Date(aInfo.invoice_date)));
                //alert(Info.effective_date);
                setDivision(Info.division ? Info.division : '');
                setPremises(Info.premises);
                setFloor(Info.floor);
                setSiteId(Info.site_id);
                setUserType(Info.user_type);
                setUserDepartment(Info.user_department);
                setEmployeeId(Info.employee_id);
                setemployeeName(Info.employee_name);
                setUserMailId(Info.user_mail_id);
                setRackId(Info.rack_id);
                setAlternativeVerification(Info.alternative_verification);

                setEffectiveDate(Info.effective_date ? (new Date(Info.effective_date)) : '');
                if (res.data.data?.logInfo && (aLogInfo.unallocate_date !== null) && (aLogInfo.unallocate_date > Info.effective_date)) {
                    console.log('aLogInfo.unallocate_date');
                    console.log(aLogInfo.unallocate_date);
                    setAssetInuseDate(aLogInfo.unallocate_date ? (new Date(aLogInfo.unallocate_date)) : '');
                } else if (Info.effective_date && (Info.effective_date !== null)) {
                    setAssetInuseDate(Info.effective_date ? (new Date(Info.effective_date)) : '');
                    console.log('Info.effective_date');
                    console.log(Info.effective_date);
                } else {
                    setAssetInuseDate(aInfo.invoice_date ? (new Date(aInfo.invoice_date)) : '');
                    console.log('aInfo.invoice_date');
                    console.log(aInfo.invoice_date);
                }
                //setFinancialsClassification(Info.financials_classification);
            }
        })
    }
    const getTagInfo = function () {
        resetAssetInfo();
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': {'tag': tag},
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token': EncryptData}
        getAllocation(oData).then(function (res) {
            console.log(res.data.data);
            var aInfo = {};
            //getTransferData(tag).then(function(res){
            if (res?.data?.data?.assetInfo) {
                setErrMsg('');
                aInfo = res.data.data.assetInfo;
                var aLogInfo = res.data.data.logInfo;
                var aTranInfo = res.data.data.transferInfo;
                setAssetInfo(aInfo);
                setDivision(aInfo.division_id ? aInfo.division_id : '');
                setAllocationStatus(aInfo.allocation_status);
                console.log(aLogInfo);
                //setInvoiceDate((new Date(aInfo.invoice_date)));
                setTag(aInfo.tag);
                //console.log(aLogInfo.length);
                if (res.data.data?.logInfo && (aLogInfo.unallocate_date !== null) && (aLogInfo.unallocate_date > aTranInfo?.effective_date)) {
                    console.log('aLogInfo.unallocate_date');
                    console.log(aLogInfo.unallocate_date);
                    setAssetInuseDate(aLogInfo.unallocate_date ? (new Date(aLogInfo.unallocate_date)) : '');
                } else if (aTranInfo?.effective_date && (aTranInfo.effective_date !== null)) {
                    setAssetInuseDate(aTranInfo.effective_date ? (new Date(aTranInfo.effective_date)) : '');
                    console.log('aTranInfo.effective_date');
                    console.log(aTranInfo.effective_date);
                } else {
                    setAssetInuseDate(aInfo?.invoice_date ? (new Date(aInfo.invoice_date)) : '');
                    console.log('aInfo.invoice_date');
                    console.log(aInfo.invoice_date);
                }


            } else {
                setAssetInfo({});
                setErrMsg('Assets Not Found');
            }
            if (res?.data?.data?.transferInfo) {
                var Info = res.data.data.transferInfo;
                //alert(Info.division);
                setAuditInfo(Info);
                setDivision(Info.division ? Info.division : '');
                setPremises(Info.premises);
                setFloor(Info.floor);
                setSiteId(Info.site_id);
                setRackId(Info.rack_id);
                setUserType(Info.user_type);
                setUserDepartment(Info.user_department);
                setEmployeeId(Info.employee_id);
                setemployeeName(Info.employee_name);
                setUserMailId(Info.user_mail_id);
                setAlternativeVerification(Info.alternative_verification);
                setChildAllocation(Info.child_allocation);
                setEffectiveDate(Info.effective_date ? (new Date(Info.effective_date)) : '');

                //setFinancialsClassification(Info.financials_classification);
            }
        })
    }

    const handleUsetType = (e) => {
        setUserType(e.target.value);
        
        if(e.target.value!=="Individual"){

            setEmployeeId('');
            setUserMailId('');
            setemployeeName('');
        }
    };


    const handleVerification = (e) => {
        setAlternativeVerification(e.target.value);
    }
    const handleChildAllocation = (e) => {
        setChildAllocation(e.target.value);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        var valid_Name = true;
        // if button enabled with JS hack
        //const v1 = TEXT_REGEX.test(division);
        //alert(tag);
        if (!TEXT_REGEX.test(tag) && tag !== 0) {
            setErrTag('TAG is required');
            valid_Name = false;
            //return;
        } else {
            setErrTag('');
        }
        if (!TEXT_REGEX.test(division)) {
            setErrDivision('Division is required');
            valid_Name = false; //alert(valid_Name);
            //return;
        } else {
            setErrDivision('');
        }
        if (!TEXT_REGEX.test(premises)) {
            setErrPremises('Premises is required');
            valid_Name = false;
            //return;
        } else {
            setErrPremises('');
        }
        if (!TEXT_REGEX.test(floor)) {
            setErrFloor('Floor is required');
            valid_Name = false;
            //return;
        } else {
            setErrFloor('');
        }
        if (!TEXT_REGEX.test(siteId)) {
            setErrSiteId('Site Id is required');
            valid_Name = false;
            //return;
        } else {
            setErrSiteId('');
        }
        if (!TEXT_REGEX.test(userType)) {
            setErrUserType('User Type is required');
            valid_Name = false;
            //return;
        } else {
            setErrUserType('');
        }
        if (!['Individual', 'Team', 'General'].includes(userType)) {
            setErrUserType(' User Type should be one of the allowed values (Individual, Team, General).');
        } else {
            setErrUserType('');
        }
        if (!TEXT_REGEX.test(userDepartment)) {
            setErrUserDepartment('User Department is required');
            valid_Name = false;
            //return;
        } else {
            setErrUserDepartment('');
        }
        if (!TEXT_REGEX.test(employeeId) && (userType === "Individual")) {
            setErrEmployeeId('Employee Id is required');
            valid_Name = false;
            //return;
        } else {
            setErrEmployeeId('');
        }
        if (!TEXT_REGEX.test(employeeName) && (userType === "Individual")) {
            setErrEmployeeName('Employee Name is required');
            valid_Name = false;
            //return;
        } else {
            setErrEmployeeName('');
        }
        if (effectivedate == '') {
            setErrEffectiveDate('Effective Date is required');
            valid_Name = false;
            //return;
        } else {
            setErrEffectiveDate('');
        }
        if (userMailId == '' && (userType === "Individual")) {
            setErrUserMailId('User Mail id is required');
            valid_Name = false;

            //return;
        } else if(!Email_REGX.test(userMailId) && (userType === "Individual")){
            setErrUserMailId('Enter user mail id is not in correct format');
            valid_Name = false;

        }else{
            setErrUserMailId('');
        }
        if (alternativeVerification == '') {
            setErrAlternativeVerification('Alternative verification is required');
            valid_Name = false;
            //return;
        } else {
            setErrAlternativeVerification('');
        }
        if (childAllocation == '') {
            setErrChildAllocation('Alternative verification is required');
            valid_Name = false;
            //return;
        } else {
            setErrChildAllocation('');
        }
        //alert(valid_Name);
        if (valid_Name) { //alert('test1');
            saveData(); //submit form
        } else {
            return false;
        }
    }

    const saveData = function () {
        var obj = {
            'rack_id': rackId,
            'user_mail_id': userMailId,
            'alternative_verification': alternativeVerification,
            'division': division,
            'premises': premises,
            'floor': floor,
            'site_id': siteId,
            'user_type': userType,
            'user_department': userDepartment,
            'employee_id': employeeId,
            'employee_name': employeeName,
            'effective_date': getFormatedDate(effectivedate),
            'tag': tag,
            'child_allocation': childAllocation,
            'company_id': sessionStorage.getItem('LoggedInCompanyId'),
            'division_id': sessionStorage.getItem('LoggedInDivisionId'),
        }
        var objAudit = {'audit_module':'Alloction','audit_method':'allocated',
        'audit_tag':tag,'old_values':auditInfo,'new_values':obj}
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': obj,'audit_params':objAudit,
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token': EncryptData}
        try {
            saveAllocation(oData).then(function (res) {
                //console.log(res);
                if ((typeof (res.data.errors) != "undefined") && res.data.errors.errno > 0) {
                    setErrMsg(res.data.message);
                    setSuccess(false);
                } else {
                    setSuccess(res.data.message);
                    resetAssetInfo();
                }
            });

            //setValidName(false);
        } catch (err) {
            setSuccess(false);
            if (!err?.data) {
                console.log(err);
                setErrMsg('No Server Response');
            } else if (err.data?.status === 409) {
                setErrMsg('data already exist');
            } else {
                setErrMsg('Insert/Update Failed');
            }
            //elementRef.current.focus();
        }
    }

    return (
        <div className="container">

            <div className="row">
                <div className="col">
                    <h5>Single Allocation</h5>
                </div>
                <div className="invalid-feedback" role="alert">
                    <i className="fa fa-info-circle"></i>
                    Please Fill all mandatory field.
                </div>

                {success ? (
                    <div className="alert alert-success" role="alert"><i
                        className="bi bi-check-circle-fill"></i>{success}</div>
                ) : (errMsg ? (<div className="alert alert-danger" role="alert"><i
                        className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>) : ('')
                )}
            </div>

            <div className='row '>
                <div className='col-12'>&nbsp;</div>
                {
                    (Tag !== '' && Tag !== null) ? <div className='row'>
                        <div className='col-10'></div>
                        <div className='col-2'></div>
                    </div> : <div className='row'>
                        <div className='col'><h5 className='align-right'>TAG </h5></div>
                        <div className='col'><input onChange={function (event) {
                            setTag(event.target.value)
                        }} inputValue={assetInfo.tag} type="text" className="form-control"
                                                    placeholder='Enter the asset TAG'/><p
                            className='invalid-feedback'>{ErrTag}</p></div>
                        <div className='col'>
                            <button onClick={getTagInfo} className='btn btn-info btn-sm'><i className="bi bi-search"></i> Search
                            </button>
                            &nbsp;<a href={'/single-allocation'}>
                            <button className='btn btn-info btn-sm'>Reset</button>
                        </a>&nbsp;</div>
                    </div>
                }
            </div>
            <div className='row'></div>
            <div className="row">
                <div className="col-6">
                    <FromFieldText
                        label="TAG"
                        inputValue={assetInfo.tag ? assetInfo.tag : ''}
                        readOnly="readonly"
                    />
                </div>
                <div className='col-6'></div>
            </div>
            <div className="row">
            </div>
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-6">
                        <div className="row">
                            <div className="col-md-6">
                                <label>Division </label><span className="star error"> *</span>
                            </div>
                            <div className="col-md-6">
                                <input className='form-control mb-1' type='text' readOnly value={
                                    divisions && divisions.length > 0 ? divisions.filter((option) => division === option.id)
                                        .map((selectedOption) => selectedOption.division_name).join(', ') : ''}/>
                                </div>
                        </div>
                    </div>
                    <div className="col-6"></div>
                    <div className="col-6">
                        <FromSpFieldText star="*"
                            label="Premises"
                            inputValue={premises}
                            setInputValue={setPremises}
                            setErrorMsg={ErrPremises}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <FromSpFieldText star="*"
                            label="Floor"
                            inputValue={floor}
                            setInputValue={setFloor}
                            setErrorMsg={ErrFloor}
                        />
                    </div>
                    <div className="col-6"></div>
                    <div className="col-6">
                        <FromSpFieldText star="*"
                            label="Site ID"
                            inputValue={siteId}
                            setInputValue={setSiteId}
                            setErrorMsg={ErrSiteId}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <FromSpFieldText star="*"
                            label="Rack Id"
                            inputValue={rackId}
                            setInputValue={setRackId}
                            setErrorMsg={ErrRackId}
                        />
                    </div>
                    <div className="col-6"></div>
                    <div className="col-6"></div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <div className="row">
                            <div className="col-md-6">
                                <label>User Type </label>
                                <span className="star error"> *</span>
                            </div>
                            <div className="col-md-6">
                                <select onChange={handleUsetType} name="user_type"
                                        className="form-select form-select-sm">
                                    <option value="">Select user type</option>
                                    <option value="Individual" selected={userType === "Individual" ? "selected" : ""}>
                                        Individual
                                    </option>
                                    <option value="Team" selected={userType === "Team" ? "selected" : ""}>
                                        Team
                                    </option>
                                    <option value="General" selected={userType === "General" ? "selected" : ""}>
                                        General
                                    </option>
                                </select>{" "}
                                <span className="invalid-feedback"> {ErrUserType} </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-6"></div>
                    <div className="col-6">
                        <FromSpFieldText
                            star="*"
                            label="User Department"
                            inputValue={userDepartment}
                            setInputValue={setUserDepartment}
                            setErrorMsg={ErrUserDepartment}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <FromSpFieldText
                            label="Employee ID"
                            star="*"
                            inputValue={employeeId}
                            setInputValue={setEmployeeId}
                            setErrorMsg={ErrEmployeeId}
                            readOnly={(userType !== "Individual") ? "readOnly" : ""} // Disable if User Type is not Individual
                        />
                    </div>
                    <div className="col-6"></div>
                    <div className="col-6">
                        <FromSpFieldText
                            label="Employee Name"
                            star="*"
                            inputValue={employeeName}
                            setInputValue={setemployeeName}
                            setErrorMsg={ErrEmployeeName}
                            readOnly={(userType !== "Individual") ? "readOnly" : ""} // Disable if User Type is not Individual
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <FromSpFieldText
                            label="User Mail ID"
                            star="*"
                            inputValue={userMailId}
                            setInputValue={setUserMailId}
                            setErrorMsg={ErrUserMailId}
                            readOnly={(userType !== "Individual") ? "readOnly" : ""} // Disable if User Type is not Individual
                        />
                    </div>
                    <div className="col-6"></div>
                    <div className="col-6">
                        <div className="row">
                            <div className="col-md-6">
                                <label> Eligible for Alternative Verification </label>
                                <span className="star error">  </span>
                            </div>
                            <div className="col-md-6">
                                <select onChange={handleVerification} name="alternative_verification"
                                        className="form-select form-select-sm">
                                    <option value="">Select Eligible for Alternative Verification</option>
                                    <option value="Yes"
                                            selected={alternativeVerification === "Yes" ? "selected" : ''}>Yes
                                    </option>
                                    <option value="No"
                                            selected={alternativeVerification === "No" ? "selected" : ''}>No
                                    </option>
                                </select>
                                <span className="invalid-feedback">{ErrAlternativeVerification} </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <FromFieldDate minDate={assetinusedate ? assetinusedate : effectivedate}
                            label="Start Date of Allocation" star="*"
                            inputValue={effectivedate}
                            setInputValue={setEffectiveDate}
                            setErrorMsg={ErrEffectiveDate}
                        />
                    </div>
                    <div className="col-6"></div>
                    <div className="col-6">

                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                    </div>
                    <div className="col-6"></div>
                    <div className="col-6">
                        <div className="row">
                            <div className="col-md-6">
                                <label> Select for child allocation </label>
                                <span className="star error"> * </span>
                            </div>
                            <div className="col-md-6">
                                <select onChange={handleChildAllocation} name="childAllocation"
                                        className="form-select form-select-sm">
                                    <option value="">Select for child allocation</option>
                                    <option value="Yes"
                                            selected={childAllocation === "Yes" ? "selected" : ''}>Yes
                                    </option>
                                    <option value="No"
                                            selected={childAllocation === "No" ? "selected" : ''}>No
                                    </option>
                                </select>
                                <span className="invalid-feedback">{ErrChildAllocation} </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-3"></div>
                    <div className="col-6">
                        {console.log(allocation_status)} {(Tag !== '' && Tag !== null) ?
                        (<div>{(allocate) ? <button className="btn btn-primary btn-md"> Reallocate </button>
                                : <button className="btn btn-primary btn-md"> Update </button>}
                                &nbsp;&nbsp;<a href={'/single-view-allocation?tag=' + Tag}
                                               className="btn btn-secondary btn-md">Cancel</a></div>
                        ) : (allocation_status == 'N') ?
                            <button className="btn btn-primary btn-md"> Allocate </button> : ''
                    }
                    </div>
                    <div className="col-3"></div>
                </div>
            </form>
        </div>
    )
}

export default SingleAllocation


