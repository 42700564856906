import React, { useState, useEffect } from 'react';
import { getAssets, saveAssetDisposal } from '../../../services/asset.service';
import FromFieldLabel from '../../../atoms/FromFieldLabel';
import FromFieldText from '../../../atoms/FromFieldText';
import FromFieldDate from '../../../atoms/FromFieldDate';

import { DataGrid } from '@mui/x-data-grid';
// import axios from "axios";
// import { read } from "xlsx";



const columns = [

    { "field": "sl_no", "hideable": true, "hide": false, "headerName": "Sl No", "width": 50 },
    { "field": "din_no", "hideable": true, "hide": false, "headerName": "DIN No", "width": 50 },
    {
        "field": "tag",  "hide": false, "headerName": "TAG", "width": 80,
        renderCell: (params) => (
            <a href={`/asset-disposal?tag=${params.value}`}>{params.value}</a>
        )
    },

    { "field": "highest_bid", "hideable": true, "hide": false, "headerName": "Highest Bid", "width": 130 },
    { "field": "finalized_bid", "hide": false, "headerName": "Finalized Bid", "width": 130 },
    { "field": "reason_for_deviation", "hide": false, "headerName": "Reason for Deviation", "width": 130 },
];

function ExceptionReportList() {
    const [items, setItems] = useState([]);
    var onCellEditStopFn = (event, newCell) => {
        var obj = {};
        obj[newCell.field] = event.target.value;
    }

    useEffect(() => {
        getAssets().then(function (res) {
            //if(res?.data?.data) {
            if (res?.data?.data?.length) setItems(res.data.data);
        })
    }, [])

    // readAssets();
    return (
        <div className='container'>
            <h3>
                <h2> Exception Report List</h2>

            </h3>
            <DataGrid
                rows={items}
                getRowId={(row) => row.sl_no}
                columns={columns}
                pageSize={5}
                autoHeight
                rowsPerPageOptions={[5]}
                onCellEditStop={onCellEditStopFn}
            />

            <div className="row mt-4">
                <div className="col-12 d-flex  align-items-center">
                    <label style={{marginRight:'10px'}}>Reason for Exception</label>
                    <textarea rows={3} cols="150"></textarea>
                </div>
            </div>

            <div className="row mt-4 align-items-center">
                <div className="col-6">
                    <FromFieldText
                        label="Upload Approval Mail for Exception"
                        placeholder="Upload Approval Mail for Exception"
                        inputValue=''
                        setInputValue=''
                    />
                </div>
                <div className="col-6 text-center">
                <button className="btn btn-primary btn-md me-2"> Edit </button>
                    <button className="btn btn-primary btn-md me-2"> Cancel </button>
                    <button className="btn btn-primary btn-md"> Submit </button>
                </div>
            </div>


        </div>
    )
}

export default ExceptionReportList


