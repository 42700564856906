import React, {useEffect, useState} from 'react';
import {DataGrid, GridToolbarContainer, GridToolbarExport} from '@mui/x-data-grid';
//import {getUnitOfMeasureList} from '../../services/master.service'
import {getTagList} from '../../../services/asset.service';

import * as CryptoJS from "crypto-js";
import {useLocation} from "react-router-dom";


const columns = [
    {"field": "id", "hide": false, "headerName": "Serial No", "width": 500},

];

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport />
        </GridToolbarContainer>
    );
}
function ViewScanSerialNo() {
    const [items, setItems] = useState([]);
    /*var onCellEditStopFn = (event,newCell) => {
      var obj = {};
      obj[newCell.field] = event.target.value;
    }*/

    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const scan_id = query.get('scan_ID');

    useEffect(() => {

        const decryptedBytes = CryptoJS.AES.decrypt(scan_id.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
        // Convert the decrypted bytes to a string
        const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
        const decryptScanId = decrypt_data.scanid;

        //encrypted data
        let EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data':{scan_id:decryptScanId},
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        let oData = {'token': EncryptData}



        getTagList(oData).then(res => {
            if (res?.data?.data?.length) setItems(res.data.data);
        })
    }, []);

    // readCategory();
    return (
        <div className='container'>
            <div className='row'>
                <div className='col-md-6'><h5>Scan Serial No TAG List</h5></div>
                
            </div>
            <div className='row'><div className='col-10'></div> 
            <div className='col-2 pb-2'><a href={'/view-scan-serial-no' }><button  className='btn btn-secondary btn-sm'><i className="bi bi-arrow-left"></i> Back</button></a>
            </div></div>
            <DataGrid
                rows={items}
                getRowId={(row) => row.id}
                columns={columns}
                pageSize={50}
                autoHeight
                rowsPerPageOptions={[50]}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    )
}

export default ViewScanSerialNo
