import React, {useCallback, useEffect, useState} from 'react';
import {getFilterQrAssetsData} from '../../services/asset.service';
import Box from '@mui/material/Box';
import {DataGrid, GridOverlay, GridToolbarContainer, GridToolbarExport} from '@mui/x-data-grid';
import {getAssets, getAssetsCSV} from '../../services/asset.service';
import QRCode from 'qrcode';
import './QRGenrate.css';
import FromFieldSelect from '../../atoms/FromFieldSelect';
import * as CryptoJS from "crypto-js";
import FromFieldDate from "../../atoms/FromFieldDate";
import FromFieldText from "../../atoms/FromFieldText";
import CircularProgress from "@mui/material/CircularProgress";

function getFormatedDate(dateString) {
    if (dateString != null) {
        let dte = new Date(dateString);
        let year = dte.getFullYear();
        let month = dte.getMonth() + 1;
        let day = dte.getDate();
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }
        return year + '-' + month + '-' + day;
        //return `${dte.getFullYear()}-${dte.getMonth()+1}-${dte.getDate()}`;
    } else {
        return null;
    }
}

const columns = [
    {"field": "sl_no", "hideable": true, "hide": true, "editable": false, "headerName": "Sl No", "width": 50},
    {"field": "tag", "hideable": true, "hide": false, "editable": false, "headerName": "Tag", "width": 250},
    {
        "field": "asset_name",
        "hideable": true,
        "hide": false,
        "editable": false,
        "headerName": "Asset Name",
        "width": 250
    },
    {
        "field": "division_name",
        "hideable": true,
        "hide": false,
        "editable": false,
        "headerName": "Division",
        "width": 250
    },
    {
        "field": "tag_name",
        "hideable": true,
        "hide": false,
        "editable": false,
        "headerName": "Asset Tag Name",
        "width": 250
    },
    {
        "field": "tag_division",
        "hideable": true,
        "hide": false,
        "editable": false,
        "headerName": "Tag Division",
        "width": 250
    },

    {"field": "premises", "hideable": true, "hide": false, "editable": false, "headerName": "Premises"},
    {"field": "floor", "hideable": true, "hide": false, "editable": false, "headerName": "Floor"},
    {"field": "site_id", "hideable": true, "hide": false, "editable": false, "headerName": "Site Id"},
    {"field": "user_type", "hideable": true, "hide": false, "editable": false, "headerName": "User Type"},
    {"field": "user_department", "hideable": true, "hide": false, "editable": false, "headerName": "User Department"},
    {"field": "employee_id", "hideable": true, "hide": false, "editable": false, "headerName": "Employee Id"},
    {"field": "employee_name", "hideable": true, "hide": false, "editable": false, "headerName": "Employee Name"},
    {
        "field": "category_name",
        "hideable": false,
        "hide": false,
        "editable": false,
        "headerName": "Financials Classification",
        "width": 180
    },
    {
        "field": "created_at", "editable": true, "hide": false, "headerName": "Created Date", "width": 130,
        renderCell: (params) => (
            <p>{getFormatedDate(params.row.created_at)}</p>
        )
    },
];

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport/>
        </GridToolbarContainer>
    );
}

const CustomLoader = () => (
    <GridOverlay>
        <CircularProgress color="primary" size={30}/>
    </GridOverlay>
);

function AllQRGenrate() {
    const [loading, setLoading] = useState(true);
    const [checkboxSelection] = useState(true);
    const [items, setItems] = useState([]);

    const [searchTag, setSearchTag] = useState('');
    const [ErrSearchTag, setErrSearchTag] = useState(false);

    const [natureOfPresence, setNatureOfPresence] = useState('');
    const [typeOfAssets, setTypeOfAssets] = useState('');
    const [typeOfSiteId, setTypeOfSiteId] = useState('');
    //const [startDate, setStartDate] = useState('');
    //const [endDate, setEndDate] = useState('');
    const [startTAGno, setStartTAGno] = useState('');
    const [endTAGNo, setEndTAGNo] = useState('');
    const [page, setPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [pageSize, setPageSize] = useState(50);
    const [selectedRowsData, setSelectedRowsData] = useState([]);

    const [filterCriteria, setFilterCriteria] = useState('');
    const [ErrFilterCriteria, setErrFilterCriteria] = useState(false);

    const [filterOperator, setFilterOperator] = useState('');
    const [ErrFilterOperator, setErrFilterOperator] = useState(false);

    const [startDate, setStartDate] = useState('');
    const [ErrStartDate, setErrStartDate] = useState(false);

    const [endDate, setEndDate] = useState('');
    const [ErrEndDate, setErrEndDate] = useState(false);

    const calculateOffset = (page, pageSize) => (page - 1) * pageSize;

    const fetchAssets = useCallback(async () => {
        setLoading(true);
        const offset = calculateOffset(page, pageSize);
        const obj = {
            'typeOfAssets': typeOfAssets,
            'natureOfPresence': natureOfPresence,
            'typeOfSiteId': typeOfSiteId,
            'startDate': startDate ? (getFormatedDate(startDate)) : '',
            'endDate': endDate ? (getFormatedDate(endDate)) : '',
            'startTAGno': startTAGno,
            'endTAGNo': endTAGNo, //'offset': offset, 'pageSize': pageSize,
            'searchTag': searchTag, 'filterCriteria': filterCriteria, 
            'filterOperator': filterOperator, 
            //'startDate': getFormatedDate(startDate), 
            //'endDate': getFormatedDate(endDate)
        };
        console.log(obj);
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify({
            param_data: obj,
            
            authToken: sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

        const requestData = { token: encryptedData };

        try {
            const res = await getFilterQrAssetsData(requestData);
            if (res?.data?.data?.length) {
                setItems(res.data.data);
                setTotalItems(res.data.total || 0);
            } else {
                setItems([]);
            }
        } catch (error) {
            console.error('Error fetching assets:', error);
        } finally {
            setLoading(false);
        }
    }, [page, pageSize, typeOfAssets, natureOfPresence, typeOfSiteId, startDate, endDate, startTAGno, endTAGNo]);
    const fetchAssets1 = async () => {
        setLoading(true);

        const offset = calculateOffset(page, pageSize);
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify({
            param_data: { 'offset': offset, 'pageSize': pageSize, 'searchTag': searchTag, 'filterCriteria': filterCriteria, 'filterOperator': filterOperator, 'startDate': getFormatedDate(startDate), 'endDate': getFormatedDate(endDate) },
            authToken: sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();

        const requestData = { token: encryptedData };

        try {
            const res = await getAssets(requestData);
            if (res?.data?.data?.length) {
                setItems(res.data.data);
                setTotalItems(res.data.total || 0);
            } else {
                setItems('');
            }
        } catch (error) {
            console.error('Error fetching assets:', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchAssets();
    }, []);

    const handlePageSizeChange = (event) => {
        setPageSize(Number(event.target.value));
        setPage(1);
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const onRowsSelectionHandler = (selectionModel) => {
        const selectedRows = selectionModel.map((id) => items.find((row) => row.sl_no === id));
        setSelectedRowsData(selectedRows);
    };
    const handleSearch = () => {
        let isValid = true;
        if (!filterCriteria) {
            setErrFilterCriteria('Please select a filter criteria');
            isValid = false;
        } else {
            setErrFilterCriteria('');
        }

        if (filterCriteria !== 'created_at' && !filterOperator) {
            setErrFilterOperator('Please select a filter operator');
            isValid = false;
        } else {
            setErrFilterOperator('');
        }

        if (!searchTag && filterCriteria != 'created_at' ) {
            setErrSearchTag('Please enter a search text');
            isValid = false;
        }else{
            setErrSearchTag('');
        }
        if (!startDate && filterCriteria == 'created_at' ) {
            setErrStartDate('Please select start date');
            isValid = false;
        }else{
            setErrStartDate('');
        }
        if (!endDate && filterCriteria == 'created_at' ) {
            setErrEndDate('Please select end date');
            isValid = false;
        }else{
            setErrEndDate('');
        }

        if (isValid) {
            setPage(1);
            fetchAssets();
        }

    };

    // Define options and values
    const options = [
        "Year Of Addition", "Type of Addition", "PO Number", "Supplier Name", "Invoice Number", "Asset Name", "Make",
        "Model Number", "Asset Serial Number", "Part Number", "TAG Number", "TAG Name", "Nature of Presence", "Date Range"
    ];

    const values = [
        "year_of_addition", "type_of_addition", "po_no", "supplier_name", "invoice_no", "asset_name", "manufacturer", "model_no", "asset_serial_no", "part_no", "tag", "tag_name", "nature_of_presence", "created_at"
    ];

    // Combine options and values into an array of objects
    const combinedOptions = options.map((option, index) => ({
        label: option,
        value: values[index]
    }));

    // Handle change event
    const handleSelectChange = (e) => {
        const selectedOption = combinedOptions.find(option => option.value === e.target.value);
        setFilterCriteria(selectedOption ? selectedOption.value : '');
        alert(filterCriteria);
        setErrFilterCriteria(''); // Reset error message on change
    };

    const optionsFilter = [
        "Contains", "Equals", "Not Contains", "Not Equals", "Blank", "Non Blank"
    ];

    const valuesFilter = [
        "LIKE", "=", "NOT LIKE", "!=", "IS NULL", "IS NOT NULL"
    ];

    // Combine options and values into an array of objects
    const combinedOptionsFilter = optionsFilter.map((option, index) => ({
        label: option,
        value: valuesFilter[index]
    }));

    // Handle operator change event
    const handleOperatorChange = (e) => {
        setFilterOperator(e.target.value);
        setErrFilterOperator(''); // Reset error message on change
    };
    const handelSearchText = (e) => {
        setSearchTag(e.target.value);
    };
    const printQR = () => {
        if (!selectedRowsData || selectedRowsData.length === 0) {
            console.error("No rows selected for printing.");
            return;
        }

        const qrcodesDiv = document.createElement("div");
        qrcodesDiv.setAttribute("style", "width:350px");
        qrcodesDiv.id = 'qrcode-container';

        selectedRowsData.forEach((elm) => {
            QRCode.toDataURL(elm.tag, {
                width: 800,
                margin: 2,
            }, (err, url) => {
                if (err) return console.error(err);

                const row1 = document.createElement("div");
                row1.setAttribute("class", 'qrcodes-div');
                row1.setAttribute("style", "display:inline-block;");

                const col1 = document.createElement("div");
                col1.setAttribute("class", 'col');
                col1.setAttribute("style", "width:90px;margin-left: -20px; margin-top:0px;");

                const col3 = document.createElement("img");
                col3.setAttribute("src", url);
                col3.setAttribute("style", "width:90px");

                col1.appendChild(col3);

                const col2 = document.createElement("div");
                col2.setAttribute("class", 'col');
                col2.setAttribute("style", "width:280px;float:right;text-align: left;font-size: 16px;margin-top: 1px;margin-left: -20px");

                col2.innerHTML = `
                <label><span>${sessionStorage.getItem("LoggedInCompanyName")}</span></label><br/>
                <label>Division : <span>${elm.tag_division}</span></label><br/>
                <label>Asset : <span>${elm.tag_name}</span></label><br/>
                <label>TAG : <span>${elm.tag}</span></label><br/>
            `;

                row1.appendChild(col1);
                row1.appendChild(col2);
                qrcodesDiv.appendChild(row1);
                document.getElementById('print_qr').appendChild(qrcodesDiv);
            });
        });

        const printContents = document.getElementById('print_qr').innerHTML;
        const docprint = window.open();

        docprint.document.write('<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN"');
        docprint.document.write('"http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">');
        docprint.document.write('<html xmlns="http://www.w3.org/1999/xhtml" xml:lang="en">');
        docprint.document.write('<head><title>Print QR Codes</title>');
        docprint.document.write('<style type="text/css">@media screen, print { @page { size: 70mm 20mm; margin: 0mm; } html, body { margin: 0px;padding: 0px; } #qrcode-container { width: 384px;}.qrcodes-div { width: 380px; height: 25mm;margin: 1mm auto;page-break-after: always;} .col:first-child { display: inline-block; width: 80px; padding-top: 0px; padding-left: 1px; }.col:last-child { display: inline-block; width: 260px; padding-top: 0px; float: right; } }</style>');
        docprint.document.write('</head><body onload="self.print()"><center>');
        docprint.document.write(printContents);
        docprint.document.write('</center></body></html>');
        docprint.document.close();
        docprint.focus();
        docprint.print();
        document.getElementById('print_qr').innerHTML = '';
    };

    //const totalPages = Math.ceil(totalItems / pageSize);

    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h5>All Print QR Code</h5>
                </div>
            </div>
            <div className='row m-2'>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>
                                Select Criteria <span className="star error">*</span>
                            </label>
                            <select
                                className="form-control"
                                value={filterCriteria}
                                onChange={handleSelectChange}
                            >
                                <option value="" disabled>Select Filter Criteria</option>
                                {combinedOptions.map((option, index) => (
                                    <option key={index} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                            {ErrFilterCriteria && <span className="invalid-feedback">{ErrFilterCriteria}</span>}
                        </div>
                    </div>

                    {filterCriteria != 'created_at' && (
                        <div className="col-md-3">
                            <div className="form-group">
                                <label>
                                    Select Operator <span className="star error">*</span>
                                </label>
                                <select
                                    className="form-control"
                                    value={filterOperator}
                                    onChange={handleOperatorChange}
                                >
                                    <option value="" disabled>Select Operator</option>
                                    {combinedOptionsFilter.map((option, index) => (
                                        <option key={index} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                                {ErrFilterOperator && <span className="invalid-feedback">{ErrFilterOperator}</span>}
                            </div>
                        </div>
                    )}

                    {filterCriteria != 'created_at' && (
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>
                                    Enter Criteria <span className="star error">*</span>
                                </label>

                                <input
                                    type="text" className="form-control"
                                    placeholder="Search..."
                                    inputValue={searchTag}
                                    //setInputValue={setSearchTag}
                                    onChange={handelSearchText}
                                    style={{marginRight: '10px'}}
                                />
                                {ErrSearchTag && <span className="invalid-feedback">{ErrSearchTag}</span>}
                            </div>
                        </div>
                    )}
                </div>
                {filterCriteria === 'created_at' && (
                    <div className="row m-2">
                        <div className="col-6">

                            <FromFieldDate
                                label="Start Date"
                                placeholder="Select Start Date"
                                inputValue={startDate}
                                setInputValue={setStartDate}
                                setErrorMsg={ErrStartDate}
                            />

                        </div>
                        <div className="col-6">

                            <FromFieldDate
                                label="End Date"
                                placeholder="Select End Date"
                                inputValue={endDate}
                                setInputValue={setEndDate}
                                setErrorMsg={ErrEndDate}
                            />

                        </div>
                    </div>
                )}

            <div className="row">
                <div className="col-4">
                    <FromFieldSelect
                        label="Nature of Presence"
                        placeholder="Select Nature of Presence"
                        selectedValue={natureOfPresence}
                        setSelectedValue={setNatureOfPresence}
                        values={["Identifiable", "Non Identifiable", "All"]}
                    />
                </div>
                <div className="col-4">
                    <FromFieldSelect
                        label="Type of Assets"
                        placeholder="Type of Assets"
                        selectedValue={typeOfAssets}
                        setSelectedValue={setTypeOfAssets}
                        values={["Parent", "Child", "All"]}
                    />
                </div>
                <div className="col-3">
                    <FromFieldSelect
                        label="Site ID"
                        placeholder=""
                        selectedValue={typeOfSiteId}
                        setSelectedValue={setTypeOfSiteId}
                        values={["Is Empty", "Is Not Empty"]}
                    />
                </div>

            </div>

            {/*<div className="row">
                <div className="col-4">
                    <FromFieldDate
                        label="Start Date"
                        placeholder="Select Start Date"
                        inputValue={startDate}
                        setInputValue={setStartDate}
                    />
                </div>
                <div className="col-4">
                    <FromFieldDate
                        label="End Date"
                        placeholder="Select End Date"
                        inputValue={endDate}
                        setInputValue={setEndDate}
                    />
                </div>
            </div>*/}

            <div className="row">
                <div className="col-4">
                    <FromFieldText
                        label="Start TAG No"
                        placeholder="Enter Start TAG No"
                        inputValue={startTAGno}
                        setInputValue={setStartTAGno}
                    />
                </div>
                <div className="col-4">
                    <FromFieldText
                        label="End TAG No"
                        placeholder="Enter End TAG No"
                        inputValue={endTAGNo}
                        setInputValue={setEndTAGNo}
                    />
                </div>
                <div className="col-4 text-center">
                    <button onClick={handleSearch} className='btn btn-info btn-md'>
                        <i className="bi bi-search"></i> Search
                    </button>
                </div>
            </div>

            <div className='row mb-3 mt-2'>
                <div className='col-2'>
                    <button onClick={printQR} className='btn btn-secondary btn-sm'>
                        <i className="bi bi-printer"></i> Print
                    </button>
                </div>
            </div>

            <div className='row'>
                {loading ? (
                    <div className="text-center">
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ) : (
                    <Box>
                        <DataGrid
                            checkboxSelection={checkboxSelection}
                            rows={items}
                            getRowId={(row) => row.sl_no}
                            columns={columns}
                            autoHeight
                            components={{
                                LoadingOverlay: CustomLoader,
                                Toolbar: CustomToolbar,
                                
                            }}
                            loading={loading}
                            onSelectionModelChange={onRowsSelectionHandler}
                        />
                    </Box>
                )}
            </div>
            <div id='print_qr'></div>
        </div>
    );
}

export default AllQRGenrate