import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { getCompanyProfileList } from '../../services/user.service';
import { getUsersList, getTicketAdminInfo, saveTicketAdmin } from '../../services/tickets.service';
import * as CryptoJS from "crypto-js";
import Select from "react-select";

const TEXT_REGEX = /^[a-zA-Z0-9-_ ]{1,255}$/;

function AddTicketAdmin() {
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
    const [companyId, setCompanyId] = useState('');
    const [ErrCompanyId, setErrCompanyId] = useState(false);
    const [companies, setCompanies] = useState([]);
    const [useraddition, setUserAddition] = useState('');
    const [ErrUserAddition, setErrUserAddition] = useState(false);
    const [user_addition_items, setUser_Addition_Items] = useState([]);
    const [selectedUserOptions, setSelectedUserOptions] = useState(null);

    const optionListUser = user_addition_items.map(option => ({
        value: option.user_id,
        label: `${option.user_name} / ${option.user_email}`
    }));

    const resetUserInfo = function () {
        setUser_Addition_Items([]);
        setCompanyId('');
        setUserAddition('');
        setSelectedUserOptions(null);
    };

    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const ticketSubID = query.get('ticket_admin_ids');

    useEffect(() => {
        if (ticketSubID !== '' && ticketSubID != null) {
            const decryptedBytes = CryptoJS.AES.decrypt(ticketSubID.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
            const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
            const yearListId = decrypt_data.yearId;
            getYearData(yearListId);
        }

        const EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        const oData = { 'token': EncryptData };
        getCompanyProfileList(oData).then(res => {
            if (res?.data?.data?.length) setCompanies(res.data.data);
        });
    }, [ticketSubID]);

    const getYearData = function (yearListId) {
        const EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': { 'yearId': yearListId },
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        const oData1 = { 'token': EncryptData };
        getTicketAdminInfo(oData1).then(function (res) {
            if (res?.data?.data?.length) {
                const divisInfo = res.data.data[0];
                if (divisInfo) {
                    setCompanyId(divisInfo.company_id || '');
                    setUserAddition(divisInfo.ticket_admin_id || '');
                    handleCompanyId({ target: { value: divisInfo.company_id } }, divisInfo.ticket_admin_id);
                }
            }
        });
    };

    const handleCompanyId = (e, adminId) => {
        const company_value = e.target.value;
        setCompanyId(company_value);

        const EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
            'param_data': { 'company_id': company_value },
            'authToken': sessionStorage.getItem('LoggedInToken'),
        }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
        const oData2 = { 'token': EncryptData };
        getUsersList(oData2).then(res => {
            if (res?.data?.data?.length) {
                setUser_Addition_Items(res.data.data);
                if (adminId) {
                    const selectedOption = res.data.data.find(option => option.user_id === adminId);
                    if (selectedOption) {
                        setSelectedUserOptions({
                            value: selectedOption.user_id,
                            label: `${selectedOption.user_name} / ${selectedOption.user_email}`
                        });
                    }
                }
            }
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let valid_Name = true;

        if (!TEXT_REGEX.test(companyId)) {
            setErrCompanyId('Company name is required');
            valid_Name = false;
        } else {
            setErrCompanyId('');
        }
        if (!TEXT_REGEX.test(useraddition)) {
            setErrUserAddition('User is required');
            valid_Name = false;
        } else {
            setErrUserAddition('');
        }

        if (valid_Name) {
            saveData();
        } else {
            return false;
        }
    };

    const saveData = function () {
        try {
            let obj;
            if (ticketSubID !== '' && ticketSubID !== null) {
                const decryptedBytes = CryptoJS.AES.decrypt(ticketSubID.replace(/ /g, '+'), process.env.REACT_APP_ENCRYPT_SECRET_KEY);
                const decrypt_data = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
                const decryptedTicketSubId = decrypt_data.yearId;
                obj = {
                    'ticket_sub_id': decryptedTicketSubId,
                    'ticket_admin_id': useraddition,
                    'company_id': companyId,
                    'created_by': sessionStorage.getItem('LoggedInUserId'),
                };
            } else {
                obj = {
                    'ticket_admin_id': useraddition,
                    'company_id': companyId,
                    'created_by': sessionStorage.getItem('LoggedInUserId'),
                };
            }

            const EncryptData = CryptoJS.AES.encrypt(JSON.stringify({
                'param_data': obj,
                'authToken': sessionStorage.getItem('LoggedInToken'),
            }), process.env.REACT_APP_ENCRYPT_SECRET_KEY).toString();
            const oData = { 'token': EncryptData };

            saveTicketAdmin(oData).then(function (res) {
                if (res.data.errors && res.data.errors.errno > 0) {
                    setErrMsg(res.data.message);
                    setSuccess(false);
                } else {
                    setSuccess(res.data.message);
                    resetUserInfo();
                }
            });

        } catch (err) {
            setSuccess(false);
            if (!err?.data) {
                console.log(err);
                setErrMsg('No Server Response');
            } else if (err.data?.status === 409) {
                setErrMsg('Data already exists');
            } else {
                setErrMsg('Insert/Update Failed');
            }
        }
    };

    function handleUserAddition(data) {
        setSelectedUserOptions(data);
        setUserAddition(data.value);
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-6">
                    <h5>Add Ticket Admin</h5>
                </div>
                <div className="invalid-feedback" role="alert">
                    <i className="fa fa-info-circle"></i>
                    Please Fill all mandatory field.
                </div>

                {success ? (
                    <div className="alert alert-success" role="alert"><i className="bi bi-check-circle-fill"></i>{success}</div>
                ) : (errMsg ? (<div className="alert alert-danger" role="alert"><i className="bi bi-exclamation-triangle-fill"></i>{errMsg}</div>) : ('')
                )}
            </div>

            <div className='row '>
                <div className='col-12'>&nbsp;</div>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="row form-fields">
                    <div className="label col-3">
                        <label>Company </label><span className="star error"> *</span>
                    </div>

                    <div className="item col-5">
                        <select className="form-select form-select-sm mb-3" onChange={handleCompanyId} value={companyId}>
                            <option value="">Select Company</option>
                            {companies && companies.length > 0 ? (
                                companies.map((option) => (
                                    <option key={option.company_id} value={option.company_id}>{option.company_name}</option>
                                ))
                            ) : (
                                <option value="">No Data</option>
                            )}
                        </select>
                        <span className="invalid-feedback"> {ErrCompanyId} </span>
                    </div>
                </div>

                <div className="row form-fields">
                    <div className="label col-3">
                        <label>Select User </label><span className="star error"> *</span>
                    </div>
                    <div className="item col-5">
                        <Select
                            options={optionListUser}
                            placeholder="Select One"
                            value={selectedUserOptions}
                            onChange={handleUserAddition}
                        />
                        <span className="invalid-feedback"> {ErrUserAddition} </span>
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-3"></div>
                    <div className="col-6">
                        <button className="btn btn-primary btn-sm"> SAVE </button>
                    </div>
                    <div className="col-3"></div>
                </div>
            </form>
        </div>
    );
}

export default AddTicketAdmin;