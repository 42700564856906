import axios from "axios";

var baseURL = process.env.REACT_APP_API_BASE_URL;

var apis = {

    'saveAssetDisposal': `${baseURL}disposal/single-add`,
    'getLastDinNo': `${baseURL}disposal/get-last-din`,
    'getAssetsDisposalData': `${baseURL}disposal/getassetsdisposaldata`,
    'getAllDisposalTagList': `${baseURL}disposal/getalldisposaltaglist`,

    'saveAssetQuotes': `${baseURL}disposal/saveassetquotes`,
    'getDisposalAssetDetails': `${baseURL}disposal/getdisposalassetdetails`,
    'getBuyerQuotedPrice': `${baseURL}disposal/getbuyerquotedprice`,

    'saveFinalisedDeal': `${baseURL}disposal/savefinaliseddeal`,

    'getFinalizeBuyerList': `${baseURL}disposal/getfinalizebuyerlist`,
    'getQuoteList': `${baseURL}disposal/getquotelist`,
    'getAssetQuoteData': `${baseURL}disposal/getassetquotedata`,

}

export function saveAssetDisposal(data) {
    return axios.put(apis.saveAssetDisposal, data);
}

export function saveAssetQuotes(data) {
    return axios.put(apis.saveAssetQuotes, data);
}

export function getLastDinNo(data) {
    //return axios.get(`${apis.getCompanyProfileList}`);
    return axios.put(apis.getLastDinNo,data);
}

export function getAssetsDisposalData(data) {
    //return axios.get(`${apis.getCompanyProfileList}`);
    return axios.put(apis.getAssetsDisposalData,data);
}

export function getAllDisposalTagList(data) {
    //return axios.get(`${apis.getCompanyProfileList}`);
    return axios.put(apis.getAllDisposalTagList,data);
}

export function getDisposalAssetDetails(data) {
    //return axios.get(`${apis.getCompanyProfileList}`);
    return axios.put(apis.getDisposalAssetDetails,data);
}

export function getBuyerQuotedPrice(data) {
    //return axios.get(`${apis.getCompanyProfileList}`);
    return axios.put(apis.getBuyerQuotedPrice,data);
}

export function saveFinalisedDeal(data) {
    //return axios.get(`${apis.getCompanyProfileList}`);
    return axios.put(apis.saveFinalisedDeal,data);
}
export function getFinalizeBuyerList(data) {
    //return axios.get(`${apis.getCompanyProfileList}`);
    return axios.put(apis.getFinalizeBuyerList,data);
}
export function getQuoteList(data) {
    //return axios.get(`${apis.getCompanyProfileList}`);
    return axios.put(apis.getQuoteList,data);
}
export function getAssetQuoteData(data) {
    //return axios.get(`${apis.getCompanyProfileList}`);
    return axios.put(apis.getAssetQuoteData,data);
}